*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Lato, Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 401px) {
  .container {
    max-width: 401px;
  }
}

@media (width >= 561px) {
  .container {
    max-width: 561px;
  }
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 682px) {
  .container {
    max-width: 682px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 769px) {
  .container {
    max-width: 769px;
  }
}

@media (width >= 961px) {
  .container {
    max-width: 961px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1025px) {
  .container {
    max-width: 1025px;
  }
}

@media (width >= 1080px) {
  .container {
    max-width: 1080px;
  }
}

@media (width >= 1175px) {
  .container {
    max-width: 1175px;
  }
}

@media (width >= 1261px) {
  .container {
    max-width: 1261px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.pointer-events-none {
  pointer-events: none;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.-right-20 {
  right: -5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.bottom-1 {
  bottom: .25rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.bottom-12 {
  bottom: 3rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.bottom-16 {
  bottom: 4rem;
}

.bottom-2 {
  bottom: .5rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.bottom-20 {
  bottom: 5rem;
}

.bottom-24 {
  bottom: 6rem;
}

.bottom-28 {
  bottom: 7rem;
}

.bottom-3 {
  bottom: .75rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-36 {
  bottom: 9rem;
}

.bottom-4 {
  bottom: 1rem;
}

.bottom-40 {
  bottom: 10rem;
}

.bottom-44 {
  bottom: 11rem;
}

.bottom-48 {
  bottom: 12rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.bottom-52 {
  bottom: 13rem;
}

.bottom-56 {
  bottom: 14rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.bottom-60 {
  bottom: 15rem;
}

.bottom-64 {
  bottom: 16rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.bottom-72 {
  bottom: 18rem;
}

.bottom-8 {
  bottom: 2rem;
}

.bottom-80 {
  bottom: 20rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.bottom-96 {
  bottom: 24rem;
}

.bottom-px {
  bottom: 1px;
}

.left-0 {
  left: 0;
}

.left-0\.5 {
  left: .125rem;
}

.left-1 {
  left: .25rem;
}

.left-1\.5 {
  left: .375rem;
}

.left-10 {
  left: 2.5rem;
}

.left-11 {
  left: 2.75rem;
}

.left-12 {
  left: 3rem;
}

.left-14 {
  left: 3.5rem;
}

.left-16 {
  left: 4rem;
}

.left-2 {
  left: .5rem;
}

.left-2\.5 {
  left: .625rem;
}

.left-20 {
  left: 5rem;
}

.left-24 {
  left: 6rem;
}

.left-28 {
  left: 7rem;
}

.left-3 {
  left: .75rem;
}

.left-3\.5 {
  left: .875rem;
}

.left-32 {
  left: 8rem;
}

.left-36 {
  left: 9rem;
}

.left-4 {
  left: 1rem;
}

.left-40 {
  left: 10rem;
}

.left-44 {
  left: 11rem;
}

.left-48 {
  left: 12rem;
}

.left-5 {
  left: 1.25rem;
}

.left-52 {
  left: 13rem;
}

.left-56 {
  left: 14rem;
}

.left-6 {
  left: 1.5rem;
}

.left-60 {
  left: 15rem;
}

.left-64 {
  left: 16rem;
}

.left-7 {
  left: 1.75rem;
}

.left-72 {
  left: 18rem;
}

.left-8 {
  left: 2rem;
}

.left-80 {
  left: 20rem;
}

.left-9 {
  left: 2.25rem;
}

.left-96 {
  left: 24rem;
}

.left-px {
  left: 1px;
}

.right-0 {
  right: 0;
}

.right-0\.5 {
  right: .125rem;
}

.right-1 {
  right: .25rem;
}

.right-1\.5 {
  right: .375rem;
}

.right-10 {
  right: 2.5rem;
}

.right-11 {
  right: 2.75rem;
}

.right-12 {
  right: 3rem;
}

.right-14 {
  right: 3.5rem;
}

.right-16 {
  right: 4rem;
}

.right-2 {
  right: .5rem;
}

.right-2\.5 {
  right: .625rem;
}

.right-20 {
  right: 5rem;
}

.right-24 {
  right: 6rem;
}

.right-28 {
  right: 7rem;
}

.right-3 {
  right: .75rem;
}

.right-3\.5 {
  right: .875rem;
}

.right-32 {
  right: 8rem;
}

.right-36 {
  right: 9rem;
}

.right-4 {
  right: 1rem;
}

.right-40 {
  right: 10rem;
}

.right-44 {
  right: 11rem;
}

.right-48 {
  right: 12rem;
}

.right-5 {
  right: 1.25rem;
}

.right-52 {
  right: 13rem;
}

.right-56 {
  right: 14rem;
}

.right-6 {
  right: 1.5rem;
}

.right-60 {
  right: 15rem;
}

.right-64 {
  right: 16rem;
}

.right-7 {
  right: 1.75rem;
}

.right-72 {
  right: 18rem;
}

.right-8 {
  right: 2rem;
}

.right-80 {
  right: 20rem;
}

.right-9 {
  right: 2.25rem;
}

.right-96 {
  right: 24rem;
}

.right-px {
  right: 1px;
}

.top-0 {
  top: 0;
}

.top-0\.5 {
  top: .125rem;
}

.top-1 {
  top: .25rem;
}

.top-1\.5 {
  top: .375rem;
}

.top-10 {
  top: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.top-14 {
  top: 3.5rem;
}

.top-16 {
  top: 4rem;
}

.top-2 {
  top: .5rem;
}

.top-2\.5 {
  top: .625rem;
}

.top-20 {
  top: 5rem;
}

.top-24 {
  top: 6rem;
}

.top-28 {
  top: 7rem;
}

.top-3 {
  top: .75rem;
}

.top-3\.5 {
  top: .875rem;
}

.top-32 {
  top: 8rem;
}

.top-36 {
  top: 9rem;
}

.top-4 {
  top: 1rem;
}

.top-40 {
  top: 10rem;
}

.top-44 {
  top: 11rem;
}

.top-48 {
  top: 12rem;
}

.top-5 {
  top: 1.25rem;
}

.top-52 {
  top: 13rem;
}

.top-56 {
  top: 14rem;
}

.top-6 {
  top: 1.5rem;
}

.top-60 {
  top: 15rem;
}

.top-64 {
  top: 16rem;
}

.top-7 {
  top: 1.75rem;
}

.top-72 {
  top: 18rem;
}

.top-8 {
  top: 2rem;
}

.top-80 {
  top: 20rem;
}

.top-9 {
  top: 2.25rem;
}

.top-96 {
  top: 24rem;
}

.top-\[3vw\] {
  top: 3vw;
}

.top-px {
  top: 1px;
}

.z-10 {
  z-index: 10;
}

.z-\[2\] {
  z-index: 2;
}

.\!mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.\!my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.\!-mb-16 {
  margin-bottom: -4rem !important;
}

.\!-mb-\[3vw\] {
  margin-bottom: -3vw !important;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!mb-10 {
  margin-bottom: 2.5rem !important;
}

.\!mb-12 {
  margin-bottom: 3rem !important;
}

.\!mb-6 {
  margin-bottom: 1.5rem !important;
}

.\!mb-8 {
  margin-bottom: 2rem !important;
}

.\!mb-\[2\.6rem\] {
  margin-bottom: 2.6rem !important;
}

.\!mb-\[3\.2rem\] {
  margin-bottom: 3.2rem !important;
}

.\!mt-12 {
  margin-top: 3rem !important;
}

.\!mt-16 {
  margin-top: 4rem !important;
}

.\!mt-8 {
  margin-top: 2rem !important;
}

.\!mt-\[2\.6rem\] {
  margin-top: 2.6rem !important;
}

.\!mt-\[6\.67vw\] {
  margin-top: 6.67vw !important;
}

.-ml-\[1\.75rem\] {
  margin-left: -1.75rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-\[3\.2rem\] {
  margin-bottom: 3.2rem;
}

.mb-\[4\.5rem\] {
  margin-bottom: 4.5rem;
}

.\!block {
  display: block !important;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.aspect-auto {
  aspect-ratio: auto;
}

.\!h-1\/2 {
  height: 50% !important;
}

.\!h-\[3\.75rem\] {
  height: 3.75rem !important;
}

.h-0 {
  height: 0;
}

.h-0\.5 {
  height: .125rem;
}

.h-1 {
  height: .25rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-1\/4 {
  height: 25%;
}

.h-1\/5 {
  height: 20%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-2\/4 {
  height: 50%;
}

.h-2\/5 {
  height: 40%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-3 {
  height: .75rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-3\/4 {
  height: 75%;
}

.h-3\/5 {
  height: 60%;
}

.h-3\/6 {
  height: 50%;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-4 {
  height: 1rem;
}

.h-4\/5 {
  height: 80%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-5 {
  height: 1.25rem;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-6 {
  height: 1.5rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-7 {
  height: 1.75rem;
}

.h-72 {
  height: 18rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[0\.8rem\] {
  height: .8rem;
}

.h-\[70\%\] {
  height: 70%;
}

.h-auto {
  height: auto;
}

.h-dvh {
  height: 100dvh;
}

.h-fit {
  height: -moz-fit-content;
  height: fit-content;
}

.h-full {
  height: 100%;
}

.h-lvh {
  height: 100lvh;
}

.h-max {
  height: max-content;
}

.h-min {
  height: min-content;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.h-svh {
  height: 100svh;
}

.\!max-h-12 {
  max-height: 3rem !important;
}

.\!max-h-16 {
  max-height: 4rem !important;
}

.\!max-h-none {
  max-height: none !important;
}

.max-h-\[1\.75rem\] {
  max-height: 1.75rem;
}

.max-h-\[2\.45rem\] {
  max-height: 2.45rem;
}

.\!w-\[12\%\] {
  width: 12% !important;
}

.w-0 {
  width: 0;
}

.w-0\.5 {
  width: .125rem;
}

.w-1 {
  width: .25rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-10 {
  width: 2.5rem;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11 {
  width: 2.75rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/4 {
  width: 50%;
}

.w-2\/5 {
  width: 40%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-3\/12 {
  width: 25%;
}

.w-3\/4 {
  width: 75%;
}

.w-3\/5 {
  width: 60%;
}

.w-3\/6 {
  width: 50%;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-4 {
  width: 1rem;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-4\/5 {
  width: 80%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-6\/12 {
  width: 50%;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-72 {
  width: 18rem;
}

.w-8 {
  width: 2rem;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-9\/12 {
  width: 75%;
}

.w-96 {
  width: 24rem;
}

.w-\[21\%\] {
  width: 21%;
}

.w-\[29\.5\%\] {
  width: 29.5%;
}

.w-\[35\%\] {
  width: 35%;
}

.w-\[41\%\] {
  width: 41%;
}

.w-\[61\%\] {
  width: 61%;
}

.w-auto {
  width: auto;
}

.w-dvw {
  width: 100dvw;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-lvw {
  width: 100lvw;
}

.w-max {
  width: max-content;
}

.w-min {
  width: min-content;
}

.w-px {
  width: 1px;
}

.w-screen {
  width: 100vw;
}

.w-svw {
  width: 100svw;
}

.\!min-w-\[9rem\] {
  min-width: 9rem !important;
}

.\!max-w-\[25\.6rem\] {
  max-width: 25.6rem !important;
}

.\!max-w-\[25\.95rem\] {
  max-width: 25.95rem !important;
}

.\!max-w-\[27\.9rem\] {
  max-width: 27.9rem !important;
}

.\!max-w-\[30\.75rem\] {
  max-width: 30.75rem !important;
}

.\!max-w-\[40rem\] {
  max-width: 40rem !important;
}

.max-w-\[14\.75rem\] {
  max-width: 14.75rem;
}

.max-w-\[16\.3rem\] {
  max-width: 16.3rem;
}

.max-w-\[17\.1rem\] {
  max-width: 17.1rem;
}

.max-w-\[34\.7rem\] {
  max-width: 34.7rem;
}

.max-w-\[40\.8rem\] {
  max-width: 40.8rem;
}

.max-w-\[40rem\] {
  max-width: 40rem;
}

.max-w-\[42rem\] {
  max-width: 42rem;
}

.max-w-\[47\.6rem\] {
  max-width: 47.6rem;
}

.max-w-\[48\.5rem\] {
  max-width: 48.5rem;
}

.max-w-\[49\.35rem\] {
  max-width: 49.35rem;
}

.max-w-\[52\.1rem\] {
  max-width: 52.1rem;
}

.max-w-\[59\.6rem\] {
  max-width: 59.6rem;
}

.max-w-\[59rem\] {
  max-width: 59rem;
}

.\!flex-1 {
  flex: 1 !important;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.resize {
  resize: both;
}

.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.items-center {
  align-items: center;
}

.\!gap-\[4\.5rem\] {
  gap: 4.5rem !important;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border-amber-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 243 199 / var(--tw-border-opacity));
}

.border-amber-200 {
  --tw-border-opacity: 1;
  border-color: rgb(253 230 138 / var(--tw-border-opacity));
}

.border-amber-300 {
  --tw-border-opacity: 1;
  border-color: rgb(252 211 77 / var(--tw-border-opacity));
}

.border-amber-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 191 36 / var(--tw-border-opacity));
}

.border-amber-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 251 235 / var(--tw-border-opacity));
}

.border-amber-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

.border-amber-600 {
  --tw-border-opacity: 1;
  border-color: rgb(217 119 6 / var(--tw-border-opacity));
}

.border-amber-700 {
  --tw-border-opacity: 1;
  border-color: rgb(180 83 9 / var(--tw-border-opacity));
}

.border-amber-800 {
  --tw-border-opacity: 1;
  border-color: rgb(146 64 14 / var(--tw-border-opacity));
}

.border-amber-900 {
  --tw-border-opacity: 1;
  border-color: rgb(120 53 15 / var(--tw-border-opacity));
}

.border-amber-950 {
  --tw-border-opacity: 1;
  border-color: rgb(69 26 3 / var(--tw-border-opacity));
}

.border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgb(191 219 254 / var(--tw-border-opacity));
}

.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgb(147 197 253 / var(--tw-border-opacity));
}

.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgb(96 165 250 / var(--tw-border-opacity));
}

.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgb(239 246 255 / var(--tw-border-opacity));
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgb(37 99 235 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 64 175 / var(--tw-border-opacity));
}

.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgb(30 58 138 / var(--tw-border-opacity));
}

.border-blue-950 {
  --tw-border-opacity: 1;
  border-color: rgb(23 37 84 / var(--tw-border-opacity));
}

.border-btn-grey {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-content-image {
  --tw-border-opacity: 1;
  border-color: rgb(158 201 217 / var(--tw-border-opacity));
}

.border-current {
  border-color: currentColor;
}

.border-cyan-100 {
  --tw-border-opacity: 1;
  border-color: rgb(207 250 254 / var(--tw-border-opacity));
}

.border-cyan-200 {
  --tw-border-opacity: 1;
  border-color: rgb(165 243 252 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-400 {
  --tw-border-opacity: 1;
  border-color: rgb(34 211 238 / var(--tw-border-opacity));
}

.border-cyan-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 254 255 / var(--tw-border-opacity));
}

.border-cyan-500 {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

.border-cyan-600 {
  --tw-border-opacity: 1;
  border-color: rgb(8 145 178 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-cyan-800 {
  --tw-border-opacity: 1;
  border-color: rgb(21 94 117 / var(--tw-border-opacity));
}

.border-cyan-900 {
  --tw-border-opacity: 1;
  border-color: rgb(22 78 99 / var(--tw-border-opacity));
}

.border-cyan-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 51 68 / var(--tw-border-opacity));
}

.border-dark-red {
  --tw-border-opacity: 1;
  border-color: rgb(209 60 13 / var(--tw-border-opacity));
}

.border-emerald-100 {
  --tw-border-opacity: 1;
  border-color: rgb(209 250 229 / var(--tw-border-opacity));
}

.border-emerald-200 {
  --tw-border-opacity: 1;
  border-color: rgb(167 243 208 / var(--tw-border-opacity));
}

.border-emerald-300 {
  --tw-border-opacity: 1;
  border-color: rgb(110 231 183 / var(--tw-border-opacity));
}

.border-emerald-400 {
  --tw-border-opacity: 1;
  border-color: rgb(52 211 153 / var(--tw-border-opacity));
}

.border-emerald-50 {
  --tw-border-opacity: 1;
  border-color: rgb(236 253 245 / var(--tw-border-opacity));
}

.border-emerald-500 {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

.border-emerald-600 {
  --tw-border-opacity: 1;
  border-color: rgb(5 150 105 / var(--tw-border-opacity));
}

.border-emerald-700 {
  --tw-border-opacity: 1;
  border-color: rgb(4 120 87 / var(--tw-border-opacity));
}

.border-emerald-800 {
  --tw-border-opacity: 1;
  border-color: rgb(6 95 70 / var(--tw-border-opacity));
}

.border-emerald-900 {
  --tw-border-opacity: 1;
  border-color: rgb(6 78 59 / var(--tw-border-opacity));
}

.border-emerald-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 44 34 / var(--tw-border-opacity));
}

.border-error {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-fuchsia-100 {
  --tw-border-opacity: 1;
  border-color: rgb(250 232 255 / var(--tw-border-opacity));
}

.border-fuchsia-200 {
  --tw-border-opacity: 1;
  border-color: rgb(245 208 254 / var(--tw-border-opacity));
}

.border-fuchsia-300 {
  --tw-border-opacity: 1;
  border-color: rgb(240 171 252 / var(--tw-border-opacity));
}

.border-fuchsia-400 {
  --tw-border-opacity: 1;
  border-color: rgb(232 121 249 / var(--tw-border-opacity));
}

.border-fuchsia-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 244 255 / var(--tw-border-opacity));
}

.border-fuchsia-500 {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

.border-fuchsia-600 {
  --tw-border-opacity: 1;
  border-color: rgb(192 38 211 / var(--tw-border-opacity));
}

.border-fuchsia-700 {
  --tw-border-opacity: 1;
  border-color: rgb(162 28 175 / var(--tw-border-opacity));
}

.border-fuchsia-800 {
  --tw-border-opacity: 1;
  border-color: rgb(134 25 143 / var(--tw-border-opacity));
}

.border-fuchsia-900 {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

.border-fuchsia-950 {
  --tw-border-opacity: 1;
  border-color: rgb(74 4 78 / var(--tw-border-opacity));
}

.border-gradient-orange-dark {
  --tw-border-opacity: 1;
  border-color: rgb(229 89 40 / var(--tw-border-opacity));
}

.border-gradient-orange-light {
  --tw-border-opacity: 1;
  border-color: rgb(232 108 77 / var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgb(249 250 251 / var(--tw-border-opacity));
}

.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(107 114 128 / var(--tw-border-opacity));
}

.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(75 85 99 / var(--tw-border-opacity));
}

.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgb(55 65 81 / var(--tw-border-opacity));
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

.border-gray-950 {
  --tw-border-opacity: 1;
  border-color: rgb(3 7 18 / var(--tw-border-opacity));
}

.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgb(220 252 231 / var(--tw-border-opacity));
}

.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgb(187 247 208 / var(--tw-border-opacity));
}

.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(74 222 128 / var(--tw-border-opacity));
}

.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 253 244 / var(--tw-border-opacity));
}

.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}

.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgb(21 128 61 / var(--tw-border-opacity));
}

.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgb(22 101 52 / var(--tw-border-opacity));
}

.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgb(20 83 45 / var(--tw-border-opacity));
}

.border-green-950 {
  --tw-border-opacity: 1;
  border-color: rgb(5 46 22 / var(--tw-border-opacity));
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgb(50 50 50 / var(--tw-border-opacity));
}

.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 231 255 / var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgb(199 210 254 / var(--tw-border-opacity));
}

.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgb(165 180 252 / var(--tw-border-opacity));
}

.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
}

.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgb(238 242 255 / var(--tw-border-opacity));
}

.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgb(67 56 202 / var(--tw-border-opacity));
}

.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgb(55 48 163 / var(--tw-border-opacity));
}

.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

.border-indigo-950 {
  --tw-border-opacity: 1;
  border-color: rgb(30 27 75 / var(--tw-border-opacity));
}

.border-inherit {
  border-color: inherit;
}

.border-lime-100 {
  --tw-border-opacity: 1;
  border-color: rgb(236 252 203 / var(--tw-border-opacity));
}

.border-lime-200 {
  --tw-border-opacity: 1;
  border-color: rgb(217 249 157 / var(--tw-border-opacity));
}

.border-lime-300 {
  --tw-border-opacity: 1;
  border-color: rgb(190 242 100 / var(--tw-border-opacity));
}

.border-lime-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 230 53 / var(--tw-border-opacity));
}

.border-lime-50 {
  --tw-border-opacity: 1;
  border-color: rgb(247 254 231 / var(--tw-border-opacity));
}

.border-lime-500 {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

.border-lime-600 {
  --tw-border-opacity: 1;
  border-color: rgb(101 163 13 / var(--tw-border-opacity));
}

.border-lime-700 {
  --tw-border-opacity: 1;
  border-color: rgb(77 124 15 / var(--tw-border-opacity));
}

.border-lime-800 {
  --tw-border-opacity: 1;
  border-color: rgb(63 98 18 / var(--tw-border-opacity));
}

.border-lime-900 {
  --tw-border-opacity: 1;
  border-color: rgb(54 83 20 / var(--tw-border-opacity));
}

.border-lime-950 {
  --tw-border-opacity: 1;
  border-color: rgb(26 46 5 / var(--tw-border-opacity));
}

.border-neutral-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-neutral-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 229 229 / var(--tw-border-opacity));
}

.border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 212 / var(--tw-border-opacity));
}

.border-neutral-400 {
  --tw-border-opacity: 1;
  border-color: rgb(163 163 163 / var(--tw-border-opacity));
}

.border-neutral-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-neutral-500 {
  --tw-border-opacity: 1;
  border-color: rgb(115 115 115 / var(--tw-border-opacity));
}

.border-neutral-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 82 / var(--tw-border-opacity));
}

.border-neutral-700 {
  --tw-border-opacity: 1;
  border-color: rgb(64 64 64 / var(--tw-border-opacity));
}

.border-neutral-800 {
  --tw-border-opacity: 1;
  border-color: rgb(38 38 38 / var(--tw-border-opacity));
}

.border-neutral-900 {
  --tw-border-opacity: 1;
  border-color: rgb(23 23 23 / var(--tw-border-opacity));
}

.border-neutral-950 {
  --tw-border-opacity: 1;
  border-color: rgb(10 10 10 / var(--tw-border-opacity));
}

.border-orange {
  --tw-border-opacity: 1;
  border-color: rgb(241 89 41 / var(--tw-border-opacity));
}

.border-overlay {
  --tw-border-opacity: 1;
  border-color: rgb(11 34 69 / var(--tw-border-opacity));
}

.border-partial-white {
  border-color: #ffffff70;
}

.border-pastel-blue {
  --tw-border-opacity: 1;
  border-color: rgb(164 220 232 / var(--tw-border-opacity));
}

.border-pastel-orange {
  --tw-border-opacity: 1;
  border-color: rgb(255 142 106 / var(--tw-border-opacity));
}

.border-pastel-purple {
  --tw-border-opacity: 1;
  border-color: rgb(159 163 208 / var(--tw-border-opacity));
}

.border-pastel-teal {
  --tw-border-opacity: 1;
  border-color: rgb(102 224 203 / var(--tw-border-opacity));
}

.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgb(252 231 243 / var(--tw-border-opacity));
}

.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgb(251 207 232 / var(--tw-border-opacity));
}

.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgb(249 168 212 / var(--tw-border-opacity));
}

.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgb(244 114 182 / var(--tw-border-opacity));
}

.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgb(253 242 248 / var(--tw-border-opacity));
}

.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgb(219 39 119 / var(--tw-border-opacity));
}

.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 24 93 / var(--tw-border-opacity));
}

.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgb(157 23 77 / var(--tw-border-opacity));
}

.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgb(131 24 67 / var(--tw-border-opacity));
}

.border-pink-950 {
  --tw-border-opacity: 1;
  border-color: rgb(80 7 36 / var(--tw-border-opacity));
}

.border-primary {
  --tw-border-opacity: 1;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
}

.border-primary-dark {
  --tw-border-opacity: 1;
  border-color: rgb(14 18 25 / var(--tw-border-opacity));
}

.border-primary-transparent {
  border-color: #262c3854;
}

.border-primary-very-transparent {
  border-color: #262c3820;
}

.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(243 232 255 / var(--tw-border-opacity));
}

.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(233 213 255 / var(--tw-border-opacity));
}

.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgb(216 180 254 / var(--tw-border-opacity));
}

.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgb(192 132 252 / var(--tw-border-opacity));
}

.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 245 255 / var(--tw-border-opacity));
}

.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgb(147 51 234 / var(--tw-border-opacity));
}

.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(126 34 206 / var(--tw-border-opacity));
}

.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgb(107 33 168 / var(--tw-border-opacity));
}

.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

.border-purple-950 {
  --tw-border-opacity: 1;
  border-color: rgb(59 7 100 / var(--tw-border-opacity));
}

.border-red {
  --tw-border-opacity: 1;
  border-color: rgb(255 0 0 / var(--tw-border-opacity));
}

.border-required {
  --tw-border-opacity: 1;
  border-color: rgb(220 40 40 / var(--tw-border-opacity));
}

.border-rose-100 {
  --tw-border-opacity: 1;
  border-color: rgb(255 228 230 / var(--tw-border-opacity));
}

.border-rose-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 205 211 / var(--tw-border-opacity));
}

.border-rose-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 164 175 / var(--tw-border-opacity));
}

.border-rose-400 {
  --tw-border-opacity: 1;
  border-color: rgb(251 113 133 / var(--tw-border-opacity));
}

.border-rose-50 {
  --tw-border-opacity: 1;
  border-color: rgb(255 241 242 / var(--tw-border-opacity));
}

.border-rose-500 {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

.border-rose-600 {
  --tw-border-opacity: 1;
  border-color: rgb(225 29 72 / var(--tw-border-opacity));
}

.border-rose-700 {
  --tw-border-opacity: 1;
  border-color: rgb(190 18 60 / var(--tw-border-opacity));
}

.border-rose-800 {
  --tw-border-opacity: 1;
  border-color: rgb(159 18 57 / var(--tw-border-opacity));
}

.border-rose-900 {
  --tw-border-opacity: 1;
  border-color: rgb(136 19 55 / var(--tw-border-opacity));
}

.border-rose-950 {
  --tw-border-opacity: 1;
  border-color: rgb(76 5 25 / var(--tw-border-opacity));
}

.border-secondary {
  --tw-border-opacity: 1;
  border-color: rgb(0 70 80 / var(--tw-border-opacity));
}

.border-secondary-text {
  --tw-border-opacity: 1;
  border-color: rgb(124 124 124 / var(--tw-border-opacity));
}

.border-see-through {
  border-color: #fff0;
}

.border-sky-100 {
  --tw-border-opacity: 1;
  border-color: rgb(224 242 254 / var(--tw-border-opacity));
}

.border-sky-200 {
  --tw-border-opacity: 1;
  border-color: rgb(186 230 253 / var(--tw-border-opacity));
}

.border-sky-300 {
  --tw-border-opacity: 1;
  border-color: rgb(125 211 252 / var(--tw-border-opacity));
}

.border-sky-400 {
  --tw-border-opacity: 1;
  border-color: rgb(56 189 248 / var(--tw-border-opacity));
}

.border-sky-50 {
  --tw-border-opacity: 1;
  border-color: rgb(240 249 255 / var(--tw-border-opacity));
}

.border-sky-500 {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

.border-sky-600 {
  --tw-border-opacity: 1;
  border-color: rgb(2 132 199 / var(--tw-border-opacity));
}

.border-sky-700 {
  --tw-border-opacity: 1;
  border-color: rgb(3 105 161 / var(--tw-border-opacity));
}

.border-sky-800 {
  --tw-border-opacity: 1;
  border-color: rgb(7 89 133 / var(--tw-border-opacity));
}

.border-sky-900 {
  --tw-border-opacity: 1;
  border-color: rgb(12 74 110 / var(--tw-border-opacity));
}

.border-sky-950 {
  --tw-border-opacity: 1;
  border-color: rgb(8 47 73 / var(--tw-border-opacity));
}

.border-slate-100 {
  --tw-border-opacity: 1;
  border-color: rgb(241 245 249 / var(--tw-border-opacity));
}

.border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.border-slate-400 {
  --tw-border-opacity: 1;
  border-color: rgb(148 163 184 / var(--tw-border-opacity));
}

.border-slate-50 {
  --tw-border-opacity: 1;
  border-color: rgb(248 250 252 / var(--tw-border-opacity));
}

.border-slate-500 {
  --tw-border-opacity: 1;
  border-color: rgb(100 116 139 / var(--tw-border-opacity));
}

.border-slate-600 {
  --tw-border-opacity: 1;
  border-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.border-slate-700 {
  --tw-border-opacity: 1;
  border-color: rgb(51 65 85 / var(--tw-border-opacity));
}

.border-slate-800 {
  --tw-border-opacity: 1;
  border-color: rgb(30 41 59 / var(--tw-border-opacity));
}

.border-slate-900 {
  --tw-border-opacity: 1;
  border-color: rgb(15 23 42 / var(--tw-border-opacity));
}

.border-slate-950 {
  --tw-border-opacity: 1;
  border-color: rgb(2 6 23 / var(--tw-border-opacity));
}

.border-stone-100 {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 244 / var(--tw-border-opacity));
}

.border-stone-200 {
  --tw-border-opacity: 1;
  border-color: rgb(231 229 228 / var(--tw-border-opacity));
}

.border-stone-300 {
  --tw-border-opacity: 1;
  border-color: rgb(214 211 209 / var(--tw-border-opacity));
}

.border-stone-400 {
  --tw-border-opacity: 1;
  border-color: rgb(168 162 158 / var(--tw-border-opacity));
}

.border-stone-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 249 / var(--tw-border-opacity));
}

.border-stone-500 {
  --tw-border-opacity: 1;
  border-color: rgb(120 113 108 / var(--tw-border-opacity));
}

.border-stone-600 {
  --tw-border-opacity: 1;
  border-color: rgb(87 83 78 / var(--tw-border-opacity));
}

.border-stone-700 {
  --tw-border-opacity: 1;
  border-color: rgb(68 64 60 / var(--tw-border-opacity));
}

.border-stone-800 {
  --tw-border-opacity: 1;
  border-color: rgb(41 37 36 / var(--tw-border-opacity));
}

.border-stone-900 {
  --tw-border-opacity: 1;
  border-color: rgb(28 25 23 / var(--tw-border-opacity));
}

.border-stone-950 {
  --tw-border-opacity: 1;
  border-color: rgb(12 10 9 / var(--tw-border-opacity));
}

.border-table-border {
  --tw-border-opacity: 1;
  border-color: rgb(224 224 224 / var(--tw-border-opacity));
}

.border-table-row {
  --tw-border-opacity: 1;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
}

.border-tag {
  --tw-border-opacity: 1;
  border-color: rgb(254 236 230 / var(--tw-border-opacity));
}

.border-teal {
  --tw-border-opacity: 1;
  border-color: rgb(0 185 174 / var(--tw-border-opacity));
}

.border-transparent {
  border-color: #ffffffb2;
}

.border-violet-100 {
  --tw-border-opacity: 1;
  border-color: rgb(237 233 254 / var(--tw-border-opacity));
}

.border-violet-200 {
  --tw-border-opacity: 1;
  border-color: rgb(221 214 254 / var(--tw-border-opacity));
}

.border-violet-300 {
  --tw-border-opacity: 1;
  border-color: rgb(196 181 253 / var(--tw-border-opacity));
}

.border-violet-400 {
  --tw-border-opacity: 1;
  border-color: rgb(167 139 250 / var(--tw-border-opacity));
}

.border-violet-50 {
  --tw-border-opacity: 1;
  border-color: rgb(245 243 255 / var(--tw-border-opacity));
}

.border-violet-500 {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

.border-violet-600 {
  --tw-border-opacity: 1;
  border-color: rgb(124 58 237 / var(--tw-border-opacity));
}

.border-violet-700 {
  --tw-border-opacity: 1;
  border-color: rgb(109 40 217 / var(--tw-border-opacity));
}

.border-violet-800 {
  --tw-border-opacity: 1;
  border-color: rgb(91 33 182 / var(--tw-border-opacity));
}

.border-violet-900 {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

.border-violet-950 {
  --tw-border-opacity: 1;
  border-color: rgb(46 16 101 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgb(254 249 195 / var(--tw-border-opacity));
}

.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 240 138 / var(--tw-border-opacity));
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 224 71 / var(--tw-border-opacity));
}

.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgb(250 204 21 / var(--tw-border-opacity));
}

.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgb(254 252 232 / var(--tw-border-opacity));
}

.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgb(202 138 4 / var(--tw-border-opacity));
}

.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgb(161 98 7 / var(--tw-border-opacity));
}

.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgb(133 77 14 / var(--tw-border-opacity));
}

.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgb(113 63 18 / var(--tw-border-opacity));
}

.border-yellow-950 {
  --tw-border-opacity: 1;
  border-color: rgb(66 32 6 / var(--tw-border-opacity));
}

.border-zinc-100 {
  --tw-border-opacity: 1;
  border-color: rgb(244 244 245 / var(--tw-border-opacity));
}

.border-zinc-200 {
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
}

.border-zinc-300 {
  --tw-border-opacity: 1;
  border-color: rgb(212 212 216 / var(--tw-border-opacity));
}

.border-zinc-400 {
  --tw-border-opacity: 1;
  border-color: rgb(161 161 170 / var(--tw-border-opacity));
}

.border-zinc-50 {
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
}

.border-zinc-500 {
  --tw-border-opacity: 1;
  border-color: rgb(113 113 122 / var(--tw-border-opacity));
}

.border-zinc-600 {
  --tw-border-opacity: 1;
  border-color: rgb(82 82 91 / var(--tw-border-opacity));
}

.border-zinc-700 {
  --tw-border-opacity: 1;
  border-color: rgb(63 63 70 / var(--tw-border-opacity));
}

.border-zinc-800 {
  --tw-border-opacity: 1;
  border-color: rgb(39 39 42 / var(--tw-border-opacity));
}

.border-zinc-900 {
  --tw-border-opacity: 1;
  border-color: rgb(24 24 27 / var(--tw-border-opacity));
}

.border-zinc-950 {
  --tw-border-opacity: 1;
  border-color: rgb(9 9 11 / var(--tw-border-opacity));
}

.\!bg-\[\#00263E00\] {
  background-color: #00263e00 !important;
}

.\!bg-\[\#080505\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(8 5 5 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#0A1B26\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(10 27 38 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#100C16\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 12 22 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#29BAD1\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(41 186 209 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#2B5C45D9\] {
  background-color: #2b5c45d9 !important;
}

.\!bg-\[\#AA95AC\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(170 149 172 / var(--tw-bg-opacity)) !important;
}

.\!bg-\[\#FEC7B2\] {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 199 178 / var(--tw-bg-opacity)) !important;
}

.bg-\[\#00464F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 79 / var(--tw-bg-opacity));
}

.bg-\[\#00B8B0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 184 176 / var(--tw-bg-opacity));
}

.bg-\[\#01E6AF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(1 230 175 / var(--tw-bg-opacity));
}

.bg-\[\#1662AD\] {
  --tw-bg-opacity: 1;
  background-color: rgb(22 98 173 / var(--tw-bg-opacity));
}

.bg-\[\#1F2A4F\] {
  --tw-bg-opacity: 1;
  background-color: rgb(31 42 79 / var(--tw-bg-opacity));
}

.bg-\[\#29BAD1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(41 186 209 / var(--tw-bg-opacity));
}

.bg-\[\#2C5C45\] {
  --tw-bg-opacity: 1;
  background-color: rgb(44 92 69 / var(--tw-bg-opacity));
}

.bg-\[\#394A58\] {
  --tw-bg-opacity: 1;
  background-color: rgb(57 74 88 / var(--tw-bg-opacity));
}

.bg-\[\#41BABF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(65 186 191 / var(--tw-bg-opacity));
}

.bg-\[\#5DCAE2\] {
  --tw-bg-opacity: 1;
  background-color: rgb(93 202 226 / var(--tw-bg-opacity));
}

.bg-\[\#603E83\] {
  --tw-bg-opacity: 1;
  background-color: rgb(96 62 131 / var(--tw-bg-opacity));
}

.bg-\[\#691E5A\] {
  --tw-bg-opacity: 1;
  background-color: rgb(105 30 90 / var(--tw-bg-opacity));
}

.bg-\[\#7F001B\] {
  --tw-bg-opacity: 1;
  background-color: rgb(127 0 27 / var(--tw-bg-opacity));
}

.bg-\[\#80378D\] {
  --tw-bg-opacity: 1;
  background-color: rgb(128 55 141 / var(--tw-bg-opacity));
}

.bg-\[\#B7DB57\] {
  --tw-bg-opacity: 1;
  background-color: rgb(183 219 87 / var(--tw-bg-opacity));
}

.bg-\[\#BE1373\] {
  --tw-bg-opacity: 1;
  background-color: rgb(190 19 115 / var(--tw-bg-opacity));
}

.bg-\[\#D5DEDA\] {
  --tw-bg-opacity: 1;
  background-color: rgb(213 222 218 / var(--tw-bg-opacity));
}

.bg-\[\#D8D8D8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(216 216 216 / var(--tw-bg-opacity));
}

.bg-\[\#DC0341\] {
  --tw-bg-opacity: 1;
  background-color: rgb(220 3 65 / var(--tw-bg-opacity));
}

.bg-\[\#E12832\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 40 50 / var(--tw-bg-opacity));
}

.bg-\[\#E1D9CE\] {
  --tw-bg-opacity: 1;
  background-color: rgb(225 217 206 / var(--tw-bg-opacity));
}

.bg-\[\#E50941\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 9 65 / var(--tw-bg-opacity));
}

.bg-\[\#E50A42\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 10 66 / var(--tw-bg-opacity));
}

.bg-\[\#EBE6E1\] {
  --tw-bg-opacity: 1;
  background-color: rgb(235 230 225 / var(--tw-bg-opacity));
}

.bg-\[\#F0F0F0\] {
  --tw-bg-opacity: 1;
  background-color: rgb(240 240 240 / var(--tw-bg-opacity));
}

.bg-\[\#F37F91\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 127 145 / var(--tw-bg-opacity));
}

.bg-\[\#F3F4F5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 245 / var(--tw-bg-opacity));
}

.bg-\[\#F79641\] {
  --tw-bg-opacity: 1;
  background-color: rgb(247 150 65 / var(--tw-bg-opacity));
}

.bg-\[\#FDBE23\] {
  --tw-bg-opacity: 1;
  background-color: rgb(253 190 35 / var(--tw-bg-opacity));
}

.bg-\[\#FF0034\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 52 / var(--tw-bg-opacity));
}

.bg-\[\#FF3366\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 51 102 / var(--tw-bg-opacity));
}

.bg-\[\#FF5501\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 85 1 / var(--tw-bg-opacity));
}

.bg-\[\#FFFFFF\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-amber-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

.bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.bg-amber-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity));
}

.bg-amber-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity));
}

.bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.bg-amber-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

.bg-amber-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity));
}

.bg-amber-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity));
}

.bg-amber-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity));
}

.bg-amber-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity));
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity));
}

.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity));
}

.bg-blue-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity));
}

.bg-btn-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-content-image {
  --tw-bg-opacity: 1;
  background-color: rgb(158 201 217 / var(--tw-bg-opacity));
}

.bg-current {
  background-color: currentColor;
}

.bg-cyan-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

.bg-cyan-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity));
}

.bg-cyan-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity));
}

.bg-cyan-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

.bg-cyan-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-cyan-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity));
}

.bg-cyan-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity));
}

.bg-cyan-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity));
}

.bg-dark-red {
  --tw-bg-opacity: 1;
  background-color: rgb(209 60 13 / var(--tw-bg-opacity));
}

.bg-emerald-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

.bg-emerald-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity));
}

.bg-emerald-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

.bg-emerald-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

.bg-emerald-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity));
}

.bg-emerald-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity));
}

.bg-emerald-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity));
}

.bg-emerald-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity));
}

.bg-error {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-fuchsia-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

.bg-fuchsia-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity));
}

.bg-fuchsia-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity));
}

.bg-fuchsia-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity));
}

.bg-fuchsia-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

.bg-fuchsia-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

.bg-fuchsia-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

.bg-fuchsia-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity));
}

.bg-fuchsia-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity));
}

.bg-fuchsia-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity));
}

.bg-gradient-orange-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(229 89 40 / var(--tw-bg-opacity));
}

.bg-gradient-orange-light {
  --tw-bg-opacity: 1;
  background-color: rgb(232 108 77 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}

.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
}

.bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity));
}

.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
}

.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity));
}

.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity));
}

.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity));
}

.bg-green-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity));
}

.bg-grey {
  --tw-bg-opacity: 1;
  background-color: rgb(50 50 50 / var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity));
}

.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity));
}

.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

.bg-indigo-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity));
}

.bg-inherit {
  background-color: inherit;
}

.bg-lime-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

.bg-lime-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

.bg-lime-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity));
}

.bg-lime-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity));
}

.bg-lime-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity));
}

.bg-lime-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

.bg-lime-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

.bg-lime-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity));
}

.bg-lime-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity));
}

.bg-lime-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity));
}

.bg-lime-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity));
}

.bg-neutral-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-neutral-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-neutral-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity));
}

.bg-neutral-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity));
}

.bg-neutral-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-neutral-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity));
}

.bg-neutral-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity));
}

.bg-neutral-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity));
}

.bg-neutral-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity));
}

.bg-neutral-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity));
}

.bg-neutral-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
}

.bg-overlay {
  --tw-bg-opacity: 1;
  background-color: rgb(11 34 69 / var(--tw-bg-opacity));
}

.bg-partial-white {
  background-color: #ffffff70;
}

.bg-pastel-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(164 220 232 / var(--tw-bg-opacity));
}

.bg-pastel-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(255 142 106 / var(--tw-bg-opacity));
}

.bg-pastel-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(159 163 208 / var(--tw-bg-opacity));
}

.bg-pastel-teal {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
}

.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity));
}

.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity));
}

.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity));
}

.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity));
}

.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity));
}

.bg-pink-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.bg-primary-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(14 18 25 / var(--tw-bg-opacity));
}

.bg-primary-transparent {
  background-color: #262c3854;
}

.bg-primary-very-transparent {
  background-color: #262c3820;
}

.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity));
}

.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity));
}

.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity));
}

.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.bg-purple-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(255 0 0 / var(--tw-bg-opacity));
}

.bg-required {
  --tw-bg-opacity: 1;
  background-color: rgb(220 40 40 / var(--tw-bg-opacity));
}

.bg-rose-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

.bg-rose-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

.bg-rose-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity));
}

.bg-rose-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity));
}

.bg-rose-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity));
}

.bg-rose-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

.bg-rose-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

.bg-rose-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity));
}

.bg-rose-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity));
}

.bg-rose-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity));
}

.bg-rose-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.bg-secondary-text {
  --tw-bg-opacity: 1;
  background-color: rgb(124 124 124 / var(--tw-bg-opacity));
}

.bg-see-through {
  background-color: #fff0;
}

.bg-sky-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

.bg-sky-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

.bg-sky-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity));
}

.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}

.bg-sky-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity));
}

.bg-sky-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

.bg-sky-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

.bg-sky-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity));
}

.bg-sky-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity));
}

.bg-sky-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity));
}

.bg-sky-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity));
}

.bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}

.bg-slate-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}

.bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.bg-slate-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
}

.bg-slate-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-slate-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity));
}

.bg-stone-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity));
}

.bg-stone-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity));
}

.bg-stone-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity));
}

.bg-stone-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity));
}

.bg-stone-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity));
}

.bg-stone-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity));
}

.bg-stone-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity));
}

.bg-stone-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity));
}

.bg-stone-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity));
}

.bg-stone-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity));
}

.bg-stone-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity));
}

.bg-table-border {
  --tw-bg-opacity: 1;
  background-color: rgb(224 224 224 / var(--tw-bg-opacity));
}

.bg-table-row {
  --tw-bg-opacity: 1;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity));
}

.bg-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 230 / var(--tw-bg-opacity));
}

.bg-teal {
  --tw-bg-opacity: 1;
  background-color: rgb(0 185 174 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #ffffffb2;
}

.bg-violet-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

.bg-violet-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

.bg-violet-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity));
}

.bg-violet-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity));
}

.bg-violet-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity));
}

.bg-violet-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

.bg-violet-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

.bg-violet-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

.bg-violet-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity));
}

.bg-violet-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

.bg-violet-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity));
}

.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity));
}

.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(133 77 14 / var(--tw-bg-opacity));
}

.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity));
}

.bg-yellow-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity));
}

.bg-zinc-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity));
}

.bg-zinc-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity));
}

.bg-zinc-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity));
}

.bg-zinc-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity));
}

.bg-zinc-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
}

.bg-zinc-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity));
}

.bg-zinc-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity));
}

.bg-zinc-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity));
}

.bg-zinc-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity));
}

.bg-zinc-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity));
}

.bg-zinc-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity));
}

.bg-\[url\(\'\.\.\/\.\.\/static\/img\/client-imagery\/whizz-kidz\/hero-detail\.png\'\)\] {
  background-image: url("hero-detail.0780f8cc.png");
}

.bg-\[url\(\'\.\.\/\.\.\/static\/img\/client-imagery\/whizz-kidz\/hero\.jpg\'\)\] {
  background-image: url("hero.a7fa6b08.jpg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.\!from-\[\#080505\] {
  --tw-gradient-from: #080505 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #08050500 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#0A1B26\] {
  --tw-gradient-from: #0a1b26 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #0a1b2600 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#100C16\] {
  --tw-gradient-from: #100c16 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #100c1600 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#2B5C45D9\] {
  --tw-gradient-from: #2b5c45d9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #2b5c4500 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#AA95AC\] {
  --tw-gradient-from: #aa95ac var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #aa95ac00 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#E1D9CE\] {
  --tw-gradient-from: #e1d9ce var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #e1d9ce00 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.\!from-\[\#FEC7B2\] {
  --tw-gradient-from: #fec7b2 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: #fec7b200 var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.from-\[\#0D0C0B\] {
  --tw-gradient-from: #0d0c0b var(--tw-gradient-from-position);
  --tw-gradient-to: #0d0c0b00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#2B5C45\] {
  --tw-gradient-from: #2b5c45 var(--tw-gradient-from-position);
  --tw-gradient-to: #2b5c4500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#979089\] {
  --tw-gradient-from: #979089 var(--tw-gradient-from-position);
  --tw-gradient-to: #97908900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#A59C84\] {
  --tw-gradient-from: #a59c84 var(--tw-gradient-from-position);
  --tw-gradient-to: #a59c8400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#F0BAFA\] {
  --tw-gradient-from: #f0bafa var(--tw-gradient-from-position);
  --tw-gradient-to: #f0bafa00 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.via-\[\#F6D1E4\] {
  --tw-gradient-to: #f6d1e400 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #f6d1e4 var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.via-\[51\%\] {
  --tw-gradient-via-position: 51%;
}

.to-\[\#FDE8AA\] {
  --tw-gradient-to: #fde8aa var(--tw-gradient-to-position);
}

.\!bg-\[length\:100\%_100\%\] {
  background-size: 100% 100% !important;
}

.\!bg-\[length\:auto_100\%\] {
  background-size: auto 100% !important;
}

.bg-cover {
  background-size: cover;
}

.\!bg-bottom {
  background-position: bottom !important;
}

.\!bg-left-top {
  background-position: 0 0 !important;
}

.\!bg-right-top {
  background-position: 100% 0 !important;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.fill-current {
  fill: currentColor;
}

.p-0 {
  padding: 0;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.p-2 {
  padding: .5rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-3 {
  padding: .75rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-4 {
  padding: 1rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-72 {
  padding: 18rem;
}

.p-8 {
  padding: 2rem;
}

.p-80 {
  padding: 20rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.\!py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.\!py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.\!py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.\!py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.\!py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.\!py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.\!py-52 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.\!py-\[11rem\] {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.\!py-\[6rem\] {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.\!py-\[9rem\] {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.\!pb-0 {
  padding-bottom: 0 !important;
}

.\!pb-2 {
  padding-bottom: .5rem !important;
}

.\!pb-20 {
  padding-bottom: 5rem !important;
}

.\!pb-24 {
  padding-bottom: 6rem !important;
}

.\!pb-32 {
  padding-bottom: 8rem !important;
}

.\!pb-40 {
  padding-bottom: 10rem !important;
}

.\!pb-\[5\.75rem\] {
  padding-bottom: 5.75rem !important;
}

.\!pb-\[5vw\] {
  padding-bottom: 5vw !important;
}

.\!pb-\[6rem\] {
  padding-bottom: 6rem !important;
}

.\!pr-\[2vw\] {
  padding-right: 2vw !important;
}

.\!pt-0 {
  padding-top: 0 !important;
}

.\!pt-16 {
  padding-top: 4rem !important;
}

.\!pt-24 {
  padding-top: 6rem !important;
}

.\!pt-36 {
  padding-top: 9rem !important;
}

.\!pt-40 {
  padding-top: 10rem !important;
}

.\!pt-5 {
  padding-top: 1.25rem !important;
}

.\!pt-\[2\.25rem\] {
  padding-top: 2.25rem !important;
}

.\!pt-\[3rem\] {
  padding-top: 3rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-\[2\.78vw\] {
  padding-left: 2.78vw;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-2xl {
  font-size: 3.2rem;
}

.text-3xl {
  font-size: 4rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-base {
  font-size: 20px;
}

.text-intro {
  font-size: 1.2rem;
}

.text-l {
  font-size: 1.6rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .9rem;
}

.text-x-sm {
  font-size: .8rem;
}

.text-xl {
  font-size: 2rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-black {
  font-weight: 900;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-extralight {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-thin {
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.leading-intro {
  line-height: 1.333;
}

.leading-normal {
  line-height: 1.4;
}

.\!text-\[\#00263E\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 38 62 / var(--tw-text-opacity)) !important;
}

.\!text-\[\#00464F\] {
  --tw-text-opacity: 1 !important;
  color: rgb(0 70 79 / var(--tw-text-opacity)) !important;
}

.\!text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-amber-100 {
  --tw-text-opacity: 1;
  color: rgb(254 243 199 / var(--tw-text-opacity));
}

.text-amber-200 {
  --tw-text-opacity: 1;
  color: rgb(253 230 138 / var(--tw-text-opacity));
}

.text-amber-300 {
  --tw-text-opacity: 1;
  color: rgb(252 211 77 / var(--tw-text-opacity));
}

.text-amber-400 {
  --tw-text-opacity: 1;
  color: rgb(251 191 36 / var(--tw-text-opacity));
}

.text-amber-50 {
  --tw-text-opacity: 1;
  color: rgb(255 251 235 / var(--tw-text-opacity));
}

.text-amber-500 {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

.text-amber-600 {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

.text-amber-700 {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

.text-amber-800 {
  --tw-text-opacity: 1;
  color: rgb(146 64 14 / var(--tw-text-opacity));
}

.text-amber-900 {
  --tw-text-opacity: 1;
  color: rgb(120 53 15 / var(--tw-text-opacity));
}

.text-amber-950 {
  --tw-text-opacity: 1;
  color: rgb(69 26 3 / var(--tw-text-opacity));
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgb(219 234 254 / var(--tw-text-opacity));
}

.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgb(191 219 254 / var(--tw-text-opacity));
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(147 197 253 / var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgb(239 246 255 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity));
}

.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(30 58 138 / var(--tw-text-opacity));
}

.text-blue-950 {
  --tw-text-opacity: 1;
  color: rgb(23 37 84 / var(--tw-text-opacity));
}

.text-btn-grey {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-content-image {
  --tw-text-opacity: 1;
  color: rgb(158 201 217 / var(--tw-text-opacity));
}

.text-current {
  color: currentColor;
}

.text-cyan-100 {
  --tw-text-opacity: 1;
  color: rgb(207 250 254 / var(--tw-text-opacity));
}

.text-cyan-200 {
  --tw-text-opacity: 1;
  color: rgb(165 243 252 / var(--tw-text-opacity));
}

.text-cyan-300 {
  --tw-text-opacity: 1;
  color: rgb(103 232 249 / var(--tw-text-opacity));
}

.text-cyan-400 {
  --tw-text-opacity: 1;
  color: rgb(34 211 238 / var(--tw-text-opacity));
}

.text-cyan-50 {
  --tw-text-opacity: 1;
  color: rgb(236 254 255 / var(--tw-text-opacity));
}

.text-cyan-500 {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

.text-cyan-600 {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

.text-cyan-700 {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

.text-cyan-800 {
  --tw-text-opacity: 1;
  color: rgb(21 94 117 / var(--tw-text-opacity));
}

.text-cyan-900 {
  --tw-text-opacity: 1;
  color: rgb(22 78 99 / var(--tw-text-opacity));
}

.text-cyan-950 {
  --tw-text-opacity: 1;
  color: rgb(8 51 68 / var(--tw-text-opacity));
}

.text-dark-red {
  --tw-text-opacity: 1;
  color: rgb(209 60 13 / var(--tw-text-opacity));
}

.text-emerald-100 {
  --tw-text-opacity: 1;
  color: rgb(209 250 229 / var(--tw-text-opacity));
}

.text-emerald-200 {
  --tw-text-opacity: 1;
  color: rgb(167 243 208 / var(--tw-text-opacity));
}

.text-emerald-300 {
  --tw-text-opacity: 1;
  color: rgb(110 231 183 / var(--tw-text-opacity));
}

.text-emerald-400 {
  --tw-text-opacity: 1;
  color: rgb(52 211 153 / var(--tw-text-opacity));
}

.text-emerald-50 {
  --tw-text-opacity: 1;
  color: rgb(236 253 245 / var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

.text-emerald-600 {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

.text-emerald-700 {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

.text-emerald-800 {
  --tw-text-opacity: 1;
  color: rgb(6 95 70 / var(--tw-text-opacity));
}

.text-emerald-900 {
  --tw-text-opacity: 1;
  color: rgb(6 78 59 / var(--tw-text-opacity));
}

.text-emerald-950 {
  --tw-text-opacity: 1;
  color: rgb(2 44 34 / var(--tw-text-opacity));
}

.text-error {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-fuchsia-100 {
  --tw-text-opacity: 1;
  color: rgb(250 232 255 / var(--tw-text-opacity));
}

.text-fuchsia-200 {
  --tw-text-opacity: 1;
  color: rgb(245 208 254 / var(--tw-text-opacity));
}

.text-fuchsia-300 {
  --tw-text-opacity: 1;
  color: rgb(240 171 252 / var(--tw-text-opacity));
}

.text-fuchsia-400 {
  --tw-text-opacity: 1;
  color: rgb(232 121 249 / var(--tw-text-opacity));
}

.text-fuchsia-50 {
  --tw-text-opacity: 1;
  color: rgb(253 244 255 / var(--tw-text-opacity));
}

.text-fuchsia-500 {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

.text-fuchsia-600 {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

.text-fuchsia-700 {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

.text-fuchsia-800 {
  --tw-text-opacity: 1;
  color: rgb(134 25 143 / var(--tw-text-opacity));
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-fuchsia-950 {
  --tw-text-opacity: 1;
  color: rgb(74 4 78 / var(--tw-text-opacity));
}

.text-gradient-orange-dark {
  --tw-text-opacity: 1;
  color: rgb(229 89 40 / var(--tw-text-opacity));
}

.text-gradient-orange-light {
  --tw-text-opacity: 1;
  color: rgb(232 108 77 / var(--tw-text-opacity));
}

.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(209 213 219 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgb(249 250 251 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

.text-gray-950 {
  --tw-text-opacity: 1;
  color: rgb(3 7 18 / var(--tw-text-opacity));
}

.text-green-100 {
  --tw-text-opacity: 1;
  color: rgb(220 252 231 / var(--tw-text-opacity));
}

.text-green-200 {
  --tw-text-opacity: 1;
  color: rgb(187 247 208 / var(--tw-text-opacity));
}

.text-green-300 {
  --tw-text-opacity: 1;
  color: rgb(134 239 172 / var(--tw-text-opacity));
}

.text-green-400 {
  --tw-text-opacity: 1;
  color: rgb(74 222 128 / var(--tw-text-opacity));
}

.text-green-50 {
  --tw-text-opacity: 1;
  color: rgb(240 253 244 / var(--tw-text-opacity));
}

.text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

.text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.text-green-700 {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(22 101 52 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-green-950 {
  --tw-text-opacity: 1;
  color: rgb(5 46 22 / var(--tw-text-opacity));
}

.text-grey {
  --tw-text-opacity: 1;
  color: rgb(50 50 50 / var(--tw-text-opacity));
}

.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgb(224 231 255 / var(--tw-text-opacity));
}

.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgb(199 210 254 / var(--tw-text-opacity));
}

.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgb(165 180 252 / var(--tw-text-opacity));
}

.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgb(129 140 248 / var(--tw-text-opacity));
}

.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgb(238 242 255 / var(--tw-text-opacity));
}

.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgb(55 48 163 / var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgb(49 46 129 / var(--tw-text-opacity));
}

.text-indigo-950 {
  --tw-text-opacity: 1;
  color: rgb(30 27 75 / var(--tw-text-opacity));
}

.text-inherit {
  color: inherit;
}

.text-lime-100 {
  --tw-text-opacity: 1;
  color: rgb(236 252 203 / var(--tw-text-opacity));
}

.text-lime-200 {
  --tw-text-opacity: 1;
  color: rgb(217 249 157 / var(--tw-text-opacity));
}

.text-lime-300 {
  --tw-text-opacity: 1;
  color: rgb(190 242 100 / var(--tw-text-opacity));
}

.text-lime-400 {
  --tw-text-opacity: 1;
  color: rgb(163 230 53 / var(--tw-text-opacity));
}

.text-lime-50 {
  --tw-text-opacity: 1;
  color: rgb(247 254 231 / var(--tw-text-opacity));
}

.text-lime-500 {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

.text-lime-600 {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

.text-lime-700 {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

.text-lime-800 {
  --tw-text-opacity: 1;
  color: rgb(63 98 18 / var(--tw-text-opacity));
}

.text-lime-900 {
  --tw-text-opacity: 1;
  color: rgb(54 83 20 / var(--tw-text-opacity));
}

.text-lime-950 {
  --tw-text-opacity: 1;
  color: rgb(26 46 5 / var(--tw-text-opacity));
}

.text-neutral-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-neutral-200 {
  --tw-text-opacity: 1;
  color: rgb(229 229 229 / var(--tw-text-opacity));
}

.text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 212 / var(--tw-text-opacity));
}

.text-neutral-400 {
  --tw-text-opacity: 1;
  color: rgb(163 163 163 / var(--tw-text-opacity));
}

.text-neutral-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(115 115 115 / var(--tw-text-opacity));
}

.text-neutral-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 82 / var(--tw-text-opacity));
}

.text-neutral-700 {
  --tw-text-opacity: 1;
  color: rgb(64 64 64 / var(--tw-text-opacity));
}

.text-neutral-800 {
  --tw-text-opacity: 1;
  color: rgb(38 38 38 / var(--tw-text-opacity));
}

.text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(23 23 23 / var(--tw-text-opacity));
}

.text-neutral-950 {
  --tw-text-opacity: 1;
  color: rgb(10 10 10 / var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(241 89 41 / var(--tw-text-opacity));
}

.text-overlay {
  --tw-text-opacity: 1;
  color: rgb(11 34 69 / var(--tw-text-opacity));
}

.text-partial-white {
  color: #ffffff70;
}

.text-pastel-blue {
  --tw-text-opacity: 1;
  color: rgb(164 220 232 / var(--tw-text-opacity));
}

.text-pastel-orange {
  --tw-text-opacity: 1;
  color: rgb(255 142 106 / var(--tw-text-opacity));
}

.text-pastel-purple {
  --tw-text-opacity: 1;
  color: rgb(159 163 208 / var(--tw-text-opacity));
}

.text-pastel-teal {
  --tw-text-opacity: 1;
  color: rgb(102 224 203 / var(--tw-text-opacity));
}

.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgb(252 231 243 / var(--tw-text-opacity));
}

.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgb(251 207 232 / var(--tw-text-opacity));
}

.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgb(249 168 212 / var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgb(244 114 182 / var(--tw-text-opacity));
}

.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgb(253 242 248 / var(--tw-text-opacity));
}

.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgb(157 23 77 / var(--tw-text-opacity));
}

.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(131 24 67 / var(--tw-text-opacity));
}

.text-pink-950 {
  --tw-text-opacity: 1;
  color: rgb(80 7 36 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.text-primary-dark {
  --tw-text-opacity: 1;
  color: rgb(14 18 25 / var(--tw-text-opacity));
}

.text-primary-transparent {
  color: #262c3854;
}

.text-primary-very-transparent {
  color: #262c3820;
}

.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgb(243 232 255 / var(--tw-text-opacity));
}

.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(233 213 255 / var(--tw-text-opacity));
}

.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(216 180 254 / var(--tw-text-opacity));
}

.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgb(192 132 252 / var(--tw-text-opacity));
}

.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgb(250 245 255 / var(--tw-text-opacity));
}

.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(107 33 168 / var(--tw-text-opacity));
}

.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(88 28 135 / var(--tw-text-opacity));
}

.text-purple-950 {
  --tw-text-opacity: 1;
  color: rgb(59 7 100 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
}

.text-required {
  --tw-text-opacity: 1;
  color: rgb(220 40 40 / var(--tw-text-opacity));
}

.text-rose-100 {
  --tw-text-opacity: 1;
  color: rgb(255 228 230 / var(--tw-text-opacity));
}

.text-rose-200 {
  --tw-text-opacity: 1;
  color: rgb(254 205 211 / var(--tw-text-opacity));
}

.text-rose-300 {
  --tw-text-opacity: 1;
  color: rgb(253 164 175 / var(--tw-text-opacity));
}

.text-rose-400 {
  --tw-text-opacity: 1;
  color: rgb(251 113 133 / var(--tw-text-opacity));
}

.text-rose-50 {
  --tw-text-opacity: 1;
  color: rgb(255 241 242 / var(--tw-text-opacity));
}

.text-rose-500 {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

.text-rose-700 {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

.text-rose-800 {
  --tw-text-opacity: 1;
  color: rgb(159 18 57 / var(--tw-text-opacity));
}

.text-rose-900 {
  --tw-text-opacity: 1;
  color: rgb(136 19 55 / var(--tw-text-opacity));
}

.text-rose-950 {
  --tw-text-opacity: 1;
  color: rgb(76 5 25 / var(--tw-text-opacity));
}

.text-secondary {
  --tw-text-opacity: 1;
  color: rgb(0 70 80 / var(--tw-text-opacity));
}

.text-secondary-text {
  --tw-text-opacity: 1;
  color: rgb(124 124 124 / var(--tw-text-opacity));
}

.text-see-through {
  color: #fff0;
}

.text-sky-100 {
  --tw-text-opacity: 1;
  color: rgb(224 242 254 / var(--tw-text-opacity));
}

.text-sky-200 {
  --tw-text-opacity: 1;
  color: rgb(186 230 253 / var(--tw-text-opacity));
}

.text-sky-300 {
  --tw-text-opacity: 1;
  color: rgb(125 211 252 / var(--tw-text-opacity));
}

.text-sky-400 {
  --tw-text-opacity: 1;
  color: rgb(56 189 248 / var(--tw-text-opacity));
}

.text-sky-50 {
  --tw-text-opacity: 1;
  color: rgb(240 249 255 / var(--tw-text-opacity));
}

.text-sky-500 {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

.text-sky-600 {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

.text-sky-700 {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

.text-sky-800 {
  --tw-text-opacity: 1;
  color: rgb(7 89 133 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-sky-950 {
  --tw-text-opacity: 1;
  color: rgb(8 47 73 / var(--tw-text-opacity));
}

.text-slate-100 {
  --tw-text-opacity: 1;
  color: rgb(241 245 249 / var(--tw-text-opacity));
}

.text-slate-200 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.text-slate-300 {
  --tw-text-opacity: 1;
  color: rgb(203 213 225 / var(--tw-text-opacity));
}

.text-slate-400 {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.text-slate-50 {
  --tw-text-opacity: 1;
  color: rgb(248 250 252 / var(--tw-text-opacity));
}

.text-slate-500 {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.text-slate-600 {
  --tw-text-opacity: 1;
  color: rgb(71 85 105 / var(--tw-text-opacity));
}

.text-slate-700 {
  --tw-text-opacity: 1;
  color: rgb(51 65 85 / var(--tw-text-opacity));
}

.text-slate-800 {
  --tw-text-opacity: 1;
  color: rgb(30 41 59 / var(--tw-text-opacity));
}

.text-slate-900 {
  --tw-text-opacity: 1;
  color: rgb(15 23 42 / var(--tw-text-opacity));
}

.text-slate-950 {
  --tw-text-opacity: 1;
  color: rgb(2 6 23 / var(--tw-text-opacity));
}

.text-stone-100 {
  --tw-text-opacity: 1;
  color: rgb(245 245 244 / var(--tw-text-opacity));
}

.text-stone-200 {
  --tw-text-opacity: 1;
  color: rgb(231 229 228 / var(--tw-text-opacity));
}

.text-stone-300 {
  --tw-text-opacity: 1;
  color: rgb(214 211 209 / var(--tw-text-opacity));
}

.text-stone-400 {
  --tw-text-opacity: 1;
  color: rgb(168 162 158 / var(--tw-text-opacity));
}

.text-stone-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 249 / var(--tw-text-opacity));
}

.text-stone-500 {
  --tw-text-opacity: 1;
  color: rgb(120 113 108 / var(--tw-text-opacity));
}

.text-stone-600 {
  --tw-text-opacity: 1;
  color: rgb(87 83 78 / var(--tw-text-opacity));
}

.text-stone-700 {
  --tw-text-opacity: 1;
  color: rgb(68 64 60 / var(--tw-text-opacity));
}

.text-stone-800 {
  --tw-text-opacity: 1;
  color: rgb(41 37 36 / var(--tw-text-opacity));
}

.text-stone-900 {
  --tw-text-opacity: 1;
  color: rgb(28 25 23 / var(--tw-text-opacity));
}

.text-stone-950 {
  --tw-text-opacity: 1;
  color: rgb(12 10 9 / var(--tw-text-opacity));
}

.text-table-border {
  --tw-text-opacity: 1;
  color: rgb(224 224 224 / var(--tw-text-opacity));
}

.text-table-row {
  --tw-text-opacity: 1;
  color: rgb(245 245 245 / var(--tw-text-opacity));
}

.text-tag {
  --tw-text-opacity: 1;
  color: rgb(254 236 230 / var(--tw-text-opacity));
}

.text-teal {
  --tw-text-opacity: 1;
  color: rgb(0 185 174 / var(--tw-text-opacity));
}

.text-transparent {
  color: #ffffffb2;
}

.text-violet-100 {
  --tw-text-opacity: 1;
  color: rgb(237 233 254 / var(--tw-text-opacity));
}

.text-violet-200 {
  --tw-text-opacity: 1;
  color: rgb(221 214 254 / var(--tw-text-opacity));
}

.text-violet-300 {
  --tw-text-opacity: 1;
  color: rgb(196 181 253 / var(--tw-text-opacity));
}

.text-violet-400 {
  --tw-text-opacity: 1;
  color: rgb(167 139 250 / var(--tw-text-opacity));
}

.text-violet-50 {
  --tw-text-opacity: 1;
  color: rgb(245 243 255 / var(--tw-text-opacity));
}

.text-violet-500 {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

.text-violet-600 {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

.text-violet-700 {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

.text-violet-800 {
  --tw-text-opacity: 1;
  color: rgb(91 33 182 / var(--tw-text-opacity));
}

.text-violet-900 {
  --tw-text-opacity: 1;
  color: rgb(76 29 149 / var(--tw-text-opacity));
}

.text-violet-950 {
  --tw-text-opacity: 1;
  color: rgb(46 16 101 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgb(254 249 195 / var(--tw-text-opacity));
}

.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgb(254 240 138 / var(--tw-text-opacity));
}

.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgb(253 224 71 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(250 204 21 / var(--tw-text-opacity));
}

.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgb(254 252 232 / var(--tw-text-opacity));
}

.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(133 77 14 / var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(113 63 18 / var(--tw-text-opacity));
}

.text-yellow-950 {
  --tw-text-opacity: 1;
  color: rgb(66 32 6 / var(--tw-text-opacity));
}

.text-zinc-100 {
  --tw-text-opacity: 1;
  color: rgb(244 244 245 / var(--tw-text-opacity));
}

.text-zinc-200 {
  --tw-text-opacity: 1;
  color: rgb(228 228 231 / var(--tw-text-opacity));
}

.text-zinc-300 {
  --tw-text-opacity: 1;
  color: rgb(212 212 216 / var(--tw-text-opacity));
}

.text-zinc-400 {
  --tw-text-opacity: 1;
  color: rgb(161 161 170 / var(--tw-text-opacity));
}

.text-zinc-50 {
  --tw-text-opacity: 1;
  color: rgb(250 250 250 / var(--tw-text-opacity));
}

.text-zinc-500 {
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

.text-zinc-600 {
  --tw-text-opacity: 1;
  color: rgb(82 82 91 / var(--tw-text-opacity));
}

.text-zinc-700 {
  --tw-text-opacity: 1;
  color: rgb(63 63 70 / var(--tw-text-opacity));
}

.text-zinc-800 {
  --tw-text-opacity: 1;
  color: rgb(39 39 42 / var(--tw-text-opacity));
}

.text-zinc-900 {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity));
}

.text-zinc-950 {
  --tw-text-opacity: 1;
  color: rgb(9 9 11 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.opacity-90 {
  opacity: .9;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline {
  outline-style: solid;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, blockquote, p, address, hr, fieldset, figure, pre {
  margin: 0;
  padding-bottom: 1rem;
}

h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, ul:last-child, ol:last-child, dl:last-child, blockquote:last-child, p:last-child, address:last-child, hr:last-child, fieldset:last-child, figure:last-child, pre:last-child {
  padding-bottom: 0;
}

ul, ol, dd {
  padding-left: 1rem;
}

html {
  font-size: 20px;
}

body {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato, Helvetica, Arial, sans-serif;
  line-height: 1.4;
}

input, select, textarea, button {
  font: inherit;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
  line-height: 1.25;
}

h1, .h1 {
  font-size: 2rem;
  line-height: 1.2;
}

@media (width >= 769px) {
  h1, .h1 {
    font-size: 3.2rem;
  }
}

h1.large, .h1.large {
  line-height: 1.125;
}

@media (width >= 961px) {
  h1.large, .h1.large {
    font-size: 4rem;
  }
}

h1.small, .h1.small {
  font-size: 2.6rem;
}

h2, .h2 {
  font-size: 1.6rem;
}

@media (width >= 769px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: 1.6rem;
}

h4, .h4, h5, .h5 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

h6, .h6 {
  font-size: 20px;
  line-height: 1;
}

h1, h2, h3, h4, h5, h6, ul, ol, dl, p, blockquote, address {
  margin-bottom: 0;
  padding-bottom: 1rem;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration-line: underline;
}

p + h1, p + h2, p + h3, p + h4, p + h5, p + h6, ol + h1, ol + h2, ol + h3, ol + h4, ol + h5, ol + h6, ul + h1, ul + h2, ul + h3, ul + h4, ul + h5, ul + h6 {
  padding-top: 1rem;
}

p {
  font-size: .9rem;
}

@media (width >= 769px) {
  p {
    font-size: 20px;
  }
}

p.intro {
  font-size: 20px;
}

@media (width >= 769px) {
  p.intro {
    font-size: 1.2rem;
    line-height: 1.333;
  }
}

ol li, ul li {
  padding-bottom: .5rem;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

html {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media only screen and (width >= 1441px) {
  html.campaign-screen {
    font-size: 21.3px;
  }
}

@media (width >= 1025px) {
  html.campaign-screen header {
    padding-top: 4rem;
  }
}

@media (width >= 1440px) {
  html.campaign-screen p {
    font-size: 1rem;
  }
}

@media (width >= 1025px) {
  html.campaign-screen.python-screen header.site-header--fixed {
    padding-top: 1.65rem;
  }
}

html .text-clip-down {
  --tw-translate-x: 0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

html body {
  font-family: Open Sans, Helvetica, Arial, sans-serif;
}

img {
  max-width: 100%;
}

picture {
  max-width: 100%;
  display: block;
}

picture img {
  margin-left: auto;
  margin-right: auto;
}

iframe {
  display: block;
}

hr {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  border-width: 0;
  height: 1px;
  margin-bottom: 1rem;
  padding: 0;
}

i {
  font-size: .8rem;
}

input::placeholder, select::placeholder, textarea::placeholder {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  opacity: 1;
}

input, select, textarea {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  width: 100%;
  padding: .5rem;
  display: block;
}

input[disabled], select[disabled], textarea[disabled] {
  cursor: default;
  opacity: .65;
}

input[type="checkbox"], input[type="radio"] {
  vertical-align: middle;
  margin-right: .5rem;
  display: inline-block;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  width: 1rem !important;
  height: 1rem !important;
}

select {
  padding: .5rem;
}

textarea {
  resize: vertical;
  height: auto;
  min-height: 8rem;
}

label {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  width: 100%;
  padding-bottom: .5rem;
  font-weight: 700;
  display: block;
  position: relative;
}

label:last-child {
  padding-bottom: 0;
}

label span {
  vertical-align: middle;
  display: inline-block;
}

fieldset {
  border-width: 0;
  margin-bottom: 1rem;
  padding: 0;
}

form .required {
  --tw-text-opacity: 1;
  color: rgb(220 40 40 / var(--tw-text-opacity));
}

table {
  border-collapse: collapse;
  --tw-border-spacing-x: 0px;
  --tw-border-spacing-y: 0px;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
  width: 100%;
  padding: 0;
}

table th, table td {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(245 245 245 / var(--tw-border-opacity));
  text-align: left;
  padding: .5rem 1rem;
}

table th {
  font-weight: 700;
}

table tbody tr:nth-child(odd) td {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

table .centered {
  text-align: center;
}

table .right {
  text-align: right;
}

.transition-overlay {
  pointer-events: none;
  z-index: -1;
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.transition-overlay.top {
  -webkit-clip-path: polygon(0% 0, 0% 100%, 0 0);
  clip-path: polygon(0% 0, 0% 100%, 0 0);
}

.transition-overlay.bottom {
  -webkit-clip-path: polygon(100% 0, 100% 100%, 100% 100%);
  clip-path: polygon(100% 0, 100% 100%, 100% 100%);
}

.body-overlay {
  pointer-events: none;
  z-index: -1;
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  opacity: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.body-overlay.init {
  z-index: 9998;
}

@keyframes overlay-out {
  0% {
    opacity: 1;
    z-index: 9998;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
}

.body-overlay.init {
  opacity: 1;
  animation: .8s linear forwards overlay-out;
}

.no-js .body-overlay {
  z-index: -1;
  opacity: 0;
}

.svg-path {
  z-index: -1;
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  stroke-dasharray: 1600;
  stroke-dashoffset: 1600px;
  position: fixed;
  top: 50%;
  left: 50%;
}

.svg-path path {
  stroke: #fff;
  stroke-width: 2px;
}

.text-clip-down, .text-clip-down • {
  -webkit-clip-path: inset(0 0 100%);
  clip-path: inset(0 0 100%);
  animation: 3s cubic-bezier(0, .55, .45, 1) .5s forwards text-clip-down;
}

@keyframes text-clip-down {
  100% {
    -webkit-clip-path: inset(0 0 -50%);
    clip-path: inset(0 0 -50%);
  }
}

.mfp-bg {
  z-index: 1042;
  opacity: .8;
  background: #0b0b0b;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mfp-wrap {
  z-index: 1043;
  -webkit-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  outline: none !important;
}

.mfp-container {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 8px;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-container:before {
  content: "";
  vertical-align: middle;
  height: 100%;
  display: inline-block;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  vertical-align: middle;
  text-align: left;
  z-index: 1045;
  margin: 0 auto;
  display: inline-block;
  position: relative;
}

.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
  cursor: auto;
  width: 100%;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close, .mfp-arrow, .mfp-preloader, .mfp-counter {
  -webkit-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #ccc;
  text-align: center;
  z-index: 1044;
  width: auto;
  margin-top: -.8em;
  position: absolute;
  top: 50%;
  left: 8px;
  right: 8px;
}

.mfp-preloader a {
  color: #ccc;
}

.mfp-preloader a:hover {
  color: #fff;
}

.mfp-s-ready .mfp-preloader, .mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  cursor: pointer;
  -webkit-appearance: none;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
  background: none;
  border: 0;
  outline: none;
  padding: 0;
  display: block;
  overflow: visible;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.mfp-close {
  text-align: center;
  opacity: .65;
  color: #fff;
  width: 44px;
  height: 44px;
  padding: 0 0 18px 10px;
  font-family: Arial, Baskerville, monospace;
  font-size: 28px;
  font-style: normal;
  line-height: 44px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close, .mfp-iframe-holder .mfp-close {
  color: #fff;
  text-align: right;
  width: 100%;
  padding-right: 6px;
  right: -6px;
}

.mfp-counter {
  color: #ccc;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: 0;
}

.mfp-arrow {
  opacity: .65;
  -webkit-tap-highlight-color: transparent;
  width: 90px;
  height: 110px;
  margin: -55px 0 0;
  padding: 0;
  position: absolute;
  top: 50%;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  border: inset #0000;
  width: 0;
  height: 0;
  margin-top: 35px;
  margin-left: 35px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  opacity: .7;
  border-top-width: 21px;
  border-bottom-width: 21px;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #fff;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  border-right: 27px solid #3f3f3f;
  margin-left: 25px;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #fff;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3f3f3f;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  width: 100%;
  max-width: 900px;
  line-height: 0;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;
}

.mfp-iframe-scaler iframe {
  background: #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 8px #0009;
}

img.mfp-img {
  box-sizing: border-box;
  width: auto;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 40px 0;
  line-height: 0;
  display: block;
}

.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  z-index: -1;
  background: #444;
  width: auto;
  height: auto;
  display: block;
  position: absolute;
  inset: 40px 0;
  box-shadow: 0 0 8px #0009;
}

.mfp-figure small {
  color: #bdbdbd;
  font-size: 12px;
  line-height: 14px;
  display: block;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  cursor: auto;
  width: 100%;
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
}

.mfp-title {
  text-align: left;
  color: #f3f3f3;
  word-wrap: break-word;
  padding-right: 36px;
  line-height: 18px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (width <= 800px) and (orientation: landscape), screen and (height <= 300px) {
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }

  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }

  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-figure small {
    margin-left: 5px;
    display: inline;
  }

  .mfp-img-mobile .mfp-bottom-bar {
    box-sizing: border-box;
    background: #0009;
    margin: 0;
    padding: 3px 5px;
    position: fixed;
    top: auto;
    bottom: 0;
  }

  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }

  .mfp-img-mobile .mfp-counter {
    top: 3px;
    right: 5px;
  }

  .mfp-img-mobile .mfp-close {
    text-align: center;
    background: #0009;
    width: 35px;
    height: 35px;
    padding: 0;
    line-height: 35px;
    position: fixed;
    top: 0;
    right: 0;
  }
}

@media (width <= 900px) {
  .mfp-arrow {
    transform: scale(.75);
  }

  .mfp-arrow-left {
    transform-origin: 0;
  }

  .mfp-arrow-right {
    transform-origin: 100%;
  }

  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-wrap {
  width: 100%;
  position: absolute;
}

.mfp-wrap:before {
  z-index: 0;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  opacity: .7;
  mix-blend-mode: multiply;
  --tw-content: "";
  content: var(--tw-content);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mfp-content {
  position: static;
  max-width: 80rem !important;
}

.mfp-figure:after {
  display: none;
}

.mfp-iframe-scaler {
  width: 91.6667%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: visible;
}

.mfp-close {
  opacity: 1;
  border-radius: 50%;
  height: 2rem;
  font-size: 2rem;
  line-height: 2rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  right: 0;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
  text-align: center !important;
  --tw-text-opacity: 1 !important;
  color: rgb(38 44 56 / var(--tw-text-opacity)) !important;
  width: 2rem !important;
  padding: 0 !important;
  top: -1rem !important;
}

.mfp-close:hover, .mfp-close:focus {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.mfp-counter {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: .5rem;
  padding-right: .5rem !important;
}

img.mfp-img {
  border-radius: .375rem;
  padding: 0;
  max-height: calc(100vh - 5rem) !important;
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
  display: block;
  position: relative;
}

.slick-list {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track, .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
  top: 0;
  left: 0;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid #0000;
  height: auto;
  display: block;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.slick-dots li {
  list-style: none;
}

.slick-dots {
  justify-content: center;
  align-items: center;
  display: flex;
}

.slick-dots li {
  flex: none;
  width: auto !important;
  padding-left: .25rem !important;
  padding-right: .25rem !important;
}

.slick-dots li button {
  background-color: #ffffffb2;
  border-radius: 9999px;
  width: .5rem;
  height: .5rem;
  font-size: 0;
  transition-property: all;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.slick-dots li.slick-active button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  width: 1.5rem;
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
}

.noUi-base, .noUi-connects {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.noUi-connects {
  z-index: 0;
  overflow: hidden;
}

.noUi-connect, .noUi-origin {
  will-change: transform;
  z-index: 1;
  transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-style: flat;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto;
}

.noUi-vertical .noUi-origin {
  width: 0;
  top: -100%;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  backface-visibility: hidden;
  position: absolute;
}

.noUi-touch-area {
  width: 100%;
  height: 100%;
}

.noUi-state-tap .noUi-connect, .noUi-state-tap .noUi-origin {
  transition: transform .3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

.noUi-horizontal {
  height: 18px;
}

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  top: -6px;
  right: -17px;
}

.noUi-vertical {
  width: 18px;
}

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  bottom: -17px;
  right: -6px;
}

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto;
}

.noUi-target {
  background: #fafafa;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}

.noUi-connects {
  border-radius: 3px;
}

.noUi-connect {
  background: #3fb8af;
}

.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  cursor: default;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}

.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}

.noUi-handle:before, .noUi-handle:after {
  content: "";
  background: #e8e7e6;
  width: 1px;
  height: 14px;
  display: block;
  position: absolute;
  top: 6px;
  left: 14px;
}

.noUi-handle:after {
  left: 17px;
}

.noUi-vertical .noUi-handle:before, .noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  top: 14px;
  left: 6px;
}

.noUi-vertical .noUi-handle:after {
  top: 17px;
}

[disabled] .noUi-connect {
  background: #b8b8b8;
}

[disabled].noUi-target, [disabled].noUi-handle, [disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-pips, .noUi-pips * {
  box-sizing: border-box;
}

.noUi-pips {
  color: #999;
  position: absolute;
}

.noUi-value {
  white-space: nowrap;
  text-align: center;
  position: absolute;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

.noUi-marker {
  background: #ccc;
  position: absolute;
}

.noUi-marker-sub, .noUi-marker-large {
  background: #aaa;
}

.noUi-pips-horizontal {
  width: 100%;
  height: 80px;
  padding: 10px 0;
  top: 100%;
  left: 0;
}

.noUi-value-horizontal {
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  width: 2px;
  height: 5px;
  margin-left: -1px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

.noUi-pips-vertical {
  height: 100%;
  padding: 0 10px;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  padding-left: 25px;
  transform: translate(0, -50%);
}

.noUi-rtl .noUi-value-vertical {
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  color: #000;
  text-align: center;
  white-space: nowrap;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 5px;
  display: block;
  position: absolute;
}

.noUi-horizontal .noUi-tooltip {
  bottom: 120%;
  left: 50%;
  transform: translate(-50%);
}

.noUi-vertical .noUi-tooltip {
  top: 50%;
  right: 120%;
  transform: translate(0, -50%);
}

.noUi-horizontal .noUi-origin > .noUi-tooltip {
  bottom: 10px;
  left: auto;
  transform: translate(50%);
}

.noUi-vertical .noUi-origin > .noUi-tooltip {
  top: auto;
  right: 28px;
  transform: translate(0, -18px);
}

.has-budget div {
  display: inline-block;
}

.has-budget div:first-child {
  margin-right: .75rem;
}

.has-budget label {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: .5rem;
  display: block;
  position: relative;
}

.has-budget input {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0 !important;
  margin: 0 !important;
}

.has-budget input + span {
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  --tw-bg-opacity: .7;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  padding: .75rem 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
}

.has-budget input + span:hover, .has-budget input + span:focus, .has-budget input:checked + span {
  --tw-bg-opacity: 1;
}

.has-budget-back {
  padding-left: 1.25rem;
  position: relative;
}

.has-budget-back img {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 0;
}

.budget-slider__container .noUi-value-sub, .budget-slider__container .noUi-value-large {
  font-size: 10px;
}

@media (width >= 961px) {
  .budget-slider__container .noUi-value-sub, .budget-slider__container .noUi-value-large {
    font-size: 20px;
  }
}

.budget-slider__container .noUi-marker {
  top: .125rem;
}

.budget-slider__container .noUi-marker.noUi-marker-sub {
  height: .25rem;
}

.budget-slider__container .noUi-marker.noUi-marker-large {
  height: .5rem;
}

.budget-slider__container .noUi-connect {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  border-radius: 9999px;
}

.budget-slider__container .noUi-target {
  border-radius: 9999px;
}

.budget-slider__container .noUi-handle {
  cursor: pointer;
  background-color: #ffffffa1;
  border-radius: 9px;
  width: 10px;
  height: 10px;
  top: 3px;
  right: -13px;
}

.budget-slider__container .noUi-handle.noUi-handle-upper {
  right: 3px;
}

.budget-slider__container .noUi-handle:before, .budget-slider__container .noUi-handle:after {
  display: none;
}

.budget-slider__container .active-pip {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  text-underline-offset: 2px;
  font-weight: 700;
  text-decoration-line: underline;
}

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900);
}

.fa-solid, .fa-regular, .fa-brands, .fas, .far, .fab, .fal, .fat, .fad, .fass, .fasr, .fasl, .fast, .fasds, .fa-light, .fa-thin, .fa-duotone, .fa-sharp, .fa-sharp-duotone, .fa-sharp-solid, .fa-classic, .fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
}

.fas, .far, .fal, .fat, .fa-solid, .fa-regular, .fa-light, .fa-thin, .fa-classic {
  font-family: "Font Awesome 6 Pro";
}

.fab, .fa-brands {
  font-family: "Font Awesome 6 Brands";
}

.fad, .fa-classic.fa-duotone, .fa-duotone {
  font-family: "Font Awesome 6 Duotone";
}

.fasds, .fa-sharp-duotone {
  font-family: "Font Awesome 6 Sharp Duotone";
  font-weight: 900;
}

.fass, .fasr, .fasl, .fast, .fa-sharp {
  font-family: "Font Awesome 6 Sharp";
}

.fass, .fa-sharp {
  font-weight: 900;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-2xs {
  vertical-align: .225em;
  font-size: .625em;
  line-height: .1em;
}

.fa-xs {
  vertical-align: .125em;
  font-size: .75em;
  line-height: .08333em;
}

.fa-sm {
  vertical-align: .05357em;
  font-size: .875em;
  line-height: .07143em;
}

.fa-lg {
  vertical-align: -.075em;
  font-size: 1.25em;
  line-height: .05em;
}

.fa-xl {
  vertical-align: -.125em;
  font-size: 1.5em;
  line-height: .04167em;
}

.fa-2xl {
  vertical-align: -.1875em;
  font-size: 2em;
  line-height: .03125em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  left: calc(-1 * var(--fa-li-width, 2em));
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit;
  position: absolute;
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em);
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em);
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em);
}

.fa-beat {
  animation-name: fa-beat;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-bounce {
  animation-name: fa-bounce;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
}

.fa-fade {
  animation-name: fa-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-beat-fade {
  animation-name: fa-beat-fade;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
}

.fa-flip {
  animation-name: fa-flip;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, ease-in-out);
}

.fa-shake {
  animation-name: fa-shake;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin {
  animation-name: fa-spin;
  animation-delay: var(--fa-animation-delay, 0s);
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 2s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, linear);
}

.fa-spin-reverse {
  --fa-animation-direction: reverse;
}

.fa-pulse, .fa-spin-pulse {
  animation-name: fa-spin;
  animation-direction: var(--fa-animation-direction, normal);
  animation-duration: var(--fa-animation-duration, 1s);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-timing-function: var(--fa-animation-timing, steps(8));
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-bounce, .fa-fade, .fa-beat-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    transition-duration: 0s;
    transition-delay: 0s;
    animation-duration: 1ms;
    animation-iteration-count: 1;
    animation-delay: -1ms;
  }
}

@keyframes fa-beat {
  0%, 90% {
    transform: scale(1);
  }

  45% {
    transform: scale(var(--fa-beat-scale, 1.25));
  }
}

@keyframes fa-bounce {
  0% {
    transform: scale(1)translateY(0);
  }

  10% {
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
  }

  30% {
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
  }

  50% {
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
  }

  57% {
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
  }

  64% {
    transform: scale(1)translateY(0);
  }

  100% {
    transform: scale(1)translateY(0);
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4);
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(var(--fa-beat-fade-scale, 1.125));
  }
}

@keyframes fa-flip {
  50% {
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
  }
}

@keyframes fa-shake {
  0% {
    transform: rotate(-15deg);
  }

  4% {
    transform: rotate(15deg);
  }

  8%, 24% {
    transform: rotate(-18deg);
  }

  12%, 28% {
    transform: rotate(18deg);
  }

  16% {
    transform: rotate(-22deg);
  }

  20% {
    transform: rotate(22deg);
  }

  32% {
    transform: rotate(-12deg);
  }

  36% {
    transform: rotate(12deg);
  }

  40%, 100% {
    transform: rotate(0);
  }
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.fa-rotate-90 {
  transform: rotate(90deg);
}

.fa-rotate-180 {
  transform: rotate(180deg);
}

.fa-rotate-270 {
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

.fa-rotate-by {
  transform: rotate(var(--fa-rotate-angle, 0));
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  z-index: var(--fa-stack-z-index, auto);
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fa-0:before {
  content: "0";
}

.fa-1:before {
  content: "1";
}

.fa-2:before {
  content: "2";
}

.fa-3:before {
  content: "3";
}

.fa-4:before {
  content: "4";
}

.fa-5:before {
  content: "5";
}

.fa-6:before {
  content: "6";
}

.fa-7:before {
  content: "7";
}

.fa-8:before {
  content: "8";
}

.fa-9:before {
  content: "9";
}

.fa-fill-drip:before {
  content: "";
}

.fa-arrows-to-circle:before {
  content: "";
}

.fa-circle-chevron-right:before, .fa-chevron-circle-right:before {
  content: "";
}

.fa-wagon-covered:before {
  content: "";
}

.fa-line-height:before {
  content: "";
}

.fa-bagel:before {
  content: "";
}

.fa-transporter-7:before {
  content: "";
}

.fa-at:before {
  content: "@";
}

.fa-rectangles-mixed:before {
  content: "";
}

.fa-phone-arrow-up-right:before, .fa-phone-arrow-up:before, .fa-phone-outgoing:before {
  content: "";
}

.fa-trash-can:before, .fa-trash-alt:before {
  content: "";
}

.fa-circle-l:before {
  content: "";
}

.fa-head-side-goggles:before, .fa-head-vr:before {
  content: "";
}

.fa-text-height:before {
  content: "";
}

.fa-user-xmark:before, .fa-user-times:before {
  content: "";
}

.fa-face-hand-yawn:before {
  content: "";
}

.fa-gauge-simple-min:before, .fa-tachometer-slowest:before {
  content: "";
}

.fa-stethoscope:before {
  content: "";
}

.fa-coffin:before {
  content: "";
}

.fa-message:before, .fa-comment-alt:before {
  content: "";
}

.fa-salad:before, .fa-bowl-salad:before {
  content: "";
}

.fa-info:before {
  content: "";
}

.fa-robot-astromech:before {
  content: "";
}

.fa-ring-diamond:before {
  content: "";
}

.fa-fondue-pot:before {
  content: "";
}

.fa-theta:before {
  content: "";
}

.fa-face-hand-peeking:before {
  content: "";
}

.fa-square-user:before {
  content: "";
}

.fa-down-left-and-up-right-to-center:before, .fa-compress-alt:before {
  content: "";
}

.fa-explosion:before {
  content: "";
}

.fa-file-lines:before, .fa-file-alt:before, .fa-file-text:before {
  content: "";
}

.fa-wave-square:before {
  content: "";
}

.fa-ring:before {
  content: "";
}

.fa-building-un:before {
  content: "";
}

.fa-dice-three:before {
  content: "";
}

.fa-tire-pressure-warning:before {
  content: "";
}

.fa-wifi-fair:before, .fa-wifi-2:before {
  content: "";
}

.fa-calendar-days:before, .fa-calendar-alt:before {
  content: "";
}

.fa-mp3-player:before {
  content: "";
}

.fa-anchor-circle-check:before {
  content: "";
}

.fa-tally-4:before {
  content: "";
}

.fa-rectangle-history:before {
  content: "";
}

.fa-building-circle-arrow-right:before {
  content: "";
}

.fa-volleyball:before, .fa-volleyball-ball:before {
  content: "";
}

.fa-sun-haze:before {
  content: "";
}

.fa-text-size:before {
  content: "";
}

.fa-ufo:before {
  content: "";
}

.fa-fork:before, .fa-utensil-fork:before {
  content: "";
}

.fa-arrows-up-to-line:before {
  content: "";
}

.fa-mobile-signal:before {
  content: "";
}

.fa-barcode-scan:before {
  content: "";
}

.fa-sort-down:before, .fa-sort-desc:before {
  content: "";
}

.fa-folder-arrow-down:before, .fa-folder-download:before {
  content: "";
}

.fa-circle-minus:before, .fa-minus-circle:before {
  content: "";
}

.fa-face-icicles:before {
  content: "";
}

.fa-shovel:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-films:before {
  content: "";
}

.fa-right-from-bracket:before, .fa-sign-out-alt:before {
  content: "";
}

.fa-face-glasses:before {
  content: "";
}

.fa-nfc:before {
  content: "";
}

.fa-atom:before {
  content: "";
}

.fa-soap:before {
  content: "";
}

.fa-icons:before, .fa-heart-music-camera-bolt:before {
  content: "";
}

.fa-microphone-lines-slash:before, .fa-microphone-alt-slash:before {
  content: "";
}

.fa-closed-captioning-slash:before {
  content: "";
}

.fa-calculator-simple:before, .fa-calculator-alt:before {
  content: "";
}

.fa-bridge-circle-check:before {
  content: "";
}

.fa-sliders-up:before, .fa-sliders-v:before {
  content: "";
}

.fa-location-minus:before, .fa-map-marker-minus:before {
  content: "";
}

.fa-pump-medical:before {
  content: "";
}

.fa-fingerprint:before {
  content: "";
}

.fa-ski-boot:before {
  content: "";
}

.fa-standard-definition:before, .fa-rectangle-sd:before {
  content: "";
}

.fa-h1:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-magnifying-glass-location:before, .fa-search-location:before {
  content: "";
}

.fa-message-bot:before {
  content: "";
}

.fa-forward-step:before, .fa-step-forward:before {
  content: "";
}

.fa-face-smile-beam:before, .fa-smile-beam:before {
  content: "";
}

.fa-light-ceiling:before {
  content: "";
}

.fa-message-exclamation:before, .fa-comment-alt-exclamation:before {
  content: "";
}

.fa-bowl-scoop:before, .fa-bowl-shaved-ice:before {
  content: "";
}

.fa-square-x:before {
  content: "";
}

.fa-building-memo:before {
  content: "";
}

.fa-utility-pole-double:before {
  content: "";
}

.fa-flag-checkered:before {
  content: "";
}

.fa-chevrons-up:before, .fa-chevron-double-up:before {
  content: "";
}

.fa-football:before, .fa-football-ball:before {
  content: "";
}

.fa-user-vneck:before {
  content: "";
}

.fa-school-circle-exclamation:before {
  content: "";
}

.fa-crop:before {
  content: "";
}

.fa-angles-down:before, .fa-angle-double-down:before {
  content: "";
}

.fa-users-rectangle:before {
  content: "";
}

.fa-people-roof:before {
  content: "";
}

.fa-square-arrow-right:before, .fa-arrow-square-right:before {
  content: "";
}

.fa-location-plus:before, .fa-map-marker-plus:before {
  content: "";
}

.fa-lightbulb-exclamation-on:before {
  content: "";
}

.fa-people-line:before {
  content: "";
}

.fa-beer-mug-empty:before, .fa-beer:before {
  content: "";
}

.fa-crate-empty:before {
  content: "";
}

.fa-diagram-predecessor:before {
  content: "";
}

.fa-transporter:before {
  content: "";
}

.fa-calendar-circle-user:before {
  content: "";
}

.fa-arrow-up-long:before, .fa-long-arrow-up:before {
  content: "";
}

.fa-person-carry-box:before, .fa-person-carry:before {
  content: "";
}

.fa-fire-flame-simple:before, .fa-burn:before {
  content: "";
}

.fa-person:before, .fa-male:before {
  content: "";
}

.fa-laptop:before {
  content: "";
}

.fa-file-csv:before {
  content: "";
}

.fa-menorah:before {
  content: "";
}

.fa-union:before {
  content: "";
}

.fa-chevrons-left:before, .fa-chevron-double-left:before {
  content: "";
}

.fa-circle-heart:before, .fa-heart-circle:before {
  content: "";
}

.fa-truck-plane:before {
  content: "";
}

.fa-record-vinyl:before {
  content: "";
}

.fa-bring-forward:before {
  content: "";
}

.fa-square-p:before {
  content: "";
}

.fa-face-grin-stars:before, .fa-grin-stars:before {
  content: "";
}

.fa-sigma:before {
  content: "";
}

.fa-camera-movie:before {
  content: "";
}

.fa-bong:before {
  content: "";
}

.fa-clarinet:before {
  content: "";
}

.fa-truck-flatbed:before {
  content: "";
}

.fa-spaghetti-monster-flying:before, .fa-pastafarianism:before {
  content: "";
}

.fa-arrow-down-up-across-line:before {
  content: "";
}

.fa-arrows-rotate-reverse:before {
  content: "";
}

.fa-leaf-heart:before {
  content: "";
}

.fa-house-building:before {
  content: "";
}

.fa-cheese-swiss:before {
  content: "";
}

.fa-spoon:before, .fa-utensil-spoon:before {
  content: "";
}

.fa-jar-wheat:before {
  content: "";
}

.fa-envelopes-bulk:before, .fa-mail-bulk:before {
  content: "";
}

.fa-file-circle-exclamation:before {
  content: "";
}

.fa-bow-arrow:before {
  content: "";
}

.fa-cart-xmark:before {
  content: "";
}

.fa-hexagon-xmark:before, .fa-times-hexagon:before, .fa-xmark-hexagon:before {
  content: "";
}

.fa-circle-h:before, .fa-hospital-symbol:before {
  content: "";
}

.fa-merge:before {
  content: "";
}

.fa-pager:before {
  content: "";
}

.fa-cart-minus:before {
  content: "";
}

.fa-address-book:before, .fa-contact-book:before {
  content: "";
}

.fa-pan-frying:before {
  content: "";
}

.fa-grid:before, .fa-grid-3:before {
  content: "";
}

.fa-football-helmet:before {
  content: "";
}

.fa-hand-love:before {
  content: "";
}

.fa-trees:before {
  content: "";
}

.fa-strikethrough:before {
  content: "";
}

.fa-page:before {
  content: "";
}

.fa-k:before {
  content: "K";
}

.fa-diagram-previous:before {
  content: "";
}

.fa-gauge-min:before, .fa-tachometer-alt-slowest:before {
  content: "";
}

.fa-folder-grid:before {
  content: "";
}

.fa-eggplant:before {
  content: "";
}

.fa-excavator:before {
  content: "";
}

.fa-ram:before {
  content: "";
}

.fa-landmark-flag:before {
  content: "";
}

.fa-lips:before {
  content: "";
}

.fa-pencil:before, .fa-pencil-alt:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-caret-right:before {
  content: "";
}

.fa-comments:before {
  content: "";
}

.fa-paste:before, .fa-file-clipboard:before {
  content: "";
}

.fa-desktop-arrow-down:before {
  content: "";
}

.fa-code-pull-request:before {
  content: "";
}

.fa-pumpkin:before {
  content: "";
}

.fa-clipboard-list:before {
  content: "";
}

.fa-pen-field:before {
  content: "";
}

.fa-blueberries:before {
  content: "";
}

.fa-truck-ramp-box:before, .fa-truck-loading:before {
  content: "";
}

.fa-note:before {
  content: "";
}

.fa-arrow-down-to-square:before {
  content: "";
}

.fa-user-check:before {
  content: "";
}

.fa-cloud-xmark:before {
  content: "";
}

.fa-vial-virus:before {
  content: "";
}

.fa-book-blank:before, .fa-book-alt:before {
  content: "";
}

.fa-golf-flag-hole:before {
  content: "";
}

.fa-message-arrow-down:before, .fa-comment-alt-arrow-down:before {
  content: "";
}

.fa-face-unamused:before {
  content: "";
}

.fa-sheet-plastic:before {
  content: "";
}

.fa-circle-9:before {
  content: "";
}

.fa-blog:before {
  content: "";
}

.fa-user-ninja:before {
  content: "";
}

.fa-pencil-slash:before {
  content: "";
}

.fa-bowling-pins:before {
  content: "";
}

.fa-person-arrow-up-from-line:before {
  content: "";
}

.fa-down-right:before {
  content: "";
}

.fa-scroll-torah:before, .fa-torah:before {
  content: "";
}

.fa-webhook:before {
  content: "";
}

.fa-blinds-open:before {
  content: "";
}

.fa-fence:before {
  content: "";
}

.fa-up:before, .fa-arrow-alt-up:before {
  content: "";
}

.fa-broom-ball:before, .fa-quidditch:before, .fa-quidditch-broom-ball:before {
  content: "";
}

.fa-drumstick:before {
  content: "";
}

.fa-square-v:before {
  content: "";
}

.fa-face-awesome:before, .fa-gave-dandy:before {
  content: "";
}

.fa-dial-off:before {
  content: "";
}

.fa-toggle-off:before {
  content: "";
}

.fa-face-smile-horns:before {
  content: "";
}

.fa-box-archive:before, .fa-archive:before {
  content: "";
}

.fa-grapes:before {
  content: "";
}

.fa-person-drowning:before {
  content: "";
}

.fa-dial-max:before {
  content: "";
}

.fa-circle-m:before {
  content: "";
}

.fa-calendar-image:before {
  content: "";
}

.fa-circle-caret-down:before, .fa-caret-circle-down:before {
  content: "";
}

.fa-arrow-down-9-1:before, .fa-sort-numeric-desc:before, .fa-sort-numeric-down-alt:before {
  content: "";
}

.fa-face-grin-tongue-squint:before, .fa-grin-tongue-squint:before {
  content: "";
}

.fa-shish-kebab:before {
  content: "";
}

.fa-spray-can:before {
  content: "";
}

.fa-alarm-snooze:before {
  content: "";
}

.fa-scarecrow:before {
  content: "";
}

.fa-truck-monster:before {
  content: "";
}

.fa-gift-card:before {
  content: "";
}

.fa-w:before {
  content: "W";
}

.fa-code-pull-request-draft:before {
  content: "";
}

.fa-square-b:before {
  content: "";
}

.fa-elephant:before {
  content: "";
}

.fa-earth-africa:before, .fa-globe-africa:before {
  content: "";
}

.fa-rainbow:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-tablet-screen-button:before, .fa-tablet-alt:before {
  content: "";
}

.fa-paw:before {
  content: "";
}

.fa-message-question:before {
  content: "";
}

.fa-cloud:before {
  content: "";
}

.fa-trowel-bricks:before {
  content: "";
}

.fa-square-3:before {
  content: "";
}

.fa-face-flushed:before, .fa-flushed:before {
  content: "";
}

.fa-hospital-user:before {
  content: "";
}

.fa-microwave:before {
  content: "";
}

.fa-chf-sign:before {
  content: "";
}

.fa-tent-arrow-left-right:before {
  content: "";
}

.fa-cart-circle-arrow-up:before {
  content: "";
}

.fa-trash-clock:before {
  content: "";
}

.fa-reflect-both:before {
  content: "";
}

.fa-gavel:before, .fa-legal:before {
  content: "";
}

.fa-sprinkler-ceiling:before {
  content: "";
}

.fa-browsers:before {
  content: "";
}

.fa-trillium:before {
  content: "";
}

.fa-table-cells-unlock:before {
  content: "";
}

.fa-music-slash:before {
  content: "";
}

.fa-truck-ramp:before {
  content: "";
}

.fa-binoculars:before {
  content: "";
}

.fa-microphone-slash:before {
  content: "";
}

.fa-box-tissue:before {
  content: "";
}

.fa-circle-c:before {
  content: "";
}

.fa-star-christmas:before {
  content: "";
}

.fa-chart-bullet:before {
  content: "";
}

.fa-motorcycle:before {
  content: "";
}

.fa-tree-christmas:before {
  content: "";
}

.fa-tire-flat:before {
  content: "";
}

.fa-sunglasses:before {
  content: "";
}

.fa-badge:before {
  content: "";
}

.fa-message-pen:before, .fa-comment-alt-edit:before, .fa-message-edit:before {
  content: "";
}

.fa-bell-concierge:before, .fa-concierge-bell:before {
  content: "";
}

.fa-pen-ruler:before, .fa-pencil-ruler:before {
  content: "";
}

.fa-file-mp3:before {
  content: "";
}

.fa-arrow-progress:before {
  content: "";
}

.fa-chess-rook-piece:before, .fa-chess-rook-alt:before {
  content: "";
}

.fa-square-root:before {
  content: "";
}

.fa-album-collection-circle-plus:before {
  content: "";
}

.fa-people-arrows:before, .fa-people-arrows-left-right:before {
  content: "";
}

.fa-sign-post:before {
  content: "";
}

.fa-face-angry-horns:before {
  content: "";
}

.fa-mars-and-venus-burst:before {
  content: "";
}

.fa-tombstone:before {
  content: "";
}

.fa-square-caret-right:before, .fa-caret-square-right:before {
  content: "";
}

.fa-scissors:before, .fa-cut:before {
  content: "";
}

.fa-list-music:before {
  content: "";
}

.fa-sun-plant-wilt:before {
  content: "";
}

.fa-toilets-portable:before {
  content: "";
}

.fa-hockey-puck:before {
  content: "";
}

.fa-mustache:before {
  content: "";
}

.fa-hyphen:before {
  content: "-";
}

.fa-table:before {
  content: "";
}

.fa-user-chef:before {
  content: "";
}

.fa-message-image:before, .fa-comment-alt-image:before {
  content: "";
}

.fa-users-medical:before {
  content: "";
}

.fa-sensor-triangle-exclamation:before, .fa-sensor-alert:before {
  content: "";
}

.fa-magnifying-glass-arrow-right:before {
  content: "";
}

.fa-tachograph-digital:before, .fa-digital-tachograph:before {
  content: "";
}

.fa-face-mask:before {
  content: "";
}

.fa-pickleball:before {
  content: "";
}

.fa-star-sharp-half:before {
  content: "";
}

.fa-users-slash:before {
  content: "";
}

.fa-clover:before {
  content: "";
}

.fa-meat:before {
  content: "";
}

.fa-reply:before, .fa-mail-reply:before {
  content: "";
}

.fa-star-and-crescent:before {
  content: "";
}

.fa-empty-set:before {
  content: "";
}

.fa-house-fire:before {
  content: "";
}

.fa-square-minus:before, .fa-minus-square:before {
  content: "";
}

.fa-helicopter:before {
  content: "";
}

.fa-bird:before {
  content: "";
}

.fa-compass:before {
  content: "";
}

.fa-square-caret-down:before, .fa-caret-square-down:before {
  content: "";
}

.fa-heart-half-stroke:before, .fa-heart-half-alt:before {
  content: "";
}

.fa-file-circle-question:before {
  content: "";
}

.fa-truck-utensils:before {
  content: "";
}

.fa-laptop-code:before {
  content: "";
}

.fa-joystick:before {
  content: "";
}

.fa-grill-fire:before {
  content: "";
}

.fa-rectangle-vertical-history:before {
  content: "";
}

.fa-swatchbook:before {
  content: "";
}

.fa-prescription-bottle:before {
  content: "";
}

.fa-bars:before, .fa-navicon:before {
  content: "";
}

.fa-keyboard-left:before {
  content: "";
}

.fa-people-group:before {
  content: "";
}

.fa-hourglass-end:before, .fa-hourglass-3:before {
  content: "";
}

.fa-heart-crack:before, .fa-heart-broken:before {
  content: "";
}

.fa-face-beam-hand-over-mouth:before {
  content: "";
}

.fa-droplet-percent:before, .fa-humidity:before {
  content: "";
}

.fa-square-up-right:before, .fa-external-link-square-alt:before {
  content: "";
}

.fa-face-kiss-beam:before, .fa-kiss-beam:before {
  content: "";
}

.fa-corn:before {
  content: "";
}

.fa-roller-coaster:before {
  content: "";
}

.fa-photo-film-music:before {
  content: "";
}

.fa-radar:before {
  content: "";
}

.fa-sickle:before {
  content: "";
}

.fa-film:before {
  content: "";
}

.fa-coconut:before {
  content: "";
}

.fa-ruler-horizontal:before {
  content: "";
}

.fa-shield-cross:before {
  content: "";
}

.fa-cassette-tape:before {
  content: "";
}

.fa-square-terminal:before {
  content: "";
}

.fa-people-robbery:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-caret-left:before {
  content: "";
}

.fa-comment-middle:before {
  content: "";
}

.fa-trash-can-list:before {
  content: "";
}

.fa-block:before {
  content: "";
}

.fa-circle-exclamation:before, .fa-exclamation-circle:before {
  content: "";
}

.fa-school-circle-xmark:before {
  content: "";
}

.fa-arrow-right-from-bracket:before, .fa-sign-out:before {
  content: "";
}

.fa-face-frown-slight:before {
  content: "";
}

.fa-circle-chevron-down:before, .fa-chevron-circle-down:before {
  content: "";
}

.fa-sidebar-flip:before {
  content: "";
}

.fa-unlock-keyhole:before, .fa-unlock-alt:before {
  content: "";
}

.fa-temperature-list:before {
  content: "";
}

.fa-cloud-showers-heavy:before {
  content: "";
}

.fa-headphones-simple:before, .fa-headphones-alt:before {
  content: "";
}

.fa-sitemap:before {
  content: "";
}

.fa-pipe-section:before {
  content: "";
}

.fa-space-station-moon-construction:before, .fa-space-station-moon-alt:before {
  content: "";
}

.fa-circle-dollar-to-slot:before, .fa-donate:before {
  content: "";
}

.fa-memory:before {
  content: "";
}

.fa-face-sleeping:before {
  content: "";
}

.fa-road-spikes:before {
  content: "";
}

.fa-fire-burner:before {
  content: "";
}

.fa-squirrel:before {
  content: "";
}

.fa-arrow-up-to-line:before, .fa-arrow-to-top:before {
  content: "";
}

.fa-flag:before {
  content: "";
}

.fa-face-cowboy-hat:before {
  content: "";
}

.fa-hanukiah:before {
  content: "";
}

.fa-chart-scatter-3d:before {
  content: "";
}

.fa-display-chart-up:before {
  content: "";
}

.fa-square-code:before {
  content: "";
}

.fa-feather:before {
  content: "";
}

.fa-volume-low:before, .fa-volume-down:before {
  content: "";
}

.fa-xmark-to-slot:before, .fa-times-to-slot:before, .fa-vote-nay:before {
  content: "";
}

.fa-box-taped:before, .fa-box-alt:before {
  content: "";
}

.fa-comment-slash:before {
  content: "";
}

.fa-swords:before {
  content: "";
}

.fa-cloud-sun-rain:before {
  content: "";
}

.fa-album:before {
  content: "";
}

.fa-circle-n:before {
  content: "";
}

.fa-compress:before {
  content: "";
}

.fa-wheat-awn:before, .fa-wheat-alt:before {
  content: "";
}

.fa-ankh:before {
  content: "";
}

.fa-hands-holding-child:before {
  content: "";
}

.fa-asterisk:before {
  content: "*";
}

.fa-key-skeleton-left-right:before {
  content: "";
}

.fa-comment-lines:before {
  content: "";
}

.fa-luchador-mask:before, .fa-luchador:before, .fa-mask-luchador:before {
  content: "";
}

.fa-square-check:before, .fa-check-square:before {
  content: "";
}

.fa-shredder:before {
  content: "";
}

.fa-book-open-cover:before, .fa-book-open-alt:before {
  content: "";
}

.fa-sandwich:before {
  content: "";
}

.fa-peseta-sign:before {
  content: "";
}

.fa-square-parking-slash:before, .fa-parking-slash:before {
  content: "";
}

.fa-train-tunnel:before {
  content: "";
}

.fa-heading:before, .fa-header:before {
  content: "";
}

.fa-ghost:before {
  content: "";
}

.fa-face-anguished:before {
  content: "";
}

.fa-hockey-sticks:before {
  content: "";
}

.fa-abacus:before {
  content: "";
}

.fa-film-simple:before, .fa-film-alt:before {
  content: "";
}

.fa-list:before, .fa-list-squares:before {
  content: "";
}

.fa-tree-palm:before {
  content: "";
}

.fa-square-phone-flip:before, .fa-phone-square-alt:before {
  content: "";
}

.fa-user-beard-bolt:before {
  content: "";
}

.fa-cart-plus:before {
  content: "";
}

.fa-gamepad:before {
  content: "";
}

.fa-border-center-v:before {
  content: "";
}

.fa-circle-dot:before, .fa-dot-circle:before {
  content: "";
}

.fa-clipboard-medical:before {
  content: "";
}

.fa-face-dizzy:before, .fa-dizzy:before {
  content: "";
}

.fa-egg:before {
  content: "";
}

.fa-up-to-line:before, .fa-arrow-alt-to-top:before {
  content: "";
}

.fa-house-medical-circle-xmark:before {
  content: "";
}

.fa-watch-fitness:before {
  content: "";
}

.fa-clock-nine-thirty:before {
  content: "";
}

.fa-campground:before {
  content: "";
}

.fa-folder-plus:before {
  content: "";
}

.fa-jug:before {
  content: "";
}

.fa-futbol:before, .fa-futbol-ball:before, .fa-soccer-ball:before {
  content: "";
}

.fa-snow-blowing:before {
  content: "";
}

.fa-paintbrush:before, .fa-paint-brush:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-arrow-down-from-line:before, .fa-arrow-from-top:before {
  content: "";
}

.fa-gas-pump:before {
  content: "";
}

.fa-signal-bars-slash:before, .fa-signal-alt-slash:before {
  content: "";
}

.fa-monkey:before {
  content: "";
}

.fa-rectangle-pro:before, .fa-pro:before {
  content: "";
}

.fa-house-night:before {
  content: "";
}

.fa-hot-tub-person:before, .fa-hot-tub:before {
  content: "";
}

.fa-globe-pointer:before {
  content: "";
}

.fa-blanket:before {
  content: "";
}

.fa-map-location:before, .fa-map-marked:before {
  content: "";
}

.fa-house-flood-water:before {
  content: "";
}

.fa-comments-question-check:before {
  content: "";
}

.fa-tree:before {
  content: "";
}

.fa-arrows-cross:before {
  content: "";
}

.fa-backpack:before {
  content: "";
}

.fa-square-small:before {
  content: "";
}

.fa-folder-arrow-up:before, .fa-folder-upload:before {
  content: "";
}

.fa-bridge-lock:before {
  content: "";
}

.fa-crosshairs-simple:before {
  content: "";
}

.fa-sack-dollar:before {
  content: "";
}

.fa-pen-to-square:before, .fa-edit:before {
  content: "";
}

.fa-square-sliders:before, .fa-sliders-h-square:before {
  content: "";
}

.fa-car-side:before {
  content: "";
}

.fa-message-middle-top:before, .fa-comment-middle-top-alt:before {
  content: "";
}

.fa-lightbulb-on:before {
  content: "";
}

.fa-knife:before, .fa-utensil-knife:before {
  content: "";
}

.fa-share-nodes:before, .fa-share-alt:before {
  content: "";
}

.fa-display-chart-up-circle-dollar:before {
  content: "";
}

.fa-wave-sine:before {
  content: "";
}

.fa-heart-circle-minus:before {
  content: "";
}

.fa-circle-w:before {
  content: "";
}

.fa-circle-calendar:before, .fa-calendar-circle:before {
  content: "";
}

.fa-hourglass-half:before, .fa-hourglass-2:before {
  content: "";
}

.fa-microscope:before {
  content: "";
}

.fa-sunset:before {
  content: "";
}

.fa-sink:before {
  content: "";
}

.fa-calendar-exclamation:before {
  content: "";
}

.fa-truck-container-empty:before {
  content: "";
}

.fa-hand-heart:before {
  content: "";
}

.fa-bag-shopping:before, .fa-shopping-bag:before {
  content: "";
}

.fa-arrow-down-z-a:before, .fa-sort-alpha-desc:before, .fa-sort-alpha-down-alt:before {
  content: "";
}

.fa-mitten:before {
  content: "";
}

.fa-reply-clock:before, .fa-reply-time:before {
  content: "";
}

.fa-person-rays:before {
  content: "";
}

.fa-right:before, .fa-arrow-alt-right:before {
  content: "";
}

.fa-circle-f:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-face-pleading:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-flask-vial:before {
  content: "";
}

.fa-police-box:before {
  content: "";
}

.fa-cucumber:before {
  content: "";
}

.fa-head-side-brain:before {
  content: "";
}

.fa-hand:before, .fa-hand-paper:before {
  content: "";
}

.fa-person-biking-mountain:before, .fa-biking-mountain:before {
  content: "";
}

.fa-utensils-slash:before {
  content: "";
}

.fa-print-magnifying-glass:before, .fa-print-search:before {
  content: "";
}

.fa-turn-right:before {
  content: "";
}

.fa-folder-bookmark:before {
  content: "";
}

.fa-arrow-turn-left-down:before {
  content: "";
}

.fa-om:before {
  content: "";
}

.fa-pi:before {
  content: "";
}

.fa-flask-round-potion:before, .fa-flask-potion:before {
  content: "";
}

.fa-face-shush:before {
  content: "";
}

.fa-worm:before {
  content: "";
}

.fa-house-circle-xmark:before {
  content: "";
}

.fa-plug:before {
  content: "";
}

.fa-calendar-circle-exclamation:before {
  content: "";
}

.fa-square-i:before {
  content: "";
}

.fa-chevron-up:before {
  content: "";
}

.fa-face-saluting:before {
  content: "";
}

.fa-gauge-simple-low:before, .fa-tachometer-slow:before {
  content: "";
}

.fa-face-persevering:before {
  content: "";
}

.fa-circle-camera:before, .fa-camera-circle:before {
  content: "";
}

.fa-hand-spock:before {
  content: "";
}

.fa-spider-web:before {
  content: "";
}

.fa-circle-microphone:before, .fa-microphone-circle:before {
  content: "";
}

.fa-book-arrow-up:before {
  content: "";
}

.fa-popsicle:before {
  content: "";
}

.fa-command:before {
  content: "";
}

.fa-blinds:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-saxophone:before {
  content: "";
}

.fa-square-2:before {
  content: "";
}

.fa-field-hockey-stick-ball:before, .fa-field-hockey:before {
  content: "";
}

.fa-arrow-up-square-triangle:before, .fa-sort-shapes-up-alt:before {
  content: "";
}

.fa-face-scream:before {
  content: "";
}

.fa-square-m:before {
  content: "";
}

.fa-camera-web:before, .fa-webcam:before {
  content: "";
}

.fa-comment-arrow-down:before {
  content: "";
}

.fa-lightbulb-cfl:before {
  content: "";
}

.fa-window-frame-open:before {
  content: "";
}

.fa-face-kiss:before, .fa-kiss:before {
  content: "";
}

.fa-bridge-circle-xmark:before {
  content: "";
}

.fa-period:before {
  content: ".";
}

.fa-face-grin-tongue:before, .fa-grin-tongue:before {
  content: "";
}

.fa-up-to-dotted-line:before {
  content: "";
}

.fa-thought-bubble:before {
  content: "";
}

.fa-skeleton-ribs:before {
  content: "";
}

.fa-raygun:before {
  content: "";
}

.fa-flute:before {
  content: "";
}

.fa-acorn:before {
  content: "";
}

.fa-video-arrow-up-right:before {
  content: "";
}

.fa-grate-droplet:before {
  content: "";
}

.fa-seal-exclamation:before {
  content: "";
}

.fa-chess-bishop:before {
  content: "";
}

.fa-message-sms:before {
  content: "";
}

.fa-coffee-beans:before {
  content: "";
}

.fa-hat-witch:before {
  content: "";
}

.fa-face-grin-wink:before, .fa-grin-wink:before {
  content: "";
}

.fa-clock-three-thirty:before {
  content: "";
}

.fa-ear-deaf:before, .fa-deaf:before, .fa-deafness:before, .fa-hard-of-hearing:before {
  content: "";
}

.fa-alarm-clock:before {
  content: "";
}

.fa-eclipse:before {
  content: "";
}

.fa-face-relieved:before {
  content: "";
}

.fa-road-circle-check:before {
  content: "";
}

.fa-dice-five:before {
  content: "";
}

.fa-octagon-minus:before, .fa-minus-octagon:before {
  content: "";
}

.fa-square-rss:before, .fa-rss-square:before {
  content: "";
}

.fa-face-zany:before {
  content: "";
}

.fa-tricycle:before {
  content: "";
}

.fa-land-mine-on:before {
  content: "";
}

.fa-square-arrow-up-left:before {
  content: "";
}

.fa-i-cursor:before {
  content: "";
}

.fa-chart-mixed-up-circle-dollar:before {
  content: "";
}

.fa-salt-shaker:before {
  content: "";
}

.fa-stamp:before {
  content: "";
}

.fa-file-plus:before {
  content: "";
}

.fa-draw-square:before {
  content: "";
}

.fa-toilet-paper-under-slash:before, .fa-toilet-paper-reverse-slash:before {
  content: "";
}

.fa-stairs:before {
  content: "";
}

.fa-drone-front:before, .fa-drone-alt:before {
  content: "";
}

.fa-glass-empty:before {
  content: "";
}

.fa-dial-high:before {
  content: "";
}

.fa-user-helmet-safety:before, .fa-user-construction:before, .fa-user-hard-hat:before {
  content: "";
}

.fa-i:before {
  content: "I";
}

.fa-hryvnia-sign:before, .fa-hryvnia:before {
  content: "";
}

.fa-arrow-down-left-and-arrow-up-right-to-center:before {
  content: "";
}

.fa-pills:before {
  content: "";
}

.fa-face-grin-wide:before, .fa-grin-alt:before {
  content: "";
}

.fa-tooth:before {
  content: "";
}

.fa-basketball-hoop:before {
  content: "";
}

.fa-objects-align-bottom:before {
  content: "";
}

.fa-v:before {
  content: "V";
}

.fa-sparkles:before {
  content: "";
}

.fa-squid:before {
  content: "";
}

.fa-leafy-green:before {
  content: "";
}

.fa-circle-arrow-up-right:before {
  content: "";
}

.fa-calendars:before {
  content: "";
}

.fa-bangladeshi-taka-sign:before {
  content: "";
}

.fa-bicycle:before {
  content: "";
}

.fa-hammer-war:before {
  content: "";
}

.fa-circle-d:before {
  content: "";
}

.fa-spider-black-widow:before {
  content: "";
}

.fa-staff-snake:before, .fa-rod-asclepius:before, .fa-rod-snake:before, .fa-staff-aesculapius:before {
  content: "";
}

.fa-pear:before {
  content: "";
}

.fa-head-side-cough-slash:before {
  content: "";
}

.fa-file-mov:before {
  content: "";
}

.fa-triangle:before {
  content: "";
}

.fa-apartment:before {
  content: "";
}

.fa-truck-medical:before, .fa-ambulance:before {
  content: "";
}

.fa-pepper:before {
  content: "";
}

.fa-piano:before {
  content: "";
}

.fa-gun-squirt:before {
  content: "";
}

.fa-wheat-awn-circle-exclamation:before {
  content: "";
}

.fa-snowman:before {
  content: "";
}

.fa-user-alien:before {
  content: "";
}

.fa-shield-check:before {
  content: "";
}

.fa-mortar-pestle:before {
  content: "";
}

.fa-road-barrier:before {
  content: "";
}

.fa-chart-candlestick:before {
  content: "";
}

.fa-briefcase-blank:before {
  content: "";
}

.fa-school:before {
  content: "";
}

.fa-igloo:before {
  content: "";
}

.fa-bracket-round:before, .fa-parenthesis:before {
  content: "(";
}

.fa-joint:before {
  content: "";
}

.fa-horse-saddle:before {
  content: "";
}

.fa-mug-marshmallows:before {
  content: "";
}

.fa-filters:before {
  content: "";
}

.fa-bell-on:before {
  content: "";
}

.fa-angle-right:before {
  content: "";
}

.fa-dial-med:before {
  content: "";
}

.fa-horse:before {
  content: "";
}

.fa-q:before {
  content: "Q";
}

.fa-monitor-waveform:before, .fa-monitor-heart-rate:before {
  content: "";
}

.fa-link-simple:before {
  content: "";
}

.fa-whistle:before {
  content: "";
}

.fa-g:before {
  content: "G";
}

.fa-wine-glass-crack:before, .fa-fragile:before {
  content: "";
}

.fa-slot-machine:before {
  content: "";
}

.fa-notes-medical:before {
  content: "";
}

.fa-car-wash:before {
  content: "";
}

.fa-escalator:before {
  content: "";
}

.fa-comment-image:before {
  content: "";
}

.fa-temperature-half:before, .fa-temperature-2:before, .fa-thermometer-2:before, .fa-thermometer-half:before {
  content: "";
}

.fa-dong-sign:before {
  content: "";
}

.fa-donut:before, .fa-doughnut:before {
  content: "";
}

.fa-capsules:before {
  content: "";
}

.fa-poo-storm:before, .fa-poo-bolt:before {
  content: "";
}

.fa-tally-1:before {
  content: "";
}

.fa-file-vector:before {
  content: "";
}

.fa-face-frown-open:before, .fa-frown-open:before {
  content: "";
}

.fa-square-dashed:before {
  content: "";
}

.fa-bag-shopping-plus:before {
  content: "";
}

.fa-square-j:before {
  content: "";
}

.fa-hand-point-up:before {
  content: "";
}

.fa-money-bill:before {
  content: "";
}

.fa-arrow-up-big-small:before, .fa-sort-size-up:before {
  content: "";
}

.fa-barcode-read:before {
  content: "";
}

.fa-baguette:before {
  content: "";
}

.fa-bowl-soft-serve:before {
  content: "";
}

.fa-face-holding-back-tears:before {
  content: "";
}

.fa-square-up:before, .fa-arrow-alt-square-up:before {
  content: "";
}

.fa-train-subway-tunnel:before, .fa-subway-tunnel:before {
  content: "";
}

.fa-square-exclamation:before, .fa-exclamation-square:before {
  content: "";
}

.fa-semicolon:before {
  content: ";";
}

.fa-bookmark:before {
  content: "";
}

.fa-fan-table:before {
  content: "";
}

.fa-align-justify:before {
  content: "";
}

.fa-battery-low:before, .fa-battery-1:before {
  content: "";
}

.fa-credit-card-front:before {
  content: "";
}

.fa-brain-arrow-curved-right:before, .fa-mind-share:before {
  content: "";
}

.fa-umbrella-beach:before {
  content: "";
}

.fa-helmet-un:before {
  content: "";
}

.fa-location-smile:before, .fa-map-marker-smile:before {
  content: "";
}

.fa-arrow-left-to-line:before, .fa-arrow-to-left:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-sushi:before, .fa-nigiri:before {
  content: "";
}

.fa-message-captions:before, .fa-comment-alt-captions:before {
  content: "";
}

.fa-trash-list:before {
  content: "";
}

.fa-bacon:before {
  content: "";
}

.fa-option:before {
  content: "";
}

.fa-raccoon:before {
  content: "";
}

.fa-hand-point-down:before {
  content: "";
}

.fa-arrow-up-from-bracket:before {
  content: "";
}

.fa-head-side-gear:before {
  content: "";
}

.fa-trash-plus:before {
  content: "";
}

.fa-file-cad:before {
  content: "";
}

.fa-objects-align-top:before {
  content: "";
}

.fa-folder:before, .fa-folder-blank:before {
  content: "";
}

.fa-face-anxious-sweat:before {
  content: "";
}

.fa-credit-card-blank:before {
  content: "";
}

.fa-file-waveform:before, .fa-file-medical-alt:before {
  content: "";
}

.fa-microchip-ai:before {
  content: "";
}

.fa-mug:before {
  content: "";
}

.fa-plane-up-slash:before {
  content: "";
}

.fa-radiation:before {
  content: "";
}

.fa-pen-circle:before {
  content: "";
}

.fa-bag-seedling:before {
  content: "";
}

.fa-chart-simple:before {
  content: "";
}

.fa-crutches:before {
  content: "";
}

.fa-circle-parking:before, .fa-parking-circle:before {
  content: "";
}

.fa-mars-stroke:before {
  content: "";
}

.fa-leaf-oak:before {
  content: "";
}

.fa-square-bolt:before {
  content: "";
}

.fa-vial:before {
  content: "";
}

.fa-gauge:before, .fa-dashboard:before, .fa-gauge-med:before, .fa-tachometer-alt-average:before {
  content: "";
}

.fa-wand-magic-sparkles:before, .fa-magic-wand-sparkles:before {
  content: "";
}

.fa-lambda:before {
  content: "";
}

.fa-e:before {
  content: "E";
}

.fa-pizza:before {
  content: "";
}

.fa-bowl-chopsticks-noodles:before {
  content: "";
}

.fa-h3:before {
  content: "";
}

.fa-pen-clip:before, .fa-pen-alt:before {
  content: "";
}

.fa-bridge-circle-exclamation:before {
  content: "";
}

.fa-badge-percent:before {
  content: "";
}

.fa-rotate-reverse:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-sensor:before {
  content: "";
}

.fa-comma:before {
  content: ",";
}

.fa-school-circle-check:before {
  content: "";
}

.fa-toilet-paper-under:before, .fa-toilet-paper-reverse:before {
  content: "";
}

.fa-light-emergency:before {
  content: "";
}

.fa-arrow-down-to-arc:before {
  content: "";
}

.fa-dumpster:before {
  content: "";
}

.fa-van-shuttle:before, .fa-shuttle-van:before {
  content: "";
}

.fa-building-user:before {
  content: "";
}

.fa-light-switch:before {
  content: "";
}

.fa-square-caret-left:before, .fa-caret-square-left:before {
  content: "";
}

.fa-highlighter:before {
  content: "";
}

.fa-wave-pulse:before, .fa-heart-rate:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-arrow-left-to-bracket:before {
  content: "";
}

.fa-hat-santa:before {
  content: "";
}

.fa-tamale:before {
  content: "";
}

.fa-box-check:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-steak:before {
  content: "";
}

.fa-location-crosshairs-slash:before, .fa-location-slash:before {
  content: "";
}

.fa-person-dolly:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-synagogue:before {
  content: "";
}

.fa-file-chart-column:before, .fa-file-chart-line:before {
  content: "";
}

.fa-person-half-dress:before {
  content: "";
}

.fa-folder-image:before {
  content: "";
}

.fa-calendar-pen:before, .fa-calendar-edit:before {
  content: "";
}

.fa-road-bridge:before {
  content: "";
}

.fa-face-smile-tear:before {
  content: "";
}

.fa-message-plus:before, .fa-comment-alt-plus:before {
  content: "";
}

.fa-location-arrow:before {
  content: "";
}

.fa-c:before {
  content: "C";
}

.fa-tablet-button:before {
  content: "";
}

.fa-person-dress-fairy:before {
  content: "";
}

.fa-rectangle-history-circle-user:before {
  content: "";
}

.fa-building-lock:before {
  content: "";
}

.fa-chart-line-up:before {
  content: "";
}

.fa-mailbox:before {
  content: "";
}

.fa-sign-posts:before {
  content: "";
}

.fa-truck-bolt:before {
  content: "";
}

.fa-pizza-slice:before {
  content: "";
}

.fa-money-bill-wave:before {
  content: "";
}

.fa-chart-area:before, .fa-area-chart:before {
  content: "";
}

.fa-house-flag:before {
  content: "";
}

.fa-circle-three-quarters-stroke:before {
  content: "";
}

.fa-person-circle-minus:before {
  content: "";
}

.fa-scalpel:before {
  content: "";
}

.fa-ban:before, .fa-cancel:before {
  content: "";
}

.fa-bell-exclamation:before {
  content: "";
}

.fa-circle-bookmark:before, .fa-bookmark-circle:before {
  content: "";
}

.fa-egg-fried:before {
  content: "";
}

.fa-face-weary:before {
  content: "";
}

.fa-uniform-martial-arts:before {
  content: "";
}

.fa-camera-rotate:before {
  content: "";
}

.fa-sun-dust:before {
  content: "";
}

.fa-comment-text:before {
  content: "";
}

.fa-spray-can-sparkles:before, .fa-air-freshener:before {
  content: "";
}

.fa-signal-bars:before, .fa-signal-alt:before, .fa-signal-alt-4:before, .fa-signal-bars-strong:before {
  content: "";
}

.fa-diamond-exclamation:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-dial-min:before {
  content: "";
}

.fa-repeat:before {
  content: "";
}

.fa-cross:before {
  content: "";
}

.fa-page-caret-down:before, .fa-file-caret-down:before {
  content: "";
}

.fa-box:before {
  content: "";
}

.fa-venus-mars:before {
  content: "";
}

.fa-clock-seven-thirty:before {
  content: "";
}

.fa-arrow-pointer:before, .fa-mouse-pointer:before {
  content: "";
}

.fa-clock-four-thirty:before {
  content: "";
}

.fa-signal-bars-good:before, .fa-signal-alt-3:before {
  content: "";
}

.fa-cactus:before {
  content: "";
}

.fa-lightbulb-gear:before {
  content: "";
}

.fa-maximize:before, .fa-expand-arrows-alt:before {
  content: "";
}

.fa-charging-station:before {
  content: "";
}

.fa-shapes:before, .fa-triangle-circle-square:before {
  content: "";
}

.fa-plane-tail:before {
  content: "";
}

.fa-gauge-simple-max:before, .fa-tachometer-fastest:before {
  content: "";
}

.fa-circle-u:before {
  content: "";
}

.fa-shield-slash:before {
  content: "";
}

.fa-square-phone-hangup:before, .fa-phone-square-down:before {
  content: "";
}

.fa-arrow-up-left:before {
  content: "";
}

.fa-transporter-1:before {
  content: "";
}

.fa-peanuts:before {
  content: "";
}

.fa-shuffle:before, .fa-random:before {
  content: "";
}

.fa-person-running:before, .fa-running:before {
  content: "";
}

.fa-mobile-retro:before {
  content: "";
}

.fa-grip-lines-vertical:before {
  content: "";
}

.fa-bin-bottles-recycle:before {
  content: "";
}

.fa-arrow-up-from-square:before {
  content: "";
}

.fa-file-dashed-line:before, .fa-page-break:before {
  content: "";
}

.fa-bracket-curly-right:before {
  content: "}";
}

.fa-spider:before {
  content: "";
}

.fa-clock-three:before {
  content: "";
}

.fa-hands-bound:before {
  content: "";
}

.fa-scalpel-line-dashed:before, .fa-scalpel-path:before {
  content: "";
}

.fa-file-invoice-dollar:before {
  content: "";
}

.fa-pipe-smoking:before {
  content: "";
}

.fa-face-astonished:before {
  content: "";
}

.fa-window:before {
  content: "";
}

.fa-plane-circle-exclamation:before {
  content: "";
}

.fa-ear:before {
  content: "";
}

.fa-file-lock:before {
  content: "";
}

.fa-diagram-venn:before {
  content: "";
}

.fa-arrow-down-from-bracket:before {
  content: "";
}

.fa-x-ray:before {
  content: "";
}

.fa-goal-net:before {
  content: "";
}

.fa-coffin-cross:before {
  content: "";
}

.fa-octopus:before {
  content: "";
}

.fa-spell-check:before {
  content: "";
}

.fa-location-xmark:before, .fa-map-marker-times:before, .fa-map-marker-xmark:before {
  content: "";
}

.fa-circle-quarter-stroke:before {
  content: "";
}

.fa-lasso:before {
  content: "";
}

.fa-slash:before {
  content: "";
}

.fa-person-to-portal:before, .fa-portal-enter:before {
  content: "";
}

.fa-calendar-star:before {
  content: "";
}

.fa-computer-mouse:before, .fa-mouse:before {
  content: "";
}

.fa-arrow-right-to-bracket:before, .fa-sign-in:before {
  content: "";
}

.fa-pegasus:before {
  content: "";
}

.fa-files-medical:before {
  content: "";
}

.fa-cannon:before {
  content: "";
}

.fa-nfc-lock:before {
  content: "";
}

.fa-person-ski-lift:before, .fa-ski-lift:before {
  content: "";
}

.fa-square-6:before {
  content: "";
}

.fa-shop-slash:before, .fa-store-alt-slash:before {
  content: "";
}

.fa-wind-turbine:before {
  content: "";
}

.fa-sliders-simple:before {
  content: "";
}

.fa-grid-round:before {
  content: "";
}

.fa-badge-sheriff:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-virus-covid-slash:before {
  content: "";
}

.fa-intersection:before {
  content: "";
}

.fa-shop-lock:before {
  content: "";
}

.fa-family:before {
  content: "";
}

.fa-hourglass-start:before, .fa-hourglass-1:before {
  content: "";
}

.fa-user-hair-buns:before {
  content: "";
}

.fa-blender-phone:before {
  content: "";
}

.fa-hourglass-clock:before {
  content: "";
}

.fa-person-seat-reclined:before {
  content: "";
}

.fa-paper-plane-top:before, .fa-paper-plane-alt:before, .fa-send:before {
  content: "";
}

.fa-message-arrow-up:before, .fa-comment-alt-arrow-up:before {
  content: "";
}

.fa-lightbulb-exclamation:before {
  content: "";
}

.fa-layer-minus:before, .fa-layer-group-minus:before {
  content: "";
}

.fa-chart-pie-simple-circle-currency:before {
  content: "";
}

.fa-circle-e:before {
  content: "";
}

.fa-building-wheat:before {
  content: "";
}

.fa-gauge-max:before, .fa-tachometer-alt-fastest:before {
  content: "";
}

.fa-person-breastfeeding:before {
  content: "";
}

.fa-apostrophe:before {
  content: "'";
}

.fa-file-png:before {
  content: "";
}

.fa-fire-hydrant:before {
  content: "";
}

.fa-right-to-bracket:before, .fa-sign-in-alt:before {
  content: "";
}

.fa-video-plus:before {
  content: "";
}

.fa-square-right:before, .fa-arrow-alt-square-right:before {
  content: "";
}

.fa-comment-smile:before {
  content: "";
}

.fa-venus:before {
  content: "";
}

.fa-passport:before {
  content: "";
}

.fa-thumbtack-slash:before, .fa-thumb-tack-slash:before {
  content: "";
}

.fa-inbox-in:before, .fa-inbox-arrow-down:before {
  content: "";
}

.fa-heart-pulse:before, .fa-heartbeat:before {
  content: "";
}

.fa-circle-8:before {
  content: "";
}

.fa-clouds-moon:before {
  content: "";
}

.fa-clock-ten-thirty:before {
  content: "";
}

.fa-people-carry-box:before, .fa-people-carry:before {
  content: "";
}

.fa-folder-user:before {
  content: "";
}

.fa-trash-can-xmark:before {
  content: "";
}

.fa-temperature-high:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-left-long-to-line:before {
  content: "";
}

.fa-crown:before {
  content: "";
}

.fa-weight-hanging:before {
  content: "";
}

.fa-xmarks-lines:before {
  content: "";
}

.fa-file-prescription:before {
  content: "";
}

.fa-table-cells-lock:before {
  content: "";
}

.fa-calendar-range:before {
  content: "";
}

.fa-flower-daffodil:before {
  content: "";
}

.fa-hand-back-point-up:before {
  content: "";
}

.fa-weight-scale:before, .fa-weight:before {
  content: "";
}

.fa-arrow-up-to-arc:before {
  content: "";
}

.fa-star-exclamation:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-user-group:before, .fa-user-friends:before {
  content: "";
}

.fa-arrow-up-a-z:before, .fa-sort-alpha-up:before {
  content: "";
}

.fa-layer-plus:before, .fa-layer-group-plus:before {
  content: "";
}

.fa-play-pause:before {
  content: "";
}

.fa-block-question:before {
  content: "";
}

.fa-snooze:before, .fa-zzz:before {
  content: "";
}

.fa-scanner-image:before {
  content: "";
}

.fa-tv-retro:before {
  content: "";
}

.fa-square-t:before {
  content: "";
}

.fa-farm:before, .fa-barn-silo:before {
  content: "";
}

.fa-chess-knight:before {
  content: "";
}

.fa-bars-sort:before {
  content: "";
}

.fa-pallet-boxes:before, .fa-palette-boxes:before, .fa-pallet-alt:before {
  content: "";
}

.fa-face-laugh-squint:before, .fa-laugh-squint:before {
  content: "";
}

.fa-code-simple:before {
  content: "";
}

.fa-bolt-slash:before {
  content: "";
}

.fa-panel-fire:before {
  content: "";
}

.fa-binary-circle-check:before {
  content: "";
}

.fa-comment-minus:before {
  content: "";
}

.fa-burrito:before {
  content: "";
}

.fa-violin:before {
  content: "";
}

.fa-objects-column:before {
  content: "";
}

.fa-square-chevron-down:before, .fa-chevron-square-down:before {
  content: "";
}

.fa-comment-plus:before {
  content: "";
}

.fa-triangle-instrument:before, .fa-triangle-music:before {
  content: "";
}

.fa-wheelchair:before {
  content: "";
}

.fa-user-pilot-tie:before {
  content: "";
}

.fa-piano-keyboard:before {
  content: "";
}

.fa-bed-empty:before {
  content: "";
}

.fa-circle-arrow-up:before, .fa-arrow-circle-up:before {
  content: "";
}

.fa-toggle-on:before {
  content: "";
}

.fa-rectangle-vertical:before, .fa-rectangle-portrait:before {
  content: "";
}

.fa-person-walking:before, .fa-walking:before {
  content: "";
}

.fa-l:before {
  content: "L";
}

.fa-signal-stream:before {
  content: "";
}

.fa-down-to-bracket:before {
  content: "";
}

.fa-circle-z:before {
  content: "";
}

.fa-stars:before {
  content: "";
}

.fa-fire:before {
  content: "";
}

.fa-bed-pulse:before, .fa-procedures:before {
  content: "";
}

.fa-house-day:before {
  content: "";
}

.fa-shuttle-space:before, .fa-space-shuttle:before {
  content: "";
}

.fa-shirt-long-sleeve:before {
  content: "";
}

.fa-chart-pie-simple:before, .fa-chart-pie-alt:before {
  content: "";
}

.fa-face-laugh:before, .fa-laugh:before {
  content: "";
}

.fa-folder-open:before {
  content: "";
}

.fa-album-collection-circle-user:before {
  content: "";
}

.fa-candy:before {
  content: "";
}

.fa-bowl-hot:before, .fa-soup:before {
  content: "";
}

.fa-flatbread:before {
  content: "";
}

.fa-heart-circle-plus:before {
  content: "";
}

.fa-code-fork:before {
  content: "";
}

.fa-city:before {
  content: "";
}

.fa-signal-bars-weak:before, .fa-signal-alt-1:before {
  content: "";
}

.fa-microphone-lines:before, .fa-microphone-alt:before {
  content: "";
}

.fa-clock-twelve:before {
  content: "";
}

.fa-pepper-hot:before {
  content: "";
}

.fa-citrus-slice:before {
  content: "";
}

.fa-sheep:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-colon-sign:before {
  content: "";
}

.fa-headset:before {
  content: "";
}

.fa-badger-honey:before {
  content: "";
}

.fa-h4:before {
  content: "";
}

.fa-store-slash:before {
  content: "";
}

.fa-road-circle-xmark:before {
  content: "";
}

.fa-signal-slash:before {
  content: "";
}

.fa-user-minus:before {
  content: "";
}

.fa-mars-stroke-up:before, .fa-mars-stroke-v:before {
  content: "";
}

.fa-champagne-glasses:before, .fa-glass-cheers:before {
  content: "";
}

.fa-taco:before {
  content: "";
}

.fa-hexagon-plus:before, .fa-plus-hexagon:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-house-circle-exclamation:before {
  content: "";
}

.fa-file-arrow-up:before, .fa-file-upload:before {
  content: "";
}

.fa-wifi:before, .fa-wifi-3:before, .fa-wifi-strong:before {
  content: "";
}

.fa-messages:before, .fa-comments-alt:before {
  content: "";
}

.fa-bath:before, .fa-bathtub:before {
  content: "";
}

.fa-umbrella-simple:before, .fa-umbrella-alt:before {
  content: "";
}

.fa-rectangle-history-circle-plus:before {
  content: "";
}

.fa-underline:before {
  content: "";
}

.fa-prescription-bottle-pill:before {
  content: "";
}

.fa-user-pen:before, .fa-user-edit:before {
  content: "";
}

.fa-binary-slash:before {
  content: "";
}

.fa-square-o:before {
  content: "";
}

.fa-caduceus:before {
  content: "";
}

.fa-signature:before {
  content: "";
}

.fa-stroopwafel:before {
  content: "";
}

.fa-bold:before {
  content: "";
}

.fa-anchor-lock:before {
  content: "";
}

.fa-building-ngo:before {
  content: "";
}

.fa-transporter-3:before {
  content: "";
}

.fa-engine-warning:before, .fa-engine-exclamation:before {
  content: "";
}

.fa-circle-down-right:before {
  content: "";
}

.fa-square-k:before {
  content: "";
}

.fa-manat-sign:before {
  content: "";
}

.fa-money-check-pen:before, .fa-money-check-edit:before {
  content: "";
}

.fa-not-equal:before {
  content: "";
}

.fa-border-top-left:before, .fa-border-style:before {
  content: "";
}

.fa-map-location-dot:before, .fa-map-marked-alt:before {
  content: "";
}

.fa-tilde:before {
  content: "~";
}

.fa-jedi:before {
  content: "";
}

.fa-square-poll-vertical:before, .fa-poll:before {
  content: "";
}

.fa-arrow-down-square-triangle:before, .fa-sort-shapes-down-alt:before {
  content: "";
}

.fa-mug-hot:before {
  content: "";
}

.fa-dog-leashed:before {
  content: "";
}

.fa-car-battery:before, .fa-battery-car:before {
  content: "";
}

.fa-face-downcast-sweat:before {
  content: "";
}

.fa-mailbox-flag-up:before {
  content: "";
}

.fa-memo-circle-info:before {
  content: "";
}

.fa-gift:before {
  content: "";
}

.fa-dice-two:before {
  content: "";
}

.fa-volume:before, .fa-volume-medium:before {
  content: "";
}

.fa-transporter-5:before {
  content: "";
}

.fa-gauge-circle-bolt:before {
  content: "";
}

.fa-coin-front:before {
  content: "";
}

.fa-file-slash:before {
  content: "";
}

.fa-message-arrow-up-right:before {
  content: "";
}

.fa-treasure-chest:before {
  content: "";
}

.fa-chess-queen:before {
  content: "";
}

.fa-paintbrush-fine:before, .fa-paint-brush-alt:before, .fa-paint-brush-fine:before, .fa-paintbrush-alt:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-hood-cloak:before {
  content: "";
}

.fa-square-quote:before {
  content: "";
}

.fa-up-left:before {
  content: "";
}

.fa-bring-front:before {
  content: "";
}

.fa-chess-board:before {
  content: "";
}

.fa-burger-cheese:before, .fa-cheeseburger:before {
  content: "";
}

.fa-building-circle-check:before {
  content: "";
}

.fa-repeat-1:before {
  content: "";
}

.fa-arrow-down-to-line:before, .fa-arrow-to-bottom:before {
  content: "";
}

.fa-grid-5:before {
  content: "";
}

.fa-swap-arrows:before {
  content: "";
}

.fa-right-long-to-line:before {
  content: "";
}

.fa-person-chalkboard:before {
  content: "";
}

.fa-mars-stroke-right:before, .fa-mars-stroke-h:before {
  content: "";
}

.fa-hand-back-fist:before, .fa-hand-rock:before {
  content: "";
}

.fa-grid-round-5:before {
  content: "";
}

.fa-tally:before, .fa-tally-5:before {
  content: "";
}

.fa-square-caret-up:before, .fa-caret-square-up:before {
  content: "";
}

.fa-cloud-showers-water:before {
  content: "";
}

.fa-chart-bar:before, .fa-bar-chart:before {
  content: "";
}

.fa-hands-bubbles:before, .fa-hands-wash:before {
  content: "";
}

.fa-less-than-equal:before {
  content: "";
}

.fa-train:before {
  content: "";
}

.fa-up-from-dotted-line:before {
  content: "";
}

.fa-eye-low-vision:before, .fa-low-vision:before {
  content: "";
}

.fa-traffic-light-go:before {
  content: "";
}

.fa-face-exhaling:before {
  content: "";
}

.fa-sensor-fire:before {
  content: "";
}

.fa-user-unlock:before {
  content: "";
}

.fa-hexagon-divide:before {
  content: "";
}

.fa-00:before {
  content: "";
}

.fa-crow:before {
  content: "";
}

.fa-cassette-betamax:before, .fa-betamax:before {
  content: "";
}

.fa-sailboat:before {
  content: "";
}

.fa-window-restore:before {
  content: "";
}

.fa-nfc-magnifying-glass:before {
  content: "";
}

.fa-file-binary:before {
  content: "";
}

.fa-circle-v:before {
  content: "";
}

.fa-square-plus:before, .fa-plus-square:before {
  content: "";
}

.fa-bowl-scoops:before {
  content: "";
}

.fa-mistletoe:before {
  content: "";
}

.fa-custard:before {
  content: "";
}

.fa-lacrosse-stick:before {
  content: "";
}

.fa-hockey-mask:before {
  content: "";
}

.fa-sunrise:before {
  content: "";
}

.fa-subtitles:before {
  content: "";
}

.fa-panel-ews:before {
  content: "";
}

.fa-torii-gate:before {
  content: "";
}

.fa-cloud-exclamation:before {
  content: "";
}

.fa-message-lines:before, .fa-comment-alt-lines:before {
  content: "";
}

.fa-frog:before {
  content: "";
}

.fa-bucket:before {
  content: "";
}

.fa-floppy-disk-pen:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-window-frame:before {
  content: "";
}

.fa-microphone:before {
  content: "";
}

.fa-cow:before {
  content: "";
}

.fa-file-zip:before {
  content: "";
}

.fa-square-ring:before {
  content: "";
}

.fa-down-from-line:before, .fa-arrow-alt-from-top:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-shield-xmark:before, .fa-shield-times:before {
  content: "";
}

.fa-screwdriver:before {
  content: "";
}

.fa-circle-sort-down:before, .fa-sort-circle-down:before {
  content: "";
}

.fa-folder-closed:before {
  content: "";
}

.fa-house-tsunami:before {
  content: "";
}

.fa-square-nfi:before {
  content: "";
}

.fa-forklift:before {
  content: "";
}

.fa-arrow-up-from-ground-water:before {
  content: "";
}

.fa-bracket-square-right:before {
  content: "]";
}

.fa-martini-glass:before, .fa-glass-martini-alt:before {
  content: "";
}

.fa-rotate-left:before, .fa-rotate-back:before, .fa-rotate-backward:before, .fa-undo-alt:before {
  content: "";
}

.fa-table-columns:before, .fa-columns:before {
  content: "";
}

.fa-square-a:before {
  content: "";
}

.fa-tick:before {
  content: "";
}

.fa-lemon:before {
  content: "";
}

.fa-head-side-mask:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-gem:before {
  content: "";
}

.fa-dolly:before, .fa-dolly-box:before {
  content: "";
}

.fa-smoking:before {
  content: "";
}

.fa-minimize:before, .fa-compress-arrows-alt:before {
  content: "";
}

.fa-refrigerator:before {
  content: "";
}

.fa-monument:before {
  content: "";
}

.fa-octagon-xmark:before, .fa-times-octagon:before, .fa-xmark-octagon:before {
  content: "";
}

.fa-align-slash:before {
  content: "";
}

.fa-snowplow:before {
  content: "";
}

.fa-angles-right:before, .fa-angle-double-right:before {
  content: "";
}

.fa-truck-ramp-couch:before, .fa-truck-couch:before {
  content: "";
}

.fa-cannabis:before {
  content: "";
}

.fa-circle-play:before, .fa-play-circle:before {
  content: "";
}

.fa-arrow-up-right-and-arrow-down-left-from-center:before {
  content: "";
}

.fa-location-arrow-up:before {
  content: "";
}

.fa-tablets:before {
  content: "";
}

.fa-360-degrees:before {
  content: "";
}

.fa-ethernet:before {
  content: "";
}

.fa-euro-sign:before, .fa-eur:before, .fa-euro:before {
  content: "";
}

.fa-chair:before {
  content: "";
}

.fa-circle-check:before, .fa-check-circle:before {
  content: "";
}

.fa-square-dashed-circle-plus:before {
  content: "";
}

.fa-hand-holding-circle-dollar:before {
  content: "";
}

.fa-money-simple-from-bracket:before {
  content: "";
}

.fa-bat:before {
  content: "";
}

.fa-circle-stop:before, .fa-stop-circle:before {
  content: "";
}

.fa-head-side-headphones:before {
  content: "";
}

.fa-phone-rotary:before {
  content: "";
}

.fa-arrow-up-to-bracket:before {
  content: "";
}

.fa-compass-drafting:before, .fa-drafting-compass:before {
  content: "";
}

.fa-plate-wheat:before {
  content: "";
}

.fa-calendar-circle-minus:before {
  content: "";
}

.fa-chopsticks:before {
  content: "";
}

.fa-car-wrench:before, .fa-car-mechanic:before {
  content: "";
}

.fa-icicles:before {
  content: "";
}

.fa-person-shelter:before {
  content: "";
}

.fa-neuter:before {
  content: "";
}

.fa-id-badge:before {
  content: "";
}

.fa-kazoo:before {
  content: "";
}

.fa-marker:before {
  content: "";
}

.fa-bin-bottles:before {
  content: "";
}

.fa-face-laugh-beam:before, .fa-laugh-beam:before {
  content: "";
}

.fa-square-arrow-down-left:before {
  content: "";
}

.fa-battery-bolt:before {
  content: "";
}

.fa-tree-large:before {
  content: "";
}

.fa-helicopter-symbol:before {
  content: "";
}

.fa-aperture:before {
  content: "";
}

.fa-universal-access:before {
  content: "";
}

.fa-gear-complex:before {
  content: "";
}

.fa-file-magnifying-glass:before, .fa-file-search:before {
  content: "";
}

.fa-up-right:before {
  content: "";
}

.fa-circle-chevron-up:before, .fa-chevron-circle-up:before {
  content: "";
}

.fa-user-police:before {
  content: "";
}

.fa-lari-sign:before {
  content: "";
}

.fa-volcano:before {
  content: "";
}

.fa-teddy-bear:before {
  content: "";
}

.fa-stocking:before {
  content: "";
}

.fa-person-walking-dashed-line-arrow-right:before {
  content: "";
}

.fa-image-slash:before {
  content: "";
}

.fa-mask-snorkel:before {
  content: "";
}

.fa-smoke:before {
  content: "";
}

.fa-sterling-sign:before, .fa-gbp:before, .fa-pound-sign:before {
  content: "";
}

.fa-battery-exclamation:before {
  content: "";
}

.fa-viruses:before {
  content: "";
}

.fa-square-person-confined:before {
  content: "";
}

.fa-user-tie:before {
  content: "";
}

.fa-up-to-bracket:before {
  content: "";
}

.fa-arrow-down-long:before, .fa-long-arrow-down:before {
  content: "";
}

.fa-tent-arrow-down-to-line:before {
  content: "";
}

.fa-certificate:before {
  content: "";
}

.fa-crystal-ball:before {
  content: "";
}

.fa-reply-all:before, .fa-mail-reply-all:before {
  content: "";
}

.fa-suitcase:before {
  content: "";
}

.fa-person-skating:before, .fa-skating:before {
  content: "";
}

.fa-star-shooting:before {
  content: "";
}

.fa-binary-lock:before {
  content: "";
}

.fa-filter-circle-dollar:before, .fa-funnel-dollar:before {
  content: "";
}

.fa-camera-retro:before {
  content: "";
}

.fa-circle-arrow-down:before, .fa-arrow-circle-down:before {
  content: "";
}

.fa-comment-pen:before, .fa-comment-edit:before {
  content: "";
}

.fa-file-import:before, .fa-arrow-right-to-file:before {
  content: "";
}

.fa-banjo:before {
  content: "";
}

.fa-square-arrow-up-right:before, .fa-external-link-square:before {
  content: "";
}

.fa-light-emergency-on:before {
  content: "";
}

.fa-kerning:before {
  content: "";
}

.fa-box-open:before {
  content: "";
}

.fa-square-f:before {
  content: "";
}

.fa-scroll:before {
  content: "";
}

.fa-spa:before {
  content: "";
}

.fa-arrow-left-from-line:before, .fa-arrow-from-right:before {
  content: "";
}

.fa-strawberry:before {
  content: "";
}

.fa-location-pin-lock:before {
  content: "";
}

.fa-pause:before {
  content: "";
}

.fa-clock-eight-thirty:before {
  content: "";
}

.fa-plane-engines:before, .fa-plane-alt:before {
  content: "";
}

.fa-hill-avalanche:before {
  content: "";
}

.fa-temperature-empty:before, .fa-temperature-0:before, .fa-thermometer-0:before, .fa-thermometer-empty:before {
  content: "";
}

.fa-bomb:before {
  content: "";
}

.fa-gauge-low:before, .fa-tachometer-alt-slow:before {
  content: "";
}

.fa-registered:before {
  content: "";
}

.fa-trash-can-plus:before {
  content: "";
}

.fa-address-card:before, .fa-contact-card:before, .fa-vcard:before {
  content: "";
}

.fa-scale-unbalanced-flip:before, .fa-balance-scale-right:before {
  content: "";
}

.fa-globe-snow:before {
  content: "";
}

.fa-subscript:before {
  content: "";
}

.fa-diamond-turn-right:before, .fa-directions:before {
  content: "";
}

.fa-integral:before {
  content: "";
}

.fa-burst:before {
  content: "";
}

.fa-house-laptop:before, .fa-laptop-house:before {
  content: "";
}

.fa-face-tired:before, .fa-tired:before {
  content: "";
}

.fa-money-bills:before {
  content: "";
}

.fa-blinds-raised:before {
  content: "";
}

.fa-smog:before {
  content: "";
}

.fa-ufo-beam:before {
  content: "";
}

.fa-hydra:before {
  content: "";
}

.fa-circle-caret-up:before, .fa-caret-circle-up:before {
  content: "";
}

.fa-user-vneck-hair-long:before {
  content: "";
}

.fa-square-a-lock:before {
  content: "";
}

.fa-crutch:before {
  content: "";
}

.fa-gas-pump-slash:before {
  content: "";
}

.fa-cloud-arrow-up:before, .fa-cloud-upload:before, .fa-cloud-upload-alt:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-transporter-4:before {
  content: "";
}

.fa-chart-mixed-up-circle-currency:before {
  content: "";
}

.fa-objects-align-right:before {
  content: "";
}

.fa-arrows-turn-right:before {
  content: "";
}

.fa-vest:before {
  content: "";
}

.fa-pig:before {
  content: "";
}

.fa-inbox-full:before {
  content: "";
}

.fa-circle-envelope:before, .fa-envelope-circle:before {
  content: "";
}

.fa-triangle-person-digging:before, .fa-construction:before {
  content: "";
}

.fa-ferry:before {
  content: "";
}

.fa-bullseye-arrow:before {
  content: "";
}

.fa-arrows-down-to-people:before {
  content: "";
}

.fa-seedling:before, .fa-sprout:before {
  content: "";
}

.fa-clock-seven:before {
  content: "";
}

.fa-left-right:before, .fa-arrows-alt-h:before {
  content: "";
}

.fa-boxes-packing:before {
  content: "";
}

.fa-circle-arrow-left:before, .fa-arrow-circle-left:before {
  content: "";
}

.fa-flashlight:before {
  content: "";
}

.fa-file-jpg:before {
  content: "";
}

.fa-group-arrows-rotate:before {
  content: "";
}

.fa-bowl-food:before {
  content: "";
}

.fa-square-9:before {
  content: "";
}

.fa-candy-cane:before {
  content: "";
}

.fa-arrow-down-wide-short:before, .fa-sort-amount-asc:before, .fa-sort-amount-down:before {
  content: "";
}

.fa-square-dollar:before, .fa-dollar-square:before, .fa-usd-square:before {
  content: "";
}

.fa-phone-arrow-right:before {
  content: "";
}

.fa-hand-holding-seedling:before {
  content: "";
}

.fa-message-check:before, .fa-comment-alt-check:before {
  content: "";
}

.fa-cloud-bolt:before, .fa-thunderstorm:before {
  content: "";
}

.fa-chart-line-up-down:before {
  content: "";
}

.fa-text-slash:before, .fa-remove-format:before {
  content: "";
}

.fa-watch:before {
  content: "";
}

.fa-circle-down-left:before {
  content: "";
}

.fa-text:before {
  content: "";
}

.fa-projector:before {
  content: "";
}

.fa-face-smile-wink:before, .fa-smile-wink:before {
  content: "";
}

.fa-tombstone-blank:before, .fa-tombstone-alt:before {
  content: "";
}

.fa-chess-king-piece:before, .fa-chess-king-alt:before {
  content: "";
}

.fa-circle-6:before {
  content: "";
}

.fa-waves-sine:before {
  content: "";
}

.fa-left:before, .fa-arrow-alt-left:before {
  content: "";
}

.fa-file-word:before {
  content: "";
}

.fa-file-powerpoint:before {
  content: "";
}

.fa-square-down:before, .fa-arrow-alt-square-down:before {
  content: "";
}

.fa-objects-align-center-vertical:before {
  content: "";
}

.fa-arrows-left-right:before, .fa-arrows-h:before {
  content: "";
}

.fa-house-lock:before {
  content: "";
}

.fa-cloud-arrow-down:before, .fa-cloud-download:before, .fa-cloud-download-alt:before {
  content: "";
}

.fa-wreath:before {
  content: "";
}

.fa-children:before {
  content: "";
}

.fa-meter-droplet:before {
  content: "";
}

.fa-chalkboard:before, .fa-blackboard:before {
  content: "";
}

.fa-user-large-slash:before, .fa-user-alt-slash:before {
  content: "";
}

.fa-signal-strong:before, .fa-signal-4:before {
  content: "";
}

.fa-lollipop:before, .fa-lollypop:before {
  content: "";
}

.fa-list-tree:before {
  content: "";
}

.fa-envelope-open:before {
  content: "";
}

.fa-draw-circle:before {
  content: "";
}

.fa-cat-space:before {
  content: "";
}

.fa-handshake-simple-slash:before, .fa-handshake-alt-slash:before {
  content: "";
}

.fa-rabbit-running:before, .fa-rabbit-fast:before {
  content: "";
}

.fa-memo-pad:before {
  content: "";
}

.fa-mattress-pillow:before {
  content: "";
}

.fa-alarm-plus:before {
  content: "";
}

.fa-alicorn:before {
  content: "";
}

.fa-comment-question:before {
  content: "";
}

.fa-gingerbread-man:before {
  content: "";
}

.fa-guarani-sign:before {
  content: "";
}

.fa-burger-fries:before {
  content: "";
}

.fa-mug-tea:before {
  content: "";
}

.fa-border-top:before {
  content: "";
}

.fa-arrows-rotate:before, .fa-refresh:before, .fa-sync:before {
  content: "";
}

.fa-circle-book-open:before, .fa-book-circle:before {
  content: "";
}

.fa-arrows-to-dotted-line:before {
  content: "";
}

.fa-fire-extinguisher:before {
  content: "";
}

.fa-magnifying-glass-arrows-rotate:before {
  content: "";
}

.fa-garage-open:before {
  content: "";
}

.fa-shelves-empty:before {
  content: "";
}

.fa-cruzeiro-sign:before {
  content: "";
}

.fa-watch-apple:before {
  content: "";
}

.fa-watch-calculator:before {
  content: "";
}

.fa-list-dropdown:before {
  content: "";
}

.fa-cabinet-filing:before {
  content: "";
}

.fa-burger-soda:before {
  content: "";
}

.fa-square-arrow-up:before, .fa-arrow-square-up:before {
  content: "";
}

.fa-greater-than-equal:before {
  content: "";
}

.fa-pallet-box:before {
  content: "";
}

.fa-face-confounded:before {
  content: "";
}

.fa-shield-halved:before, .fa-shield-alt:before {
  content: "";
}

.fa-truck-plow:before {
  content: "";
}

.fa-book-atlas:before, .fa-atlas:before {
  content: "";
}

.fa-virus:before {
  content: "";
}

.fa-grid-round-2:before {
  content: "";
}

.fa-comment-middle-top:before {
  content: "";
}

.fa-wave:before {
  content: "";
}

.fa-envelope-circle-check:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-restroom-simple:before {
  content: "";
}

.fa-arrows-to-dot:before {
  content: "";
}

.fa-border-outer:before {
  content: "";
}

.fa-hashtag-lock:before {
  content: "";
}

.fa-clock-two-thirty:before {
  content: "";
}

.fa-archway:before {
  content: "";
}

.fa-heart-circle-check:before {
  content: "";
}

.fa-house-chimney-crack:before, .fa-house-damage:before {
  content: "";
}

.fa-file-zipper:before, .fa-file-archive:before {
  content: "";
}

.fa-ticket-perforated:before {
  content: "";
}

.fa-heart-half:before {
  content: "";
}

.fa-comment-check:before {
  content: "";
}

.fa-square:before {
  content: "";
}

.fa-memo:before {
  content: "";
}

.fa-martini-glass-empty:before, .fa-glass-martini:before {
  content: "";
}

.fa-couch:before {
  content: "";
}

.fa-cedi-sign:before {
  content: "";
}

.fa-italic:before {
  content: "";
}

.fa-glass-citrus:before {
  content: "";
}

.fa-calendar-lines-pen:before {
  content: "";
}

.fa-table-cells-column-lock:before {
  content: "";
}

.fa-church:before {
  content: "";
}

.fa-person-snowmobiling:before, .fa-snowmobile:before {
  content: "";
}

.fa-face-hushed:before {
  content: "";
}

.fa-comments-dollar:before {
  content: "";
}

.fa-tickets-simple:before {
  content: "";
}

.fa-pickaxe:before {
  content: "";
}

.fa-link-simple-slash:before {
  content: "";
}

.fa-democrat:before {
  content: "";
}

.fa-face-confused:before {
  content: "";
}

.fa-pinball:before {
  content: "";
}

.fa-z:before {
  content: "Z";
}

.fa-person-skiing:before, .fa-skiing:before {
  content: "";
}

.fa-deer:before {
  content: "";
}

.fa-input-pipe:before {
  content: "";
}

.fa-road-lock:before {
  content: "";
}

.fa-a:before {
  content: "A";
}

.fa-bookmark-slash:before {
  content: "";
}

.fa-temperature-arrow-down:before, .fa-temperature-down:before {
  content: "";
}

.fa-mace:before {
  content: "";
}

.fa-feather-pointed:before, .fa-feather-alt:before {
  content: "";
}

.fa-sausage:before {
  content: "";
}

.fa-trash-can-clock:before {
  content: "";
}

.fa-p:before {
  content: "P";
}

.fa-broom-wide:before {
  content: "";
}

.fa-snowflake:before {
  content: "";
}

.fa-stomach:before {
  content: "";
}

.fa-newspaper:before {
  content: "";
}

.fa-rectangle-ad:before, .fa-ad:before {
  content: "";
}

.fa-guitar-electric:before {
  content: "";
}

.fa-arrow-turn-down-right:before {
  content: "";
}

.fa-moon-cloud:before {
  content: "";
}

.fa-bread-slice-butter:before {
  content: "";
}

.fa-circle-arrow-right:before, .fa-arrow-circle-right:before {
  content: "";
}

.fa-user-group-crown:before, .fa-users-crown:before {
  content: "";
}

.fa-circle-i:before {
  content: "";
}

.fa-toilet-paper-check:before {
  content: "";
}

.fa-filter-circle-xmark:before {
  content: "";
}

.fa-locust:before {
  content: "";
}

.fa-sort:before, .fa-unsorted:before {
  content: "";
}

.fa-list-ol:before, .fa-list-1-2:before, .fa-list-numeric:before {
  content: "";
}

.fa-chart-waterfall:before {
  content: "";
}

.fa-sparkle:before {
  content: "";
}

.fa-face-party:before {
  content: "";
}

.fa-kidneys:before {
  content: "";
}

.fa-wifi-exclamation:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-person-dress-burst:before {
  content: "";
}

.fa-dice-d4:before {
  content: "";
}

.fa-money-check-dollar:before, .fa-money-check-alt:before {
  content: "";
}

.fa-vector-square:before {
  content: "";
}

.fa-bread-slice:before {
  content: "";
}

.fa-language:before {
  content: "";
}

.fa-wheat-awn-slash:before {
  content: "";
}

.fa-face-kiss-wink-heart:before, .fa-kiss-wink-heart:before {
  content: "";
}

.fa-dagger:before {
  content: "";
}

.fa-podium:before {
  content: "";
}

.fa-diamonds-4:before {
  content: "";
}

.fa-memo-circle-check:before {
  content: "";
}

.fa-route-highway:before {
  content: "";
}

.fa-down-to-line:before, .fa-arrow-alt-to-bottom:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-square-g:before {
  content: "";
}

.fa-circle-phone:before, .fa-phone-circle:before {
  content: "";
}

.fa-clipboard-prescription:before {
  content: "";
}

.fa-user-nurse-hair:before {
  content: "";
}

.fa-question:before {
  content: "?";
}

.fa-file-signature:before {
  content: "";
}

.fa-toggle-large-on:before {
  content: "";
}

.fa-up-down-left-right:before, .fa-arrows-alt:before {
  content: "";
}

.fa-dryer-heat:before, .fa-dryer-alt:before {
  content: "";
}

.fa-house-chimney-user:before {
  content: "";
}

.fa-hand-holding-heart:before {
  content: "";
}

.fa-arrow-up-small-big:before, .fa-sort-size-up-alt:before {
  content: "";
}

.fa-train-track:before {
  content: "";
}

.fa-puzzle-piece:before {
  content: "";
}

.fa-money-check:before {
  content: "";
}

.fa-star-half-stroke:before, .fa-star-half-alt:before {
  content: "";
}

.fa-file-exclamation:before {
  content: "";
}

.fa-code:before {
  content: "";
}

.fa-whiskey-glass:before, .fa-glass-whiskey:before {
  content: "";
}

.fa-moon-stars:before {
  content: "";
}

.fa-building-circle-exclamation:before {
  content: "";
}

.fa-clothes-hanger:before {
  content: "";
}

.fa-mobile-notch:before, .fa-mobile-iphone:before {
  content: "";
}

.fa-magnifying-glass-chart:before {
  content: "";
}

.fa-arrow-up-right-from-square:before, .fa-external-link:before {
  content: "";
}

.fa-cubes-stacked:before {
  content: "";
}

.fa-images-user:before {
  content: "";
}

.fa-won-sign:before, .fa-krw:before, .fa-won:before {
  content: "";
}

.fa-image-polaroid-user:before {
  content: "";
}

.fa-virus-covid:before {
  content: "";
}

.fa-square-ellipsis:before {
  content: "";
}

.fa-pie:before {
  content: "";
}

.fa-chess-knight-piece:before, .fa-chess-knight-alt:before {
  content: "";
}

.fa-austral-sign:before {
  content: "";
}

.fa-cloud-plus:before {
  content: "";
}

.fa-f:before {
  content: "F";
}

.fa-leaf:before {
  content: "";
}

.fa-bed-bunk:before {
  content: "";
}

.fa-road:before {
  content: "";
}

.fa-taxi:before, .fa-cab:before {
  content: "";
}

.fa-person-circle-plus:before {
  content: "";
}

.fa-chart-pie:before, .fa-pie-chart:before {
  content: "";
}

.fa-bolt-lightning:before {
  content: "";
}

.fa-clock-eight:before {
  content: "";
}

.fa-sack-xmark:before {
  content: "";
}

.fa-file-xls:before {
  content: "";
}

.fa-file-excel:before {
  content: "";
}

.fa-file-contract:before {
  content: "";
}

.fa-fish-fins:before {
  content: "";
}

.fa-circle-q:before {
  content: "";
}

.fa-building-flag:before {
  content: "";
}

.fa-face-grin-beam:before, .fa-grin-beam:before {
  content: "";
}

.fa-object-ungroup:before {
  content: "";
}

.fa-face-disguise:before {
  content: "";
}

.fa-circle-arrow-down-right:before {
  content: "";
}

.fa-alien-8bit:before, .fa-alien-monster:before {
  content: "";
}

.fa-hand-point-ribbon:before {
  content: "";
}

.fa-poop:before {
  content: "";
}

.fa-object-exclude:before {
  content: "";
}

.fa-telescope:before {
  content: "";
}

.fa-location-pin:before, .fa-map-marker:before {
  content: "";
}

.fa-square-list:before {
  content: "";
}

.fa-kaaba:before {
  content: "";
}

.fa-toilet-paper:before {
  content: "";
}

.fa-helmet-safety:before, .fa-hard-hat:before, .fa-hat-hard:before {
  content: "";
}

.fa-comment-code:before {
  content: "";
}

.fa-sim-cards:before {
  content: "";
}

.fa-starship:before {
  content: "";
}

.fa-eject:before {
  content: "";
}

.fa-circle-right:before, .fa-arrow-alt-circle-right:before {
  content: "";
}

.fa-plane-circle-check:before {
  content: "";
}

.fa-seal:before {
  content: "";
}

.fa-user-cowboy:before {
  content: "";
}

.fa-hexagon-vertical-nft:before {
  content: "";
}

.fa-face-rolling-eyes:before, .fa-meh-rolling-eyes:before {
  content: "";
}

.fa-bread-loaf:before {
  content: "";
}

.fa-rings-wedding:before {
  content: "";
}

.fa-object-group:before {
  content: "";
}

.fa-french-fries:before {
  content: "";
}

.fa-chart-line:before, .fa-line-chart:before {
  content: "";
}

.fa-calendar-arrow-down:before, .fa-calendar-download:before {
  content: "";
}

.fa-send-back:before {
  content: "";
}

.fa-mask-ventilator:before {
  content: "";
}

.fa-tickets:before {
  content: "";
}

.fa-signature-lock:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-signs-post:before, .fa-map-signs:before {
  content: "";
}

.fa-octagon-plus:before, .fa-plus-octagon:before {
  content: "";
}

.fa-cash-register:before {
  content: "";
}

.fa-person-circle-question:before {
  content: "";
}

.fa-melon-slice:before {
  content: "";
}

.fa-space-station-moon:before {
  content: "";
}

.fa-message-smile:before, .fa-comment-alt-smile:before {
  content: "";
}

.fa-cup-straw:before {
  content: "";
}

.fa-left-from-line:before, .fa-arrow-alt-from-right:before {
  content: "";
}

.fa-h:before {
  content: "H";
}

.fa-basket-shopping-simple:before, .fa-shopping-basket-alt:before {
  content: "";
}

.fa-hands-holding-heart:before, .fa-hands-heart:before {
  content: "";
}

.fa-clock-nine:before {
  content: "";
}

.fa-hammer-brush:before {
  content: "";
}

.fa-tarp:before {
  content: "";
}

.fa-face-sleepy:before {
  content: "";
}

.fa-hand-horns:before {
  content: "";
}

.fa-screwdriver-wrench:before, .fa-tools:before {
  content: "";
}

.fa-arrows-to-eye:before {
  content: "";
}

.fa-circle-three-quarters:before {
  content: "";
}

.fa-trophy-star:before, .fa-trophy-alt:before {
  content: "";
}

.fa-plug-circle-bolt:before {
  content: "";
}

.fa-face-thermometer:before {
  content: "";
}

.fa-grid-round-4:before {
  content: "";
}

.fa-sign-posts-wrench:before {
  content: "";
}

.fa-shirt-running:before {
  content: "";
}

.fa-book-circle-arrow-up:before {
  content: "";
}

.fa-face-nauseated:before {
  content: "";
}

.fa-heart:before {
  content: "";
}

.fa-file-chart-pie:before {
  content: "";
}

.fa-mars-and-venus:before {
  content: "";
}

.fa-house-user:before, .fa-home-user:before {
  content: "";
}

.fa-circle-arrow-down-left:before {
  content: "";
}

.fa-dumpster-fire:before {
  content: "";
}

.fa-hexagon-minus:before, .fa-minus-hexagon:before {
  content: "";
}

.fa-left-to-line:before, .fa-arrow-alt-to-left:before {
  content: "";
}

.fa-house-crack:before {
  content: "";
}

.fa-paw-simple:before, .fa-paw-alt:before {
  content: "";
}

.fa-arrow-left-long-to-line:before {
  content: "";
}

.fa-brackets-round:before, .fa-parentheses:before {
  content: "";
}

.fa-martini-glass-citrus:before, .fa-cocktail:before {
  content: "";
}

.fa-user-shakespeare:before {
  content: "";
}

.fa-arrow-right-to-arc:before {
  content: "";
}

.fa-face-surprise:before, .fa-surprise:before {
  content: "";
}

.fa-bottle-water:before {
  content: "";
}

.fa-circle-pause:before, .fa-pause-circle:before {
  content: "";
}

.fa-gauge-circle-plus:before {
  content: "";
}

.fa-folders:before {
  content: "";
}

.fa-angel:before {
  content: "";
}

.fa-value-absolute:before {
  content: "";
}

.fa-rabbit:before {
  content: "";
}

.fa-toilet-paper-slash:before {
  content: "";
}

.fa-circle-euro:before {
  content: "";
}

.fa-apple-whole:before, .fa-apple-alt:before {
  content: "";
}

.fa-kitchen-set:before {
  content: "";
}

.fa-diamond-half:before {
  content: "";
}

.fa-lock-keyhole:before, .fa-lock-alt:before {
  content: "";
}

.fa-r:before {
  content: "R";
}

.fa-temperature-quarter:before, .fa-temperature-1:before, .fa-thermometer-1:before, .fa-thermometer-quarter:before {
  content: "";
}

.fa-square-info:before, .fa-info-square:before {
  content: "";
}

.fa-wifi-slash:before {
  content: "";
}

.fa-toilet-paper-xmark:before {
  content: "";
}

.fa-hands-holding-dollar:before, .fa-hands-usd:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-arrow-down-triangle-square:before, .fa-sort-shapes-down:before {
  content: "";
}

.fa-bitcoin-sign:before {
  content: "";
}

.fa-shutters:before {
  content: "";
}

.fa-shield-dog:before {
  content: "";
}

.fa-solar-panel:before {
  content: "";
}

.fa-lock-open:before {
  content: "";
}

.fa-table-tree:before {
  content: "";
}

.fa-house-chimney-heart:before {
  content: "";
}

.fa-tally-3:before {
  content: "";
}

.fa-elevator:before {
  content: "";
}

.fa-money-bill-transfer:before {
  content: "";
}

.fa-money-bill-trend-up:before {
  content: "";
}

.fa-house-flood-water-circle-arrow-right:before {
  content: "";
}

.fa-square-poll-horizontal:before, .fa-poll-h:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-left-to-bracket:before {
  content: "";
}

.fa-cart-circle-exclamation:before {
  content: "";
}

.fa-sword:before {
  content: "";
}

.fa-backward-fast:before, .fa-fast-backward:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-user-astronaut:before {
  content: "";
}

.fa-interrobang:before {
  content: "";
}

.fa-plane-slash:before {
  content: "";
}

.fa-circle-dashed:before {
  content: "";
}

.fa-trademark:before {
  content: "";
}

.fa-basketball:before, .fa-basketball-ball:before {
  content: "";
}

.fa-fork-knife:before, .fa-utensils-alt:before {
  content: "";
}

.fa-satellite-dish:before {
  content: "";
}

.fa-badge-check:before {
  content: "";
}

.fa-circle-up:before, .fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-slider:before {
  content: "";
}

.fa-mobile-screen-button:before, .fa-mobile-alt:before {
  content: "";
}

.fa-clock-one-thirty:before {
  content: "";
}

.fa-inbox-out:before, .fa-inbox-arrow-up:before {
  content: "";
}

.fa-cloud-slash:before {
  content: "";
}

.fa-volume-high:before, .fa-volume-up:before {
  content: "";
}

.fa-users-rays:before {
  content: "";
}

.fa-wallet:before {
  content: "";
}

.fa-octagon-check:before {
  content: "";
}

.fa-flatbread-stuffed:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-cart-circle-plus:before {
  content: "";
}

.fa-truck-clock:before, .fa-shipping-timed:before {
  content: "";
}

.fa-pool-8-ball:before {
  content: "";
}

.fa-file-audio:before {
  content: "";
}

.fa-turn-down-left:before {
  content: "";
}

.fa-lock-hashtag:before {
  content: "";
}

.fa-chart-radar:before {
  content: "";
}

.fa-staff:before {
  content: "";
}

.fa-burger:before, .fa-hamburger:before {
  content: "";
}

.fa-utility-pole:before {
  content: "";
}

.fa-transporter-6:before {
  content: "";
}

.fa-arrow-turn-left:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.fa-bugs:before {
  content: "";
}

.fa-vector-polygon:before {
  content: "";
}

.fa-diagram-nested:before {
  content: "";
}

.fa-rupee-sign:before, .fa-rupee:before {
  content: "";
}

.fa-file-image:before {
  content: "";
}

.fa-circle-question:before, .fa-question-circle:before {
  content: "";
}

.fa-tickets-perforated:before {
  content: "";
}

.fa-image-user:before {
  content: "";
}

.fa-buoy:before {
  content: "";
}

.fa-plane-departure:before {
  content: "";
}

.fa-handshake-slash:before {
  content: "";
}

.fa-book-bookmark:before {
  content: "";
}

.fa-border-center-h:before {
  content: "";
}

.fa-can-food:before {
  content: "";
}

.fa-typewriter:before {
  content: "";
}

.fa-arrow-right-from-arc:before {
  content: "";
}

.fa-circle-k:before {
  content: "";
}

.fa-face-hand-over-mouth:before {
  content: "";
}

.fa-popcorn:before {
  content: "";
}

.fa-house-water:before, .fa-house-flood:before {
  content: "";
}

.fa-object-subtract:before {
  content: "";
}

.fa-code-branch:before {
  content: "";
}

.fa-warehouse-full:before, .fa-warehouse-alt:before {
  content: "";
}

.fa-hat-cowboy:before {
  content: "";
}

.fa-bridge:before {
  content: "";
}

.fa-phone-flip:before, .fa-phone-alt:before {
  content: "";
}

.fa-arrow-down-from-dotted-line:before {
  content: "";
}

.fa-file-doc:before {
  content: "";
}

.fa-square-quarters:before {
  content: "";
}

.fa-truck-front:before {
  content: "";
}

.fa-cat:before {
  content: "";
}

.fa-trash-xmark:before {
  content: "";
}

.fa-circle-caret-left:before, .fa-caret-circle-left:before {
  content: "";
}

.fa-files:before {
  content: "";
}

.fa-anchor-circle-exclamation:before {
  content: "";
}

.fa-face-clouds:before {
  content: "";
}

.fa-user-crown:before {
  content: "";
}

.fa-basket-shopping-plus:before {
  content: "";
}

.fa-truck-field:before {
  content: "";
}

.fa-route:before {
  content: "";
}

.fa-cart-circle-check:before {
  content: "";
}

.fa-clipboard-question:before {
  content: "";
}

.fa-panorama:before {
  content: "";
}

.fa-comment-medical:before {
  content: "";
}

.fa-teeth-open:before {
  content: "";
}

.fa-user-tie-hair-long:before {
  content: "";
}

.fa-file-circle-minus:before {
  content: "";
}

.fa-head-side-medical:before {
  content: "";
}

.fa-arrow-turn-right:before {
  content: "";
}

.fa-tags:before {
  content: "";
}

.fa-wine-glass:before {
  content: "";
}

.fa-forward-fast:before, .fa-fast-forward:before {
  content: "";
}

.fa-face-meh-blank:before, .fa-meh-blank:before {
  content: "";
}

.fa-user-robot:before {
  content: "";
}

.fa-square-parking:before, .fa-parking:before {
  content: "";
}

.fa-card-diamond:before {
  content: "";
}

.fa-face-zipper:before {
  content: "";
}

.fa-face-raised-eyebrow:before {
  content: "";
}

.fa-house-signal:before {
  content: "";
}

.fa-square-chevron-up:before, .fa-chevron-square-up:before {
  content: "";
}

.fa-bars-progress:before, .fa-tasks-alt:before {
  content: "";
}

.fa-faucet-drip:before {
  content: "";
}

.fa-arrows-to-line:before {
  content: "";
}

.fa-dolphin:before {
  content: "";
}

.fa-arrow-up-right:before {
  content: "";
}

.fa-circle-r:before {
  content: "";
}

.fa-cart-flatbed:before, .fa-dolly-flatbed:before {
  content: "";
}

.fa-ban-smoking:before, .fa-smoking-ban:before {
  content: "";
}

.fa-circle-sort-up:before, .fa-sort-circle-up:before {
  content: "";
}

.fa-terminal:before {
  content: "";
}

.fa-mobile-button:before {
  content: "";
}

.fa-house-medical-flag:before {
  content: "";
}

.fa-basket-shopping:before, .fa-shopping-basket:before {
  content: "";
}

.fa-tape:before {
  content: "";
}

.fa-chestnut:before {
  content: "";
}

.fa-bus-simple:before, .fa-bus-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-face-sad-cry:before, .fa-sad-cry:before {
  content: "";
}

.fa-heat:before {
  content: "";
}

.fa-ticket-airline:before, .fa-ticket-perforated-plane:before, .fa-ticket-plane:before {
  content: "";
}

.fa-boot-heeled:before {
  content: "";
}

.fa-arrows-minimize:before, .fa-compress-arrows:before {
  content: "";
}

.fa-audio-description:before {
  content: "";
}

.fa-person-military-to-person:before {
  content: "";
}

.fa-file-shield:before {
  content: "";
}

.fa-hexagon:before {
  content: "";
}

.fa-manhole:before {
  content: "";
}

.fa-user-slash:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-tower-observation:before {
  content: "";
}

.fa-floppy-disks:before {
  content: "";
}

.fa-toilet-paper-blank-under:before, .fa-toilet-paper-reverse-alt:before {
  content: "";
}

.fa-file-code:before {
  content: "";
}

.fa-signal:before, .fa-signal-5:before, .fa-signal-perfect:before {
  content: "";
}

.fa-pump:before {
  content: "";
}

.fa-bus:before {
  content: "";
}

.fa-heart-circle-xmark:before {
  content: "";
}

.fa-arrow-up-left-from-circle:before {
  content: "";
}

.fa-house-chimney:before, .fa-home-lg:before {
  content: "";
}

.fa-window-maximize:before {
  content: "";
}

.fa-dryer:before {
  content: "";
}

.fa-face-frown:before, .fa-frown:before {
  content: "";
}

.fa-chess-bishop-piece:before, .fa-chess-bishop-alt:before {
  content: "";
}

.fa-shirt-tank-top:before {
  content: "";
}

.fa-diploma:before, .fa-scroll-ribbon:before {
  content: "";
}

.fa-screencast:before {
  content: "";
}

.fa-walker:before {
  content: "";
}

.fa-prescription:before {
  content: "";
}

.fa-shop:before, .fa-store-alt:before {
  content: "";
}

.fa-floppy-disk:before, .fa-save:before {
  content: "";
}

.fa-vihara:before {
  content: "";
}

.fa-face-kiss-closed-eyes:before {
  content: "";
}

.fa-scale-unbalanced:before, .fa-balance-scale-left:before {
  content: "";
}

.fa-file-user:before {
  content: "";
}

.fa-user-police-tie:before {
  content: "";
}

.fa-face-tongue-money:before {
  content: "";
}

.fa-tennis-ball:before {
  content: "";
}

.fa-square-l:before {
  content: "";
}

.fa-sort-up:before, .fa-sort-asc:before {
  content: "";
}

.fa-calendar-arrow-up:before, .fa-calendar-upload:before {
  content: "";
}

.fa-comment-dots:before, .fa-commenting:before {
  content: "";
}

.fa-plant-wilt:before {
  content: "";
}

.fa-scarf:before {
  content: "";
}

.fa-album-circle-plus:before {
  content: "";
}

.fa-user-nurse-hair-long:before {
  content: "";
}

.fa-diamond:before {
  content: "";
}

.fa-square-left:before, .fa-arrow-alt-square-left:before {
  content: "";
}

.fa-face-grin-squint:before, .fa-grin-squint:before {
  content: "";
}

.fa-circle-ellipsis-vertical:before {
  content: "";
}

.fa-hand-holding-dollar:before, .fa-hand-holding-usd:before {
  content: "";
}

.fa-grid-dividers:before {
  content: "";
}

.fa-bacterium:before {
  content: "";
}

.fa-hand-pointer:before {
  content: "";
}

.fa-drum-steelpan:before {
  content: "";
}

.fa-hand-scissors:before {
  content: "";
}

.fa-hands-praying:before, .fa-praying-hands:before {
  content: "";
}

.fa-face-pensive:before {
  content: "";
}

.fa-user-music:before {
  content: "";
}

.fa-arrow-rotate-right:before, .fa-arrow-right-rotate:before, .fa-arrow-rotate-forward:before, .fa-redo:before {
  content: "";
}

.fa-messages-dollar:before, .fa-comments-alt-dollar:before {
  content: "";
}

.fa-sensor-on:before {
  content: "";
}

.fa-balloon:before {
  content: "";
}

.fa-biohazard:before {
  content: "";
}

.fa-chess-queen-piece:before, .fa-chess-queen-alt:before {
  content: "";
}

.fa-location-crosshairs:before, .fa-location:before {
  content: "";
}

.fa-mars-double:before {
  content: "";
}

.fa-left-from-bracket:before {
  content: "";
}

.fa-house-person-leave:before, .fa-house-leave:before, .fa-house-person-depart:before {
  content: "";
}

.fa-ruler-triangle:before {
  content: "";
}

.fa-card-club:before {
  content: "";
}

.fa-child-dress:before {
  content: "";
}

.fa-users-between-lines:before {
  content: "";
}

.fa-lungs-virus:before {
  content: "";
}

.fa-spinner-third:before {
  content: "";
}

.fa-face-grin-tears:before, .fa-grin-tears:before {
  content: "";
}

.fa-phone:before {
  content: "";
}

.fa-computer-mouse-scrollwheel:before, .fa-mouse-alt:before {
  content: "";
}

.fa-calendar-xmark:before, .fa-calendar-times:before {
  content: "";
}

.fa-child-reaching:before {
  content: "";
}

.fa-table-layout:before {
  content: "";
}

.fa-narwhal:before {
  content: "";
}

.fa-ramp-loading:before {
  content: "";
}

.fa-calendar-circle-plus:before {
  content: "";
}

.fa-toothbrush:before {
  content: "";
}

.fa-border-inner:before {
  content: "";
}

.fa-paw-claws:before {
  content: "";
}

.fa-kiwi-fruit:before {
  content: "";
}

.fa-traffic-light-slow:before {
  content: "";
}

.fa-rectangle-code:before {
  content: "";
}

.fa-head-side-virus:before {
  content: "";
}

.fa-keyboard-brightness:before {
  content: "";
}

.fa-books-medical:before {
  content: "";
}

.fa-lightbulb-slash:before {
  content: "";
}

.fa-house-blank:before, .fa-home-blank:before {
  content: "";
}

.fa-square-5:before {
  content: "";
}

.fa-square-heart:before, .fa-heart-square:before {
  content: "";
}

.fa-puzzle:before {
  content: "";
}

.fa-user-gear:before, .fa-user-cog:before {
  content: "";
}

.fa-pipe-circle-check:before {
  content: "";
}

.fa-arrow-up-1-9:before, .fa-sort-numeric-up:before {
  content: "";
}

.fa-octagon-exclamation:before {
  content: "";
}

.fa-dial-low:before {
  content: "";
}

.fa-door-closed:before {
  content: "";
}

.fa-laptop-mobile:before, .fa-phone-laptop:before {
  content: "";
}

.fa-conveyor-belt-boxes:before, .fa-conveyor-belt-alt:before {
  content: "";
}

.fa-shield-virus:before {
  content: "";
}

.fa-starfighter-twin-ion-engine-advanced:before, .fa-starfighter-alt-advanced:before {
  content: "";
}

.fa-dice-six:before {
  content: "";
}

.fa-starfighter-twin-ion-engine:before, .fa-starfighter-alt:before {
  content: "";
}

.fa-rocket-launch:before {
  content: "";
}

.fa-mosquito-net:before {
  content: "";
}

.fa-vent-damper:before {
  content: "";
}

.fa-bridge-water:before {
  content: "";
}

.fa-ban-bug:before, .fa-debug:before {
  content: "";
}

.fa-person-booth:before {
  content: "";
}

.fa-text-width:before {
  content: "";
}

.fa-garage-car:before {
  content: "";
}

.fa-square-kanban:before {
  content: "";
}

.fa-hat-wizard:before {
  content: "";
}

.fa-chart-kanban:before {
  content: "";
}

.fa-pen-fancy:before {
  content: "";
}

.fa-coffee-pot:before {
  content: "";
}

.fa-mouse-field:before {
  content: "";
}

.fa-person-digging:before, .fa-digging:before {
  content: "";
}

.fa-shower-down:before, .fa-shower-alt:before {
  content: "";
}

.fa-box-circle-check:before {
  content: "";
}

.fa-brightness:before {
  content: "";
}

.fa-car-side-bolt:before {
  content: "";
}

.fa-file-xml:before {
  content: "";
}

.fa-ornament:before {
  content: "";
}

.fa-phone-arrow-down-left:before, .fa-phone-arrow-down:before, .fa-phone-incoming:before {
  content: "";
}

.fa-cloud-word:before {
  content: "";
}

.fa-hand-fingers-crossed:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-gauge-simple:before, .fa-gauge-simple-med:before, .fa-tachometer-average:before {
  content: "";
}

.fa-arrow-down-small-big:before, .fa-sort-size-down-alt:before {
  content: "";
}

.fa-book-medical:before {
  content: "";
}

.fa-face-melting:before {
  content: "";
}

.fa-poo:before {
  content: "";
}

.fa-pen-clip-slash:before, .fa-pen-alt-slash:before {
  content: "";
}

.fa-quote-right:before, .fa-quote-right-alt:before {
  content: "";
}

.fa-scroll-old:before {
  content: "";
}

.fa-guitars:before {
  content: "";
}

.fa-phone-xmark:before {
  content: "";
}

.fa-hose:before {
  content: "";
}

.fa-clock-six:before {
  content: "";
}

.fa-shirt:before, .fa-t-shirt:before, .fa-tshirt:before {
  content: "";
}

.fa-billboard:before {
  content: "";
}

.fa-square-r:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-envelope-open-dollar:before {
  content: "";
}

.fa-divide:before {
  content: "";
}

.fa-sun-cloud:before {
  content: "";
}

.fa-lamp-floor:before {
  content: "";
}

.fa-square-7:before {
  content: "";
}

.fa-tenge-sign:before, .fa-tenge:before {
  content: "";
}

.fa-headphones:before {
  content: "";
}

.fa-hands-holding:before {
  content: "";
}

.fa-campfire:before {
  content: "";
}

.fa-circle-ampersand:before {
  content: "";
}

.fa-snowflakes:before {
  content: "";
}

.fa-hands-clapping:before {
  content: "";
}

.fa-republican:before {
  content: "";
}

.fa-leaf-maple:before {
  content: "";
}

.fa-arrow-left:before {
  content: "";
}

.fa-person-circle-xmark:before {
  content: "";
}

.fa-ruler:before {
  content: "";
}

.fa-arrow-left-from-bracket:before {
  content: "";
}

.fa-cup-straw-swoosh:before {
  content: "";
}

.fa-temperature-sun:before, .fa-temperature-hot:before {
  content: "";
}

.fa-align-left:before {
  content: "";
}

.fa-dice-d6:before {
  content: "";
}

.fa-restroom:before {
  content: "";
}

.fa-high-definition:before, .fa-rectangle-hd:before {
  content: "";
}

.fa-j:before {
  content: "J";
}

.fa-galaxy:before {
  content: "";
}

.fa-users-viewfinder:before {
  content: "";
}

.fa-file-video:before {
  content: "";
}

.fa-cherries:before {
  content: "";
}

.fa-up-right-from-square:before, .fa-external-link-alt:before {
  content: "";
}

.fa-circle-sort:before, .fa-sort-circle:before {
  content: "";
}

.fa-table-cells:before, .fa-th:before {
  content: "";
}

.fa-bag-shopping-minus:before {
  content: "";
}

.fa-file-pdf:before {
  content: "";
}

.fa-siren:before {
  content: "";
}

.fa-arrow-up-to-dotted-line:before {
  content: "";
}

.fa-image-landscape:before, .fa-landscape:before {
  content: "";
}

.fa-tank-water:before {
  content: "";
}

.fa-curling-stone:before, .fa-curling:before {
  content: "";
}

.fa-gamepad-modern:before, .fa-gamepad-alt:before {
  content: "";
}

.fa-messages-question:before {
  content: "";
}

.fa-book-bible:before, .fa-bible:before {
  content: "";
}

.fa-o:before {
  content: "O";
}

.fa-suitcase-medical:before, .fa-medkit:before {
  content: "";
}

.fa-briefcase-arrow-right:before {
  content: "";
}

.fa-expand-wide:before {
  content: "";
}

.fa-clock-eleven-thirty:before {
  content: "";
}

.fa-rv:before {
  content: "";
}

.fa-user-secret:before {
  content: "";
}

.fa-otter:before {
  content: "";
}

.fa-dreidel:before {
  content: "";
}

.fa-person-dress:before, .fa-female:before {
  content: "";
}

.fa-comment-dollar:before {
  content: "";
}

.fa-business-time:before, .fa-briefcase-clock:before {
  content: "";
}

.fa-flower-tulip:before {
  content: "";
}

.fa-people-pants-simple:before {
  content: "";
}

.fa-cloud-drizzle:before {
  content: "";
}

.fa-table-cells-large:before, .fa-th-large:before {
  content: "";
}

.fa-book-tanakh:before, .fa-tanakh:before {
  content: "";
}

.fa-solar-system:before {
  content: "";
}

.fa-seal-question:before {
  content: "";
}

.fa-phone-volume:before, .fa-volume-control-phone:before {
  content: "";
}

.fa-disc-drive:before {
  content: "";
}

.fa-hat-cowboy-side:before {
  content: "";
}

.fa-table-rows:before, .fa-rows:before {
  content: "";
}

.fa-location-exclamation:before, .fa-map-marker-exclamation:before {
  content: "";
}

.fa-face-fearful:before {
  content: "";
}

.fa-clipboard-user:before {
  content: "";
}

.fa-bus-school:before {
  content: "";
}

.fa-film-slash:before {
  content: "";
}

.fa-square-arrow-down-right:before {
  content: "";
}

.fa-book-sparkles:before, .fa-book-spells:before {
  content: "";
}

.fa-washing-machine:before, .fa-washer:before {
  content: "";
}

.fa-child:before {
  content: "";
}

.fa-lira-sign:before {
  content: "";
}

.fa-user-visor:before {
  content: "";
}

.fa-file-plus-minus:before {
  content: "";
}

.fa-chess-clock-flip:before, .fa-chess-clock-alt:before {
  content: "";
}

.fa-satellite:before {
  content: "";
}

.fa-truck-fire:before {
  content: "";
}

.fa-plane-lock:before {
  content: "";
}

.fa-steering-wheel:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-stretcher:before {
  content: "";
}

.fa-book-section:before, .fa-book-law:before {
  content: "";
}

.fa-inboxes:before {
  content: "";
}

.fa-coffee-bean:before {
  content: "";
}

.fa-circle-yen:before {
  content: "";
}

.fa-brackets-curly:before {
  content: "";
}

.fa-ellipsis-stroke-vertical:before, .fa-ellipsis-v-alt:before {
  content: "";
}

.fa-comment:before {
  content: "";
}

.fa-square-1:before {
  content: "";
}

.fa-cake-candles:before, .fa-birthday-cake:before, .fa-cake:before {
  content: "";
}

.fa-head-side:before {
  content: "";
}

.fa-truck-ladder:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-dolly-empty:before {
  content: "";
}

.fa-face-tissue:before {
  content: "";
}

.fa-angles-up:before, .fa-angle-double-up:before {
  content: "";
}

.fa-bin-recycle:before {
  content: "";
}

.fa-paperclip:before {
  content: "";
}

.fa-chart-line-down:before {
  content: "";
}

.fa-arrow-right-to-city:before {
  content: "";
}

.fa-lock-a:before {
  content: "";
}

.fa-ribbon:before {
  content: "";
}

.fa-lungs:before {
  content: "";
}

.fa-person-pinball:before {
  content: "";
}

.fa-arrow-up-9-1:before, .fa-sort-numeric-up-alt:before {
  content: "";
}

.fa-apple-core:before {
  content: "";
}

.fa-circle-y:before {
  content: "";
}

.fa-h6:before {
  content: "";
}

.fa-litecoin-sign:before {
  content: "";
}

.fa-bottle-baby:before {
  content: "";
}

.fa-circle-small:before {
  content: "";
}

.fa-border-none:before {
  content: "";
}

.fa-arrow-turn-down-left:before {
  content: "";
}

.fa-circle-wifi-circle-wifi:before, .fa-circle-wifi-group:before {
  content: "";
}

.fa-circle-nodes:before {
  content: "";
}

.fa-parachute-box:before {
  content: "";
}

.fa-reflect-horizontal:before {
  content: "";
}

.fa-message-medical:before, .fa-comment-alt-medical:before {
  content: "";
}

.fa-rugby-ball:before {
  content: "";
}

.fa-comment-music:before {
  content: "";
}

.fa-indent:before {
  content: "";
}

.fa-tree-deciduous:before, .fa-tree-alt:before {
  content: "";
}

.fa-puzzle-piece-simple:before, .fa-puzzle-piece-alt:before {
  content: "";
}

.fa-truck-field-un:before {
  content: "";
}

.fa-nfc-trash:before {
  content: "";
}

.fa-hourglass:before, .fa-hourglass-empty:before {
  content: "";
}

.fa-mountain:before {
  content: "";
}

.fa-file-xmark:before, .fa-file-times:before {
  content: "";
}

.fa-house-heart:before, .fa-home-heart:before {
  content: "";
}

.fa-house-chimney-blank:before {
  content: "";
}

.fa-meter-bolt:before {
  content: "";
}

.fa-user-doctor:before, .fa-user-md:before {
  content: "";
}

.fa-slash-back:before {
  content: "\\";
}

.fa-circle-info:before, .fa-info-circle:before {
  content: "";
}

.fa-fishing-rod:before {
  content: "";
}

.fa-hammer-crash:before {
  content: "";
}

.fa-message-heart:before {
  content: "";
}

.fa-cloud-meatball:before {
  content: "";
}

.fa-camera-polaroid:before {
  content: "";
}

.fa-camera:before, .fa-camera-alt:before {
  content: "";
}

.fa-square-virus:before {
  content: "";
}

.fa-cart-arrow-up:before {
  content: "";
}

.fa-meteor:before {
  content: "";
}

.fa-car-on:before {
  content: "";
}

.fa-sleigh:before {
  content: "";
}

.fa-arrow-down-1-9:before, .fa-sort-numeric-asc:before, .fa-sort-numeric-down:before {
  content: "";
}

.fa-buoy-mooring:before {
  content: "";
}

.fa-square-4:before {
  content: "";
}

.fa-hand-holding-droplet:before, .fa-hand-holding-water:before {
  content: "";
}

.fa-file-eps:before {
  content: "";
}

.fa-tricycle-adult:before {
  content: "";
}

.fa-waveform:before {
  content: "";
}

.fa-water:before {
  content: "";
}

.fa-star-sharp-half-stroke:before, .fa-star-sharp-half-alt:before {
  content: "";
}

.fa-nfc-signal:before {
  content: "";
}

.fa-plane-prop:before {
  content: "";
}

.fa-calendar-check:before {
  content: "";
}

.fa-clock-desk:before {
  content: "";
}

.fa-calendar-clock:before, .fa-calendar-time:before {
  content: "";
}

.fa-braille:before {
  content: "";
}

.fa-prescription-bottle-medical:before, .fa-prescription-bottle-alt:before {
  content: "";
}

.fa-plate-utensils:before {
  content: "";
}

.fa-family-pants:before {
  content: "";
}

.fa-hose-reel:before {
  content: "";
}

.fa-house-window:before {
  content: "";
}

.fa-landmark:before {
  content: "";
}

.fa-truck:before {
  content: "";
}

.fa-music-magnifying-glass:before {
  content: "";
}

.fa-crosshairs:before {
  content: "";
}

.fa-cloud-rainbow:before {
  content: "";
}

.fa-person-cane:before {
  content: "";
}

.fa-alien:before {
  content: "";
}

.fa-tent:before {
  content: "";
}

.fa-laptop-binary:before {
  content: "";
}

.fa-vest-patches:before {
  content: "";
}

.fa-people-dress-simple:before {
  content: "";
}

.fa-check-double:before {
  content: "";
}

.fa-arrow-down-a-z:before, .fa-sort-alpha-asc:before, .fa-sort-alpha-down:before {
  content: "";
}

.fa-bowling-ball-pin:before {
  content: "";
}

.fa-bell-school-slash:before {
  content: "";
}

.fa-plus-large:before {
  content: "";
}

.fa-money-bill-wheat:before {
  content: "";
}

.fa-camera-viewfinder:before, .fa-screenshot:before {
  content: "";
}

.fa-message-music:before, .fa-comment-alt-music:before {
  content: "";
}

.fa-car-building:before {
  content: "";
}

.fa-border-bottom-right:before, .fa-border-style-alt:before {
  content: "";
}

.fa-octagon:before {
  content: "";
}

.fa-comment-arrow-up-right:before {
  content: "";
}

.fa-octagon-divide:before {
  content: "";
}

.fa-cookie:before {
  content: "";
}

.fa-arrow-rotate-left:before, .fa-arrow-left-rotate:before, .fa-arrow-rotate-back:before, .fa-arrow-rotate-backward:before, .fa-undo:before {
  content: "";
}

.fa-tv-music:before {
  content: "";
}

.fa-hard-drive:before, .fa-hdd:before {
  content: "";
}

.fa-reel:before {
  content: "";
}

.fa-face-grin-squint-tears:before, .fa-grin-squint-tears:before {
  content: "";
}

.fa-dumbbell:before {
  content: "";
}

.fa-rectangle-list:before, .fa-list-alt:before {
  content: "";
}

.fa-tarp-droplet:before {
  content: "";
}

.fa-alarm-exclamation:before {
  content: "";
}

.fa-house-medical-circle-check:before {
  content: "";
}

.fa-traffic-cone:before {
  content: "";
}

.fa-grate:before {
  content: "";
}

.fa-arrow-down-right:before {
  content: "";
}

.fa-person-skiing-nordic:before, .fa-skiing-nordic:before {
  content: "";
}

.fa-calendar-plus:before {
  content: "";
}

.fa-person-from-portal:before, .fa-portal-exit:before {
  content: "";
}

.fa-plane-arrival:before {
  content: "";
}

.fa-cowbell-circle-plus:before, .fa-cowbell-more:before {
  content: "";
}

.fa-circle-left:before, .fa-arrow-alt-circle-left:before {
  content: "";
}

.fa-distribute-spacing-vertical:before {
  content: "";
}

.fa-signal-bars-fair:before, .fa-signal-alt-2:before {
  content: "";
}

.fa-sportsball:before {
  content: "";
}

.fa-game-console-handheld-crank:before {
  content: "";
}

.fa-train-subway:before, .fa-subway:before {
  content: "";
}

.fa-chart-gantt:before {
  content: "";
}

.fa-face-smile-upside-down:before {
  content: "";
}

.fa-ball-pile:before {
  content: "";
}

.fa-badge-dollar:before {
  content: "";
}

.fa-money-bills-simple:before, .fa-money-bills-alt:before {
  content: "";
}

.fa-list-timeline:before {
  content: "";
}

.fa-indian-rupee-sign:before, .fa-indian-rupee:before, .fa-inr:before {
  content: "";
}

.fa-crop-simple:before, .fa-crop-alt:before {
  content: "";
}

.fa-money-bill-1:before, .fa-money-bill-alt:before {
  content: "";
}

.fa-left-long:before, .fa-long-arrow-alt-left:before {
  content: "";
}

.fa-keyboard-down:before {
  content: "";
}

.fa-circle-up-right:before {
  content: "";
}

.fa-cloud-bolt-moon:before, .fa-thunderstorm-moon:before {
  content: "";
}

.fa-turn-left-up:before {
  content: "";
}

.fa-dna:before {
  content: "";
}

.fa-virus-slash:before {
  content: "";
}

.fa-bracket-round-right:before {
  content: ")";
}

.fa-circle-sterling:before {
  content: "";
}

.fa-circle-5:before {
  content: "";
}

.fa-minus:before, .fa-subtract:before {
  content: "";
}

.fa-fire-flame:before, .fa-flame:before {
  content: "";
}

.fa-right-to-line:before, .fa-arrow-alt-to-right:before {
  content: "";
}

.fa-gif:before {
  content: "";
}

.fa-chess:before {
  content: "";
}

.fa-trash-slash:before {
  content: "";
}

.fa-arrow-left-long:before, .fa-long-arrow-left:before {
  content: "";
}

.fa-plug-circle-check:before {
  content: "";
}

.fa-font-case:before {
  content: "";
}

.fa-street-view:before {
  content: "";
}

.fa-arrow-down-left:before {
  content: "";
}

.fa-franc-sign:before {
  content: "";
}

.fa-flask-round-poison:before, .fa-flask-poison:before {
  content: "";
}

.fa-volume-off:before {
  content: "";
}

.fa-book-circle-arrow-right:before {
  content: "";
}

.fa-chart-user:before, .fa-user-chart:before {
  content: "";
}

.fa-hands-asl-interpreting:before, .fa-american-sign-language-interpreting:before, .fa-asl-interpreting:before, .fa-hands-american-sign-language-interpreting:before {
  content: "";
}

.fa-presentation-screen:before, .fa-presentation:before {
  content: "";
}

.fa-circle-bolt:before {
  content: "";
}

.fa-face-smile-halo:before {
  content: "";
}

.fa-cart-circle-arrow-down:before {
  content: "";
}

.fa-house-person-return:before, .fa-house-person-arrive:before, .fa-house-return:before {
  content: "";
}

.fa-message-xmark:before, .fa-comment-alt-times:before, .fa-message-times:before {
  content: "";
}

.fa-file-certificate:before, .fa-file-award:before {
  content: "";
}

.fa-user-doctor-hair-long:before {
  content: "";
}

.fa-camera-security:before, .fa-camera-home:before {
  content: "";
}

.fa-gear:before, .fa-cog:before {
  content: "";
}

.fa-droplet-slash:before, .fa-tint-slash:before {
  content: "";
}

.fa-book-heart:before {
  content: "";
}

.fa-mosque:before {
  content: "";
}

.fa-duck:before {
  content: "";
}

.fa-mosquito:before {
  content: "";
}

.fa-star-of-david:before {
  content: "";
}

.fa-flag-swallowtail:before, .fa-flag-alt:before {
  content: "";
}

.fa-person-military-rifle:before {
  content: "";
}

.fa-car-garage:before {
  content: "";
}

.fa-cart-shopping:before, .fa-shopping-cart:before {
  content: "";
}

.fa-book-font:before {
  content: "";
}

.fa-shield-plus:before {
  content: "";
}

.fa-vials:before {
  content: "";
}

.fa-eye-dropper-full:before {
  content: "";
}

.fa-distribute-spacing-horizontal:before {
  content: "";
}

.fa-tablet-rugged:before {
  content: "";
}

.fa-temperature-snow:before, .fa-temperature-frigid:before {
  content: "";
}

.fa-moped:before {
  content: "";
}

.fa-face-smile-plus:before, .fa-smile-plus:before {
  content: "";
}

.fa-radio-tuner:before, .fa-radio-alt:before {
  content: "";
}

.fa-face-swear:before {
  content: "";
}

.fa-water-arrow-down:before, .fa-water-lower:before {
  content: "";
}

.fa-scanner-touchscreen:before {
  content: "";
}

.fa-circle-7:before {
  content: "";
}

.fa-plug-circle-plus:before {
  content: "";
}

.fa-person-ski-jumping:before, .fa-ski-jump:before {
  content: "";
}

.fa-place-of-worship:before {
  content: "";
}

.fa-water-arrow-up:before, .fa-water-rise:before {
  content: "";
}

.fa-waveform-lines:before, .fa-waveform-path:before {
  content: "";
}

.fa-split:before {
  content: "";
}

.fa-film-canister:before, .fa-film-cannister:before {
  content: "";
}

.fa-folder-xmark:before, .fa-folder-times:before {
  content: "";
}

.fa-toilet-paper-blank:before, .fa-toilet-paper-alt:before {
  content: "";
}

.fa-tablet-screen:before, .fa-tablet-android-alt:before {
  content: "";
}

.fa-hexagon-vertical-nft-slanted:before {
  content: "";
}

.fa-folder-music:before {
  content: "";
}

.fa-display-medical:before, .fa-desktop-medical:before {
  content: "";
}

.fa-share-all:before {
  content: "";
}

.fa-peapod:before {
  content: "";
}

.fa-chess-clock:before {
  content: "";
}

.fa-axe:before {
  content: "";
}

.fa-square-d:before {
  content: "";
}

.fa-grip-vertical:before {
  content: "";
}

.fa-mobile-signal-out:before {
  content: "";
}

.fa-arrow-turn-up:before, .fa-level-up:before {
  content: "";
}

.fa-u:before {
  content: "U";
}

.fa-arrow-up-from-dotted-line:before {
  content: "";
}

.fa-square-root-variable:before, .fa-square-root-alt:before {
  content: "";
}

.fa-light-switch-on:before {
  content: "";
}

.fa-arrow-down-arrow-up:before, .fa-sort-alt:before {
  content: "";
}

.fa-raindrops:before {
  content: "";
}

.fa-dash:before, .fa-minus-large:before {
  content: "";
}

.fa-clock:before, .fa-clock-four:before {
  content: "";
}

.fa-input-numeric:before {
  content: "";
}

.fa-truck-tow:before {
  content: "";
}

.fa-backward-step:before, .fa-step-backward:before {
  content: "";
}

.fa-pallet:before {
  content: "";
}

.fa-car-bolt:before {
  content: "";
}

.fa-arrows-maximize:before, .fa-expand-arrows:before {
  content: "";
}

.fa-faucet:before {
  content: "";
}

.fa-cloud-sleet:before {
  content: "";
}

.fa-lamp-street:before {
  content: "";
}

.fa-list-radio:before {
  content: "";
}

.fa-pen-nib-slash:before {
  content: "";
}

.fa-baseball-bat-ball:before {
  content: "";
}

.fa-square-up-left:before {
  content: "";
}

.fa-overline:before {
  content: "";
}

.fa-s:before {
  content: "S";
}

.fa-timeline:before {
  content: "";
}

.fa-keyboard:before {
  content: "";
}

.fa-arrows-from-dotted-line:before {
  content: "";
}

.fa-usb-drive:before {
  content: "";
}

.fa-ballot:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-location-dot-slash:before, .fa-map-marker-alt-slash:before {
  content: "";
}

.fa-cards:before {
  content: "";
}

.fa-house-chimney-medical:before, .fa-clinic-medical:before {
  content: "";
}

.fa-boxing-glove:before, .fa-glove-boxing:before {
  content: "";
}

.fa-temperature-three-quarters:before, .fa-temperature-3:before, .fa-thermometer-3:before, .fa-thermometer-three-quarters:before {
  content: "";
}

.fa-bell-school:before {
  content: "";
}

.fa-mobile-screen:before, .fa-mobile-android-alt:before {
  content: "";
}

.fa-plane-up:before {
  content: "";
}

.fa-folder-heart:before {
  content: "";
}

.fa-circle-location-arrow:before, .fa-location-circle:before {
  content: "";
}

.fa-face-head-bandage:before {
  content: "";
}

.fa-sushi-roll:before, .fa-maki-roll:before, .fa-makizushi:before {
  content: "";
}

.fa-car-bump:before {
  content: "";
}

.fa-piggy-bank:before {
  content: "";
}

.fa-racquet:before {
  content: "";
}

.fa-car-mirrors:before {
  content: "";
}

.fa-industry-windows:before, .fa-industry-alt:before {
  content: "";
}

.fa-bolt-auto:before {
  content: "";
}

.fa-battery-half:before, .fa-battery-3:before {
  content: "";
}

.fa-flux-capacitor:before {
  content: "";
}

.fa-mountain-city:before {
  content: "";
}

.fa-coins:before {
  content: "";
}

.fa-honey-pot:before {
  content: "";
}

.fa-olive:before {
  content: "";
}

.fa-khanda:before {
  content: "";
}

.fa-filter-list:before {
  content: "";
}

.fa-outlet:before {
  content: "";
}

.fa-sliders:before, .fa-sliders-h:before {
  content: "";
}

.fa-cauldron:before {
  content: "";
}

.fa-people:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-network-wired:before {
  content: "";
}

.fa-croissant:before {
  content: "";
}

.fa-map-pin:before {
  content: "";
}

.fa-hamsa:before {
  content: "";
}

.fa-cent-sign:before {
  content: "";
}

.fa-swords-laser:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-person-pregnant:before {
  content: "";
}

.fa-square-u:before {
  content: "";
}

.fa-wand-sparkles:before {
  content: "";
}

.fa-router:before {
  content: "";
}

.fa-ellipsis-vertical:before, .fa-ellipsis-v:before {
  content: "";
}

.fa-sword-laser-alt:before {
  content: "";
}

.fa-ticket:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-coin:before {
  content: "";
}

.fa-laptop-slash:before {
  content: "";
}

.fa-right-long:before, .fa-long-arrow-alt-right:before {
  content: "";
}

.fa-circle-b:before {
  content: "";
}

.fa-person-dress-simple:before {
  content: "";
}

.fa-pipe-collar:before {
  content: "";
}

.fa-lights-holiday:before {
  content: "";
}

.fa-citrus:before {
  content: "";
}

.fa-flag-usa:before {
  content: "";
}

.fa-laptop-file:before {
  content: "";
}

.fa-tty:before, .fa-teletype:before {
  content: "";
}

.fa-chart-tree-map:before {
  content: "";
}

.fa-diagram-next:before {
  content: "";
}

.fa-person-rifle:before {
  content: "";
}

.fa-clock-five-thirty:before {
  content: "";
}

.fa-pipe-valve:before {
  content: "";
}

.fa-lightbulb-message:before {
  content: "";
}

.fa-arrow-up-from-arc:before {
  content: "";
}

.fa-face-spiral-eyes:before {
  content: "";
}

.fa-compress-wide:before {
  content: "";
}

.fa-circle-phone-hangup:before, .fa-phone-circle-down:before {
  content: "";
}

.fa-gear-complex-code:before {
  content: "";
}

.fa-house-medical-circle-exclamation:before {
  content: "";
}

.fa-badminton:before {
  content: "";
}

.fa-closed-captioning:before {
  content: "";
}

.fa-person-hiking:before, .fa-hiking:before {
  content: "";
}

.fa-right-from-line:before, .fa-arrow-alt-from-left:before {
  content: "";
}

.fa-venus-double:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-calculator:before {
  content: "";
}

.fa-shuttlecock:before {
  content: "";
}

.fa-user-hair:before {
  content: "";
}

.fa-eye-evil:before {
  content: "";
}

.fa-people-pulling:before {
  content: "";
}

.fa-n:before {
  content: "N";
}

.fa-swap:before {
  content: "";
}

.fa-garage:before {
  content: "";
}

.fa-cable-car:before, .fa-tram:before {
  content: "";
}

.fa-shovel-snow:before {
  content: "";
}

.fa-cloud-rain:before {
  content: "";
}

.fa-face-lying:before {
  content: "";
}

.fa-sprinkler:before {
  content: "";
}

.fa-building-circle-xmark:before {
  content: "";
}

.fa-person-sledding:before, .fa-sledding:before {
  content: "";
}

.fa-game-console-handheld:before {
  content: "";
}

.fa-ship:before {
  content: "";
}

.fa-clock-six-thirty:before {
  content: "";
}

.fa-battery-slash:before {
  content: "";
}

.fa-tugrik-sign:before {
  content: "";
}

.fa-arrows-down-to-line:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-angles-up-down:before {
  content: "";
}

.fa-shelves:before, .fa-inventory:before {
  content: "";
}

.fa-cloud-snow:before {
  content: "";
}

.fa-face-grin:before, .fa-grin:before {
  content: "";
}

.fa-delete-left:before, .fa-backspace:before {
  content: "";
}

.fa-oven:before {
  content: "";
}

.fa-cloud-binary:before {
  content: "";
}

.fa-eye-dropper:before, .fa-eye-dropper-empty:before, .fa-eyedropper:before {
  content: "";
}

.fa-comment-captions:before {
  content: "";
}

.fa-comments-question:before {
  content: "";
}

.fa-scribble:before {
  content: "";
}

.fa-rotate-exclamation:before {
  content: "";
}

.fa-file-circle-check:before {
  content: "";
}

.fa-glass:before {
  content: "";
}

.fa-loader:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-user-pilot:before {
  content: "";
}

.fa-mobile:before, .fa-mobile-android:before, .fa-mobile-phone:before {
  content: "";
}

.fa-code-pull-request-closed:before {
  content: "";
}

.fa-face-meh:before, .fa-meh:before {
  content: "";
}

.fa-align-center:before {
  content: "";
}

.fa-book-skull:before, .fa-book-dead:before {
  content: "";
}

.fa-id-card:before, .fa-drivers-license:before {
  content: "";
}

.fa-face-dotted:before {
  content: "";
}

.fa-face-worried:before {
  content: "";
}

.fa-outdent:before, .fa-dedent:before {
  content: "";
}

.fa-court-sport:before {
  content: "";
}

.fa-heart-circle-exclamation:before {
  content: "";
}

.fa-house:before, .fa-home:before, .fa-home-alt:before, .fa-home-lg-alt:before {
  content: "";
}

.fa-vector-circle:before {
  content: "";
}

.fa-car-circle-bolt:before {
  content: "";
}

.fa-calendar-week:before {
  content: "";
}

.fa-flying-disc:before {
  content: "";
}

.fa-laptop-medical:before {
  content: "";
}

.fa-square-down-right:before {
  content: "";
}

.fa-b:before {
  content: "B";
}

.fa-seat-airline:before {
  content: "";
}

.fa-moon-over-sun:before, .fa-eclipse-alt:before {
  content: "";
}

.fa-pipe:before {
  content: "|";
}

.fa-file-medical:before {
  content: "";
}

.fa-potato:before {
  content: "";
}

.fa-dice-one:before {
  content: "";
}

.fa-circle-a:before {
  content: "";
}

.fa-helmet-battle:before {
  content: "";
}

.fa-butter:before {
  content: "";
}

.fa-blanket-fire:before {
  content: "";
}

.fa-kiwi-bird:before {
  content: "";
}

.fa-castle:before {
  content: "";
}

.fa-golf-club:before {
  content: "";
}

.fa-arrow-right-arrow-left:before, .fa-exchange:before {
  content: "";
}

.fa-rotate-right:before, .fa-redo-alt:before, .fa-rotate-forward:before {
  content: "";
}

.fa-utensils:before, .fa-cutlery:before {
  content: "";
}

.fa-arrow-up-wide-short:before, .fa-sort-amount-up:before {
  content: "";
}

.fa-chart-pie-simple-circle-dollar:before {
  content: "";
}

.fa-balloons:before {
  content: "";
}

.fa-mill-sign:before {
  content: "";
}

.fa-bowl-rice:before {
  content: "";
}

.fa-timeline-arrow:before {
  content: "";
}

.fa-skull:before {
  content: "";
}

.fa-game-board-simple:before, .fa-game-board-alt:before {
  content: "";
}

.fa-circle-video:before, .fa-video-circle:before {
  content: "";
}

.fa-chart-scatter-bubble:before {
  content: "";
}

.fa-house-turret:before {
  content: "";
}

.fa-banana:before {
  content: "";
}

.fa-hand-holding-skull:before {
  content: "";
}

.fa-people-dress:before {
  content: "";
}

.fa-loveseat:before, .fa-couch-small:before {
  content: "";
}

.fa-tower-broadcast:before, .fa-broadcast-tower:before {
  content: "";
}

.fa-truck-pickup:before {
  content: "";
}

.fa-block-quote:before {
  content: "";
}

.fa-up-long:before, .fa-long-arrow-alt-up:before {
  content: "";
}

.fa-stop:before {
  content: "";
}

.fa-code-merge:before {
  content: "";
}

.fa-money-check-dollar-pen:before, .fa-money-check-edit-alt:before {
  content: "";
}

.fa-up-from-line:before, .fa-arrow-alt-from-bottom:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-hurricane:before {
  content: "";
}

.fa-grid-round-2-plus:before {
  content: "";
}

.fa-people-pants:before {
  content: "";
}

.fa-mound:before {
  content: "";
}

.fa-windsock:before {
  content: "";
}

.fa-circle-half:before {
  content: "";
}

.fa-brake-warning:before {
  content: "";
}

.fa-toilet-portable:before {
  content: "";
}

.fa-compact-disc:before {
  content: "";
}

.fa-file-arrow-down:before, .fa-file-download:before {
  content: "";
}

.fa-saxophone-fire:before, .fa-sax-hot:before {
  content: "";
}

.fa-camera-web-slash:before, .fa-webcam-slash:before {
  content: "";
}

.fa-folder-medical:before {
  content: "";
}

.fa-folder-gear:before, .fa-folder-cog:before {
  content: "";
}

.fa-hand-wave:before {
  content: "";
}

.fa-arrow-up-arrow-down:before, .fa-sort-up-down:before {
  content: "";
}

.fa-caravan:before {
  content: "";
}

.fa-shield-cat:before {
  content: "";
}

.fa-message-slash:before, .fa-comment-alt-slash:before {
  content: "";
}

.fa-bolt:before, .fa-zap:before {
  content: "";
}

.fa-trash-can-check:before {
  content: "";
}

.fa-glass-water:before {
  content: "";
}

.fa-oil-well:before {
  content: "";
}

.fa-table-cells-column-unlock:before {
  content: "";
}

.fa-person-simple:before {
  content: "";
}

.fa-arrow-turn-left-up:before {
  content: "";
}

.fa-vault:before {
  content: "";
}

.fa-mars:before {
  content: "";
}

.fa-toilet:before {
  content: "";
}

.fa-plane-circle-xmark:before {
  content: "";
}

.fa-yen-sign:before, .fa-cny:before, .fa-jpy:before, .fa-rmb:before, .fa-yen:before {
  content: "";
}

.fa-gear-code:before {
  content: "";
}

.fa-notes:before {
  content: "";
}

.fa-ruble-sign:before, .fa-rouble:before, .fa-rub:before, .fa-ruble:before {
  content: "";
}

.fa-trash-undo:before, .fa-trash-arrow-turn-left:before {
  content: "";
}

.fa-champagne-glass:before, .fa-glass-champagne:before {
  content: "";
}

.fa-objects-align-center-horizontal:before {
  content: "";
}

.fa-sun:before {
  content: "";
}

.fa-trash-can-slash:before, .fa-trash-alt-slash:before {
  content: "";
}

.fa-screen-users:before, .fa-users-class:before {
  content: "";
}

.fa-guitar:before {
  content: "";
}

.fa-square-arrow-left:before, .fa-arrow-square-left:before {
  content: "";
}

.fa-square-8:before {
  content: "";
}

.fa-face-smile-hearts:before {
  content: "";
}

.fa-brackets-square:before, .fa-brackets:before {
  content: "";
}

.fa-laptop-arrow-down:before {
  content: "";
}

.fa-hockey-stick-puck:before {
  content: "";
}

.fa-house-tree:before {
  content: "";
}

.fa-signal-fair:before, .fa-signal-2:before {
  content: "";
}

.fa-face-laugh-wink:before, .fa-laugh-wink:before {
  content: "";
}

.fa-circle-dollar:before, .fa-dollar-circle:before, .fa-usd-circle:before {
  content: "";
}

.fa-horse-head:before {
  content: "";
}

.fa-arrows-repeat:before, .fa-repeat-alt:before {
  content: "";
}

.fa-bore-hole:before {
  content: "";
}

.fa-industry:before {
  content: "";
}

.fa-image-polaroid:before {
  content: "";
}

.fa-wave-triangle:before {
  content: "";
}

.fa-turn-left-down:before {
  content: "";
}

.fa-person-running-fast:before {
  content: "";
}

.fa-circle-down:before, .fa-arrow-alt-circle-down:before {
  content: "";
}

.fa-grill:before {
  content: "";
}

.fa-arrows-turn-to-dots:before {
  content: "";
}

.fa-chart-mixed:before, .fa-analytics:before {
  content: "";
}

.fa-florin-sign:before {
  content: "";
}

.fa-arrow-down-short-wide:before, .fa-sort-amount-desc:before, .fa-sort-amount-down-alt:before {
  content: "";
}

.fa-less-than:before {
  content: "<";
}

.fa-display-code:before, .fa-desktop-code:before {
  content: "";
}

.fa-face-drooling:before {
  content: "";
}

.fa-oil-temperature:before, .fa-oil-temp:before {
  content: "";
}

.fa-square-question:before, .fa-question-square:before {
  content: "";
}

.fa-air-conditioner:before {
  content: "";
}

.fa-angle-down:before {
  content: "";
}

.fa-mountains:before {
  content: "";
}

.fa-omega:before {
  content: "";
}

.fa-car-tunnel:before {
  content: "";
}

.fa-person-dolly-empty:before {
  content: "";
}

.fa-pan-food:before {
  content: "";
}

.fa-head-side-cough:before {
  content: "";
}

.fa-grip-lines:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-user-lock:before {
  content: "";
}

.fa-arrow-right-long:before, .fa-long-arrow-right:before {
  content: "";
}

.fa-tickets-airline:before, .fa-tickets-perforated-plane:before, .fa-tickets-plane:before {
  content: "";
}

.fa-tent-double-peak:before {
  content: "";
}

.fa-anchor-circle-xmark:before {
  content: "";
}

.fa-ellipsis:before, .fa-ellipsis-h:before {
  content: "";
}

.fa-nfc-slash:before {
  content: "";
}

.fa-chess-pawn:before {
  content: "";
}

.fa-kit-medical:before, .fa-first-aid:before {
  content: "";
}

.fa-grid-2-plus:before {
  content: "";
}

.fa-bells:before {
  content: "";
}

.fa-person-through-window:before {
  content: "";
}

.fa-toolbox:before {
  content: "";
}

.fa-globe-wifi:before {
  content: "";
}

.fa-envelope-dot:before, .fa-envelope-badge:before {
  content: "";
}

.fa-magnifying-glass-waveform:before {
  content: "";
}

.fa-hands-holding-circle:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-bowl-chopsticks:before {
  content: "";
}

.fa-credit-card:before, .fa-credit-card-alt:before {
  content: "";
}

.fa-circle-s:before {
  content: "";
}

.fa-box-ballot:before {
  content: "";
}

.fa-car:before, .fa-automobile:before {
  content: "";
}

.fa-hand-holding-hand:before {
  content: "";
}

.fa-user-tie-hair:before {
  content: "";
}

.fa-podium-star:before {
  content: "";
}

.fa-user-hair-mullet:before, .fa-business-front:before, .fa-party-back:before, .fa-trian-balbot:before {
  content: "";
}

.fa-microphone-stand:before {
  content: "";
}

.fa-book-open-reader:before, .fa-book-reader:before {
  content: "";
}

.fa-family-dress:before {
  content: "";
}

.fa-circle-x:before {
  content: "";
}

.fa-cabin:before {
  content: "";
}

.fa-mountain-sun:before {
  content: "";
}

.fa-chart-simple-horizontal:before {
  content: "";
}

.fa-arrows-left-right-to-line:before {
  content: "";
}

.fa-hand-back-point-left:before {
  content: "";
}

.fa-message-dots:before, .fa-comment-alt-dots:before, .fa-messaging:before {
  content: "";
}

.fa-file-heart:before {
  content: "";
}

.fa-beer-mug:before, .fa-beer-foam:before {
  content: "";
}

.fa-dice-d20:before {
  content: "";
}

.fa-drone:before {
  content: "";
}

.fa-truck-droplet:before {
  content: "";
}

.fa-file-circle-xmark:before {
  content: "";
}

.fa-temperature-arrow-up:before, .fa-temperature-up:before {
  content: "";
}

.fa-medal:before {
  content: "";
}

.fa-person-fairy:before {
  content: "";
}

.fa-bed:before {
  content: "";
}

.fa-book-copy:before {
  content: "";
}

.fa-square-h:before, .fa-h-square:before {
  content: "";
}

.fa-square-c:before {
  content: "";
}

.fa-clock-two:before {
  content: "";
}

.fa-square-ellipsis-vertical:before {
  content: "";
}

.fa-calendar-users:before {
  content: "";
}

.fa-podcast:before {
  content: "";
}

.fa-bee:before {
  content: "";
}

.fa-temperature-full:before, .fa-temperature-4:before, .fa-thermometer-4:before, .fa-thermometer-full:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-candy-bar:before, .fa-chocolate-bar:before {
  content: "";
}

.fa-xmark-large:before {
  content: "";
}

.fa-pinata:before {
  content: "";
}

.fa-file-ppt:before {
  content: "";
}

.fa-arrows-from-line:before {
  content: "";
}

.fa-superscript:before {
  content: "";
}

.fa-bowl-spoon:before {
  content: "";
}

.fa-hexagon-check:before {
  content: "";
}

.fa-plug-circle-xmark:before {
  content: "";
}

.fa-star-of-life:before {
  content: "";
}

.fa-phone-slash:before {
  content: "";
}

.fa-traffic-light-stop:before {
  content: "";
}

.fa-paint-roller:before {
  content: "";
}

.fa-accent-grave:before {
  content: "`";
}

.fa-handshake-angle:before, .fa-hands-helping:before {
  content: "";
}

.fa-circle-0:before {
  content: "";
}

.fa-dial-med-low:before {
  content: "";
}

.fa-location-dot:before, .fa-map-marker-alt:before {
  content: "";
}

.fa-crab:before {
  content: "";
}

.fa-box-open-full:before, .fa-box-full:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-greater-than:before {
  content: ">";
}

.fa-quotes:before {
  content: "";
}

.fa-pretzel:before {
  content: "";
}

.fa-t-rex:before {
  content: "";
}

.fa-person-swimming:before, .fa-swimmer:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-user-robot-xmarks:before {
  content: "";
}

.fa-message-quote:before, .fa-comment-alt-quote:before {
  content: "";
}

.fa-candy-corn:before {
  content: "";
}

.fa-folder-magnifying-glass:before, .fa-folder-search:before {
  content: "";
}

.fa-notebook:before {
  content: "";
}

.fa-circle-wifi:before {
  content: "";
}

.fa-droplet:before, .fa-tint:before {
  content: "";
}

.fa-bullseye-pointer:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-hexagon-image:before {
  content: "";
}

.fa-earth-americas:before, .fa-earth:before, .fa-earth-america:before, .fa-globe-americas:before {
  content: "";
}

.fa-file-svg:before {
  content: "";
}

.fa-crate-apple:before, .fa-apple-crate:before {
  content: "";
}

.fa-person-burst:before {
  content: "";
}

.fa-game-board:before {
  content: "";
}

.fa-hat-chef:before {
  content: "";
}

.fa-hand-back-point-right:before {
  content: "";
}

.fa-dove:before {
  content: "";
}

.fa-snowflake-droplets:before {
  content: "";
}

.fa-battery-empty:before, .fa-battery-0:before {
  content: "";
}

.fa-grid-4:before {
  content: "";
}

.fa-socks:before {
  content: "";
}

.fa-face-sunglasses:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-square-0:before {
  content: "";
}

.fa-section:before {
  content: "";
}

.fa-square-this-way-up:before, .fa-box-up:before {
  content: "";
}

.fa-gauge-high:before, .fa-tachometer-alt:before, .fa-tachometer-alt-fast:before {
  content: "";
}

.fa-square-ampersand:before {
  content: "";
}

.fa-envelope-open-text:before {
  content: "";
}

.fa-lamp-desk:before {
  content: "";
}

.fa-hospital:before, .fa-hospital-alt:before, .fa-hospital-wide:before {
  content: "";
}

.fa-poll-people:before {
  content: "";
}

.fa-whiskey-glass-ice:before, .fa-glass-whiskey-rocks:before {
  content: "";
}

.fa-wine-bottle:before {
  content: "";
}

.fa-chess-rook:before {
  content: "";
}

.fa-user-bounty-hunter:before {
  content: "";
}

.fa-bars-staggered:before, .fa-reorder:before, .fa-stream:before {
  content: "";
}

.fa-diagram-sankey:before {
  content: "";
}

.fa-cloud-hail-mixed:before {
  content: "";
}

.fa-circle-up-left:before {
  content: "";
}

.fa-dharmachakra:before {
  content: "";
}

.fa-objects-align-left:before {
  content: "";
}

.fa-oil-can-drip:before {
  content: "";
}

.fa-face-smiling-hands:before {
  content: "";
}

.fa-broccoli:before {
  content: "";
}

.fa-route-interstate:before {
  content: "";
}

.fa-ear-muffs:before {
  content: "";
}

.fa-hotdog:before {
  content: "";
}

.fa-transporter-empty:before {
  content: "";
}

.fa-person-walking-with-cane:before, .fa-blind:before {
  content: "";
}

.fa-angle-90:before {
  content: "";
}

.fa-rectangle-terminal:before {
  content: "";
}

.fa-kite:before {
  content: "";
}

.fa-drum:before {
  content: "";
}

.fa-scrubber:before {
  content: "";
}

.fa-ice-cream:before {
  content: "";
}

.fa-heart-circle-bolt:before {
  content: "";
}

.fa-fish-bones:before {
  content: "";
}

.fa-deer-rudolph:before {
  content: "";
}

.fa-fax:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-head-side-heart:before {
  content: "";
}

.fa-square-e:before {
  content: "";
}

.fa-meter-fire:before {
  content: "";
}

.fa-cloud-hail:before {
  content: "";
}

.fa-check-to-slot:before, .fa-vote-yea:before {
  content: "";
}

.fa-money-from-bracket:before {
  content: "";
}

.fa-star-half:before {
  content: "";
}

.fa-car-bus:before {
  content: "";
}

.fa-speaker:before {
  content: "";
}

.fa-timer:before {
  content: "";
}

.fa-boxes-stacked:before, .fa-boxes:before, .fa-boxes-alt:before {
  content: "";
}

.fa-landmark-magnifying-glass:before {
  content: "";
}

.fa-grill-hot:before {
  content: "";
}

.fa-ballot-check:before {
  content: "";
}

.fa-link:before, .fa-chain:before {
  content: "";
}

.fa-ear-listen:before, .fa-assistive-listening-systems:before {
  content: "";
}

.fa-file-minus:before {
  content: "";
}

.fa-tree-city:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-font:before {
  content: "";
}

.fa-cup-togo:before, .fa-coffee-togo:before {
  content: "";
}

.fa-square-down-left:before {
  content: "";
}

.fa-burger-lettuce:before {
  content: "";
}

.fa-table-cells-row-lock:before {
  content: "";
}

.fa-rupiah-sign:before {
  content: "";
}

.fa-magnifying-glass:before, .fa-search:before {
  content: "";
}

.fa-table-tennis-paddle-ball:before, .fa-ping-pong-paddle-ball:before, .fa-table-tennis:before {
  content: "";
}

.fa-person-dots-from-line:before, .fa-diagnoses:before {
  content: "";
}

.fa-chevrons-down:before, .fa-chevron-double-down:before {
  content: "";
}

.fa-trash-can-arrow-up:before, .fa-trash-restore-alt:before {
  content: "";
}

.fa-signal-good:before, .fa-signal-3:before {
  content: "";
}

.fa-location-question:before, .fa-map-marker-question:before {
  content: "";
}

.fa-floppy-disk-circle-xmark:before, .fa-floppy-disk-times:before, .fa-save-circle-xmark:before, .fa-save-times:before {
  content: "";
}

.fa-naira-sign:before {
  content: "";
}

.fa-peach:before {
  content: "";
}

.fa-taxi-bus:before {
  content: "";
}

.fa-bracket-curly:before, .fa-bracket-curly-left:before {
  content: "{";
}

.fa-lobster:before {
  content: "";
}

.fa-cart-flatbed-empty:before, .fa-dolly-flatbed-empty:before {
  content: "";
}

.fa-colon:before {
  content: ":";
}

.fa-cart-arrow-down:before {
  content: "";
}

.fa-wand:before {
  content: "";
}

.fa-walkie-talkie:before {
  content: "";
}

.fa-file-pen:before, .fa-file-edit:before {
  content: "";
}

.fa-receipt:before {
  content: "";
}

.fa-table-picnic:before {
  content: "";
}

.fa-square-pen:before, .fa-pen-square:before, .fa-pencil-square:before {
  content: "";
}

.fa-circle-microphone-lines:before, .fa-microphone-circle-alt:before {
  content: "";
}

.fa-display-slash:before, .fa-desktop-slash:before {
  content: "";
}

.fa-suitcase-rolling:before {
  content: "";
}

.fa-person-circle-exclamation:before {
  content: "";
}

.fa-transporter-2:before {
  content: "";
}

.fa-user-hoodie:before {
  content: "";
}

.fa-hands-holding-diamond:before, .fa-hand-receiving:before {
  content: "";
}

.fa-money-bill-simple-wave:before {
  content: "";
}

.fa-chevron-down:before {
  content: "";
}

.fa-battery-full:before, .fa-battery:before, .fa-battery-5:before {
  content: "";
}

.fa-bell-plus:before {
  content: "";
}

.fa-book-arrow-right:before {
  content: "";
}

.fa-hospitals:before {
  content: "";
}

.fa-club:before {
  content: "";
}

.fa-skull-crossbones:before {
  content: "";
}

.fa-droplet-degree:before, .fa-dewpoint:before {
  content: "";
}

.fa-code-compare:before {
  content: "";
}

.fa-list-ul:before, .fa-list-dots:before {
  content: "";
}

.fa-hand-holding-magic:before {
  content: "";
}

.fa-watermelon-slice:before {
  content: "";
}

.fa-circle-ellipsis:before {
  content: "";
}

.fa-school-lock:before {
  content: "";
}

.fa-tower-cell:before {
  content: "";
}

.fa-sd-cards:before {
  content: "";
}

.fa-jug-bottle:before {
  content: "";
}

.fa-down-long:before, .fa-long-arrow-alt-down:before {
  content: "";
}

.fa-envelopes:before {
  content: "";
}

.fa-phone-office:before {
  content: "";
}

.fa-ranking-star:before {
  content: "";
}

.fa-chess-king:before {
  content: "";
}

.fa-nfc-pen:before {
  content: "";
}

.fa-person-harassing:before {
  content: "";
}

.fa-magnifying-glass-play:before {
  content: "";
}

.fa-hat-winter:before {
  content: "";
}

.fa-brazilian-real-sign:before {
  content: "";
}

.fa-landmark-dome:before, .fa-landmark-alt:before {
  content: "";
}

.fa-bone-break:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-down-from-dotted-line:before {
  content: "";
}

.fa-tv:before, .fa-television:before, .fa-tv-alt:before {
  content: "";
}

.fa-border-left:before {
  content: "";
}

.fa-circle-divide:before {
  content: "";
}

.fa-shrimp:before {
  content: "";
}

.fa-list-check:before, .fa-tasks:before {
  content: "";
}

.fa-diagram-subtask:before {
  content: "";
}

.fa-jug-detergent:before {
  content: "";
}

.fa-circle-user:before, .fa-user-circle:before {
  content: "";
}

.fa-square-y:before {
  content: "";
}

.fa-user-doctor-hair:before {
  content: "";
}

.fa-planet-ringed:before {
  content: "";
}

.fa-mushroom:before {
  content: "";
}

.fa-user-shield:before {
  content: "";
}

.fa-megaphone:before {
  content: "";
}

.fa-wreath-laurel:before {
  content: "";
}

.fa-circle-exclamation-check:before {
  content: "";
}

.fa-wind:before {
  content: "";
}

.fa-box-dollar:before, .fa-box-usd:before {
  content: "";
}

.fa-car-burst:before, .fa-car-crash:before {
  content: "";
}

.fa-y:before {
  content: "Y";
}

.fa-user-headset:before {
  content: "";
}

.fa-arrows-retweet:before, .fa-retweet-alt:before {
  content: "";
}

.fa-person-snowboarding:before, .fa-snowboarding:before {
  content: "";
}

.fa-square-chevron-right:before, .fa-chevron-square-right:before {
  content: "";
}

.fa-lacrosse-stick-ball:before {
  content: "";
}

.fa-truck-fast:before, .fa-shipping-fast:before {
  content: "";
}

.fa-user-magnifying-glass:before {
  content: "";
}

.fa-star-sharp:before {
  content: "";
}

.fa-comment-heart:before {
  content: "";
}

.fa-circle-1:before {
  content: "";
}

.fa-circle-star:before, .fa-star-circle:before {
  content: "";
}

.fa-fish:before {
  content: "";
}

.fa-cloud-fog:before, .fa-fog:before {
  content: "";
}

.fa-waffle:before {
  content: "";
}

.fa-music-note:before, .fa-music-alt:before {
  content: "";
}

.fa-hexagon-exclamation:before {
  content: "";
}

.fa-cart-shopping-fast:before {
  content: "";
}

.fa-object-union:before {
  content: "";
}

.fa-user-graduate:before {
  content: "";
}

.fa-starfighter:before {
  content: "";
}

.fa-circle-half-stroke:before, .fa-adjust:before {
  content: "";
}

.fa-arrow-right-long-to-line:before {
  content: "";
}

.fa-square-arrow-down:before, .fa-arrow-square-down:before {
  content: "";
}

.fa-diamond-half-stroke:before {
  content: "";
}

.fa-clapperboard:before {
  content: "";
}

.fa-square-chevron-left:before, .fa-chevron-square-left:before {
  content: "";
}

.fa-phone-intercom:before {
  content: "";
}

.fa-link-horizontal:before, .fa-chain-horizontal:before {
  content: "";
}

.fa-mango:before {
  content: "";
}

.fa-music-note-slash:before, .fa-music-alt-slash:before {
  content: "";
}

.fa-circle-radiation:before, .fa-radiation-alt:before {
  content: "";
}

.fa-face-tongue-sweat:before {
  content: "";
}

.fa-globe-stand:before {
  content: "";
}

.fa-baseball:before, .fa-baseball-ball:before {
  content: "";
}

.fa-circle-p:before {
  content: "";
}

.fa-award-simple:before {
  content: "";
}

.fa-jet-fighter-up:before {
  content: "";
}

.fa-diagram-project:before, .fa-project-diagram:before {
  content: "";
}

.fa-pedestal:before {
  content: "";
}

.fa-chart-pyramid:before {
  content: "";
}

.fa-sidebar:before {
  content: "";
}

.fa-snowman-head:before, .fa-frosty-head:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-burger-glass:before {
  content: "";
}

.fa-volume-xmark:before, .fa-volume-mute:before, .fa-volume-times:before {
  content: "";
}

.fa-hand-sparkles:before {
  content: "";
}

.fa-bars-filter:before {
  content: "";
}

.fa-paintbrush-pencil:before {
  content: "";
}

.fa-party-bell:before {
  content: "";
}

.fa-user-vneck-hair:before {
  content: "";
}

.fa-jack-o-lantern:before {
  content: "";
}

.fa-grip:before, .fa-grip-horizontal:before {
  content: "";
}

.fa-share-from-square:before, .fa-share-square:before {
  content: "";
}

.fa-keynote:before {
  content: "";
}

.fa-child-combatant:before, .fa-child-rifle:before {
  content: "";
}

.fa-gun:before {
  content: "";
}

.fa-square-phone:before, .fa-phone-square:before {
  content: "";
}

.fa-hat-beach:before {
  content: "";
}

.fa-plus:before, .fa-add:before {
  content: "+";
}

.fa-expand:before {
  content: "";
}

.fa-computer:before {
  content: "";
}

.fa-fort:before {
  content: "";
}

.fa-cloud-check:before {
  content: "";
}

.fa-xmark:before, .fa-close:before, .fa-multiply:before, .fa-remove:before, .fa-times:before {
  content: "";
}

.fa-face-smirking:before {
  content: "";
}

.fa-arrows-up-down-left-right:before, .fa-arrows:before {
  content: "";
}

.fa-chalkboard-user:before, .fa-chalkboard-teacher:before {
  content: "";
}

.fa-rhombus:before {
  content: "";
}

.fa-claw-marks:before {
  content: "";
}

.fa-peso-sign:before {
  content: "";
}

.fa-face-smile-tongue:before {
  content: "";
}

.fa-cart-circle-xmark:before {
  content: "";
}

.fa-building-shield:before {
  content: "";
}

.fa-circle-phone-flip:before, .fa-phone-circle-alt:before {
  content: "";
}

.fa-baby:before {
  content: "";
}

.fa-users-line:before {
  content: "";
}

.fa-quote-left:before, .fa-quote-left-alt:before {
  content: "";
}

.fa-tractor:before {
  content: "";
}

.fa-down-from-bracket:before {
  content: "";
}

.fa-key-skeleton:before {
  content: "";
}

.fa-trash-arrow-up:before, .fa-trash-restore:before {
  content: "";
}

.fa-arrow-down-up-lock:before {
  content: "";
}

.fa-arrow-down-to-bracket:before {
  content: "";
}

.fa-lines-leaning:before {
  content: "";
}

.fa-square-q:before {
  content: "";
}

.fa-ruler-combined:before {
  content: "";
}

.fa-symbols:before, .fa-icons-alt:before {
  content: "";
}

.fa-copyright:before {
  content: "";
}

.fa-flask-gear:before {
  content: "";
}

.fa-highlighter-line:before {
  content: "";
}

.fa-bracket-square:before, .fa-bracket:before, .fa-bracket-left:before {
  content: "[";
}

.fa-island-tropical:before, .fa-island-tree-palm:before {
  content: "";
}

.fa-arrow-right-from-line:before, .fa-arrow-from-left:before {
  content: "";
}

.fa-h2:before {
  content: "";
}

.fa-equals:before {
  content: "=";
}

.fa-cake-slice:before, .fa-shortcake:before {
  content: "";
}

.fa-building-magnifying-glass:before {
  content: "";
}

.fa-peanut:before {
  content: "";
}

.fa-wrench-simple:before {
  content: "";
}

.fa-blender:before {
  content: "";
}

.fa-teeth:before {
  content: "";
}

.fa-tally-2:before {
  content: "";
}

.fa-shekel-sign:before, .fa-ils:before, .fa-shekel:before, .fa-sheqel:before, .fa-sheqel-sign:before {
  content: "";
}

.fa-cars:before {
  content: "";
}

.fa-axe-battle:before {
  content: "";
}

.fa-user-hair-long:before {
  content: "";
}

.fa-map:before {
  content: "";
}

.fa-arrow-left-from-arc:before {
  content: "";
}

.fa-file-circle-info:before {
  content: "";
}

.fa-face-disappointed:before {
  content: "";
}

.fa-lasso-sparkles:before {
  content: "";
}

.fa-clock-eleven:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-siren-on:before {
  content: "";
}

.fa-clock-ten:before {
  content: "";
}

.fa-candle-holder:before {
  content: "";
}

.fa-video-arrow-down-left:before {
  content: "";
}

.fa-photo-film:before, .fa-photo-video:before {
  content: "";
}

.fa-floppy-disk-circle-arrow-right:before, .fa-save-circle-arrow-right:before {
  content: "";
}

.fa-folder-minus:before {
  content: "";
}

.fa-planet-moon:before {
  content: "";
}

.fa-face-eyes-xmarks:before {
  content: "";
}

.fa-chart-scatter:before {
  content: "";
}

.fa-circle-gf:before {
  content: "";
}

.fa-display-arrow-down:before {
  content: "";
}

.fa-store:before {
  content: "";
}

.fa-arrow-trend-up:before {
  content: "";
}

.fa-plug-circle-minus:before {
  content: "";
}

.fa-olive-branch:before {
  content: "";
}

.fa-angle:before {
  content: "";
}

.fa-vacuum-robot:before {
  content: "";
}

.fa-sign-hanging:before, .fa-sign:before {
  content: "";
}

.fa-square-divide:before {
  content: "";
}

.fa-folder-check:before {
  content: "";
}

.fa-signal-stream-slash:before {
  content: "";
}

.fa-bezier-curve:before {
  content: "";
}

.fa-eye-dropper-half:before {
  content: "";
}

.fa-store-lock:before {
  content: "";
}

.fa-bell-slash:before {
  content: "";
}

.fa-cloud-bolt-sun:before, .fa-thunderstorm-sun:before {
  content: "";
}

.fa-camera-slash:before {
  content: "";
}

.fa-comment-quote:before {
  content: "";
}

.fa-tablet:before, .fa-tablet-android:before {
  content: "";
}

.fa-school-flag:before {
  content: "";
}

.fa-message-code:before {
  content: "";
}

.fa-glass-half:before, .fa-glass-half-empty:before, .fa-glass-half-full:before {
  content: "";
}

.fa-fill:before {
  content: "";
}

.fa-message-minus:before, .fa-comment-alt-minus:before {
  content: "";
}

.fa-angle-up:before {
  content: "";
}

.fa-dinosaur:before {
  content: "";
}

.fa-drumstick-bite:before {
  content: "";
}

.fa-link-horizontal-slash:before, .fa-chain-horizontal-slash:before {
  content: "";
}

.fa-holly-berry:before {
  content: "";
}

.fa-nose:before {
  content: "";
}

.fa-arrow-left-to-arc:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-bacteria:before {
  content: "";
}

.fa-clouds:before {
  content: "";
}

.fa-money-bill-simple:before {
  content: "";
}

.fa-hand-lizard:before {
  content: "";
}

.fa-table-pivot:before {
  content: "";
}

.fa-filter-slash:before {
  content: "";
}

.fa-trash-can-undo:before, .fa-trash-can-arrow-turn-left:before, .fa-trash-undo-alt:before {
  content: "";
}

.fa-notdef:before {
  content: "";
}

.fa-disease:before {
  content: "";
}

.fa-person-to-door:before {
  content: "";
}

.fa-turntable:before {
  content: "";
}

.fa-briefcase-medical:before {
  content: "";
}

.fa-genderless:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-signal-weak:before, .fa-signal-1:before {
  content: "";
}

.fa-clock-five:before {
  content: "";
}

.fa-retweet:before {
  content: "";
}

.fa-car-rear:before, .fa-car-alt:before {
  content: "";
}

.fa-pump-soap:before {
  content: "";
}

.fa-computer-classic:before {
  content: "";
}

.fa-frame:before {
  content: "";
}

.fa-video-slash:before {
  content: "";
}

.fa-battery-quarter:before, .fa-battery-2:before {
  content: "";
}

.fa-ellipsis-stroke:before, .fa-ellipsis-h-alt:before {
  content: "";
}

.fa-radio:before {
  content: "";
}

.fa-baby-carriage:before, .fa-carriage-baby:before {
  content: "";
}

.fa-face-expressionless:before {
  content: "";
}

.fa-down-to-dotted-line:before {
  content: "";
}

.fa-cloud-music:before {
  content: "";
}

.fa-traffic-light:before {
  content: "";
}

.fa-cloud-minus:before {
  content: "";
}

.fa-thermometer:before {
  content: "";
}

.fa-shield-minus:before {
  content: "";
}

.fa-vr-cardboard:before {
  content: "";
}

.fa-car-tilt:before {
  content: "";
}

.fa-gauge-circle-minus:before {
  content: "";
}

.fa-brightness-low:before {
  content: "";
}

.fa-hand-middle-finger:before {
  content: "";
}

.fa-percent:before, .fa-percentage:before {
  content: "%";
}

.fa-truck-moving:before {
  content: "";
}

.fa-glass-water-droplet:before {
  content: "";
}

.fa-conveyor-belt:before {
  content: "";
}

.fa-location-check:before, .fa-map-marker-check:before {
  content: "";
}

.fa-coin-vertical:before {
  content: "";
}

.fa-display:before {
  content: "";
}

.fa-person-sign:before {
  content: "";
}

.fa-face-smile:before, .fa-smile:before {
  content: "";
}

.fa-phone-hangup:before {
  content: "";
}

.fa-signature-slash:before {
  content: "";
}

.fa-thumbtack:before, .fa-thumb-tack:before {
  content: "";
}

.fa-wheat-slash:before {
  content: "";
}

.fa-trophy:before {
  content: "";
}

.fa-clouds-sun:before {
  content: "";
}

.fa-person-praying:before, .fa-pray:before {
  content: "";
}

.fa-hammer:before {
  content: "";
}

.fa-face-vomit:before {
  content: "";
}

.fa-speakers:before {
  content: "";
}

.fa-tty-answer:before, .fa-teletype-answer:before {
  content: "";
}

.fa-mug-tea-saucer:before {
  content: "";
}

.fa-diagram-lean-canvas:before {
  content: "";
}

.fa-alt:before {
  content: "";
}

.fa-dial:before, .fa-dial-med-high:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-circle-trash:before, .fa-trash-circle:before {
  content: "";
}

.fa-rotate:before, .fa-sync-alt:before {
  content: "";
}

.fa-circle-quarters:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-tower-control:before {
  content: "";
}

.fa-arrow-up-triangle-square:before, .fa-sort-shapes-up:before {
  content: "";
}

.fa-whale:before {
  content: "";
}

.fa-robot:before {
  content: "";
}

.fa-peace:before {
  content: "";
}

.fa-party-horn:before {
  content: "";
}

.fa-gears:before, .fa-cogs:before {
  content: "";
}

.fa-sun-bright:before, .fa-sun-alt:before {
  content: "";
}

.fa-warehouse:before {
  content: "";
}

.fa-conveyor-belt-arm:before {
  content: "";
}

.fa-lock-keyhole-open:before, .fa-lock-open-alt:before {
  content: "";
}

.fa-square-fragile:before, .fa-box-fragile:before, .fa-square-wine-glass-crack:before {
  content: "";
}

.fa-arrow-up-right-dots:before {
  content: "";
}

.fa-square-n:before {
  content: "";
}

.fa-splotch:before {
  content: "";
}

.fa-face-grin-hearts:before, .fa-grin-hearts:before {
  content: "";
}

.fa-meter:before {
  content: "";
}

.fa-mandolin:before {
  content: "";
}

.fa-dice-four:before {
  content: "";
}

.fa-sim-card:before {
  content: "";
}

.fa-transgender:before, .fa-transgender-alt:before {
  content: "";
}

.fa-mercury:before {
  content: "";
}

.fa-up-from-bracket:before {
  content: "";
}

.fa-knife-kitchen:before {
  content: "";
}

.fa-border-right:before {
  content: "";
}

.fa-arrow-turn-down:before, .fa-level-down:before {
  content: "";
}

.fa-spade:before {
  content: "";
}

.fa-card-spade:before {
  content: "";
}

.fa-line-columns:before {
  content: "";
}

.fa-ant:before {
  content: "";
}

.fa-arrow-right-to-line:before, .fa-arrow-to-right:before {
  content: "";
}

.fa-person-falling-burst:before {
  content: "";
}

.fa-flag-pennant:before, .fa-pennant:before {
  content: "";
}

.fa-conveyor-belt-empty:before {
  content: "";
}

.fa-user-group-simple:before {
  content: "";
}

.fa-award:before {
  content: "";
}

.fa-ticket-simple:before, .fa-ticket-alt:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-angles-left:before, .fa-angle-double-left:before {
  content: "";
}

.fa-camcorder:before, .fa-video-handheld:before {
  content: "";
}

.fa-pancakes:before {
  content: "";
}

.fa-album-circle-user:before {
  content: "";
}

.fa-subtitles-slash:before {
  content: "";
}

.fa-qrcode:before {
  content: "";
}

.fa-dice-d10:before {
  content: "";
}

.fa-fireplace:before {
  content: "";
}

.fa-browser:before {
  content: "";
}

.fa-pen-paintbrush:before, .fa-pencil-paintbrush:before {
  content: "";
}

.fa-fish-cooked:before {
  content: "";
}

.fa-chair-office:before {
  content: "";
}

.fa-magnifying-glass-music:before {
  content: "";
}

.fa-nesting-dolls:before {
  content: "";
}

.fa-clock-rotate-left:before, .fa-history:before {
  content: "";
}

.fa-trumpet:before {
  content: "";
}

.fa-face-grin-beam-sweat:before, .fa-grin-beam-sweat:before {
  content: "";
}

.fa-fire-smoke:before {
  content: "";
}

.fa-phone-missed:before {
  content: "";
}

.fa-file-export:before, .fa-arrow-right-from-file:before {
  content: "";
}

.fa-shield:before, .fa-shield-blank:before {
  content: "";
}

.fa-arrow-up-short-wide:before, .fa-sort-amount-up-alt:before {
  content: "";
}

.fa-arrows-repeat-1:before, .fa-repeat-1-alt:before {
  content: "";
}

.fa-gun-slash:before {
  content: "";
}

.fa-avocado:before {
  content: "";
}

.fa-binary:before {
  content: "";
}

.fa-glasses-round:before, .fa-glasses-alt:before {
  content: "";
}

.fa-phone-plus:before {
  content: "";
}

.fa-ditto:before {
  content: "\"";
}

.fa-person-seat:before {
  content: "";
}

.fa-house-medical:before {
  content: "";
}

.fa-golf-ball-tee:before, .fa-golf-ball:before {
  content: "";
}

.fa-circle-chevron-left:before, .fa-chevron-circle-left:before {
  content: "";
}

.fa-house-chimney-window:before {
  content: "";
}

.fa-scythe:before {
  content: "";
}

.fa-pen-nib:before {
  content: "";
}

.fa-ban-parking:before, .fa-parking-circle-slash:before {
  content: "";
}

.fa-tent-arrow-turn-left:before {
  content: "";
}

.fa-face-diagonal-mouth:before {
  content: "";
}

.fa-diagram-cells:before {
  content: "";
}

.fa-cricket-bat-ball:before, .fa-cricket:before {
  content: "";
}

.fa-tents:before {
  content: "";
}

.fa-wand-magic:before, .fa-magic:before {
  content: "";
}

.fa-dog:before {
  content: "";
}

.fa-pen-line:before {
  content: "";
}

.fa-atom-simple:before, .fa-atom-alt:before {
  content: "";
}

.fa-ampersand:before {
  content: "&";
}

.fa-carrot:before {
  content: "";
}

.fa-arrow-up-from-line:before, .fa-arrow-from-bottom:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-pen-slash:before {
  content: "";
}

.fa-wine-glass-empty:before, .fa-wine-glass-alt:before {
  content: "";
}

.fa-square-star:before {
  content: "";
}

.fa-cheese:before {
  content: "";
}

.fa-send-backward:before {
  content: "";
}

.fa-yin-yang:before {
  content: "";
}

.fa-music:before {
  content: "";
}

.fa-compass-slash:before {
  content: "";
}

.fa-clock-one:before {
  content: "";
}

.fa-file-music:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-temperature-low:before {
  content: "";
}

.fa-person-biking:before, .fa-biking:before {
  content: "";
}

.fa-display-chart-up-circle-currency:before {
  content: "";
}

.fa-skeleton:before {
  content: "";
}

.fa-circle-g:before {
  content: "";
}

.fa-circle-arrow-up-left:before {
  content: "";
}

.fa-coin-blank:before {
  content: "";
}

.fa-broom:before {
  content: "";
}

.fa-vacuum:before {
  content: "";
}

.fa-shield-heart:before {
  content: "";
}

.fa-card-heart:before {
  content: "";
}

.fa-lightbulb-cfl-on:before {
  content: "";
}

.fa-melon:before {
  content: "";
}

.fa-gopuram:before {
  content: "";
}

.fa-earth-oceania:before, .fa-globe-oceania:before {
  content: "";
}

.fa-container-storage:before {
  content: "";
}

.fa-face-pouting:before {
  content: "";
}

.fa-square-xmark:before, .fa-times-square:before, .fa-xmark-square:before {
  content: "";
}

.fa-face-explode:before, .fa-exploding-head:before {
  content: "";
}

.fa-hashtag:before {
  content: "#";
}

.fa-up-right-and-down-left-from-center:before, .fa-expand-alt:before {
  content: "";
}

.fa-oil-can:before {
  content: "";
}

.fa-t:before {
  content: "T";
}

.fa-transformer-bolt:before {
  content: "";
}

.fa-hippo:before {
  content: "";
}

.fa-chart-column:before {
  content: "";
}

.fa-cassette-vhs:before, .fa-vhs:before {
  content: "";
}

.fa-infinity:before {
  content: "";
}

.fa-vial-circle-check:before {
  content: "";
}

.fa-chimney:before {
  content: "";
}

.fa-object-intersect:before {
  content: "";
}

.fa-person-arrow-down-to-line:before {
  content: "";
}

.fa-voicemail:before {
  content: "";
}

.fa-block-brick:before, .fa-wall-brick:before {
  content: "";
}

.fa-fan:before {
  content: "";
}

.fa-bags-shopping:before {
  content: "";
}

.fa-paragraph-left:before, .fa-paragraph-rtl:before {
  content: "";
}

.fa-person-walking-luggage:before {
  content: "";
}

.fa-caravan-simple:before, .fa-caravan-alt:before {
  content: "";
}

.fa-turtle:before {
  content: "";
}

.fa-pencil-mechanical:before {
  content: "";
}

.fa-up-down:before, .fa-arrows-alt-v:before {
  content: "";
}

.fa-cloud-moon-rain:before {
  content: "";
}

.fa-booth-curtain:before {
  content: "";
}

.fa-calendar:before {
  content: "";
}

.fa-box-heart:before {
  content: "";
}

.fa-trailer:before {
  content: "";
}

.fa-user-doctor-message:before, .fa-user-md-chat:before {
  content: "";
}

.fa-bahai:before, .fa-haykal:before {
  content: "";
}

.fa-lighthouse:before {
  content: "";
}

.fa-amp-guitar:before {
  content: "";
}

.fa-sd-card:before {
  content: "";
}

.fa-volume-slash:before {
  content: "";
}

.fa-border-bottom:before {
  content: "";
}

.fa-wifi-weak:before, .fa-wifi-1:before {
  content: "";
}

.fa-dragon:before {
  content: "";
}

.fa-shoe-prints:before {
  content: "";
}

.fa-circle-plus:before, .fa-plus-circle:before {
  content: "";
}

.fa-face-grin-tongue-wink:before, .fa-grin-tongue-wink:before {
  content: "";
}

.fa-hand-holding:before {
  content: "";
}

.fa-plug-circle-exclamation:before {
  content: "";
}

.fa-link-slash:before, .fa-chain-broken:before, .fa-chain-slash:before, .fa-unlink:before {
  content: "";
}

.fa-clone:before {
  content: "";
}

.fa-person-walking-arrow-loop-left:before {
  content: "";
}

.fa-arrow-up-z-a:before, .fa-sort-alpha-up-alt:before {
  content: "";
}

.fa-fire-flame-curved:before, .fa-fire-alt:before {
  content: "";
}

.fa-tornado:before {
  content: "";
}

.fa-file-circle-plus:before {
  content: "";
}

.fa-delete-right:before {
  content: "";
}

.fa-book-quran:before, .fa-quran:before {
  content: "";
}

.fa-circle-quarter:before {
  content: "";
}

.fa-anchor:before {
  content: "";
}

.fa-border-all:before {
  content: "";
}

.fa-function:before {
  content: "";
}

.fa-face-angry:before, .fa-angry:before {
  content: "";
}

.fa-people-simple:before {
  content: "";
}

.fa-cookie-bite:before {
  content: "";
}

.fa-arrow-trend-down:before {
  content: "";
}

.fa-rss:before, .fa-feed:before {
  content: "";
}

.fa-face-monocle:before {
  content: "";
}

.fa-draw-polygon:before {
  content: "";
}

.fa-scale-balanced:before, .fa-balance-scale:before {
  content: "";
}

.fa-calendar-lines:before, .fa-calendar-note:before {
  content: "";
}

.fa-arrow-down-big-small:before, .fa-sort-size-down:before {
  content: "";
}

.fa-gauge-simple-high:before, .fa-tachometer:before, .fa-tachometer-fast:before {
  content: "";
}

.fa-do-not-enter:before {
  content: "";
}

.fa-shower:before {
  content: "";
}

.fa-dice-d8:before {
  content: "";
}

.fa-desktop:before, .fa-desktop-alt:before {
  content: "";
}

.fa-m:before {
  content: "M";
}

.fa-spinner-scale:before {
  content: "";
}

.fa-grip-dots-vertical:before {
  content: "";
}

.fa-face-viewfinder:before {
  content: "";
}

.fa-soft-serve:before, .fa-creemee:before {
  content: "";
}

.fa-h5:before {
  content: "";
}

.fa-hand-back-point-down:before {
  content: "";
}

.fa-table-list:before, .fa-th-list:before {
  content: "";
}

.fa-basket-shopping-minus:before {
  content: "";
}

.fa-comment-sms:before, .fa-sms:before {
  content: "";
}

.fa-rectangle:before, .fa-rectangle-landscape:before {
  content: "";
}

.fa-clipboard-list-check:before {
  content: "";
}

.fa-turkey:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-ice-skate:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-battery-three-quarters:before, .fa-battery-4:before {
  content: "";
}

.fa-tomato:before {
  content: "";
}

.fa-sword-laser:before {
  content: "";
}

.fa-house-circle-check:before {
  content: "";
}

.fa-buildings:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-cart-flatbed-boxes:before, .fa-dolly-flatbed-alt:before {
  content: "";
}

.fa-diagram-successor:before {
  content: "";
}

.fa-truck-arrow-right:before {
  content: "";
}

.fa-square-w:before {
  content: "";
}

.fa-arrows-split-up-and-left:before {
  content: "";
}

.fa-lamp:before {
  content: "";
}

.fa-airplay:before {
  content: "";
}

.fa-hand-fist:before, .fa-fist-raised:before {
  content: "";
}

.fa-shield-quartered:before {
  content: "";
}

.fa-slash-forward:before {
  content: "/";
}

.fa-location-pen:before, .fa-map-marker-edit:before {
  content: "";
}

.fa-cloud-moon:before {
  content: "";
}

.fa-pot-food:before {
  content: "";
}

.fa-briefcase:before {
  content: "";
}

.fa-person-falling:before {
  content: "";
}

.fa-image-portrait:before, .fa-portrait:before {
  content: "";
}

.fa-user-tag:before {
  content: "";
}

.fa-rug:before {
  content: "";
}

.fa-print-slash:before {
  content: "";
}

.fa-earth-europe:before, .fa-globe-europe:before {
  content: "";
}

.fa-cart-flatbed-suitcase:before, .fa-luggage-cart:before {
  content: "";
}

.fa-hand-back-point-ribbon:before {
  content: "";
}

.fa-rectangle-xmark:before, .fa-rectangle-times:before, .fa-times-rectangle:before, .fa-window-close:before {
  content: "";
}

.fa-tire-rugged:before {
  content: "";
}

.fa-lightbulb-dollar:before {
  content: "";
}

.fa-cowbell:before {
  content: "";
}

.fa-baht-sign:before {
  content: "";
}

.fa-corner:before {
  content: "";
}

.fa-chevrons-right:before, .fa-chevron-double-right:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-book-journal-whills:before, .fa-journal-whills:before {
  content: "";
}

.fa-inhaler:before {
  content: "";
}

.fa-handcuffs:before {
  content: "";
}

.fa-snake:before {
  content: "";
}

.fa-triangle-exclamation:before, .fa-exclamation-triangle:before, .fa-warning:before {
  content: "";
}

.fa-note-medical:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-down-left:before {
  content: "";
}

.fa-share:before, .fa-mail-forward:before {
  content: "";
}

.fa-face-thinking:before {
  content: "";
}

.fa-turn-down-right:before {
  content: "";
}

.fa-bottle-droplet:before {
  content: "";
}

.fa-mask-face:before {
  content: "";
}

.fa-hill-rockslide:before {
  content: "";
}

.fa-scanner-keyboard:before {
  content: "";
}

.fa-circle-o:before {
  content: "";
}

.fa-grid-horizontal:before {
  content: "";
}

.fa-message-dollar:before, .fa-comment-alt-dollar:before {
  content: "";
}

.fa-right-left:before, .fa-exchange-alt:before {
  content: "";
}

.fa-columns-3:before {
  content: "";
}

.fa-paper-plane:before {
  content: "";
}

.fa-road-circle-exclamation:before {
  content: "";
}

.fa-dungeon:before {
  content: "";
}

.fa-hand-holding-box:before {
  content: "";
}

.fa-input-text:before {
  content: "";
}

.fa-window-flip:before, .fa-window-alt:before {
  content: "";
}

.fa-align-right:before {
  content: "";
}

.fa-scanner-gun:before, .fa-scanner:before {
  content: "";
}

.fa-tire:before {
  content: "";
}

.fa-engine:before {
  content: "";
}

.fa-money-bill-1-wave:before, .fa-money-bill-wave-alt:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-hands:before, .fa-sign-language:before, .fa-signing:before {
  content: "";
}

.fa-circle-caret-right:before, .fa-caret-circle-right:before {
  content: "";
}

.fa-turn-left:before {
  content: "";
}

.fa-wheat:before {
  content: "";
}

.fa-file-spreadsheet:before {
  content: "";
}

.fa-audio-description-slash:before {
  content: "";
}

.fa-bell-ring:before {
  content: "";
}

.fa-calendar-day:before {
  content: "";
}

.fa-water-ladder:before, .fa-ladder-water:before, .fa-swimming-pool:before {
  content: "";
}

.fa-arrows-up-down:before, .fa-arrows-v:before {
  content: "";
}

.fa-chess-pawn-piece:before, .fa-chess-pawn-alt:before {
  content: "";
}

.fa-face-grimace:before, .fa-grimace:before {
  content: "";
}

.fa-wheelchair-move:before, .fa-wheelchair-alt:before {
  content: "";
}

.fa-turn-down:before, .fa-level-down-alt:before {
  content: "";
}

.fa-square-s:before {
  content: "";
}

.fa-rectangle-barcode:before, .fa-barcode-alt:before {
  content: "";
}

.fa-person-walking-arrow-right:before {
  content: "";
}

.fa-square-envelope:before, .fa-envelope-square:before {
  content: "";
}

.fa-dice:before {
  content: "";
}

.fa-unicorn:before {
  content: "";
}

.fa-bowling-ball:before {
  content: "";
}

.fa-pompebled:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-watch-smart:before {
  content: "";
}

.fa-book-user:before {
  content: "";
}

.fa-sensor-cloud:before, .fa-sensor-smoke:before {
  content: "";
}

.fa-clapperboard-play:before {
  content: "";
}

.fa-bandage:before, .fa-band-aid:before {
  content: "";
}

.fa-calendar-minus:before {
  content: "";
}

.fa-circle-xmark:before, .fa-times-circle:before, .fa-xmark-circle:before {
  content: "";
}

.fa-circle-4:before {
  content: "";
}

.fa-gifts:before {
  content: "";
}

.fa-album-collection:before {
  content: "";
}

.fa-hotel:before {
  content: "";
}

.fa-earth-asia:before, .fa-globe-asia:before {
  content: "";
}

.fa-id-card-clip:before, .fa-id-card-alt:before {
  content: "";
}

.fa-magnifying-glass-plus:before, .fa-search-plus:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-cloud-showers:before {
  content: "";
}

.fa-user-clock:before {
  content: "";
}

.fa-onion:before {
  content: "";
}

.fa-clock-twelve-thirty:before {
  content: "";
}

.fa-arrow-down-to-dotted-line:before {
  content: "";
}

.fa-hand-dots:before, .fa-allergies:before {
  content: "";
}

.fa-file-invoice:before {
  content: "";
}

.fa-window-minimize:before {
  content: "";
}

.fa-rectangle-wide:before {
  content: "";
}

.fa-comment-arrow-up:before {
  content: "";
}

.fa-garlic:before {
  content: "";
}

.fa-mug-saucer:before, .fa-coffee:before {
  content: "";
}

.fa-brush:before {
  content: "";
}

.fa-tree-decorated:before {
  content: "";
}

.fa-mask:before {
  content: "";
}

.fa-calendar-heart:before {
  content: "";
}

.fa-magnifying-glass-minus:before, .fa-search-minus:before {
  content: "";
}

.fa-flower:before {
  content: "";
}

.fa-arrow-down-from-arc:before {
  content: "";
}

.fa-right-left-large:before {
  content: "";
}

.fa-ruler-vertical:before {
  content: "";
}

.fa-circles-overlap:before {
  content: "";
}

.fa-user-large:before, .fa-user-alt:before {
  content: "";
}

.fa-starship-freighter:before {
  content: "";
}

.fa-train-tram:before {
  content: "";
}

.fa-bridge-suspension:before {
  content: "";
}

.fa-trash-check:before {
  content: "";
}

.fa-user-nurse:before {
  content: "";
}

.fa-boombox:before {
  content: "";
}

.fa-syringe:before {
  content: "";
}

.fa-cloud-sun:before {
  content: "";
}

.fa-shield-exclamation:before {
  content: "";
}

.fa-stopwatch-20:before {
  content: "";
}

.fa-square-full:before {
  content: "";
}

.fa-grip-dots:before {
  content: "";
}

.fa-comment-exclamation:before {
  content: "";
}

.fa-pen-swirl:before {
  content: "";
}

.fa-falafel:before {
  content: "";
}

.fa-circle-2:before {
  content: "";
}

.fa-magnet:before {
  content: "";
}

.fa-jar:before {
  content: "";
}

.fa-gramophone:before {
  content: "";
}

.fa-dice-d12:before {
  content: "";
}

.fa-note-sticky:before, .fa-sticky-note:before {
  content: "";
}

.fa-down:before, .fa-arrow-alt-down:before {
  content: "";
}

.fa-hundred-points:before, .fa-100:before {
  content: "";
}

.fa-paperclip-vertical:before {
  content: "";
}

.fa-wind-warning:before, .fa-wind-circle-exclamation:before {
  content: "";
}

.fa-location-pin-slash:before, .fa-map-marker-slash:before {
  content: "";
}

.fa-face-sad-sweat:before {
  content: "";
}

.fa-bug-slash:before {
  content: "";
}

.fa-cupcake:before {
  content: "";
}

.fa-light-switch-off:before {
  content: "";
}

.fa-toggle-large-off:before {
  content: "";
}

.fa-pen-fancy-slash:before {
  content: "";
}

.fa-truck-container:before {
  content: "";
}

.fa-boot:before {
  content: "";
}

.fa-arrow-up-from-water-pump:before {
  content: "";
}

.fa-file-check:before {
  content: "";
}

.fa-bone:before {
  content: "";
}

.fa-cards-blank:before {
  content: "";
}

.fa-circle-3:before {
  content: "";
}

.fa-bench-tree:before {
  content: "";
}

.fa-keyboard-brightness-low:before {
  content: "";
}

.fa-ski-boot-ski:before {
  content: "";
}

.fa-brain-circuit:before {
  content: "";
}

.fa-table-cells-row-unlock:before {
  content: "";
}

.fa-user-injured:before {
  content: "";
}

.fa-block-brick-fire:before, .fa-firewall:before {
  content: "";
}

.fa-face-sad-tear:before, .fa-sad-tear:before {
  content: "";
}

.fa-plane:before {
  content: "";
}

.fa-tent-arrows-down:before {
  content: "";
}

.fa-exclamation:before {
  content: "!";
}

.fa-arrows-spin:before {
  content: "";
}

.fa-face-smile-relaxed:before {
  content: "";
}

.fa-comment-xmark:before, .fa-comment-times:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-turkish-lira-sign:before, .fa-try:before, .fa-turkish-lira:before {
  content: "";
}

.fa-face-nose-steam:before {
  content: "";
}

.fa-circle-waveform-lines:before, .fa-waveform-circle:before {
  content: "";
}

.fa-dollar-sign:before, .fa-dollar:before, .fa-usd:before {
  content: "$";
}

.fa-ferris-wheel:before {
  content: "";
}

.fa-computer-speaker:before {
  content: "";
}

.fa-skull-cow:before {
  content: "";
}

.fa-x:before {
  content: "X";
}

.fa-magnifying-glass-dollar:before, .fa-search-dollar:before {
  content: "";
}

.fa-users-gear:before, .fa-users-cog:before {
  content: "";
}

.fa-person-military-pointing:before {
  content: "";
}

.fa-building-columns:before, .fa-bank:before, .fa-institution:before, .fa-museum:before, .fa-university:before {
  content: "";
}

.fa-circle-t:before {
  content: "";
}

.fa-sack:before {
  content: "";
}

.fa-grid-2:before {
  content: "";
}

.fa-camera-cctv:before, .fa-cctv:before {
  content: "";
}

.fa-umbrella:before {
  content: "";
}

.fa-trowel:before {
  content: "";
}

.fa-horizontal-rule:before {
  content: "";
}

.fa-bed-front:before, .fa-bed-alt:before {
  content: "";
}

.fa-d:before {
  content: "D";
}

.fa-stapler:before {
  content: "";
}

.fa-masks-theater:before, .fa-theater-masks:before {
  content: "";
}

.fa-file-gif:before {
  content: "";
}

.fa-kip-sign:before {
  content: "";
}

.fa-face-woozy:before {
  content: "";
}

.fa-cloud-question:before {
  content: "";
}

.fa-pineapple:before {
  content: "";
}

.fa-hand-point-left:before {
  content: "";
}

.fa-gallery-thumbnails:before {
  content: "";
}

.fa-circle-j:before {
  content: "";
}

.fa-eyes:before {
  content: "";
}

.fa-handshake-simple:before, .fa-handshake-alt:before {
  content: "";
}

.fa-page-caret-up:before, .fa-file-caret-up:before {
  content: "";
}

.fa-jet-fighter:before, .fa-fighter-jet:before {
  content: "";
}

.fa-comet:before {
  content: "";
}

.fa-square-share-nodes:before, .fa-share-alt-square:before {
  content: "";
}

.fa-reflect-vertical:before {
  content: "";
}

.fa-shield-keyhole:before {
  content: "";
}

.fa-file-mp4:before {
  content: "";
}

.fa-barcode:before {
  content: "";
}

.fa-bulldozer:before {
  content: "";
}

.fa-plus-minus:before {
  content: "";
}

.fa-square-sliders-vertical:before, .fa-sliders-v-square:before {
  content: "";
}

.fa-video:before, .fa-video-camera:before {
  content: "";
}

.fa-message-middle:before, .fa-comment-middle-alt:before {
  content: "";
}

.fa-graduation-cap:before, .fa-mortar-board:before {
  content: "";
}

.fa-hand-holding-medical:before {
  content: "";
}

.fa-person-circle-check:before {
  content: "";
}

.fa-square-z:before {
  content: "";
}

.fa-message-text:before, .fa-comment-alt-text:before {
  content: "";
}

.fa-turn-up:before, .fa-level-up-alt:before {
  content: "";
}

.sr-only, .fa-sr-only, .sr-only-focusable:not(:focus), .fa-sr-only-focusable:not(:focus) {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

:root, :host {
  --fa-style-family-brands: "Font Awesome 6 Brands";
  --fa-font-brands: normal 400 1em / 1 "Font Awesome 6 Brands";
}

@font-face {
  font-family: "Font Awesome 6 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-brands-400.0859ffe7.woff2") format("woff2"), url("fa-brands-400.465c5219.ttf") format("truetype");
}

.fab, .fa-brands {
  font-weight: 400;
}

.fa-monero:before {
  content: "";
}

.fa-hooli:before {
  content: "";
}

.fa-yelp:before {
  content: "";
}

.fa-cc-visa:before {
  content: "";
}

.fa-lastfm:before {
  content: "";
}

.fa-shopware:before {
  content: "";
}

.fa-creative-commons-nc:before {
  content: "";
}

.fa-aws:before {
  content: "";
}

.fa-redhat:before {
  content: "";
}

.fa-yoast:before {
  content: "";
}

.fa-cloudflare:before {
  content: "";
}

.fa-ups:before {
  content: "";
}

.fa-pixiv:before {
  content: "";
}

.fa-wpexplorer:before {
  content: "";
}

.fa-dyalog:before {
  content: "";
}

.fa-bity:before {
  content: "";
}

.fa-stackpath:before {
  content: "";
}

.fa-buysellads:before {
  content: "";
}

.fa-first-order:before {
  content: "";
}

.fa-modx:before {
  content: "";
}

.fa-guilded:before {
  content: "";
}

.fa-vnv:before {
  content: "";
}

.fa-square-js:before, .fa-js-square:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-qq:before {
  content: "";
}

.fa-orcid:before {
  content: "";
}

.fa-java:before {
  content: "";
}

.fa-invision:before {
  content: "";
}

.fa-creative-commons-pd-alt:before {
  content: "";
}

.fa-centercode:before {
  content: "";
}

.fa-glide-g:before {
  content: "";
}

.fa-drupal:before {
  content: "";
}

.fa-jxl:before {
  content: "";
}

.fa-dart-lang:before {
  content: "";
}

.fa-hire-a-helper:before {
  content: "";
}

.fa-creative-commons-by:before {
  content: "";
}

.fa-unity:before {
  content: "";
}

.fa-whmcs:before {
  content: "";
}

.fa-rocketchat:before {
  content: "";
}

.fa-vk:before {
  content: "";
}

.fa-untappd:before {
  content: "";
}

.fa-mailchimp:before {
  content: "";
}

.fa-css3-alt:before {
  content: "";
}

.fa-square-reddit:before, .fa-reddit-square:before {
  content: "";
}

.fa-vimeo-v:before {
  content: "";
}

.fa-contao:before {
  content: "";
}

.fa-square-font-awesome:before {
  content: "";
}

.fa-deskpro:before {
  content: "";
}

.fa-brave:before {
  content: "";
}

.fa-sistrix:before {
  content: "";
}

.fa-square-instagram:before, .fa-instagram-square:before {
  content: "";
}

.fa-battle-net:before {
  content: "";
}

.fa-the-red-yeti:before {
  content: "";
}

.fa-square-hacker-news:before, .fa-hacker-news-square:before {
  content: "";
}

.fa-edge:before {
  content: "";
}

.fa-threads:before {
  content: "";
}

.fa-napster:before {
  content: "";
}

.fa-square-snapchat:before, .fa-snapchat-square:before {
  content: "";
}

.fa-google-plus-g:before {
  content: "";
}

.fa-artstation:before {
  content: "";
}

.fa-markdown:before {
  content: "";
}

.fa-sourcetree:before {
  content: "";
}

.fa-google-plus:before {
  content: "";
}

.fa-diaspora:before {
  content: "";
}

.fa-foursquare:before {
  content: "";
}

.fa-stack-overflow:before {
  content: "";
}

.fa-github-alt:before {
  content: "";
}

.fa-phoenix-squadron:before {
  content: "";
}

.fa-pagelines:before {
  content: "";
}

.fa-algolia:before {
  content: "";
}

.fa-red-river:before {
  content: "";
}

.fa-creative-commons-sa:before {
  content: "";
}

.fa-safari:before {
  content: "";
}

.fa-google:before {
  content: "";
}

.fa-square-font-awesome-stroke:before, .fa-font-awesome-alt:before {
  content: "";
}

.fa-atlassian:before {
  content: "";
}

.fa-linkedin-in:before {
  content: "";
}

.fa-digital-ocean:before {
  content: "";
}

.fa-nimblr:before {
  content: "";
}

.fa-chromecast:before {
  content: "";
}

.fa-evernote:before {
  content: "";
}

.fa-hacker-news:before {
  content: "";
}

.fa-creative-commons-sampling:before {
  content: "";
}

.fa-adversal:before {
  content: "";
}

.fa-creative-commons:before {
  content: "";
}

.fa-watchman-monitoring:before {
  content: "";
}

.fa-fonticons:before {
  content: "";
}

.fa-weixin:before {
  content: "";
}

.fa-shirtsinbulk:before {
  content: "";
}

.fa-codepen:before {
  content: "";
}

.fa-git-alt:before {
  content: "";
}

.fa-lyft:before {
  content: "";
}

.fa-rev:before {
  content: "";
}

.fa-windows:before {
  content: "";
}

.fa-wizards-of-the-coast:before {
  content: "";
}

.fa-square-viadeo:before, .fa-viadeo-square:before {
  content: "";
}

.fa-meetup:before {
  content: "";
}

.fa-centos:before {
  content: "";
}

.fa-adn:before {
  content: "";
}

.fa-cloudsmith:before {
  content: "";
}

.fa-opensuse:before {
  content: "";
}

.fa-pied-piper-alt:before {
  content: "";
}

.fa-square-dribbble:before, .fa-dribbble-square:before {
  content: "";
}

.fa-codiepie:before {
  content: "";
}

.fa-node:before {
  content: "";
}

.fa-mix:before {
  content: "";
}

.fa-steam:before {
  content: "";
}

.fa-cc-apple-pay:before {
  content: "";
}

.fa-scribd:before {
  content: "";
}

.fa-debian:before {
  content: "";
}

.fa-openid:before {
  content: "";
}

.fa-instalod:before {
  content: "";
}

.fa-expeditedssl:before {
  content: "";
}

.fa-sellcast:before {
  content: "";
}

.fa-square-twitter:before, .fa-twitter-square:before {
  content: "";
}

.fa-r-project:before {
  content: "";
}

.fa-delicious:before {
  content: "";
}

.fa-freebsd:before {
  content: "";
}

.fa-vuejs:before {
  content: "";
}

.fa-accusoft:before {
  content: "";
}

.fa-ioxhost:before {
  content: "";
}

.fa-fonticons-fi:before {
  content: "";
}

.fa-app-store:before {
  content: "";
}

.fa-cc-mastercard:before {
  content: "";
}

.fa-itunes-note:before {
  content: "";
}

.fa-golang:before {
  content: "";
}

.fa-kickstarter:before, .fa-square-kickstarter:before {
  content: "";
}

.fa-grav:before {
  content: "";
}

.fa-weibo:before {
  content: "";
}

.fa-uncharted:before {
  content: "";
}

.fa-firstdraft:before {
  content: "";
}

.fa-square-youtube:before, .fa-youtube-square:before {
  content: "";
}

.fa-wikipedia-w:before {
  content: "";
}

.fa-wpressr:before, .fa-rendact:before {
  content: "";
}

.fa-angellist:before {
  content: "";
}

.fa-galactic-republic:before {
  content: "";
}

.fa-nfc-directional:before {
  content: "";
}

.fa-skype:before {
  content: "";
}

.fa-joget:before {
  content: "";
}

.fa-fedora:before {
  content: "";
}

.fa-stripe-s:before {
  content: "";
}

.fa-meta:before {
  content: "";
}

.fa-laravel:before {
  content: "";
}

.fa-hotjar:before {
  content: "";
}

.fa-bluetooth-b:before {
  content: "";
}

.fa-square-letterboxd:before {
  content: "";
}

.fa-sticker-mule:before {
  content: "";
}

.fa-creative-commons-zero:before {
  content: "";
}

.fa-hips:before {
  content: "";
}

.fa-behance:before {
  content: "";
}

.fa-reddit:before {
  content: "";
}

.fa-discord:before {
  content: "";
}

.fa-chrome:before {
  content: "";
}

.fa-app-store-ios:before {
  content: "";
}

.fa-cc-discover:before {
  content: "";
}

.fa-wpbeginner:before {
  content: "";
}

.fa-confluence:before {
  content: "";
}

.fa-shoelace:before {
  content: "";
}

.fa-mdb:before {
  content: "";
}

.fa-dochub:before {
  content: "";
}

.fa-accessible-icon:before {
  content: "";
}

.fa-ebay:before {
  content: "";
}

.fa-amazon:before {
  content: "";
}

.fa-unsplash:before {
  content: "";
}

.fa-yarn:before {
  content: "";
}

.fa-square-steam:before, .fa-steam-square:before {
  content: "";
}

.fa-500px:before {
  content: "";
}

.fa-square-vimeo:before, .fa-vimeo-square:before {
  content: "";
}

.fa-asymmetrik:before {
  content: "";
}

.fa-font-awesome:before, .fa-font-awesome-flag:before, .fa-font-awesome-logo-full:before {
  content: "";
}

.fa-gratipay:before {
  content: "";
}

.fa-apple:before {
  content: "";
}

.fa-hive:before {
  content: "";
}

.fa-gitkraken:before {
  content: "";
}

.fa-keybase:before {
  content: "";
}

.fa-apple-pay:before {
  content: "";
}

.fa-padlet:before {
  content: "";
}

.fa-amazon-pay:before {
  content: "";
}

.fa-square-github:before, .fa-github-square:before {
  content: "";
}

.fa-stumbleupon:before {
  content: "";
}

.fa-fedex:before {
  content: "";
}

.fa-phoenix-framework:before {
  content: "";
}

.fa-shopify:before {
  content: "";
}

.fa-neos:before {
  content: "";
}

.fa-square-threads:before {
  content: "";
}

.fa-hackerrank:before {
  content: "";
}

.fa-researchgate:before {
  content: "";
}

.fa-swift:before {
  content: "";
}

.fa-angular:before {
  content: "";
}

.fa-speakap:before {
  content: "";
}

.fa-angrycreative:before {
  content: "";
}

.fa-y-combinator:before {
  content: "";
}

.fa-empire:before {
  content: "";
}

.fa-envira:before {
  content: "";
}

.fa-google-scholar:before {
  content: "";
}

.fa-square-gitlab:before, .fa-gitlab-square:before {
  content: "";
}

.fa-studiovinari:before {
  content: "";
}

.fa-pied-piper:before {
  content: "";
}

.fa-wordpress:before {
  content: "";
}

.fa-product-hunt:before {
  content: "";
}

.fa-firefox:before {
  content: "";
}

.fa-linode:before {
  content: "";
}

.fa-goodreads:before {
  content: "";
}

.fa-square-odnoklassniki:before, .fa-odnoklassniki-square:before {
  content: "";
}

.fa-jsfiddle:before {
  content: "";
}

.fa-sith:before {
  content: "";
}

.fa-themeisle:before {
  content: "";
}

.fa-page4:before {
  content: "";
}

.fa-hashnode:before {
  content: "";
}

.fa-react:before {
  content: "";
}

.fa-cc-paypal:before {
  content: "";
}

.fa-squarespace:before {
  content: "";
}

.fa-cc-stripe:before {
  content: "";
}

.fa-creative-commons-share:before {
  content: "";
}

.fa-bitcoin:before {
  content: "";
}

.fa-keycdn:before {
  content: "";
}

.fa-opera:before {
  content: "";
}

.fa-itch-io:before {
  content: "";
}

.fa-umbraco:before {
  content: "";
}

.fa-galactic-senate:before {
  content: "";
}

.fa-ubuntu:before {
  content: "";
}

.fa-draft2digital:before {
  content: "";
}

.fa-stripe:before {
  content: "";
}

.fa-houzz:before {
  content: "";
}

.fa-gg:before {
  content: "";
}

.fa-dhl:before {
  content: "";
}

.fa-square-pinterest:before, .fa-pinterest-square:before {
  content: "";
}

.fa-xing:before {
  content: "";
}

.fa-blackberry:before {
  content: "";
}

.fa-creative-commons-pd:before {
  content: "";
}

.fa-playstation:before {
  content: "";
}

.fa-quinscape:before {
  content: "";
}

.fa-less:before {
  content: "";
}

.fa-blogger-b:before {
  content: "";
}

.fa-opencart:before {
  content: "";
}

.fa-vine:before {
  content: "";
}

.fa-signal-messenger:before {
  content: "";
}

.fa-paypal:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-typo3:before {
  content: "";
}

.fa-reddit-alien:before {
  content: "";
}

.fa-yahoo:before {
  content: "";
}

.fa-dailymotion:before {
  content: "";
}

.fa-affiliatetheme:before {
  content: "";
}

.fa-pied-piper-pp:before {
  content: "";
}

.fa-bootstrap:before {
  content: "";
}

.fa-odnoklassniki:before {
  content: "";
}

.fa-nfc-symbol:before {
  content: "";
}

.fa-mintbit:before {
  content: "";
}

.fa-ethereum:before {
  content: "";
}

.fa-speaker-deck:before {
  content: "";
}

.fa-creative-commons-nc-eu:before {
  content: "";
}

.fa-patreon:before {
  content: "";
}

.fa-avianex:before {
  content: "";
}

.fa-ello:before {
  content: "";
}

.fa-gofore:before {
  content: "";
}

.fa-bimobject:before {
  content: "";
}

.fa-brave-reverse:before {
  content: "";
}

.fa-facebook-f:before {
  content: "";
}

.fa-square-google-plus:before, .fa-google-plus-square:before {
  content: "";
}

.fa-web-awesome:before {
  content: "";
}

.fa-mandalorian:before {
  content: "";
}

.fa-first-order-alt:before {
  content: "";
}

.fa-osi:before {
  content: "";
}

.fa-google-wallet:before {
  content: "";
}

.fa-d-and-d-beyond:before {
  content: "";
}

.fa-periscope:before {
  content: "";
}

.fa-fulcrum:before {
  content: "";
}

.fa-cloudscale:before {
  content: "";
}

.fa-forumbee:before {
  content: "";
}

.fa-mizuni:before {
  content: "";
}

.fa-schlix:before {
  content: "";
}

.fa-square-xing:before, .fa-xing-square:before {
  content: "";
}

.fa-bandcamp:before {
  content: "";
}

.fa-wpforms:before {
  content: "";
}

.fa-cloudversify:before {
  content: "";
}

.fa-usps:before {
  content: "";
}

.fa-megaport:before {
  content: "";
}

.fa-magento:before {
  content: "";
}

.fa-spotify:before {
  content: "";
}

.fa-optin-monster:before {
  content: "";
}

.fa-fly:before {
  content: "";
}

.fa-aviato:before {
  content: "";
}

.fa-itunes:before {
  content: "";
}

.fa-cuttlefish:before {
  content: "";
}

.fa-blogger:before {
  content: "";
}

.fa-flickr:before {
  content: "";
}

.fa-viber:before {
  content: "";
}

.fa-soundcloud:before {
  content: "";
}

.fa-digg:before {
  content: "";
}

.fa-tencent-weibo:before {
  content: "";
}

.fa-letterboxd:before {
  content: "";
}

.fa-symfony:before {
  content: "";
}

.fa-maxcdn:before {
  content: "";
}

.fa-etsy:before {
  content: "";
}

.fa-facebook-messenger:before {
  content: "";
}

.fa-audible:before {
  content: "";
}

.fa-think-peaks:before {
  content: "";
}

.fa-bilibili:before {
  content: "";
}

.fa-erlang:before {
  content: "";
}

.fa-x-twitter:before {
  content: "";
}

.fa-cotton-bureau:before {
  content: "";
}

.fa-dashcube:before {
  content: "";
}

.fa-42-group:before, .fa-innosoft:before {
  content: "";
}

.fa-stack-exchange:before {
  content: "";
}

.fa-elementor:before {
  content: "";
}

.fa-square-pied-piper:before, .fa-pied-piper-square:before {
  content: "";
}

.fa-creative-commons-nd:before {
  content: "";
}

.fa-palfed:before {
  content: "";
}

.fa-superpowers:before {
  content: "";
}

.fa-resolving:before {
  content: "";
}

.fa-xbox:before {
  content: "";
}

.fa-square-web-awesome-stroke:before {
  content: "";
}

.fa-searchengin:before {
  content: "";
}

.fa-tiktok:before {
  content: "";
}

.fa-square-facebook:before, .fa-facebook-square:before {
  content: "";
}

.fa-renren:before {
  content: "";
}

.fa-linux:before {
  content: "";
}

.fa-glide:before {
  content: "";
}

.fa-linkedin:before {
  content: "";
}

.fa-hubspot:before {
  content: "";
}

.fa-deploydog:before {
  content: "";
}

.fa-twitch:before {
  content: "";
}

.fa-flutter:before {
  content: "";
}

.fa-ravelry:before {
  content: "";
}

.fa-mixer:before {
  content: "";
}

.fa-square-lastfm:before, .fa-lastfm-square:before {
  content: "";
}

.fa-vimeo:before {
  content: "";
}

.fa-mendeley:before {
  content: "";
}

.fa-uniregistry:before {
  content: "";
}

.fa-figma:before {
  content: "";
}

.fa-creative-commons-remix:before {
  content: "";
}

.fa-cc-amazon-pay:before {
  content: "";
}

.fa-dropbox:before {
  content: "";
}

.fa-instagram:before {
  content: "";
}

.fa-cmplid:before {
  content: "";
}

.fa-upwork:before {
  content: "";
}

.fa-facebook:before {
  content: "";
}

.fa-gripfire:before {
  content: "";
}

.fa-jedi-order:before {
  content: "";
}

.fa-uikit:before {
  content: "";
}

.fa-fort-awesome-alt:before {
  content: "";
}

.fa-phabricator:before {
  content: "";
}

.fa-ussunnah:before {
  content: "";
}

.fa-earlybirds:before {
  content: "";
}

.fa-trade-federation:before {
  content: "";
}

.fa-autoprefixer:before {
  content: "";
}

.fa-whatsapp:before {
  content: "";
}

.fa-square-upwork:before {
  content: "";
}

.fa-slideshare:before {
  content: "";
}

.fa-google-play:before {
  content: "";
}

.fa-viadeo:before {
  content: "";
}

.fa-line:before {
  content: "";
}

.fa-google-drive:before {
  content: "";
}

.fa-servicestack:before {
  content: "";
}

.fa-simplybuilt:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-imdb:before {
  content: "";
}

.fa-deezer:before {
  content: "";
}

.fa-raspberry-pi:before {
  content: "";
}

.fa-jira:before {
  content: "";
}

.fa-docker:before {
  content: "";
}

.fa-screenpal:before {
  content: "";
}

.fa-bluetooth:before {
  content: "";
}

.fa-gitter:before {
  content: "";
}

.fa-d-and-d:before {
  content: "";
}

.fa-microblog:before {
  content: "";
}

.fa-cc-diners-club:before {
  content: "";
}

.fa-gg-circle:before {
  content: "";
}

.fa-pied-piper-hat:before {
  content: "";
}

.fa-kickstarter-k:before {
  content: "";
}

.fa-yandex:before {
  content: "";
}

.fa-readme:before {
  content: "";
}

.fa-html5:before {
  content: "";
}

.fa-sellsy:before {
  content: "";
}

.fa-square-web-awesome:before {
  content: "";
}

.fa-sass:before {
  content: "";
}

.fa-wirsindhandwerk:before, .fa-wsh:before {
  content: "";
}

.fa-buromobelexperte:before {
  content: "";
}

.fa-salesforce:before {
  content: "";
}

.fa-octopus-deploy:before {
  content: "";
}

.fa-medapps:before {
  content: "";
}

.fa-ns8:before {
  content: "";
}

.fa-pinterest-p:before {
  content: "";
}

.fa-apper:before {
  content: "";
}

.fa-fort-awesome:before {
  content: "";
}

.fa-waze:before {
  content: "";
}

.fa-bluesky:before {
  content: "";
}

.fa-cc-jcb:before {
  content: "";
}

.fa-snapchat:before, .fa-snapchat-ghost:before {
  content: "";
}

.fa-fantasy-flight-games:before {
  content: "";
}

.fa-rust:before {
  content: "";
}

.fa-wix:before {
  content: "";
}

.fa-square-behance:before, .fa-behance-square:before {
  content: "";
}

.fa-supple:before {
  content: "";
}

.fa-webflow:before {
  content: "";
}

.fa-rebel:before {
  content: "";
}

.fa-css3:before {
  content: "";
}

.fa-staylinked:before {
  content: "";
}

.fa-kaggle:before {
  content: "";
}

.fa-space-awesome:before {
  content: "";
}

.fa-deviantart:before {
  content: "";
}

.fa-cpanel:before {
  content: "";
}

.fa-goodreads-g:before {
  content: "";
}

.fa-square-git:before, .fa-git-square:before {
  content: "";
}

.fa-square-tumblr:before, .fa-tumblr-square:before {
  content: "";
}

.fa-trello:before {
  content: "";
}

.fa-creative-commons-nc-jp:before {
  content: "";
}

.fa-get-pocket:before {
  content: "";
}

.fa-perbyte:before {
  content: "";
}

.fa-grunt:before {
  content: "";
}

.fa-weebly:before {
  content: "";
}

.fa-connectdevelop:before {
  content: "";
}

.fa-leanpub:before {
  content: "";
}

.fa-black-tie:before {
  content: "";
}

.fa-themeco:before {
  content: "";
}

.fa-python:before {
  content: "";
}

.fa-android:before {
  content: "";
}

.fa-bots:before {
  content: "";
}

.fa-free-code-camp:before {
  content: "";
}

.fa-hornbill:before {
  content: "";
}

.fa-js:before {
  content: "";
}

.fa-ideal:before {
  content: "";
}

.fa-git:before {
  content: "";
}

.fa-dev:before {
  content: "";
}

.fa-sketch:before {
  content: "";
}

.fa-yandex-international:before {
  content: "";
}

.fa-cc-amex:before {
  content: "";
}

.fa-uber:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-php:before {
  content: "";
}

.fa-alipay:before {
  content: "";
}

.fa-youtube:before {
  content: "";
}

.fa-skyatlas:before {
  content: "";
}

.fa-firefox-browser:before {
  content: "";
}

.fa-replyd:before {
  content: "";
}

.fa-suse:before {
  content: "";
}

.fa-jenkins:before {
  content: "";
}

.fa-twitter:before {
  content: "";
}

.fa-rockrms:before {
  content: "";
}

.fa-pinterest:before {
  content: "";
}

.fa-buffer:before {
  content: "";
}

.fa-npm:before {
  content: "";
}

.fa-yammer:before {
  content: "";
}

.fa-btc:before {
  content: "";
}

.fa-dribbble:before {
  content: "";
}

.fa-stumbleupon-circle:before {
  content: "";
}

.fa-internet-explorer:before {
  content: "";
}

.fa-stubber:before {
  content: "";
}

.fa-telegram:before, .fa-telegram-plane:before {
  content: "";
}

.fa-old-republic:before {
  content: "";
}

.fa-odysee:before {
  content: "";
}

.fa-square-whatsapp:before, .fa-whatsapp-square:before {
  content: "";
}

.fa-node-js:before {
  content: "";
}

.fa-edge-legacy:before {
  content: "";
}

.fa-slack:before, .fa-slack-hash:before {
  content: "";
}

.fa-medrt:before {
  content: "";
}

.fa-usb:before {
  content: "";
}

.fa-tumblr:before {
  content: "";
}

.fa-vaadin:before {
  content: "";
}

.fa-quora:before {
  content: "";
}

.fa-square-x-twitter:before {
  content: "";
}

.fa-reacteurope:before {
  content: "";
}

.fa-medium:before, .fa-medium-m:before {
  content: "";
}

.fa-amilia:before {
  content: "";
}

.fa-mixcloud:before {
  content: "";
}

.fa-flipboard:before {
  content: "";
}

.fa-viacoin:before {
  content: "";
}

.fa-critical-role:before {
  content: "";
}

.fa-sitrox:before {
  content: "";
}

.fa-discourse:before {
  content: "";
}

.fa-joomla:before {
  content: "";
}

.fa-mastodon:before {
  content: "";
}

.fa-airbnb:before {
  content: "";
}

.fa-wolf-pack-battalion:before {
  content: "";
}

.fa-buy-n-large:before {
  content: "";
}

.fa-gulp:before {
  content: "";
}

.fa-creative-commons-sampling-plus:before {
  content: "";
}

.fa-strava:before {
  content: "";
}

.fa-ember:before {
  content: "";
}

.fa-canadian-maple-leaf:before {
  content: "";
}

.fa-teamspeak:before {
  content: "";
}

.fa-pushed:before {
  content: "";
}

.fa-wordpress-simple:before {
  content: "";
}

.fa-nutritionix:before {
  content: "";
}

.fa-wodu:before {
  content: "";
}

.fa-google-pay:before {
  content: "";
}

.fa-intercom:before {
  content: "";
}

.fa-zhihu:before {
  content: "";
}

.fa-korvue:before {
  content: "";
}

.fa-pix:before {
  content: "";
}

.fa-steam-symbol:before {
  content: "";
}

:root, :host {
  --fa-style-family-duotone: "Font Awesome 6 Duotone";
  --fa-font-duotone: normal 900 1em / 1 "Font Awesome 6 Duotone";
}

@font-face {
  font-family: "Font Awesome 6 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-duotone-900.af241644.woff2") format("woff2"), url("fa-duotone-900.442e9bfa.ttf") format("truetype");
}

.fad, .fa-duotone {
  letter-spacing: normal;
  font-weight: 900;
  position: relative;
}

.fad:before, .fa-duotone:before {
  color: var(--fa-primary-color, inherit);
  opacity: var(--fa-primary-opacity, 1);
  position: absolute;
}

.fad:after, .fa-duotone:after {
  color: var(--fa-secondary-color, inherit);
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:before, .fa-swap-opacity .fa-duotone:before, .fad.fa-swap-opacity:before, .fa-duotone.fa-swap-opacity:before {
  opacity: var(--fa-secondary-opacity, .4);
}

.fa-swap-opacity .fad:after, .fa-swap-opacity .fa-duotone:after, .fad.fa-swap-opacity:after, .fa-duotone.fa-swap-opacity:after {
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse, .fa-duotone.fa-inverse {
  color: var(--fa-inverse, #fff);
}

.fad.fa-stack-1x, .fad.fa-stack-2x, .fa-duotone.fa-stack-1x, .fa-duotone.fa-stack-2x {
  position: absolute;
}

.fad.fa-0:after, .fa-duotone.fa-0:after {
  content: "00";
}

.fad.fa-1:after, .fa-duotone.fa-1:after {
  content: "11";
}

.fad.fa-2:after, .fa-duotone.fa-2:after {
  content: "22";
}

.fad.fa-3:after, .fa-duotone.fa-3:after {
  content: "33";
}

.fad.fa-4:after, .fa-duotone.fa-4:after {
  content: "44";
}

.fad.fa-5:after, .fa-duotone.fa-5:after {
  content: "55";
}

.fad.fa-6:after, .fa-duotone.fa-6:after {
  content: "66";
}

.fad.fa-7:after, .fa-duotone.fa-7:after {
  content: "77";
}

.fad.fa-8:after, .fa-duotone.fa-8:after {
  content: "88";
}

.fad.fa-9:after, .fa-duotone.fa-9:after {
  content: "99";
}

.fad.fa-fill-drip:after, .fa-duotone.fa-fill-drip:after {
  content: "";
}

.fad.fa-arrows-to-circle:after, .fa-duotone.fa-arrows-to-circle:after {
  content: "";
}

.fad.fa-circle-chevron-right:after, .fa-duotone.fa-circle-chevron-right:after, .fad.fa-chevron-circle-right:after, .fa-duotone.fa-chevron-circle-right:after {
  content: "";
}

.fad.fa-wagon-covered:after, .fa-duotone.fa-wagon-covered:after {
  content: "";
}

.fad.fa-line-height:after, .fa-duotone.fa-line-height:after {
  content: "";
}

.fad.fa-bagel:after, .fa-duotone.fa-bagel:after {
  content: "";
}

.fad.fa-transporter-7:after, .fa-duotone.fa-transporter-7:after {
  content: "";
}

.fad.fa-at:after, .fa-duotone.fa-at:after {
  content: "@@";
}

.fad.fa-rectangles-mixed:after, .fa-duotone.fa-rectangles-mixed:after {
  content: "";
}

.fad.fa-phone-arrow-up-right:after, .fa-duotone.fa-phone-arrow-up-right:after, .fad.fa-phone-arrow-up:after, .fa-duotone.fa-phone-arrow-up:after, .fad.fa-phone-outgoing:after, .fa-duotone.fa-phone-outgoing:after {
  content: "";
}

.fad.fa-trash-can:after, .fa-duotone.fa-trash-can:after, .fad.fa-trash-alt:after, .fa-duotone.fa-trash-alt:after {
  content: "";
}

.fad.fa-circle-l:after, .fa-duotone.fa-circle-l:after {
  content: "";
}

.fad.fa-head-side-goggles:after, .fa-duotone.fa-head-side-goggles:after, .fad.fa-head-vr:after, .fa-duotone.fa-head-vr:after {
  content: "";
}

.fad.fa-text-height:after, .fa-duotone.fa-text-height:after {
  content: "";
}

.fad.fa-user-xmark:after, .fa-duotone.fa-user-xmark:after, .fad.fa-user-times:after, .fa-duotone.fa-user-times:after {
  content: "";
}

.fad.fa-face-hand-yawn:after, .fa-duotone.fa-face-hand-yawn:after {
  content: "";
}

.fad.fa-gauge-simple-min:after, .fa-duotone.fa-gauge-simple-min:after, .fad.fa-tachometer-slowest:after, .fa-duotone.fa-tachometer-slowest:after {
  content: "";
}

.fad.fa-stethoscope:after, .fa-duotone.fa-stethoscope:after {
  content: "";
}

.fad.fa-coffin:after, .fa-duotone.fa-coffin:after {
  content: "";
}

.fad.fa-message:after, .fa-duotone.fa-message:after, .fad.fa-comment-alt:after, .fa-duotone.fa-comment-alt:after {
  content: "";
}

.fad.fa-salad:after, .fa-duotone.fa-salad:after, .fad.fa-bowl-salad:after, .fa-duotone.fa-bowl-salad:after {
  content: "";
}

.fad.fa-info:after, .fa-duotone.fa-info:after {
  content: "";
}

.fad.fa-robot-astromech:after, .fa-duotone.fa-robot-astromech:after {
  content: "";
}

.fad.fa-ring-diamond:after, .fa-duotone.fa-ring-diamond:after {
  content: "";
}

.fad.fa-fondue-pot:after, .fa-duotone.fa-fondue-pot:after {
  content: "";
}

.fad.fa-theta:after, .fa-duotone.fa-theta:after {
  content: "";
}

.fad.fa-face-hand-peeking:after, .fa-duotone.fa-face-hand-peeking:after {
  content: "";
}

.fad.fa-square-user:after, .fa-duotone.fa-square-user:after {
  content: "";
}

.fad.fa-down-left-and-up-right-to-center:after, .fa-duotone.fa-down-left-and-up-right-to-center:after, .fad.fa-compress-alt:after, .fa-duotone.fa-compress-alt:after {
  content: "";
}

.fad.fa-explosion:after, .fa-duotone.fa-explosion:after {
  content: "";
}

.fad.fa-file-lines:after, .fa-duotone.fa-file-lines:after, .fad.fa-file-alt:after, .fa-duotone.fa-file-alt:after, .fad.fa-file-text:after, .fa-duotone.fa-file-text:after {
  content: "";
}

.fad.fa-wave-square:after, .fa-duotone.fa-wave-square:after {
  content: "";
}

.fad.fa-ring:after, .fa-duotone.fa-ring:after {
  content: "";
}

.fad.fa-building-un:after, .fa-duotone.fa-building-un:after {
  content: "";
}

.fad.fa-dice-three:after, .fa-duotone.fa-dice-three:after {
  content: "";
}

.fad.fa-tire-pressure-warning:after, .fa-duotone.fa-tire-pressure-warning:after {
  content: "";
}

.fad.fa-wifi-fair:after, .fa-duotone.fa-wifi-fair:after, .fad.fa-wifi-2:after, .fa-duotone.fa-wifi-2:after {
  content: "";
}

.fad.fa-calendar-days:after, .fa-duotone.fa-calendar-days:after, .fad.fa-calendar-alt:after, .fa-duotone.fa-calendar-alt:after {
  content: "";
}

.fad.fa-mp3-player:after, .fa-duotone.fa-mp3-player:after {
  content: "";
}

.fad.fa-anchor-circle-check:after, .fa-duotone.fa-anchor-circle-check:after {
  content: "";
}

.fad.fa-tally-4:after, .fa-duotone.fa-tally-4:after {
  content: "";
}

.fad.fa-rectangle-history:after, .fa-duotone.fa-rectangle-history:after {
  content: "";
}

.fad.fa-building-circle-arrow-right:after, .fa-duotone.fa-building-circle-arrow-right:after {
  content: "";
}

.fad.fa-volleyball:after, .fa-duotone.fa-volleyball:after, .fad.fa-volleyball-ball:after, .fa-duotone.fa-volleyball-ball:after {
  content: "";
}

.fad.fa-sun-haze:after, .fa-duotone.fa-sun-haze:after {
  content: "";
}

.fad.fa-text-size:after, .fa-duotone.fa-text-size:after {
  content: "";
}

.fad.fa-ufo:after, .fa-duotone.fa-ufo:after {
  content: "";
}

.fad.fa-fork:after, .fa-duotone.fa-fork:after, .fad.fa-utensil-fork:after, .fa-duotone.fa-utensil-fork:after {
  content: "";
}

.fad.fa-arrows-up-to-line:after, .fa-duotone.fa-arrows-up-to-line:after {
  content: "";
}

.fad.fa-mobile-signal:after, .fa-duotone.fa-mobile-signal:after {
  content: "";
}

.fad.fa-barcode-scan:after, .fa-duotone.fa-barcode-scan:after {
  content: "";
}

.fad.fa-sort-down:after, .fa-duotone.fa-sort-down:after, .fad.fa-sort-desc:after, .fa-duotone.fa-sort-desc:after {
  content: "";
}

.fad.fa-folder-arrow-down:after, .fa-duotone.fa-folder-arrow-down:after, .fad.fa-folder-download:after, .fa-duotone.fa-folder-download:after {
  content: "";
}

.fad.fa-circle-minus:after, .fa-duotone.fa-circle-minus:after, .fad.fa-minus-circle:after, .fa-duotone.fa-minus-circle:after {
  content: "";
}

.fad.fa-face-icicles:after, .fa-duotone.fa-face-icicles:after {
  content: "";
}

.fad.fa-shovel:after, .fa-duotone.fa-shovel:after {
  content: "";
}

.fad.fa-door-open:after, .fa-duotone.fa-door-open:after {
  content: "";
}

.fad.fa-films:after, .fa-duotone.fa-films:after {
  content: "";
}

.fad.fa-right-from-bracket:after, .fa-duotone.fa-right-from-bracket:after, .fad.fa-sign-out-alt:after, .fa-duotone.fa-sign-out-alt:after {
  content: "";
}

.fad.fa-face-glasses:after, .fa-duotone.fa-face-glasses:after {
  content: "";
}

.fad.fa-nfc:after, .fa-duotone.fa-nfc:after {
  content: "";
}

.fad.fa-atom:after, .fa-duotone.fa-atom:after {
  content: "";
}

.fad.fa-soap:after, .fa-duotone.fa-soap:after {
  content: "";
}

.fad.fa-icons:after, .fa-duotone.fa-icons:after, .fad.fa-heart-music-camera-bolt:after, .fa-duotone.fa-heart-music-camera-bolt:after {
  content: "";
}

.fad.fa-microphone-lines-slash:after, .fa-duotone.fa-microphone-lines-slash:after, .fad.fa-microphone-alt-slash:after, .fa-duotone.fa-microphone-alt-slash:after {
  content: "";
}

.fad.fa-closed-captioning-slash:after, .fa-duotone.fa-closed-captioning-slash:after {
  content: "";
}

.fad.fa-calculator-simple:after, .fa-duotone.fa-calculator-simple:after, .fad.fa-calculator-alt:after, .fa-duotone.fa-calculator-alt:after {
  content: "";
}

.fad.fa-bridge-circle-check:after, .fa-duotone.fa-bridge-circle-check:after {
  content: "";
}

.fad.fa-sliders-up:after, .fa-duotone.fa-sliders-up:after, .fad.fa-sliders-v:after, .fa-duotone.fa-sliders-v:after {
  content: "";
}

.fad.fa-location-minus:after, .fa-duotone.fa-location-minus:after, .fad.fa-map-marker-minus:after, .fa-duotone.fa-map-marker-minus:after {
  content: "";
}

.fad.fa-pump-medical:after, .fa-duotone.fa-pump-medical:after {
  content: "";
}

.fad.fa-fingerprint:after, .fa-duotone.fa-fingerprint:after {
  content: "";
}

.fad.fa-ski-boot:after, .fa-duotone.fa-ski-boot:after {
  content: "";
}

.fad.fa-standard-definition:after, .fa-duotone.fa-standard-definition:after, .fad.fa-rectangle-sd:after, .fa-duotone.fa-rectangle-sd:after {
  content: "";
}

.fad.fa-h1:after, .fa-duotone.fa-h1:after {
  content: "";
}

.fad.fa-hand-point-right:after, .fa-duotone.fa-hand-point-right:after {
  content: "";
}

.fad.fa-magnifying-glass-location:after, .fa-duotone.fa-magnifying-glass-location:after, .fad.fa-search-location:after, .fa-duotone.fa-search-location:after {
  content: "";
}

.fad.fa-message-bot:after, .fa-duotone.fa-message-bot:after {
  content: "";
}

.fad.fa-forward-step:after, .fa-duotone.fa-forward-step:after, .fad.fa-step-forward:after, .fa-duotone.fa-step-forward:after {
  content: "";
}

.fad.fa-face-smile-beam:after, .fa-duotone.fa-face-smile-beam:after, .fad.fa-smile-beam:after, .fa-duotone.fa-smile-beam:after {
  content: "";
}

.fad.fa-light-ceiling:after, .fa-duotone.fa-light-ceiling:after {
  content: "";
}

.fad.fa-message-exclamation:after, .fa-duotone.fa-message-exclamation:after, .fad.fa-comment-alt-exclamation:after, .fa-duotone.fa-comment-alt-exclamation:after {
  content: "";
}

.fad.fa-bowl-scoop:after, .fa-duotone.fa-bowl-scoop:after, .fad.fa-bowl-shaved-ice:after, .fa-duotone.fa-bowl-shaved-ice:after {
  content: "";
}

.fad.fa-square-x:after, .fa-duotone.fa-square-x:after {
  content: "";
}

.fad.fa-building-memo:after, .fa-duotone.fa-building-memo:after {
  content: "";
}

.fad.fa-utility-pole-double:after, .fa-duotone.fa-utility-pole-double:after {
  content: "";
}

.fad.fa-flag-checkered:after, .fa-duotone.fa-flag-checkered:after {
  content: "";
}

.fad.fa-chevrons-up:after, .fa-duotone.fa-chevrons-up:after, .fad.fa-chevron-double-up:after, .fa-duotone.fa-chevron-double-up:after {
  content: "";
}

.fad.fa-football:after, .fa-duotone.fa-football:after, .fad.fa-football-ball:after, .fa-duotone.fa-football-ball:after {
  content: "";
}

.fad.fa-user-vneck:after, .fa-duotone.fa-user-vneck:after {
  content: "";
}

.fad.fa-school-circle-exclamation:after, .fa-duotone.fa-school-circle-exclamation:after {
  content: "";
}

.fad.fa-crop:after, .fa-duotone.fa-crop:after {
  content: "";
}

.fad.fa-angles-down:after, .fa-duotone.fa-angles-down:after, .fad.fa-angle-double-down:after, .fa-duotone.fa-angle-double-down:after {
  content: "";
}

.fad.fa-users-rectangle:after, .fa-duotone.fa-users-rectangle:after {
  content: "";
}

.fad.fa-people-roof:after, .fa-duotone.fa-people-roof:after {
  content: "";
}

.fad.fa-square-arrow-right:after, .fa-duotone.fa-square-arrow-right:after, .fad.fa-arrow-square-right:after, .fa-duotone.fa-arrow-square-right:after {
  content: "";
}

.fad.fa-location-plus:after, .fa-duotone.fa-location-plus:after, .fad.fa-map-marker-plus:after, .fa-duotone.fa-map-marker-plus:after {
  content: "";
}

.fad.fa-lightbulb-exclamation-on:after, .fa-duotone.fa-lightbulb-exclamation-on:after {
  content: "";
}

.fad.fa-people-line:after, .fa-duotone.fa-people-line:after {
  content: "";
}

.fad.fa-beer-mug-empty:after, .fa-duotone.fa-beer-mug-empty:after, .fad.fa-beer:after, .fa-duotone.fa-beer:after {
  content: "";
}

.fad.fa-crate-empty:after, .fa-duotone.fa-crate-empty:after {
  content: "";
}

.fad.fa-diagram-predecessor:after, .fa-duotone.fa-diagram-predecessor:after {
  content: "";
}

.fad.fa-transporter:after, .fa-duotone.fa-transporter:after {
  content: "";
}

.fad.fa-calendar-circle-user:after, .fa-duotone.fa-calendar-circle-user:after {
  content: "";
}

.fad.fa-arrow-up-long:after, .fa-duotone.fa-arrow-up-long:after, .fad.fa-long-arrow-up:after, .fa-duotone.fa-long-arrow-up:after {
  content: "";
}

.fad.fa-person-carry-box:after, .fa-duotone.fa-person-carry-box:after, .fad.fa-person-carry:after, .fa-duotone.fa-person-carry:after {
  content: "";
}

.fad.fa-fire-flame-simple:after, .fa-duotone.fa-fire-flame-simple:after, .fad.fa-burn:after, .fa-duotone.fa-burn:after {
  content: "";
}

.fad.fa-person:after, .fa-duotone.fa-person:after, .fad.fa-male:after, .fa-duotone.fa-male:after {
  content: "";
}

.fad.fa-laptop:after, .fa-duotone.fa-laptop:after {
  content: "";
}

.fad.fa-file-csv:after, .fa-duotone.fa-file-csv:after {
  content: "";
}

.fad.fa-menorah:after, .fa-duotone.fa-menorah:after {
  content: "";
}

.fad.fa-union:after, .fa-duotone.fa-union:after {
  content: "";
}

.fad.fa-chevrons-left:after, .fa-duotone.fa-chevrons-left:after, .fad.fa-chevron-double-left:after, .fa-duotone.fa-chevron-double-left:after {
  content: "";
}

.fad.fa-circle-heart:after, .fa-duotone.fa-circle-heart:after, .fad.fa-heart-circle:after, .fa-duotone.fa-heart-circle:after {
  content: "";
}

.fad.fa-truck-plane:after, .fa-duotone.fa-truck-plane:after {
  content: "";
}

.fad.fa-record-vinyl:after, .fa-duotone.fa-record-vinyl:after {
  content: "";
}

.fad.fa-bring-forward:after, .fa-duotone.fa-bring-forward:after {
  content: "";
}

.fad.fa-square-p:after, .fa-duotone.fa-square-p:after {
  content: "";
}

.fad.fa-face-grin-stars:after, .fa-duotone.fa-face-grin-stars:after, .fad.fa-grin-stars:after, .fa-duotone.fa-grin-stars:after {
  content: "";
}

.fad.fa-sigma:after, .fa-duotone.fa-sigma:after {
  content: "";
}

.fad.fa-camera-movie:after, .fa-duotone.fa-camera-movie:after {
  content: "";
}

.fad.fa-bong:after, .fa-duotone.fa-bong:after {
  content: "";
}

.fad.fa-clarinet:after, .fa-duotone.fa-clarinet:after {
  content: "";
}

.fad.fa-truck-flatbed:after, .fa-duotone.fa-truck-flatbed:after {
  content: "";
}

.fad.fa-spaghetti-monster-flying:after, .fa-duotone.fa-spaghetti-monster-flying:after, .fad.fa-pastafarianism:after, .fa-duotone.fa-pastafarianism:after {
  content: "";
}

.fad.fa-arrow-down-up-across-line:after, .fa-duotone.fa-arrow-down-up-across-line:after {
  content: "";
}

.fad.fa-arrows-rotate-reverse:after, .fa-duotone.fa-arrows-rotate-reverse:after {
  content: "";
}

.fad.fa-leaf-heart:after, .fa-duotone.fa-leaf-heart:after {
  content: "";
}

.fad.fa-house-building:after, .fa-duotone.fa-house-building:after {
  content: "";
}

.fad.fa-cheese-swiss:after, .fa-duotone.fa-cheese-swiss:after {
  content: "";
}

.fad.fa-spoon:after, .fa-duotone.fa-spoon:after, .fad.fa-utensil-spoon:after, .fa-duotone.fa-utensil-spoon:after {
  content: "";
}

.fad.fa-jar-wheat:after, .fa-duotone.fa-jar-wheat:after {
  content: "";
}

.fad.fa-envelopes-bulk:after, .fa-duotone.fa-envelopes-bulk:after, .fad.fa-mail-bulk:after, .fa-duotone.fa-mail-bulk:after {
  content: "";
}

.fad.fa-file-circle-exclamation:after, .fa-duotone.fa-file-circle-exclamation:after {
  content: "";
}

.fad.fa-bow-arrow:after, .fa-duotone.fa-bow-arrow:after {
  content: "";
}

.fad.fa-cart-xmark:after, .fa-duotone.fa-cart-xmark:after {
  content: "";
}

.fad.fa-hexagon-xmark:after, .fa-duotone.fa-hexagon-xmark:after, .fad.fa-times-hexagon:after, .fa-duotone.fa-times-hexagon:after, .fad.fa-xmark-hexagon:after, .fa-duotone.fa-xmark-hexagon:after {
  content: "";
}

.fad.fa-circle-h:after, .fa-duotone.fa-circle-h:after, .fad.fa-hospital-symbol:after, .fa-duotone.fa-hospital-symbol:after {
  content: "";
}

.fad.fa-merge:after, .fa-duotone.fa-merge:after {
  content: "";
}

.fad.fa-pager:after, .fa-duotone.fa-pager:after {
  content: "";
}

.fad.fa-cart-minus:after, .fa-duotone.fa-cart-minus:after {
  content: "";
}

.fad.fa-address-book:after, .fa-duotone.fa-address-book:after, .fad.fa-contact-book:after, .fa-duotone.fa-contact-book:after {
  content: "";
}

.fad.fa-pan-frying:after, .fa-duotone.fa-pan-frying:after {
  content: "";
}

.fad.fa-grid:after, .fa-duotone.fa-grid:after, .fad.fa-grid-3:after, .fa-duotone.fa-grid-3:after {
  content: "";
}

.fad.fa-football-helmet:after, .fa-duotone.fa-football-helmet:after {
  content: "";
}

.fad.fa-hand-love:after, .fa-duotone.fa-hand-love:after {
  content: "";
}

.fad.fa-trees:after, .fa-duotone.fa-trees:after {
  content: "";
}

.fad.fa-strikethrough:after, .fa-duotone.fa-strikethrough:after {
  content: "";
}

.fad.fa-page:after, .fa-duotone.fa-page:after {
  content: "";
}

.fad.fa-k:after, .fa-duotone.fa-k:after {
  content: "KK";
}

.fad.fa-diagram-previous:after, .fa-duotone.fa-diagram-previous:after {
  content: "";
}

.fad.fa-gauge-min:after, .fa-duotone.fa-gauge-min:after, .fad.fa-tachometer-alt-slowest:after, .fa-duotone.fa-tachometer-alt-slowest:after {
  content: "";
}

.fad.fa-folder-grid:after, .fa-duotone.fa-folder-grid:after {
  content: "";
}

.fad.fa-eggplant:after, .fa-duotone.fa-eggplant:after {
  content: "";
}

.fad.fa-excavator:after, .fa-duotone.fa-excavator:after {
  content: "";
}

.fad.fa-ram:after, .fa-duotone.fa-ram:after {
  content: "";
}

.fad.fa-landmark-flag:after, .fa-duotone.fa-landmark-flag:after {
  content: "";
}

.fad.fa-lips:after, .fa-duotone.fa-lips:after {
  content: "";
}

.fad.fa-pencil:after, .fa-duotone.fa-pencil:after, .fad.fa-pencil-alt:after, .fa-duotone.fa-pencil-alt:after {
  content: "";
}

.fad.fa-backward:after, .fa-duotone.fa-backward:after {
  content: "";
}

.fad.fa-caret-right:after, .fa-duotone.fa-caret-right:after {
  content: "";
}

.fad.fa-comments:after, .fa-duotone.fa-comments:after {
  content: "";
}

.fad.fa-paste:after, .fa-duotone.fa-paste:after, .fad.fa-file-clipboard:after, .fa-duotone.fa-file-clipboard:after {
  content: "";
}

.fad.fa-desktop-arrow-down:after, .fa-duotone.fa-desktop-arrow-down:after {
  content: "";
}

.fad.fa-code-pull-request:after, .fa-duotone.fa-code-pull-request:after {
  content: "";
}

.fad.fa-pumpkin:after, .fa-duotone.fa-pumpkin:after {
  content: "";
}

.fad.fa-clipboard-list:after, .fa-duotone.fa-clipboard-list:after {
  content: "";
}

.fad.fa-pen-field:after, .fa-duotone.fa-pen-field:after {
  content: "";
}

.fad.fa-blueberries:after, .fa-duotone.fa-blueberries:after {
  content: "";
}

.fad.fa-truck-ramp-box:after, .fa-duotone.fa-truck-ramp-box:after, .fad.fa-truck-loading:after, .fa-duotone.fa-truck-loading:after {
  content: "";
}

.fad.fa-note:after, .fa-duotone.fa-note:after {
  content: "";
}

.fad.fa-arrow-down-to-square:after, .fa-duotone.fa-arrow-down-to-square:after {
  content: "";
}

.fad.fa-user-check:after, .fa-duotone.fa-user-check:after {
  content: "";
}

.fad.fa-cloud-xmark:after, .fa-duotone.fa-cloud-xmark:after {
  content: "";
}

.fad.fa-vial-virus:after, .fa-duotone.fa-vial-virus:after {
  content: "";
}

.fad.fa-book-blank:after, .fa-duotone.fa-book-blank:after, .fad.fa-book-alt:after, .fa-duotone.fa-book-alt:after {
  content: "";
}

.fad.fa-golf-flag-hole:after, .fa-duotone.fa-golf-flag-hole:after {
  content: "";
}

.fad.fa-message-arrow-down:after, .fa-duotone.fa-message-arrow-down:after, .fad.fa-comment-alt-arrow-down:after, .fa-duotone.fa-comment-alt-arrow-down:after {
  content: "";
}

.fad.fa-face-unamused:after, .fa-duotone.fa-face-unamused:after {
  content: "";
}

.fad.fa-sheet-plastic:after, .fa-duotone.fa-sheet-plastic:after {
  content: "";
}

.fad.fa-circle-9:after, .fa-duotone.fa-circle-9:after {
  content: "";
}

.fad.fa-blog:after, .fa-duotone.fa-blog:after {
  content: "";
}

.fad.fa-user-ninja:after, .fa-duotone.fa-user-ninja:after {
  content: "";
}

.fad.fa-pencil-slash:after, .fa-duotone.fa-pencil-slash:after {
  content: "";
}

.fad.fa-bowling-pins:after, .fa-duotone.fa-bowling-pins:after {
  content: "";
}

.fad.fa-person-arrow-up-from-line:after, .fa-duotone.fa-person-arrow-up-from-line:after {
  content: "";
}

.fad.fa-down-right:after, .fa-duotone.fa-down-right:after {
  content: "";
}

.fad.fa-scroll-torah:after, .fa-duotone.fa-scroll-torah:after, .fad.fa-torah:after, .fa-duotone.fa-torah:after {
  content: "";
}

.fad.fa-webhook:after, .fa-duotone.fa-webhook:after {
  content: "";
}

.fad.fa-blinds-open:after, .fa-duotone.fa-blinds-open:after {
  content: "";
}

.fad.fa-fence:after, .fa-duotone.fa-fence:after {
  content: "";
}

.fad.fa-up:after, .fa-duotone.fa-up:after, .fad.fa-arrow-alt-up:after, .fa-duotone.fa-arrow-alt-up:after {
  content: "";
}

.fad.fa-broom-ball:after, .fa-duotone.fa-broom-ball:after, .fad.fa-quidditch:after, .fa-duotone.fa-quidditch:after, .fad.fa-quidditch-broom-ball:after, .fa-duotone.fa-quidditch-broom-ball:after {
  content: "";
}

.fad.fa-drumstick:after, .fa-duotone.fa-drumstick:after {
  content: "";
}

.fad.fa-square-v:after, .fa-duotone.fa-square-v:after {
  content: "";
}

.fad.fa-face-awesome:after, .fa-duotone.fa-face-awesome:after, .fad.fa-gave-dandy:after, .fa-duotone.fa-gave-dandy:after {
  content: "";
}

.fad.fa-dial-off:after, .fa-duotone.fa-dial-off:after {
  content: "";
}

.fad.fa-toggle-off:after, .fa-duotone.fa-toggle-off:after {
  content: "";
}

.fad.fa-face-smile-horns:after, .fa-duotone.fa-face-smile-horns:after {
  content: "";
}

.fad.fa-box-archive:after, .fa-duotone.fa-box-archive:after, .fad.fa-archive:after, .fa-duotone.fa-archive:after {
  content: "";
}

.fad.fa-grapes:after, .fa-duotone.fa-grapes:after {
  content: "";
}

.fad.fa-person-drowning:after, .fa-duotone.fa-person-drowning:after {
  content: "";
}

.fad.fa-dial-max:after, .fa-duotone.fa-dial-max:after {
  content: "";
}

.fad.fa-circle-m:after, .fa-duotone.fa-circle-m:after {
  content: "";
}

.fad.fa-calendar-image:after, .fa-duotone.fa-calendar-image:after {
  content: "";
}

.fad.fa-circle-caret-down:after, .fa-duotone.fa-circle-caret-down:after, .fad.fa-caret-circle-down:after, .fa-duotone.fa-caret-circle-down:after {
  content: "";
}

.fad.fa-arrow-down-9-1:after, .fa-duotone.fa-arrow-down-9-1:after, .fad.fa-sort-numeric-desc:after, .fa-duotone.fa-sort-numeric-desc:after, .fad.fa-sort-numeric-down-alt:after, .fa-duotone.fa-sort-numeric-down-alt:after {
  content: "";
}

.fad.fa-face-grin-tongue-squint:after, .fa-duotone.fa-face-grin-tongue-squint:after, .fad.fa-grin-tongue-squint:after, .fa-duotone.fa-grin-tongue-squint:after {
  content: "";
}

.fad.fa-shish-kebab:after, .fa-duotone.fa-shish-kebab:after {
  content: "";
}

.fad.fa-spray-can:after, .fa-duotone.fa-spray-can:after {
  content: "";
}

.fad.fa-alarm-snooze:after, .fa-duotone.fa-alarm-snooze:after {
  content: "";
}

.fad.fa-scarecrow:after, .fa-duotone.fa-scarecrow:after {
  content: "";
}

.fad.fa-truck-monster:after, .fa-duotone.fa-truck-monster:after {
  content: "";
}

.fad.fa-gift-card:after, .fa-duotone.fa-gift-card:after {
  content: "";
}

.fad.fa-w:after, .fa-duotone.fa-w:after {
  content: "WW";
}

.fad.fa-code-pull-request-draft:after, .fa-duotone.fa-code-pull-request-draft:after {
  content: "";
}

.fad.fa-square-b:after, .fa-duotone.fa-square-b:after {
  content: "";
}

.fad.fa-elephant:after, .fa-duotone.fa-elephant:after {
  content: "";
}

.fad.fa-earth-africa:after, .fa-duotone.fa-earth-africa:after, .fad.fa-globe-africa:after, .fa-duotone.fa-globe-africa:after {
  content: "";
}

.fad.fa-rainbow:after, .fa-duotone.fa-rainbow:after {
  content: "";
}

.fad.fa-circle-notch:after, .fa-duotone.fa-circle-notch:after {
  content: "";
}

.fad.fa-tablet-screen-button:after, .fa-duotone.fa-tablet-screen-button:after, .fad.fa-tablet-alt:after, .fa-duotone.fa-tablet-alt:after {
  content: "";
}

.fad.fa-paw:after, .fa-duotone.fa-paw:after {
  content: "";
}

.fad.fa-message-question:after, .fa-duotone.fa-message-question:after {
  content: "";
}

.fad.fa-cloud:after, .fa-duotone.fa-cloud:after {
  content: "";
}

.fad.fa-trowel-bricks:after, .fa-duotone.fa-trowel-bricks:after {
  content: "";
}

.fad.fa-square-3:after, .fa-duotone.fa-square-3:after {
  content: "";
}

.fad.fa-face-flushed:after, .fa-duotone.fa-face-flushed:after, .fad.fa-flushed:after, .fa-duotone.fa-flushed:after {
  content: "";
}

.fad.fa-hospital-user:after, .fa-duotone.fa-hospital-user:after {
  content: "";
}

.fad.fa-microwave:after, .fa-duotone.fa-microwave:after {
  content: "";
}

.fad.fa-chf-sign:after, .fa-duotone.fa-chf-sign:after {
  content: "";
}

.fad.fa-tent-arrow-left-right:after, .fa-duotone.fa-tent-arrow-left-right:after {
  content: "";
}

.fad.fa-cart-circle-arrow-up:after, .fa-duotone.fa-cart-circle-arrow-up:after {
  content: "";
}

.fad.fa-trash-clock:after, .fa-duotone.fa-trash-clock:after {
  content: "";
}

.fad.fa-reflect-both:after, .fa-duotone.fa-reflect-both:after {
  content: "";
}

.fad.fa-gavel:after, .fa-duotone.fa-gavel:after, .fad.fa-legal:after, .fa-duotone.fa-legal:after {
  content: "";
}

.fad.fa-sprinkler-ceiling:after, .fa-duotone.fa-sprinkler-ceiling:after {
  content: "";
}

.fad.fa-browsers:after, .fa-duotone.fa-browsers:after {
  content: "";
}

.fad.fa-trillium:after, .fa-duotone.fa-trillium:after {
  content: "";
}

.fad.fa-table-cells-unlock:after, .fa-duotone.fa-table-cells-unlock:after {
  content: "";
}

.fad.fa-music-slash:after, .fa-duotone.fa-music-slash:after {
  content: "";
}

.fad.fa-truck-ramp:after, .fa-duotone.fa-truck-ramp:after {
  content: "";
}

.fad.fa-binoculars:after, .fa-duotone.fa-binoculars:after {
  content: "";
}

.fad.fa-microphone-slash:after, .fa-duotone.fa-microphone-slash:after {
  content: "";
}

.fad.fa-box-tissue:after, .fa-duotone.fa-box-tissue:after {
  content: "";
}

.fad.fa-circle-c:after, .fa-duotone.fa-circle-c:after {
  content: "";
}

.fad.fa-star-christmas:after, .fa-duotone.fa-star-christmas:after {
  content: "";
}

.fad.fa-chart-bullet:after, .fa-duotone.fa-chart-bullet:after {
  content: "";
}

.fad.fa-motorcycle:after, .fa-duotone.fa-motorcycle:after {
  content: "";
}

.fad.fa-tree-christmas:after, .fa-duotone.fa-tree-christmas:after {
  content: "";
}

.fad.fa-tire-flat:after, .fa-duotone.fa-tire-flat:after {
  content: "";
}

.fad.fa-sunglasses:after, .fa-duotone.fa-sunglasses:after {
  content: "";
}

.fad.fa-badge:after, .fa-duotone.fa-badge:after {
  content: "";
}

.fad.fa-message-pen:after, .fa-duotone.fa-message-pen:after, .fad.fa-comment-alt-edit:after, .fa-duotone.fa-comment-alt-edit:after, .fad.fa-message-edit:after, .fa-duotone.fa-message-edit:after {
  content: "";
}

.fad.fa-bell-concierge:after, .fa-duotone.fa-bell-concierge:after, .fad.fa-concierge-bell:after, .fa-duotone.fa-concierge-bell:after {
  content: "";
}

.fad.fa-pen-ruler:after, .fa-duotone.fa-pen-ruler:after, .fad.fa-pencil-ruler:after, .fa-duotone.fa-pencil-ruler:after {
  content: "";
}

.fad.fa-file-mp3:after, .fa-duotone.fa-file-mp3:after {
  content: "";
}

.fad.fa-arrow-progress:after, .fa-duotone.fa-arrow-progress:after {
  content: "";
}

.fad.fa-chess-rook-piece:after, .fa-duotone.fa-chess-rook-piece:after, .fad.fa-chess-rook-alt:after, .fa-duotone.fa-chess-rook-alt:after {
  content: "";
}

.fad.fa-square-root:after, .fa-duotone.fa-square-root:after {
  content: "";
}

.fad.fa-album-collection-circle-plus:after, .fa-duotone.fa-album-collection-circle-plus:after {
  content: "";
}

.fad.fa-people-arrows:after, .fa-duotone.fa-people-arrows:after, .fad.fa-people-arrows-left-right:after, .fa-duotone.fa-people-arrows-left-right:after {
  content: "";
}

.fad.fa-sign-post:after, .fa-duotone.fa-sign-post:after {
  content: "";
}

.fad.fa-face-angry-horns:after, .fa-duotone.fa-face-angry-horns:after {
  content: "";
}

.fad.fa-mars-and-venus-burst:after, .fa-duotone.fa-mars-and-venus-burst:after {
  content: "";
}

.fad.fa-tombstone:after, .fa-duotone.fa-tombstone:after {
  content: "";
}

.fad.fa-square-caret-right:after, .fa-duotone.fa-square-caret-right:after, .fad.fa-caret-square-right:after, .fa-duotone.fa-caret-square-right:after {
  content: "";
}

.fad.fa-scissors:after, .fa-duotone.fa-scissors:after, .fad.fa-cut:after, .fa-duotone.fa-cut:after {
  content: "";
}

.fad.fa-list-music:after, .fa-duotone.fa-list-music:after {
  content: "";
}

.fad.fa-sun-plant-wilt:after, .fa-duotone.fa-sun-plant-wilt:after {
  content: "";
}

.fad.fa-toilets-portable:after, .fa-duotone.fa-toilets-portable:after {
  content: "";
}

.fad.fa-hockey-puck:after, .fa-duotone.fa-hockey-puck:after {
  content: "";
}

.fad.fa-mustache:after, .fa-duotone.fa-mustache:after {
  content: "";
}

.fad.fa-hyphen:after, .fa-duotone.fa-hyphen:after {
  content: "--";
}

.fad.fa-table:after, .fa-duotone.fa-table:after {
  content: "";
}

.fad.fa-user-chef:after, .fa-duotone.fa-user-chef:after {
  content: "";
}

.fad.fa-message-image:after, .fa-duotone.fa-message-image:after, .fad.fa-comment-alt-image:after, .fa-duotone.fa-comment-alt-image:after {
  content: "";
}

.fad.fa-users-medical:after, .fa-duotone.fa-users-medical:after {
  content: "";
}

.fad.fa-sensor-triangle-exclamation:after, .fa-duotone.fa-sensor-triangle-exclamation:after, .fad.fa-sensor-alert:after, .fa-duotone.fa-sensor-alert:after {
  content: "";
}

.fad.fa-magnifying-glass-arrow-right:after, .fa-duotone.fa-magnifying-glass-arrow-right:after {
  content: "";
}

.fad.fa-tachograph-digital:after, .fa-duotone.fa-tachograph-digital:after, .fad.fa-digital-tachograph:after, .fa-duotone.fa-digital-tachograph:after {
  content: "";
}

.fad.fa-face-mask:after, .fa-duotone.fa-face-mask:after {
  content: "";
}

.fad.fa-pickleball:after, .fa-duotone.fa-pickleball:after {
  content: "";
}

.fad.fa-star-sharp-half:after, .fa-duotone.fa-star-sharp-half:after {
  content: "";
}

.fad.fa-users-slash:after, .fa-duotone.fa-users-slash:after {
  content: "";
}

.fad.fa-clover:after, .fa-duotone.fa-clover:after {
  content: "";
}

.fad.fa-meat:after, .fa-duotone.fa-meat:after {
  content: "";
}

.fad.fa-reply:after, .fa-duotone.fa-reply:after, .fad.fa-mail-reply:after, .fa-duotone.fa-mail-reply:after {
  content: "";
}

.fad.fa-star-and-crescent:after, .fa-duotone.fa-star-and-crescent:after {
  content: "";
}

.fad.fa-empty-set:after, .fa-duotone.fa-empty-set:after {
  content: "";
}

.fad.fa-house-fire:after, .fa-duotone.fa-house-fire:after {
  content: "";
}

.fad.fa-square-minus:after, .fa-duotone.fa-square-minus:after, .fad.fa-minus-square:after, .fa-duotone.fa-minus-square:after {
  content: "";
}

.fad.fa-helicopter:after, .fa-duotone.fa-helicopter:after {
  content: "";
}

.fad.fa-bird:after, .fa-duotone.fa-bird:after {
  content: "";
}

.fad.fa-compass:after, .fa-duotone.fa-compass:after {
  content: "";
}

.fad.fa-square-caret-down:after, .fa-duotone.fa-square-caret-down:after, .fad.fa-caret-square-down:after, .fa-duotone.fa-caret-square-down:after {
  content: "";
}

.fad.fa-heart-half-stroke:after, .fa-duotone.fa-heart-half-stroke:after, .fad.fa-heart-half-alt:after, .fa-duotone.fa-heart-half-alt:after {
  content: "";
}

.fad.fa-file-circle-question:after, .fa-duotone.fa-file-circle-question:after {
  content: "";
}

.fad.fa-truck-utensils:after, .fa-duotone.fa-truck-utensils:after {
  content: "";
}

.fad.fa-laptop-code:after, .fa-duotone.fa-laptop-code:after {
  content: "";
}

.fad.fa-joystick:after, .fa-duotone.fa-joystick:after {
  content: "";
}

.fad.fa-grill-fire:after, .fa-duotone.fa-grill-fire:after {
  content: "";
}

.fad.fa-rectangle-vertical-history:after, .fa-duotone.fa-rectangle-vertical-history:after {
  content: "";
}

.fad.fa-swatchbook:after, .fa-duotone.fa-swatchbook:after {
  content: "";
}

.fad.fa-prescription-bottle:after, .fa-duotone.fa-prescription-bottle:after {
  content: "";
}

.fad.fa-bars:after, .fa-duotone.fa-bars:after, .fad.fa-navicon:after, .fa-duotone.fa-navicon:after {
  content: "";
}

.fad.fa-keyboard-left:after, .fa-duotone.fa-keyboard-left:after {
  content: "";
}

.fad.fa-people-group:after, .fa-duotone.fa-people-group:after {
  content: "";
}

.fad.fa-hourglass-end:after, .fa-duotone.fa-hourglass-end:after, .fad.fa-hourglass-3:after, .fa-duotone.fa-hourglass-3:after {
  content: "";
}

.fad.fa-heart-crack:after, .fa-duotone.fa-heart-crack:after, .fad.fa-heart-broken:after, .fa-duotone.fa-heart-broken:after {
  content: "";
}

.fad.fa-face-beam-hand-over-mouth:after, .fa-duotone.fa-face-beam-hand-over-mouth:after {
  content: "";
}

.fad.fa-droplet-percent:after, .fa-duotone.fa-droplet-percent:after, .fad.fa-humidity:after, .fa-duotone.fa-humidity:after {
  content: "";
}

.fad.fa-square-up-right:after, .fa-duotone.fa-square-up-right:after, .fad.fa-external-link-square-alt:after, .fa-duotone.fa-external-link-square-alt:after {
  content: "";
}

.fad.fa-face-kiss-beam:after, .fa-duotone.fa-face-kiss-beam:after, .fad.fa-kiss-beam:after, .fa-duotone.fa-kiss-beam:after {
  content: "";
}

.fad.fa-corn:after, .fa-duotone.fa-corn:after {
  content: "";
}

.fad.fa-roller-coaster:after, .fa-duotone.fa-roller-coaster:after {
  content: "";
}

.fad.fa-photo-film-music:after, .fa-duotone.fa-photo-film-music:after {
  content: "";
}

.fad.fa-radar:after, .fa-duotone.fa-radar:after {
  content: "";
}

.fad.fa-sickle:after, .fa-duotone.fa-sickle:after {
  content: "";
}

.fad.fa-film:after, .fa-duotone.fa-film:after {
  content: "";
}

.fad.fa-coconut:after, .fa-duotone.fa-coconut:after {
  content: "";
}

.fad.fa-ruler-horizontal:after, .fa-duotone.fa-ruler-horizontal:after {
  content: "";
}

.fad.fa-shield-cross:after, .fa-duotone.fa-shield-cross:after {
  content: "";
}

.fad.fa-cassette-tape:after, .fa-duotone.fa-cassette-tape:after {
  content: "";
}

.fad.fa-square-terminal:after, .fa-duotone.fa-square-terminal:after {
  content: "";
}

.fad.fa-people-robbery:after, .fa-duotone.fa-people-robbery:after {
  content: "";
}

.fad.fa-lightbulb:after, .fa-duotone.fa-lightbulb:after {
  content: "";
}

.fad.fa-caret-left:after, .fa-duotone.fa-caret-left:after {
  content: "";
}

.fad.fa-comment-middle:after, .fa-duotone.fa-comment-middle:after {
  content: "";
}

.fad.fa-trash-can-list:after, .fa-duotone.fa-trash-can-list:after {
  content: "";
}

.fad.fa-block:after, .fa-duotone.fa-block:after {
  content: "";
}

.fad.fa-circle-exclamation:after, .fa-duotone.fa-circle-exclamation:after, .fad.fa-exclamation-circle:after, .fa-duotone.fa-exclamation-circle:after {
  content: "";
}

.fad.fa-school-circle-xmark:after, .fa-duotone.fa-school-circle-xmark:after {
  content: "";
}

.fad.fa-arrow-right-from-bracket:after, .fa-duotone.fa-arrow-right-from-bracket:after, .fad.fa-sign-out:after, .fa-duotone.fa-sign-out:after {
  content: "";
}

.fad.fa-face-frown-slight:after, .fa-duotone.fa-face-frown-slight:after {
  content: "";
}

.fad.fa-circle-chevron-down:after, .fa-duotone.fa-circle-chevron-down:after, .fad.fa-chevron-circle-down:after, .fa-duotone.fa-chevron-circle-down:after {
  content: "";
}

.fad.fa-sidebar-flip:after, .fa-duotone.fa-sidebar-flip:after {
  content: "";
}

.fad.fa-unlock-keyhole:after, .fa-duotone.fa-unlock-keyhole:after, .fad.fa-unlock-alt:after, .fa-duotone.fa-unlock-alt:after {
  content: "";
}

.fad.fa-temperature-list:after, .fa-duotone.fa-temperature-list:after {
  content: "";
}

.fad.fa-cloud-showers-heavy:after, .fa-duotone.fa-cloud-showers-heavy:after {
  content: "";
}

.fad.fa-headphones-simple:after, .fa-duotone.fa-headphones-simple:after, .fad.fa-headphones-alt:after, .fa-duotone.fa-headphones-alt:after {
  content: "";
}

.fad.fa-sitemap:after, .fa-duotone.fa-sitemap:after {
  content: "";
}

.fad.fa-pipe-section:after, .fa-duotone.fa-pipe-section:after {
  content: "";
}

.fad.fa-space-station-moon-construction:after, .fa-duotone.fa-space-station-moon-construction:after, .fad.fa-space-station-moon-alt:after, .fa-duotone.fa-space-station-moon-alt:after {
  content: "";
}

.fad.fa-circle-dollar-to-slot:after, .fa-duotone.fa-circle-dollar-to-slot:after, .fad.fa-donate:after, .fa-duotone.fa-donate:after {
  content: "";
}

.fad.fa-memory:after, .fa-duotone.fa-memory:after {
  content: "";
}

.fad.fa-face-sleeping:after, .fa-duotone.fa-face-sleeping:after {
  content: "";
}

.fad.fa-road-spikes:after, .fa-duotone.fa-road-spikes:after {
  content: "";
}

.fad.fa-fire-burner:after, .fa-duotone.fa-fire-burner:after {
  content: "";
}

.fad.fa-squirrel:after, .fa-duotone.fa-squirrel:after {
  content: "";
}

.fad.fa-arrow-up-to-line:after, .fa-duotone.fa-arrow-up-to-line:after, .fad.fa-arrow-to-top:after, .fa-duotone.fa-arrow-to-top:after {
  content: "";
}

.fad.fa-flag:after, .fa-duotone.fa-flag:after {
  content: "";
}

.fad.fa-face-cowboy-hat:after, .fa-duotone.fa-face-cowboy-hat:after {
  content: "";
}

.fad.fa-hanukiah:after, .fa-duotone.fa-hanukiah:after {
  content: "";
}

.fad.fa-chart-scatter-3d:after, .fa-duotone.fa-chart-scatter-3d:after {
  content: "";
}

.fad.fa-display-chart-up:after, .fa-duotone.fa-display-chart-up:after {
  content: "";
}

.fad.fa-square-code:after, .fa-duotone.fa-square-code:after {
  content: "";
}

.fad.fa-feather:after, .fa-duotone.fa-feather:after {
  content: "";
}

.fad.fa-volume-low:after, .fa-duotone.fa-volume-low:after, .fad.fa-volume-down:after, .fa-duotone.fa-volume-down:after {
  content: "";
}

.fad.fa-xmark-to-slot:after, .fa-duotone.fa-xmark-to-slot:after, .fad.fa-times-to-slot:after, .fa-duotone.fa-times-to-slot:after, .fad.fa-vote-nay:after, .fa-duotone.fa-vote-nay:after {
  content: "";
}

.fad.fa-box-taped:after, .fa-duotone.fa-box-taped:after, .fad.fa-box-alt:after, .fa-duotone.fa-box-alt:after {
  content: "";
}

.fad.fa-comment-slash:after, .fa-duotone.fa-comment-slash:after {
  content: "";
}

.fad.fa-swords:after, .fa-duotone.fa-swords:after {
  content: "";
}

.fad.fa-cloud-sun-rain:after, .fa-duotone.fa-cloud-sun-rain:after {
  content: "";
}

.fad.fa-album:after, .fa-duotone.fa-album:after {
  content: "";
}

.fad.fa-circle-n:after, .fa-duotone.fa-circle-n:after {
  content: "";
}

.fad.fa-compress:after, .fa-duotone.fa-compress:after {
  content: "";
}

.fad.fa-wheat-awn:after, .fa-duotone.fa-wheat-awn:after, .fad.fa-wheat-alt:after, .fa-duotone.fa-wheat-alt:after {
  content: "";
}

.fad.fa-ankh:after, .fa-duotone.fa-ankh:after {
  content: "";
}

.fad.fa-hands-holding-child:after, .fa-duotone.fa-hands-holding-child:after {
  content: "";
}

.fad.fa-asterisk:after, .fa-duotone.fa-asterisk:after {
  content: "**";
}

.fad.fa-key-skeleton-left-right:after, .fa-duotone.fa-key-skeleton-left-right:after {
  content: "";
}

.fad.fa-comment-lines:after, .fa-duotone.fa-comment-lines:after {
  content: "";
}

.fad.fa-luchador-mask:after, .fa-duotone.fa-luchador-mask:after, .fad.fa-luchador:after, .fa-duotone.fa-luchador:after, .fad.fa-mask-luchador:after, .fa-duotone.fa-mask-luchador:after {
  content: "";
}

.fad.fa-square-check:after, .fa-duotone.fa-square-check:after, .fad.fa-check-square:after, .fa-duotone.fa-check-square:after {
  content: "";
}

.fad.fa-shredder:after, .fa-duotone.fa-shredder:after {
  content: "";
}

.fad.fa-book-open-cover:after, .fa-duotone.fa-book-open-cover:after, .fad.fa-book-open-alt:after, .fa-duotone.fa-book-open-alt:after {
  content: "";
}

.fad.fa-sandwich:after, .fa-duotone.fa-sandwich:after {
  content: "";
}

.fad.fa-peseta-sign:after, .fa-duotone.fa-peseta-sign:after {
  content: "";
}

.fad.fa-square-parking-slash:after, .fa-duotone.fa-square-parking-slash:after, .fad.fa-parking-slash:after, .fa-duotone.fa-parking-slash:after {
  content: "";
}

.fad.fa-train-tunnel:after, .fa-duotone.fa-train-tunnel:after {
  content: "";
}

.fad.fa-heading:after, .fa-duotone.fa-heading:after, .fad.fa-header:after, .fa-duotone.fa-header:after {
  content: "";
}

.fad.fa-ghost:after, .fa-duotone.fa-ghost:after {
  content: "";
}

.fad.fa-face-anguished:after, .fa-duotone.fa-face-anguished:after {
  content: "";
}

.fad.fa-hockey-sticks:after, .fa-duotone.fa-hockey-sticks:after {
  content: "";
}

.fad.fa-abacus:after, .fa-duotone.fa-abacus:after {
  content: "";
}

.fad.fa-film-simple:after, .fa-duotone.fa-film-simple:after, .fad.fa-film-alt:after, .fa-duotone.fa-film-alt:after {
  content: "";
}

.fad.fa-list:after, .fa-duotone.fa-list:after, .fad.fa-list-squares:after, .fa-duotone.fa-list-squares:after {
  content: "";
}

.fad.fa-tree-palm:after, .fa-duotone.fa-tree-palm:after {
  content: "";
}

.fad.fa-square-phone-flip:after, .fa-duotone.fa-square-phone-flip:after, .fad.fa-phone-square-alt:after, .fa-duotone.fa-phone-square-alt:after {
  content: "";
}

.fad.fa-user-beard-bolt:after, .fa-duotone.fa-user-beard-bolt:after {
  content: "";
}

.fad.fa-cart-plus:after, .fa-duotone.fa-cart-plus:after {
  content: "";
}

.fad.fa-gamepad:after, .fa-duotone.fa-gamepad:after {
  content: "";
}

.fad.fa-border-center-v:after, .fa-duotone.fa-border-center-v:after {
  content: "";
}

.fad.fa-circle-dot:after, .fa-duotone.fa-circle-dot:after, .fad.fa-dot-circle:after, .fa-duotone.fa-dot-circle:after {
  content: "";
}

.fad.fa-clipboard-medical:after, .fa-duotone.fa-clipboard-medical:after {
  content: "";
}

.fad.fa-face-dizzy:after, .fa-duotone.fa-face-dizzy:after, .fad.fa-dizzy:after, .fa-duotone.fa-dizzy:after {
  content: "";
}

.fad.fa-egg:after, .fa-duotone.fa-egg:after {
  content: "";
}

.fad.fa-up-to-line:after, .fa-duotone.fa-up-to-line:after, .fad.fa-arrow-alt-to-top:after, .fa-duotone.fa-arrow-alt-to-top:after {
  content: "";
}

.fad.fa-house-medical-circle-xmark:after, .fa-duotone.fa-house-medical-circle-xmark:after {
  content: "";
}

.fad.fa-watch-fitness:after, .fa-duotone.fa-watch-fitness:after {
  content: "";
}

.fad.fa-clock-nine-thirty:after, .fa-duotone.fa-clock-nine-thirty:after {
  content: "";
}

.fad.fa-campground:after, .fa-duotone.fa-campground:after {
  content: "";
}

.fad.fa-folder-plus:after, .fa-duotone.fa-folder-plus:after {
  content: "";
}

.fad.fa-jug:after, .fa-duotone.fa-jug:after {
  content: "";
}

.fad.fa-futbol:after, .fa-duotone.fa-futbol:after, .fad.fa-futbol-ball:after, .fa-duotone.fa-futbol-ball:after, .fad.fa-soccer-ball:after, .fa-duotone.fa-soccer-ball:after {
  content: "";
}

.fad.fa-snow-blowing:after, .fa-duotone.fa-snow-blowing:after {
  content: "";
}

.fad.fa-paintbrush:after, .fa-duotone.fa-paintbrush:after, .fad.fa-paint-brush:after, .fa-duotone.fa-paint-brush:after {
  content: "";
}

.fad.fa-lock:after, .fa-duotone.fa-lock:after {
  content: "";
}

.fad.fa-arrow-down-from-line:after, .fa-duotone.fa-arrow-down-from-line:after, .fad.fa-arrow-from-top:after, .fa-duotone.fa-arrow-from-top:after {
  content: "";
}

.fad.fa-gas-pump:after, .fa-duotone.fa-gas-pump:after {
  content: "";
}

.fad.fa-signal-bars-slash:after, .fa-duotone.fa-signal-bars-slash:after, .fad.fa-signal-alt-slash:after, .fa-duotone.fa-signal-alt-slash:after {
  content: "";
}

.fad.fa-monkey:after, .fa-duotone.fa-monkey:after {
  content: "";
}

.fad.fa-rectangle-pro:after, .fa-duotone.fa-rectangle-pro:after, .fad.fa-pro:after, .fa-duotone.fa-pro:after {
  content: "";
}

.fad.fa-house-night:after, .fa-duotone.fa-house-night:after {
  content: "";
}

.fad.fa-hot-tub-person:after, .fa-duotone.fa-hot-tub-person:after, .fad.fa-hot-tub:after, .fa-duotone.fa-hot-tub:after {
  content: "";
}

.fad.fa-globe-pointer:after, .fa-duotone.fa-globe-pointer:after {
  content: "";
}

.fad.fa-blanket:after, .fa-duotone.fa-blanket:after {
  content: "";
}

.fad.fa-map-location:after, .fa-duotone.fa-map-location:after, .fad.fa-map-marked:after, .fa-duotone.fa-map-marked:after {
  content: "";
}

.fad.fa-house-flood-water:after, .fa-duotone.fa-house-flood-water:after {
  content: "";
}

.fad.fa-comments-question-check:after, .fa-duotone.fa-comments-question-check:after {
  content: "";
}

.fad.fa-tree:after, .fa-duotone.fa-tree:after {
  content: "";
}

.fad.fa-arrows-cross:after, .fa-duotone.fa-arrows-cross:after {
  content: "";
}

.fad.fa-backpack:after, .fa-duotone.fa-backpack:after {
  content: "";
}

.fad.fa-square-small:after, .fa-duotone.fa-square-small:after {
  content: "";
}

.fad.fa-folder-arrow-up:after, .fa-duotone.fa-folder-arrow-up:after, .fad.fa-folder-upload:after, .fa-duotone.fa-folder-upload:after {
  content: "";
}

.fad.fa-bridge-lock:after, .fa-duotone.fa-bridge-lock:after {
  content: "";
}

.fad.fa-crosshairs-simple:after, .fa-duotone.fa-crosshairs-simple:after {
  content: "";
}

.fad.fa-sack-dollar:after, .fa-duotone.fa-sack-dollar:after {
  content: "";
}

.fad.fa-pen-to-square:after, .fa-duotone.fa-pen-to-square:after, .fad.fa-edit:after, .fa-duotone.fa-edit:after {
  content: "";
}

.fad.fa-square-sliders:after, .fa-duotone.fa-square-sliders:after, .fad.fa-sliders-h-square:after, .fa-duotone.fa-sliders-h-square:after {
  content: "";
}

.fad.fa-car-side:after, .fa-duotone.fa-car-side:after {
  content: "";
}

.fad.fa-message-middle-top:after, .fa-duotone.fa-message-middle-top:after, .fad.fa-comment-middle-top-alt:after, .fa-duotone.fa-comment-middle-top-alt:after {
  content: "";
}

.fad.fa-lightbulb-on:after, .fa-duotone.fa-lightbulb-on:after {
  content: "";
}

.fad.fa-knife:after, .fa-duotone.fa-knife:after, .fad.fa-utensil-knife:after, .fa-duotone.fa-utensil-knife:after {
  content: "";
}

.fad.fa-share-nodes:after, .fa-duotone.fa-share-nodes:after, .fad.fa-share-alt:after, .fa-duotone.fa-share-alt:after {
  content: "";
}

.fad.fa-display-chart-up-circle-dollar:after, .fa-duotone.fa-display-chart-up-circle-dollar:after {
  content: "";
}

.fad.fa-wave-sine:after, .fa-duotone.fa-wave-sine:after {
  content: "";
}

.fad.fa-heart-circle-minus:after, .fa-duotone.fa-heart-circle-minus:after {
  content: "";
}

.fad.fa-circle-w:after, .fa-duotone.fa-circle-w:after {
  content: "";
}

.fad.fa-circle-calendar:after, .fa-duotone.fa-circle-calendar:after, .fad.fa-calendar-circle:after, .fa-duotone.fa-calendar-circle:after {
  content: "";
}

.fad.fa-hourglass-half:after, .fa-duotone.fa-hourglass-half:after, .fad.fa-hourglass-2:after, .fa-duotone.fa-hourglass-2:after {
  content: "";
}

.fad.fa-microscope:after, .fa-duotone.fa-microscope:after {
  content: "";
}

.fad.fa-sunset:after, .fa-duotone.fa-sunset:after {
  content: "";
}

.fad.fa-sink:after, .fa-duotone.fa-sink:after {
  content: "";
}

.fad.fa-calendar-exclamation:after, .fa-duotone.fa-calendar-exclamation:after {
  content: "";
}

.fad.fa-truck-container-empty:after, .fa-duotone.fa-truck-container-empty:after {
  content: "";
}

.fad.fa-hand-heart:after, .fa-duotone.fa-hand-heart:after {
  content: "";
}

.fad.fa-bag-shopping:after, .fa-duotone.fa-bag-shopping:after, .fad.fa-shopping-bag:after, .fa-duotone.fa-shopping-bag:after {
  content: "";
}

.fad.fa-arrow-down-z-a:after, .fa-duotone.fa-arrow-down-z-a:after, .fad.fa-sort-alpha-desc:after, .fa-duotone.fa-sort-alpha-desc:after, .fad.fa-sort-alpha-down-alt:after, .fa-duotone.fa-sort-alpha-down-alt:after {
  content: "";
}

.fad.fa-mitten:after, .fa-duotone.fa-mitten:after {
  content: "";
}

.fad.fa-reply-clock:after, .fa-duotone.fa-reply-clock:after, .fad.fa-reply-time:after, .fa-duotone.fa-reply-time:after {
  content: "";
}

.fad.fa-person-rays:after, .fa-duotone.fa-person-rays:after {
  content: "";
}

.fad.fa-right:after, .fa-duotone.fa-right:after, .fad.fa-arrow-alt-right:after, .fa-duotone.fa-arrow-alt-right:after {
  content: "";
}

.fad.fa-circle-f:after, .fa-duotone.fa-circle-f:after {
  content: "";
}

.fad.fa-users:after, .fa-duotone.fa-users:after {
  content: "";
}

.fad.fa-face-pleading:after, .fa-duotone.fa-face-pleading:after {
  content: "";
}

.fad.fa-eye-slash:after, .fa-duotone.fa-eye-slash:after {
  content: "";
}

.fad.fa-flask-vial:after, .fa-duotone.fa-flask-vial:after {
  content: "";
}

.fad.fa-police-box:after, .fa-duotone.fa-police-box:after {
  content: "";
}

.fad.fa-cucumber:after, .fa-duotone.fa-cucumber:after {
  content: "";
}

.fad.fa-head-side-brain:after, .fa-duotone.fa-head-side-brain:after {
  content: "";
}

.fad.fa-hand:after, .fa-duotone.fa-hand:after, .fad.fa-hand-paper:after, .fa-duotone.fa-hand-paper:after {
  content: "";
}

.fad.fa-person-biking-mountain:after, .fa-duotone.fa-person-biking-mountain:after, .fad.fa-biking-mountain:after, .fa-duotone.fa-biking-mountain:after {
  content: "";
}

.fad.fa-utensils-slash:after, .fa-duotone.fa-utensils-slash:after {
  content: "";
}

.fad.fa-print-magnifying-glass:after, .fa-duotone.fa-print-magnifying-glass:after, .fad.fa-print-search:after, .fa-duotone.fa-print-search:after {
  content: "";
}

.fad.fa-turn-right:after, .fa-duotone.fa-turn-right:after {
  content: "";
}

.fad.fa-folder-bookmark:after, .fa-duotone.fa-folder-bookmark:after {
  content: "";
}

.fad.fa-arrow-turn-left-down:after, .fa-duotone.fa-arrow-turn-left-down:after {
  content: "";
}

.fad.fa-om:after, .fa-duotone.fa-om:after {
  content: "";
}

.fad.fa-pi:after, .fa-duotone.fa-pi:after {
  content: "";
}

.fad.fa-flask-round-potion:after, .fa-duotone.fa-flask-round-potion:after, .fad.fa-flask-potion:after, .fa-duotone.fa-flask-potion:after {
  content: "";
}

.fad.fa-face-shush:after, .fa-duotone.fa-face-shush:after {
  content: "";
}

.fad.fa-worm:after, .fa-duotone.fa-worm:after {
  content: "";
}

.fad.fa-house-circle-xmark:after, .fa-duotone.fa-house-circle-xmark:after {
  content: "";
}

.fad.fa-plug:after, .fa-duotone.fa-plug:after {
  content: "";
}

.fad.fa-calendar-circle-exclamation:after, .fa-duotone.fa-calendar-circle-exclamation:after {
  content: "";
}

.fad.fa-square-i:after, .fa-duotone.fa-square-i:after {
  content: "";
}

.fad.fa-chevron-up:after, .fa-duotone.fa-chevron-up:after {
  content: "";
}

.fad.fa-face-saluting:after, .fa-duotone.fa-face-saluting:after {
  content: "";
}

.fad.fa-gauge-simple-low:after, .fa-duotone.fa-gauge-simple-low:after, .fad.fa-tachometer-slow:after, .fa-duotone.fa-tachometer-slow:after {
  content: "";
}

.fad.fa-face-persevering:after, .fa-duotone.fa-face-persevering:after {
  content: "";
}

.fad.fa-circle-camera:after, .fa-duotone.fa-circle-camera:after, .fad.fa-camera-circle:after, .fa-duotone.fa-camera-circle:after {
  content: "";
}

.fad.fa-hand-spock:after, .fa-duotone.fa-hand-spock:after {
  content: "";
}

.fad.fa-spider-web:after, .fa-duotone.fa-spider-web:after {
  content: "";
}

.fad.fa-circle-microphone:after, .fa-duotone.fa-circle-microphone:after, .fad.fa-microphone-circle:after, .fa-duotone.fa-microphone-circle:after {
  content: "";
}

.fad.fa-book-arrow-up:after, .fa-duotone.fa-book-arrow-up:after {
  content: "";
}

.fad.fa-popsicle:after, .fa-duotone.fa-popsicle:after {
  content: "";
}

.fad.fa-command:after, .fa-duotone.fa-command:after {
  content: "";
}

.fad.fa-blinds:after, .fa-duotone.fa-blinds:after {
  content: "";
}

.fad.fa-stopwatch:after, .fa-duotone.fa-stopwatch:after {
  content: "";
}

.fad.fa-saxophone:after, .fa-duotone.fa-saxophone:after {
  content: "";
}

.fad.fa-square-2:after, .fa-duotone.fa-square-2:after {
  content: "";
}

.fad.fa-field-hockey-stick-ball:after, .fa-duotone.fa-field-hockey-stick-ball:after, .fad.fa-field-hockey:after, .fa-duotone.fa-field-hockey:after {
  content: "";
}

.fad.fa-arrow-up-square-triangle:after, .fa-duotone.fa-arrow-up-square-triangle:after, .fad.fa-sort-shapes-up-alt:after, .fa-duotone.fa-sort-shapes-up-alt:after {
  content: "";
}

.fad.fa-face-scream:after, .fa-duotone.fa-face-scream:after {
  content: "";
}

.fad.fa-square-m:after, .fa-duotone.fa-square-m:after {
  content: "";
}

.fad.fa-camera-web:after, .fa-duotone.fa-camera-web:after, .fad.fa-webcam:after, .fa-duotone.fa-webcam:after {
  content: "";
}

.fad.fa-comment-arrow-down:after, .fa-duotone.fa-comment-arrow-down:after {
  content: "";
}

.fad.fa-lightbulb-cfl:after, .fa-duotone.fa-lightbulb-cfl:after {
  content: "";
}

.fad.fa-window-frame-open:after, .fa-duotone.fa-window-frame-open:after {
  content: "";
}

.fad.fa-face-kiss:after, .fa-duotone.fa-face-kiss:after, .fad.fa-kiss:after, .fa-duotone.fa-kiss:after {
  content: "";
}

.fad.fa-bridge-circle-xmark:after, .fa-duotone.fa-bridge-circle-xmark:after {
  content: "";
}

.fad.fa-period:after, .fa-duotone.fa-period:after {
  content: "..";
}

.fad.fa-face-grin-tongue:after, .fa-duotone.fa-face-grin-tongue:after, .fad.fa-grin-tongue:after, .fa-duotone.fa-grin-tongue:after {
  content: "";
}

.fad.fa-up-to-dotted-line:after, .fa-duotone.fa-up-to-dotted-line:after {
  content: "";
}

.fad.fa-thought-bubble:after, .fa-duotone.fa-thought-bubble:after {
  content: "";
}

.fad.fa-skeleton-ribs:after, .fa-duotone.fa-skeleton-ribs:after {
  content: "";
}

.fad.fa-raygun:after, .fa-duotone.fa-raygun:after {
  content: "";
}

.fad.fa-flute:after, .fa-duotone.fa-flute:after {
  content: "";
}

.fad.fa-acorn:after, .fa-duotone.fa-acorn:after {
  content: "";
}

.fad.fa-video-arrow-up-right:after, .fa-duotone.fa-video-arrow-up-right:after {
  content: "";
}

.fad.fa-grate-droplet:after, .fa-duotone.fa-grate-droplet:after {
  content: "";
}

.fad.fa-seal-exclamation:after, .fa-duotone.fa-seal-exclamation:after {
  content: "";
}

.fad.fa-chess-bishop:after, .fa-duotone.fa-chess-bishop:after {
  content: "";
}

.fad.fa-message-sms:after, .fa-duotone.fa-message-sms:after {
  content: "";
}

.fad.fa-coffee-beans:after, .fa-duotone.fa-coffee-beans:after {
  content: "";
}

.fad.fa-hat-witch:after, .fa-duotone.fa-hat-witch:after {
  content: "";
}

.fad.fa-face-grin-wink:after, .fa-duotone.fa-face-grin-wink:after, .fad.fa-grin-wink:after, .fa-duotone.fa-grin-wink:after {
  content: "";
}

.fad.fa-clock-three-thirty:after, .fa-duotone.fa-clock-three-thirty:after {
  content: "";
}

.fad.fa-ear-deaf:after, .fa-duotone.fa-ear-deaf:after, .fad.fa-deaf:after, .fa-duotone.fa-deaf:after, .fad.fa-deafness:after, .fa-duotone.fa-deafness:after, .fad.fa-hard-of-hearing:after, .fa-duotone.fa-hard-of-hearing:after {
  content: "";
}

.fad.fa-alarm-clock:after, .fa-duotone.fa-alarm-clock:after {
  content: "";
}

.fad.fa-eclipse:after, .fa-duotone.fa-eclipse:after {
  content: "";
}

.fad.fa-face-relieved:after, .fa-duotone.fa-face-relieved:after {
  content: "";
}

.fad.fa-road-circle-check:after, .fa-duotone.fa-road-circle-check:after {
  content: "";
}

.fad.fa-dice-five:after, .fa-duotone.fa-dice-five:after {
  content: "";
}

.fad.fa-octagon-minus:after, .fa-duotone.fa-octagon-minus:after, .fad.fa-minus-octagon:after, .fa-duotone.fa-minus-octagon:after {
  content: "";
}

.fad.fa-square-rss:after, .fa-duotone.fa-square-rss:after, .fad.fa-rss-square:after, .fa-duotone.fa-rss-square:after {
  content: "";
}

.fad.fa-face-zany:after, .fa-duotone.fa-face-zany:after {
  content: "";
}

.fad.fa-tricycle:after, .fa-duotone.fa-tricycle:after {
  content: "";
}

.fad.fa-land-mine-on:after, .fa-duotone.fa-land-mine-on:after {
  content: "";
}

.fad.fa-square-arrow-up-left:after, .fa-duotone.fa-square-arrow-up-left:after {
  content: "";
}

.fad.fa-i-cursor:after, .fa-duotone.fa-i-cursor:after {
  content: "";
}

.fad.fa-chart-mixed-up-circle-dollar:after, .fa-duotone.fa-chart-mixed-up-circle-dollar:after {
  content: "";
}

.fad.fa-salt-shaker:after, .fa-duotone.fa-salt-shaker:after {
  content: "";
}

.fad.fa-stamp:after, .fa-duotone.fa-stamp:after {
  content: "";
}

.fad.fa-file-plus:after, .fa-duotone.fa-file-plus:after {
  content: "";
}

.fad.fa-draw-square:after, .fa-duotone.fa-draw-square:after {
  content: "";
}

.fad.fa-toilet-paper-under-slash:after, .fa-duotone.fa-toilet-paper-under-slash:after, .fad.fa-toilet-paper-reverse-slash:after, .fa-duotone.fa-toilet-paper-reverse-slash:after {
  content: "";
}

.fad.fa-stairs:after, .fa-duotone.fa-stairs:after {
  content: "";
}

.fad.fa-drone-front:after, .fa-duotone.fa-drone-front:after, .fad.fa-drone-alt:after, .fa-duotone.fa-drone-alt:after {
  content: "";
}

.fad.fa-glass-empty:after, .fa-duotone.fa-glass-empty:after {
  content: "";
}

.fad.fa-dial-high:after, .fa-duotone.fa-dial-high:after {
  content: "";
}

.fad.fa-user-helmet-safety:after, .fa-duotone.fa-user-helmet-safety:after, .fad.fa-user-construction:after, .fa-duotone.fa-user-construction:after, .fad.fa-user-hard-hat:after, .fa-duotone.fa-user-hard-hat:after {
  content: "";
}

.fad.fa-i:after, .fa-duotone.fa-i:after {
  content: "II";
}

.fad.fa-hryvnia-sign:after, .fa-duotone.fa-hryvnia-sign:after, .fad.fa-hryvnia:after, .fa-duotone.fa-hryvnia:after {
  content: "";
}

.fad.fa-arrow-down-left-and-arrow-up-right-to-center:after, .fa-duotone.fa-arrow-down-left-and-arrow-up-right-to-center:after {
  content: "";
}

.fad.fa-pills:after, .fa-duotone.fa-pills:after {
  content: "";
}

.fad.fa-face-grin-wide:after, .fa-duotone.fa-face-grin-wide:after, .fad.fa-grin-alt:after, .fa-duotone.fa-grin-alt:after {
  content: "";
}

.fad.fa-tooth:after, .fa-duotone.fa-tooth:after {
  content: "";
}

.fad.fa-basketball-hoop:after, .fa-duotone.fa-basketball-hoop:after {
  content: "";
}

.fad.fa-objects-align-bottom:after, .fa-duotone.fa-objects-align-bottom:after {
  content: "";
}

.fad.fa-v:after, .fa-duotone.fa-v:after {
  content: "VV";
}

.fad.fa-sparkles:after, .fa-duotone.fa-sparkles:after {
  content: "";
}

.fad.fa-squid:after, .fa-duotone.fa-squid:after {
  content: "";
}

.fad.fa-leafy-green:after, .fa-duotone.fa-leafy-green:after {
  content: "";
}

.fad.fa-circle-arrow-up-right:after, .fa-duotone.fa-circle-arrow-up-right:after {
  content: "";
}

.fad.fa-calendars:after, .fa-duotone.fa-calendars:after {
  content: "";
}

.fad.fa-bangladeshi-taka-sign:after, .fa-duotone.fa-bangladeshi-taka-sign:after {
  content: "";
}

.fad.fa-bicycle:after, .fa-duotone.fa-bicycle:after {
  content: "";
}

.fad.fa-hammer-war:after, .fa-duotone.fa-hammer-war:after {
  content: "";
}

.fad.fa-circle-d:after, .fa-duotone.fa-circle-d:after {
  content: "";
}

.fad.fa-spider-black-widow:after, .fa-duotone.fa-spider-black-widow:after {
  content: "";
}

.fad.fa-staff-snake:after, .fa-duotone.fa-staff-snake:after, .fad.fa-rod-asclepius:after, .fa-duotone.fa-rod-asclepius:after, .fad.fa-rod-snake:after, .fa-duotone.fa-rod-snake:after, .fad.fa-staff-aesculapius:after, .fa-duotone.fa-staff-aesculapius:after {
  content: "";
}

.fad.fa-pear:after, .fa-duotone.fa-pear:after {
  content: "";
}

.fad.fa-head-side-cough-slash:after, .fa-duotone.fa-head-side-cough-slash:after {
  content: "";
}

.fad.fa-file-mov:after, .fa-duotone.fa-file-mov:after {
  content: "";
}

.fad.fa-triangle:after, .fa-duotone.fa-triangle:after {
  content: "";
}

.fad.fa-apartment:after, .fa-duotone.fa-apartment:after {
  content: "";
}

.fad.fa-truck-medical:after, .fa-duotone.fa-truck-medical:after, .fad.fa-ambulance:after, .fa-duotone.fa-ambulance:after {
  content: "";
}

.fad.fa-pepper:after, .fa-duotone.fa-pepper:after {
  content: "";
}

.fad.fa-piano:after, .fa-duotone.fa-piano:after {
  content: "";
}

.fad.fa-gun-squirt:after, .fa-duotone.fa-gun-squirt:after {
  content: "";
}

.fad.fa-wheat-awn-circle-exclamation:after, .fa-duotone.fa-wheat-awn-circle-exclamation:after {
  content: "";
}

.fad.fa-snowman:after, .fa-duotone.fa-snowman:after {
  content: "";
}

.fad.fa-user-alien:after, .fa-duotone.fa-user-alien:after {
  content: "";
}

.fad.fa-shield-check:after, .fa-duotone.fa-shield-check:after {
  content: "";
}

.fad.fa-mortar-pestle:after, .fa-duotone.fa-mortar-pestle:after {
  content: "";
}

.fad.fa-road-barrier:after, .fa-duotone.fa-road-barrier:after {
  content: "";
}

.fad.fa-chart-candlestick:after, .fa-duotone.fa-chart-candlestick:after {
  content: "";
}

.fad.fa-briefcase-blank:after, .fa-duotone.fa-briefcase-blank:after {
  content: "";
}

.fad.fa-school:after, .fa-duotone.fa-school:after {
  content: "";
}

.fad.fa-igloo:after, .fa-duotone.fa-igloo:after {
  content: "";
}

.fad.fa-bracket-round:after, .fa-duotone.fa-bracket-round:after, .fad.fa-parenthesis:after, .fa-duotone.fa-parenthesis:after {
  content: "((";
}

.fad.fa-joint:after, .fa-duotone.fa-joint:after {
  content: "";
}

.fad.fa-horse-saddle:after, .fa-duotone.fa-horse-saddle:after {
  content: "";
}

.fad.fa-mug-marshmallows:after, .fa-duotone.fa-mug-marshmallows:after {
  content: "";
}

.fad.fa-filters:after, .fa-duotone.fa-filters:after {
  content: "";
}

.fad.fa-bell-on:after, .fa-duotone.fa-bell-on:after {
  content: "";
}

.fad.fa-angle-right:after, .fa-duotone.fa-angle-right:after {
  content: "";
}

.fad.fa-dial-med:after, .fa-duotone.fa-dial-med:after {
  content: "";
}

.fad.fa-horse:after, .fa-duotone.fa-horse:after {
  content: "";
}

.fad.fa-q:after, .fa-duotone.fa-q:after {
  content: "QQ";
}

.fad.fa-monitor-waveform:after, .fa-duotone.fa-monitor-waveform:after, .fad.fa-monitor-heart-rate:after, .fa-duotone.fa-monitor-heart-rate:after {
  content: "";
}

.fad.fa-link-simple:after, .fa-duotone.fa-link-simple:after {
  content: "";
}

.fad.fa-whistle:after, .fa-duotone.fa-whistle:after {
  content: "";
}

.fad.fa-g:after, .fa-duotone.fa-g:after {
  content: "GG";
}

.fad.fa-wine-glass-crack:after, .fa-duotone.fa-wine-glass-crack:after, .fad.fa-fragile:after, .fa-duotone.fa-fragile:after {
  content: "";
}

.fad.fa-slot-machine:after, .fa-duotone.fa-slot-machine:after {
  content: "";
}

.fad.fa-notes-medical:after, .fa-duotone.fa-notes-medical:after {
  content: "";
}

.fad.fa-car-wash:after, .fa-duotone.fa-car-wash:after {
  content: "";
}

.fad.fa-escalator:after, .fa-duotone.fa-escalator:after {
  content: "";
}

.fad.fa-comment-image:after, .fa-duotone.fa-comment-image:after {
  content: "";
}

.fad.fa-temperature-half:after, .fa-duotone.fa-temperature-half:after, .fad.fa-temperature-2:after, .fa-duotone.fa-temperature-2:after, .fad.fa-thermometer-2:after, .fa-duotone.fa-thermometer-2:after, .fad.fa-thermometer-half:after, .fa-duotone.fa-thermometer-half:after {
  content: "";
}

.fad.fa-dong-sign:after, .fa-duotone.fa-dong-sign:after {
  content: "";
}

.fad.fa-donut:after, .fa-duotone.fa-donut:after, .fad.fa-doughnut:after, .fa-duotone.fa-doughnut:after {
  content: "";
}

.fad.fa-capsules:after, .fa-duotone.fa-capsules:after {
  content: "";
}

.fad.fa-poo-storm:after, .fa-duotone.fa-poo-storm:after, .fad.fa-poo-bolt:after, .fa-duotone.fa-poo-bolt:after {
  content: "";
}

.fad.fa-tally-1:after, .fa-duotone.fa-tally-1:after {
  content: "";
}

.fad.fa-file-vector:after, .fa-duotone.fa-file-vector:after {
  content: "";
}

.fad.fa-face-frown-open:after, .fa-duotone.fa-face-frown-open:after, .fad.fa-frown-open:after, .fa-duotone.fa-frown-open:after {
  content: "";
}

.fad.fa-square-dashed:after, .fa-duotone.fa-square-dashed:after {
  content: "";
}

.fad.fa-bag-shopping-plus:after, .fa-duotone.fa-bag-shopping-plus:after {
  content: "";
}

.fad.fa-square-j:after, .fa-duotone.fa-square-j:after {
  content: "";
}

.fad.fa-hand-point-up:after, .fa-duotone.fa-hand-point-up:after {
  content: "";
}

.fad.fa-money-bill:after, .fa-duotone.fa-money-bill:after {
  content: "";
}

.fad.fa-arrow-up-big-small:after, .fa-duotone.fa-arrow-up-big-small:after, .fad.fa-sort-size-up:after, .fa-duotone.fa-sort-size-up:after {
  content: "";
}

.fad.fa-barcode-read:after, .fa-duotone.fa-barcode-read:after {
  content: "";
}

.fad.fa-baguette:after, .fa-duotone.fa-baguette:after {
  content: "";
}

.fad.fa-bowl-soft-serve:after, .fa-duotone.fa-bowl-soft-serve:after {
  content: "";
}

.fad.fa-face-holding-back-tears:after, .fa-duotone.fa-face-holding-back-tears:after {
  content: "";
}

.fad.fa-square-up:after, .fa-duotone.fa-square-up:after, .fad.fa-arrow-alt-square-up:after, .fa-duotone.fa-arrow-alt-square-up:after {
  content: "";
}

.fad.fa-train-subway-tunnel:after, .fa-duotone.fa-train-subway-tunnel:after, .fad.fa-subway-tunnel:after, .fa-duotone.fa-subway-tunnel:after {
  content: "";
}

.fad.fa-square-exclamation:after, .fa-duotone.fa-square-exclamation:after, .fad.fa-exclamation-square:after, .fa-duotone.fa-exclamation-square:after {
  content: "";
}

.fad.fa-semicolon:after, .fa-duotone.fa-semicolon:after {
  content: ";;";
}

.fad.fa-bookmark:after, .fa-duotone.fa-bookmark:after {
  content: "";
}

.fad.fa-fan-table:after, .fa-duotone.fa-fan-table:after {
  content: "";
}

.fad.fa-align-justify:after, .fa-duotone.fa-align-justify:after {
  content: "";
}

.fad.fa-battery-low:after, .fa-duotone.fa-battery-low:after, .fad.fa-battery-1:after, .fa-duotone.fa-battery-1:after {
  content: "";
}

.fad.fa-credit-card-front:after, .fa-duotone.fa-credit-card-front:after {
  content: "";
}

.fad.fa-brain-arrow-curved-right:after, .fa-duotone.fa-brain-arrow-curved-right:after, .fad.fa-mind-share:after, .fa-duotone.fa-mind-share:after {
  content: "";
}

.fad.fa-umbrella-beach:after, .fa-duotone.fa-umbrella-beach:after {
  content: "";
}

.fad.fa-helmet-un:after, .fa-duotone.fa-helmet-un:after {
  content: "";
}

.fad.fa-location-smile:after, .fa-duotone.fa-location-smile:after, .fad.fa-map-marker-smile:after, .fa-duotone.fa-map-marker-smile:after {
  content: "";
}

.fad.fa-arrow-left-to-line:after, .fa-duotone.fa-arrow-left-to-line:after, .fad.fa-arrow-to-left:after, .fa-duotone.fa-arrow-to-left:after {
  content: "";
}

.fad.fa-bullseye:after, .fa-duotone.fa-bullseye:after {
  content: "";
}

.fad.fa-sushi:after, .fa-duotone.fa-sushi:after, .fad.fa-nigiri:after, .fa-duotone.fa-nigiri:after {
  content: "";
}

.fad.fa-message-captions:after, .fa-duotone.fa-message-captions:after, .fad.fa-comment-alt-captions:after, .fa-duotone.fa-comment-alt-captions:after {
  content: "";
}

.fad.fa-trash-list:after, .fa-duotone.fa-trash-list:after {
  content: "";
}

.fad.fa-bacon:after, .fa-duotone.fa-bacon:after {
  content: "";
}

.fad.fa-option:after, .fa-duotone.fa-option:after {
  content: "";
}

.fad.fa-raccoon:after, .fa-duotone.fa-raccoon:after {
  content: "";
}

.fad.fa-hand-point-down:after, .fa-duotone.fa-hand-point-down:after {
  content: "";
}

.fad.fa-arrow-up-from-bracket:after, .fa-duotone.fa-arrow-up-from-bracket:after {
  content: "";
}

.fad.fa-head-side-gear:after, .fa-duotone.fa-head-side-gear:after {
  content: "";
}

.fad.fa-trash-plus:after, .fa-duotone.fa-trash-plus:after {
  content: "";
}

.fad.fa-file-cad:after, .fa-duotone.fa-file-cad:after {
  content: "";
}

.fad.fa-objects-align-top:after, .fa-duotone.fa-objects-align-top:after {
  content: "";
}

.fad.fa-folder:after, .fa-duotone.fa-folder:after, .fad.fa-folder-blank:after, .fa-duotone.fa-folder-blank:after {
  content: "";
}

.fad.fa-face-anxious-sweat:after, .fa-duotone.fa-face-anxious-sweat:after {
  content: "";
}

.fad.fa-credit-card-blank:after, .fa-duotone.fa-credit-card-blank:after {
  content: "";
}

.fad.fa-file-waveform:after, .fa-duotone.fa-file-waveform:after, .fad.fa-file-medical-alt:after, .fa-duotone.fa-file-medical-alt:after {
  content: "";
}

.fad.fa-microchip-ai:after, .fa-duotone.fa-microchip-ai:after {
  content: "";
}

.fad.fa-mug:after, .fa-duotone.fa-mug:after {
  content: "";
}

.fad.fa-plane-up-slash:after, .fa-duotone.fa-plane-up-slash:after {
  content: "";
}

.fad.fa-radiation:after, .fa-duotone.fa-radiation:after {
  content: "";
}

.fad.fa-pen-circle:after, .fa-duotone.fa-pen-circle:after {
  content: "";
}

.fad.fa-bag-seedling:after, .fa-duotone.fa-bag-seedling:after {
  content: "";
}

.fad.fa-chart-simple:after, .fa-duotone.fa-chart-simple:after {
  content: "";
}

.fad.fa-crutches:after, .fa-duotone.fa-crutches:after {
  content: "";
}

.fad.fa-circle-parking:after, .fa-duotone.fa-circle-parking:after, .fad.fa-parking-circle:after, .fa-duotone.fa-parking-circle:after {
  content: "";
}

.fad.fa-mars-stroke:after, .fa-duotone.fa-mars-stroke:after {
  content: "";
}

.fad.fa-leaf-oak:after, .fa-duotone.fa-leaf-oak:after {
  content: "";
}

.fad.fa-square-bolt:after, .fa-duotone.fa-square-bolt:after {
  content: "";
}

.fad.fa-vial:after, .fa-duotone.fa-vial:after {
  content: "";
}

.fad.fa-gauge:after, .fa-duotone.fa-gauge:after, .fad.fa-dashboard:after, .fa-duotone.fa-dashboard:after, .fad.fa-gauge-med:after, .fa-duotone.fa-gauge-med:after, .fad.fa-tachometer-alt-average:after, .fa-duotone.fa-tachometer-alt-average:after {
  content: "";
}

.fad.fa-wand-magic-sparkles:after, .fa-duotone.fa-wand-magic-sparkles:after, .fad.fa-magic-wand-sparkles:after, .fa-duotone.fa-magic-wand-sparkles:after {
  content: "";
}

.fad.fa-lambda:after, .fa-duotone.fa-lambda:after {
  content: "";
}

.fad.fa-e:after, .fa-duotone.fa-e:after {
  content: "EE";
}

.fad.fa-pizza:after, .fa-duotone.fa-pizza:after {
  content: "";
}

.fad.fa-bowl-chopsticks-noodles:after, .fa-duotone.fa-bowl-chopsticks-noodles:after {
  content: "";
}

.fad.fa-h3:after, .fa-duotone.fa-h3:after {
  content: "";
}

.fad.fa-pen-clip:after, .fa-duotone.fa-pen-clip:after, .fad.fa-pen-alt:after, .fa-duotone.fa-pen-alt:after {
  content: "";
}

.fad.fa-bridge-circle-exclamation:after, .fa-duotone.fa-bridge-circle-exclamation:after {
  content: "";
}

.fad.fa-badge-percent:after, .fa-duotone.fa-badge-percent:after {
  content: "";
}

.fad.fa-rotate-reverse:after, .fa-duotone.fa-rotate-reverse:after {
  content: "";
}

.fad.fa-user:after, .fa-duotone.fa-user:after {
  content: "";
}

.fad.fa-sensor:after, .fa-duotone.fa-sensor:after {
  content: "";
}

.fad.fa-comma:after, .fa-duotone.fa-comma:after {
  content: ",,";
}

.fad.fa-school-circle-check:after, .fa-duotone.fa-school-circle-check:after {
  content: "";
}

.fad.fa-toilet-paper-under:after, .fa-duotone.fa-toilet-paper-under:after, .fad.fa-toilet-paper-reverse:after, .fa-duotone.fa-toilet-paper-reverse:after {
  content: "";
}

.fad.fa-light-emergency:after, .fa-duotone.fa-light-emergency:after {
  content: "";
}

.fad.fa-arrow-down-to-arc:after, .fa-duotone.fa-arrow-down-to-arc:after {
  content: "";
}

.fad.fa-dumpster:after, .fa-duotone.fa-dumpster:after {
  content: "";
}

.fad.fa-van-shuttle:after, .fa-duotone.fa-van-shuttle:after, .fad.fa-shuttle-van:after, .fa-duotone.fa-shuttle-van:after {
  content: "";
}

.fad.fa-building-user:after, .fa-duotone.fa-building-user:after {
  content: "";
}

.fad.fa-light-switch:after, .fa-duotone.fa-light-switch:after {
  content: "";
}

.fad.fa-square-caret-left:after, .fa-duotone.fa-square-caret-left:after, .fad.fa-caret-square-left:after, .fa-duotone.fa-caret-square-left:after {
  content: "";
}

.fad.fa-highlighter:after, .fa-duotone.fa-highlighter:after {
  content: "";
}

.fad.fa-wave-pulse:after, .fa-duotone.fa-wave-pulse:after, .fad.fa-heart-rate:after, .fa-duotone.fa-heart-rate:after {
  content: "";
}

.fad.fa-key:after, .fa-duotone.fa-key:after {
  content: "";
}

.fad.fa-arrow-left-to-bracket:after, .fa-duotone.fa-arrow-left-to-bracket:after {
  content: "";
}

.fad.fa-hat-santa:after, .fa-duotone.fa-hat-santa:after {
  content: "";
}

.fad.fa-tamale:after, .fa-duotone.fa-tamale:after {
  content: "";
}

.fad.fa-box-check:after, .fa-duotone.fa-box-check:after {
  content: "";
}

.fad.fa-bullhorn:after, .fa-duotone.fa-bullhorn:after {
  content: "";
}

.fad.fa-steak:after, .fa-duotone.fa-steak:after {
  content: "";
}

.fad.fa-location-crosshairs-slash:after, .fa-duotone.fa-location-crosshairs-slash:after, .fad.fa-location-slash:after, .fa-duotone.fa-location-slash:after {
  content: "";
}

.fad.fa-person-dolly:after, .fa-duotone.fa-person-dolly:after {
  content: "";
}

.fad.fa-globe:after, .fa-duotone.fa-globe:after {
  content: "";
}

.fad.fa-synagogue:after, .fa-duotone.fa-synagogue:after {
  content: "";
}

.fad.fa-file-chart-column:after, .fa-duotone.fa-file-chart-column:after, .fad.fa-file-chart-line:after, .fa-duotone.fa-file-chart-line:after {
  content: "";
}

.fad.fa-person-half-dress:after, .fa-duotone.fa-person-half-dress:after {
  content: "";
}

.fad.fa-folder-image:after, .fa-duotone.fa-folder-image:after {
  content: "";
}

.fad.fa-calendar-pen:after, .fa-duotone.fa-calendar-pen:after, .fad.fa-calendar-edit:after, .fa-duotone.fa-calendar-edit:after {
  content: "";
}

.fad.fa-road-bridge:after, .fa-duotone.fa-road-bridge:after {
  content: "";
}

.fad.fa-face-smile-tear:after, .fa-duotone.fa-face-smile-tear:after {
  content: "";
}

.fad.fa-message-plus:after, .fa-duotone.fa-message-plus:after, .fad.fa-comment-alt-plus:after, .fa-duotone.fa-comment-alt-plus:after {
  content: "";
}

.fad.fa-location-arrow:after, .fa-duotone.fa-location-arrow:after {
  content: "";
}

.fad.fa-c:after, .fa-duotone.fa-c:after {
  content: "CC";
}

.fad.fa-tablet-button:after, .fa-duotone.fa-tablet-button:after {
  content: "";
}

.fad.fa-person-dress-fairy:after, .fa-duotone.fa-person-dress-fairy:after {
  content: "";
}

.fad.fa-rectangle-history-circle-user:after, .fa-duotone.fa-rectangle-history-circle-user:after {
  content: "";
}

.fad.fa-building-lock:after, .fa-duotone.fa-building-lock:after {
  content: "";
}

.fad.fa-chart-line-up:after, .fa-duotone.fa-chart-line-up:after {
  content: "";
}

.fad.fa-mailbox:after, .fa-duotone.fa-mailbox:after {
  content: "";
}

.fad.fa-sign-posts:after, .fa-duotone.fa-sign-posts:after {
  content: "";
}

.fad.fa-truck-bolt:after, .fa-duotone.fa-truck-bolt:after {
  content: "";
}

.fad.fa-pizza-slice:after, .fa-duotone.fa-pizza-slice:after {
  content: "";
}

.fad.fa-money-bill-wave:after, .fa-duotone.fa-money-bill-wave:after {
  content: "";
}

.fad.fa-chart-area:after, .fa-duotone.fa-chart-area:after, .fad.fa-area-chart:after, .fa-duotone.fa-area-chart:after {
  content: "";
}

.fad.fa-house-flag:after, .fa-duotone.fa-house-flag:after {
  content: "";
}

.fad.fa-circle-three-quarters-stroke:after, .fa-duotone.fa-circle-three-quarters-stroke:after {
  content: "";
}

.fad.fa-person-circle-minus:after, .fa-duotone.fa-person-circle-minus:after {
  content: "";
}

.fad.fa-scalpel:after, .fa-duotone.fa-scalpel:after {
  content: "";
}

.fad.fa-ban:after, .fa-duotone.fa-ban:after, .fad.fa-cancel:after, .fa-duotone.fa-cancel:after {
  content: "";
}

.fad.fa-bell-exclamation:after, .fa-duotone.fa-bell-exclamation:after {
  content: "";
}

.fad.fa-circle-bookmark:after, .fa-duotone.fa-circle-bookmark:after, .fad.fa-bookmark-circle:after, .fa-duotone.fa-bookmark-circle:after {
  content: "";
}

.fad.fa-egg-fried:after, .fa-duotone.fa-egg-fried:after {
  content: "";
}

.fad.fa-face-weary:after, .fa-duotone.fa-face-weary:after {
  content: "";
}

.fad.fa-uniform-martial-arts:after, .fa-duotone.fa-uniform-martial-arts:after {
  content: "";
}

.fad.fa-camera-rotate:after, .fa-duotone.fa-camera-rotate:after {
  content: "";
}

.fad.fa-sun-dust:after, .fa-duotone.fa-sun-dust:after {
  content: "";
}

.fad.fa-comment-text:after, .fa-duotone.fa-comment-text:after {
  content: "";
}

.fad.fa-spray-can-sparkles:after, .fa-duotone.fa-spray-can-sparkles:after, .fad.fa-air-freshener:after, .fa-duotone.fa-air-freshener:after {
  content: "";
}

.fad.fa-signal-bars:after, .fa-duotone.fa-signal-bars:after, .fad.fa-signal-alt:after, .fa-duotone.fa-signal-alt:after, .fad.fa-signal-alt-4:after, .fa-duotone.fa-signal-alt-4:after, .fad.fa-signal-bars-strong:after, .fa-duotone.fa-signal-bars-strong:after {
  content: "";
}

.fad.fa-diamond-exclamation:after, .fa-duotone.fa-diamond-exclamation:after {
  content: "";
}

.fad.fa-star:after, .fa-duotone.fa-star:after {
  content: "";
}

.fad.fa-dial-min:after, .fa-duotone.fa-dial-min:after {
  content: "";
}

.fad.fa-repeat:after, .fa-duotone.fa-repeat:after {
  content: "";
}

.fad.fa-cross:after, .fa-duotone.fa-cross:after {
  content: "";
}

.fad.fa-page-caret-down:after, .fa-duotone.fa-page-caret-down:after, .fad.fa-file-caret-down:after, .fa-duotone.fa-file-caret-down:after {
  content: "";
}

.fad.fa-box:after, .fa-duotone.fa-box:after {
  content: "";
}

.fad.fa-venus-mars:after, .fa-duotone.fa-venus-mars:after {
  content: "";
}

.fad.fa-clock-seven-thirty:after, .fa-duotone.fa-clock-seven-thirty:after {
  content: "";
}

.fad.fa-arrow-pointer:after, .fa-duotone.fa-arrow-pointer:after, .fad.fa-mouse-pointer:after, .fa-duotone.fa-mouse-pointer:after {
  content: "";
}

.fad.fa-clock-four-thirty:after, .fa-duotone.fa-clock-four-thirty:after {
  content: "";
}

.fad.fa-signal-bars-good:after, .fa-duotone.fa-signal-bars-good:after, .fad.fa-signal-alt-3:after, .fa-duotone.fa-signal-alt-3:after {
  content: "";
}

.fad.fa-cactus:after, .fa-duotone.fa-cactus:after {
  content: "";
}

.fad.fa-lightbulb-gear:after, .fa-duotone.fa-lightbulb-gear:after {
  content: "";
}

.fad.fa-maximize:after, .fa-duotone.fa-maximize:after, .fad.fa-expand-arrows-alt:after, .fa-duotone.fa-expand-arrows-alt:after {
  content: "";
}

.fad.fa-charging-station:after, .fa-duotone.fa-charging-station:after {
  content: "";
}

.fad.fa-shapes:after, .fa-duotone.fa-shapes:after, .fad.fa-triangle-circle-square:after, .fa-duotone.fa-triangle-circle-square:after {
  content: "";
}

.fad.fa-plane-tail:after, .fa-duotone.fa-plane-tail:after {
  content: "";
}

.fad.fa-gauge-simple-max:after, .fa-duotone.fa-gauge-simple-max:after, .fad.fa-tachometer-fastest:after, .fa-duotone.fa-tachometer-fastest:after {
  content: "";
}

.fad.fa-circle-u:after, .fa-duotone.fa-circle-u:after {
  content: "";
}

.fad.fa-shield-slash:after, .fa-duotone.fa-shield-slash:after {
  content: "";
}

.fad.fa-square-phone-hangup:after, .fa-duotone.fa-square-phone-hangup:after, .fad.fa-phone-square-down:after, .fa-duotone.fa-phone-square-down:after {
  content: "";
}

.fad.fa-arrow-up-left:after, .fa-duotone.fa-arrow-up-left:after {
  content: "";
}

.fad.fa-transporter-1:after, .fa-duotone.fa-transporter-1:after {
  content: "";
}

.fad.fa-peanuts:after, .fa-duotone.fa-peanuts:after {
  content: "";
}

.fad.fa-shuffle:after, .fa-duotone.fa-shuffle:after, .fad.fa-random:after, .fa-duotone.fa-random:after {
  content: "";
}

.fad.fa-person-running:after, .fa-duotone.fa-person-running:after, .fad.fa-running:after, .fa-duotone.fa-running:after {
  content: "";
}

.fad.fa-mobile-retro:after, .fa-duotone.fa-mobile-retro:after {
  content: "";
}

.fad.fa-grip-lines-vertical:after, .fa-duotone.fa-grip-lines-vertical:after {
  content: "";
}

.fad.fa-bin-bottles-recycle:after, .fa-duotone.fa-bin-bottles-recycle:after {
  content: "";
}

.fad.fa-arrow-up-from-square:after, .fa-duotone.fa-arrow-up-from-square:after {
  content: "";
}

.fad.fa-file-dashed-line:after, .fa-duotone.fa-file-dashed-line:after, .fad.fa-page-break:after, .fa-duotone.fa-page-break:after {
  content: "";
}

.fad.fa-bracket-curly-right:after, .fa-duotone.fa-bracket-curly-right:after {
  content: "}}";
}

.fad.fa-spider:after, .fa-duotone.fa-spider:after {
  content: "";
}

.fad.fa-clock-three:after, .fa-duotone.fa-clock-three:after {
  content: "";
}

.fad.fa-hands-bound:after, .fa-duotone.fa-hands-bound:after {
  content: "";
}

.fad.fa-scalpel-line-dashed:after, .fa-duotone.fa-scalpel-line-dashed:after, .fad.fa-scalpel-path:after, .fa-duotone.fa-scalpel-path:after {
  content: "";
}

.fad.fa-file-invoice-dollar:after, .fa-duotone.fa-file-invoice-dollar:after {
  content: "";
}

.fad.fa-pipe-smoking:after, .fa-duotone.fa-pipe-smoking:after {
  content: "";
}

.fad.fa-face-astonished:after, .fa-duotone.fa-face-astonished:after {
  content: "";
}

.fad.fa-window:after, .fa-duotone.fa-window:after {
  content: "";
}

.fad.fa-plane-circle-exclamation:after, .fa-duotone.fa-plane-circle-exclamation:after {
  content: "";
}

.fad.fa-ear:after, .fa-duotone.fa-ear:after {
  content: "";
}

.fad.fa-file-lock:after, .fa-duotone.fa-file-lock:after {
  content: "";
}

.fad.fa-diagram-venn:after, .fa-duotone.fa-diagram-venn:after {
  content: "";
}

.fad.fa-arrow-down-from-bracket:after, .fa-duotone.fa-arrow-down-from-bracket:after {
  content: "";
}

.fad.fa-x-ray:after, .fa-duotone.fa-x-ray:after {
  content: "";
}

.fad.fa-goal-net:after, .fa-duotone.fa-goal-net:after {
  content: "";
}

.fad.fa-coffin-cross:after, .fa-duotone.fa-coffin-cross:after {
  content: "";
}

.fad.fa-octopus:after, .fa-duotone.fa-octopus:after {
  content: "";
}

.fad.fa-spell-check:after, .fa-duotone.fa-spell-check:after {
  content: "";
}

.fad.fa-location-xmark:after, .fa-duotone.fa-location-xmark:after, .fad.fa-map-marker-times:after, .fa-duotone.fa-map-marker-times:after, .fad.fa-map-marker-xmark:after, .fa-duotone.fa-map-marker-xmark:after {
  content: "";
}

.fad.fa-circle-quarter-stroke:after, .fa-duotone.fa-circle-quarter-stroke:after {
  content: "";
}

.fad.fa-lasso:after, .fa-duotone.fa-lasso:after {
  content: "";
}

.fad.fa-slash:after, .fa-duotone.fa-slash:after {
  content: "";
}

.fad.fa-person-to-portal:after, .fa-duotone.fa-person-to-portal:after, .fad.fa-portal-enter:after, .fa-duotone.fa-portal-enter:after {
  content: "";
}

.fad.fa-calendar-star:after, .fa-duotone.fa-calendar-star:after {
  content: "";
}

.fad.fa-computer-mouse:after, .fa-duotone.fa-computer-mouse:after, .fad.fa-mouse:after, .fa-duotone.fa-mouse:after {
  content: "";
}

.fad.fa-arrow-right-to-bracket:after, .fa-duotone.fa-arrow-right-to-bracket:after, .fad.fa-sign-in:after, .fa-duotone.fa-sign-in:after {
  content: "";
}

.fad.fa-pegasus:after, .fa-duotone.fa-pegasus:after {
  content: "";
}

.fad.fa-files-medical:after, .fa-duotone.fa-files-medical:after {
  content: "";
}

.fad.fa-cannon:after, .fa-duotone.fa-cannon:after {
  content: "";
}

.fad.fa-nfc-lock:after, .fa-duotone.fa-nfc-lock:after {
  content: "";
}

.fad.fa-person-ski-lift:after, .fa-duotone.fa-person-ski-lift:after, .fad.fa-ski-lift:after, .fa-duotone.fa-ski-lift:after {
  content: "";
}

.fad.fa-square-6:after, .fa-duotone.fa-square-6:after {
  content: "";
}

.fad.fa-shop-slash:after, .fa-duotone.fa-shop-slash:after, .fad.fa-store-alt-slash:after, .fa-duotone.fa-store-alt-slash:after {
  content: "";
}

.fad.fa-wind-turbine:after, .fa-duotone.fa-wind-turbine:after {
  content: "";
}

.fad.fa-sliders-simple:after, .fa-duotone.fa-sliders-simple:after {
  content: "";
}

.fad.fa-grid-round:after, .fa-duotone.fa-grid-round:after {
  content: "";
}

.fad.fa-badge-sheriff:after, .fa-duotone.fa-badge-sheriff:after {
  content: "";
}

.fad.fa-server:after, .fa-duotone.fa-server:after {
  content: "";
}

.fad.fa-virus-covid-slash:after, .fa-duotone.fa-virus-covid-slash:after {
  content: "";
}

.fad.fa-intersection:after, .fa-duotone.fa-intersection:after {
  content: "";
}

.fad.fa-shop-lock:after, .fa-duotone.fa-shop-lock:after {
  content: "";
}

.fad.fa-family:after, .fa-duotone.fa-family:after {
  content: "";
}

.fad.fa-hourglass-start:after, .fa-duotone.fa-hourglass-start:after, .fad.fa-hourglass-1:after, .fa-duotone.fa-hourglass-1:after {
  content: "";
}

.fad.fa-user-hair-buns:after, .fa-duotone.fa-user-hair-buns:after {
  content: "";
}

.fad.fa-blender-phone:after, .fa-duotone.fa-blender-phone:after {
  content: "";
}

.fad.fa-hourglass-clock:after, .fa-duotone.fa-hourglass-clock:after {
  content: "";
}

.fad.fa-person-seat-reclined:after, .fa-duotone.fa-person-seat-reclined:after {
  content: "";
}

.fad.fa-paper-plane-top:after, .fa-duotone.fa-paper-plane-top:after, .fad.fa-paper-plane-alt:after, .fa-duotone.fa-paper-plane-alt:after, .fad.fa-send:after, .fa-duotone.fa-send:after {
  content: "";
}

.fad.fa-message-arrow-up:after, .fa-duotone.fa-message-arrow-up:after, .fad.fa-comment-alt-arrow-up:after, .fa-duotone.fa-comment-alt-arrow-up:after {
  content: "";
}

.fad.fa-lightbulb-exclamation:after, .fa-duotone.fa-lightbulb-exclamation:after {
  content: "";
}

.fad.fa-layer-minus:after, .fa-duotone.fa-layer-minus:after, .fad.fa-layer-group-minus:after, .fa-duotone.fa-layer-group-minus:after {
  content: "";
}

.fad.fa-chart-pie-simple-circle-currency:after, .fa-duotone.fa-chart-pie-simple-circle-currency:after {
  content: "";
}

.fad.fa-circle-e:after, .fa-duotone.fa-circle-e:after {
  content: "";
}

.fad.fa-building-wheat:after, .fa-duotone.fa-building-wheat:after {
  content: "";
}

.fad.fa-gauge-max:after, .fa-duotone.fa-gauge-max:after, .fad.fa-tachometer-alt-fastest:after, .fa-duotone.fa-tachometer-alt-fastest:after {
  content: "";
}

.fad.fa-person-breastfeeding:after, .fa-duotone.fa-person-breastfeeding:after {
  content: "";
}

.fad.fa-apostrophe:after, .fa-duotone.fa-apostrophe:after {
  content: "''";
}

.fad.fa-file-png:after, .fa-duotone.fa-file-png:after {
  content: "";
}

.fad.fa-fire-hydrant:after, .fa-duotone.fa-fire-hydrant:after {
  content: "";
}

.fad.fa-right-to-bracket:after, .fa-duotone.fa-right-to-bracket:after, .fad.fa-sign-in-alt:after, .fa-duotone.fa-sign-in-alt:after {
  content: "";
}

.fad.fa-video-plus:after, .fa-duotone.fa-video-plus:after {
  content: "";
}

.fad.fa-square-right:after, .fa-duotone.fa-square-right:after, .fad.fa-arrow-alt-square-right:after, .fa-duotone.fa-arrow-alt-square-right:after {
  content: "";
}

.fad.fa-comment-smile:after, .fa-duotone.fa-comment-smile:after {
  content: "";
}

.fad.fa-venus:after, .fa-duotone.fa-venus:after {
  content: "";
}

.fad.fa-passport:after, .fa-duotone.fa-passport:after {
  content: "";
}

.fad.fa-thumbtack-slash:after, .fa-duotone.fa-thumbtack-slash:after, .fad.fa-thumb-tack-slash:after, .fa-duotone.fa-thumb-tack-slash:after {
  content: "";
}

.fad.fa-inbox-in:after, .fa-duotone.fa-inbox-in:after, .fad.fa-inbox-arrow-down:after, .fa-duotone.fa-inbox-arrow-down:after {
  content: "";
}

.fad.fa-heart-pulse:after, .fa-duotone.fa-heart-pulse:after, .fad.fa-heartbeat:after, .fa-duotone.fa-heartbeat:after {
  content: "";
}

.fad.fa-circle-8:after, .fa-duotone.fa-circle-8:after {
  content: "";
}

.fad.fa-clouds-moon:after, .fa-duotone.fa-clouds-moon:after {
  content: "";
}

.fad.fa-clock-ten-thirty:after, .fa-duotone.fa-clock-ten-thirty:after {
  content: "";
}

.fad.fa-people-carry-box:after, .fa-duotone.fa-people-carry-box:after, .fad.fa-people-carry:after, .fa-duotone.fa-people-carry:after {
  content: "";
}

.fad.fa-folder-user:after, .fa-duotone.fa-folder-user:after {
  content: "";
}

.fad.fa-trash-can-xmark:after, .fa-duotone.fa-trash-can-xmark:after {
  content: "";
}

.fad.fa-temperature-high:after, .fa-duotone.fa-temperature-high:after {
  content: "";
}

.fad.fa-microchip:after, .fa-duotone.fa-microchip:after {
  content: "";
}

.fad.fa-left-long-to-line:after, .fa-duotone.fa-left-long-to-line:after {
  content: "";
}

.fad.fa-crown:after, .fa-duotone.fa-crown:after {
  content: "";
}

.fad.fa-weight-hanging:after, .fa-duotone.fa-weight-hanging:after {
  content: "";
}

.fad.fa-xmarks-lines:after, .fa-duotone.fa-xmarks-lines:after {
  content: "";
}

.fad.fa-file-prescription:after, .fa-duotone.fa-file-prescription:after {
  content: "";
}

.fad.fa-table-cells-lock:after, .fa-duotone.fa-table-cells-lock:after {
  content: "";
}

.fad.fa-calendar-range:after, .fa-duotone.fa-calendar-range:after {
  content: "";
}

.fad.fa-flower-daffodil:after, .fa-duotone.fa-flower-daffodil:after {
  content: "";
}

.fad.fa-hand-back-point-up:after, .fa-duotone.fa-hand-back-point-up:after {
  content: "";
}

.fad.fa-weight-scale:after, .fa-duotone.fa-weight-scale:after, .fad.fa-weight:after, .fa-duotone.fa-weight:after {
  content: "";
}

.fad.fa-arrow-up-to-arc:after, .fa-duotone.fa-arrow-up-to-arc:after {
  content: "";
}

.fad.fa-star-exclamation:after, .fa-duotone.fa-star-exclamation:after {
  content: "";
}

.fad.fa-books:after, .fa-duotone.fa-books:after {
  content: "";
}

.fad.fa-user-group:after, .fa-duotone.fa-user-group:after, .fad.fa-user-friends:after, .fa-duotone.fa-user-friends:after {
  content: "";
}

.fad.fa-arrow-up-a-z:after, .fa-duotone.fa-arrow-up-a-z:after, .fad.fa-sort-alpha-up:after, .fa-duotone.fa-sort-alpha-up:after {
  content: "";
}

.fad.fa-layer-plus:after, .fa-duotone.fa-layer-plus:after, .fad.fa-layer-group-plus:after, .fa-duotone.fa-layer-group-plus:after {
  content: "";
}

.fad.fa-play-pause:after, .fa-duotone.fa-play-pause:after {
  content: "";
}

.fad.fa-block-question:after, .fa-duotone.fa-block-question:after {
  content: "";
}

.fad.fa-snooze:after, .fa-duotone.fa-snooze:after, .fad.fa-zzz:after, .fa-duotone.fa-zzz:after {
  content: "";
}

.fad.fa-scanner-image:after, .fa-duotone.fa-scanner-image:after {
  content: "";
}

.fad.fa-tv-retro:after, .fa-duotone.fa-tv-retro:after {
  content: "";
}

.fad.fa-square-t:after, .fa-duotone.fa-square-t:after {
  content: "";
}

.fad.fa-farm:after, .fa-duotone.fa-farm:after, .fad.fa-barn-silo:after, .fa-duotone.fa-barn-silo:after {
  content: "";
}

.fad.fa-chess-knight:after, .fa-duotone.fa-chess-knight:after {
  content: "";
}

.fad.fa-bars-sort:after, .fa-duotone.fa-bars-sort:after {
  content: "";
}

.fad.fa-pallet-boxes:after, .fa-duotone.fa-pallet-boxes:after, .fad.fa-palette-boxes:after, .fa-duotone.fa-palette-boxes:after, .fad.fa-pallet-alt:after, .fa-duotone.fa-pallet-alt:after {
  content: "";
}

.fad.fa-face-laugh-squint:after, .fa-duotone.fa-face-laugh-squint:after, .fad.fa-laugh-squint:after, .fa-duotone.fa-laugh-squint:after {
  content: "";
}

.fad.fa-code-simple:after, .fa-duotone.fa-code-simple:after {
  content: "";
}

.fad.fa-bolt-slash:after, .fa-duotone.fa-bolt-slash:after {
  content: "";
}

.fad.fa-panel-fire:after, .fa-duotone.fa-panel-fire:after {
  content: "";
}

.fad.fa-binary-circle-check:after, .fa-duotone.fa-binary-circle-check:after {
  content: "";
}

.fad.fa-comment-minus:after, .fa-duotone.fa-comment-minus:after {
  content: "";
}

.fad.fa-burrito:after, .fa-duotone.fa-burrito:after {
  content: "";
}

.fad.fa-violin:after, .fa-duotone.fa-violin:after {
  content: "";
}

.fad.fa-objects-column:after, .fa-duotone.fa-objects-column:after {
  content: "";
}

.fad.fa-square-chevron-down:after, .fa-duotone.fa-square-chevron-down:after, .fad.fa-chevron-square-down:after, .fa-duotone.fa-chevron-square-down:after {
  content: "";
}

.fad.fa-comment-plus:after, .fa-duotone.fa-comment-plus:after {
  content: "";
}

.fad.fa-triangle-instrument:after, .fa-duotone.fa-triangle-instrument:after, .fad.fa-triangle-music:after, .fa-duotone.fa-triangle-music:after {
  content: "";
}

.fad.fa-wheelchair:after, .fa-duotone.fa-wheelchair:after {
  content: "";
}

.fad.fa-user-pilot-tie:after, .fa-duotone.fa-user-pilot-tie:after {
  content: "";
}

.fad.fa-piano-keyboard:after, .fa-duotone.fa-piano-keyboard:after {
  content: "";
}

.fad.fa-bed-empty:after, .fa-duotone.fa-bed-empty:after {
  content: "";
}

.fad.fa-circle-arrow-up:after, .fa-duotone.fa-circle-arrow-up:after, .fad.fa-arrow-circle-up:after, .fa-duotone.fa-arrow-circle-up:after {
  content: "";
}

.fad.fa-toggle-on:after, .fa-duotone.fa-toggle-on:after {
  content: "";
}

.fad.fa-rectangle-vertical:after, .fa-duotone.fa-rectangle-vertical:after, .fad.fa-rectangle-portrait:after, .fa-duotone.fa-rectangle-portrait:after {
  content: "";
}

.fad.fa-person-walking:after, .fa-duotone.fa-person-walking:after, .fad.fa-walking:after, .fa-duotone.fa-walking:after {
  content: "";
}

.fad.fa-l:after, .fa-duotone.fa-l:after {
  content: "LL";
}

.fad.fa-signal-stream:after, .fa-duotone.fa-signal-stream:after {
  content: "";
}

.fad.fa-down-to-bracket:after, .fa-duotone.fa-down-to-bracket:after {
  content: "";
}

.fad.fa-circle-z:after, .fa-duotone.fa-circle-z:after {
  content: "";
}

.fad.fa-stars:after, .fa-duotone.fa-stars:after {
  content: "";
}

.fad.fa-fire:after, .fa-duotone.fa-fire:after {
  content: "";
}

.fad.fa-bed-pulse:after, .fa-duotone.fa-bed-pulse:after, .fad.fa-procedures:after, .fa-duotone.fa-procedures:after {
  content: "";
}

.fad.fa-house-day:after, .fa-duotone.fa-house-day:after {
  content: "";
}

.fad.fa-shuttle-space:after, .fa-duotone.fa-shuttle-space:after, .fad.fa-space-shuttle:after, .fa-duotone.fa-space-shuttle:after {
  content: "";
}

.fad.fa-shirt-long-sleeve:after, .fa-duotone.fa-shirt-long-sleeve:after {
  content: "";
}

.fad.fa-chart-pie-simple:after, .fa-duotone.fa-chart-pie-simple:after, .fad.fa-chart-pie-alt:after, .fa-duotone.fa-chart-pie-alt:after {
  content: "";
}

.fad.fa-face-laugh:after, .fa-duotone.fa-face-laugh:after, .fad.fa-laugh:after, .fa-duotone.fa-laugh:after {
  content: "";
}

.fad.fa-folder-open:after, .fa-duotone.fa-folder-open:after {
  content: "";
}

.fad.fa-album-collection-circle-user:after, .fa-duotone.fa-album-collection-circle-user:after {
  content: "";
}

.fad.fa-candy:after, .fa-duotone.fa-candy:after {
  content: "";
}

.fad.fa-bowl-hot:after, .fa-duotone.fa-bowl-hot:after, .fad.fa-soup:after, .fa-duotone.fa-soup:after {
  content: "";
}

.fad.fa-flatbread:after, .fa-duotone.fa-flatbread:after {
  content: "";
}

.fad.fa-heart-circle-plus:after, .fa-duotone.fa-heart-circle-plus:after {
  content: "";
}

.fad.fa-code-fork:after, .fa-duotone.fa-code-fork:after {
  content: "";
}

.fad.fa-city:after, .fa-duotone.fa-city:after {
  content: "";
}

.fad.fa-signal-bars-weak:after, .fa-duotone.fa-signal-bars-weak:after, .fad.fa-signal-alt-1:after, .fa-duotone.fa-signal-alt-1:after {
  content: "";
}

.fad.fa-microphone-lines:after, .fa-duotone.fa-microphone-lines:after, .fad.fa-microphone-alt:after, .fa-duotone.fa-microphone-alt:after {
  content: "";
}

.fad.fa-clock-twelve:after, .fa-duotone.fa-clock-twelve:after {
  content: "";
}

.fad.fa-pepper-hot:after, .fa-duotone.fa-pepper-hot:after {
  content: "";
}

.fad.fa-citrus-slice:after, .fa-duotone.fa-citrus-slice:after {
  content: "";
}

.fad.fa-sheep:after, .fa-duotone.fa-sheep:after {
  content: "";
}

.fad.fa-unlock:after, .fa-duotone.fa-unlock:after {
  content: "";
}

.fad.fa-colon-sign:after, .fa-duotone.fa-colon-sign:after {
  content: "";
}

.fad.fa-headset:after, .fa-duotone.fa-headset:after {
  content: "";
}

.fad.fa-badger-honey:after, .fa-duotone.fa-badger-honey:after {
  content: "";
}

.fad.fa-h4:after, .fa-duotone.fa-h4:after {
  content: "";
}

.fad.fa-store-slash:after, .fa-duotone.fa-store-slash:after {
  content: "";
}

.fad.fa-road-circle-xmark:after, .fa-duotone.fa-road-circle-xmark:after {
  content: "";
}

.fad.fa-signal-slash:after, .fa-duotone.fa-signal-slash:after {
  content: "";
}

.fad.fa-user-minus:after, .fa-duotone.fa-user-minus:after {
  content: "";
}

.fad.fa-mars-stroke-up:after, .fa-duotone.fa-mars-stroke-up:after, .fad.fa-mars-stroke-v:after, .fa-duotone.fa-mars-stroke-v:after {
  content: "";
}

.fad.fa-champagne-glasses:after, .fa-duotone.fa-champagne-glasses:after, .fad.fa-glass-cheers:after, .fa-duotone.fa-glass-cheers:after {
  content: "";
}

.fad.fa-taco:after, .fa-duotone.fa-taco:after {
  content: "";
}

.fad.fa-hexagon-plus:after, .fa-duotone.fa-hexagon-plus:after, .fad.fa-plus-hexagon:after, .fa-duotone.fa-plus-hexagon:after {
  content: "";
}

.fad.fa-clipboard:after, .fa-duotone.fa-clipboard:after {
  content: "";
}

.fad.fa-house-circle-exclamation:after, .fa-duotone.fa-house-circle-exclamation:after {
  content: "";
}

.fad.fa-file-arrow-up:after, .fa-duotone.fa-file-arrow-up:after, .fad.fa-file-upload:after, .fa-duotone.fa-file-upload:after {
  content: "";
}

.fad.fa-wifi:after, .fa-duotone.fa-wifi:after, .fad.fa-wifi-3:after, .fa-duotone.fa-wifi-3:after, .fad.fa-wifi-strong:after, .fa-duotone.fa-wifi-strong:after {
  content: "";
}

.fad.fa-messages:after, .fa-duotone.fa-messages:after, .fad.fa-comments-alt:after, .fa-duotone.fa-comments-alt:after {
  content: "";
}

.fad.fa-bath:after, .fa-duotone.fa-bath:after, .fad.fa-bathtub:after, .fa-duotone.fa-bathtub:after {
  content: "";
}

.fad.fa-umbrella-simple:after, .fa-duotone.fa-umbrella-simple:after, .fad.fa-umbrella-alt:after, .fa-duotone.fa-umbrella-alt:after {
  content: "";
}

.fad.fa-rectangle-history-circle-plus:after, .fa-duotone.fa-rectangle-history-circle-plus:after {
  content: "";
}

.fad.fa-underline:after, .fa-duotone.fa-underline:after {
  content: "";
}

.fad.fa-prescription-bottle-pill:after, .fa-duotone.fa-prescription-bottle-pill:after {
  content: "";
}

.fad.fa-user-pen:after, .fa-duotone.fa-user-pen:after, .fad.fa-user-edit:after, .fa-duotone.fa-user-edit:after {
  content: "";
}

.fad.fa-binary-slash:after, .fa-duotone.fa-binary-slash:after {
  content: "";
}

.fad.fa-square-o:after, .fa-duotone.fa-square-o:after {
  content: "";
}

.fad.fa-caduceus:after, .fa-duotone.fa-caduceus:after {
  content: "";
}

.fad.fa-signature:after, .fa-duotone.fa-signature:after {
  content: "";
}

.fad.fa-stroopwafel:after, .fa-duotone.fa-stroopwafel:after {
  content: "";
}

.fad.fa-bold:after, .fa-duotone.fa-bold:after {
  content: "";
}

.fad.fa-anchor-lock:after, .fa-duotone.fa-anchor-lock:after {
  content: "";
}

.fad.fa-building-ngo:after, .fa-duotone.fa-building-ngo:after {
  content: "";
}

.fad.fa-transporter-3:after, .fa-duotone.fa-transporter-3:after {
  content: "";
}

.fad.fa-engine-warning:after, .fa-duotone.fa-engine-warning:after, .fad.fa-engine-exclamation:after, .fa-duotone.fa-engine-exclamation:after {
  content: "";
}

.fad.fa-circle-down-right:after, .fa-duotone.fa-circle-down-right:after {
  content: "";
}

.fad.fa-square-k:after, .fa-duotone.fa-square-k:after {
  content: "";
}

.fad.fa-manat-sign:after, .fa-duotone.fa-manat-sign:after {
  content: "";
}

.fad.fa-money-check-pen:after, .fa-duotone.fa-money-check-pen:after, .fad.fa-money-check-edit:after, .fa-duotone.fa-money-check-edit:after {
  content: "";
}

.fad.fa-not-equal:after, .fa-duotone.fa-not-equal:after {
  content: "";
}

.fad.fa-border-top-left:after, .fa-duotone.fa-border-top-left:after, .fad.fa-border-style:after, .fa-duotone.fa-border-style:after {
  content: "";
}

.fad.fa-map-location-dot:after, .fa-duotone.fa-map-location-dot:after, .fad.fa-map-marked-alt:after, .fa-duotone.fa-map-marked-alt:after {
  content: "";
}

.fad.fa-tilde:after, .fa-duotone.fa-tilde:after {
  content: "~~";
}

.fad.fa-jedi:after, .fa-duotone.fa-jedi:after {
  content: "";
}

.fad.fa-square-poll-vertical:after, .fa-duotone.fa-square-poll-vertical:after, .fad.fa-poll:after, .fa-duotone.fa-poll:after {
  content: "";
}

.fad.fa-arrow-down-square-triangle:after, .fa-duotone.fa-arrow-down-square-triangle:after, .fad.fa-sort-shapes-down-alt:after, .fa-duotone.fa-sort-shapes-down-alt:after {
  content: "";
}

.fad.fa-mug-hot:after, .fa-duotone.fa-mug-hot:after {
  content: "";
}

.fad.fa-dog-leashed:after, .fa-duotone.fa-dog-leashed:after {
  content: "";
}

.fad.fa-car-battery:after, .fa-duotone.fa-car-battery:after, .fad.fa-battery-car:after, .fa-duotone.fa-battery-car:after {
  content: "";
}

.fad.fa-face-downcast-sweat:after, .fa-duotone.fa-face-downcast-sweat:after {
  content: "";
}

.fad.fa-mailbox-flag-up:after, .fa-duotone.fa-mailbox-flag-up:after {
  content: "";
}

.fad.fa-memo-circle-info:after, .fa-duotone.fa-memo-circle-info:after {
  content: "";
}

.fad.fa-gift:after, .fa-duotone.fa-gift:after {
  content: "";
}

.fad.fa-dice-two:after, .fa-duotone.fa-dice-two:after {
  content: "";
}

.fad.fa-volume:after, .fa-duotone.fa-volume:after, .fad.fa-volume-medium:after, .fa-duotone.fa-volume-medium:after {
  content: "";
}

.fad.fa-transporter-5:after, .fa-duotone.fa-transporter-5:after {
  content: "";
}

.fad.fa-gauge-circle-bolt:after, .fa-duotone.fa-gauge-circle-bolt:after {
  content: "";
}

.fad.fa-coin-front:after, .fa-duotone.fa-coin-front:after {
  content: "";
}

.fad.fa-file-slash:after, .fa-duotone.fa-file-slash:after {
  content: "";
}

.fad.fa-message-arrow-up-right:after, .fa-duotone.fa-message-arrow-up-right:after {
  content: "";
}

.fad.fa-treasure-chest:after, .fa-duotone.fa-treasure-chest:after {
  content: "";
}

.fad.fa-chess-queen:after, .fa-duotone.fa-chess-queen:after {
  content: "";
}

.fad.fa-paintbrush-fine:after, .fa-duotone.fa-paintbrush-fine:after, .fad.fa-paint-brush-alt:after, .fa-duotone.fa-paint-brush-alt:after, .fad.fa-paint-brush-fine:after, .fa-duotone.fa-paint-brush-fine:after, .fad.fa-paintbrush-alt:after, .fa-duotone.fa-paintbrush-alt:after {
  content: "";
}

.fad.fa-glasses:after, .fa-duotone.fa-glasses:after {
  content: "";
}

.fad.fa-hood-cloak:after, .fa-duotone.fa-hood-cloak:after {
  content: "";
}

.fad.fa-square-quote:after, .fa-duotone.fa-square-quote:after {
  content: "";
}

.fad.fa-up-left:after, .fa-duotone.fa-up-left:after {
  content: "";
}

.fad.fa-bring-front:after, .fa-duotone.fa-bring-front:after {
  content: "";
}

.fad.fa-chess-board:after, .fa-duotone.fa-chess-board:after {
  content: "";
}

.fad.fa-burger-cheese:after, .fa-duotone.fa-burger-cheese:after, .fad.fa-cheeseburger:after, .fa-duotone.fa-cheeseburger:after {
  content: "";
}

.fad.fa-building-circle-check:after, .fa-duotone.fa-building-circle-check:after {
  content: "";
}

.fad.fa-repeat-1:after, .fa-duotone.fa-repeat-1:after {
  content: "";
}

.fad.fa-arrow-down-to-line:after, .fa-duotone.fa-arrow-down-to-line:after, .fad.fa-arrow-to-bottom:after, .fa-duotone.fa-arrow-to-bottom:after {
  content: "";
}

.fad.fa-grid-5:after, .fa-duotone.fa-grid-5:after {
  content: "";
}

.fad.fa-swap-arrows:after, .fa-duotone.fa-swap-arrows:after {
  content: "";
}

.fad.fa-right-long-to-line:after, .fa-duotone.fa-right-long-to-line:after {
  content: "";
}

.fad.fa-person-chalkboard:after, .fa-duotone.fa-person-chalkboard:after {
  content: "";
}

.fad.fa-mars-stroke-right:after, .fa-duotone.fa-mars-stroke-right:after, .fad.fa-mars-stroke-h:after, .fa-duotone.fa-mars-stroke-h:after {
  content: "";
}

.fad.fa-hand-back-fist:after, .fa-duotone.fa-hand-back-fist:after, .fad.fa-hand-rock:after, .fa-duotone.fa-hand-rock:after {
  content: "";
}

.fad.fa-grid-round-5:after, .fa-duotone.fa-grid-round-5:after {
  content: "";
}

.fad.fa-tally:after, .fa-duotone.fa-tally:after, .fad.fa-tally-5:after, .fa-duotone.fa-tally-5:after {
  content: "";
}

.fad.fa-square-caret-up:after, .fa-duotone.fa-square-caret-up:after, .fad.fa-caret-square-up:after, .fa-duotone.fa-caret-square-up:after {
  content: "";
}

.fad.fa-cloud-showers-water:after, .fa-duotone.fa-cloud-showers-water:after {
  content: "";
}

.fad.fa-chart-bar:after, .fa-duotone.fa-chart-bar:after, .fad.fa-bar-chart:after, .fa-duotone.fa-bar-chart:after {
  content: "";
}

.fad.fa-hands-bubbles:after, .fa-duotone.fa-hands-bubbles:after, .fad.fa-hands-wash:after, .fa-duotone.fa-hands-wash:after {
  content: "";
}

.fad.fa-less-than-equal:after, .fa-duotone.fa-less-than-equal:after {
  content: "";
}

.fad.fa-train:after, .fa-duotone.fa-train:after {
  content: "";
}

.fad.fa-up-from-dotted-line:after, .fa-duotone.fa-up-from-dotted-line:after {
  content: "";
}

.fad.fa-eye-low-vision:after, .fa-duotone.fa-eye-low-vision:after, .fad.fa-low-vision:after, .fa-duotone.fa-low-vision:after {
  content: "";
}

.fad.fa-traffic-light-go:after, .fa-duotone.fa-traffic-light-go:after {
  content: "";
}

.fad.fa-face-exhaling:after, .fa-duotone.fa-face-exhaling:after {
  content: "";
}

.fad.fa-sensor-fire:after, .fa-duotone.fa-sensor-fire:after {
  content: "";
}

.fad.fa-user-unlock:after, .fa-duotone.fa-user-unlock:after {
  content: "";
}

.fad.fa-hexagon-divide:after, .fa-duotone.fa-hexagon-divide:after {
  content: "";
}

.fad.fa-00:after, .fa-duotone.fa-00:after {
  content: "";
}

.fad.fa-crow:after, .fa-duotone.fa-crow:after {
  content: "";
}

.fad.fa-cassette-betamax:after, .fa-duotone.fa-cassette-betamax:after, .fad.fa-betamax:after, .fa-duotone.fa-betamax:after {
  content: "";
}

.fad.fa-sailboat:after, .fa-duotone.fa-sailboat:after {
  content: "";
}

.fad.fa-window-restore:after, .fa-duotone.fa-window-restore:after {
  content: "";
}

.fad.fa-nfc-magnifying-glass:after, .fa-duotone.fa-nfc-magnifying-glass:after {
  content: "";
}

.fad.fa-file-binary:after, .fa-duotone.fa-file-binary:after {
  content: "";
}

.fad.fa-circle-v:after, .fa-duotone.fa-circle-v:after {
  content: "";
}

.fad.fa-square-plus:after, .fa-duotone.fa-square-plus:after, .fad.fa-plus-square:after, .fa-duotone.fa-plus-square:after {
  content: "";
}

.fad.fa-bowl-scoops:after, .fa-duotone.fa-bowl-scoops:after {
  content: "";
}

.fad.fa-mistletoe:after, .fa-duotone.fa-mistletoe:after {
  content: "";
}

.fad.fa-custard:after, .fa-duotone.fa-custard:after {
  content: "";
}

.fad.fa-lacrosse-stick:after, .fa-duotone.fa-lacrosse-stick:after {
  content: "";
}

.fad.fa-hockey-mask:after, .fa-duotone.fa-hockey-mask:after {
  content: "";
}

.fad.fa-sunrise:after, .fa-duotone.fa-sunrise:after {
  content: "";
}

.fad.fa-subtitles:after, .fa-duotone.fa-subtitles:after {
  content: "";
}

.fad.fa-panel-ews:after, .fa-duotone.fa-panel-ews:after {
  content: "";
}

.fad.fa-torii-gate:after, .fa-duotone.fa-torii-gate:after {
  content: "";
}

.fad.fa-cloud-exclamation:after, .fa-duotone.fa-cloud-exclamation:after {
  content: "";
}

.fad.fa-message-lines:after, .fa-duotone.fa-message-lines:after, .fad.fa-comment-alt-lines:after, .fa-duotone.fa-comment-alt-lines:after {
  content: "";
}

.fad.fa-frog:after, .fa-duotone.fa-frog:after {
  content: "";
}

.fad.fa-bucket:after, .fa-duotone.fa-bucket:after {
  content: "";
}

.fad.fa-floppy-disk-pen:after, .fa-duotone.fa-floppy-disk-pen:after {
  content: "";
}

.fad.fa-image:after, .fa-duotone.fa-image:after {
  content: "";
}

.fad.fa-window-frame:after, .fa-duotone.fa-window-frame:after {
  content: "";
}

.fad.fa-microphone:after, .fa-duotone.fa-microphone:after {
  content: "";
}

.fad.fa-cow:after, .fa-duotone.fa-cow:after {
  content: "";
}

.fad.fa-file-zip:after, .fa-duotone.fa-file-zip:after {
  content: "";
}

.fad.fa-square-ring:after, .fa-duotone.fa-square-ring:after {
  content: "";
}

.fad.fa-down-from-line:after, .fa-duotone.fa-down-from-line:after, .fad.fa-arrow-alt-from-top:after, .fa-duotone.fa-arrow-alt-from-top:after {
  content: "";
}

.fad.fa-caret-up:after, .fa-duotone.fa-caret-up:after {
  content: "";
}

.fad.fa-shield-xmark:after, .fa-duotone.fa-shield-xmark:after, .fad.fa-shield-times:after, .fa-duotone.fa-shield-times:after {
  content: "";
}

.fad.fa-screwdriver:after, .fa-duotone.fa-screwdriver:after {
  content: "";
}

.fad.fa-circle-sort-down:after, .fa-duotone.fa-circle-sort-down:after, .fad.fa-sort-circle-down:after, .fa-duotone.fa-sort-circle-down:after {
  content: "";
}

.fad.fa-folder-closed:after, .fa-duotone.fa-folder-closed:after {
  content: "";
}

.fad.fa-house-tsunami:after, .fa-duotone.fa-house-tsunami:after {
  content: "";
}

.fad.fa-square-nfi:after, .fa-duotone.fa-square-nfi:after {
  content: "";
}

.fad.fa-forklift:after, .fa-duotone.fa-forklift:after {
  content: "";
}

.fad.fa-arrow-up-from-ground-water:after, .fa-duotone.fa-arrow-up-from-ground-water:after {
  content: "";
}

.fad.fa-bracket-square-right:after, .fa-duotone.fa-bracket-square-right:after {
  content: "]]";
}

.fad.fa-martini-glass:after, .fa-duotone.fa-martini-glass:after, .fad.fa-glass-martini-alt:after, .fa-duotone.fa-glass-martini-alt:after {
  content: "";
}

.fad.fa-rotate-left:after, .fa-duotone.fa-rotate-left:after, .fad.fa-rotate-back:after, .fa-duotone.fa-rotate-back:after, .fad.fa-rotate-backward:after, .fa-duotone.fa-rotate-backward:after, .fad.fa-undo-alt:after, .fa-duotone.fa-undo-alt:after {
  content: "";
}

.fad.fa-table-columns:after, .fa-duotone.fa-table-columns:after, .fad.fa-columns:after, .fa-duotone.fa-columns:after {
  content: "";
}

.fad.fa-square-a:after, .fa-duotone.fa-square-a:after {
  content: "";
}

.fad.fa-tick:after, .fa-duotone.fa-tick:after {
  content: "";
}

.fad.fa-lemon:after, .fa-duotone.fa-lemon:after {
  content: "";
}

.fad.fa-head-side-mask:after, .fa-duotone.fa-head-side-mask:after {
  content: "";
}

.fad.fa-handshake:after, .fa-duotone.fa-handshake:after {
  content: "";
}

.fad.fa-gem:after, .fa-duotone.fa-gem:after {
  content: "";
}

.fad.fa-dolly:after, .fa-duotone.fa-dolly:after, .fad.fa-dolly-box:after, .fa-duotone.fa-dolly-box:after {
  content: "";
}

.fad.fa-smoking:after, .fa-duotone.fa-smoking:after {
  content: "";
}

.fad.fa-minimize:after, .fa-duotone.fa-minimize:after, .fad.fa-compress-arrows-alt:after, .fa-duotone.fa-compress-arrows-alt:after {
  content: "";
}

.fad.fa-refrigerator:after, .fa-duotone.fa-refrigerator:after {
  content: "";
}

.fad.fa-monument:after, .fa-duotone.fa-monument:after {
  content: "";
}

.fad.fa-octagon-xmark:after, .fa-duotone.fa-octagon-xmark:after, .fad.fa-times-octagon:after, .fa-duotone.fa-times-octagon:after, .fad.fa-xmark-octagon:after, .fa-duotone.fa-xmark-octagon:after {
  content: "";
}

.fad.fa-align-slash:after, .fa-duotone.fa-align-slash:after {
  content: "";
}

.fad.fa-snowplow:after, .fa-duotone.fa-snowplow:after {
  content: "";
}

.fad.fa-angles-right:after, .fa-duotone.fa-angles-right:after, .fad.fa-angle-double-right:after, .fa-duotone.fa-angle-double-right:after {
  content: "";
}

.fad.fa-truck-ramp-couch:after, .fa-duotone.fa-truck-ramp-couch:after, .fad.fa-truck-couch:after, .fa-duotone.fa-truck-couch:after {
  content: "";
}

.fad.fa-cannabis:after, .fa-duotone.fa-cannabis:after {
  content: "";
}

.fad.fa-circle-play:after, .fa-duotone.fa-circle-play:after, .fad.fa-play-circle:after, .fa-duotone.fa-play-circle:after {
  content: "";
}

.fad.fa-arrow-up-right-and-arrow-down-left-from-center:after, .fa-duotone.fa-arrow-up-right-and-arrow-down-left-from-center:after {
  content: "";
}

.fad.fa-location-arrow-up:after, .fa-duotone.fa-location-arrow-up:after {
  content: "";
}

.fad.fa-tablets:after, .fa-duotone.fa-tablets:after {
  content: "";
}

.fad.fa-360-degrees:after, .fa-duotone.fa-360-degrees:after {
  content: "";
}

.fad.fa-ethernet:after, .fa-duotone.fa-ethernet:after {
  content: "";
}

.fad.fa-euro-sign:after, .fa-duotone.fa-euro-sign:after, .fad.fa-eur:after, .fa-duotone.fa-eur:after, .fad.fa-euro:after, .fa-duotone.fa-euro:after {
  content: "";
}

.fad.fa-chair:after, .fa-duotone.fa-chair:after {
  content: "";
}

.fad.fa-circle-check:after, .fa-duotone.fa-circle-check:after, .fad.fa-check-circle:after, .fa-duotone.fa-check-circle:after {
  content: "";
}

.fad.fa-square-dashed-circle-plus:after, .fa-duotone.fa-square-dashed-circle-plus:after {
  content: "";
}

.fad.fa-hand-holding-circle-dollar:after, .fa-duotone.fa-hand-holding-circle-dollar:after {
  content: "";
}

.fad.fa-money-simple-from-bracket:after, .fa-duotone.fa-money-simple-from-bracket:after {
  content: "";
}

.fad.fa-bat:after, .fa-duotone.fa-bat:after {
  content: "";
}

.fad.fa-circle-stop:after, .fa-duotone.fa-circle-stop:after, .fad.fa-stop-circle:after, .fa-duotone.fa-stop-circle:after {
  content: "";
}

.fad.fa-head-side-headphones:after, .fa-duotone.fa-head-side-headphones:after {
  content: "";
}

.fad.fa-phone-rotary:after, .fa-duotone.fa-phone-rotary:after {
  content: "";
}

.fad.fa-arrow-up-to-bracket:after, .fa-duotone.fa-arrow-up-to-bracket:after {
  content: "";
}

.fad.fa-compass-drafting:after, .fa-duotone.fa-compass-drafting:after, .fad.fa-drafting-compass:after, .fa-duotone.fa-drafting-compass:after {
  content: "";
}

.fad.fa-plate-wheat:after, .fa-duotone.fa-plate-wheat:after {
  content: "";
}

.fad.fa-calendar-circle-minus:after, .fa-duotone.fa-calendar-circle-minus:after {
  content: "";
}

.fad.fa-chopsticks:after, .fa-duotone.fa-chopsticks:after {
  content: "";
}

.fad.fa-car-wrench:after, .fa-duotone.fa-car-wrench:after, .fad.fa-car-mechanic:after, .fa-duotone.fa-car-mechanic:after {
  content: "";
}

.fad.fa-icicles:after, .fa-duotone.fa-icicles:after {
  content: "";
}

.fad.fa-person-shelter:after, .fa-duotone.fa-person-shelter:after {
  content: "";
}

.fad.fa-neuter:after, .fa-duotone.fa-neuter:after {
  content: "";
}

.fad.fa-id-badge:after, .fa-duotone.fa-id-badge:after {
  content: "";
}

.fad.fa-kazoo:after, .fa-duotone.fa-kazoo:after {
  content: "";
}

.fad.fa-marker:after, .fa-duotone.fa-marker:after {
  content: "";
}

.fad.fa-bin-bottles:after, .fa-duotone.fa-bin-bottles:after {
  content: "";
}

.fad.fa-face-laugh-beam:after, .fa-duotone.fa-face-laugh-beam:after, .fad.fa-laugh-beam:after, .fa-duotone.fa-laugh-beam:after {
  content: "";
}

.fad.fa-square-arrow-down-left:after, .fa-duotone.fa-square-arrow-down-left:after {
  content: "";
}

.fad.fa-battery-bolt:after, .fa-duotone.fa-battery-bolt:after {
  content: "";
}

.fad.fa-tree-large:after, .fa-duotone.fa-tree-large:after {
  content: "";
}

.fad.fa-helicopter-symbol:after, .fa-duotone.fa-helicopter-symbol:after {
  content: "";
}

.fad.fa-aperture:after, .fa-duotone.fa-aperture:after {
  content: "";
}

.fad.fa-universal-access:after, .fa-duotone.fa-universal-access:after {
  content: "";
}

.fad.fa-gear-complex:after, .fa-duotone.fa-gear-complex:after {
  content: "";
}

.fad.fa-file-magnifying-glass:after, .fa-duotone.fa-file-magnifying-glass:after, .fad.fa-file-search:after, .fa-duotone.fa-file-search:after {
  content: "";
}

.fad.fa-up-right:after, .fa-duotone.fa-up-right:after {
  content: "";
}

.fad.fa-circle-chevron-up:after, .fa-duotone.fa-circle-chevron-up:after, .fad.fa-chevron-circle-up:after, .fa-duotone.fa-chevron-circle-up:after {
  content: "";
}

.fad.fa-user-police:after, .fa-duotone.fa-user-police:after {
  content: "";
}

.fad.fa-lari-sign:after, .fa-duotone.fa-lari-sign:after {
  content: "";
}

.fad.fa-volcano:after, .fa-duotone.fa-volcano:after {
  content: "";
}

.fad.fa-teddy-bear:after, .fa-duotone.fa-teddy-bear:after {
  content: "";
}

.fad.fa-stocking:after, .fa-duotone.fa-stocking:after {
  content: "";
}

.fad.fa-person-walking-dashed-line-arrow-right:after, .fa-duotone.fa-person-walking-dashed-line-arrow-right:after {
  content: "";
}

.fad.fa-image-slash:after, .fa-duotone.fa-image-slash:after {
  content: "";
}

.fad.fa-mask-snorkel:after, .fa-duotone.fa-mask-snorkel:after {
  content: "";
}

.fad.fa-smoke:after, .fa-duotone.fa-smoke:after {
  content: "";
}

.fad.fa-sterling-sign:after, .fa-duotone.fa-sterling-sign:after, .fad.fa-gbp:after, .fa-duotone.fa-gbp:after, .fad.fa-pound-sign:after, .fa-duotone.fa-pound-sign:after {
  content: "";
}

.fad.fa-battery-exclamation:after, .fa-duotone.fa-battery-exclamation:after {
  content: "";
}

.fad.fa-viruses:after, .fa-duotone.fa-viruses:after {
  content: "";
}

.fad.fa-square-person-confined:after, .fa-duotone.fa-square-person-confined:after {
  content: "";
}

.fad.fa-user-tie:after, .fa-duotone.fa-user-tie:after {
  content: "";
}

.fad.fa-up-to-bracket:after, .fa-duotone.fa-up-to-bracket:after {
  content: "";
}

.fad.fa-arrow-down-long:after, .fa-duotone.fa-arrow-down-long:after, .fad.fa-long-arrow-down:after, .fa-duotone.fa-long-arrow-down:after {
  content: "";
}

.fad.fa-tent-arrow-down-to-line:after, .fa-duotone.fa-tent-arrow-down-to-line:after {
  content: "";
}

.fad.fa-certificate:after, .fa-duotone.fa-certificate:after {
  content: "";
}

.fad.fa-crystal-ball:after, .fa-duotone.fa-crystal-ball:after {
  content: "";
}

.fad.fa-reply-all:after, .fa-duotone.fa-reply-all:after, .fad.fa-mail-reply-all:after, .fa-duotone.fa-mail-reply-all:after {
  content: "";
}

.fad.fa-suitcase:after, .fa-duotone.fa-suitcase:after {
  content: "";
}

.fad.fa-person-skating:after, .fa-duotone.fa-person-skating:after, .fad.fa-skating:after, .fa-duotone.fa-skating:after {
  content: "";
}

.fad.fa-star-shooting:after, .fa-duotone.fa-star-shooting:after {
  content: "";
}

.fad.fa-binary-lock:after, .fa-duotone.fa-binary-lock:after {
  content: "";
}

.fad.fa-filter-circle-dollar:after, .fa-duotone.fa-filter-circle-dollar:after, .fad.fa-funnel-dollar:after, .fa-duotone.fa-funnel-dollar:after {
  content: "";
}

.fad.fa-camera-retro:after, .fa-duotone.fa-camera-retro:after {
  content: "";
}

.fad.fa-circle-arrow-down:after, .fa-duotone.fa-circle-arrow-down:after, .fad.fa-arrow-circle-down:after, .fa-duotone.fa-arrow-circle-down:after {
  content: "";
}

.fad.fa-comment-pen:after, .fa-duotone.fa-comment-pen:after, .fad.fa-comment-edit:after, .fa-duotone.fa-comment-edit:after {
  content: "";
}

.fad.fa-file-import:after, .fa-duotone.fa-file-import:after, .fad.fa-arrow-right-to-file:after, .fa-duotone.fa-arrow-right-to-file:after {
  content: "";
}

.fad.fa-banjo:after, .fa-duotone.fa-banjo:after {
  content: "";
}

.fad.fa-square-arrow-up-right:after, .fa-duotone.fa-square-arrow-up-right:after, .fad.fa-external-link-square:after, .fa-duotone.fa-external-link-square:after {
  content: "";
}

.fad.fa-light-emergency-on:after, .fa-duotone.fa-light-emergency-on:after {
  content: "";
}

.fad.fa-kerning:after, .fa-duotone.fa-kerning:after {
  content: "";
}

.fad.fa-box-open:after, .fa-duotone.fa-box-open:after {
  content: "";
}

.fad.fa-square-f:after, .fa-duotone.fa-square-f:after {
  content: "";
}

.fad.fa-scroll:after, .fa-duotone.fa-scroll:after {
  content: "";
}

.fad.fa-spa:after, .fa-duotone.fa-spa:after {
  content: "";
}

.fad.fa-arrow-left-from-line:after, .fa-duotone.fa-arrow-left-from-line:after, .fad.fa-arrow-from-right:after, .fa-duotone.fa-arrow-from-right:after {
  content: "";
}

.fad.fa-strawberry:after, .fa-duotone.fa-strawberry:after {
  content: "";
}

.fad.fa-location-pin-lock:after, .fa-duotone.fa-location-pin-lock:after {
  content: "";
}

.fad.fa-pause:after, .fa-duotone.fa-pause:after {
  content: "";
}

.fad.fa-clock-eight-thirty:after, .fa-duotone.fa-clock-eight-thirty:after {
  content: "";
}

.fad.fa-plane-engines:after, .fa-duotone.fa-plane-engines:after, .fad.fa-plane-alt:after, .fa-duotone.fa-plane-alt:after {
  content: "";
}

.fad.fa-hill-avalanche:after, .fa-duotone.fa-hill-avalanche:after {
  content: "";
}

.fad.fa-temperature-empty:after, .fa-duotone.fa-temperature-empty:after, .fad.fa-temperature-0:after, .fa-duotone.fa-temperature-0:after, .fad.fa-thermometer-0:after, .fa-duotone.fa-thermometer-0:after, .fad.fa-thermometer-empty:after, .fa-duotone.fa-thermometer-empty:after {
  content: "";
}

.fad.fa-bomb:after, .fa-duotone.fa-bomb:after {
  content: "";
}

.fad.fa-gauge-low:after, .fa-duotone.fa-gauge-low:after, .fad.fa-tachometer-alt-slow:after, .fa-duotone.fa-tachometer-alt-slow:after {
  content: "";
}

.fad.fa-registered:after, .fa-duotone.fa-registered:after {
  content: "";
}

.fad.fa-trash-can-plus:after, .fa-duotone.fa-trash-can-plus:after {
  content: "";
}

.fad.fa-address-card:after, .fa-duotone.fa-address-card:after, .fad.fa-contact-card:after, .fa-duotone.fa-contact-card:after, .fad.fa-vcard:after, .fa-duotone.fa-vcard:after {
  content: "";
}

.fad.fa-scale-unbalanced-flip:after, .fa-duotone.fa-scale-unbalanced-flip:after, .fad.fa-balance-scale-right:after, .fa-duotone.fa-balance-scale-right:after {
  content: "";
}

.fad.fa-globe-snow:after, .fa-duotone.fa-globe-snow:after {
  content: "";
}

.fad.fa-subscript:after, .fa-duotone.fa-subscript:after {
  content: "";
}

.fad.fa-diamond-turn-right:after, .fa-duotone.fa-diamond-turn-right:after, .fad.fa-directions:after, .fa-duotone.fa-directions:after {
  content: "";
}

.fad.fa-integral:after, .fa-duotone.fa-integral:after {
  content: "";
}

.fad.fa-burst:after, .fa-duotone.fa-burst:after {
  content: "";
}

.fad.fa-house-laptop:after, .fa-duotone.fa-house-laptop:after, .fad.fa-laptop-house:after, .fa-duotone.fa-laptop-house:after {
  content: "";
}

.fad.fa-face-tired:after, .fa-duotone.fa-face-tired:after, .fad.fa-tired:after, .fa-duotone.fa-tired:after {
  content: "";
}

.fad.fa-money-bills:after, .fa-duotone.fa-money-bills:after {
  content: "";
}

.fad.fa-blinds-raised:after, .fa-duotone.fa-blinds-raised:after {
  content: "";
}

.fad.fa-smog:after, .fa-duotone.fa-smog:after {
  content: "";
}

.fad.fa-ufo-beam:after, .fa-duotone.fa-ufo-beam:after {
  content: "";
}

.fad.fa-hydra:after, .fa-duotone.fa-hydra:after {
  content: "";
}

.fad.fa-circle-caret-up:after, .fa-duotone.fa-circle-caret-up:after, .fad.fa-caret-circle-up:after, .fa-duotone.fa-caret-circle-up:after {
  content: "";
}

.fad.fa-user-vneck-hair-long:after, .fa-duotone.fa-user-vneck-hair-long:after {
  content: "";
}

.fad.fa-square-a-lock:after, .fa-duotone.fa-square-a-lock:after {
  content: "";
}

.fad.fa-crutch:after, .fa-duotone.fa-crutch:after {
  content: "";
}

.fad.fa-gas-pump-slash:after, .fa-duotone.fa-gas-pump-slash:after {
  content: "";
}

.fad.fa-cloud-arrow-up:after, .fa-duotone.fa-cloud-arrow-up:after, .fad.fa-cloud-upload:after, .fa-duotone.fa-cloud-upload:after, .fad.fa-cloud-upload-alt:after, .fa-duotone.fa-cloud-upload-alt:after {
  content: "";
}

.fad.fa-palette:after, .fa-duotone.fa-palette:after {
  content: "";
}

.fad.fa-transporter-4:after, .fa-duotone.fa-transporter-4:after {
  content: "";
}

.fad.fa-chart-mixed-up-circle-currency:after, .fa-duotone.fa-chart-mixed-up-circle-currency:after {
  content: "";
}

.fad.fa-objects-align-right:after, .fa-duotone.fa-objects-align-right:after {
  content: "";
}

.fad.fa-arrows-turn-right:after, .fa-duotone.fa-arrows-turn-right:after {
  content: "";
}

.fad.fa-vest:after, .fa-duotone.fa-vest:after {
  content: "";
}

.fad.fa-pig:after, .fa-duotone.fa-pig:after {
  content: "";
}

.fad.fa-inbox-full:after, .fa-duotone.fa-inbox-full:after {
  content: "";
}

.fad.fa-circle-envelope:after, .fa-duotone.fa-circle-envelope:after, .fad.fa-envelope-circle:after, .fa-duotone.fa-envelope-circle:after {
  content: "";
}

.fad.fa-triangle-person-digging:after, .fa-duotone.fa-triangle-person-digging:after, .fad.fa-construction:after, .fa-duotone.fa-construction:after {
  content: "";
}

.fad.fa-ferry:after, .fa-duotone.fa-ferry:after {
  content: "";
}

.fad.fa-bullseye-arrow:after, .fa-duotone.fa-bullseye-arrow:after {
  content: "";
}

.fad.fa-arrows-down-to-people:after, .fa-duotone.fa-arrows-down-to-people:after {
  content: "";
}

.fad.fa-seedling:after, .fa-duotone.fa-seedling:after, .fad.fa-sprout:after, .fa-duotone.fa-sprout:after {
  content: "";
}

.fad.fa-clock-seven:after, .fa-duotone.fa-clock-seven:after {
  content: "";
}

.fad.fa-left-right:after, .fa-duotone.fa-left-right:after, .fad.fa-arrows-alt-h:after, .fa-duotone.fa-arrows-alt-h:after {
  content: "";
}

.fad.fa-boxes-packing:after, .fa-duotone.fa-boxes-packing:after {
  content: "";
}

.fad.fa-circle-arrow-left:after, .fa-duotone.fa-circle-arrow-left:after, .fad.fa-arrow-circle-left:after, .fa-duotone.fa-arrow-circle-left:after {
  content: "";
}

.fad.fa-flashlight:after, .fa-duotone.fa-flashlight:after {
  content: "";
}

.fad.fa-file-jpg:after, .fa-duotone.fa-file-jpg:after {
  content: "";
}

.fad.fa-group-arrows-rotate:after, .fa-duotone.fa-group-arrows-rotate:after {
  content: "";
}

.fad.fa-bowl-food:after, .fa-duotone.fa-bowl-food:after {
  content: "";
}

.fad.fa-square-9:after, .fa-duotone.fa-square-9:after {
  content: "";
}

.fad.fa-candy-cane:after, .fa-duotone.fa-candy-cane:after {
  content: "";
}

.fad.fa-arrow-down-wide-short:after, .fa-duotone.fa-arrow-down-wide-short:after, .fad.fa-sort-amount-asc:after, .fa-duotone.fa-sort-amount-asc:after, .fad.fa-sort-amount-down:after, .fa-duotone.fa-sort-amount-down:after {
  content: "";
}

.fad.fa-square-dollar:after, .fa-duotone.fa-square-dollar:after, .fad.fa-dollar-square:after, .fa-duotone.fa-dollar-square:after, .fad.fa-usd-square:after, .fa-duotone.fa-usd-square:after {
  content: "";
}

.fad.fa-phone-arrow-right:after, .fa-duotone.fa-phone-arrow-right:after {
  content: "";
}

.fad.fa-hand-holding-seedling:after, .fa-duotone.fa-hand-holding-seedling:after {
  content: "";
}

.fad.fa-message-check:after, .fa-duotone.fa-message-check:after, .fad.fa-comment-alt-check:after, .fa-duotone.fa-comment-alt-check:after {
  content: "";
}

.fad.fa-cloud-bolt:after, .fa-duotone.fa-cloud-bolt:after, .fad.fa-thunderstorm:after, .fa-duotone.fa-thunderstorm:after {
  content: "";
}

.fad.fa-chart-line-up-down:after, .fa-duotone.fa-chart-line-up-down:after {
  content: "";
}

.fad.fa-text-slash:after, .fa-duotone.fa-text-slash:after, .fad.fa-remove-format:after, .fa-duotone.fa-remove-format:after {
  content: "";
}

.fad.fa-watch:after, .fa-duotone.fa-watch:after {
  content: "";
}

.fad.fa-circle-down-left:after, .fa-duotone.fa-circle-down-left:after {
  content: "";
}

.fad.fa-text:after, .fa-duotone.fa-text:after {
  content: "";
}

.fad.fa-projector:after, .fa-duotone.fa-projector:after {
  content: "";
}

.fad.fa-face-smile-wink:after, .fa-duotone.fa-face-smile-wink:after, .fad.fa-smile-wink:after, .fa-duotone.fa-smile-wink:after {
  content: "";
}

.fad.fa-tombstone-blank:after, .fa-duotone.fa-tombstone-blank:after, .fad.fa-tombstone-alt:after, .fa-duotone.fa-tombstone-alt:after {
  content: "";
}

.fad.fa-chess-king-piece:after, .fa-duotone.fa-chess-king-piece:after, .fad.fa-chess-king-alt:after, .fa-duotone.fa-chess-king-alt:after {
  content: "";
}

.fad.fa-circle-6:after, .fa-duotone.fa-circle-6:after {
  content: "";
}

.fad.fa-waves-sine:after, .fa-duotone.fa-waves-sine:after {
  content: "";
}

.fad.fa-left:after, .fa-duotone.fa-left:after, .fad.fa-arrow-alt-left:after, .fa-duotone.fa-arrow-alt-left:after {
  content: "";
}

.fad.fa-file-word:after, .fa-duotone.fa-file-word:after {
  content: "";
}

.fad.fa-file-powerpoint:after, .fa-duotone.fa-file-powerpoint:after {
  content: "";
}

.fad.fa-square-down:after, .fa-duotone.fa-square-down:after, .fad.fa-arrow-alt-square-down:after, .fa-duotone.fa-arrow-alt-square-down:after {
  content: "";
}

.fad.fa-objects-align-center-vertical:after, .fa-duotone.fa-objects-align-center-vertical:after {
  content: "";
}

.fad.fa-arrows-left-right:after, .fa-duotone.fa-arrows-left-right:after, .fad.fa-arrows-h:after, .fa-duotone.fa-arrows-h:after {
  content: "";
}

.fad.fa-house-lock:after, .fa-duotone.fa-house-lock:after {
  content: "";
}

.fad.fa-cloud-arrow-down:after, .fa-duotone.fa-cloud-arrow-down:after, .fad.fa-cloud-download:after, .fa-duotone.fa-cloud-download:after, .fad.fa-cloud-download-alt:after, .fa-duotone.fa-cloud-download-alt:after {
  content: "";
}

.fad.fa-wreath:after, .fa-duotone.fa-wreath:after {
  content: "";
}

.fad.fa-children:after, .fa-duotone.fa-children:after {
  content: "";
}

.fad.fa-meter-droplet:after, .fa-duotone.fa-meter-droplet:after {
  content: "";
}

.fad.fa-chalkboard:after, .fa-duotone.fa-chalkboard:after, .fad.fa-blackboard:after, .fa-duotone.fa-blackboard:after {
  content: "";
}

.fad.fa-user-large-slash:after, .fa-duotone.fa-user-large-slash:after, .fad.fa-user-alt-slash:after, .fa-duotone.fa-user-alt-slash:after {
  content: "";
}

.fad.fa-signal-strong:after, .fa-duotone.fa-signal-strong:after, .fad.fa-signal-4:after, .fa-duotone.fa-signal-4:after {
  content: "";
}

.fad.fa-lollipop:after, .fa-duotone.fa-lollipop:after, .fad.fa-lollypop:after, .fa-duotone.fa-lollypop:after {
  content: "";
}

.fad.fa-list-tree:after, .fa-duotone.fa-list-tree:after {
  content: "";
}

.fad.fa-envelope-open:after, .fa-duotone.fa-envelope-open:after {
  content: "";
}

.fad.fa-draw-circle:after, .fa-duotone.fa-draw-circle:after {
  content: "";
}

.fad.fa-cat-space:after, .fa-duotone.fa-cat-space:after {
  content: "";
}

.fad.fa-handshake-simple-slash:after, .fa-duotone.fa-handshake-simple-slash:after, .fad.fa-handshake-alt-slash:after, .fa-duotone.fa-handshake-alt-slash:after {
  content: "";
}

.fad.fa-rabbit-running:after, .fa-duotone.fa-rabbit-running:after, .fad.fa-rabbit-fast:after, .fa-duotone.fa-rabbit-fast:after {
  content: "";
}

.fad.fa-memo-pad:after, .fa-duotone.fa-memo-pad:after {
  content: "";
}

.fad.fa-mattress-pillow:after, .fa-duotone.fa-mattress-pillow:after {
  content: "";
}

.fad.fa-alarm-plus:after, .fa-duotone.fa-alarm-plus:after {
  content: "";
}

.fad.fa-alicorn:after, .fa-duotone.fa-alicorn:after {
  content: "";
}

.fad.fa-comment-question:after, .fa-duotone.fa-comment-question:after {
  content: "";
}

.fad.fa-gingerbread-man:after, .fa-duotone.fa-gingerbread-man:after {
  content: "";
}

.fad.fa-guarani-sign:after, .fa-duotone.fa-guarani-sign:after {
  content: "";
}

.fad.fa-burger-fries:after, .fa-duotone.fa-burger-fries:after {
  content: "";
}

.fad.fa-mug-tea:after, .fa-duotone.fa-mug-tea:after {
  content: "";
}

.fad.fa-border-top:after, .fa-duotone.fa-border-top:after {
  content: "";
}

.fad.fa-arrows-rotate:after, .fa-duotone.fa-arrows-rotate:after, .fad.fa-refresh:after, .fa-duotone.fa-refresh:after, .fad.fa-sync:after, .fa-duotone.fa-sync:after {
  content: "";
}

.fad.fa-circle-book-open:after, .fa-duotone.fa-circle-book-open:after, .fad.fa-book-circle:after, .fa-duotone.fa-book-circle:after {
  content: "";
}

.fad.fa-arrows-to-dotted-line:after, .fa-duotone.fa-arrows-to-dotted-line:after {
  content: "";
}

.fad.fa-fire-extinguisher:after, .fa-duotone.fa-fire-extinguisher:after {
  content: "";
}

.fad.fa-magnifying-glass-arrows-rotate:after, .fa-duotone.fa-magnifying-glass-arrows-rotate:after {
  content: "";
}

.fad.fa-garage-open:after, .fa-duotone.fa-garage-open:after {
  content: "";
}

.fad.fa-shelves-empty:after, .fa-duotone.fa-shelves-empty:after {
  content: "";
}

.fad.fa-cruzeiro-sign:after, .fa-duotone.fa-cruzeiro-sign:after {
  content: "";
}

.fad.fa-watch-apple:after, .fa-duotone.fa-watch-apple:after {
  content: "";
}

.fad.fa-watch-calculator:after, .fa-duotone.fa-watch-calculator:after {
  content: "";
}

.fad.fa-list-dropdown:after, .fa-duotone.fa-list-dropdown:after {
  content: "";
}

.fad.fa-cabinet-filing:after, .fa-duotone.fa-cabinet-filing:after {
  content: "";
}

.fad.fa-burger-soda:after, .fa-duotone.fa-burger-soda:after {
  content: "";
}

.fad.fa-square-arrow-up:after, .fa-duotone.fa-square-arrow-up:after, .fad.fa-arrow-square-up:after, .fa-duotone.fa-arrow-square-up:after {
  content: "";
}

.fad.fa-greater-than-equal:after, .fa-duotone.fa-greater-than-equal:after {
  content: "";
}

.fad.fa-pallet-box:after, .fa-duotone.fa-pallet-box:after {
  content: "";
}

.fad.fa-face-confounded:after, .fa-duotone.fa-face-confounded:after {
  content: "";
}

.fad.fa-shield-halved:after, .fa-duotone.fa-shield-halved:after, .fad.fa-shield-alt:after, .fa-duotone.fa-shield-alt:after {
  content: "";
}

.fad.fa-truck-plow:after, .fa-duotone.fa-truck-plow:after {
  content: "";
}

.fad.fa-book-atlas:after, .fa-duotone.fa-book-atlas:after, .fad.fa-atlas:after, .fa-duotone.fa-atlas:after {
  content: "";
}

.fad.fa-virus:after, .fa-duotone.fa-virus:after {
  content: "";
}

.fad.fa-grid-round-2:after, .fa-duotone.fa-grid-round-2:after {
  content: "";
}

.fad.fa-comment-middle-top:after, .fa-duotone.fa-comment-middle-top:after {
  content: "";
}

.fad.fa-wave:after, .fa-duotone.fa-wave:after {
  content: "";
}

.fad.fa-envelope-circle-check:after, .fa-duotone.fa-envelope-circle-check:after {
  content: "";
}

.fad.fa-layer-group:after, .fa-duotone.fa-layer-group:after {
  content: "";
}

.fad.fa-restroom-simple:after, .fa-duotone.fa-restroom-simple:after {
  content: "";
}

.fad.fa-arrows-to-dot:after, .fa-duotone.fa-arrows-to-dot:after {
  content: "";
}

.fad.fa-border-outer:after, .fa-duotone.fa-border-outer:after {
  content: "";
}

.fad.fa-hashtag-lock:after, .fa-duotone.fa-hashtag-lock:after {
  content: "";
}

.fad.fa-clock-two-thirty:after, .fa-duotone.fa-clock-two-thirty:after {
  content: "";
}

.fad.fa-archway:after, .fa-duotone.fa-archway:after {
  content: "";
}

.fad.fa-heart-circle-check:after, .fa-duotone.fa-heart-circle-check:after {
  content: "";
}

.fad.fa-house-chimney-crack:after, .fa-duotone.fa-house-chimney-crack:after, .fad.fa-house-damage:after, .fa-duotone.fa-house-damage:after {
  content: "";
}

.fad.fa-file-zipper:after, .fa-duotone.fa-file-zipper:after, .fad.fa-file-archive:after, .fa-duotone.fa-file-archive:after {
  content: "";
}

.fad.fa-ticket-perforated:after, .fa-duotone.fa-ticket-perforated:after {
  content: "";
}

.fad.fa-heart-half:after, .fa-duotone.fa-heart-half:after {
  content: "";
}

.fad.fa-comment-check:after, .fa-duotone.fa-comment-check:after {
  content: "";
}

.fad.fa-square:after, .fa-duotone.fa-square:after {
  content: "";
}

.fad.fa-memo:after, .fa-duotone.fa-memo:after {
  content: "";
}

.fad.fa-martini-glass-empty:after, .fa-duotone.fa-martini-glass-empty:after, .fad.fa-glass-martini:after, .fa-duotone.fa-glass-martini:after {
  content: "";
}

.fad.fa-couch:after, .fa-duotone.fa-couch:after {
  content: "";
}

.fad.fa-cedi-sign:after, .fa-duotone.fa-cedi-sign:after {
  content: "";
}

.fad.fa-italic:after, .fa-duotone.fa-italic:after {
  content: "";
}

.fad.fa-glass-citrus:after, .fa-duotone.fa-glass-citrus:after {
  content: "";
}

.fad.fa-calendar-lines-pen:after, .fa-duotone.fa-calendar-lines-pen:after {
  content: "";
}

.fad.fa-table-cells-column-lock:after, .fa-duotone.fa-table-cells-column-lock:after {
  content: "";
}

.fad.fa-church:after, .fa-duotone.fa-church:after {
  content: "";
}

.fad.fa-person-snowmobiling:after, .fa-duotone.fa-person-snowmobiling:after, .fad.fa-snowmobile:after, .fa-duotone.fa-snowmobile:after {
  content: "";
}

.fad.fa-face-hushed:after, .fa-duotone.fa-face-hushed:after {
  content: "";
}

.fad.fa-comments-dollar:after, .fa-duotone.fa-comments-dollar:after {
  content: "";
}

.fad.fa-tickets-simple:after, .fa-duotone.fa-tickets-simple:after {
  content: "";
}

.fad.fa-pickaxe:after, .fa-duotone.fa-pickaxe:after {
  content: "";
}

.fad.fa-link-simple-slash:after, .fa-duotone.fa-link-simple-slash:after {
  content: "";
}

.fad.fa-democrat:after, .fa-duotone.fa-democrat:after {
  content: "";
}

.fad.fa-face-confused:after, .fa-duotone.fa-face-confused:after {
  content: "";
}

.fad.fa-pinball:after, .fa-duotone.fa-pinball:after {
  content: "";
}

.fad.fa-z:after, .fa-duotone.fa-z:after {
  content: "ZZ";
}

.fad.fa-person-skiing:after, .fa-duotone.fa-person-skiing:after, .fad.fa-skiing:after, .fa-duotone.fa-skiing:after {
  content: "";
}

.fad.fa-deer:after, .fa-duotone.fa-deer:after {
  content: "";
}

.fad.fa-input-pipe:after, .fa-duotone.fa-input-pipe:after {
  content: "";
}

.fad.fa-road-lock:after, .fa-duotone.fa-road-lock:after {
  content: "";
}

.fad.fa-a:after, .fa-duotone.fa-a:after {
  content: "AA";
}

.fad.fa-bookmark-slash:after, .fa-duotone.fa-bookmark-slash:after {
  content: "";
}

.fad.fa-temperature-arrow-down:after, .fa-duotone.fa-temperature-arrow-down:after, .fad.fa-temperature-down:after, .fa-duotone.fa-temperature-down:after {
  content: "";
}

.fad.fa-mace:after, .fa-duotone.fa-mace:after {
  content: "";
}

.fad.fa-feather-pointed:after, .fa-duotone.fa-feather-pointed:after, .fad.fa-feather-alt:after, .fa-duotone.fa-feather-alt:after {
  content: "";
}

.fad.fa-sausage:after, .fa-duotone.fa-sausage:after {
  content: "";
}

.fad.fa-trash-can-clock:after, .fa-duotone.fa-trash-can-clock:after {
  content: "";
}

.fad.fa-p:after, .fa-duotone.fa-p:after {
  content: "PP";
}

.fad.fa-broom-wide:after, .fa-duotone.fa-broom-wide:after {
  content: "";
}

.fad.fa-snowflake:after, .fa-duotone.fa-snowflake:after {
  content: "";
}

.fad.fa-stomach:after, .fa-duotone.fa-stomach:after {
  content: "";
}

.fad.fa-newspaper:after, .fa-duotone.fa-newspaper:after {
  content: "";
}

.fad.fa-rectangle-ad:after, .fa-duotone.fa-rectangle-ad:after, .fad.fa-ad:after, .fa-duotone.fa-ad:after {
  content: "";
}

.fad.fa-guitar-electric:after, .fa-duotone.fa-guitar-electric:after {
  content: "";
}

.fad.fa-arrow-turn-down-right:after, .fa-duotone.fa-arrow-turn-down-right:after {
  content: "";
}

.fad.fa-moon-cloud:after, .fa-duotone.fa-moon-cloud:after {
  content: "";
}

.fad.fa-bread-slice-butter:after, .fa-duotone.fa-bread-slice-butter:after {
  content: "";
}

.fad.fa-circle-arrow-right:after, .fa-duotone.fa-circle-arrow-right:after, .fad.fa-arrow-circle-right:after, .fa-duotone.fa-arrow-circle-right:after {
  content: "";
}

.fad.fa-user-group-crown:after, .fa-duotone.fa-user-group-crown:after, .fad.fa-users-crown:after, .fa-duotone.fa-users-crown:after {
  content: "";
}

.fad.fa-circle-i:after, .fa-duotone.fa-circle-i:after {
  content: "";
}

.fad.fa-toilet-paper-check:after, .fa-duotone.fa-toilet-paper-check:after {
  content: "";
}

.fad.fa-filter-circle-xmark:after, .fa-duotone.fa-filter-circle-xmark:after {
  content: "";
}

.fad.fa-locust:after, .fa-duotone.fa-locust:after {
  content: "";
}

.fad.fa-sort:after, .fa-duotone.fa-sort:after, .fad.fa-unsorted:after, .fa-duotone.fa-unsorted:after {
  content: "";
}

.fad.fa-list-ol:after, .fa-duotone.fa-list-ol:after, .fad.fa-list-1-2:after, .fa-duotone.fa-list-1-2:after, .fad.fa-list-numeric:after, .fa-duotone.fa-list-numeric:after {
  content: "";
}

.fad.fa-chart-waterfall:after, .fa-duotone.fa-chart-waterfall:after {
  content: "";
}

.fad.fa-sparkle:after, .fa-duotone.fa-sparkle:after {
  content: "";
}

.fad.fa-face-party:after, .fa-duotone.fa-face-party:after {
  content: "";
}

.fad.fa-kidneys:after, .fa-duotone.fa-kidneys:after {
  content: "";
}

.fad.fa-wifi-exclamation:after, .fa-duotone.fa-wifi-exclamation:after {
  content: "";
}

.fad.fa-chart-network:after, .fa-duotone.fa-chart-network:after {
  content: "";
}

.fad.fa-person-dress-burst:after, .fa-duotone.fa-person-dress-burst:after {
  content: "";
}

.fad.fa-dice-d4:after, .fa-duotone.fa-dice-d4:after {
  content: "";
}

.fad.fa-money-check-dollar:after, .fa-duotone.fa-money-check-dollar:after, .fad.fa-money-check-alt:after, .fa-duotone.fa-money-check-alt:after {
  content: "";
}

.fad.fa-vector-square:after, .fa-duotone.fa-vector-square:after {
  content: "";
}

.fad.fa-bread-slice:after, .fa-duotone.fa-bread-slice:after {
  content: "";
}

.fad.fa-language:after, .fa-duotone.fa-language:after {
  content: "";
}

.fad.fa-wheat-awn-slash:after, .fa-duotone.fa-wheat-awn-slash:after {
  content: "";
}

.fad.fa-face-kiss-wink-heart:after, .fa-duotone.fa-face-kiss-wink-heart:after, .fad.fa-kiss-wink-heart:after, .fa-duotone.fa-kiss-wink-heart:after {
  content: "";
}

.fad.fa-dagger:after, .fa-duotone.fa-dagger:after {
  content: "";
}

.fad.fa-podium:after, .fa-duotone.fa-podium:after {
  content: "";
}

.fad.fa-diamonds-4:after, .fa-duotone.fa-diamonds-4:after {
  content: "";
}

.fad.fa-memo-circle-check:after, .fa-duotone.fa-memo-circle-check:after {
  content: "";
}

.fad.fa-route-highway:after, .fa-duotone.fa-route-highway:after {
  content: "";
}

.fad.fa-down-to-line:after, .fa-duotone.fa-down-to-line:after, .fad.fa-arrow-alt-to-bottom:after, .fa-duotone.fa-arrow-alt-to-bottom:after {
  content: "";
}

.fad.fa-filter:after, .fa-duotone.fa-filter:after {
  content: "";
}

.fad.fa-square-g:after, .fa-duotone.fa-square-g:after {
  content: "";
}

.fad.fa-circle-phone:after, .fa-duotone.fa-circle-phone:after, .fad.fa-phone-circle:after, .fa-duotone.fa-phone-circle:after {
  content: "";
}

.fad.fa-clipboard-prescription:after, .fa-duotone.fa-clipboard-prescription:after {
  content: "";
}

.fad.fa-user-nurse-hair:after, .fa-duotone.fa-user-nurse-hair:after {
  content: "";
}

.fad.fa-question:after, .fa-duotone.fa-question:after {
  content: "??";
}

.fad.fa-file-signature:after, .fa-duotone.fa-file-signature:after {
  content: "";
}

.fad.fa-toggle-large-on:after, .fa-duotone.fa-toggle-large-on:after {
  content: "";
}

.fad.fa-up-down-left-right:after, .fa-duotone.fa-up-down-left-right:after, .fad.fa-arrows-alt:after, .fa-duotone.fa-arrows-alt:after {
  content: "";
}

.fad.fa-dryer-heat:after, .fa-duotone.fa-dryer-heat:after, .fad.fa-dryer-alt:after, .fa-duotone.fa-dryer-alt:after {
  content: "";
}

.fad.fa-house-chimney-user:after, .fa-duotone.fa-house-chimney-user:after {
  content: "";
}

.fad.fa-hand-holding-heart:after, .fa-duotone.fa-hand-holding-heart:after {
  content: "";
}

.fad.fa-arrow-up-small-big:after, .fa-duotone.fa-arrow-up-small-big:after, .fad.fa-sort-size-up-alt:after, .fa-duotone.fa-sort-size-up-alt:after {
  content: "";
}

.fad.fa-train-track:after, .fa-duotone.fa-train-track:after {
  content: "";
}

.fad.fa-puzzle-piece:after, .fa-duotone.fa-puzzle-piece:after {
  content: "";
}

.fad.fa-money-check:after, .fa-duotone.fa-money-check:after {
  content: "";
}

.fad.fa-star-half-stroke:after, .fa-duotone.fa-star-half-stroke:after, .fad.fa-star-half-alt:after, .fa-duotone.fa-star-half-alt:after {
  content: "";
}

.fad.fa-file-exclamation:after, .fa-duotone.fa-file-exclamation:after {
  content: "";
}

.fad.fa-code:after, .fa-duotone.fa-code:after {
  content: "";
}

.fad.fa-whiskey-glass:after, .fa-duotone.fa-whiskey-glass:after, .fad.fa-glass-whiskey:after, .fa-duotone.fa-glass-whiskey:after {
  content: "";
}

.fad.fa-moon-stars:after, .fa-duotone.fa-moon-stars:after {
  content: "";
}

.fad.fa-building-circle-exclamation:after, .fa-duotone.fa-building-circle-exclamation:after {
  content: "";
}

.fad.fa-clothes-hanger:after, .fa-duotone.fa-clothes-hanger:after {
  content: "";
}

.fad.fa-mobile-notch:after, .fa-duotone.fa-mobile-notch:after, .fad.fa-mobile-iphone:after, .fa-duotone.fa-mobile-iphone:after {
  content: "";
}

.fad.fa-magnifying-glass-chart:after, .fa-duotone.fa-magnifying-glass-chart:after {
  content: "";
}

.fad.fa-arrow-up-right-from-square:after, .fa-duotone.fa-arrow-up-right-from-square:after, .fad.fa-external-link:after, .fa-duotone.fa-external-link:after {
  content: "";
}

.fad.fa-cubes-stacked:after, .fa-duotone.fa-cubes-stacked:after {
  content: "";
}

.fad.fa-images-user:after, .fa-duotone.fa-images-user:after {
  content: "";
}

.fad.fa-won-sign:after, .fa-duotone.fa-won-sign:after, .fad.fa-krw:after, .fa-duotone.fa-krw:after, .fad.fa-won:after, .fa-duotone.fa-won:after {
  content: "";
}

.fad.fa-image-polaroid-user:after, .fa-duotone.fa-image-polaroid-user:after {
  content: "";
}

.fad.fa-virus-covid:after, .fa-duotone.fa-virus-covid:after {
  content: "";
}

.fad.fa-square-ellipsis:after, .fa-duotone.fa-square-ellipsis:after {
  content: "";
}

.fad.fa-pie:after, .fa-duotone.fa-pie:after {
  content: "";
}

.fad.fa-chess-knight-piece:after, .fa-duotone.fa-chess-knight-piece:after, .fad.fa-chess-knight-alt:after, .fa-duotone.fa-chess-knight-alt:after {
  content: "";
}

.fad.fa-austral-sign:after, .fa-duotone.fa-austral-sign:after {
  content: "";
}

.fad.fa-cloud-plus:after, .fa-duotone.fa-cloud-plus:after {
  content: "";
}

.fad.fa-f:after, .fa-duotone.fa-f:after {
  content: "FF";
}

.fad.fa-leaf:after, .fa-duotone.fa-leaf:after {
  content: "";
}

.fad.fa-bed-bunk:after, .fa-duotone.fa-bed-bunk:after {
  content: "";
}

.fad.fa-road:after, .fa-duotone.fa-road:after {
  content: "";
}

.fad.fa-taxi:after, .fa-duotone.fa-taxi:after, .fad.fa-cab:after, .fa-duotone.fa-cab:after {
  content: "";
}

.fad.fa-person-circle-plus:after, .fa-duotone.fa-person-circle-plus:after {
  content: "";
}

.fad.fa-chart-pie:after, .fa-duotone.fa-chart-pie:after, .fad.fa-pie-chart:after, .fa-duotone.fa-pie-chart:after {
  content: "";
}

.fad.fa-bolt-lightning:after, .fa-duotone.fa-bolt-lightning:after {
  content: "";
}

.fad.fa-clock-eight:after, .fa-duotone.fa-clock-eight:after {
  content: "";
}

.fad.fa-sack-xmark:after, .fa-duotone.fa-sack-xmark:after {
  content: "";
}

.fad.fa-file-xls:after, .fa-duotone.fa-file-xls:after {
  content: "";
}

.fad.fa-file-excel:after, .fa-duotone.fa-file-excel:after {
  content: "";
}

.fad.fa-file-contract:after, .fa-duotone.fa-file-contract:after {
  content: "";
}

.fad.fa-fish-fins:after, .fa-duotone.fa-fish-fins:after {
  content: "";
}

.fad.fa-circle-q:after, .fa-duotone.fa-circle-q:after {
  content: "";
}

.fad.fa-building-flag:after, .fa-duotone.fa-building-flag:after {
  content: "";
}

.fad.fa-face-grin-beam:after, .fa-duotone.fa-face-grin-beam:after, .fad.fa-grin-beam:after, .fa-duotone.fa-grin-beam:after {
  content: "";
}

.fad.fa-object-ungroup:after, .fa-duotone.fa-object-ungroup:after {
  content: "";
}

.fad.fa-face-disguise:after, .fa-duotone.fa-face-disguise:after {
  content: "";
}

.fad.fa-circle-arrow-down-right:after, .fa-duotone.fa-circle-arrow-down-right:after {
  content: "";
}

.fad.fa-alien-8bit:after, .fa-duotone.fa-alien-8bit:after, .fad.fa-alien-monster:after, .fa-duotone.fa-alien-monster:after {
  content: "";
}

.fad.fa-hand-point-ribbon:after, .fa-duotone.fa-hand-point-ribbon:after {
  content: "";
}

.fad.fa-poop:after, .fa-duotone.fa-poop:after {
  content: "";
}

.fad.fa-object-exclude:after, .fa-duotone.fa-object-exclude:after {
  content: "";
}

.fad.fa-telescope:after, .fa-duotone.fa-telescope:after {
  content: "";
}

.fad.fa-location-pin:after, .fa-duotone.fa-location-pin:after, .fad.fa-map-marker:after, .fa-duotone.fa-map-marker:after {
  content: "";
}

.fad.fa-square-list:after, .fa-duotone.fa-square-list:after {
  content: "";
}

.fad.fa-kaaba:after, .fa-duotone.fa-kaaba:after {
  content: "";
}

.fad.fa-toilet-paper:after, .fa-duotone.fa-toilet-paper:after {
  content: "";
}

.fad.fa-helmet-safety:after, .fa-duotone.fa-helmet-safety:after, .fad.fa-hard-hat:after, .fa-duotone.fa-hard-hat:after, .fad.fa-hat-hard:after, .fa-duotone.fa-hat-hard:after {
  content: "";
}

.fad.fa-comment-code:after, .fa-duotone.fa-comment-code:after {
  content: "";
}

.fad.fa-sim-cards:after, .fa-duotone.fa-sim-cards:after {
  content: "";
}

.fad.fa-starship:after, .fa-duotone.fa-starship:after {
  content: "";
}

.fad.fa-eject:after, .fa-duotone.fa-eject:after {
  content: "";
}

.fad.fa-circle-right:after, .fa-duotone.fa-circle-right:after, .fad.fa-arrow-alt-circle-right:after, .fa-duotone.fa-arrow-alt-circle-right:after {
  content: "";
}

.fad.fa-plane-circle-check:after, .fa-duotone.fa-plane-circle-check:after {
  content: "";
}

.fad.fa-seal:after, .fa-duotone.fa-seal:after {
  content: "";
}

.fad.fa-user-cowboy:after, .fa-duotone.fa-user-cowboy:after {
  content: "";
}

.fad.fa-hexagon-vertical-nft:after, .fa-duotone.fa-hexagon-vertical-nft:after {
  content: "";
}

.fad.fa-face-rolling-eyes:after, .fa-duotone.fa-face-rolling-eyes:after, .fad.fa-meh-rolling-eyes:after, .fa-duotone.fa-meh-rolling-eyes:after {
  content: "";
}

.fad.fa-bread-loaf:after, .fa-duotone.fa-bread-loaf:after {
  content: "";
}

.fad.fa-rings-wedding:after, .fa-duotone.fa-rings-wedding:after {
  content: "";
}

.fad.fa-object-group:after, .fa-duotone.fa-object-group:after {
  content: "";
}

.fad.fa-french-fries:after, .fa-duotone.fa-french-fries:after {
  content: "";
}

.fad.fa-chart-line:after, .fa-duotone.fa-chart-line:after, .fad.fa-line-chart:after, .fa-duotone.fa-line-chart:after {
  content: "";
}

.fad.fa-calendar-arrow-down:after, .fa-duotone.fa-calendar-arrow-down:after, .fad.fa-calendar-download:after, .fa-duotone.fa-calendar-download:after {
  content: "";
}

.fad.fa-send-back:after, .fa-duotone.fa-send-back:after {
  content: "";
}

.fad.fa-mask-ventilator:after, .fa-duotone.fa-mask-ventilator:after {
  content: "";
}

.fad.fa-tickets:after, .fa-duotone.fa-tickets:after {
  content: "";
}

.fad.fa-signature-lock:after, .fa-duotone.fa-signature-lock:after {
  content: "";
}

.fad.fa-arrow-right:after, .fa-duotone.fa-arrow-right:after {
  content: "";
}

.fad.fa-signs-post:after, .fa-duotone.fa-signs-post:after, .fad.fa-map-signs:after, .fa-duotone.fa-map-signs:after {
  content: "";
}

.fad.fa-octagon-plus:after, .fa-duotone.fa-octagon-plus:after, .fad.fa-plus-octagon:after, .fa-duotone.fa-plus-octagon:after {
  content: "";
}

.fad.fa-cash-register:after, .fa-duotone.fa-cash-register:after {
  content: "";
}

.fad.fa-person-circle-question:after, .fa-duotone.fa-person-circle-question:after {
  content: "";
}

.fad.fa-melon-slice:after, .fa-duotone.fa-melon-slice:after {
  content: "";
}

.fad.fa-space-station-moon:after, .fa-duotone.fa-space-station-moon:after {
  content: "";
}

.fad.fa-message-smile:after, .fa-duotone.fa-message-smile:after, .fad.fa-comment-alt-smile:after, .fa-duotone.fa-comment-alt-smile:after {
  content: "";
}

.fad.fa-cup-straw:after, .fa-duotone.fa-cup-straw:after {
  content: "";
}

.fad.fa-left-from-line:after, .fa-duotone.fa-left-from-line:after, .fad.fa-arrow-alt-from-right:after, .fa-duotone.fa-arrow-alt-from-right:after {
  content: "";
}

.fad.fa-h:after, .fa-duotone.fa-h:after {
  content: "HH";
}

.fad.fa-basket-shopping-simple:after, .fa-duotone.fa-basket-shopping-simple:after, .fad.fa-shopping-basket-alt:after, .fa-duotone.fa-shopping-basket-alt:after {
  content: "";
}

.fad.fa-hands-holding-heart:after, .fa-duotone.fa-hands-holding-heart:after, .fad.fa-hands-heart:after, .fa-duotone.fa-hands-heart:after {
  content: "";
}

.fad.fa-clock-nine:after, .fa-duotone.fa-clock-nine:after {
  content: "";
}

.fad.fa-hammer-brush:after, .fa-duotone.fa-hammer-brush:after {
  content: "";
}

.fad.fa-tarp:after, .fa-duotone.fa-tarp:after {
  content: "";
}

.fad.fa-face-sleepy:after, .fa-duotone.fa-face-sleepy:after {
  content: "";
}

.fad.fa-hand-horns:after, .fa-duotone.fa-hand-horns:after {
  content: "";
}

.fad.fa-screwdriver-wrench:after, .fa-duotone.fa-screwdriver-wrench:after, .fad.fa-tools:after, .fa-duotone.fa-tools:after {
  content: "";
}

.fad.fa-arrows-to-eye:after, .fa-duotone.fa-arrows-to-eye:after {
  content: "";
}

.fad.fa-circle-three-quarters:after, .fa-duotone.fa-circle-three-quarters:after {
  content: "";
}

.fad.fa-trophy-star:after, .fa-duotone.fa-trophy-star:after, .fad.fa-trophy-alt:after, .fa-duotone.fa-trophy-alt:after {
  content: "";
}

.fad.fa-plug-circle-bolt:after, .fa-duotone.fa-plug-circle-bolt:after {
  content: "";
}

.fad.fa-face-thermometer:after, .fa-duotone.fa-face-thermometer:after {
  content: "";
}

.fad.fa-grid-round-4:after, .fa-duotone.fa-grid-round-4:after {
  content: "";
}

.fad.fa-sign-posts-wrench:after, .fa-duotone.fa-sign-posts-wrench:after {
  content: "";
}

.fad.fa-shirt-running:after, .fa-duotone.fa-shirt-running:after {
  content: "";
}

.fad.fa-book-circle-arrow-up:after, .fa-duotone.fa-book-circle-arrow-up:after {
  content: "";
}

.fad.fa-face-nauseated:after, .fa-duotone.fa-face-nauseated:after {
  content: "";
}

.fad.fa-heart:after, .fa-duotone.fa-heart:after {
  content: "";
}

.fad.fa-file-chart-pie:after, .fa-duotone.fa-file-chart-pie:after {
  content: "";
}

.fad.fa-mars-and-venus:after, .fa-duotone.fa-mars-and-venus:after {
  content: "";
}

.fad.fa-house-user:after, .fa-duotone.fa-house-user:after, .fad.fa-home-user:after, .fa-duotone.fa-home-user:after {
  content: "";
}

.fad.fa-circle-arrow-down-left:after, .fa-duotone.fa-circle-arrow-down-left:after {
  content: "";
}

.fad.fa-dumpster-fire:after, .fa-duotone.fa-dumpster-fire:after {
  content: "";
}

.fad.fa-hexagon-minus:after, .fa-duotone.fa-hexagon-minus:after, .fad.fa-minus-hexagon:after, .fa-duotone.fa-minus-hexagon:after {
  content: "";
}

.fad.fa-left-to-line:after, .fa-duotone.fa-left-to-line:after, .fad.fa-arrow-alt-to-left:after, .fa-duotone.fa-arrow-alt-to-left:after {
  content: "";
}

.fad.fa-house-crack:after, .fa-duotone.fa-house-crack:after {
  content: "";
}

.fad.fa-paw-simple:after, .fa-duotone.fa-paw-simple:after, .fad.fa-paw-alt:after, .fa-duotone.fa-paw-alt:after {
  content: "";
}

.fad.fa-arrow-left-long-to-line:after, .fa-duotone.fa-arrow-left-long-to-line:after {
  content: "";
}

.fad.fa-brackets-round:after, .fa-duotone.fa-brackets-round:after, .fad.fa-parentheses:after, .fa-duotone.fa-parentheses:after {
  content: "";
}

.fad.fa-martini-glass-citrus:after, .fa-duotone.fa-martini-glass-citrus:after, .fad.fa-cocktail:after, .fa-duotone.fa-cocktail:after {
  content: "";
}

.fad.fa-user-shakespeare:after, .fa-duotone.fa-user-shakespeare:after {
  content: "";
}

.fad.fa-arrow-right-to-arc:after, .fa-duotone.fa-arrow-right-to-arc:after {
  content: "";
}

.fad.fa-face-surprise:after, .fa-duotone.fa-face-surprise:after, .fad.fa-surprise:after, .fa-duotone.fa-surprise:after {
  content: "";
}

.fad.fa-bottle-water:after, .fa-duotone.fa-bottle-water:after {
  content: "";
}

.fad.fa-circle-pause:after, .fa-duotone.fa-circle-pause:after, .fad.fa-pause-circle:after, .fa-duotone.fa-pause-circle:after {
  content: "";
}

.fad.fa-gauge-circle-plus:after, .fa-duotone.fa-gauge-circle-plus:after {
  content: "";
}

.fad.fa-folders:after, .fa-duotone.fa-folders:after {
  content: "";
}

.fad.fa-angel:after, .fa-duotone.fa-angel:after {
  content: "";
}

.fad.fa-value-absolute:after, .fa-duotone.fa-value-absolute:after {
  content: "";
}

.fad.fa-rabbit:after, .fa-duotone.fa-rabbit:after {
  content: "";
}

.fad.fa-toilet-paper-slash:after, .fa-duotone.fa-toilet-paper-slash:after {
  content: "";
}

.fad.fa-circle-euro:after, .fa-duotone.fa-circle-euro:after {
  content: "";
}

.fad.fa-apple-whole:after, .fa-duotone.fa-apple-whole:after, .fad.fa-apple-alt:after, .fa-duotone.fa-apple-alt:after {
  content: "";
}

.fad.fa-kitchen-set:after, .fa-duotone.fa-kitchen-set:after {
  content: "";
}

.fad.fa-diamond-half:after, .fa-duotone.fa-diamond-half:after {
  content: "";
}

.fad.fa-lock-keyhole:after, .fa-duotone.fa-lock-keyhole:after, .fad.fa-lock-alt:after, .fa-duotone.fa-lock-alt:after {
  content: "";
}

.fad.fa-r:after, .fa-duotone.fa-r:after {
  content: "RR";
}

.fad.fa-temperature-quarter:after, .fa-duotone.fa-temperature-quarter:after, .fad.fa-temperature-1:after, .fa-duotone.fa-temperature-1:after, .fad.fa-thermometer-1:after, .fa-duotone.fa-thermometer-1:after, .fad.fa-thermometer-quarter:after, .fa-duotone.fa-thermometer-quarter:after {
  content: "";
}

.fad.fa-square-info:after, .fa-duotone.fa-square-info:after, .fad.fa-info-square:after, .fa-duotone.fa-info-square:after {
  content: "";
}

.fad.fa-wifi-slash:after, .fa-duotone.fa-wifi-slash:after {
  content: "";
}

.fad.fa-toilet-paper-xmark:after, .fa-duotone.fa-toilet-paper-xmark:after {
  content: "";
}

.fad.fa-hands-holding-dollar:after, .fa-duotone.fa-hands-holding-dollar:after, .fad.fa-hands-usd:after, .fa-duotone.fa-hands-usd:after {
  content: "";
}

.fad.fa-cube:after, .fa-duotone.fa-cube:after {
  content: "";
}

.fad.fa-arrow-down-triangle-square:after, .fa-duotone.fa-arrow-down-triangle-square:after, .fad.fa-sort-shapes-down:after, .fa-duotone.fa-sort-shapes-down:after {
  content: "";
}

.fad.fa-bitcoin-sign:after, .fa-duotone.fa-bitcoin-sign:after {
  content: "";
}

.fad.fa-shutters:after, .fa-duotone.fa-shutters:after {
  content: "";
}

.fad.fa-shield-dog:after, .fa-duotone.fa-shield-dog:after {
  content: "";
}

.fad.fa-solar-panel:after, .fa-duotone.fa-solar-panel:after {
  content: "";
}

.fad.fa-lock-open:after, .fa-duotone.fa-lock-open:after {
  content: "";
}

.fad.fa-table-tree:after, .fa-duotone.fa-table-tree:after {
  content: "";
}

.fad.fa-house-chimney-heart:after, .fa-duotone.fa-house-chimney-heart:after {
  content: "";
}

.fad.fa-tally-3:after, .fa-duotone.fa-tally-3:after {
  content: "";
}

.fad.fa-elevator:after, .fa-duotone.fa-elevator:after {
  content: "";
}

.fad.fa-money-bill-transfer:after, .fa-duotone.fa-money-bill-transfer:after {
  content: "";
}

.fad.fa-money-bill-trend-up:after, .fa-duotone.fa-money-bill-trend-up:after {
  content: "";
}

.fad.fa-house-flood-water-circle-arrow-right:after, .fa-duotone.fa-house-flood-water-circle-arrow-right:after {
  content: "";
}

.fad.fa-square-poll-horizontal:after, .fa-duotone.fa-square-poll-horizontal:after, .fad.fa-poll-h:after, .fa-duotone.fa-poll-h:after {
  content: "";
}

.fad.fa-circle:after, .fa-duotone.fa-circle:after {
  content: "";
}

.fad.fa-left-to-bracket:after, .fa-duotone.fa-left-to-bracket:after {
  content: "";
}

.fad.fa-cart-circle-exclamation:after, .fa-duotone.fa-cart-circle-exclamation:after {
  content: "";
}

.fad.fa-sword:after, .fa-duotone.fa-sword:after {
  content: "";
}

.fad.fa-backward-fast:after, .fa-duotone.fa-backward-fast:after, .fad.fa-fast-backward:after, .fa-duotone.fa-fast-backward:after {
  content: "";
}

.fad.fa-recycle:after, .fa-duotone.fa-recycle:after {
  content: "";
}

.fad.fa-user-astronaut:after, .fa-duotone.fa-user-astronaut:after {
  content: "";
}

.fad.fa-interrobang:after, .fa-duotone.fa-interrobang:after {
  content: "";
}

.fad.fa-plane-slash:after, .fa-duotone.fa-plane-slash:after {
  content: "";
}

.fad.fa-circle-dashed:after, .fa-duotone.fa-circle-dashed:after {
  content: "";
}

.fad.fa-trademark:after, .fa-duotone.fa-trademark:after {
  content: "";
}

.fad.fa-basketball:after, .fa-duotone.fa-basketball:after, .fad.fa-basketball-ball:after, .fa-duotone.fa-basketball-ball:after {
  content: "";
}

.fad.fa-fork-knife:after, .fa-duotone.fa-fork-knife:after, .fad.fa-utensils-alt:after, .fa-duotone.fa-utensils-alt:after {
  content: "";
}

.fad.fa-satellite-dish:after, .fa-duotone.fa-satellite-dish:after {
  content: "";
}

.fad.fa-badge-check:after, .fa-duotone.fa-badge-check:after {
  content: "";
}

.fad.fa-circle-up:after, .fa-duotone.fa-circle-up:after, .fad.fa-arrow-alt-circle-up:after, .fa-duotone.fa-arrow-alt-circle-up:after {
  content: "";
}

.fad.fa-slider:after, .fa-duotone.fa-slider:after {
  content: "";
}

.fad.fa-mobile-screen-button:after, .fa-duotone.fa-mobile-screen-button:after, .fad.fa-mobile-alt:after, .fa-duotone.fa-mobile-alt:after {
  content: "";
}

.fad.fa-clock-one-thirty:after, .fa-duotone.fa-clock-one-thirty:after {
  content: "";
}

.fad.fa-inbox-out:after, .fa-duotone.fa-inbox-out:after, .fad.fa-inbox-arrow-up:after, .fa-duotone.fa-inbox-arrow-up:after {
  content: "";
}

.fad.fa-cloud-slash:after, .fa-duotone.fa-cloud-slash:after {
  content: "";
}

.fad.fa-volume-high:after, .fa-duotone.fa-volume-high:after, .fad.fa-volume-up:after, .fa-duotone.fa-volume-up:after {
  content: "";
}

.fad.fa-users-rays:after, .fa-duotone.fa-users-rays:after {
  content: "";
}

.fad.fa-wallet:after, .fa-duotone.fa-wallet:after {
  content: "";
}

.fad.fa-octagon-check:after, .fa-duotone.fa-octagon-check:after {
  content: "";
}

.fad.fa-flatbread-stuffed:after, .fa-duotone.fa-flatbread-stuffed:after {
  content: "";
}

.fad.fa-clipboard-check:after, .fa-duotone.fa-clipboard-check:after {
  content: "";
}

.fad.fa-cart-circle-plus:after, .fa-duotone.fa-cart-circle-plus:after {
  content: "";
}

.fad.fa-truck-clock:after, .fa-duotone.fa-truck-clock:after, .fad.fa-shipping-timed:after, .fa-duotone.fa-shipping-timed:after {
  content: "";
}

.fad.fa-pool-8-ball:after, .fa-duotone.fa-pool-8-ball:after {
  content: "";
}

.fad.fa-file-audio:after, .fa-duotone.fa-file-audio:after {
  content: "";
}

.fad.fa-turn-down-left:after, .fa-duotone.fa-turn-down-left:after {
  content: "";
}

.fad.fa-lock-hashtag:after, .fa-duotone.fa-lock-hashtag:after {
  content: "";
}

.fad.fa-chart-radar:after, .fa-duotone.fa-chart-radar:after {
  content: "";
}

.fad.fa-staff:after, .fa-duotone.fa-staff:after {
  content: "";
}

.fad.fa-burger:after, .fa-duotone.fa-burger:after, .fad.fa-hamburger:after, .fa-duotone.fa-hamburger:after {
  content: "";
}

.fad.fa-utility-pole:after, .fa-duotone.fa-utility-pole:after {
  content: "";
}

.fad.fa-transporter-6:after, .fa-duotone.fa-transporter-6:after {
  content: "";
}

.fad.fa-arrow-turn-left:after, .fa-duotone.fa-arrow-turn-left:after {
  content: "";
}

.fad.fa-wrench:after, .fa-duotone.fa-wrench:after {
  content: "";
}

.fad.fa-bugs:after, .fa-duotone.fa-bugs:after {
  content: "";
}

.fad.fa-vector-polygon:after, .fa-duotone.fa-vector-polygon:after {
  content: "";
}

.fad.fa-diagram-nested:after, .fa-duotone.fa-diagram-nested:after {
  content: "";
}

.fad.fa-rupee-sign:after, .fa-duotone.fa-rupee-sign:after, .fad.fa-rupee:after, .fa-duotone.fa-rupee:after {
  content: "";
}

.fad.fa-file-image:after, .fa-duotone.fa-file-image:after {
  content: "";
}

.fad.fa-circle-question:after, .fa-duotone.fa-circle-question:after, .fad.fa-question-circle:after, .fa-duotone.fa-question-circle:after {
  content: "";
}

.fad.fa-tickets-perforated:after, .fa-duotone.fa-tickets-perforated:after {
  content: "";
}

.fad.fa-image-user:after, .fa-duotone.fa-image-user:after {
  content: "";
}

.fad.fa-buoy:after, .fa-duotone.fa-buoy:after {
  content: "";
}

.fad.fa-plane-departure:after, .fa-duotone.fa-plane-departure:after {
  content: "";
}

.fad.fa-handshake-slash:after, .fa-duotone.fa-handshake-slash:after {
  content: "";
}

.fad.fa-book-bookmark:after, .fa-duotone.fa-book-bookmark:after {
  content: "";
}

.fad.fa-border-center-h:after, .fa-duotone.fa-border-center-h:after {
  content: "";
}

.fad.fa-can-food:after, .fa-duotone.fa-can-food:after {
  content: "";
}

.fad.fa-typewriter:after, .fa-duotone.fa-typewriter:after {
  content: "";
}

.fad.fa-arrow-right-from-arc:after, .fa-duotone.fa-arrow-right-from-arc:after {
  content: "";
}

.fad.fa-circle-k:after, .fa-duotone.fa-circle-k:after {
  content: "";
}

.fad.fa-face-hand-over-mouth:after, .fa-duotone.fa-face-hand-over-mouth:after {
  content: "";
}

.fad.fa-popcorn:after, .fa-duotone.fa-popcorn:after {
  content: "";
}

.fad.fa-house-water:after, .fa-duotone.fa-house-water:after, .fad.fa-house-flood:after, .fa-duotone.fa-house-flood:after {
  content: "";
}

.fad.fa-object-subtract:after, .fa-duotone.fa-object-subtract:after {
  content: "";
}

.fad.fa-code-branch:after, .fa-duotone.fa-code-branch:after {
  content: "";
}

.fad.fa-warehouse-full:after, .fa-duotone.fa-warehouse-full:after, .fad.fa-warehouse-alt:after, .fa-duotone.fa-warehouse-alt:after {
  content: "";
}

.fad.fa-hat-cowboy:after, .fa-duotone.fa-hat-cowboy:after {
  content: "";
}

.fad.fa-bridge:after, .fa-duotone.fa-bridge:after {
  content: "";
}

.fad.fa-phone-flip:after, .fa-duotone.fa-phone-flip:after, .fad.fa-phone-alt:after, .fa-duotone.fa-phone-alt:after {
  content: "";
}

.fad.fa-arrow-down-from-dotted-line:after, .fa-duotone.fa-arrow-down-from-dotted-line:after {
  content: "";
}

.fad.fa-file-doc:after, .fa-duotone.fa-file-doc:after {
  content: "";
}

.fad.fa-square-quarters:after, .fa-duotone.fa-square-quarters:after {
  content: "";
}

.fad.fa-truck-front:after, .fa-duotone.fa-truck-front:after {
  content: "";
}

.fad.fa-cat:after, .fa-duotone.fa-cat:after {
  content: "";
}

.fad.fa-trash-xmark:after, .fa-duotone.fa-trash-xmark:after {
  content: "";
}

.fad.fa-circle-caret-left:after, .fa-duotone.fa-circle-caret-left:after, .fad.fa-caret-circle-left:after, .fa-duotone.fa-caret-circle-left:after {
  content: "";
}

.fad.fa-files:after, .fa-duotone.fa-files:after {
  content: "";
}

.fad.fa-anchor-circle-exclamation:after, .fa-duotone.fa-anchor-circle-exclamation:after {
  content: "";
}

.fad.fa-face-clouds:after, .fa-duotone.fa-face-clouds:after {
  content: "";
}

.fad.fa-user-crown:after, .fa-duotone.fa-user-crown:after {
  content: "";
}

.fad.fa-basket-shopping-plus:after, .fa-duotone.fa-basket-shopping-plus:after {
  content: "";
}

.fad.fa-truck-field:after, .fa-duotone.fa-truck-field:after {
  content: "";
}

.fad.fa-route:after, .fa-duotone.fa-route:after {
  content: "";
}

.fad.fa-cart-circle-check:after, .fa-duotone.fa-cart-circle-check:after {
  content: "";
}

.fad.fa-clipboard-question:after, .fa-duotone.fa-clipboard-question:after {
  content: "";
}

.fad.fa-panorama:after, .fa-duotone.fa-panorama:after {
  content: "";
}

.fad.fa-comment-medical:after, .fa-duotone.fa-comment-medical:after {
  content: "";
}

.fad.fa-teeth-open:after, .fa-duotone.fa-teeth-open:after {
  content: "";
}

.fad.fa-user-tie-hair-long:after, .fa-duotone.fa-user-tie-hair-long:after {
  content: "";
}

.fad.fa-file-circle-minus:after, .fa-duotone.fa-file-circle-minus:after {
  content: "";
}

.fad.fa-head-side-medical:after, .fa-duotone.fa-head-side-medical:after {
  content: "";
}

.fad.fa-arrow-turn-right:after, .fa-duotone.fa-arrow-turn-right:after {
  content: "";
}

.fad.fa-tags:after, .fa-duotone.fa-tags:after {
  content: "";
}

.fad.fa-wine-glass:after, .fa-duotone.fa-wine-glass:after {
  content: "";
}

.fad.fa-forward-fast:after, .fa-duotone.fa-forward-fast:after, .fad.fa-fast-forward:after, .fa-duotone.fa-fast-forward:after {
  content: "";
}

.fad.fa-face-meh-blank:after, .fa-duotone.fa-face-meh-blank:after, .fad.fa-meh-blank:after, .fa-duotone.fa-meh-blank:after {
  content: "";
}

.fad.fa-user-robot:after, .fa-duotone.fa-user-robot:after {
  content: "";
}

.fad.fa-square-parking:after, .fa-duotone.fa-square-parking:after, .fad.fa-parking:after, .fa-duotone.fa-parking:after {
  content: "";
}

.fad.fa-card-diamond:after, .fa-duotone.fa-card-diamond:after {
  content: "";
}

.fad.fa-face-zipper:after, .fa-duotone.fa-face-zipper:after {
  content: "";
}

.fad.fa-face-raised-eyebrow:after, .fa-duotone.fa-face-raised-eyebrow:after {
  content: "";
}

.fad.fa-house-signal:after, .fa-duotone.fa-house-signal:after {
  content: "";
}

.fad.fa-square-chevron-up:after, .fa-duotone.fa-square-chevron-up:after, .fad.fa-chevron-square-up:after, .fa-duotone.fa-chevron-square-up:after {
  content: "";
}

.fad.fa-bars-progress:after, .fa-duotone.fa-bars-progress:after, .fad.fa-tasks-alt:after, .fa-duotone.fa-tasks-alt:after {
  content: "";
}

.fad.fa-faucet-drip:after, .fa-duotone.fa-faucet-drip:after {
  content: "";
}

.fad.fa-arrows-to-line:after, .fa-duotone.fa-arrows-to-line:after {
  content: "";
}

.fad.fa-dolphin:after, .fa-duotone.fa-dolphin:after {
  content: "";
}

.fad.fa-arrow-up-right:after, .fa-duotone.fa-arrow-up-right:after {
  content: "";
}

.fad.fa-circle-r:after, .fa-duotone.fa-circle-r:after {
  content: "";
}

.fad.fa-cart-flatbed:after, .fa-duotone.fa-cart-flatbed:after, .fad.fa-dolly-flatbed:after, .fa-duotone.fa-dolly-flatbed:after {
  content: "";
}

.fad.fa-ban-smoking:after, .fa-duotone.fa-ban-smoking:after, .fad.fa-smoking-ban:after, .fa-duotone.fa-smoking-ban:after {
  content: "";
}

.fad.fa-circle-sort-up:after, .fa-duotone.fa-circle-sort-up:after, .fad.fa-sort-circle-up:after, .fa-duotone.fa-sort-circle-up:after {
  content: "";
}

.fad.fa-terminal:after, .fa-duotone.fa-terminal:after {
  content: "";
}

.fad.fa-mobile-button:after, .fa-duotone.fa-mobile-button:after {
  content: "";
}

.fad.fa-house-medical-flag:after, .fa-duotone.fa-house-medical-flag:after {
  content: "";
}

.fad.fa-basket-shopping:after, .fa-duotone.fa-basket-shopping:after, .fad.fa-shopping-basket:after, .fa-duotone.fa-shopping-basket:after {
  content: "";
}

.fad.fa-tape:after, .fa-duotone.fa-tape:after {
  content: "";
}

.fad.fa-chestnut:after, .fa-duotone.fa-chestnut:after {
  content: "";
}

.fad.fa-bus-simple:after, .fa-duotone.fa-bus-simple:after, .fad.fa-bus-alt:after, .fa-duotone.fa-bus-alt:after {
  content: "";
}

.fad.fa-eye:after, .fa-duotone.fa-eye:after {
  content: "";
}

.fad.fa-face-sad-cry:after, .fa-duotone.fa-face-sad-cry:after, .fad.fa-sad-cry:after, .fa-duotone.fa-sad-cry:after {
  content: "";
}

.fad.fa-heat:after, .fa-duotone.fa-heat:after {
  content: "";
}

.fad.fa-ticket-airline:after, .fa-duotone.fa-ticket-airline:after, .fad.fa-ticket-perforated-plane:after, .fa-duotone.fa-ticket-perforated-plane:after, .fad.fa-ticket-plane:after, .fa-duotone.fa-ticket-plane:after {
  content: "";
}

.fad.fa-boot-heeled:after, .fa-duotone.fa-boot-heeled:after {
  content: "";
}

.fad.fa-arrows-minimize:after, .fa-duotone.fa-arrows-minimize:after, .fad.fa-compress-arrows:after, .fa-duotone.fa-compress-arrows:after {
  content: "";
}

.fad.fa-audio-description:after, .fa-duotone.fa-audio-description:after {
  content: "";
}

.fad.fa-person-military-to-person:after, .fa-duotone.fa-person-military-to-person:after {
  content: "";
}

.fad.fa-file-shield:after, .fa-duotone.fa-file-shield:after {
  content: "";
}

.fad.fa-hexagon:after, .fa-duotone.fa-hexagon:after {
  content: "";
}

.fad.fa-manhole:after, .fa-duotone.fa-manhole:after {
  content: "";
}

.fad.fa-user-slash:after, .fa-duotone.fa-user-slash:after {
  content: "";
}

.fad.fa-pen:after, .fa-duotone.fa-pen:after {
  content: "";
}

.fad.fa-tower-observation:after, .fa-duotone.fa-tower-observation:after {
  content: "";
}

.fad.fa-floppy-disks:after, .fa-duotone.fa-floppy-disks:after {
  content: "";
}

.fad.fa-toilet-paper-blank-under:after, .fa-duotone.fa-toilet-paper-blank-under:after, .fad.fa-toilet-paper-reverse-alt:after, .fa-duotone.fa-toilet-paper-reverse-alt:after {
  content: "";
}

.fad.fa-file-code:after, .fa-duotone.fa-file-code:after {
  content: "";
}

.fad.fa-signal:after, .fa-duotone.fa-signal:after, .fad.fa-signal-5:after, .fa-duotone.fa-signal-5:after, .fad.fa-signal-perfect:after, .fa-duotone.fa-signal-perfect:after {
  content: "";
}

.fad.fa-pump:after, .fa-duotone.fa-pump:after {
  content: "";
}

.fad.fa-bus:after, .fa-duotone.fa-bus:after {
  content: "";
}

.fad.fa-heart-circle-xmark:after, .fa-duotone.fa-heart-circle-xmark:after {
  content: "";
}

.fad.fa-arrow-up-left-from-circle:after, .fa-duotone.fa-arrow-up-left-from-circle:after {
  content: "";
}

.fad.fa-house-chimney:after, .fa-duotone.fa-house-chimney:after, .fad.fa-home-lg:after, .fa-duotone.fa-home-lg:after {
  content: "";
}

.fad.fa-window-maximize:after, .fa-duotone.fa-window-maximize:after {
  content: "";
}

.fad.fa-dryer:after, .fa-duotone.fa-dryer:after {
  content: "";
}

.fad.fa-face-frown:after, .fa-duotone.fa-face-frown:after, .fad.fa-frown:after, .fa-duotone.fa-frown:after {
  content: "";
}

.fad.fa-chess-bishop-piece:after, .fa-duotone.fa-chess-bishop-piece:after, .fad.fa-chess-bishop-alt:after, .fa-duotone.fa-chess-bishop-alt:after {
  content: "";
}

.fad.fa-shirt-tank-top:after, .fa-duotone.fa-shirt-tank-top:after {
  content: "";
}

.fad.fa-diploma:after, .fa-duotone.fa-diploma:after, .fad.fa-scroll-ribbon:after, .fa-duotone.fa-scroll-ribbon:after {
  content: "";
}

.fad.fa-screencast:after, .fa-duotone.fa-screencast:after {
  content: "";
}

.fad.fa-walker:after, .fa-duotone.fa-walker:after {
  content: "";
}

.fad.fa-prescription:after, .fa-duotone.fa-prescription:after {
  content: "";
}

.fad.fa-shop:after, .fa-duotone.fa-shop:after, .fad.fa-store-alt:after, .fa-duotone.fa-store-alt:after {
  content: "";
}

.fad.fa-floppy-disk:after, .fa-duotone.fa-floppy-disk:after, .fad.fa-save:after, .fa-duotone.fa-save:after {
  content: "";
}

.fad.fa-vihara:after, .fa-duotone.fa-vihara:after {
  content: "";
}

.fad.fa-face-kiss-closed-eyes:after, .fa-duotone.fa-face-kiss-closed-eyes:after {
  content: "";
}

.fad.fa-scale-unbalanced:after, .fa-duotone.fa-scale-unbalanced:after, .fad.fa-balance-scale-left:after, .fa-duotone.fa-balance-scale-left:after {
  content: "";
}

.fad.fa-file-user:after, .fa-duotone.fa-file-user:after {
  content: "";
}

.fad.fa-user-police-tie:after, .fa-duotone.fa-user-police-tie:after {
  content: "";
}

.fad.fa-face-tongue-money:after, .fa-duotone.fa-face-tongue-money:after {
  content: "";
}

.fad.fa-tennis-ball:after, .fa-duotone.fa-tennis-ball:after {
  content: "";
}

.fad.fa-square-l:after, .fa-duotone.fa-square-l:after {
  content: "";
}

.fad.fa-sort-up:after, .fa-duotone.fa-sort-up:after, .fad.fa-sort-asc:after, .fa-duotone.fa-sort-asc:after {
  content: "";
}

.fad.fa-calendar-arrow-up:after, .fa-duotone.fa-calendar-arrow-up:after, .fad.fa-calendar-upload:after, .fa-duotone.fa-calendar-upload:after {
  content: "";
}

.fad.fa-comment-dots:after, .fa-duotone.fa-comment-dots:after, .fad.fa-commenting:after, .fa-duotone.fa-commenting:after {
  content: "";
}

.fad.fa-plant-wilt:after, .fa-duotone.fa-plant-wilt:after {
  content: "";
}

.fad.fa-scarf:after, .fa-duotone.fa-scarf:after {
  content: "";
}

.fad.fa-album-circle-plus:after, .fa-duotone.fa-album-circle-plus:after {
  content: "";
}

.fad.fa-user-nurse-hair-long:after, .fa-duotone.fa-user-nurse-hair-long:after {
  content: "";
}

.fad.fa-diamond:after, .fa-duotone.fa-diamond:after {
  content: "";
}

.fad.fa-square-left:after, .fa-duotone.fa-square-left:after, .fad.fa-arrow-alt-square-left:after, .fa-duotone.fa-arrow-alt-square-left:after {
  content: "";
}

.fad.fa-face-grin-squint:after, .fa-duotone.fa-face-grin-squint:after, .fad.fa-grin-squint:after, .fa-duotone.fa-grin-squint:after {
  content: "";
}

.fad.fa-circle-ellipsis-vertical:after, .fa-duotone.fa-circle-ellipsis-vertical:after {
  content: "";
}

.fad.fa-hand-holding-dollar:after, .fa-duotone.fa-hand-holding-dollar:after, .fad.fa-hand-holding-usd:after, .fa-duotone.fa-hand-holding-usd:after {
  content: "";
}

.fad.fa-grid-dividers:after, .fa-duotone.fa-grid-dividers:after {
  content: "";
}

.fad.fa-bacterium:after, .fa-duotone.fa-bacterium:after {
  content: "";
}

.fad.fa-hand-pointer:after, .fa-duotone.fa-hand-pointer:after {
  content: "";
}

.fad.fa-drum-steelpan:after, .fa-duotone.fa-drum-steelpan:after {
  content: "";
}

.fad.fa-hand-scissors:after, .fa-duotone.fa-hand-scissors:after {
  content: "";
}

.fad.fa-hands-praying:after, .fa-duotone.fa-hands-praying:after, .fad.fa-praying-hands:after, .fa-duotone.fa-praying-hands:after {
  content: "";
}

.fad.fa-face-pensive:after, .fa-duotone.fa-face-pensive:after {
  content: "";
}

.fad.fa-user-music:after, .fa-duotone.fa-user-music:after {
  content: "";
}

.fad.fa-arrow-rotate-right:after, .fa-duotone.fa-arrow-rotate-right:after, .fad.fa-arrow-right-rotate:after, .fa-duotone.fa-arrow-right-rotate:after, .fad.fa-arrow-rotate-forward:after, .fa-duotone.fa-arrow-rotate-forward:after, .fad.fa-redo:after, .fa-duotone.fa-redo:after {
  content: "";
}

.fad.fa-messages-dollar:after, .fa-duotone.fa-messages-dollar:after, .fad.fa-comments-alt-dollar:after, .fa-duotone.fa-comments-alt-dollar:after {
  content: "";
}

.fad.fa-sensor-on:after, .fa-duotone.fa-sensor-on:after {
  content: "";
}

.fad.fa-balloon:after, .fa-duotone.fa-balloon:after {
  content: "";
}

.fad.fa-biohazard:after, .fa-duotone.fa-biohazard:after {
  content: "";
}

.fad.fa-chess-queen-piece:after, .fa-duotone.fa-chess-queen-piece:after, .fad.fa-chess-queen-alt:after, .fa-duotone.fa-chess-queen-alt:after {
  content: "";
}

.fad.fa-location-crosshairs:after, .fa-duotone.fa-location-crosshairs:after, .fad.fa-location:after, .fa-duotone.fa-location:after {
  content: "";
}

.fad.fa-mars-double:after, .fa-duotone.fa-mars-double:after {
  content: "";
}

.fad.fa-left-from-bracket:after, .fa-duotone.fa-left-from-bracket:after {
  content: "";
}

.fad.fa-house-person-leave:after, .fa-duotone.fa-house-person-leave:after, .fad.fa-house-leave:after, .fa-duotone.fa-house-leave:after, .fad.fa-house-person-depart:after, .fa-duotone.fa-house-person-depart:after {
  content: "";
}

.fad.fa-ruler-triangle:after, .fa-duotone.fa-ruler-triangle:after {
  content: "";
}

.fad.fa-card-club:after, .fa-duotone.fa-card-club:after {
  content: "";
}

.fad.fa-child-dress:after, .fa-duotone.fa-child-dress:after {
  content: "";
}

.fad.fa-users-between-lines:after, .fa-duotone.fa-users-between-lines:after {
  content: "";
}

.fad.fa-lungs-virus:after, .fa-duotone.fa-lungs-virus:after {
  content: "";
}

.fad.fa-spinner-third:after, .fa-duotone.fa-spinner-third:after {
  content: "";
}

.fad.fa-face-grin-tears:after, .fa-duotone.fa-face-grin-tears:after, .fad.fa-grin-tears:after, .fa-duotone.fa-grin-tears:after {
  content: "";
}

.fad.fa-phone:after, .fa-duotone.fa-phone:after {
  content: "";
}

.fad.fa-computer-mouse-scrollwheel:after, .fa-duotone.fa-computer-mouse-scrollwheel:after, .fad.fa-mouse-alt:after, .fa-duotone.fa-mouse-alt:after {
  content: "";
}

.fad.fa-calendar-xmark:after, .fa-duotone.fa-calendar-xmark:after, .fad.fa-calendar-times:after, .fa-duotone.fa-calendar-times:after {
  content: "";
}

.fad.fa-child-reaching:after, .fa-duotone.fa-child-reaching:after {
  content: "";
}

.fad.fa-table-layout:after, .fa-duotone.fa-table-layout:after {
  content: "";
}

.fad.fa-narwhal:after, .fa-duotone.fa-narwhal:after {
  content: "";
}

.fad.fa-ramp-loading:after, .fa-duotone.fa-ramp-loading:after {
  content: "";
}

.fad.fa-calendar-circle-plus:after, .fa-duotone.fa-calendar-circle-plus:after {
  content: "";
}

.fad.fa-toothbrush:after, .fa-duotone.fa-toothbrush:after {
  content: "";
}

.fad.fa-border-inner:after, .fa-duotone.fa-border-inner:after {
  content: "";
}

.fad.fa-paw-claws:after, .fa-duotone.fa-paw-claws:after {
  content: "";
}

.fad.fa-kiwi-fruit:after, .fa-duotone.fa-kiwi-fruit:after {
  content: "";
}

.fad.fa-traffic-light-slow:after, .fa-duotone.fa-traffic-light-slow:after {
  content: "";
}

.fad.fa-rectangle-code:after, .fa-duotone.fa-rectangle-code:after {
  content: "";
}

.fad.fa-head-side-virus:after, .fa-duotone.fa-head-side-virus:after {
  content: "";
}

.fad.fa-keyboard-brightness:after, .fa-duotone.fa-keyboard-brightness:after {
  content: "";
}

.fad.fa-books-medical:after, .fa-duotone.fa-books-medical:after {
  content: "";
}

.fad.fa-lightbulb-slash:after, .fa-duotone.fa-lightbulb-slash:after {
  content: "";
}

.fad.fa-house-blank:after, .fa-duotone.fa-house-blank:after, .fad.fa-home-blank:after, .fa-duotone.fa-home-blank:after {
  content: "";
}

.fad.fa-square-5:after, .fa-duotone.fa-square-5:after {
  content: "";
}

.fad.fa-square-heart:after, .fa-duotone.fa-square-heart:after, .fad.fa-heart-square:after, .fa-duotone.fa-heart-square:after {
  content: "";
}

.fad.fa-puzzle:after, .fa-duotone.fa-puzzle:after {
  content: "";
}

.fad.fa-user-gear:after, .fa-duotone.fa-user-gear:after, .fad.fa-user-cog:after, .fa-duotone.fa-user-cog:after {
  content: "";
}

.fad.fa-pipe-circle-check:after, .fa-duotone.fa-pipe-circle-check:after {
  content: "";
}

.fad.fa-arrow-up-1-9:after, .fa-duotone.fa-arrow-up-1-9:after, .fad.fa-sort-numeric-up:after, .fa-duotone.fa-sort-numeric-up:after {
  content: "";
}

.fad.fa-octagon-exclamation:after, .fa-duotone.fa-octagon-exclamation:after {
  content: "";
}

.fad.fa-dial-low:after, .fa-duotone.fa-dial-low:after {
  content: "";
}

.fad.fa-door-closed:after, .fa-duotone.fa-door-closed:after {
  content: "";
}

.fad.fa-laptop-mobile:after, .fa-duotone.fa-laptop-mobile:after, .fad.fa-phone-laptop:after, .fa-duotone.fa-phone-laptop:after {
  content: "";
}

.fad.fa-conveyor-belt-boxes:after, .fa-duotone.fa-conveyor-belt-boxes:after, .fad.fa-conveyor-belt-alt:after, .fa-duotone.fa-conveyor-belt-alt:after {
  content: "";
}

.fad.fa-shield-virus:after, .fa-duotone.fa-shield-virus:after {
  content: "";
}

.fad.fa-starfighter-twin-ion-engine-advanced:after, .fa-duotone.fa-starfighter-twin-ion-engine-advanced:after, .fad.fa-starfighter-alt-advanced:after, .fa-duotone.fa-starfighter-alt-advanced:after {
  content: "";
}

.fad.fa-dice-six:after, .fa-duotone.fa-dice-six:after {
  content: "";
}

.fad.fa-starfighter-twin-ion-engine:after, .fa-duotone.fa-starfighter-twin-ion-engine:after, .fad.fa-starfighter-alt:after, .fa-duotone.fa-starfighter-alt:after {
  content: "";
}

.fad.fa-rocket-launch:after, .fa-duotone.fa-rocket-launch:after {
  content: "";
}

.fad.fa-mosquito-net:after, .fa-duotone.fa-mosquito-net:after {
  content: "";
}

.fad.fa-vent-damper:after, .fa-duotone.fa-vent-damper:after {
  content: "";
}

.fad.fa-bridge-water:after, .fa-duotone.fa-bridge-water:after {
  content: "";
}

.fad.fa-ban-bug:after, .fa-duotone.fa-ban-bug:after, .fad.fa-debug:after, .fa-duotone.fa-debug:after {
  content: "";
}

.fad.fa-person-booth:after, .fa-duotone.fa-person-booth:after {
  content: "";
}

.fad.fa-text-width:after, .fa-duotone.fa-text-width:after {
  content: "";
}

.fad.fa-garage-car:after, .fa-duotone.fa-garage-car:after {
  content: "";
}

.fad.fa-square-kanban:after, .fa-duotone.fa-square-kanban:after {
  content: "";
}

.fad.fa-hat-wizard:after, .fa-duotone.fa-hat-wizard:after {
  content: "";
}

.fad.fa-chart-kanban:after, .fa-duotone.fa-chart-kanban:after {
  content: "";
}

.fad.fa-pen-fancy:after, .fa-duotone.fa-pen-fancy:after {
  content: "";
}

.fad.fa-coffee-pot:after, .fa-duotone.fa-coffee-pot:after {
  content: "";
}

.fad.fa-mouse-field:after, .fa-duotone.fa-mouse-field:after {
  content: "";
}

.fad.fa-person-digging:after, .fa-duotone.fa-person-digging:after, .fad.fa-digging:after, .fa-duotone.fa-digging:after {
  content: "";
}

.fad.fa-shower-down:after, .fa-duotone.fa-shower-down:after, .fad.fa-shower-alt:after, .fa-duotone.fa-shower-alt:after {
  content: "";
}

.fad.fa-box-circle-check:after, .fa-duotone.fa-box-circle-check:after {
  content: "";
}

.fad.fa-brightness:after, .fa-duotone.fa-brightness:after {
  content: "";
}

.fad.fa-car-side-bolt:after, .fa-duotone.fa-car-side-bolt:after {
  content: "";
}

.fad.fa-file-xml:after, .fa-duotone.fa-file-xml:after {
  content: "";
}

.fad.fa-ornament:after, .fa-duotone.fa-ornament:after {
  content: "";
}

.fad.fa-phone-arrow-down-left:after, .fa-duotone.fa-phone-arrow-down-left:after, .fad.fa-phone-arrow-down:after, .fa-duotone.fa-phone-arrow-down:after, .fad.fa-phone-incoming:after, .fa-duotone.fa-phone-incoming:after {
  content: "";
}

.fad.fa-cloud-word:after, .fa-duotone.fa-cloud-word:after {
  content: "";
}

.fad.fa-hand-fingers-crossed:after, .fa-duotone.fa-hand-fingers-crossed:after {
  content: "";
}

.fad.fa-trash:after, .fa-duotone.fa-trash:after {
  content: "";
}

.fad.fa-gauge-simple:after, .fa-duotone.fa-gauge-simple:after, .fad.fa-gauge-simple-med:after, .fa-duotone.fa-gauge-simple-med:after, .fad.fa-tachometer-average:after, .fa-duotone.fa-tachometer-average:after {
  content: "";
}

.fad.fa-arrow-down-small-big:after, .fa-duotone.fa-arrow-down-small-big:after, .fad.fa-sort-size-down-alt:after, .fa-duotone.fa-sort-size-down-alt:after {
  content: "";
}

.fad.fa-book-medical:after, .fa-duotone.fa-book-medical:after {
  content: "";
}

.fad.fa-face-melting:after, .fa-duotone.fa-face-melting:after {
  content: "";
}

.fad.fa-poo:after, .fa-duotone.fa-poo:after {
  content: "";
}

.fad.fa-pen-clip-slash:after, .fa-duotone.fa-pen-clip-slash:after, .fad.fa-pen-alt-slash:after, .fa-duotone.fa-pen-alt-slash:after {
  content: "";
}

.fad.fa-quote-right:after, .fa-duotone.fa-quote-right:after, .fad.fa-quote-right-alt:after, .fa-duotone.fa-quote-right-alt:after {
  content: "";
}

.fad.fa-scroll-old:after, .fa-duotone.fa-scroll-old:after {
  content: "";
}

.fad.fa-guitars:after, .fa-duotone.fa-guitars:after {
  content: "";
}

.fad.fa-phone-xmark:after, .fa-duotone.fa-phone-xmark:after {
  content: "";
}

.fad.fa-hose:after, .fa-duotone.fa-hose:after {
  content: "";
}

.fad.fa-clock-six:after, .fa-duotone.fa-clock-six:after {
  content: "";
}

.fad.fa-shirt:after, .fa-duotone.fa-shirt:after, .fad.fa-t-shirt:after, .fa-duotone.fa-t-shirt:after, .fad.fa-tshirt:after, .fa-duotone.fa-tshirt:after {
  content: "";
}

.fad.fa-billboard:after, .fa-duotone.fa-billboard:after {
  content: "";
}

.fad.fa-square-r:after, .fa-duotone.fa-square-r:after {
  content: "";
}

.fad.fa-cubes:after, .fa-duotone.fa-cubes:after {
  content: "";
}

.fad.fa-envelope-open-dollar:after, .fa-duotone.fa-envelope-open-dollar:after {
  content: "";
}

.fad.fa-divide:after, .fa-duotone.fa-divide:after {
  content: "";
}

.fad.fa-sun-cloud:after, .fa-duotone.fa-sun-cloud:after {
  content: "";
}

.fad.fa-lamp-floor:after, .fa-duotone.fa-lamp-floor:after {
  content: "";
}

.fad.fa-square-7:after, .fa-duotone.fa-square-7:after {
  content: "";
}

.fad.fa-tenge-sign:after, .fa-duotone.fa-tenge-sign:after, .fad.fa-tenge:after, .fa-duotone.fa-tenge:after {
  content: "";
}

.fad.fa-headphones:after, .fa-duotone.fa-headphones:after {
  content: "";
}

.fad.fa-hands-holding:after, .fa-duotone.fa-hands-holding:after {
  content: "";
}

.fad.fa-campfire:after, .fa-duotone.fa-campfire:after {
  content: "";
}

.fad.fa-circle-ampersand:after, .fa-duotone.fa-circle-ampersand:after {
  content: "";
}

.fad.fa-snowflakes:after, .fa-duotone.fa-snowflakes:after {
  content: "";
}

.fad.fa-hands-clapping:after, .fa-duotone.fa-hands-clapping:after {
  content: "";
}

.fad.fa-republican:after, .fa-duotone.fa-republican:after {
  content: "";
}

.fad.fa-leaf-maple:after, .fa-duotone.fa-leaf-maple:after {
  content: "";
}

.fad.fa-arrow-left:after, .fa-duotone.fa-arrow-left:after {
  content: "";
}

.fad.fa-person-circle-xmark:after, .fa-duotone.fa-person-circle-xmark:after {
  content: "";
}

.fad.fa-ruler:after, .fa-duotone.fa-ruler:after {
  content: "";
}

.fad.fa-arrow-left-from-bracket:after, .fa-duotone.fa-arrow-left-from-bracket:after {
  content: "";
}

.fad.fa-cup-straw-swoosh:after, .fa-duotone.fa-cup-straw-swoosh:after {
  content: "";
}

.fad.fa-temperature-sun:after, .fa-duotone.fa-temperature-sun:after, .fad.fa-temperature-hot:after, .fa-duotone.fa-temperature-hot:after {
  content: "";
}

.fad.fa-align-left:after, .fa-duotone.fa-align-left:after {
  content: "";
}

.fad.fa-dice-d6:after, .fa-duotone.fa-dice-d6:after {
  content: "";
}

.fad.fa-restroom:after, .fa-duotone.fa-restroom:after {
  content: "";
}

.fad.fa-high-definition:after, .fa-duotone.fa-high-definition:after, .fad.fa-rectangle-hd:after, .fa-duotone.fa-rectangle-hd:after {
  content: "";
}

.fad.fa-j:after, .fa-duotone.fa-j:after {
  content: "JJ";
}

.fad.fa-galaxy:after, .fa-duotone.fa-galaxy:after {
  content: "";
}

.fad.fa-users-viewfinder:after, .fa-duotone.fa-users-viewfinder:after {
  content: "";
}

.fad.fa-file-video:after, .fa-duotone.fa-file-video:after {
  content: "";
}

.fad.fa-cherries:after, .fa-duotone.fa-cherries:after {
  content: "";
}

.fad.fa-up-right-from-square:after, .fa-duotone.fa-up-right-from-square:after, .fad.fa-external-link-alt:after, .fa-duotone.fa-external-link-alt:after {
  content: "";
}

.fad.fa-circle-sort:after, .fa-duotone.fa-circle-sort:after, .fad.fa-sort-circle:after, .fa-duotone.fa-sort-circle:after {
  content: "";
}

.fad.fa-table-cells:after, .fa-duotone.fa-table-cells:after, .fad.fa-th:after, .fa-duotone.fa-th:after {
  content: "";
}

.fad.fa-bag-shopping-minus:after, .fa-duotone.fa-bag-shopping-minus:after {
  content: "";
}

.fad.fa-file-pdf:after, .fa-duotone.fa-file-pdf:after {
  content: "";
}

.fad.fa-siren:after, .fa-duotone.fa-siren:after {
  content: "";
}

.fad.fa-arrow-up-to-dotted-line:after, .fa-duotone.fa-arrow-up-to-dotted-line:after {
  content: "";
}

.fad.fa-image-landscape:after, .fa-duotone.fa-image-landscape:after, .fad.fa-landscape:after, .fa-duotone.fa-landscape:after {
  content: "";
}

.fad.fa-tank-water:after, .fa-duotone.fa-tank-water:after {
  content: "";
}

.fad.fa-curling-stone:after, .fa-duotone.fa-curling-stone:after, .fad.fa-curling:after, .fa-duotone.fa-curling:after {
  content: "";
}

.fad.fa-gamepad-modern:after, .fa-duotone.fa-gamepad-modern:after, .fad.fa-gamepad-alt:after, .fa-duotone.fa-gamepad-alt:after {
  content: "";
}

.fad.fa-messages-question:after, .fa-duotone.fa-messages-question:after {
  content: "";
}

.fad.fa-book-bible:after, .fa-duotone.fa-book-bible:after, .fad.fa-bible:after, .fa-duotone.fa-bible:after {
  content: "";
}

.fad.fa-o:after, .fa-duotone.fa-o:after {
  content: "OO";
}

.fad.fa-suitcase-medical:after, .fa-duotone.fa-suitcase-medical:after, .fad.fa-medkit:after, .fa-duotone.fa-medkit:after {
  content: "";
}

.fad.fa-briefcase-arrow-right:after, .fa-duotone.fa-briefcase-arrow-right:after {
  content: "";
}

.fad.fa-expand-wide:after, .fa-duotone.fa-expand-wide:after {
  content: "";
}

.fad.fa-clock-eleven-thirty:after, .fa-duotone.fa-clock-eleven-thirty:after {
  content: "";
}

.fad.fa-rv:after, .fa-duotone.fa-rv:after {
  content: "";
}

.fad.fa-user-secret:after, .fa-duotone.fa-user-secret:after {
  content: "";
}

.fad.fa-otter:after, .fa-duotone.fa-otter:after {
  content: "";
}

.fad.fa-dreidel:after, .fa-duotone.fa-dreidel:after {
  content: "";
}

.fad.fa-person-dress:after, .fa-duotone.fa-person-dress:after, .fad.fa-female:after, .fa-duotone.fa-female:after {
  content: "";
}

.fad.fa-comment-dollar:after, .fa-duotone.fa-comment-dollar:after {
  content: "";
}

.fad.fa-business-time:after, .fa-duotone.fa-business-time:after, .fad.fa-briefcase-clock:after, .fa-duotone.fa-briefcase-clock:after {
  content: "";
}

.fad.fa-flower-tulip:after, .fa-duotone.fa-flower-tulip:after {
  content: "";
}

.fad.fa-people-pants-simple:after, .fa-duotone.fa-people-pants-simple:after {
  content: "";
}

.fad.fa-cloud-drizzle:after, .fa-duotone.fa-cloud-drizzle:after {
  content: "";
}

.fad.fa-table-cells-large:after, .fa-duotone.fa-table-cells-large:after, .fad.fa-th-large:after, .fa-duotone.fa-th-large:after {
  content: "";
}

.fad.fa-book-tanakh:after, .fa-duotone.fa-book-tanakh:after, .fad.fa-tanakh:after, .fa-duotone.fa-tanakh:after {
  content: "";
}

.fad.fa-solar-system:after, .fa-duotone.fa-solar-system:after {
  content: "";
}

.fad.fa-seal-question:after, .fa-duotone.fa-seal-question:after {
  content: "";
}

.fad.fa-phone-volume:after, .fa-duotone.fa-phone-volume:after, .fad.fa-volume-control-phone:after, .fa-duotone.fa-volume-control-phone:after {
  content: "";
}

.fad.fa-disc-drive:after, .fa-duotone.fa-disc-drive:after {
  content: "";
}

.fad.fa-hat-cowboy-side:after, .fa-duotone.fa-hat-cowboy-side:after {
  content: "";
}

.fad.fa-table-rows:after, .fa-duotone.fa-table-rows:after, .fad.fa-rows:after, .fa-duotone.fa-rows:after {
  content: "";
}

.fad.fa-location-exclamation:after, .fa-duotone.fa-location-exclamation:after, .fad.fa-map-marker-exclamation:after, .fa-duotone.fa-map-marker-exclamation:after {
  content: "";
}

.fad.fa-face-fearful:after, .fa-duotone.fa-face-fearful:after {
  content: "";
}

.fad.fa-clipboard-user:after, .fa-duotone.fa-clipboard-user:after {
  content: "";
}

.fad.fa-bus-school:after, .fa-duotone.fa-bus-school:after {
  content: "";
}

.fad.fa-film-slash:after, .fa-duotone.fa-film-slash:after {
  content: "";
}

.fad.fa-square-arrow-down-right:after, .fa-duotone.fa-square-arrow-down-right:after {
  content: "";
}

.fad.fa-book-sparkles:after, .fa-duotone.fa-book-sparkles:after, .fad.fa-book-spells:after, .fa-duotone.fa-book-spells:after {
  content: "";
}

.fad.fa-washing-machine:after, .fa-duotone.fa-washing-machine:after, .fad.fa-washer:after, .fa-duotone.fa-washer:after {
  content: "";
}

.fad.fa-child:after, .fa-duotone.fa-child:after {
  content: "";
}

.fad.fa-lira-sign:after, .fa-duotone.fa-lira-sign:after {
  content: "";
}

.fad.fa-user-visor:after, .fa-duotone.fa-user-visor:after {
  content: "";
}

.fad.fa-file-plus-minus:after, .fa-duotone.fa-file-plus-minus:after {
  content: "";
}

.fad.fa-chess-clock-flip:after, .fa-duotone.fa-chess-clock-flip:after, .fad.fa-chess-clock-alt:after, .fa-duotone.fa-chess-clock-alt:after {
  content: "";
}

.fad.fa-satellite:after, .fa-duotone.fa-satellite:after {
  content: "";
}

.fad.fa-truck-fire:after, .fa-duotone.fa-truck-fire:after {
  content: "";
}

.fad.fa-plane-lock:after, .fa-duotone.fa-plane-lock:after {
  content: "";
}

.fad.fa-steering-wheel:after, .fa-duotone.fa-steering-wheel:after {
  content: "";
}

.fad.fa-tag:after, .fa-duotone.fa-tag:after {
  content: "";
}

.fad.fa-stretcher:after, .fa-duotone.fa-stretcher:after {
  content: "";
}

.fad.fa-book-section:after, .fa-duotone.fa-book-section:after, .fad.fa-book-law:after, .fa-duotone.fa-book-law:after {
  content: "";
}

.fad.fa-inboxes:after, .fa-duotone.fa-inboxes:after {
  content: "";
}

.fad.fa-coffee-bean:after, .fa-duotone.fa-coffee-bean:after {
  content: "";
}

.fad.fa-circle-yen:after, .fa-duotone.fa-circle-yen:after {
  content: "";
}

.fad.fa-brackets-curly:after, .fa-duotone.fa-brackets-curly:after {
  content: "";
}

.fad.fa-ellipsis-stroke-vertical:after, .fa-duotone.fa-ellipsis-stroke-vertical:after, .fad.fa-ellipsis-v-alt:after, .fa-duotone.fa-ellipsis-v-alt:after {
  content: "";
}

.fad.fa-comment:after, .fa-duotone.fa-comment:after {
  content: "";
}

.fad.fa-square-1:after, .fa-duotone.fa-square-1:after {
  content: "";
}

.fad.fa-cake-candles:after, .fa-duotone.fa-cake-candles:after, .fad.fa-birthday-cake:after, .fa-duotone.fa-birthday-cake:after, .fad.fa-cake:after, .fa-duotone.fa-cake:after {
  content: "";
}

.fad.fa-head-side:after, .fa-duotone.fa-head-side:after {
  content: "";
}

.fad.fa-truck-ladder:after, .fa-duotone.fa-truck-ladder:after {
  content: "";
}

.fad.fa-envelope:after, .fa-duotone.fa-envelope:after {
  content: "";
}

.fad.fa-dolly-empty:after, .fa-duotone.fa-dolly-empty:after {
  content: "";
}

.fad.fa-face-tissue:after, .fa-duotone.fa-face-tissue:after {
  content: "";
}

.fad.fa-angles-up:after, .fa-duotone.fa-angles-up:after, .fad.fa-angle-double-up:after, .fa-duotone.fa-angle-double-up:after {
  content: "";
}

.fad.fa-bin-recycle:after, .fa-duotone.fa-bin-recycle:after {
  content: "";
}

.fad.fa-paperclip:after, .fa-duotone.fa-paperclip:after {
  content: "";
}

.fad.fa-chart-line-down:after, .fa-duotone.fa-chart-line-down:after {
  content: "";
}

.fad.fa-arrow-right-to-city:after, .fa-duotone.fa-arrow-right-to-city:after {
  content: "";
}

.fad.fa-lock-a:after, .fa-duotone.fa-lock-a:after {
  content: "";
}

.fad.fa-ribbon:after, .fa-duotone.fa-ribbon:after {
  content: "";
}

.fad.fa-lungs:after, .fa-duotone.fa-lungs:after {
  content: "";
}

.fad.fa-person-pinball:after, .fa-duotone.fa-person-pinball:after {
  content: "";
}

.fad.fa-arrow-up-9-1:after, .fa-duotone.fa-arrow-up-9-1:after, .fad.fa-sort-numeric-up-alt:after, .fa-duotone.fa-sort-numeric-up-alt:after {
  content: "";
}

.fad.fa-apple-core:after, .fa-duotone.fa-apple-core:after {
  content: "";
}

.fad.fa-circle-y:after, .fa-duotone.fa-circle-y:after {
  content: "";
}

.fad.fa-h6:after, .fa-duotone.fa-h6:after {
  content: "";
}

.fad.fa-litecoin-sign:after, .fa-duotone.fa-litecoin-sign:after {
  content: "";
}

.fad.fa-bottle-baby:after, .fa-duotone.fa-bottle-baby:after {
  content: "";
}

.fad.fa-circle-small:after, .fa-duotone.fa-circle-small:after {
  content: "";
}

.fad.fa-border-none:after, .fa-duotone.fa-border-none:after {
  content: "";
}

.fad.fa-arrow-turn-down-left:after, .fa-duotone.fa-arrow-turn-down-left:after {
  content: "";
}

.fad.fa-circle-wifi-circle-wifi:after, .fa-duotone.fa-circle-wifi-circle-wifi:after, .fad.fa-circle-wifi-group:after, .fa-duotone.fa-circle-wifi-group:after {
  content: "";
}

.fad.fa-circle-nodes:after, .fa-duotone.fa-circle-nodes:after {
  content: "";
}

.fad.fa-parachute-box:after, .fa-duotone.fa-parachute-box:after {
  content: "";
}

.fad.fa-reflect-horizontal:after, .fa-duotone.fa-reflect-horizontal:after {
  content: "";
}

.fad.fa-message-medical:after, .fa-duotone.fa-message-medical:after, .fad.fa-comment-alt-medical:after, .fa-duotone.fa-comment-alt-medical:after {
  content: "";
}

.fad.fa-rugby-ball:after, .fa-duotone.fa-rugby-ball:after {
  content: "";
}

.fad.fa-comment-music:after, .fa-duotone.fa-comment-music:after {
  content: "";
}

.fad.fa-indent:after, .fa-duotone.fa-indent:after {
  content: "";
}

.fad.fa-tree-deciduous:after, .fa-duotone.fa-tree-deciduous:after, .fad.fa-tree-alt:after, .fa-duotone.fa-tree-alt:after {
  content: "";
}

.fad.fa-puzzle-piece-simple:after, .fa-duotone.fa-puzzle-piece-simple:after, .fad.fa-puzzle-piece-alt:after, .fa-duotone.fa-puzzle-piece-alt:after {
  content: "";
}

.fad.fa-truck-field-un:after, .fa-duotone.fa-truck-field-un:after {
  content: "";
}

.fad.fa-nfc-trash:after, .fa-duotone.fa-nfc-trash:after {
  content: "";
}

.fad.fa-hourglass:after, .fa-duotone.fa-hourglass:after, .fad.fa-hourglass-empty:after, .fa-duotone.fa-hourglass-empty:after {
  content: "";
}

.fad.fa-mountain:after, .fa-duotone.fa-mountain:after {
  content: "";
}

.fad.fa-file-xmark:after, .fa-duotone.fa-file-xmark:after, .fad.fa-file-times:after, .fa-duotone.fa-file-times:after {
  content: "";
}

.fad.fa-house-heart:after, .fa-duotone.fa-house-heart:after, .fad.fa-home-heart:after, .fa-duotone.fa-home-heart:after {
  content: "";
}

.fad.fa-house-chimney-blank:after, .fa-duotone.fa-house-chimney-blank:after {
  content: "";
}

.fad.fa-meter-bolt:after, .fa-duotone.fa-meter-bolt:after {
  content: "";
}

.fad.fa-user-doctor:after, .fa-duotone.fa-user-doctor:after, .fad.fa-user-md:after, .fa-duotone.fa-user-md:after {
  content: "";
}

.fad.fa-slash-back:after, .fa-duotone.fa-slash-back:after {
  content: "\\\\";
}

.fad.fa-circle-info:after, .fa-duotone.fa-circle-info:after, .fad.fa-info-circle:after, .fa-duotone.fa-info-circle:after {
  content: "";
}

.fad.fa-fishing-rod:after, .fa-duotone.fa-fishing-rod:after {
  content: "";
}

.fad.fa-hammer-crash:after, .fa-duotone.fa-hammer-crash:after {
  content: "";
}

.fad.fa-message-heart:after, .fa-duotone.fa-message-heart:after {
  content: "";
}

.fad.fa-cloud-meatball:after, .fa-duotone.fa-cloud-meatball:after {
  content: "";
}

.fad.fa-camera-polaroid:after, .fa-duotone.fa-camera-polaroid:after {
  content: "";
}

.fad.fa-camera:after, .fa-duotone.fa-camera:after, .fad.fa-camera-alt:after, .fa-duotone.fa-camera-alt:after {
  content: "";
}

.fad.fa-square-virus:after, .fa-duotone.fa-square-virus:after {
  content: "";
}

.fad.fa-cart-arrow-up:after, .fa-duotone.fa-cart-arrow-up:after {
  content: "";
}

.fad.fa-meteor:after, .fa-duotone.fa-meteor:after {
  content: "";
}

.fad.fa-car-on:after, .fa-duotone.fa-car-on:after {
  content: "";
}

.fad.fa-sleigh:after, .fa-duotone.fa-sleigh:after {
  content: "";
}

.fad.fa-arrow-down-1-9:after, .fa-duotone.fa-arrow-down-1-9:after, .fad.fa-sort-numeric-asc:after, .fa-duotone.fa-sort-numeric-asc:after, .fad.fa-sort-numeric-down:after, .fa-duotone.fa-sort-numeric-down:after {
  content: "";
}

.fad.fa-buoy-mooring:after, .fa-duotone.fa-buoy-mooring:after {
  content: "";
}

.fad.fa-square-4:after, .fa-duotone.fa-square-4:after {
  content: "";
}

.fad.fa-hand-holding-droplet:after, .fa-duotone.fa-hand-holding-droplet:after, .fad.fa-hand-holding-water:after, .fa-duotone.fa-hand-holding-water:after {
  content: "";
}

.fad.fa-file-eps:after, .fa-duotone.fa-file-eps:after {
  content: "";
}

.fad.fa-tricycle-adult:after, .fa-duotone.fa-tricycle-adult:after {
  content: "";
}

.fad.fa-waveform:after, .fa-duotone.fa-waveform:after {
  content: "";
}

.fad.fa-water:after, .fa-duotone.fa-water:after {
  content: "";
}

.fad.fa-star-sharp-half-stroke:after, .fa-duotone.fa-star-sharp-half-stroke:after, .fad.fa-star-sharp-half-alt:after, .fa-duotone.fa-star-sharp-half-alt:after {
  content: "";
}

.fad.fa-nfc-signal:after, .fa-duotone.fa-nfc-signal:after {
  content: "";
}

.fad.fa-plane-prop:after, .fa-duotone.fa-plane-prop:after {
  content: "";
}

.fad.fa-calendar-check:after, .fa-duotone.fa-calendar-check:after {
  content: "";
}

.fad.fa-clock-desk:after, .fa-duotone.fa-clock-desk:after {
  content: "";
}

.fad.fa-calendar-clock:after, .fa-duotone.fa-calendar-clock:after, .fad.fa-calendar-time:after, .fa-duotone.fa-calendar-time:after {
  content: "";
}

.fad.fa-braille:after, .fa-duotone.fa-braille:after {
  content: "";
}

.fad.fa-prescription-bottle-medical:after, .fa-duotone.fa-prescription-bottle-medical:after, .fad.fa-prescription-bottle-alt:after, .fa-duotone.fa-prescription-bottle-alt:after {
  content: "";
}

.fad.fa-plate-utensils:after, .fa-duotone.fa-plate-utensils:after {
  content: "";
}

.fad.fa-family-pants:after, .fa-duotone.fa-family-pants:after {
  content: "";
}

.fad.fa-hose-reel:after, .fa-duotone.fa-hose-reel:after {
  content: "";
}

.fad.fa-house-window:after, .fa-duotone.fa-house-window:after {
  content: "";
}

.fad.fa-landmark:after, .fa-duotone.fa-landmark:after {
  content: "";
}

.fad.fa-truck:after, .fa-duotone.fa-truck:after {
  content: "";
}

.fad.fa-music-magnifying-glass:after, .fa-duotone.fa-music-magnifying-glass:after {
  content: "";
}

.fad.fa-crosshairs:after, .fa-duotone.fa-crosshairs:after {
  content: "";
}

.fad.fa-cloud-rainbow:after, .fa-duotone.fa-cloud-rainbow:after {
  content: "";
}

.fad.fa-person-cane:after, .fa-duotone.fa-person-cane:after {
  content: "";
}

.fad.fa-alien:after, .fa-duotone.fa-alien:after {
  content: "";
}

.fad.fa-tent:after, .fa-duotone.fa-tent:after {
  content: "";
}

.fad.fa-laptop-binary:after, .fa-duotone.fa-laptop-binary:after {
  content: "";
}

.fad.fa-vest-patches:after, .fa-duotone.fa-vest-patches:after {
  content: "";
}

.fad.fa-people-dress-simple:after, .fa-duotone.fa-people-dress-simple:after {
  content: "";
}

.fad.fa-check-double:after, .fa-duotone.fa-check-double:after {
  content: "";
}

.fad.fa-arrow-down-a-z:after, .fa-duotone.fa-arrow-down-a-z:after, .fad.fa-sort-alpha-asc:after, .fa-duotone.fa-sort-alpha-asc:after, .fad.fa-sort-alpha-down:after, .fa-duotone.fa-sort-alpha-down:after {
  content: "";
}

.fad.fa-bowling-ball-pin:after, .fa-duotone.fa-bowling-ball-pin:after {
  content: "";
}

.fad.fa-bell-school-slash:after, .fa-duotone.fa-bell-school-slash:after {
  content: "";
}

.fad.fa-plus-large:after, .fa-duotone.fa-plus-large:after {
  content: "";
}

.fad.fa-money-bill-wheat:after, .fa-duotone.fa-money-bill-wheat:after {
  content: "";
}

.fad.fa-camera-viewfinder:after, .fa-duotone.fa-camera-viewfinder:after, .fad.fa-screenshot:after, .fa-duotone.fa-screenshot:after {
  content: "";
}

.fad.fa-message-music:after, .fa-duotone.fa-message-music:after, .fad.fa-comment-alt-music:after, .fa-duotone.fa-comment-alt-music:after {
  content: "";
}

.fad.fa-car-building:after, .fa-duotone.fa-car-building:after {
  content: "";
}

.fad.fa-border-bottom-right:after, .fa-duotone.fa-border-bottom-right:after, .fad.fa-border-style-alt:after, .fa-duotone.fa-border-style-alt:after {
  content: "";
}

.fad.fa-octagon:after, .fa-duotone.fa-octagon:after {
  content: "";
}

.fad.fa-comment-arrow-up-right:after, .fa-duotone.fa-comment-arrow-up-right:after {
  content: "";
}

.fad.fa-octagon-divide:after, .fa-duotone.fa-octagon-divide:after {
  content: "";
}

.fad.fa-cookie:after, .fa-duotone.fa-cookie:after {
  content: "";
}

.fad.fa-arrow-rotate-left:after, .fa-duotone.fa-arrow-rotate-left:after, .fad.fa-arrow-left-rotate:after, .fa-duotone.fa-arrow-left-rotate:after, .fad.fa-arrow-rotate-back:after, .fa-duotone.fa-arrow-rotate-back:after, .fad.fa-arrow-rotate-backward:after, .fa-duotone.fa-arrow-rotate-backward:after, .fad.fa-undo:after, .fa-duotone.fa-undo:after {
  content: "";
}

.fad.fa-tv-music:after, .fa-duotone.fa-tv-music:after {
  content: "";
}

.fad.fa-hard-drive:after, .fa-duotone.fa-hard-drive:after, .fad.fa-hdd:after, .fa-duotone.fa-hdd:after {
  content: "";
}

.fad.fa-reel:after, .fa-duotone.fa-reel:after {
  content: "";
}

.fad.fa-face-grin-squint-tears:after, .fa-duotone.fa-face-grin-squint-tears:after, .fad.fa-grin-squint-tears:after, .fa-duotone.fa-grin-squint-tears:after {
  content: "";
}

.fad.fa-dumbbell:after, .fa-duotone.fa-dumbbell:after {
  content: "";
}

.fad.fa-rectangle-list:after, .fa-duotone.fa-rectangle-list:after, .fad.fa-list-alt:after, .fa-duotone.fa-list-alt:after {
  content: "";
}

.fad.fa-tarp-droplet:after, .fa-duotone.fa-tarp-droplet:after {
  content: "";
}

.fad.fa-alarm-exclamation:after, .fa-duotone.fa-alarm-exclamation:after {
  content: "";
}

.fad.fa-house-medical-circle-check:after, .fa-duotone.fa-house-medical-circle-check:after {
  content: "";
}

.fad.fa-traffic-cone:after, .fa-duotone.fa-traffic-cone:after {
  content: "";
}

.fad.fa-grate:after, .fa-duotone.fa-grate:after {
  content: "";
}

.fad.fa-arrow-down-right:after, .fa-duotone.fa-arrow-down-right:after {
  content: "";
}

.fad.fa-person-skiing-nordic:after, .fa-duotone.fa-person-skiing-nordic:after, .fad.fa-skiing-nordic:after, .fa-duotone.fa-skiing-nordic:after {
  content: "";
}

.fad.fa-calendar-plus:after, .fa-duotone.fa-calendar-plus:after {
  content: "";
}

.fad.fa-person-from-portal:after, .fa-duotone.fa-person-from-portal:after, .fad.fa-portal-exit:after, .fa-duotone.fa-portal-exit:after {
  content: "";
}

.fad.fa-plane-arrival:after, .fa-duotone.fa-plane-arrival:after {
  content: "";
}

.fad.fa-cowbell-circle-plus:after, .fa-duotone.fa-cowbell-circle-plus:after, .fad.fa-cowbell-more:after, .fa-duotone.fa-cowbell-more:after {
  content: "";
}

.fad.fa-circle-left:after, .fa-duotone.fa-circle-left:after, .fad.fa-arrow-alt-circle-left:after, .fa-duotone.fa-arrow-alt-circle-left:after {
  content: "";
}

.fad.fa-distribute-spacing-vertical:after, .fa-duotone.fa-distribute-spacing-vertical:after {
  content: "";
}

.fad.fa-signal-bars-fair:after, .fa-duotone.fa-signal-bars-fair:after, .fad.fa-signal-alt-2:after, .fa-duotone.fa-signal-alt-2:after {
  content: "";
}

.fad.fa-sportsball:after, .fa-duotone.fa-sportsball:after {
  content: "";
}

.fad.fa-game-console-handheld-crank:after, .fa-duotone.fa-game-console-handheld-crank:after {
  content: "";
}

.fad.fa-train-subway:after, .fa-duotone.fa-train-subway:after, .fad.fa-subway:after, .fa-duotone.fa-subway:after {
  content: "";
}

.fad.fa-chart-gantt:after, .fa-duotone.fa-chart-gantt:after {
  content: "";
}

.fad.fa-face-smile-upside-down:after, .fa-duotone.fa-face-smile-upside-down:after {
  content: "";
}

.fad.fa-ball-pile:after, .fa-duotone.fa-ball-pile:after {
  content: "";
}

.fad.fa-badge-dollar:after, .fa-duotone.fa-badge-dollar:after {
  content: "";
}

.fad.fa-money-bills-simple:after, .fa-duotone.fa-money-bills-simple:after, .fad.fa-money-bills-alt:after, .fa-duotone.fa-money-bills-alt:after {
  content: "";
}

.fad.fa-list-timeline:after, .fa-duotone.fa-list-timeline:after {
  content: "";
}

.fad.fa-indian-rupee-sign:after, .fa-duotone.fa-indian-rupee-sign:after, .fad.fa-indian-rupee:after, .fa-duotone.fa-indian-rupee:after, .fad.fa-inr:after, .fa-duotone.fa-inr:after {
  content: "";
}

.fad.fa-crop-simple:after, .fa-duotone.fa-crop-simple:after, .fad.fa-crop-alt:after, .fa-duotone.fa-crop-alt:after {
  content: "";
}

.fad.fa-money-bill-1:after, .fa-duotone.fa-money-bill-1:after, .fad.fa-money-bill-alt:after, .fa-duotone.fa-money-bill-alt:after {
  content: "";
}

.fad.fa-left-long:after, .fa-duotone.fa-left-long:after, .fad.fa-long-arrow-alt-left:after, .fa-duotone.fa-long-arrow-alt-left:after {
  content: "";
}

.fad.fa-keyboard-down:after, .fa-duotone.fa-keyboard-down:after {
  content: "";
}

.fad.fa-circle-up-right:after, .fa-duotone.fa-circle-up-right:after {
  content: "";
}

.fad.fa-cloud-bolt-moon:after, .fa-duotone.fa-cloud-bolt-moon:after, .fad.fa-thunderstorm-moon:after, .fa-duotone.fa-thunderstorm-moon:after {
  content: "";
}

.fad.fa-turn-left-up:after, .fa-duotone.fa-turn-left-up:after {
  content: "";
}

.fad.fa-dna:after, .fa-duotone.fa-dna:after {
  content: "";
}

.fad.fa-virus-slash:after, .fa-duotone.fa-virus-slash:after {
  content: "";
}

.fad.fa-bracket-round-right:after, .fa-duotone.fa-bracket-round-right:after {
  content: "))";
}

.fad.fa-circle-sterling:after, .fa-duotone.fa-circle-sterling:after {
  content: "";
}

.fad.fa-circle-5:after, .fa-duotone.fa-circle-5:after {
  content: "";
}

.fad.fa-minus:after, .fa-duotone.fa-minus:after, .fad.fa-subtract:after, .fa-duotone.fa-subtract:after {
  content: "";
}

.fad.fa-fire-flame:after, .fa-duotone.fa-fire-flame:after, .fad.fa-flame:after, .fa-duotone.fa-flame:after {
  content: "";
}

.fad.fa-right-to-line:after, .fa-duotone.fa-right-to-line:after, .fad.fa-arrow-alt-to-right:after, .fa-duotone.fa-arrow-alt-to-right:after {
  content: "";
}

.fad.fa-gif:after, .fa-duotone.fa-gif:after {
  content: "";
}

.fad.fa-chess:after, .fa-duotone.fa-chess:after {
  content: "";
}

.fad.fa-trash-slash:after, .fa-duotone.fa-trash-slash:after {
  content: "";
}

.fad.fa-arrow-left-long:after, .fa-duotone.fa-arrow-left-long:after, .fad.fa-long-arrow-left:after, .fa-duotone.fa-long-arrow-left:after {
  content: "";
}

.fad.fa-plug-circle-check:after, .fa-duotone.fa-plug-circle-check:after {
  content: "";
}

.fad.fa-font-case:after, .fa-duotone.fa-font-case:after {
  content: "";
}

.fad.fa-street-view:after, .fa-duotone.fa-street-view:after {
  content: "";
}

.fad.fa-arrow-down-left:after, .fa-duotone.fa-arrow-down-left:after {
  content: "";
}

.fad.fa-franc-sign:after, .fa-duotone.fa-franc-sign:after {
  content: "";
}

.fad.fa-flask-round-poison:after, .fa-duotone.fa-flask-round-poison:after, .fad.fa-flask-poison:after, .fa-duotone.fa-flask-poison:after {
  content: "";
}

.fad.fa-volume-off:after, .fa-duotone.fa-volume-off:after {
  content: "";
}

.fad.fa-book-circle-arrow-right:after, .fa-duotone.fa-book-circle-arrow-right:after {
  content: "";
}

.fad.fa-chart-user:after, .fa-duotone.fa-chart-user:after, .fad.fa-user-chart:after, .fa-duotone.fa-user-chart:after {
  content: "";
}

.fad.fa-hands-asl-interpreting:after, .fa-duotone.fa-hands-asl-interpreting:after, .fad.fa-american-sign-language-interpreting:after, .fa-duotone.fa-american-sign-language-interpreting:after, .fad.fa-asl-interpreting:after, .fa-duotone.fa-asl-interpreting:after, .fad.fa-hands-american-sign-language-interpreting:after, .fa-duotone.fa-hands-american-sign-language-interpreting:after {
  content: "";
}

.fad.fa-presentation-screen:after, .fa-duotone.fa-presentation-screen:after, .fad.fa-presentation:after, .fa-duotone.fa-presentation:after {
  content: "";
}

.fad.fa-circle-bolt:after, .fa-duotone.fa-circle-bolt:after {
  content: "";
}

.fad.fa-face-smile-halo:after, .fa-duotone.fa-face-smile-halo:after {
  content: "";
}

.fad.fa-cart-circle-arrow-down:after, .fa-duotone.fa-cart-circle-arrow-down:after {
  content: "";
}

.fad.fa-house-person-return:after, .fa-duotone.fa-house-person-return:after, .fad.fa-house-person-arrive:after, .fa-duotone.fa-house-person-arrive:after, .fad.fa-house-return:after, .fa-duotone.fa-house-return:after {
  content: "";
}

.fad.fa-message-xmark:after, .fa-duotone.fa-message-xmark:after, .fad.fa-comment-alt-times:after, .fa-duotone.fa-comment-alt-times:after, .fad.fa-message-times:after, .fa-duotone.fa-message-times:after {
  content: "";
}

.fad.fa-file-certificate:after, .fa-duotone.fa-file-certificate:after, .fad.fa-file-award:after, .fa-duotone.fa-file-award:after {
  content: "";
}

.fad.fa-user-doctor-hair-long:after, .fa-duotone.fa-user-doctor-hair-long:after {
  content: "";
}

.fad.fa-camera-security:after, .fa-duotone.fa-camera-security:after, .fad.fa-camera-home:after, .fa-duotone.fa-camera-home:after {
  content: "";
}

.fad.fa-gear:after, .fa-duotone.fa-gear:after, .fad.fa-cog:after, .fa-duotone.fa-cog:after {
  content: "";
}

.fad.fa-droplet-slash:after, .fa-duotone.fa-droplet-slash:after, .fad.fa-tint-slash:after, .fa-duotone.fa-tint-slash:after {
  content: "";
}

.fad.fa-book-heart:after, .fa-duotone.fa-book-heart:after {
  content: "";
}

.fad.fa-mosque:after, .fa-duotone.fa-mosque:after {
  content: "";
}

.fad.fa-duck:after, .fa-duotone.fa-duck:after {
  content: "";
}

.fad.fa-mosquito:after, .fa-duotone.fa-mosquito:after {
  content: "";
}

.fad.fa-star-of-david:after, .fa-duotone.fa-star-of-david:after {
  content: "";
}

.fad.fa-flag-swallowtail:after, .fa-duotone.fa-flag-swallowtail:after, .fad.fa-flag-alt:after, .fa-duotone.fa-flag-alt:after {
  content: "";
}

.fad.fa-person-military-rifle:after, .fa-duotone.fa-person-military-rifle:after {
  content: "";
}

.fad.fa-car-garage:after, .fa-duotone.fa-car-garage:after {
  content: "";
}

.fad.fa-cart-shopping:after, .fa-duotone.fa-cart-shopping:after, .fad.fa-shopping-cart:after, .fa-duotone.fa-shopping-cart:after {
  content: "";
}

.fad.fa-book-font:after, .fa-duotone.fa-book-font:after {
  content: "";
}

.fad.fa-shield-plus:after, .fa-duotone.fa-shield-plus:after {
  content: "";
}

.fad.fa-vials:after, .fa-duotone.fa-vials:after {
  content: "";
}

.fad.fa-eye-dropper-full:after, .fa-duotone.fa-eye-dropper-full:after {
  content: "";
}

.fad.fa-distribute-spacing-horizontal:after, .fa-duotone.fa-distribute-spacing-horizontal:after {
  content: "";
}

.fad.fa-tablet-rugged:after, .fa-duotone.fa-tablet-rugged:after {
  content: "";
}

.fad.fa-temperature-snow:after, .fa-duotone.fa-temperature-snow:after, .fad.fa-temperature-frigid:after, .fa-duotone.fa-temperature-frigid:after {
  content: "";
}

.fad.fa-moped:after, .fa-duotone.fa-moped:after {
  content: "";
}

.fad.fa-face-smile-plus:after, .fa-duotone.fa-face-smile-plus:after, .fad.fa-smile-plus:after, .fa-duotone.fa-smile-plus:after {
  content: "";
}

.fad.fa-radio-tuner:after, .fa-duotone.fa-radio-tuner:after, .fad.fa-radio-alt:after, .fa-duotone.fa-radio-alt:after {
  content: "";
}

.fad.fa-face-swear:after, .fa-duotone.fa-face-swear:after {
  content: "";
}

.fad.fa-water-arrow-down:after, .fa-duotone.fa-water-arrow-down:after, .fad.fa-water-lower:after, .fa-duotone.fa-water-lower:after {
  content: "";
}

.fad.fa-scanner-touchscreen:after, .fa-duotone.fa-scanner-touchscreen:after {
  content: "";
}

.fad.fa-circle-7:after, .fa-duotone.fa-circle-7:after {
  content: "";
}

.fad.fa-plug-circle-plus:after, .fa-duotone.fa-plug-circle-plus:after {
  content: "";
}

.fad.fa-person-ski-jumping:after, .fa-duotone.fa-person-ski-jumping:after, .fad.fa-ski-jump:after, .fa-duotone.fa-ski-jump:after {
  content: "";
}

.fad.fa-place-of-worship:after, .fa-duotone.fa-place-of-worship:after {
  content: "";
}

.fad.fa-water-arrow-up:after, .fa-duotone.fa-water-arrow-up:after, .fad.fa-water-rise:after, .fa-duotone.fa-water-rise:after {
  content: "";
}

.fad.fa-waveform-lines:after, .fa-duotone.fa-waveform-lines:after, .fad.fa-waveform-path:after, .fa-duotone.fa-waveform-path:after {
  content: "";
}

.fad.fa-split:after, .fa-duotone.fa-split:after {
  content: "";
}

.fad.fa-film-canister:after, .fa-duotone.fa-film-canister:after, .fad.fa-film-cannister:after, .fa-duotone.fa-film-cannister:after {
  content: "";
}

.fad.fa-folder-xmark:after, .fa-duotone.fa-folder-xmark:after, .fad.fa-folder-times:after, .fa-duotone.fa-folder-times:after {
  content: "";
}

.fad.fa-toilet-paper-blank:after, .fa-duotone.fa-toilet-paper-blank:after, .fad.fa-toilet-paper-alt:after, .fa-duotone.fa-toilet-paper-alt:after {
  content: "";
}

.fad.fa-tablet-screen:after, .fa-duotone.fa-tablet-screen:after, .fad.fa-tablet-android-alt:after, .fa-duotone.fa-tablet-android-alt:after {
  content: "";
}

.fad.fa-hexagon-vertical-nft-slanted:after, .fa-duotone.fa-hexagon-vertical-nft-slanted:after {
  content: "";
}

.fad.fa-folder-music:after, .fa-duotone.fa-folder-music:after {
  content: "";
}

.fad.fa-display-medical:after, .fa-duotone.fa-display-medical:after, .fad.fa-desktop-medical:after, .fa-duotone.fa-desktop-medical:after {
  content: "";
}

.fad.fa-share-all:after, .fa-duotone.fa-share-all:after {
  content: "";
}

.fad.fa-peapod:after, .fa-duotone.fa-peapod:after {
  content: "";
}

.fad.fa-chess-clock:after, .fa-duotone.fa-chess-clock:after {
  content: "";
}

.fad.fa-axe:after, .fa-duotone.fa-axe:after {
  content: "";
}

.fad.fa-square-d:after, .fa-duotone.fa-square-d:after {
  content: "";
}

.fad.fa-grip-vertical:after, .fa-duotone.fa-grip-vertical:after {
  content: "";
}

.fad.fa-mobile-signal-out:after, .fa-duotone.fa-mobile-signal-out:after {
  content: "";
}

.fad.fa-arrow-turn-up:after, .fa-duotone.fa-arrow-turn-up:after, .fad.fa-level-up:after, .fa-duotone.fa-level-up:after {
  content: "";
}

.fad.fa-u:after, .fa-duotone.fa-u:after {
  content: "UU";
}

.fad.fa-arrow-up-from-dotted-line:after, .fa-duotone.fa-arrow-up-from-dotted-line:after {
  content: "";
}

.fad.fa-square-root-variable:after, .fa-duotone.fa-square-root-variable:after, .fad.fa-square-root-alt:after, .fa-duotone.fa-square-root-alt:after {
  content: "";
}

.fad.fa-light-switch-on:after, .fa-duotone.fa-light-switch-on:after {
  content: "";
}

.fad.fa-arrow-down-arrow-up:after, .fa-duotone.fa-arrow-down-arrow-up:after, .fad.fa-sort-alt:after, .fa-duotone.fa-sort-alt:after {
  content: "";
}

.fad.fa-raindrops:after, .fa-duotone.fa-raindrops:after {
  content: "";
}

.fad.fa-dash:after, .fa-duotone.fa-dash:after, .fad.fa-minus-large:after, .fa-duotone.fa-minus-large:after {
  content: "";
}

.fad.fa-clock:after, .fa-duotone.fa-clock:after, .fad.fa-clock-four:after, .fa-duotone.fa-clock-four:after {
  content: "";
}

.fad.fa-input-numeric:after, .fa-duotone.fa-input-numeric:after {
  content: "";
}

.fad.fa-truck-tow:after, .fa-duotone.fa-truck-tow:after {
  content: "";
}

.fad.fa-backward-step:after, .fa-duotone.fa-backward-step:after, .fad.fa-step-backward:after, .fa-duotone.fa-step-backward:after {
  content: "";
}

.fad.fa-pallet:after, .fa-duotone.fa-pallet:after {
  content: "";
}

.fad.fa-car-bolt:after, .fa-duotone.fa-car-bolt:after {
  content: "";
}

.fad.fa-arrows-maximize:after, .fa-duotone.fa-arrows-maximize:after, .fad.fa-expand-arrows:after, .fa-duotone.fa-expand-arrows:after {
  content: "";
}

.fad.fa-faucet:after, .fa-duotone.fa-faucet:after {
  content: "";
}

.fad.fa-cloud-sleet:after, .fa-duotone.fa-cloud-sleet:after {
  content: "";
}

.fad.fa-lamp-street:after, .fa-duotone.fa-lamp-street:after {
  content: "";
}

.fad.fa-list-radio:after, .fa-duotone.fa-list-radio:after {
  content: "";
}

.fad.fa-pen-nib-slash:after, .fa-duotone.fa-pen-nib-slash:after {
  content: "";
}

.fad.fa-baseball-bat-ball:after, .fa-duotone.fa-baseball-bat-ball:after {
  content: "";
}

.fad.fa-square-up-left:after, .fa-duotone.fa-square-up-left:after {
  content: "";
}

.fad.fa-overline:after, .fa-duotone.fa-overline:after {
  content: "";
}

.fad.fa-s:after, .fa-duotone.fa-s:after {
  content: "SS";
}

.fad.fa-timeline:after, .fa-duotone.fa-timeline:after {
  content: "";
}

.fad.fa-keyboard:after, .fa-duotone.fa-keyboard:after {
  content: "";
}

.fad.fa-arrows-from-dotted-line:after, .fa-duotone.fa-arrows-from-dotted-line:after {
  content: "";
}

.fad.fa-usb-drive:after, .fa-duotone.fa-usb-drive:after {
  content: "";
}

.fad.fa-ballot:after, .fa-duotone.fa-ballot:after {
  content: "";
}

.fad.fa-caret-down:after, .fa-duotone.fa-caret-down:after {
  content: "";
}

.fad.fa-location-dot-slash:after, .fa-duotone.fa-location-dot-slash:after, .fad.fa-map-marker-alt-slash:after, .fa-duotone.fa-map-marker-alt-slash:after {
  content: "";
}

.fad.fa-cards:after, .fa-duotone.fa-cards:after {
  content: "";
}

.fad.fa-house-chimney-medical:after, .fa-duotone.fa-house-chimney-medical:after, .fad.fa-clinic-medical:after, .fa-duotone.fa-clinic-medical:after {
  content: "";
}

.fad.fa-boxing-glove:after, .fa-duotone.fa-boxing-glove:after, .fad.fa-glove-boxing:after, .fa-duotone.fa-glove-boxing:after {
  content: "";
}

.fad.fa-temperature-three-quarters:after, .fa-duotone.fa-temperature-three-quarters:after, .fad.fa-temperature-3:after, .fa-duotone.fa-temperature-3:after, .fad.fa-thermometer-3:after, .fa-duotone.fa-thermometer-3:after, .fad.fa-thermometer-three-quarters:after, .fa-duotone.fa-thermometer-three-quarters:after {
  content: "";
}

.fad.fa-bell-school:after, .fa-duotone.fa-bell-school:after {
  content: "";
}

.fad.fa-mobile-screen:after, .fa-duotone.fa-mobile-screen:after, .fad.fa-mobile-android-alt:after, .fa-duotone.fa-mobile-android-alt:after {
  content: "";
}

.fad.fa-plane-up:after, .fa-duotone.fa-plane-up:after {
  content: "";
}

.fad.fa-folder-heart:after, .fa-duotone.fa-folder-heart:after {
  content: "";
}

.fad.fa-circle-location-arrow:after, .fa-duotone.fa-circle-location-arrow:after, .fad.fa-location-circle:after, .fa-duotone.fa-location-circle:after {
  content: "";
}

.fad.fa-face-head-bandage:after, .fa-duotone.fa-face-head-bandage:after {
  content: "";
}

.fad.fa-sushi-roll:after, .fa-duotone.fa-sushi-roll:after, .fad.fa-maki-roll:after, .fa-duotone.fa-maki-roll:after, .fad.fa-makizushi:after, .fa-duotone.fa-makizushi:after {
  content: "";
}

.fad.fa-car-bump:after, .fa-duotone.fa-car-bump:after {
  content: "";
}

.fad.fa-piggy-bank:after, .fa-duotone.fa-piggy-bank:after {
  content: "";
}

.fad.fa-racquet:after, .fa-duotone.fa-racquet:after {
  content: "";
}

.fad.fa-car-mirrors:after, .fa-duotone.fa-car-mirrors:after {
  content: "";
}

.fad.fa-industry-windows:after, .fa-duotone.fa-industry-windows:after, .fad.fa-industry-alt:after, .fa-duotone.fa-industry-alt:after {
  content: "";
}

.fad.fa-bolt-auto:after, .fa-duotone.fa-bolt-auto:after {
  content: "";
}

.fad.fa-battery-half:after, .fa-duotone.fa-battery-half:after, .fad.fa-battery-3:after, .fa-duotone.fa-battery-3:after {
  content: "";
}

.fad.fa-flux-capacitor:after, .fa-duotone.fa-flux-capacitor:after {
  content: "";
}

.fad.fa-mountain-city:after, .fa-duotone.fa-mountain-city:after {
  content: "";
}

.fad.fa-coins:after, .fa-duotone.fa-coins:after {
  content: "";
}

.fad.fa-honey-pot:after, .fa-duotone.fa-honey-pot:after {
  content: "";
}

.fad.fa-olive:after, .fa-duotone.fa-olive:after {
  content: "";
}

.fad.fa-khanda:after, .fa-duotone.fa-khanda:after {
  content: "";
}

.fad.fa-filter-list:after, .fa-duotone.fa-filter-list:after {
  content: "";
}

.fad.fa-outlet:after, .fa-duotone.fa-outlet:after {
  content: "";
}

.fad.fa-sliders:after, .fa-duotone.fa-sliders:after, .fad.fa-sliders-h:after, .fa-duotone.fa-sliders-h:after {
  content: "";
}

.fad.fa-cauldron:after, .fa-duotone.fa-cauldron:after {
  content: "";
}

.fad.fa-people:after, .fa-duotone.fa-people:after {
  content: "";
}

.fad.fa-folder-tree:after, .fa-duotone.fa-folder-tree:after {
  content: "";
}

.fad.fa-network-wired:after, .fa-duotone.fa-network-wired:after {
  content: "";
}

.fad.fa-croissant:after, .fa-duotone.fa-croissant:after {
  content: "";
}

.fad.fa-map-pin:after, .fa-duotone.fa-map-pin:after {
  content: "";
}

.fad.fa-hamsa:after, .fa-duotone.fa-hamsa:after {
  content: "";
}

.fad.fa-cent-sign:after, .fa-duotone.fa-cent-sign:after {
  content: "";
}

.fad.fa-swords-laser:after, .fa-duotone.fa-swords-laser:after {
  content: "";
}

.fad.fa-flask:after, .fa-duotone.fa-flask:after {
  content: "";
}

.fad.fa-person-pregnant:after, .fa-duotone.fa-person-pregnant:after {
  content: "";
}

.fad.fa-square-u:after, .fa-duotone.fa-square-u:after {
  content: "";
}

.fad.fa-wand-sparkles:after, .fa-duotone.fa-wand-sparkles:after {
  content: "";
}

.fad.fa-router:after, .fa-duotone.fa-router:after {
  content: "";
}

.fad.fa-ellipsis-vertical:after, .fa-duotone.fa-ellipsis-vertical:after, .fad.fa-ellipsis-v:after, .fa-duotone.fa-ellipsis-v:after {
  content: "";
}

.fad.fa-sword-laser-alt:after, .fa-duotone.fa-sword-laser-alt:after {
  content: "";
}

.fad.fa-ticket:after, .fa-duotone.fa-ticket:after {
  content: "";
}

.fad.fa-power-off:after, .fa-duotone.fa-power-off:after {
  content: "";
}

.fad.fa-coin:after, .fa-duotone.fa-coin:after {
  content: "";
}

.fad.fa-laptop-slash:after, .fa-duotone.fa-laptop-slash:after {
  content: "";
}

.fad.fa-right-long:after, .fa-duotone.fa-right-long:after, .fad.fa-long-arrow-alt-right:after, .fa-duotone.fa-long-arrow-alt-right:after {
  content: "";
}

.fad.fa-circle-b:after, .fa-duotone.fa-circle-b:after {
  content: "";
}

.fad.fa-person-dress-simple:after, .fa-duotone.fa-person-dress-simple:after {
  content: "";
}

.fad.fa-pipe-collar:after, .fa-duotone.fa-pipe-collar:after {
  content: "";
}

.fad.fa-lights-holiday:after, .fa-duotone.fa-lights-holiday:after {
  content: "";
}

.fad.fa-citrus:after, .fa-duotone.fa-citrus:after {
  content: "";
}

.fad.fa-flag-usa:after, .fa-duotone.fa-flag-usa:after {
  content: "";
}

.fad.fa-laptop-file:after, .fa-duotone.fa-laptop-file:after {
  content: "";
}

.fad.fa-tty:after, .fa-duotone.fa-tty:after, .fad.fa-teletype:after, .fa-duotone.fa-teletype:after {
  content: "";
}

.fad.fa-chart-tree-map:after, .fa-duotone.fa-chart-tree-map:after {
  content: "";
}

.fad.fa-diagram-next:after, .fa-duotone.fa-diagram-next:after {
  content: "";
}

.fad.fa-person-rifle:after, .fa-duotone.fa-person-rifle:after {
  content: "";
}

.fad.fa-clock-five-thirty:after, .fa-duotone.fa-clock-five-thirty:after {
  content: "";
}

.fad.fa-pipe-valve:after, .fa-duotone.fa-pipe-valve:after {
  content: "";
}

.fad.fa-lightbulb-message:after, .fa-duotone.fa-lightbulb-message:after {
  content: "";
}

.fad.fa-arrow-up-from-arc:after, .fa-duotone.fa-arrow-up-from-arc:after {
  content: "";
}

.fad.fa-face-spiral-eyes:after, .fa-duotone.fa-face-spiral-eyes:after {
  content: "";
}

.fad.fa-compress-wide:after, .fa-duotone.fa-compress-wide:after {
  content: "";
}

.fad.fa-circle-phone-hangup:after, .fa-duotone.fa-circle-phone-hangup:after, .fad.fa-phone-circle-down:after, .fa-duotone.fa-phone-circle-down:after {
  content: "";
}

.fad.fa-gear-complex-code:after, .fa-duotone.fa-gear-complex-code:after {
  content: "";
}

.fad.fa-house-medical-circle-exclamation:after, .fa-duotone.fa-house-medical-circle-exclamation:after {
  content: "";
}

.fad.fa-badminton:after, .fa-duotone.fa-badminton:after {
  content: "";
}

.fad.fa-closed-captioning:after, .fa-duotone.fa-closed-captioning:after {
  content: "";
}

.fad.fa-person-hiking:after, .fa-duotone.fa-person-hiking:after, .fad.fa-hiking:after, .fa-duotone.fa-hiking:after {
  content: "";
}

.fad.fa-right-from-line:after, .fa-duotone.fa-right-from-line:after, .fad.fa-arrow-alt-from-left:after, .fa-duotone.fa-arrow-alt-from-left:after {
  content: "";
}

.fad.fa-venus-double:after, .fa-duotone.fa-venus-double:after {
  content: "";
}

.fad.fa-images:after, .fa-duotone.fa-images:after {
  content: "";
}

.fad.fa-calculator:after, .fa-duotone.fa-calculator:after {
  content: "";
}

.fad.fa-shuttlecock:after, .fa-duotone.fa-shuttlecock:after {
  content: "";
}

.fad.fa-user-hair:after, .fa-duotone.fa-user-hair:after {
  content: "";
}

.fad.fa-eye-evil:after, .fa-duotone.fa-eye-evil:after {
  content: "";
}

.fad.fa-people-pulling:after, .fa-duotone.fa-people-pulling:after {
  content: "";
}

.fad.fa-n:after, .fa-duotone.fa-n:after {
  content: "NN";
}

.fad.fa-swap:after, .fa-duotone.fa-swap:after {
  content: "";
}

.fad.fa-garage:after, .fa-duotone.fa-garage:after {
  content: "";
}

.fad.fa-cable-car:after, .fa-duotone.fa-cable-car:after, .fad.fa-tram:after, .fa-duotone.fa-tram:after {
  content: "";
}

.fad.fa-shovel-snow:after, .fa-duotone.fa-shovel-snow:after {
  content: "";
}

.fad.fa-cloud-rain:after, .fa-duotone.fa-cloud-rain:after {
  content: "";
}

.fad.fa-face-lying:after, .fa-duotone.fa-face-lying:after {
  content: "";
}

.fad.fa-sprinkler:after, .fa-duotone.fa-sprinkler:after {
  content: "";
}

.fad.fa-building-circle-xmark:after, .fa-duotone.fa-building-circle-xmark:after {
  content: "";
}

.fad.fa-person-sledding:after, .fa-duotone.fa-person-sledding:after, .fad.fa-sledding:after, .fa-duotone.fa-sledding:after {
  content: "";
}

.fad.fa-game-console-handheld:after, .fa-duotone.fa-game-console-handheld:after {
  content: "";
}

.fad.fa-ship:after, .fa-duotone.fa-ship:after {
  content: "";
}

.fad.fa-clock-six-thirty:after, .fa-duotone.fa-clock-six-thirty:after {
  content: "";
}

.fad.fa-battery-slash:after, .fa-duotone.fa-battery-slash:after {
  content: "";
}

.fad.fa-tugrik-sign:after, .fa-duotone.fa-tugrik-sign:after {
  content: "";
}

.fad.fa-arrows-down-to-line:after, .fa-duotone.fa-arrows-down-to-line:after {
  content: "";
}

.fad.fa-download:after, .fa-duotone.fa-download:after {
  content: "";
}

.fad.fa-angles-up-down:after, .fa-duotone.fa-angles-up-down:after {
  content: "";
}

.fad.fa-shelves:after, .fa-duotone.fa-shelves:after, .fad.fa-inventory:after, .fa-duotone.fa-inventory:after {
  content: "";
}

.fad.fa-cloud-snow:after, .fa-duotone.fa-cloud-snow:after {
  content: "";
}

.fad.fa-face-grin:after, .fa-duotone.fa-face-grin:after, .fad.fa-grin:after, .fa-duotone.fa-grin:after {
  content: "";
}

.fad.fa-delete-left:after, .fa-duotone.fa-delete-left:after, .fad.fa-backspace:after, .fa-duotone.fa-backspace:after {
  content: "";
}

.fad.fa-oven:after, .fa-duotone.fa-oven:after {
  content: "";
}

.fad.fa-cloud-binary:after, .fa-duotone.fa-cloud-binary:after {
  content: "";
}

.fad.fa-eye-dropper:after, .fa-duotone.fa-eye-dropper:after, .fad.fa-eye-dropper-empty:after, .fa-duotone.fa-eye-dropper-empty:after, .fad.fa-eyedropper:after, .fa-duotone.fa-eyedropper:after {
  content: "";
}

.fad.fa-comment-captions:after, .fa-duotone.fa-comment-captions:after {
  content: "";
}

.fad.fa-comments-question:after, .fa-duotone.fa-comments-question:after {
  content: "";
}

.fad.fa-scribble:after, .fa-duotone.fa-scribble:after {
  content: "";
}

.fad.fa-rotate-exclamation:after, .fa-duotone.fa-rotate-exclamation:after {
  content: "";
}

.fad.fa-file-circle-check:after, .fa-duotone.fa-file-circle-check:after {
  content: "";
}

.fad.fa-glass:after, .fa-duotone.fa-glass:after {
  content: "";
}

.fad.fa-loader:after, .fa-duotone.fa-loader:after {
  content: "";
}

.fad.fa-forward:after, .fa-duotone.fa-forward:after {
  content: "";
}

.fad.fa-user-pilot:after, .fa-duotone.fa-user-pilot:after {
  content: "";
}

.fad.fa-mobile:after, .fa-duotone.fa-mobile:after, .fad.fa-mobile-android:after, .fa-duotone.fa-mobile-android:after, .fad.fa-mobile-phone:after, .fa-duotone.fa-mobile-phone:after {
  content: "";
}

.fad.fa-code-pull-request-closed:after, .fa-duotone.fa-code-pull-request-closed:after {
  content: "";
}

.fad.fa-face-meh:after, .fa-duotone.fa-face-meh:after, .fad.fa-meh:after, .fa-duotone.fa-meh:after {
  content: "";
}

.fad.fa-align-center:after, .fa-duotone.fa-align-center:after {
  content: "";
}

.fad.fa-book-skull:after, .fa-duotone.fa-book-skull:after, .fad.fa-book-dead:after, .fa-duotone.fa-book-dead:after {
  content: "";
}

.fad.fa-id-card:after, .fa-duotone.fa-id-card:after, .fad.fa-drivers-license:after, .fa-duotone.fa-drivers-license:after {
  content: "";
}

.fad.fa-face-dotted:after, .fa-duotone.fa-face-dotted:after {
  content: "";
}

.fad.fa-face-worried:after, .fa-duotone.fa-face-worried:after {
  content: "";
}

.fad.fa-outdent:after, .fa-duotone.fa-outdent:after, .fad.fa-dedent:after, .fa-duotone.fa-dedent:after {
  content: "";
}

.fad.fa-court-sport:after, .fa-duotone.fa-court-sport:after {
  content: "";
}

.fad.fa-heart-circle-exclamation:after, .fa-duotone.fa-heart-circle-exclamation:after {
  content: "";
}

.fad.fa-house:after, .fa-duotone.fa-house:after, .fad.fa-home:after, .fa-duotone.fa-home:after, .fad.fa-home-alt:after, .fa-duotone.fa-home-alt:after, .fad.fa-home-lg-alt:after, .fa-duotone.fa-home-lg-alt:after {
  content: "";
}

.fad.fa-vector-circle:after, .fa-duotone.fa-vector-circle:after {
  content: "";
}

.fad.fa-car-circle-bolt:after, .fa-duotone.fa-car-circle-bolt:after {
  content: "";
}

.fad.fa-calendar-week:after, .fa-duotone.fa-calendar-week:after {
  content: "";
}

.fad.fa-flying-disc:after, .fa-duotone.fa-flying-disc:after {
  content: "";
}

.fad.fa-laptop-medical:after, .fa-duotone.fa-laptop-medical:after {
  content: "";
}

.fad.fa-square-down-right:after, .fa-duotone.fa-square-down-right:after {
  content: "";
}

.fad.fa-b:after, .fa-duotone.fa-b:after {
  content: "BB";
}

.fad.fa-seat-airline:after, .fa-duotone.fa-seat-airline:after {
  content: "";
}

.fad.fa-moon-over-sun:after, .fa-duotone.fa-moon-over-sun:after, .fad.fa-eclipse-alt:after, .fa-duotone.fa-eclipse-alt:after {
  content: "";
}

.fad.fa-pipe:after, .fa-duotone.fa-pipe:after {
  content: "||";
}

.fad.fa-file-medical:after, .fa-duotone.fa-file-medical:after {
  content: "";
}

.fad.fa-potato:after, .fa-duotone.fa-potato:after {
  content: "";
}

.fad.fa-dice-one:after, .fa-duotone.fa-dice-one:after {
  content: "";
}

.fad.fa-circle-a:after, .fa-duotone.fa-circle-a:after {
  content: "";
}

.fad.fa-helmet-battle:after, .fa-duotone.fa-helmet-battle:after {
  content: "";
}

.fad.fa-butter:after, .fa-duotone.fa-butter:after {
  content: "";
}

.fad.fa-blanket-fire:after, .fa-duotone.fa-blanket-fire:after {
  content: "";
}

.fad.fa-kiwi-bird:after, .fa-duotone.fa-kiwi-bird:after {
  content: "";
}

.fad.fa-castle:after, .fa-duotone.fa-castle:after {
  content: "";
}

.fad.fa-golf-club:after, .fa-duotone.fa-golf-club:after {
  content: "";
}

.fad.fa-arrow-right-arrow-left:after, .fa-duotone.fa-arrow-right-arrow-left:after, .fad.fa-exchange:after, .fa-duotone.fa-exchange:after {
  content: "";
}

.fad.fa-rotate-right:after, .fa-duotone.fa-rotate-right:after, .fad.fa-redo-alt:after, .fa-duotone.fa-redo-alt:after, .fad.fa-rotate-forward:after, .fa-duotone.fa-rotate-forward:after {
  content: "";
}

.fad.fa-utensils:after, .fa-duotone.fa-utensils:after, .fad.fa-cutlery:after, .fa-duotone.fa-cutlery:after {
  content: "";
}

.fad.fa-arrow-up-wide-short:after, .fa-duotone.fa-arrow-up-wide-short:after, .fad.fa-sort-amount-up:after, .fa-duotone.fa-sort-amount-up:after {
  content: "";
}

.fad.fa-chart-pie-simple-circle-dollar:after, .fa-duotone.fa-chart-pie-simple-circle-dollar:after {
  content: "";
}

.fad.fa-balloons:after, .fa-duotone.fa-balloons:after {
  content: "";
}

.fad.fa-mill-sign:after, .fa-duotone.fa-mill-sign:after {
  content: "";
}

.fad.fa-bowl-rice:after, .fa-duotone.fa-bowl-rice:after {
  content: "";
}

.fad.fa-timeline-arrow:after, .fa-duotone.fa-timeline-arrow:after {
  content: "";
}

.fad.fa-skull:after, .fa-duotone.fa-skull:after {
  content: "";
}

.fad.fa-game-board-simple:after, .fa-duotone.fa-game-board-simple:after, .fad.fa-game-board-alt:after, .fa-duotone.fa-game-board-alt:after {
  content: "";
}

.fad.fa-circle-video:after, .fa-duotone.fa-circle-video:after, .fad.fa-video-circle:after, .fa-duotone.fa-video-circle:after {
  content: "";
}

.fad.fa-chart-scatter-bubble:after, .fa-duotone.fa-chart-scatter-bubble:after {
  content: "";
}

.fad.fa-house-turret:after, .fa-duotone.fa-house-turret:after {
  content: "";
}

.fad.fa-banana:after, .fa-duotone.fa-banana:after {
  content: "";
}

.fad.fa-hand-holding-skull:after, .fa-duotone.fa-hand-holding-skull:after {
  content: "";
}

.fad.fa-people-dress:after, .fa-duotone.fa-people-dress:after {
  content: "";
}

.fad.fa-loveseat:after, .fa-duotone.fa-loveseat:after, .fad.fa-couch-small:after, .fa-duotone.fa-couch-small:after {
  content: "";
}

.fad.fa-tower-broadcast:after, .fa-duotone.fa-tower-broadcast:after, .fad.fa-broadcast-tower:after, .fa-duotone.fa-broadcast-tower:after {
  content: "";
}

.fad.fa-truck-pickup:after, .fa-duotone.fa-truck-pickup:after {
  content: "";
}

.fad.fa-block-quote:after, .fa-duotone.fa-block-quote:after {
  content: "";
}

.fad.fa-up-long:after, .fa-duotone.fa-up-long:after, .fad.fa-long-arrow-alt-up:after, .fa-duotone.fa-long-arrow-alt-up:after {
  content: "";
}

.fad.fa-stop:after, .fa-duotone.fa-stop:after {
  content: "";
}

.fad.fa-code-merge:after, .fa-duotone.fa-code-merge:after {
  content: "";
}

.fad.fa-money-check-dollar-pen:after, .fa-duotone.fa-money-check-dollar-pen:after, .fad.fa-money-check-edit-alt:after, .fa-duotone.fa-money-check-edit-alt:after {
  content: "";
}

.fad.fa-up-from-line:after, .fa-duotone.fa-up-from-line:after, .fad.fa-arrow-alt-from-bottom:after, .fa-duotone.fa-arrow-alt-from-bottom:after {
  content: "";
}

.fad.fa-upload:after, .fa-duotone.fa-upload:after {
  content: "";
}

.fad.fa-hurricane:after, .fa-duotone.fa-hurricane:after {
  content: "";
}

.fad.fa-grid-round-2-plus:after, .fa-duotone.fa-grid-round-2-plus:after {
  content: "";
}

.fad.fa-people-pants:after, .fa-duotone.fa-people-pants:after {
  content: "";
}

.fad.fa-mound:after, .fa-duotone.fa-mound:after {
  content: "";
}

.fad.fa-windsock:after, .fa-duotone.fa-windsock:after {
  content: "";
}

.fad.fa-circle-half:after, .fa-duotone.fa-circle-half:after {
  content: "";
}

.fad.fa-brake-warning:after, .fa-duotone.fa-brake-warning:after {
  content: "";
}

.fad.fa-toilet-portable:after, .fa-duotone.fa-toilet-portable:after {
  content: "";
}

.fad.fa-compact-disc:after, .fa-duotone.fa-compact-disc:after {
  content: "";
}

.fad.fa-file-arrow-down:after, .fa-duotone.fa-file-arrow-down:after, .fad.fa-file-download:after, .fa-duotone.fa-file-download:after {
  content: "";
}

.fad.fa-saxophone-fire:after, .fa-duotone.fa-saxophone-fire:after, .fad.fa-sax-hot:after, .fa-duotone.fa-sax-hot:after {
  content: "";
}

.fad.fa-camera-web-slash:after, .fa-duotone.fa-camera-web-slash:after, .fad.fa-webcam-slash:after, .fa-duotone.fa-webcam-slash:after {
  content: "";
}

.fad.fa-folder-medical:after, .fa-duotone.fa-folder-medical:after {
  content: "";
}

.fad.fa-folder-gear:after, .fa-duotone.fa-folder-gear:after, .fad.fa-folder-cog:after, .fa-duotone.fa-folder-cog:after {
  content: "";
}

.fad.fa-hand-wave:after, .fa-duotone.fa-hand-wave:after {
  content: "";
}

.fad.fa-arrow-up-arrow-down:after, .fa-duotone.fa-arrow-up-arrow-down:after, .fad.fa-sort-up-down:after, .fa-duotone.fa-sort-up-down:after {
  content: "";
}

.fad.fa-caravan:after, .fa-duotone.fa-caravan:after {
  content: "";
}

.fad.fa-shield-cat:after, .fa-duotone.fa-shield-cat:after {
  content: "";
}

.fad.fa-message-slash:after, .fa-duotone.fa-message-slash:after, .fad.fa-comment-alt-slash:after, .fa-duotone.fa-comment-alt-slash:after {
  content: "";
}

.fad.fa-bolt:after, .fa-duotone.fa-bolt:after, .fad.fa-zap:after, .fa-duotone.fa-zap:after {
  content: "";
}

.fad.fa-trash-can-check:after, .fa-duotone.fa-trash-can-check:after {
  content: "";
}

.fad.fa-glass-water:after, .fa-duotone.fa-glass-water:after {
  content: "";
}

.fad.fa-oil-well:after, .fa-duotone.fa-oil-well:after {
  content: "";
}

.fad.fa-table-cells-column-unlock:after, .fa-duotone.fa-table-cells-column-unlock:after {
  content: "";
}

.fad.fa-person-simple:after, .fa-duotone.fa-person-simple:after {
  content: "";
}

.fad.fa-arrow-turn-left-up:after, .fa-duotone.fa-arrow-turn-left-up:after {
  content: "";
}

.fad.fa-vault:after, .fa-duotone.fa-vault:after {
  content: "";
}

.fad.fa-mars:after, .fa-duotone.fa-mars:after {
  content: "";
}

.fad.fa-toilet:after, .fa-duotone.fa-toilet:after {
  content: "";
}

.fad.fa-plane-circle-xmark:after, .fa-duotone.fa-plane-circle-xmark:after {
  content: "";
}

.fad.fa-yen-sign:after, .fa-duotone.fa-yen-sign:after, .fad.fa-cny:after, .fa-duotone.fa-cny:after, .fad.fa-jpy:after, .fa-duotone.fa-jpy:after, .fad.fa-rmb:after, .fa-duotone.fa-rmb:after, .fad.fa-yen:after, .fa-duotone.fa-yen:after {
  content: "";
}

.fad.fa-gear-code:after, .fa-duotone.fa-gear-code:after {
  content: "";
}

.fad.fa-notes:after, .fa-duotone.fa-notes:after {
  content: "";
}

.fad.fa-ruble-sign:after, .fa-duotone.fa-ruble-sign:after, .fad.fa-rouble:after, .fa-duotone.fa-rouble:after, .fad.fa-rub:after, .fa-duotone.fa-rub:after, .fad.fa-ruble:after, .fa-duotone.fa-ruble:after {
  content: "";
}

.fad.fa-trash-undo:after, .fa-duotone.fa-trash-undo:after, .fad.fa-trash-arrow-turn-left:after, .fa-duotone.fa-trash-arrow-turn-left:after {
  content: "";
}

.fad.fa-champagne-glass:after, .fa-duotone.fa-champagne-glass:after, .fad.fa-glass-champagne:after, .fa-duotone.fa-glass-champagne:after {
  content: "";
}

.fad.fa-objects-align-center-horizontal:after, .fa-duotone.fa-objects-align-center-horizontal:after {
  content: "";
}

.fad.fa-sun:after, .fa-duotone.fa-sun:after {
  content: "";
}

.fad.fa-trash-can-slash:after, .fa-duotone.fa-trash-can-slash:after, .fad.fa-trash-alt-slash:after, .fa-duotone.fa-trash-alt-slash:after {
  content: "";
}

.fad.fa-screen-users:after, .fa-duotone.fa-screen-users:after, .fad.fa-users-class:after, .fa-duotone.fa-users-class:after {
  content: "";
}

.fad.fa-guitar:after, .fa-duotone.fa-guitar:after {
  content: "";
}

.fad.fa-square-arrow-left:after, .fa-duotone.fa-square-arrow-left:after, .fad.fa-arrow-square-left:after, .fa-duotone.fa-arrow-square-left:after {
  content: "";
}

.fad.fa-square-8:after, .fa-duotone.fa-square-8:after {
  content: "";
}

.fad.fa-face-smile-hearts:after, .fa-duotone.fa-face-smile-hearts:after {
  content: "";
}

.fad.fa-brackets-square:after, .fa-duotone.fa-brackets-square:after, .fad.fa-brackets:after, .fa-duotone.fa-brackets:after {
  content: "";
}

.fad.fa-laptop-arrow-down:after, .fa-duotone.fa-laptop-arrow-down:after {
  content: "";
}

.fad.fa-hockey-stick-puck:after, .fa-duotone.fa-hockey-stick-puck:after {
  content: "";
}

.fad.fa-house-tree:after, .fa-duotone.fa-house-tree:after {
  content: "";
}

.fad.fa-signal-fair:after, .fa-duotone.fa-signal-fair:after, .fad.fa-signal-2:after, .fa-duotone.fa-signal-2:after {
  content: "";
}

.fad.fa-face-laugh-wink:after, .fa-duotone.fa-face-laugh-wink:after, .fad.fa-laugh-wink:after, .fa-duotone.fa-laugh-wink:after {
  content: "";
}

.fad.fa-circle-dollar:after, .fa-duotone.fa-circle-dollar:after, .fad.fa-dollar-circle:after, .fa-duotone.fa-dollar-circle:after, .fad.fa-usd-circle:after, .fa-duotone.fa-usd-circle:after {
  content: "";
}

.fad.fa-horse-head:after, .fa-duotone.fa-horse-head:after {
  content: "";
}

.fad.fa-arrows-repeat:after, .fa-duotone.fa-arrows-repeat:after, .fad.fa-repeat-alt:after, .fa-duotone.fa-repeat-alt:after {
  content: "";
}

.fad.fa-bore-hole:after, .fa-duotone.fa-bore-hole:after {
  content: "";
}

.fad.fa-industry:after, .fa-duotone.fa-industry:after {
  content: "";
}

.fad.fa-image-polaroid:after, .fa-duotone.fa-image-polaroid:after {
  content: "";
}

.fad.fa-wave-triangle:after, .fa-duotone.fa-wave-triangle:after {
  content: "";
}

.fad.fa-turn-left-down:after, .fa-duotone.fa-turn-left-down:after {
  content: "";
}

.fad.fa-person-running-fast:after, .fa-duotone.fa-person-running-fast:after {
  content: "";
}

.fad.fa-circle-down:after, .fa-duotone.fa-circle-down:after, .fad.fa-arrow-alt-circle-down:after, .fa-duotone.fa-arrow-alt-circle-down:after {
  content: "";
}

.fad.fa-grill:after, .fa-duotone.fa-grill:after {
  content: "";
}

.fad.fa-arrows-turn-to-dots:after, .fa-duotone.fa-arrows-turn-to-dots:after {
  content: "";
}

.fad.fa-chart-mixed:after, .fa-duotone.fa-chart-mixed:after, .fad.fa-analytics:after, .fa-duotone.fa-analytics:after {
  content: "";
}

.fad.fa-florin-sign:after, .fa-duotone.fa-florin-sign:after {
  content: "";
}

.fad.fa-arrow-down-short-wide:after, .fa-duotone.fa-arrow-down-short-wide:after, .fad.fa-sort-amount-desc:after, .fa-duotone.fa-sort-amount-desc:after, .fad.fa-sort-amount-down-alt:after, .fa-duotone.fa-sort-amount-down-alt:after {
  content: "";
}

.fad.fa-less-than:after, .fa-duotone.fa-less-than:after {
  content: "<<";
}

.fad.fa-display-code:after, .fa-duotone.fa-display-code:after, .fad.fa-desktop-code:after, .fa-duotone.fa-desktop-code:after {
  content: "";
}

.fad.fa-face-drooling:after, .fa-duotone.fa-face-drooling:after {
  content: "";
}

.fad.fa-oil-temperature:after, .fa-duotone.fa-oil-temperature:after, .fad.fa-oil-temp:after, .fa-duotone.fa-oil-temp:after {
  content: "";
}

.fad.fa-square-question:after, .fa-duotone.fa-square-question:after, .fad.fa-question-square:after, .fa-duotone.fa-question-square:after {
  content: "";
}

.fad.fa-air-conditioner:after, .fa-duotone.fa-air-conditioner:after {
  content: "";
}

.fad.fa-angle-down:after, .fa-duotone.fa-angle-down:after {
  content: "";
}

.fad.fa-mountains:after, .fa-duotone.fa-mountains:after {
  content: "";
}

.fad.fa-omega:after, .fa-duotone.fa-omega:after {
  content: "";
}

.fad.fa-car-tunnel:after, .fa-duotone.fa-car-tunnel:after {
  content: "";
}

.fad.fa-person-dolly-empty:after, .fa-duotone.fa-person-dolly-empty:after {
  content: "";
}

.fad.fa-pan-food:after, .fa-duotone.fa-pan-food:after {
  content: "";
}

.fad.fa-head-side-cough:after, .fa-duotone.fa-head-side-cough:after {
  content: "";
}

.fad.fa-grip-lines:after, .fa-duotone.fa-grip-lines:after {
  content: "";
}

.fad.fa-thumbs-down:after, .fa-duotone.fa-thumbs-down:after {
  content: "";
}

.fad.fa-user-lock:after, .fa-duotone.fa-user-lock:after {
  content: "";
}

.fad.fa-arrow-right-long:after, .fa-duotone.fa-arrow-right-long:after, .fad.fa-long-arrow-right:after, .fa-duotone.fa-long-arrow-right:after {
  content: "";
}

.fad.fa-tickets-airline:after, .fa-duotone.fa-tickets-airline:after, .fad.fa-tickets-perforated-plane:after, .fa-duotone.fa-tickets-perforated-plane:after, .fad.fa-tickets-plane:after, .fa-duotone.fa-tickets-plane:after {
  content: "";
}

.fad.fa-tent-double-peak:after, .fa-duotone.fa-tent-double-peak:after {
  content: "";
}

.fad.fa-anchor-circle-xmark:after, .fa-duotone.fa-anchor-circle-xmark:after {
  content: "";
}

.fad.fa-ellipsis:after, .fa-duotone.fa-ellipsis:after, .fad.fa-ellipsis-h:after, .fa-duotone.fa-ellipsis-h:after {
  content: "";
}

.fad.fa-nfc-slash:after, .fa-duotone.fa-nfc-slash:after {
  content: "";
}

.fad.fa-chess-pawn:after, .fa-duotone.fa-chess-pawn:after {
  content: "";
}

.fad.fa-kit-medical:after, .fa-duotone.fa-kit-medical:after, .fad.fa-first-aid:after, .fa-duotone.fa-first-aid:after {
  content: "";
}

.fad.fa-grid-2-plus:after, .fa-duotone.fa-grid-2-plus:after {
  content: "";
}

.fad.fa-bells:after, .fa-duotone.fa-bells:after {
  content: "";
}

.fad.fa-person-through-window:after, .fa-duotone.fa-person-through-window:after {
  content: "";
}

.fad.fa-toolbox:after, .fa-duotone.fa-toolbox:after {
  content: "";
}

.fad.fa-globe-wifi:after, .fa-duotone.fa-globe-wifi:after {
  content: "";
}

.fad.fa-envelope-dot:after, .fa-duotone.fa-envelope-dot:after, .fad.fa-envelope-badge:after, .fa-duotone.fa-envelope-badge:after {
  content: "";
}

.fad.fa-magnifying-glass-waveform:after, .fa-duotone.fa-magnifying-glass-waveform:after {
  content: "";
}

.fad.fa-hands-holding-circle:after, .fa-duotone.fa-hands-holding-circle:after {
  content: "";
}

.fad.fa-bug:after, .fa-duotone.fa-bug:after {
  content: "";
}

.fad.fa-bowl-chopsticks:after, .fa-duotone.fa-bowl-chopsticks:after {
  content: "";
}

.fad.fa-credit-card:after, .fa-duotone.fa-credit-card:after, .fad.fa-credit-card-alt:after, .fa-duotone.fa-credit-card-alt:after {
  content: "";
}

.fad.fa-circle-s:after, .fa-duotone.fa-circle-s:after {
  content: "";
}

.fad.fa-box-ballot:after, .fa-duotone.fa-box-ballot:after {
  content: "";
}

.fad.fa-car:after, .fa-duotone.fa-car:after, .fad.fa-automobile:after, .fa-duotone.fa-automobile:after {
  content: "";
}

.fad.fa-hand-holding-hand:after, .fa-duotone.fa-hand-holding-hand:after {
  content: "";
}

.fad.fa-user-tie-hair:after, .fa-duotone.fa-user-tie-hair:after {
  content: "";
}

.fad.fa-podium-star:after, .fa-duotone.fa-podium-star:after {
  content: "";
}

.fad.fa-user-hair-mullet:after, .fa-duotone.fa-user-hair-mullet:after, .fad.fa-business-front:after, .fa-duotone.fa-business-front:after, .fad.fa-party-back:after, .fa-duotone.fa-party-back:after, .fad.fa-trian-balbot:after, .fa-duotone.fa-trian-balbot:after {
  content: "";
}

.fad.fa-microphone-stand:after, .fa-duotone.fa-microphone-stand:after {
  content: "";
}

.fad.fa-book-open-reader:after, .fa-duotone.fa-book-open-reader:after, .fad.fa-book-reader:after, .fa-duotone.fa-book-reader:after {
  content: "";
}

.fad.fa-family-dress:after, .fa-duotone.fa-family-dress:after {
  content: "";
}

.fad.fa-circle-x:after, .fa-duotone.fa-circle-x:after {
  content: "";
}

.fad.fa-cabin:after, .fa-duotone.fa-cabin:after {
  content: "";
}

.fad.fa-mountain-sun:after, .fa-duotone.fa-mountain-sun:after {
  content: "";
}

.fad.fa-chart-simple-horizontal:after, .fa-duotone.fa-chart-simple-horizontal:after {
  content: "";
}

.fad.fa-arrows-left-right-to-line:after, .fa-duotone.fa-arrows-left-right-to-line:after {
  content: "";
}

.fad.fa-hand-back-point-left:after, .fa-duotone.fa-hand-back-point-left:after {
  content: "";
}

.fad.fa-message-dots:after, .fa-duotone.fa-message-dots:after, .fad.fa-comment-alt-dots:after, .fa-duotone.fa-comment-alt-dots:after, .fad.fa-messaging:after, .fa-duotone.fa-messaging:after {
  content: "";
}

.fad.fa-file-heart:after, .fa-duotone.fa-file-heart:after {
  content: "";
}

.fad.fa-beer-mug:after, .fa-duotone.fa-beer-mug:after, .fad.fa-beer-foam:after, .fa-duotone.fa-beer-foam:after {
  content: "";
}

.fad.fa-dice-d20:after, .fa-duotone.fa-dice-d20:after {
  content: "";
}

.fad.fa-drone:after, .fa-duotone.fa-drone:after {
  content: "";
}

.fad.fa-truck-droplet:after, .fa-duotone.fa-truck-droplet:after {
  content: "";
}

.fad.fa-file-circle-xmark:after, .fa-duotone.fa-file-circle-xmark:after {
  content: "";
}

.fad.fa-temperature-arrow-up:after, .fa-duotone.fa-temperature-arrow-up:after, .fad.fa-temperature-up:after, .fa-duotone.fa-temperature-up:after {
  content: "";
}

.fad.fa-medal:after, .fa-duotone.fa-medal:after {
  content: "";
}

.fad.fa-person-fairy:after, .fa-duotone.fa-person-fairy:after {
  content: "";
}

.fad.fa-bed:after, .fa-duotone.fa-bed:after {
  content: "";
}

.fad.fa-book-copy:after, .fa-duotone.fa-book-copy:after {
  content: "";
}

.fad.fa-square-h:after, .fa-duotone.fa-square-h:after, .fad.fa-h-square:after, .fa-duotone.fa-h-square:after {
  content: "";
}

.fad.fa-square-c:after, .fa-duotone.fa-square-c:after {
  content: "";
}

.fad.fa-clock-two:after, .fa-duotone.fa-clock-two:after {
  content: "";
}

.fad.fa-square-ellipsis-vertical:after, .fa-duotone.fa-square-ellipsis-vertical:after {
  content: "";
}

.fad.fa-calendar-users:after, .fa-duotone.fa-calendar-users:after {
  content: "";
}

.fad.fa-podcast:after, .fa-duotone.fa-podcast:after {
  content: "";
}

.fad.fa-bee:after, .fa-duotone.fa-bee:after {
  content: "";
}

.fad.fa-temperature-full:after, .fa-duotone.fa-temperature-full:after, .fad.fa-temperature-4:after, .fa-duotone.fa-temperature-4:after, .fad.fa-thermometer-4:after, .fa-duotone.fa-thermometer-4:after, .fad.fa-thermometer-full:after, .fa-duotone.fa-thermometer-full:after {
  content: "";
}

.fad.fa-bell:after, .fa-duotone.fa-bell:after {
  content: "";
}

.fad.fa-candy-bar:after, .fa-duotone.fa-candy-bar:after, .fad.fa-chocolate-bar:after, .fa-duotone.fa-chocolate-bar:after {
  content: "";
}

.fad.fa-xmark-large:after, .fa-duotone.fa-xmark-large:after {
  content: "";
}

.fad.fa-pinata:after, .fa-duotone.fa-pinata:after {
  content: "";
}

.fad.fa-file-ppt:after, .fa-duotone.fa-file-ppt:after {
  content: "";
}

.fad.fa-arrows-from-line:after, .fa-duotone.fa-arrows-from-line:after {
  content: "";
}

.fad.fa-superscript:after, .fa-duotone.fa-superscript:after {
  content: "";
}

.fad.fa-bowl-spoon:after, .fa-duotone.fa-bowl-spoon:after {
  content: "";
}

.fad.fa-hexagon-check:after, .fa-duotone.fa-hexagon-check:after {
  content: "";
}

.fad.fa-plug-circle-xmark:after, .fa-duotone.fa-plug-circle-xmark:after {
  content: "";
}

.fad.fa-star-of-life:after, .fa-duotone.fa-star-of-life:after {
  content: "";
}

.fad.fa-phone-slash:after, .fa-duotone.fa-phone-slash:after {
  content: "";
}

.fad.fa-traffic-light-stop:after, .fa-duotone.fa-traffic-light-stop:after {
  content: "";
}

.fad.fa-paint-roller:after, .fa-duotone.fa-paint-roller:after {
  content: "";
}

.fad.fa-accent-grave:after, .fa-duotone.fa-accent-grave:after {
  content: "``";
}

.fad.fa-handshake-angle:after, .fa-duotone.fa-handshake-angle:after, .fad.fa-hands-helping:after, .fa-duotone.fa-hands-helping:after {
  content: "";
}

.fad.fa-circle-0:after, .fa-duotone.fa-circle-0:after {
  content: "";
}

.fad.fa-dial-med-low:after, .fa-duotone.fa-dial-med-low:after {
  content: "";
}

.fad.fa-location-dot:after, .fa-duotone.fa-location-dot:after, .fad.fa-map-marker-alt:after, .fa-duotone.fa-map-marker-alt:after {
  content: "";
}

.fad.fa-crab:after, .fa-duotone.fa-crab:after {
  content: "";
}

.fad.fa-box-open-full:after, .fa-duotone.fa-box-open-full:after, .fad.fa-box-full:after, .fa-duotone.fa-box-full:after {
  content: "";
}

.fad.fa-file:after, .fa-duotone.fa-file:after {
  content: "";
}

.fad.fa-greater-than:after, .fa-duotone.fa-greater-than:after {
  content: ">>";
}

.fad.fa-quotes:after, .fa-duotone.fa-quotes:after {
  content: "";
}

.fad.fa-pretzel:after, .fa-duotone.fa-pretzel:after {
  content: "";
}

.fad.fa-t-rex:after, .fa-duotone.fa-t-rex:after {
  content: "";
}

.fad.fa-person-swimming:after, .fa-duotone.fa-person-swimming:after, .fad.fa-swimmer:after, .fa-duotone.fa-swimmer:after {
  content: "";
}

.fad.fa-arrow-down:after, .fa-duotone.fa-arrow-down:after {
  content: "";
}

.fad.fa-user-robot-xmarks:after, .fa-duotone.fa-user-robot-xmarks:after {
  content: "";
}

.fad.fa-message-quote:after, .fa-duotone.fa-message-quote:after, .fad.fa-comment-alt-quote:after, .fa-duotone.fa-comment-alt-quote:after {
  content: "";
}

.fad.fa-candy-corn:after, .fa-duotone.fa-candy-corn:after {
  content: "";
}

.fad.fa-folder-magnifying-glass:after, .fa-duotone.fa-folder-magnifying-glass:after, .fad.fa-folder-search:after, .fa-duotone.fa-folder-search:after {
  content: "";
}

.fad.fa-notebook:after, .fa-duotone.fa-notebook:after {
  content: "";
}

.fad.fa-circle-wifi:after, .fa-duotone.fa-circle-wifi:after {
  content: "";
}

.fad.fa-droplet:after, .fa-duotone.fa-droplet:after, .fad.fa-tint:after, .fa-duotone.fa-tint:after {
  content: "";
}

.fad.fa-bullseye-pointer:after, .fa-duotone.fa-bullseye-pointer:after {
  content: "";
}

.fad.fa-eraser:after, .fa-duotone.fa-eraser:after {
  content: "";
}

.fad.fa-hexagon-image:after, .fa-duotone.fa-hexagon-image:after {
  content: "";
}

.fad.fa-earth-americas:after, .fa-duotone.fa-earth-americas:after, .fad.fa-earth:after, .fa-duotone.fa-earth:after, .fad.fa-earth-america:after, .fa-duotone.fa-earth-america:after, .fad.fa-globe-americas:after, .fa-duotone.fa-globe-americas:after {
  content: "";
}

.fad.fa-file-svg:after, .fa-duotone.fa-file-svg:after {
  content: "";
}

.fad.fa-crate-apple:after, .fa-duotone.fa-crate-apple:after, .fad.fa-apple-crate:after, .fa-duotone.fa-apple-crate:after {
  content: "";
}

.fad.fa-person-burst:after, .fa-duotone.fa-person-burst:after {
  content: "";
}

.fad.fa-game-board:after, .fa-duotone.fa-game-board:after {
  content: "";
}

.fad.fa-hat-chef:after, .fa-duotone.fa-hat-chef:after {
  content: "";
}

.fad.fa-hand-back-point-right:after, .fa-duotone.fa-hand-back-point-right:after {
  content: "";
}

.fad.fa-dove:after, .fa-duotone.fa-dove:after {
  content: "";
}

.fad.fa-snowflake-droplets:after, .fa-duotone.fa-snowflake-droplets:after {
  content: "";
}

.fad.fa-battery-empty:after, .fa-duotone.fa-battery-empty:after, .fad.fa-battery-0:after, .fa-duotone.fa-battery-0:after {
  content: "";
}

.fad.fa-grid-4:after, .fa-duotone.fa-grid-4:after {
  content: "";
}

.fad.fa-socks:after, .fa-duotone.fa-socks:after {
  content: "";
}

.fad.fa-face-sunglasses:after, .fa-duotone.fa-face-sunglasses:after {
  content: "";
}

.fad.fa-inbox:after, .fa-duotone.fa-inbox:after {
  content: "";
}

.fad.fa-square-0:after, .fa-duotone.fa-square-0:after {
  content: "";
}

.fad.fa-section:after, .fa-duotone.fa-section:after {
  content: "";
}

.fad.fa-square-this-way-up:after, .fa-duotone.fa-square-this-way-up:after, .fad.fa-box-up:after, .fa-duotone.fa-box-up:after {
  content: "";
}

.fad.fa-gauge-high:after, .fa-duotone.fa-gauge-high:after, .fad.fa-tachometer-alt:after, .fa-duotone.fa-tachometer-alt:after, .fad.fa-tachometer-alt-fast:after, .fa-duotone.fa-tachometer-alt-fast:after {
  content: "";
}

.fad.fa-square-ampersand:after, .fa-duotone.fa-square-ampersand:after {
  content: "";
}

.fad.fa-envelope-open-text:after, .fa-duotone.fa-envelope-open-text:after {
  content: "";
}

.fad.fa-lamp-desk:after, .fa-duotone.fa-lamp-desk:after {
  content: "";
}

.fad.fa-hospital:after, .fa-duotone.fa-hospital:after, .fad.fa-hospital-alt:after, .fa-duotone.fa-hospital-alt:after, .fad.fa-hospital-wide:after, .fa-duotone.fa-hospital-wide:after {
  content: "";
}

.fad.fa-poll-people:after, .fa-duotone.fa-poll-people:after {
  content: "";
}

.fad.fa-whiskey-glass-ice:after, .fa-duotone.fa-whiskey-glass-ice:after, .fad.fa-glass-whiskey-rocks:after, .fa-duotone.fa-glass-whiskey-rocks:after {
  content: "";
}

.fad.fa-wine-bottle:after, .fa-duotone.fa-wine-bottle:after {
  content: "";
}

.fad.fa-chess-rook:after, .fa-duotone.fa-chess-rook:after {
  content: "";
}

.fad.fa-user-bounty-hunter:after, .fa-duotone.fa-user-bounty-hunter:after {
  content: "";
}

.fad.fa-bars-staggered:after, .fa-duotone.fa-bars-staggered:after, .fad.fa-reorder:after, .fa-duotone.fa-reorder:after, .fad.fa-stream:after, .fa-duotone.fa-stream:after {
  content: "";
}

.fad.fa-diagram-sankey:after, .fa-duotone.fa-diagram-sankey:after {
  content: "";
}

.fad.fa-cloud-hail-mixed:after, .fa-duotone.fa-cloud-hail-mixed:after {
  content: "";
}

.fad.fa-circle-up-left:after, .fa-duotone.fa-circle-up-left:after {
  content: "";
}

.fad.fa-dharmachakra:after, .fa-duotone.fa-dharmachakra:after {
  content: "";
}

.fad.fa-objects-align-left:after, .fa-duotone.fa-objects-align-left:after {
  content: "";
}

.fad.fa-oil-can-drip:after, .fa-duotone.fa-oil-can-drip:after {
  content: "";
}

.fad.fa-face-smiling-hands:after, .fa-duotone.fa-face-smiling-hands:after {
  content: "";
}

.fad.fa-broccoli:after, .fa-duotone.fa-broccoli:after {
  content: "";
}

.fad.fa-route-interstate:after, .fa-duotone.fa-route-interstate:after {
  content: "";
}

.fad.fa-ear-muffs:after, .fa-duotone.fa-ear-muffs:after {
  content: "";
}

.fad.fa-hotdog:after, .fa-duotone.fa-hotdog:after {
  content: "";
}

.fad.fa-transporter-empty:after, .fa-duotone.fa-transporter-empty:after {
  content: "";
}

.fad.fa-person-walking-with-cane:after, .fa-duotone.fa-person-walking-with-cane:after, .fad.fa-blind:after, .fa-duotone.fa-blind:after {
  content: "";
}

.fad.fa-angle-90:after, .fa-duotone.fa-angle-90:after {
  content: "";
}

.fad.fa-rectangle-terminal:after, .fa-duotone.fa-rectangle-terminal:after {
  content: "";
}

.fad.fa-kite:after, .fa-duotone.fa-kite:after {
  content: "";
}

.fad.fa-drum:after, .fa-duotone.fa-drum:after {
  content: "";
}

.fad.fa-scrubber:after, .fa-duotone.fa-scrubber:after {
  content: "";
}

.fad.fa-ice-cream:after, .fa-duotone.fa-ice-cream:after {
  content: "";
}

.fad.fa-heart-circle-bolt:after, .fa-duotone.fa-heart-circle-bolt:after {
  content: "";
}

.fad.fa-fish-bones:after, .fa-duotone.fa-fish-bones:after {
  content: "";
}

.fad.fa-deer-rudolph:after, .fa-duotone.fa-deer-rudolph:after {
  content: "";
}

.fad.fa-fax:after, .fa-duotone.fa-fax:after {
  content: "";
}

.fad.fa-paragraph:after, .fa-duotone.fa-paragraph:after {
  content: "";
}

.fad.fa-head-side-heart:after, .fa-duotone.fa-head-side-heart:after {
  content: "";
}

.fad.fa-square-e:after, .fa-duotone.fa-square-e:after {
  content: "";
}

.fad.fa-meter-fire:after, .fa-duotone.fa-meter-fire:after {
  content: "";
}

.fad.fa-cloud-hail:after, .fa-duotone.fa-cloud-hail:after {
  content: "";
}

.fad.fa-check-to-slot:after, .fa-duotone.fa-check-to-slot:after, .fad.fa-vote-yea:after, .fa-duotone.fa-vote-yea:after {
  content: "";
}

.fad.fa-money-from-bracket:after, .fa-duotone.fa-money-from-bracket:after {
  content: "";
}

.fad.fa-star-half:after, .fa-duotone.fa-star-half:after {
  content: "";
}

.fad.fa-car-bus:after, .fa-duotone.fa-car-bus:after {
  content: "";
}

.fad.fa-speaker:after, .fa-duotone.fa-speaker:after {
  content: "";
}

.fad.fa-timer:after, .fa-duotone.fa-timer:after {
  content: "";
}

.fad.fa-boxes-stacked:after, .fa-duotone.fa-boxes-stacked:after, .fad.fa-boxes:after, .fa-duotone.fa-boxes:after, .fad.fa-boxes-alt:after, .fa-duotone.fa-boxes-alt:after {
  content: "";
}

.fad.fa-landmark-magnifying-glass:after, .fa-duotone.fa-landmark-magnifying-glass:after {
  content: "";
}

.fad.fa-grill-hot:after, .fa-duotone.fa-grill-hot:after {
  content: "";
}

.fad.fa-ballot-check:after, .fa-duotone.fa-ballot-check:after {
  content: "";
}

.fad.fa-link:after, .fa-duotone.fa-link:after, .fad.fa-chain:after, .fa-duotone.fa-chain:after {
  content: "";
}

.fad.fa-ear-listen:after, .fa-duotone.fa-ear-listen:after, .fad.fa-assistive-listening-systems:after, .fa-duotone.fa-assistive-listening-systems:after {
  content: "";
}

.fad.fa-file-minus:after, .fa-duotone.fa-file-minus:after {
  content: "";
}

.fad.fa-tree-city:after, .fa-duotone.fa-tree-city:after {
  content: "";
}

.fad.fa-play:after, .fa-duotone.fa-play:after {
  content: "";
}

.fad.fa-font:after, .fa-duotone.fa-font:after {
  content: "";
}

.fad.fa-cup-togo:after, .fa-duotone.fa-cup-togo:after, .fad.fa-coffee-togo:after, .fa-duotone.fa-coffee-togo:after {
  content: "";
}

.fad.fa-square-down-left:after, .fa-duotone.fa-square-down-left:after {
  content: "";
}

.fad.fa-burger-lettuce:after, .fa-duotone.fa-burger-lettuce:after {
  content: "";
}

.fad.fa-table-cells-row-lock:after, .fa-duotone.fa-table-cells-row-lock:after {
  content: "";
}

.fad.fa-rupiah-sign:after, .fa-duotone.fa-rupiah-sign:after {
  content: "";
}

.fad.fa-magnifying-glass:after, .fa-duotone.fa-magnifying-glass:after, .fad.fa-search:after, .fa-duotone.fa-search:after {
  content: "";
}

.fad.fa-table-tennis-paddle-ball:after, .fa-duotone.fa-table-tennis-paddle-ball:after, .fad.fa-ping-pong-paddle-ball:after, .fa-duotone.fa-ping-pong-paddle-ball:after, .fad.fa-table-tennis:after, .fa-duotone.fa-table-tennis:after {
  content: "";
}

.fad.fa-person-dots-from-line:after, .fa-duotone.fa-person-dots-from-line:after, .fad.fa-diagnoses:after, .fa-duotone.fa-diagnoses:after {
  content: "";
}

.fad.fa-chevrons-down:after, .fa-duotone.fa-chevrons-down:after, .fad.fa-chevron-double-down:after, .fa-duotone.fa-chevron-double-down:after {
  content: "";
}

.fad.fa-trash-can-arrow-up:after, .fa-duotone.fa-trash-can-arrow-up:after, .fad.fa-trash-restore-alt:after, .fa-duotone.fa-trash-restore-alt:after {
  content: "";
}

.fad.fa-signal-good:after, .fa-duotone.fa-signal-good:after, .fad.fa-signal-3:after, .fa-duotone.fa-signal-3:after {
  content: "";
}

.fad.fa-location-question:after, .fa-duotone.fa-location-question:after, .fad.fa-map-marker-question:after, .fa-duotone.fa-map-marker-question:after {
  content: "";
}

.fad.fa-floppy-disk-circle-xmark:after, .fa-duotone.fa-floppy-disk-circle-xmark:after, .fad.fa-floppy-disk-times:after, .fa-duotone.fa-floppy-disk-times:after, .fad.fa-save-circle-xmark:after, .fa-duotone.fa-save-circle-xmark:after, .fad.fa-save-times:after, .fa-duotone.fa-save-times:after {
  content: "";
}

.fad.fa-naira-sign:after, .fa-duotone.fa-naira-sign:after {
  content: "";
}

.fad.fa-peach:after, .fa-duotone.fa-peach:after {
  content: "";
}

.fad.fa-taxi-bus:after, .fa-duotone.fa-taxi-bus:after {
  content: "";
}

.fad.fa-bracket-curly:after, .fa-duotone.fa-bracket-curly:after, .fad.fa-bracket-curly-left:after, .fa-duotone.fa-bracket-curly-left:after {
  content: "{{";
}

.fad.fa-lobster:after, .fa-duotone.fa-lobster:after {
  content: "";
}

.fad.fa-cart-flatbed-empty:after, .fa-duotone.fa-cart-flatbed-empty:after, .fad.fa-dolly-flatbed-empty:after, .fa-duotone.fa-dolly-flatbed-empty:after {
  content: "";
}

.fad.fa-colon:after, .fa-duotone.fa-colon:after {
  content: "::";
}

.fad.fa-cart-arrow-down:after, .fa-duotone.fa-cart-arrow-down:after {
  content: "";
}

.fad.fa-wand:after, .fa-duotone.fa-wand:after {
  content: "";
}

.fad.fa-walkie-talkie:after, .fa-duotone.fa-walkie-talkie:after {
  content: "";
}

.fad.fa-file-pen:after, .fa-duotone.fa-file-pen:after, .fad.fa-file-edit:after, .fa-duotone.fa-file-edit:after {
  content: "";
}

.fad.fa-receipt:after, .fa-duotone.fa-receipt:after {
  content: "";
}

.fad.fa-table-picnic:after, .fa-duotone.fa-table-picnic:after {
  content: "";
}

.fad.fa-square-pen:after, .fa-duotone.fa-square-pen:after, .fad.fa-pen-square:after, .fa-duotone.fa-pen-square:after, .fad.fa-pencil-square:after, .fa-duotone.fa-pencil-square:after {
  content: "";
}

.fad.fa-circle-microphone-lines:after, .fa-duotone.fa-circle-microphone-lines:after, .fad.fa-microphone-circle-alt:after, .fa-duotone.fa-microphone-circle-alt:after {
  content: "";
}

.fad.fa-display-slash:after, .fa-duotone.fa-display-slash:after, .fad.fa-desktop-slash:after, .fa-duotone.fa-desktop-slash:after {
  content: "";
}

.fad.fa-suitcase-rolling:after, .fa-duotone.fa-suitcase-rolling:after {
  content: "";
}

.fad.fa-person-circle-exclamation:after, .fa-duotone.fa-person-circle-exclamation:after {
  content: "";
}

.fad.fa-transporter-2:after, .fa-duotone.fa-transporter-2:after {
  content: "";
}

.fad.fa-user-hoodie:after, .fa-duotone.fa-user-hoodie:after {
  content: "";
}

.fad.fa-hands-holding-diamond:after, .fa-duotone.fa-hands-holding-diamond:after, .fad.fa-hand-receiving:after, .fa-duotone.fa-hand-receiving:after {
  content: "";
}

.fad.fa-money-bill-simple-wave:after, .fa-duotone.fa-money-bill-simple-wave:after {
  content: "";
}

.fad.fa-chevron-down:after, .fa-duotone.fa-chevron-down:after {
  content: "";
}

.fad.fa-battery-full:after, .fa-duotone.fa-battery-full:after, .fad.fa-battery:after, .fa-duotone.fa-battery:after, .fad.fa-battery-5:after, .fa-duotone.fa-battery-5:after {
  content: "";
}

.fad.fa-bell-plus:after, .fa-duotone.fa-bell-plus:after {
  content: "";
}

.fad.fa-book-arrow-right:after, .fa-duotone.fa-book-arrow-right:after {
  content: "";
}

.fad.fa-hospitals:after, .fa-duotone.fa-hospitals:after {
  content: "";
}

.fad.fa-club:after, .fa-duotone.fa-club:after {
  content: "";
}

.fad.fa-skull-crossbones:after, .fa-duotone.fa-skull-crossbones:after {
  content: "";
}

.fad.fa-droplet-degree:after, .fa-duotone.fa-droplet-degree:after, .fad.fa-dewpoint:after, .fa-duotone.fa-dewpoint:after {
  content: "";
}

.fad.fa-code-compare:after, .fa-duotone.fa-code-compare:after {
  content: "";
}

.fad.fa-list-ul:after, .fa-duotone.fa-list-ul:after, .fad.fa-list-dots:after, .fa-duotone.fa-list-dots:after {
  content: "";
}

.fad.fa-hand-holding-magic:after, .fa-duotone.fa-hand-holding-magic:after {
  content: "";
}

.fad.fa-watermelon-slice:after, .fa-duotone.fa-watermelon-slice:after {
  content: "";
}

.fad.fa-circle-ellipsis:after, .fa-duotone.fa-circle-ellipsis:after {
  content: "";
}

.fad.fa-school-lock:after, .fa-duotone.fa-school-lock:after {
  content: "";
}

.fad.fa-tower-cell:after, .fa-duotone.fa-tower-cell:after {
  content: "";
}

.fad.fa-sd-cards:after, .fa-duotone.fa-sd-cards:after {
  content: "";
}

.fad.fa-jug-bottle:after, .fa-duotone.fa-jug-bottle:after {
  content: "";
}

.fad.fa-down-long:after, .fa-duotone.fa-down-long:after, .fad.fa-long-arrow-alt-down:after, .fa-duotone.fa-long-arrow-alt-down:after {
  content: "";
}

.fad.fa-envelopes:after, .fa-duotone.fa-envelopes:after {
  content: "";
}

.fad.fa-phone-office:after, .fa-duotone.fa-phone-office:after {
  content: "";
}

.fad.fa-ranking-star:after, .fa-duotone.fa-ranking-star:after {
  content: "";
}

.fad.fa-chess-king:after, .fa-duotone.fa-chess-king:after {
  content: "";
}

.fad.fa-nfc-pen:after, .fa-duotone.fa-nfc-pen:after {
  content: "";
}

.fad.fa-person-harassing:after, .fa-duotone.fa-person-harassing:after {
  content: "";
}

.fad.fa-magnifying-glass-play:after, .fa-duotone.fa-magnifying-glass-play:after {
  content: "";
}

.fad.fa-hat-winter:after, .fa-duotone.fa-hat-winter:after {
  content: "";
}

.fad.fa-brazilian-real-sign:after, .fa-duotone.fa-brazilian-real-sign:after {
  content: "";
}

.fad.fa-landmark-dome:after, .fa-duotone.fa-landmark-dome:after, .fad.fa-landmark-alt:after, .fa-duotone.fa-landmark-alt:after {
  content: "";
}

.fad.fa-bone-break:after, .fa-duotone.fa-bone-break:after {
  content: "";
}

.fad.fa-arrow-up:after, .fa-duotone.fa-arrow-up:after {
  content: "";
}

.fad.fa-down-from-dotted-line:after, .fa-duotone.fa-down-from-dotted-line:after {
  content: "";
}

.fad.fa-tv:after, .fa-duotone.fa-tv:after, .fad.fa-television:after, .fa-duotone.fa-television:after, .fad.fa-tv-alt:after, .fa-duotone.fa-tv-alt:after {
  content: "";
}

.fad.fa-border-left:after, .fa-duotone.fa-border-left:after {
  content: "";
}

.fad.fa-circle-divide:after, .fa-duotone.fa-circle-divide:after {
  content: "";
}

.fad.fa-shrimp:after, .fa-duotone.fa-shrimp:after {
  content: "";
}

.fad.fa-list-check:after, .fa-duotone.fa-list-check:after, .fad.fa-tasks:after, .fa-duotone.fa-tasks:after {
  content: "";
}

.fad.fa-diagram-subtask:after, .fa-duotone.fa-diagram-subtask:after {
  content: "";
}

.fad.fa-jug-detergent:after, .fa-duotone.fa-jug-detergent:after {
  content: "";
}

.fad.fa-circle-user:after, .fa-duotone.fa-circle-user:after, .fad.fa-user-circle:after, .fa-duotone.fa-user-circle:after {
  content: "";
}

.fad.fa-square-y:after, .fa-duotone.fa-square-y:after {
  content: "";
}

.fad.fa-user-doctor-hair:after, .fa-duotone.fa-user-doctor-hair:after {
  content: "";
}

.fad.fa-planet-ringed:after, .fa-duotone.fa-planet-ringed:after {
  content: "";
}

.fad.fa-mushroom:after, .fa-duotone.fa-mushroom:after {
  content: "";
}

.fad.fa-user-shield:after, .fa-duotone.fa-user-shield:after {
  content: "";
}

.fad.fa-megaphone:after, .fa-duotone.fa-megaphone:after {
  content: "";
}

.fad.fa-wreath-laurel:after, .fa-duotone.fa-wreath-laurel:after {
  content: "";
}

.fad.fa-circle-exclamation-check:after, .fa-duotone.fa-circle-exclamation-check:after {
  content: "";
}

.fad.fa-wind:after, .fa-duotone.fa-wind:after {
  content: "";
}

.fad.fa-box-dollar:after, .fa-duotone.fa-box-dollar:after, .fad.fa-box-usd:after, .fa-duotone.fa-box-usd:after {
  content: "";
}

.fad.fa-car-burst:after, .fa-duotone.fa-car-burst:after, .fad.fa-car-crash:after, .fa-duotone.fa-car-crash:after {
  content: "";
}

.fad.fa-y:after, .fa-duotone.fa-y:after {
  content: "YY";
}

.fad.fa-user-headset:after, .fa-duotone.fa-user-headset:after {
  content: "";
}

.fad.fa-arrows-retweet:after, .fa-duotone.fa-arrows-retweet:after, .fad.fa-retweet-alt:after, .fa-duotone.fa-retweet-alt:after {
  content: "";
}

.fad.fa-person-snowboarding:after, .fa-duotone.fa-person-snowboarding:after, .fad.fa-snowboarding:after, .fa-duotone.fa-snowboarding:after {
  content: "";
}

.fad.fa-square-chevron-right:after, .fa-duotone.fa-square-chevron-right:after, .fad.fa-chevron-square-right:after, .fa-duotone.fa-chevron-square-right:after {
  content: "";
}

.fad.fa-lacrosse-stick-ball:after, .fa-duotone.fa-lacrosse-stick-ball:after {
  content: "";
}

.fad.fa-truck-fast:after, .fa-duotone.fa-truck-fast:after, .fad.fa-shipping-fast:after, .fa-duotone.fa-shipping-fast:after {
  content: "";
}

.fad.fa-user-magnifying-glass:after, .fa-duotone.fa-user-magnifying-glass:after {
  content: "";
}

.fad.fa-star-sharp:after, .fa-duotone.fa-star-sharp:after {
  content: "";
}

.fad.fa-comment-heart:after, .fa-duotone.fa-comment-heart:after {
  content: "";
}

.fad.fa-circle-1:after, .fa-duotone.fa-circle-1:after {
  content: "";
}

.fad.fa-circle-star:after, .fa-duotone.fa-circle-star:after, .fad.fa-star-circle:after, .fa-duotone.fa-star-circle:after {
  content: "";
}

.fad.fa-fish:after, .fa-duotone.fa-fish:after {
  content: "";
}

.fad.fa-cloud-fog:after, .fa-duotone.fa-cloud-fog:after, .fad.fa-fog:after, .fa-duotone.fa-fog:after {
  content: "";
}

.fad.fa-waffle:after, .fa-duotone.fa-waffle:after {
  content: "";
}

.fad.fa-music-note:after, .fa-duotone.fa-music-note:after, .fad.fa-music-alt:after, .fa-duotone.fa-music-alt:after {
  content: "";
}

.fad.fa-hexagon-exclamation:after, .fa-duotone.fa-hexagon-exclamation:after {
  content: "";
}

.fad.fa-cart-shopping-fast:after, .fa-duotone.fa-cart-shopping-fast:after {
  content: "";
}

.fad.fa-object-union:after, .fa-duotone.fa-object-union:after {
  content: "";
}

.fad.fa-user-graduate:after, .fa-duotone.fa-user-graduate:after {
  content: "";
}

.fad.fa-starfighter:after, .fa-duotone.fa-starfighter:after {
  content: "";
}

.fad.fa-circle-half-stroke:after, .fa-duotone.fa-circle-half-stroke:after, .fad.fa-adjust:after, .fa-duotone.fa-adjust:after {
  content: "";
}

.fad.fa-arrow-right-long-to-line:after, .fa-duotone.fa-arrow-right-long-to-line:after {
  content: "";
}

.fad.fa-square-arrow-down:after, .fa-duotone.fa-square-arrow-down:after, .fad.fa-arrow-square-down:after, .fa-duotone.fa-arrow-square-down:after {
  content: "";
}

.fad.fa-diamond-half-stroke:after, .fa-duotone.fa-diamond-half-stroke:after {
  content: "";
}

.fad.fa-clapperboard:after, .fa-duotone.fa-clapperboard:after {
  content: "";
}

.fad.fa-square-chevron-left:after, .fa-duotone.fa-square-chevron-left:after, .fad.fa-chevron-square-left:after, .fa-duotone.fa-chevron-square-left:after {
  content: "";
}

.fad.fa-phone-intercom:after, .fa-duotone.fa-phone-intercom:after {
  content: "";
}

.fad.fa-link-horizontal:after, .fa-duotone.fa-link-horizontal:after, .fad.fa-chain-horizontal:after, .fa-duotone.fa-chain-horizontal:after {
  content: "";
}

.fad.fa-mango:after, .fa-duotone.fa-mango:after {
  content: "";
}

.fad.fa-music-note-slash:after, .fa-duotone.fa-music-note-slash:after, .fad.fa-music-alt-slash:after, .fa-duotone.fa-music-alt-slash:after {
  content: "";
}

.fad.fa-circle-radiation:after, .fa-duotone.fa-circle-radiation:after, .fad.fa-radiation-alt:after, .fa-duotone.fa-radiation-alt:after {
  content: "";
}

.fad.fa-face-tongue-sweat:after, .fa-duotone.fa-face-tongue-sweat:after {
  content: "";
}

.fad.fa-globe-stand:after, .fa-duotone.fa-globe-stand:after {
  content: "";
}

.fad.fa-baseball:after, .fa-duotone.fa-baseball:after, .fad.fa-baseball-ball:after, .fa-duotone.fa-baseball-ball:after {
  content: "";
}

.fad.fa-circle-p:after, .fa-duotone.fa-circle-p:after {
  content: "";
}

.fad.fa-award-simple:after, .fa-duotone.fa-award-simple:after {
  content: "";
}

.fad.fa-jet-fighter-up:after, .fa-duotone.fa-jet-fighter-up:after {
  content: "";
}

.fad.fa-diagram-project:after, .fa-duotone.fa-diagram-project:after, .fad.fa-project-diagram:after, .fa-duotone.fa-project-diagram:after {
  content: "";
}

.fad.fa-pedestal:after, .fa-duotone.fa-pedestal:after {
  content: "";
}

.fad.fa-chart-pyramid:after, .fa-duotone.fa-chart-pyramid:after {
  content: "";
}

.fad.fa-sidebar:after, .fa-duotone.fa-sidebar:after {
  content: "";
}

.fad.fa-snowman-head:after, .fa-duotone.fa-snowman-head:after, .fad.fa-frosty-head:after, .fa-duotone.fa-frosty-head:after {
  content: "";
}

.fad.fa-copy:after, .fa-duotone.fa-copy:after {
  content: "";
}

.fad.fa-burger-glass:after, .fa-duotone.fa-burger-glass:after {
  content: "";
}

.fad.fa-volume-xmark:after, .fa-duotone.fa-volume-xmark:after, .fad.fa-volume-mute:after, .fa-duotone.fa-volume-mute:after, .fad.fa-volume-times:after, .fa-duotone.fa-volume-times:after {
  content: "";
}

.fad.fa-hand-sparkles:after, .fa-duotone.fa-hand-sparkles:after {
  content: "";
}

.fad.fa-bars-filter:after, .fa-duotone.fa-bars-filter:after {
  content: "";
}

.fad.fa-paintbrush-pencil:after, .fa-duotone.fa-paintbrush-pencil:after {
  content: "";
}

.fad.fa-party-bell:after, .fa-duotone.fa-party-bell:after {
  content: "";
}

.fad.fa-user-vneck-hair:after, .fa-duotone.fa-user-vneck-hair:after {
  content: "";
}

.fad.fa-jack-o-lantern:after, .fa-duotone.fa-jack-o-lantern:after {
  content: "";
}

.fad.fa-grip:after, .fa-duotone.fa-grip:after, .fad.fa-grip-horizontal:after, .fa-duotone.fa-grip-horizontal:after {
  content: "";
}

.fad.fa-share-from-square:after, .fa-duotone.fa-share-from-square:after, .fad.fa-share-square:after, .fa-duotone.fa-share-square:after {
  content: "";
}

.fad.fa-keynote:after, .fa-duotone.fa-keynote:after {
  content: "";
}

.fad.fa-child-combatant:after, .fa-duotone.fa-child-combatant:after, .fad.fa-child-rifle:after, .fa-duotone.fa-child-rifle:after {
  content: "";
}

.fad.fa-gun:after, .fa-duotone.fa-gun:after {
  content: "";
}

.fad.fa-square-phone:after, .fa-duotone.fa-square-phone:after, .fad.fa-phone-square:after, .fa-duotone.fa-phone-square:after {
  content: "";
}

.fad.fa-hat-beach:after, .fa-duotone.fa-hat-beach:after {
  content: "";
}

.fad.fa-plus:after, .fa-duotone.fa-plus:after, .fad.fa-add:after, .fa-duotone.fa-add:after {
  content: "++";
}

.fad.fa-expand:after, .fa-duotone.fa-expand:after {
  content: "";
}

.fad.fa-computer:after, .fa-duotone.fa-computer:after {
  content: "";
}

.fad.fa-fort:after, .fa-duotone.fa-fort:after {
  content: "";
}

.fad.fa-cloud-check:after, .fa-duotone.fa-cloud-check:after {
  content: "";
}

.fad.fa-xmark:after, .fa-duotone.fa-xmark:after, .fad.fa-close:after, .fa-duotone.fa-close:after, .fad.fa-multiply:after, .fa-duotone.fa-multiply:after, .fad.fa-remove:after, .fa-duotone.fa-remove:after, .fad.fa-times:after, .fa-duotone.fa-times:after {
  content: "";
}

.fad.fa-face-smirking:after, .fa-duotone.fa-face-smirking:after {
  content: "";
}

.fad.fa-arrows-up-down-left-right:after, .fa-duotone.fa-arrows-up-down-left-right:after, .fad.fa-arrows:after, .fa-duotone.fa-arrows:after {
  content: "";
}

.fad.fa-chalkboard-user:after, .fa-duotone.fa-chalkboard-user:after, .fad.fa-chalkboard-teacher:after, .fa-duotone.fa-chalkboard-teacher:after {
  content: "";
}

.fad.fa-rhombus:after, .fa-duotone.fa-rhombus:after {
  content: "";
}

.fad.fa-claw-marks:after, .fa-duotone.fa-claw-marks:after {
  content: "";
}

.fad.fa-peso-sign:after, .fa-duotone.fa-peso-sign:after {
  content: "";
}

.fad.fa-face-smile-tongue:after, .fa-duotone.fa-face-smile-tongue:after {
  content: "";
}

.fad.fa-cart-circle-xmark:after, .fa-duotone.fa-cart-circle-xmark:after {
  content: "";
}

.fad.fa-building-shield:after, .fa-duotone.fa-building-shield:after {
  content: "";
}

.fad.fa-circle-phone-flip:after, .fa-duotone.fa-circle-phone-flip:after, .fad.fa-phone-circle-alt:after, .fa-duotone.fa-phone-circle-alt:after {
  content: "";
}

.fad.fa-baby:after, .fa-duotone.fa-baby:after {
  content: "";
}

.fad.fa-users-line:after, .fa-duotone.fa-users-line:after {
  content: "";
}

.fad.fa-quote-left:after, .fa-duotone.fa-quote-left:after, .fad.fa-quote-left-alt:after, .fa-duotone.fa-quote-left-alt:after {
  content: "";
}

.fad.fa-tractor:after, .fa-duotone.fa-tractor:after {
  content: "";
}

.fad.fa-down-from-bracket:after, .fa-duotone.fa-down-from-bracket:after {
  content: "";
}

.fad.fa-key-skeleton:after, .fa-duotone.fa-key-skeleton:after {
  content: "";
}

.fad.fa-trash-arrow-up:after, .fa-duotone.fa-trash-arrow-up:after, .fad.fa-trash-restore:after, .fa-duotone.fa-trash-restore:after {
  content: "";
}

.fad.fa-arrow-down-up-lock:after, .fa-duotone.fa-arrow-down-up-lock:after {
  content: "";
}

.fad.fa-arrow-down-to-bracket:after, .fa-duotone.fa-arrow-down-to-bracket:after {
  content: "";
}

.fad.fa-lines-leaning:after, .fa-duotone.fa-lines-leaning:after {
  content: "";
}

.fad.fa-square-q:after, .fa-duotone.fa-square-q:after {
  content: "";
}

.fad.fa-ruler-combined:after, .fa-duotone.fa-ruler-combined:after {
  content: "";
}

.fad.fa-symbols:after, .fa-duotone.fa-symbols:after, .fad.fa-icons-alt:after, .fa-duotone.fa-icons-alt:after {
  content: "";
}

.fad.fa-copyright:after, .fa-duotone.fa-copyright:after {
  content: "";
}

.fad.fa-flask-gear:after, .fa-duotone.fa-flask-gear:after {
  content: "";
}

.fad.fa-highlighter-line:after, .fa-duotone.fa-highlighter-line:after {
  content: "";
}

.fad.fa-bracket-square:after, .fa-duotone.fa-bracket-square:after, .fad.fa-bracket:after, .fa-duotone.fa-bracket:after, .fad.fa-bracket-left:after, .fa-duotone.fa-bracket-left:after {
  content: "[[";
}

.fad.fa-island-tropical:after, .fa-duotone.fa-island-tropical:after, .fad.fa-island-tree-palm:after, .fa-duotone.fa-island-tree-palm:after {
  content: "";
}

.fad.fa-arrow-right-from-line:after, .fa-duotone.fa-arrow-right-from-line:after, .fad.fa-arrow-from-left:after, .fa-duotone.fa-arrow-from-left:after {
  content: "";
}

.fad.fa-h2:after, .fa-duotone.fa-h2:after {
  content: "";
}

.fad.fa-equals:after, .fa-duotone.fa-equals:after {
  content: "==";
}

.fad.fa-cake-slice:after, .fa-duotone.fa-cake-slice:after, .fad.fa-shortcake:after, .fa-duotone.fa-shortcake:after {
  content: "";
}

.fad.fa-building-magnifying-glass:after, .fa-duotone.fa-building-magnifying-glass:after {
  content: "";
}

.fad.fa-peanut:after, .fa-duotone.fa-peanut:after {
  content: "";
}

.fad.fa-wrench-simple:after, .fa-duotone.fa-wrench-simple:after {
  content: "";
}

.fad.fa-blender:after, .fa-duotone.fa-blender:after {
  content: "";
}

.fad.fa-teeth:after, .fa-duotone.fa-teeth:after {
  content: "";
}

.fad.fa-tally-2:after, .fa-duotone.fa-tally-2:after {
  content: "";
}

.fad.fa-shekel-sign:after, .fa-duotone.fa-shekel-sign:after, .fad.fa-ils:after, .fa-duotone.fa-ils:after, .fad.fa-shekel:after, .fa-duotone.fa-shekel:after, .fad.fa-sheqel:after, .fa-duotone.fa-sheqel:after, .fad.fa-sheqel-sign:after, .fa-duotone.fa-sheqel-sign:after {
  content: "";
}

.fad.fa-cars:after, .fa-duotone.fa-cars:after {
  content: "";
}

.fad.fa-axe-battle:after, .fa-duotone.fa-axe-battle:after {
  content: "";
}

.fad.fa-user-hair-long:after, .fa-duotone.fa-user-hair-long:after {
  content: "";
}

.fad.fa-map:after, .fa-duotone.fa-map:after {
  content: "";
}

.fad.fa-arrow-left-from-arc:after, .fa-duotone.fa-arrow-left-from-arc:after {
  content: "";
}

.fad.fa-file-circle-info:after, .fa-duotone.fa-file-circle-info:after {
  content: "";
}

.fad.fa-face-disappointed:after, .fa-duotone.fa-face-disappointed:after {
  content: "";
}

.fad.fa-lasso-sparkles:after, .fa-duotone.fa-lasso-sparkles:after {
  content: "";
}

.fad.fa-clock-eleven:after, .fa-duotone.fa-clock-eleven:after {
  content: "";
}

.fad.fa-rocket:after, .fa-duotone.fa-rocket:after {
  content: "";
}

.fad.fa-siren-on:after, .fa-duotone.fa-siren-on:after {
  content: "";
}

.fad.fa-clock-ten:after, .fa-duotone.fa-clock-ten:after {
  content: "";
}

.fad.fa-candle-holder:after, .fa-duotone.fa-candle-holder:after {
  content: "";
}

.fad.fa-video-arrow-down-left:after, .fa-duotone.fa-video-arrow-down-left:after {
  content: "";
}

.fad.fa-photo-film:after, .fa-duotone.fa-photo-film:after, .fad.fa-photo-video:after, .fa-duotone.fa-photo-video:after {
  content: "";
}

.fad.fa-floppy-disk-circle-arrow-right:after, .fa-duotone.fa-floppy-disk-circle-arrow-right:after, .fad.fa-save-circle-arrow-right:after, .fa-duotone.fa-save-circle-arrow-right:after {
  content: "";
}

.fad.fa-folder-minus:after, .fa-duotone.fa-folder-minus:after {
  content: "";
}

.fad.fa-planet-moon:after, .fa-duotone.fa-planet-moon:after {
  content: "";
}

.fad.fa-face-eyes-xmarks:after, .fa-duotone.fa-face-eyes-xmarks:after {
  content: "";
}

.fad.fa-chart-scatter:after, .fa-duotone.fa-chart-scatter:after {
  content: "";
}

.fad.fa-circle-gf:after, .fa-duotone.fa-circle-gf:after {
  content: "";
}

.fad.fa-display-arrow-down:after, .fa-duotone.fa-display-arrow-down:after {
  content: "";
}

.fad.fa-store:after, .fa-duotone.fa-store:after {
  content: "";
}

.fad.fa-arrow-trend-up:after, .fa-duotone.fa-arrow-trend-up:after {
  content: "";
}

.fad.fa-plug-circle-minus:after, .fa-duotone.fa-plug-circle-minus:after {
  content: "";
}

.fad.fa-olive-branch:after, .fa-duotone.fa-olive-branch:after {
  content: "";
}

.fad.fa-angle:after, .fa-duotone.fa-angle:after {
  content: "";
}

.fad.fa-vacuum-robot:after, .fa-duotone.fa-vacuum-robot:after {
  content: "";
}

.fad.fa-sign-hanging:after, .fa-duotone.fa-sign-hanging:after, .fad.fa-sign:after, .fa-duotone.fa-sign:after {
  content: "";
}

.fad.fa-square-divide:after, .fa-duotone.fa-square-divide:after {
  content: "";
}

.fad.fa-folder-check:after, .fa-duotone.fa-folder-check:after {
  content: "";
}

.fad.fa-signal-stream-slash:after, .fa-duotone.fa-signal-stream-slash:after {
  content: "";
}

.fad.fa-bezier-curve:after, .fa-duotone.fa-bezier-curve:after {
  content: "";
}

.fad.fa-eye-dropper-half:after, .fa-duotone.fa-eye-dropper-half:after {
  content: "";
}

.fad.fa-store-lock:after, .fa-duotone.fa-store-lock:after {
  content: "";
}

.fad.fa-bell-slash:after, .fa-duotone.fa-bell-slash:after {
  content: "";
}

.fad.fa-cloud-bolt-sun:after, .fa-duotone.fa-cloud-bolt-sun:after, .fad.fa-thunderstorm-sun:after, .fa-duotone.fa-thunderstorm-sun:after {
  content: "";
}

.fad.fa-camera-slash:after, .fa-duotone.fa-camera-slash:after {
  content: "";
}

.fad.fa-comment-quote:after, .fa-duotone.fa-comment-quote:after {
  content: "";
}

.fad.fa-tablet:after, .fa-duotone.fa-tablet:after, .fad.fa-tablet-android:after, .fa-duotone.fa-tablet-android:after {
  content: "";
}

.fad.fa-school-flag:after, .fa-duotone.fa-school-flag:after {
  content: "";
}

.fad.fa-message-code:after, .fa-duotone.fa-message-code:after {
  content: "";
}

.fad.fa-glass-half:after, .fa-duotone.fa-glass-half:after, .fad.fa-glass-half-empty:after, .fa-duotone.fa-glass-half-empty:after, .fad.fa-glass-half-full:after, .fa-duotone.fa-glass-half-full:after {
  content: "";
}

.fad.fa-fill:after, .fa-duotone.fa-fill:after {
  content: "";
}

.fad.fa-message-minus:after, .fa-duotone.fa-message-minus:after, .fad.fa-comment-alt-minus:after, .fa-duotone.fa-comment-alt-minus:after {
  content: "";
}

.fad.fa-angle-up:after, .fa-duotone.fa-angle-up:after {
  content: "";
}

.fad.fa-dinosaur:after, .fa-duotone.fa-dinosaur:after {
  content: "";
}

.fad.fa-drumstick-bite:after, .fa-duotone.fa-drumstick-bite:after {
  content: "";
}

.fad.fa-link-horizontal-slash:after, .fa-duotone.fa-link-horizontal-slash:after, .fad.fa-chain-horizontal-slash:after, .fa-duotone.fa-chain-horizontal-slash:after {
  content: "";
}

.fad.fa-holly-berry:after, .fa-duotone.fa-holly-berry:after {
  content: "";
}

.fad.fa-nose:after, .fa-duotone.fa-nose:after {
  content: "";
}

.fad.fa-arrow-left-to-arc:after, .fa-duotone.fa-arrow-left-to-arc:after {
  content: "";
}

.fad.fa-chevron-left:after, .fa-duotone.fa-chevron-left:after {
  content: "";
}

.fad.fa-bacteria:after, .fa-duotone.fa-bacteria:after {
  content: "";
}

.fad.fa-clouds:after, .fa-duotone.fa-clouds:after {
  content: "";
}

.fad.fa-money-bill-simple:after, .fa-duotone.fa-money-bill-simple:after {
  content: "";
}

.fad.fa-hand-lizard:after, .fa-duotone.fa-hand-lizard:after {
  content: "";
}

.fad.fa-table-pivot:after, .fa-duotone.fa-table-pivot:after {
  content: "";
}

.fad.fa-filter-slash:after, .fa-duotone.fa-filter-slash:after {
  content: "";
}

.fad.fa-trash-can-undo:after, .fa-duotone.fa-trash-can-undo:after, .fad.fa-trash-can-arrow-turn-left:after, .fa-duotone.fa-trash-can-arrow-turn-left:after, .fad.fa-trash-undo-alt:after, .fa-duotone.fa-trash-undo-alt:after {
  content: "";
}

.fad.fa-notdef:after, .fa-duotone.fa-notdef:after {
  content: "";
}

.fad.fa-disease:after, .fa-duotone.fa-disease:after {
  content: "";
}

.fad.fa-person-to-door:after, .fa-duotone.fa-person-to-door:after {
  content: "";
}

.fad.fa-turntable:after, .fa-duotone.fa-turntable:after {
  content: "";
}

.fad.fa-briefcase-medical:after, .fa-duotone.fa-briefcase-medical:after {
  content: "";
}

.fad.fa-genderless:after, .fa-duotone.fa-genderless:after {
  content: "";
}

.fad.fa-chevron-right:after, .fa-duotone.fa-chevron-right:after {
  content: "";
}

.fad.fa-signal-weak:after, .fa-duotone.fa-signal-weak:after, .fad.fa-signal-1:after, .fa-duotone.fa-signal-1:after {
  content: "";
}

.fad.fa-clock-five:after, .fa-duotone.fa-clock-five:after {
  content: "";
}

.fad.fa-retweet:after, .fa-duotone.fa-retweet:after {
  content: "";
}

.fad.fa-car-rear:after, .fa-duotone.fa-car-rear:after, .fad.fa-car-alt:after, .fa-duotone.fa-car-alt:after {
  content: "";
}

.fad.fa-pump-soap:after, .fa-duotone.fa-pump-soap:after {
  content: "";
}

.fad.fa-computer-classic:after, .fa-duotone.fa-computer-classic:after {
  content: "";
}

.fad.fa-frame:after, .fa-duotone.fa-frame:after {
  content: "";
}

.fad.fa-video-slash:after, .fa-duotone.fa-video-slash:after {
  content: "";
}

.fad.fa-battery-quarter:after, .fa-duotone.fa-battery-quarter:after, .fad.fa-battery-2:after, .fa-duotone.fa-battery-2:after {
  content: "";
}

.fad.fa-ellipsis-stroke:after, .fa-duotone.fa-ellipsis-stroke:after, .fad.fa-ellipsis-h-alt:after, .fa-duotone.fa-ellipsis-h-alt:after {
  content: "";
}

.fad.fa-radio:after, .fa-duotone.fa-radio:after {
  content: "";
}

.fad.fa-baby-carriage:after, .fa-duotone.fa-baby-carriage:after, .fad.fa-carriage-baby:after, .fa-duotone.fa-carriage-baby:after {
  content: "";
}

.fad.fa-face-expressionless:after, .fa-duotone.fa-face-expressionless:after {
  content: "";
}

.fad.fa-down-to-dotted-line:after, .fa-duotone.fa-down-to-dotted-line:after {
  content: "";
}

.fad.fa-cloud-music:after, .fa-duotone.fa-cloud-music:after {
  content: "";
}

.fad.fa-traffic-light:after, .fa-duotone.fa-traffic-light:after {
  content: "";
}

.fad.fa-cloud-minus:after, .fa-duotone.fa-cloud-minus:after {
  content: "";
}

.fad.fa-thermometer:after, .fa-duotone.fa-thermometer:after {
  content: "";
}

.fad.fa-shield-minus:after, .fa-duotone.fa-shield-minus:after {
  content: "";
}

.fad.fa-vr-cardboard:after, .fa-duotone.fa-vr-cardboard:after {
  content: "";
}

.fad.fa-car-tilt:after, .fa-duotone.fa-car-tilt:after {
  content: "";
}

.fad.fa-gauge-circle-minus:after, .fa-duotone.fa-gauge-circle-minus:after {
  content: "";
}

.fad.fa-brightness-low:after, .fa-duotone.fa-brightness-low:after {
  content: "";
}

.fad.fa-hand-middle-finger:after, .fa-duotone.fa-hand-middle-finger:after {
  content: "";
}

.fad.fa-percent:after, .fa-duotone.fa-percent:after, .fad.fa-percentage:after, .fa-duotone.fa-percentage:after {
  content: "%%";
}

.fad.fa-truck-moving:after, .fa-duotone.fa-truck-moving:after {
  content: "";
}

.fad.fa-glass-water-droplet:after, .fa-duotone.fa-glass-water-droplet:after {
  content: "";
}

.fad.fa-conveyor-belt:after, .fa-duotone.fa-conveyor-belt:after {
  content: "";
}

.fad.fa-location-check:after, .fa-duotone.fa-location-check:after, .fad.fa-map-marker-check:after, .fa-duotone.fa-map-marker-check:after {
  content: "";
}

.fad.fa-coin-vertical:after, .fa-duotone.fa-coin-vertical:after {
  content: "";
}

.fad.fa-display:after, .fa-duotone.fa-display:after {
  content: "";
}

.fad.fa-person-sign:after, .fa-duotone.fa-person-sign:after {
  content: "";
}

.fad.fa-face-smile:after, .fa-duotone.fa-face-smile:after, .fad.fa-smile:after, .fa-duotone.fa-smile:after {
  content: "";
}

.fad.fa-phone-hangup:after, .fa-duotone.fa-phone-hangup:after {
  content: "";
}

.fad.fa-signature-slash:after, .fa-duotone.fa-signature-slash:after {
  content: "";
}

.fad.fa-thumbtack:after, .fa-duotone.fa-thumbtack:after, .fad.fa-thumb-tack:after, .fa-duotone.fa-thumb-tack:after {
  content: "";
}

.fad.fa-wheat-slash:after, .fa-duotone.fa-wheat-slash:after {
  content: "";
}

.fad.fa-trophy:after, .fa-duotone.fa-trophy:after {
  content: "";
}

.fad.fa-clouds-sun:after, .fa-duotone.fa-clouds-sun:after {
  content: "";
}

.fad.fa-person-praying:after, .fa-duotone.fa-person-praying:after, .fad.fa-pray:after, .fa-duotone.fa-pray:after {
  content: "";
}

.fad.fa-hammer:after, .fa-duotone.fa-hammer:after {
  content: "";
}

.fad.fa-face-vomit:after, .fa-duotone.fa-face-vomit:after {
  content: "";
}

.fad.fa-speakers:after, .fa-duotone.fa-speakers:after {
  content: "";
}

.fad.fa-tty-answer:after, .fa-duotone.fa-tty-answer:after, .fad.fa-teletype-answer:after, .fa-duotone.fa-teletype-answer:after {
  content: "";
}

.fad.fa-mug-tea-saucer:after, .fa-duotone.fa-mug-tea-saucer:after {
  content: "";
}

.fad.fa-diagram-lean-canvas:after, .fa-duotone.fa-diagram-lean-canvas:after {
  content: "";
}

.fad.fa-alt:after, .fa-duotone.fa-alt:after {
  content: "";
}

.fad.fa-dial:after, .fa-duotone.fa-dial:after, .fad.fa-dial-med-high:after, .fa-duotone.fa-dial-med-high:after {
  content: "";
}

.fad.fa-hand-peace:after, .fa-duotone.fa-hand-peace:after {
  content: "";
}

.fad.fa-circle-trash:after, .fa-duotone.fa-circle-trash:after, .fad.fa-trash-circle:after, .fa-duotone.fa-trash-circle:after {
  content: "";
}

.fad.fa-rotate:after, .fa-duotone.fa-rotate:after, .fad.fa-sync-alt:after, .fa-duotone.fa-sync-alt:after {
  content: "";
}

.fad.fa-circle-quarters:after, .fa-duotone.fa-circle-quarters:after {
  content: "";
}

.fad.fa-spinner:after, .fa-duotone.fa-spinner:after {
  content: "";
}

.fad.fa-tower-control:after, .fa-duotone.fa-tower-control:after {
  content: "";
}

.fad.fa-arrow-up-triangle-square:after, .fa-duotone.fa-arrow-up-triangle-square:after, .fad.fa-sort-shapes-up:after, .fa-duotone.fa-sort-shapes-up:after {
  content: "";
}

.fad.fa-whale:after, .fa-duotone.fa-whale:after {
  content: "";
}

.fad.fa-robot:after, .fa-duotone.fa-robot:after {
  content: "";
}

.fad.fa-peace:after, .fa-duotone.fa-peace:after {
  content: "";
}

.fad.fa-party-horn:after, .fa-duotone.fa-party-horn:after {
  content: "";
}

.fad.fa-gears:after, .fa-duotone.fa-gears:after, .fad.fa-cogs:after, .fa-duotone.fa-cogs:after {
  content: "";
}

.fad.fa-sun-bright:after, .fa-duotone.fa-sun-bright:after, .fad.fa-sun-alt:after, .fa-duotone.fa-sun-alt:after {
  content: "";
}

.fad.fa-warehouse:after, .fa-duotone.fa-warehouse:after {
  content: "";
}

.fad.fa-conveyor-belt-arm:after, .fa-duotone.fa-conveyor-belt-arm:after {
  content: "";
}

.fad.fa-lock-keyhole-open:after, .fa-duotone.fa-lock-keyhole-open:after, .fad.fa-lock-open-alt:after, .fa-duotone.fa-lock-open-alt:after {
  content: "";
}

.fad.fa-square-fragile:after, .fa-duotone.fa-square-fragile:after, .fad.fa-box-fragile:after, .fa-duotone.fa-box-fragile:after, .fad.fa-square-wine-glass-crack:after, .fa-duotone.fa-square-wine-glass-crack:after {
  content: "";
}

.fad.fa-arrow-up-right-dots:after, .fa-duotone.fa-arrow-up-right-dots:after {
  content: "";
}

.fad.fa-square-n:after, .fa-duotone.fa-square-n:after {
  content: "";
}

.fad.fa-splotch:after, .fa-duotone.fa-splotch:after {
  content: "";
}

.fad.fa-face-grin-hearts:after, .fa-duotone.fa-face-grin-hearts:after, .fad.fa-grin-hearts:after, .fa-duotone.fa-grin-hearts:after {
  content: "";
}

.fad.fa-meter:after, .fa-duotone.fa-meter:after {
  content: "";
}

.fad.fa-mandolin:after, .fa-duotone.fa-mandolin:after {
  content: "";
}

.fad.fa-dice-four:after, .fa-duotone.fa-dice-four:after {
  content: "";
}

.fad.fa-sim-card:after, .fa-duotone.fa-sim-card:after {
  content: "";
}

.fad.fa-transgender:after, .fa-duotone.fa-transgender:after, .fad.fa-transgender-alt:after, .fa-duotone.fa-transgender-alt:after {
  content: "";
}

.fad.fa-mercury:after, .fa-duotone.fa-mercury:after {
  content: "";
}

.fad.fa-up-from-bracket:after, .fa-duotone.fa-up-from-bracket:after {
  content: "";
}

.fad.fa-knife-kitchen:after, .fa-duotone.fa-knife-kitchen:after {
  content: "";
}

.fad.fa-border-right:after, .fa-duotone.fa-border-right:after {
  content: "";
}

.fad.fa-arrow-turn-down:after, .fa-duotone.fa-arrow-turn-down:after, .fad.fa-level-down:after, .fa-duotone.fa-level-down:after {
  content: "";
}

.fad.fa-spade:after, .fa-duotone.fa-spade:after {
  content: "";
}

.fad.fa-card-spade:after, .fa-duotone.fa-card-spade:after {
  content: "";
}

.fad.fa-line-columns:after, .fa-duotone.fa-line-columns:after {
  content: "";
}

.fad.fa-ant:after, .fa-duotone.fa-ant:after {
  content: "";
}

.fad.fa-arrow-right-to-line:after, .fa-duotone.fa-arrow-right-to-line:after, .fad.fa-arrow-to-right:after, .fa-duotone.fa-arrow-to-right:after {
  content: "";
}

.fad.fa-person-falling-burst:after, .fa-duotone.fa-person-falling-burst:after {
  content: "";
}

.fad.fa-flag-pennant:after, .fa-duotone.fa-flag-pennant:after, .fad.fa-pennant:after, .fa-duotone.fa-pennant:after {
  content: "";
}

.fad.fa-conveyor-belt-empty:after, .fa-duotone.fa-conveyor-belt-empty:after {
  content: "";
}

.fad.fa-user-group-simple:after, .fa-duotone.fa-user-group-simple:after {
  content: "";
}

.fad.fa-award:after, .fa-duotone.fa-award:after {
  content: "";
}

.fad.fa-ticket-simple:after, .fa-duotone.fa-ticket-simple:after, .fad.fa-ticket-alt:after, .fa-duotone.fa-ticket-alt:after {
  content: "";
}

.fad.fa-building:after, .fa-duotone.fa-building:after {
  content: "";
}

.fad.fa-angles-left:after, .fa-duotone.fa-angles-left:after, .fad.fa-angle-double-left:after, .fa-duotone.fa-angle-double-left:after {
  content: "";
}

.fad.fa-camcorder:after, .fa-duotone.fa-camcorder:after, .fad.fa-video-handheld:after, .fa-duotone.fa-video-handheld:after {
  content: "";
}

.fad.fa-pancakes:after, .fa-duotone.fa-pancakes:after {
  content: "";
}

.fad.fa-album-circle-user:after, .fa-duotone.fa-album-circle-user:after {
  content: "";
}

.fad.fa-subtitles-slash:after, .fa-duotone.fa-subtitles-slash:after {
  content: "";
}

.fad.fa-qrcode:after, .fa-duotone.fa-qrcode:after {
  content: "";
}

.fad.fa-dice-d10:after, .fa-duotone.fa-dice-d10:after {
  content: "";
}

.fad.fa-fireplace:after, .fa-duotone.fa-fireplace:after {
  content: "";
}

.fad.fa-browser:after, .fa-duotone.fa-browser:after {
  content: "";
}

.fad.fa-pen-paintbrush:after, .fa-duotone.fa-pen-paintbrush:after, .fad.fa-pencil-paintbrush:after, .fa-duotone.fa-pencil-paintbrush:after {
  content: "";
}

.fad.fa-fish-cooked:after, .fa-duotone.fa-fish-cooked:after {
  content: "";
}

.fad.fa-chair-office:after, .fa-duotone.fa-chair-office:after {
  content: "";
}

.fad.fa-magnifying-glass-music:after, .fa-duotone.fa-magnifying-glass-music:after {
  content: "";
}

.fad.fa-nesting-dolls:after, .fa-duotone.fa-nesting-dolls:after {
  content: "";
}

.fad.fa-clock-rotate-left:after, .fa-duotone.fa-clock-rotate-left:after, .fad.fa-history:after, .fa-duotone.fa-history:after {
  content: "";
}

.fad.fa-trumpet:after, .fa-duotone.fa-trumpet:after {
  content: "";
}

.fad.fa-face-grin-beam-sweat:after, .fa-duotone.fa-face-grin-beam-sweat:after, .fad.fa-grin-beam-sweat:after, .fa-duotone.fa-grin-beam-sweat:after {
  content: "";
}

.fad.fa-fire-smoke:after, .fa-duotone.fa-fire-smoke:after {
  content: "";
}

.fad.fa-phone-missed:after, .fa-duotone.fa-phone-missed:after {
  content: "";
}

.fad.fa-file-export:after, .fa-duotone.fa-file-export:after, .fad.fa-arrow-right-from-file:after, .fa-duotone.fa-arrow-right-from-file:after {
  content: "";
}

.fad.fa-shield:after, .fa-duotone.fa-shield:after, .fad.fa-shield-blank:after, .fa-duotone.fa-shield-blank:after {
  content: "";
}

.fad.fa-arrow-up-short-wide:after, .fa-duotone.fa-arrow-up-short-wide:after, .fad.fa-sort-amount-up-alt:after, .fa-duotone.fa-sort-amount-up-alt:after {
  content: "";
}

.fad.fa-arrows-repeat-1:after, .fa-duotone.fa-arrows-repeat-1:after, .fad.fa-repeat-1-alt:after, .fa-duotone.fa-repeat-1-alt:after {
  content: "";
}

.fad.fa-gun-slash:after, .fa-duotone.fa-gun-slash:after {
  content: "";
}

.fad.fa-avocado:after, .fa-duotone.fa-avocado:after {
  content: "";
}

.fad.fa-binary:after, .fa-duotone.fa-binary:after {
  content: "";
}

.fad.fa-glasses-round:after, .fa-duotone.fa-glasses-round:after, .fad.fa-glasses-alt:after, .fa-duotone.fa-glasses-alt:after {
  content: "";
}

.fad.fa-phone-plus:after, .fa-duotone.fa-phone-plus:after {
  content: "";
}

.fad.fa-ditto:after, .fa-duotone.fa-ditto:after {
  content: "\"\"";
}

.fad.fa-person-seat:after, .fa-duotone.fa-person-seat:after {
  content: "";
}

.fad.fa-house-medical:after, .fa-duotone.fa-house-medical:after {
  content: "";
}

.fad.fa-golf-ball-tee:after, .fa-duotone.fa-golf-ball-tee:after, .fad.fa-golf-ball:after, .fa-duotone.fa-golf-ball:after {
  content: "";
}

.fad.fa-circle-chevron-left:after, .fa-duotone.fa-circle-chevron-left:after, .fad.fa-chevron-circle-left:after, .fa-duotone.fa-chevron-circle-left:after {
  content: "";
}

.fad.fa-house-chimney-window:after, .fa-duotone.fa-house-chimney-window:after {
  content: "";
}

.fad.fa-scythe:after, .fa-duotone.fa-scythe:after {
  content: "";
}

.fad.fa-pen-nib:after, .fa-duotone.fa-pen-nib:after {
  content: "";
}

.fad.fa-ban-parking:after, .fa-duotone.fa-ban-parking:after, .fad.fa-parking-circle-slash:after, .fa-duotone.fa-parking-circle-slash:after {
  content: "";
}

.fad.fa-tent-arrow-turn-left:after, .fa-duotone.fa-tent-arrow-turn-left:after {
  content: "";
}

.fad.fa-face-diagonal-mouth:after, .fa-duotone.fa-face-diagonal-mouth:after {
  content: "";
}

.fad.fa-diagram-cells:after, .fa-duotone.fa-diagram-cells:after {
  content: "";
}

.fad.fa-cricket-bat-ball:after, .fa-duotone.fa-cricket-bat-ball:after, .fad.fa-cricket:after, .fa-duotone.fa-cricket:after {
  content: "";
}

.fad.fa-tents:after, .fa-duotone.fa-tents:after {
  content: "";
}

.fad.fa-wand-magic:after, .fa-duotone.fa-wand-magic:after, .fad.fa-magic:after, .fa-duotone.fa-magic:after {
  content: "";
}

.fad.fa-dog:after, .fa-duotone.fa-dog:after {
  content: "";
}

.fad.fa-pen-line:after, .fa-duotone.fa-pen-line:after {
  content: "";
}

.fad.fa-atom-simple:after, .fa-duotone.fa-atom-simple:after, .fad.fa-atom-alt:after, .fa-duotone.fa-atom-alt:after {
  content: "";
}

.fad.fa-ampersand:after, .fa-duotone.fa-ampersand:after {
  content: "&&";
}

.fad.fa-carrot:after, .fa-duotone.fa-carrot:after {
  content: "";
}

.fad.fa-arrow-up-from-line:after, .fa-duotone.fa-arrow-up-from-line:after, .fad.fa-arrow-from-bottom:after, .fa-duotone.fa-arrow-from-bottom:after {
  content: "";
}

.fad.fa-moon:after, .fa-duotone.fa-moon:after {
  content: "";
}

.fad.fa-pen-slash:after, .fa-duotone.fa-pen-slash:after {
  content: "";
}

.fad.fa-wine-glass-empty:after, .fa-duotone.fa-wine-glass-empty:after, .fad.fa-wine-glass-alt:after, .fa-duotone.fa-wine-glass-alt:after {
  content: "";
}

.fad.fa-square-star:after, .fa-duotone.fa-square-star:after {
  content: "";
}

.fad.fa-cheese:after, .fa-duotone.fa-cheese:after {
  content: "";
}

.fad.fa-send-backward:after, .fa-duotone.fa-send-backward:after {
  content: "";
}

.fad.fa-yin-yang:after, .fa-duotone.fa-yin-yang:after {
  content: "";
}

.fad.fa-music:after, .fa-duotone.fa-music:after {
  content: "";
}

.fad.fa-compass-slash:after, .fa-duotone.fa-compass-slash:after {
  content: "";
}

.fad.fa-clock-one:after, .fa-duotone.fa-clock-one:after {
  content: "";
}

.fad.fa-file-music:after, .fa-duotone.fa-file-music:after {
  content: "";
}

.fad.fa-code-commit:after, .fa-duotone.fa-code-commit:after {
  content: "";
}

.fad.fa-temperature-low:after, .fa-duotone.fa-temperature-low:after {
  content: "";
}

.fad.fa-person-biking:after, .fa-duotone.fa-person-biking:after, .fad.fa-biking:after, .fa-duotone.fa-biking:after {
  content: "";
}

.fad.fa-display-chart-up-circle-currency:after, .fa-duotone.fa-display-chart-up-circle-currency:after {
  content: "";
}

.fad.fa-skeleton:after, .fa-duotone.fa-skeleton:after {
  content: "";
}

.fad.fa-circle-g:after, .fa-duotone.fa-circle-g:after {
  content: "";
}

.fad.fa-circle-arrow-up-left:after, .fa-duotone.fa-circle-arrow-up-left:after {
  content: "";
}

.fad.fa-coin-blank:after, .fa-duotone.fa-coin-blank:after {
  content: "";
}

.fad.fa-broom:after, .fa-duotone.fa-broom:after {
  content: "";
}

.fad.fa-vacuum:after, .fa-duotone.fa-vacuum:after {
  content: "";
}

.fad.fa-shield-heart:after, .fa-duotone.fa-shield-heart:after {
  content: "";
}

.fad.fa-card-heart:after, .fa-duotone.fa-card-heart:after {
  content: "";
}

.fad.fa-lightbulb-cfl-on:after, .fa-duotone.fa-lightbulb-cfl-on:after {
  content: "";
}

.fad.fa-melon:after, .fa-duotone.fa-melon:after {
  content: "";
}

.fad.fa-gopuram:after, .fa-duotone.fa-gopuram:after {
  content: "";
}

.fad.fa-earth-oceania:after, .fa-duotone.fa-earth-oceania:after, .fad.fa-globe-oceania:after, .fa-duotone.fa-globe-oceania:after {
  content: "";
}

.fad.fa-container-storage:after, .fa-duotone.fa-container-storage:after {
  content: "";
}

.fad.fa-face-pouting:after, .fa-duotone.fa-face-pouting:after {
  content: "";
}

.fad.fa-square-xmark:after, .fa-duotone.fa-square-xmark:after, .fad.fa-times-square:after, .fa-duotone.fa-times-square:after, .fad.fa-xmark-square:after, .fa-duotone.fa-xmark-square:after {
  content: "";
}

.fad.fa-face-explode:after, .fa-duotone.fa-face-explode:after, .fad.fa-exploding-head:after, .fa-duotone.fa-exploding-head:after {
  content: "";
}

.fad.fa-hashtag:after, .fa-duotone.fa-hashtag:after {
  content: "##";
}

.fad.fa-up-right-and-down-left-from-center:after, .fa-duotone.fa-up-right-and-down-left-from-center:after, .fad.fa-expand-alt:after, .fa-duotone.fa-expand-alt:after {
  content: "";
}

.fad.fa-oil-can:after, .fa-duotone.fa-oil-can:after {
  content: "";
}

.fad.fa-t:after, .fa-duotone.fa-t:after {
  content: "TT";
}

.fad.fa-transformer-bolt:after, .fa-duotone.fa-transformer-bolt:after {
  content: "";
}

.fad.fa-hippo:after, .fa-duotone.fa-hippo:after {
  content: "";
}

.fad.fa-chart-column:after, .fa-duotone.fa-chart-column:after {
  content: "";
}

.fad.fa-cassette-vhs:after, .fa-duotone.fa-cassette-vhs:after, .fad.fa-vhs:after, .fa-duotone.fa-vhs:after {
  content: "";
}

.fad.fa-infinity:after, .fa-duotone.fa-infinity:after {
  content: "";
}

.fad.fa-vial-circle-check:after, .fa-duotone.fa-vial-circle-check:after {
  content: "";
}

.fad.fa-chimney:after, .fa-duotone.fa-chimney:after {
  content: "";
}

.fad.fa-object-intersect:after, .fa-duotone.fa-object-intersect:after {
  content: "";
}

.fad.fa-person-arrow-down-to-line:after, .fa-duotone.fa-person-arrow-down-to-line:after {
  content: "";
}

.fad.fa-voicemail:after, .fa-duotone.fa-voicemail:after {
  content: "";
}

.fad.fa-block-brick:after, .fa-duotone.fa-block-brick:after, .fad.fa-wall-brick:after, .fa-duotone.fa-wall-brick:after {
  content: "";
}

.fad.fa-fan:after, .fa-duotone.fa-fan:after {
  content: "";
}

.fad.fa-bags-shopping:after, .fa-duotone.fa-bags-shopping:after {
  content: "";
}

.fad.fa-paragraph-left:after, .fa-duotone.fa-paragraph-left:after, .fad.fa-paragraph-rtl:after, .fa-duotone.fa-paragraph-rtl:after {
  content: "";
}

.fad.fa-person-walking-luggage:after, .fa-duotone.fa-person-walking-luggage:after {
  content: "";
}

.fad.fa-caravan-simple:after, .fa-duotone.fa-caravan-simple:after, .fad.fa-caravan-alt:after, .fa-duotone.fa-caravan-alt:after {
  content: "";
}

.fad.fa-turtle:after, .fa-duotone.fa-turtle:after {
  content: "";
}

.fad.fa-pencil-mechanical:after, .fa-duotone.fa-pencil-mechanical:after {
  content: "";
}

.fad.fa-up-down:after, .fa-duotone.fa-up-down:after, .fad.fa-arrows-alt-v:after, .fa-duotone.fa-arrows-alt-v:after {
  content: "";
}

.fad.fa-cloud-moon-rain:after, .fa-duotone.fa-cloud-moon-rain:after {
  content: "";
}

.fad.fa-booth-curtain:after, .fa-duotone.fa-booth-curtain:after {
  content: "";
}

.fad.fa-calendar:after, .fa-duotone.fa-calendar:after {
  content: "";
}

.fad.fa-box-heart:after, .fa-duotone.fa-box-heart:after {
  content: "";
}

.fad.fa-trailer:after, .fa-duotone.fa-trailer:after {
  content: "";
}

.fad.fa-user-doctor-message:after, .fa-duotone.fa-user-doctor-message:after, .fad.fa-user-md-chat:after, .fa-duotone.fa-user-md-chat:after {
  content: "";
}

.fad.fa-bahai:after, .fa-duotone.fa-bahai:after, .fad.fa-haykal:after, .fa-duotone.fa-haykal:after {
  content: "";
}

.fad.fa-lighthouse:after, .fa-duotone.fa-lighthouse:after {
  content: "";
}

.fad.fa-amp-guitar:after, .fa-duotone.fa-amp-guitar:after {
  content: "";
}

.fad.fa-sd-card:after, .fa-duotone.fa-sd-card:after {
  content: "";
}

.fad.fa-volume-slash:after, .fa-duotone.fa-volume-slash:after {
  content: "";
}

.fad.fa-border-bottom:after, .fa-duotone.fa-border-bottom:after {
  content: "";
}

.fad.fa-wifi-weak:after, .fa-duotone.fa-wifi-weak:after, .fad.fa-wifi-1:after, .fa-duotone.fa-wifi-1:after {
  content: "";
}

.fad.fa-dragon:after, .fa-duotone.fa-dragon:after {
  content: "";
}

.fad.fa-shoe-prints:after, .fa-duotone.fa-shoe-prints:after {
  content: "";
}

.fad.fa-circle-plus:after, .fa-duotone.fa-circle-plus:after, .fad.fa-plus-circle:after, .fa-duotone.fa-plus-circle:after {
  content: "";
}

.fad.fa-face-grin-tongue-wink:after, .fa-duotone.fa-face-grin-tongue-wink:after, .fad.fa-grin-tongue-wink:after, .fa-duotone.fa-grin-tongue-wink:after {
  content: "";
}

.fad.fa-hand-holding:after, .fa-duotone.fa-hand-holding:after {
  content: "";
}

.fad.fa-plug-circle-exclamation:after, .fa-duotone.fa-plug-circle-exclamation:after {
  content: "";
}

.fad.fa-link-slash:after, .fa-duotone.fa-link-slash:after, .fad.fa-chain-broken:after, .fa-duotone.fa-chain-broken:after, .fad.fa-chain-slash:after, .fa-duotone.fa-chain-slash:after, .fad.fa-unlink:after, .fa-duotone.fa-unlink:after {
  content: "";
}

.fad.fa-clone:after, .fa-duotone.fa-clone:after {
  content: "";
}

.fad.fa-person-walking-arrow-loop-left:after, .fa-duotone.fa-person-walking-arrow-loop-left:after {
  content: "";
}

.fad.fa-arrow-up-z-a:after, .fa-duotone.fa-arrow-up-z-a:after, .fad.fa-sort-alpha-up-alt:after, .fa-duotone.fa-sort-alpha-up-alt:after {
  content: "";
}

.fad.fa-fire-flame-curved:after, .fa-duotone.fa-fire-flame-curved:after, .fad.fa-fire-alt:after, .fa-duotone.fa-fire-alt:after {
  content: "";
}

.fad.fa-tornado:after, .fa-duotone.fa-tornado:after {
  content: "";
}

.fad.fa-file-circle-plus:after, .fa-duotone.fa-file-circle-plus:after {
  content: "";
}

.fad.fa-delete-right:after, .fa-duotone.fa-delete-right:after {
  content: "";
}

.fad.fa-book-quran:after, .fa-duotone.fa-book-quran:after, .fad.fa-quran:after, .fa-duotone.fa-quran:after {
  content: "";
}

.fad.fa-circle-quarter:after, .fa-duotone.fa-circle-quarter:after {
  content: "";
}

.fad.fa-anchor:after, .fa-duotone.fa-anchor:after {
  content: "";
}

.fad.fa-border-all:after, .fa-duotone.fa-border-all:after {
  content: "";
}

.fad.fa-function:after, .fa-duotone.fa-function:after {
  content: "";
}

.fad.fa-face-angry:after, .fa-duotone.fa-face-angry:after, .fad.fa-angry:after, .fa-duotone.fa-angry:after {
  content: "";
}

.fad.fa-people-simple:after, .fa-duotone.fa-people-simple:after {
  content: "";
}

.fad.fa-cookie-bite:after, .fa-duotone.fa-cookie-bite:after {
  content: "";
}

.fad.fa-arrow-trend-down:after, .fa-duotone.fa-arrow-trend-down:after {
  content: "";
}

.fad.fa-rss:after, .fa-duotone.fa-rss:after, .fad.fa-feed:after, .fa-duotone.fa-feed:after {
  content: "";
}

.fad.fa-face-monocle:after, .fa-duotone.fa-face-monocle:after {
  content: "";
}

.fad.fa-draw-polygon:after, .fa-duotone.fa-draw-polygon:after {
  content: "";
}

.fad.fa-scale-balanced:after, .fa-duotone.fa-scale-balanced:after, .fad.fa-balance-scale:after, .fa-duotone.fa-balance-scale:after {
  content: "";
}

.fad.fa-calendar-lines:after, .fa-duotone.fa-calendar-lines:after, .fad.fa-calendar-note:after, .fa-duotone.fa-calendar-note:after {
  content: "";
}

.fad.fa-arrow-down-big-small:after, .fa-duotone.fa-arrow-down-big-small:after, .fad.fa-sort-size-down:after, .fa-duotone.fa-sort-size-down:after {
  content: "";
}

.fad.fa-gauge-simple-high:after, .fa-duotone.fa-gauge-simple-high:after, .fad.fa-tachometer:after, .fa-duotone.fa-tachometer:after, .fad.fa-tachometer-fast:after, .fa-duotone.fa-tachometer-fast:after {
  content: "";
}

.fad.fa-do-not-enter:after, .fa-duotone.fa-do-not-enter:after {
  content: "";
}

.fad.fa-shower:after, .fa-duotone.fa-shower:after {
  content: "";
}

.fad.fa-dice-d8:after, .fa-duotone.fa-dice-d8:after {
  content: "";
}

.fad.fa-desktop:after, .fa-duotone.fa-desktop:after, .fad.fa-desktop-alt:after, .fa-duotone.fa-desktop-alt:after {
  content: "";
}

.fad.fa-m:after, .fa-duotone.fa-m:after {
  content: "MM";
}

.fad.fa-spinner-scale:after, .fa-duotone.fa-spinner-scale:after {
  content: "";
}

.fad.fa-grip-dots-vertical:after, .fa-duotone.fa-grip-dots-vertical:after {
  content: "";
}

.fad.fa-face-viewfinder:after, .fa-duotone.fa-face-viewfinder:after {
  content: "";
}

.fad.fa-soft-serve:after, .fa-duotone.fa-soft-serve:after, .fad.fa-creemee:after, .fa-duotone.fa-creemee:after {
  content: "";
}

.fad.fa-h5:after, .fa-duotone.fa-h5:after {
  content: "";
}

.fad.fa-hand-back-point-down:after, .fa-duotone.fa-hand-back-point-down:after {
  content: "";
}

.fad.fa-table-list:after, .fa-duotone.fa-table-list:after, .fad.fa-th-list:after, .fa-duotone.fa-th-list:after {
  content: "";
}

.fad.fa-basket-shopping-minus:after, .fa-duotone.fa-basket-shopping-minus:after {
  content: "";
}

.fad.fa-comment-sms:after, .fa-duotone.fa-comment-sms:after, .fad.fa-sms:after, .fa-duotone.fa-sms:after {
  content: "";
}

.fad.fa-rectangle:after, .fa-duotone.fa-rectangle:after, .fad.fa-rectangle-landscape:after, .fa-duotone.fa-rectangle-landscape:after {
  content: "";
}

.fad.fa-clipboard-list-check:after, .fa-duotone.fa-clipboard-list-check:after {
  content: "";
}

.fad.fa-turkey:after, .fa-duotone.fa-turkey:after {
  content: "";
}

.fad.fa-book:after, .fa-duotone.fa-book:after {
  content: "";
}

.fad.fa-user-plus:after, .fa-duotone.fa-user-plus:after {
  content: "";
}

.fad.fa-ice-skate:after, .fa-duotone.fa-ice-skate:after {
  content: "";
}

.fad.fa-check:after, .fa-duotone.fa-check:after {
  content: "";
}

.fad.fa-battery-three-quarters:after, .fa-duotone.fa-battery-three-quarters:after, .fad.fa-battery-4:after, .fa-duotone.fa-battery-4:after {
  content: "";
}

.fad.fa-tomato:after, .fa-duotone.fa-tomato:after {
  content: "";
}

.fad.fa-sword-laser:after, .fa-duotone.fa-sword-laser:after {
  content: "";
}

.fad.fa-house-circle-check:after, .fa-duotone.fa-house-circle-check:after {
  content: "";
}

.fad.fa-buildings:after, .fa-duotone.fa-buildings:after {
  content: "";
}

.fad.fa-angle-left:after, .fa-duotone.fa-angle-left:after {
  content: "";
}

.fad.fa-cart-flatbed-boxes:after, .fa-duotone.fa-cart-flatbed-boxes:after, .fad.fa-dolly-flatbed-alt:after, .fa-duotone.fa-dolly-flatbed-alt:after {
  content: "";
}

.fad.fa-diagram-successor:after, .fa-duotone.fa-diagram-successor:after {
  content: "";
}

.fad.fa-truck-arrow-right:after, .fa-duotone.fa-truck-arrow-right:after {
  content: "";
}

.fad.fa-square-w:after, .fa-duotone.fa-square-w:after {
  content: "";
}

.fad.fa-arrows-split-up-and-left:after, .fa-duotone.fa-arrows-split-up-and-left:after {
  content: "";
}

.fad.fa-lamp:after, .fa-duotone.fa-lamp:after {
  content: "";
}

.fad.fa-airplay:after, .fa-duotone.fa-airplay:after {
  content: "";
}

.fad.fa-hand-fist:after, .fa-duotone.fa-hand-fist:after, .fad.fa-fist-raised:after, .fa-duotone.fa-fist-raised:after {
  content: "";
}

.fad.fa-shield-quartered:after, .fa-duotone.fa-shield-quartered:after {
  content: "";
}

.fad.fa-slash-forward:after, .fa-duotone.fa-slash-forward:after {
  content: "//";
}

.fad.fa-location-pen:after, .fa-duotone.fa-location-pen:after, .fad.fa-map-marker-edit:after, .fa-duotone.fa-map-marker-edit:after {
  content: "";
}

.fad.fa-cloud-moon:after, .fa-duotone.fa-cloud-moon:after {
  content: "";
}

.fad.fa-pot-food:after, .fa-duotone.fa-pot-food:after {
  content: "";
}

.fad.fa-briefcase:after, .fa-duotone.fa-briefcase:after {
  content: "";
}

.fad.fa-person-falling:after, .fa-duotone.fa-person-falling:after {
  content: "";
}

.fad.fa-image-portrait:after, .fa-duotone.fa-image-portrait:after, .fad.fa-portrait:after, .fa-duotone.fa-portrait:after {
  content: "";
}

.fad.fa-user-tag:after, .fa-duotone.fa-user-tag:after {
  content: "";
}

.fad.fa-rug:after, .fa-duotone.fa-rug:after {
  content: "";
}

.fad.fa-print-slash:after, .fa-duotone.fa-print-slash:after {
  content: "";
}

.fad.fa-earth-europe:after, .fa-duotone.fa-earth-europe:after, .fad.fa-globe-europe:after, .fa-duotone.fa-globe-europe:after {
  content: "";
}

.fad.fa-cart-flatbed-suitcase:after, .fa-duotone.fa-cart-flatbed-suitcase:after, .fad.fa-luggage-cart:after, .fa-duotone.fa-luggage-cart:after {
  content: "";
}

.fad.fa-hand-back-point-ribbon:after, .fa-duotone.fa-hand-back-point-ribbon:after {
  content: "";
}

.fad.fa-rectangle-xmark:after, .fa-duotone.fa-rectangle-xmark:after, .fad.fa-rectangle-times:after, .fa-duotone.fa-rectangle-times:after, .fad.fa-times-rectangle:after, .fa-duotone.fa-times-rectangle:after, .fad.fa-window-close:after, .fa-duotone.fa-window-close:after {
  content: "";
}

.fad.fa-tire-rugged:after, .fa-duotone.fa-tire-rugged:after {
  content: "";
}

.fad.fa-lightbulb-dollar:after, .fa-duotone.fa-lightbulb-dollar:after {
  content: "";
}

.fad.fa-cowbell:after, .fa-duotone.fa-cowbell:after {
  content: "";
}

.fad.fa-baht-sign:after, .fa-duotone.fa-baht-sign:after {
  content: "";
}

.fad.fa-corner:after, .fa-duotone.fa-corner:after {
  content: "";
}

.fad.fa-chevrons-right:after, .fa-duotone.fa-chevrons-right:after, .fad.fa-chevron-double-right:after, .fa-duotone.fa-chevron-double-right:after {
  content: "";
}

.fad.fa-book-open:after, .fa-duotone.fa-book-open:after {
  content: "";
}

.fad.fa-book-journal-whills:after, .fa-duotone.fa-book-journal-whills:after, .fad.fa-journal-whills:after, .fa-duotone.fa-journal-whills:after {
  content: "";
}

.fad.fa-inhaler:after, .fa-duotone.fa-inhaler:after {
  content: "";
}

.fad.fa-handcuffs:after, .fa-duotone.fa-handcuffs:after {
  content: "";
}

.fad.fa-snake:after, .fa-duotone.fa-snake:after {
  content: "";
}

.fad.fa-triangle-exclamation:after, .fa-duotone.fa-triangle-exclamation:after, .fad.fa-exclamation-triangle:after, .fa-duotone.fa-exclamation-triangle:after, .fad.fa-warning:after, .fa-duotone.fa-warning:after {
  content: "";
}

.fad.fa-note-medical:after, .fa-duotone.fa-note-medical:after {
  content: "";
}

.fad.fa-database:after, .fa-duotone.fa-database:after {
  content: "";
}

.fad.fa-down-left:after, .fa-duotone.fa-down-left:after {
  content: "";
}

.fad.fa-share:after, .fa-duotone.fa-share:after, .fad.fa-mail-forward:after, .fa-duotone.fa-mail-forward:after {
  content: "";
}

.fad.fa-face-thinking:after, .fa-duotone.fa-face-thinking:after {
  content: "";
}

.fad.fa-turn-down-right:after, .fa-duotone.fa-turn-down-right:after {
  content: "";
}

.fad.fa-bottle-droplet:after, .fa-duotone.fa-bottle-droplet:after {
  content: "";
}

.fad.fa-mask-face:after, .fa-duotone.fa-mask-face:after {
  content: "";
}

.fad.fa-hill-rockslide:after, .fa-duotone.fa-hill-rockslide:after {
  content: "";
}

.fad.fa-scanner-keyboard:after, .fa-duotone.fa-scanner-keyboard:after {
  content: "";
}

.fad.fa-circle-o:after, .fa-duotone.fa-circle-o:after {
  content: "";
}

.fad.fa-grid-horizontal:after, .fa-duotone.fa-grid-horizontal:after {
  content: "";
}

.fad.fa-message-dollar:after, .fa-duotone.fa-message-dollar:after, .fad.fa-comment-alt-dollar:after, .fa-duotone.fa-comment-alt-dollar:after {
  content: "";
}

.fad.fa-right-left:after, .fa-duotone.fa-right-left:after, .fad.fa-exchange-alt:after, .fa-duotone.fa-exchange-alt:after {
  content: "";
}

.fad.fa-columns-3:after, .fa-duotone.fa-columns-3:after {
  content: "";
}

.fad.fa-paper-plane:after, .fa-duotone.fa-paper-plane:after {
  content: "";
}

.fad.fa-road-circle-exclamation:after, .fa-duotone.fa-road-circle-exclamation:after {
  content: "";
}

.fad.fa-dungeon:after, .fa-duotone.fa-dungeon:after {
  content: "";
}

.fad.fa-hand-holding-box:after, .fa-duotone.fa-hand-holding-box:after {
  content: "";
}

.fad.fa-input-text:after, .fa-duotone.fa-input-text:after {
  content: "";
}

.fad.fa-window-flip:after, .fa-duotone.fa-window-flip:after, .fad.fa-window-alt:after, .fa-duotone.fa-window-alt:after {
  content: "";
}

.fad.fa-align-right:after, .fa-duotone.fa-align-right:after {
  content: "";
}

.fad.fa-scanner-gun:after, .fa-duotone.fa-scanner-gun:after, .fad.fa-scanner:after, .fa-duotone.fa-scanner:after {
  content: "";
}

.fad.fa-tire:after, .fa-duotone.fa-tire:after {
  content: "";
}

.fad.fa-engine:after, .fa-duotone.fa-engine:after {
  content: "";
}

.fad.fa-money-bill-1-wave:after, .fa-duotone.fa-money-bill-1-wave:after, .fad.fa-money-bill-wave-alt:after, .fa-duotone.fa-money-bill-wave-alt:after {
  content: "";
}

.fad.fa-life-ring:after, .fa-duotone.fa-life-ring:after {
  content: "";
}

.fad.fa-hands:after, .fa-duotone.fa-hands:after, .fad.fa-sign-language:after, .fa-duotone.fa-sign-language:after, .fad.fa-signing:after, .fa-duotone.fa-signing:after {
  content: "";
}

.fad.fa-circle-caret-right:after, .fa-duotone.fa-circle-caret-right:after, .fad.fa-caret-circle-right:after, .fa-duotone.fa-caret-circle-right:after {
  content: "";
}

.fad.fa-turn-left:after, .fa-duotone.fa-turn-left:after {
  content: "";
}

.fad.fa-wheat:after, .fa-duotone.fa-wheat:after {
  content: "";
}

.fad.fa-file-spreadsheet:after, .fa-duotone.fa-file-spreadsheet:after {
  content: "";
}

.fad.fa-audio-description-slash:after, .fa-duotone.fa-audio-description-slash:after {
  content: "";
}

.fad.fa-bell-ring:after, .fa-duotone.fa-bell-ring:after {
  content: "";
}

.fad.fa-calendar-day:after, .fa-duotone.fa-calendar-day:after {
  content: "";
}

.fad.fa-water-ladder:after, .fa-duotone.fa-water-ladder:after, .fad.fa-ladder-water:after, .fa-duotone.fa-ladder-water:after, .fad.fa-swimming-pool:after, .fa-duotone.fa-swimming-pool:after {
  content: "";
}

.fad.fa-arrows-up-down:after, .fa-duotone.fa-arrows-up-down:after, .fad.fa-arrows-v:after, .fa-duotone.fa-arrows-v:after {
  content: "";
}

.fad.fa-chess-pawn-piece:after, .fa-duotone.fa-chess-pawn-piece:after, .fad.fa-chess-pawn-alt:after, .fa-duotone.fa-chess-pawn-alt:after {
  content: "";
}

.fad.fa-face-grimace:after, .fa-duotone.fa-face-grimace:after, .fad.fa-grimace:after, .fa-duotone.fa-grimace:after {
  content: "";
}

.fad.fa-wheelchair-move:after, .fa-duotone.fa-wheelchair-move:after, .fad.fa-wheelchair-alt:after, .fa-duotone.fa-wheelchair-alt:after {
  content: "";
}

.fad.fa-turn-down:after, .fa-duotone.fa-turn-down:after, .fad.fa-level-down-alt:after, .fa-duotone.fa-level-down-alt:after {
  content: "";
}

.fad.fa-square-s:after, .fa-duotone.fa-square-s:after {
  content: "";
}

.fad.fa-rectangle-barcode:after, .fa-duotone.fa-rectangle-barcode:after, .fad.fa-barcode-alt:after, .fa-duotone.fa-barcode-alt:after {
  content: "";
}

.fad.fa-person-walking-arrow-right:after, .fa-duotone.fa-person-walking-arrow-right:after {
  content: "";
}

.fad.fa-square-envelope:after, .fa-duotone.fa-square-envelope:after, .fad.fa-envelope-square:after, .fa-duotone.fa-envelope-square:after {
  content: "";
}

.fad.fa-dice:after, .fa-duotone.fa-dice:after {
  content: "";
}

.fad.fa-unicorn:after, .fa-duotone.fa-unicorn:after {
  content: "";
}

.fad.fa-bowling-ball:after, .fa-duotone.fa-bowling-ball:after {
  content: "";
}

.fad.fa-pompebled:after, .fa-duotone.fa-pompebled:after {
  content: "";
}

.fad.fa-brain:after, .fa-duotone.fa-brain:after {
  content: "";
}

.fad.fa-watch-smart:after, .fa-duotone.fa-watch-smart:after {
  content: "";
}

.fad.fa-book-user:after, .fa-duotone.fa-book-user:after {
  content: "";
}

.fad.fa-sensor-cloud:after, .fa-duotone.fa-sensor-cloud:after, .fad.fa-sensor-smoke:after, .fa-duotone.fa-sensor-smoke:after {
  content: "";
}

.fad.fa-clapperboard-play:after, .fa-duotone.fa-clapperboard-play:after {
  content: "";
}

.fad.fa-bandage:after, .fa-duotone.fa-bandage:after, .fad.fa-band-aid:after, .fa-duotone.fa-band-aid:after {
  content: "";
}

.fad.fa-calendar-minus:after, .fa-duotone.fa-calendar-minus:after {
  content: "";
}

.fad.fa-circle-xmark:after, .fa-duotone.fa-circle-xmark:after, .fad.fa-times-circle:after, .fa-duotone.fa-times-circle:after, .fad.fa-xmark-circle:after, .fa-duotone.fa-xmark-circle:after {
  content: "";
}

.fad.fa-circle-4:after, .fa-duotone.fa-circle-4:after {
  content: "";
}

.fad.fa-gifts:after, .fa-duotone.fa-gifts:after {
  content: "";
}

.fad.fa-album-collection:after, .fa-duotone.fa-album-collection:after {
  content: "";
}

.fad.fa-hotel:after, .fa-duotone.fa-hotel:after {
  content: "";
}

.fad.fa-earth-asia:after, .fa-duotone.fa-earth-asia:after, .fad.fa-globe-asia:after, .fa-duotone.fa-globe-asia:after {
  content: "";
}

.fad.fa-id-card-clip:after, .fa-duotone.fa-id-card-clip:after, .fad.fa-id-card-alt:after, .fa-duotone.fa-id-card-alt:after {
  content: "";
}

.fad.fa-magnifying-glass-plus:after, .fa-duotone.fa-magnifying-glass-plus:after, .fad.fa-search-plus:after, .fa-duotone.fa-search-plus:after {
  content: "";
}

.fad.fa-thumbs-up:after, .fa-duotone.fa-thumbs-up:after {
  content: "";
}

.fad.fa-cloud-showers:after, .fa-duotone.fa-cloud-showers:after {
  content: "";
}

.fad.fa-user-clock:after, .fa-duotone.fa-user-clock:after {
  content: "";
}

.fad.fa-onion:after, .fa-duotone.fa-onion:after {
  content: "";
}

.fad.fa-clock-twelve-thirty:after, .fa-duotone.fa-clock-twelve-thirty:after {
  content: "";
}

.fad.fa-arrow-down-to-dotted-line:after, .fa-duotone.fa-arrow-down-to-dotted-line:after {
  content: "";
}

.fad.fa-hand-dots:after, .fa-duotone.fa-hand-dots:after, .fad.fa-allergies:after, .fa-duotone.fa-allergies:after {
  content: "";
}

.fad.fa-file-invoice:after, .fa-duotone.fa-file-invoice:after {
  content: "";
}

.fad.fa-window-minimize:after, .fa-duotone.fa-window-minimize:after {
  content: "";
}

.fad.fa-rectangle-wide:after, .fa-duotone.fa-rectangle-wide:after {
  content: "";
}

.fad.fa-comment-arrow-up:after, .fa-duotone.fa-comment-arrow-up:after {
  content: "";
}

.fad.fa-garlic:after, .fa-duotone.fa-garlic:after {
  content: "";
}

.fad.fa-mug-saucer:after, .fa-duotone.fa-mug-saucer:after, .fad.fa-coffee:after, .fa-duotone.fa-coffee:after {
  content: "";
}

.fad.fa-brush:after, .fa-duotone.fa-brush:after {
  content: "";
}

.fad.fa-tree-decorated:after, .fa-duotone.fa-tree-decorated:after {
  content: "";
}

.fad.fa-mask:after, .fa-duotone.fa-mask:after {
  content: "";
}

.fad.fa-calendar-heart:after, .fa-duotone.fa-calendar-heart:after {
  content: "";
}

.fad.fa-magnifying-glass-minus:after, .fa-duotone.fa-magnifying-glass-minus:after, .fad.fa-search-minus:after, .fa-duotone.fa-search-minus:after {
  content: "";
}

.fad.fa-flower:after, .fa-duotone.fa-flower:after {
  content: "";
}

.fad.fa-arrow-down-from-arc:after, .fa-duotone.fa-arrow-down-from-arc:after {
  content: "";
}

.fad.fa-right-left-large:after, .fa-duotone.fa-right-left-large:after {
  content: "";
}

.fad.fa-ruler-vertical:after, .fa-duotone.fa-ruler-vertical:after {
  content: "";
}

.fad.fa-circles-overlap:after, .fa-duotone.fa-circles-overlap:after {
  content: "";
}

.fad.fa-user-large:after, .fa-duotone.fa-user-large:after, .fad.fa-user-alt:after, .fa-duotone.fa-user-alt:after {
  content: "";
}

.fad.fa-starship-freighter:after, .fa-duotone.fa-starship-freighter:after {
  content: "";
}

.fad.fa-train-tram:after, .fa-duotone.fa-train-tram:after {
  content: "";
}

.fad.fa-bridge-suspension:after, .fa-duotone.fa-bridge-suspension:after {
  content: "";
}

.fad.fa-trash-check:after, .fa-duotone.fa-trash-check:after {
  content: "";
}

.fad.fa-user-nurse:after, .fa-duotone.fa-user-nurse:after {
  content: "";
}

.fad.fa-boombox:after, .fa-duotone.fa-boombox:after {
  content: "";
}

.fad.fa-syringe:after, .fa-duotone.fa-syringe:after {
  content: "";
}

.fad.fa-cloud-sun:after, .fa-duotone.fa-cloud-sun:after {
  content: "";
}

.fad.fa-shield-exclamation:after, .fa-duotone.fa-shield-exclamation:after {
  content: "";
}

.fad.fa-stopwatch-20:after, .fa-duotone.fa-stopwatch-20:after {
  content: "";
}

.fad.fa-square-full:after, .fa-duotone.fa-square-full:after {
  content: "";
}

.fad.fa-grip-dots:after, .fa-duotone.fa-grip-dots:after {
  content: "";
}

.fad.fa-comment-exclamation:after, .fa-duotone.fa-comment-exclamation:after {
  content: "";
}

.fad.fa-pen-swirl:after, .fa-duotone.fa-pen-swirl:after {
  content: "";
}

.fad.fa-falafel:after, .fa-duotone.fa-falafel:after {
  content: "";
}

.fad.fa-circle-2:after, .fa-duotone.fa-circle-2:after {
  content: "";
}

.fad.fa-magnet:after, .fa-duotone.fa-magnet:after {
  content: "";
}

.fad.fa-jar:after, .fa-duotone.fa-jar:after {
  content: "";
}

.fad.fa-gramophone:after, .fa-duotone.fa-gramophone:after {
  content: "";
}

.fad.fa-dice-d12:after, .fa-duotone.fa-dice-d12:after {
  content: "";
}

.fad.fa-note-sticky:after, .fa-duotone.fa-note-sticky:after, .fad.fa-sticky-note:after, .fa-duotone.fa-sticky-note:after {
  content: "";
}

.fad.fa-down:after, .fa-duotone.fa-down:after, .fad.fa-arrow-alt-down:after, .fa-duotone.fa-arrow-alt-down:after {
  content: "";
}

.fad.fa-hundred-points:after, .fa-duotone.fa-hundred-points:after, .fad.fa-100:after, .fa-duotone.fa-100:after {
  content: "";
}

.fad.fa-paperclip-vertical:after, .fa-duotone.fa-paperclip-vertical:after {
  content: "";
}

.fad.fa-wind-warning:after, .fa-duotone.fa-wind-warning:after, .fad.fa-wind-circle-exclamation:after, .fa-duotone.fa-wind-circle-exclamation:after {
  content: "";
}

.fad.fa-location-pin-slash:after, .fa-duotone.fa-location-pin-slash:after, .fad.fa-map-marker-slash:after, .fa-duotone.fa-map-marker-slash:after {
  content: "";
}

.fad.fa-face-sad-sweat:after, .fa-duotone.fa-face-sad-sweat:after {
  content: "";
}

.fad.fa-bug-slash:after, .fa-duotone.fa-bug-slash:after {
  content: "";
}

.fad.fa-cupcake:after, .fa-duotone.fa-cupcake:after {
  content: "";
}

.fad.fa-light-switch-off:after, .fa-duotone.fa-light-switch-off:after {
  content: "";
}

.fad.fa-toggle-large-off:after, .fa-duotone.fa-toggle-large-off:after {
  content: "";
}

.fad.fa-pen-fancy-slash:after, .fa-duotone.fa-pen-fancy-slash:after {
  content: "";
}

.fad.fa-truck-container:after, .fa-duotone.fa-truck-container:after {
  content: "";
}

.fad.fa-boot:after, .fa-duotone.fa-boot:after {
  content: "";
}

.fad.fa-arrow-up-from-water-pump:after, .fa-duotone.fa-arrow-up-from-water-pump:after {
  content: "";
}

.fad.fa-file-check:after, .fa-duotone.fa-file-check:after {
  content: "";
}

.fad.fa-bone:after, .fa-duotone.fa-bone:after {
  content: "";
}

.fad.fa-cards-blank:after, .fa-duotone.fa-cards-blank:after {
  content: "";
}

.fad.fa-circle-3:after, .fa-duotone.fa-circle-3:after {
  content: "";
}

.fad.fa-bench-tree:after, .fa-duotone.fa-bench-tree:after {
  content: "";
}

.fad.fa-keyboard-brightness-low:after, .fa-duotone.fa-keyboard-brightness-low:after {
  content: "";
}

.fad.fa-ski-boot-ski:after, .fa-duotone.fa-ski-boot-ski:after {
  content: "";
}

.fad.fa-brain-circuit:after, .fa-duotone.fa-brain-circuit:after {
  content: "";
}

.fad.fa-table-cells-row-unlock:after, .fa-duotone.fa-table-cells-row-unlock:after {
  content: "";
}

.fad.fa-user-injured:after, .fa-duotone.fa-user-injured:after {
  content: "";
}

.fad.fa-block-brick-fire:after, .fa-duotone.fa-block-brick-fire:after, .fad.fa-firewall:after, .fa-duotone.fa-firewall:after {
  content: "";
}

.fad.fa-face-sad-tear:after, .fa-duotone.fa-face-sad-tear:after, .fad.fa-sad-tear:after, .fa-duotone.fa-sad-tear:after {
  content: "";
}

.fad.fa-plane:after, .fa-duotone.fa-plane:after {
  content: "";
}

.fad.fa-tent-arrows-down:after, .fa-duotone.fa-tent-arrows-down:after {
  content: "";
}

.fad.fa-exclamation:after, .fa-duotone.fa-exclamation:after {
  content: "!!";
}

.fad.fa-arrows-spin:after, .fa-duotone.fa-arrows-spin:after {
  content: "";
}

.fad.fa-face-smile-relaxed:after, .fa-duotone.fa-face-smile-relaxed:after {
  content: "";
}

.fad.fa-comment-xmark:after, .fa-duotone.fa-comment-xmark:after, .fad.fa-comment-times:after, .fa-duotone.fa-comment-times:after {
  content: "";
}

.fad.fa-print:after, .fa-duotone.fa-print:after {
  content: "";
}

.fad.fa-turkish-lira-sign:after, .fa-duotone.fa-turkish-lira-sign:after, .fad.fa-try:after, .fa-duotone.fa-try:after, .fad.fa-turkish-lira:after, .fa-duotone.fa-turkish-lira:after {
  content: "";
}

.fad.fa-face-nose-steam:after, .fa-duotone.fa-face-nose-steam:after {
  content: "";
}

.fad.fa-circle-waveform-lines:after, .fa-duotone.fa-circle-waveform-lines:after, .fad.fa-waveform-circle:after, .fa-duotone.fa-waveform-circle:after {
  content: "";
}

.fad.fa-dollar-sign:after, .fa-duotone.fa-dollar-sign:after, .fad.fa-dollar:after, .fa-duotone.fa-dollar:after, .fad.fa-usd:after, .fa-duotone.fa-usd:after {
  content: "$$";
}

.fad.fa-ferris-wheel:after, .fa-duotone.fa-ferris-wheel:after {
  content: "";
}

.fad.fa-computer-speaker:after, .fa-duotone.fa-computer-speaker:after {
  content: "";
}

.fad.fa-skull-cow:after, .fa-duotone.fa-skull-cow:after {
  content: "";
}

.fad.fa-x:after, .fa-duotone.fa-x:after {
  content: "XX";
}

.fad.fa-magnifying-glass-dollar:after, .fa-duotone.fa-magnifying-glass-dollar:after, .fad.fa-search-dollar:after, .fa-duotone.fa-search-dollar:after {
  content: "";
}

.fad.fa-users-gear:after, .fa-duotone.fa-users-gear:after, .fad.fa-users-cog:after, .fa-duotone.fa-users-cog:after {
  content: "";
}

.fad.fa-person-military-pointing:after, .fa-duotone.fa-person-military-pointing:after {
  content: "";
}

.fad.fa-building-columns:after, .fa-duotone.fa-building-columns:after, .fad.fa-bank:after, .fa-duotone.fa-bank:after, .fad.fa-institution:after, .fa-duotone.fa-institution:after, .fad.fa-museum:after, .fa-duotone.fa-museum:after, .fad.fa-university:after, .fa-duotone.fa-university:after {
  content: "";
}

.fad.fa-circle-t:after, .fa-duotone.fa-circle-t:after {
  content: "";
}

.fad.fa-sack:after, .fa-duotone.fa-sack:after {
  content: "";
}

.fad.fa-grid-2:after, .fa-duotone.fa-grid-2:after {
  content: "";
}

.fad.fa-camera-cctv:after, .fa-duotone.fa-camera-cctv:after, .fad.fa-cctv:after, .fa-duotone.fa-cctv:after {
  content: "";
}

.fad.fa-umbrella:after, .fa-duotone.fa-umbrella:after {
  content: "";
}

.fad.fa-trowel:after, .fa-duotone.fa-trowel:after {
  content: "";
}

.fad.fa-horizontal-rule:after, .fa-duotone.fa-horizontal-rule:after {
  content: "";
}

.fad.fa-bed-front:after, .fa-duotone.fa-bed-front:after, .fad.fa-bed-alt:after, .fa-duotone.fa-bed-alt:after {
  content: "";
}

.fad.fa-d:after, .fa-duotone.fa-d:after {
  content: "DD";
}

.fad.fa-stapler:after, .fa-duotone.fa-stapler:after {
  content: "";
}

.fad.fa-masks-theater:after, .fa-duotone.fa-masks-theater:after, .fad.fa-theater-masks:after, .fa-duotone.fa-theater-masks:after {
  content: "";
}

.fad.fa-file-gif:after, .fa-duotone.fa-file-gif:after {
  content: "";
}

.fad.fa-kip-sign:after, .fa-duotone.fa-kip-sign:after {
  content: "";
}

.fad.fa-face-woozy:after, .fa-duotone.fa-face-woozy:after {
  content: "";
}

.fad.fa-cloud-question:after, .fa-duotone.fa-cloud-question:after {
  content: "";
}

.fad.fa-pineapple:after, .fa-duotone.fa-pineapple:after {
  content: "";
}

.fad.fa-hand-point-left:after, .fa-duotone.fa-hand-point-left:after {
  content: "";
}

.fad.fa-gallery-thumbnails:after, .fa-duotone.fa-gallery-thumbnails:after {
  content: "";
}

.fad.fa-circle-j:after, .fa-duotone.fa-circle-j:after {
  content: "";
}

.fad.fa-eyes:after, .fa-duotone.fa-eyes:after {
  content: "";
}

.fad.fa-handshake-simple:after, .fa-duotone.fa-handshake-simple:after, .fad.fa-handshake-alt:after, .fa-duotone.fa-handshake-alt:after {
  content: "";
}

.fad.fa-page-caret-up:after, .fa-duotone.fa-page-caret-up:after, .fad.fa-file-caret-up:after, .fa-duotone.fa-file-caret-up:after {
  content: "";
}

.fad.fa-jet-fighter:after, .fa-duotone.fa-jet-fighter:after, .fad.fa-fighter-jet:after, .fa-duotone.fa-fighter-jet:after {
  content: "";
}

.fad.fa-comet:after, .fa-duotone.fa-comet:after {
  content: "";
}

.fad.fa-square-share-nodes:after, .fa-duotone.fa-square-share-nodes:after, .fad.fa-share-alt-square:after, .fa-duotone.fa-share-alt-square:after {
  content: "";
}

.fad.fa-reflect-vertical:after, .fa-duotone.fa-reflect-vertical:after {
  content: "";
}

.fad.fa-shield-keyhole:after, .fa-duotone.fa-shield-keyhole:after {
  content: "";
}

.fad.fa-file-mp4:after, .fa-duotone.fa-file-mp4:after {
  content: "";
}

.fad.fa-barcode:after, .fa-duotone.fa-barcode:after {
  content: "";
}

.fad.fa-bulldozer:after, .fa-duotone.fa-bulldozer:after {
  content: "";
}

.fad.fa-plus-minus:after, .fa-duotone.fa-plus-minus:after {
  content: "";
}

.fad.fa-square-sliders-vertical:after, .fa-duotone.fa-square-sliders-vertical:after, .fad.fa-sliders-v-square:after, .fa-duotone.fa-sliders-v-square:after {
  content: "";
}

.fad.fa-video:after, .fa-duotone.fa-video:after, .fad.fa-video-camera:after, .fa-duotone.fa-video-camera:after {
  content: "";
}

.fad.fa-message-middle:after, .fa-duotone.fa-message-middle:after, .fad.fa-comment-middle-alt:after, .fa-duotone.fa-comment-middle-alt:after {
  content: "";
}

.fad.fa-graduation-cap:after, .fa-duotone.fa-graduation-cap:after, .fad.fa-mortar-board:after, .fa-duotone.fa-mortar-board:after {
  content: "";
}

.fad.fa-hand-holding-medical:after, .fa-duotone.fa-hand-holding-medical:after {
  content: "";
}

.fad.fa-person-circle-check:after, .fa-duotone.fa-person-circle-check:after {
  content: "";
}

.fad.fa-square-z:after, .fa-duotone.fa-square-z:after {
  content: "";
}

.fad.fa-message-text:after, .fa-duotone.fa-message-text:after, .fad.fa-comment-alt-text:after, .fa-duotone.fa-comment-alt-text:after {
  content: "";
}

.fad.fa-turn-up:after, .fa-duotone.fa-turn-up:after, .fad.fa-level-up-alt:after, .fa-duotone.fa-level-up-alt:after {
  content: "";
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-light: normal 300 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("fa-light-300.3cd67a10.woff2") format("woff2"), url("fa-light-300.e98c3ee2.ttf") format("truetype");
}

.fal, .fa-light {
  font-weight: 300;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-regular: normal 400 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("fa-regular-400.402a2c8b.woff2") format("woff2"), url("fa-regular-400.ecf41ec7.ttf") format("truetype");
}

.far, .fa-regular {
  font-weight: 400;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.a6efde67.woff2") format("woff2"), url("fa-solid-900.cd862fcc.ttf") format("truetype");
}

.fas, .fa-solid {
  font-weight: 900;
}

:root, :host {
  --fa-style-family-classic: "Font Awesome 6 Pro";
  --fa-font-thin: normal 100 1em / 1 "Font Awesome 6 Pro";
}

@font-face {
  font-family: "Font Awesome 6 Pro";
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("fa-thin-100.4359b891.woff2") format("woff2"), url("fa-thin-100.f6e69cfb.ttf") format("truetype");
}

.fat, .fa-thin {
  font-weight: 100;
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-display: block;
  font-weight: 400;
  src: url("fa-brands-400.0859ffe7.woff2") format("woff2"), url("fa-brands-400.465c5219.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 900;
  src: url("fa-solid-900.a6efde67.woff2") format("woff2"), url("fa-solid-900.cd862fcc.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 400;
  src: url("fa-regular-400.402a2c8b.woff2") format("woff2"), url("fa-regular-400.ecf41ec7.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-display: block;
  font-weight: 300;
  src: url("fa-light-300.3cd67a10.woff2") format("woff2"), url("fa-light-300.e98c3ee2.ttf") format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-display: block;
  font-weight: 900;
  src: url("fa-duotone-900.af241644.woff2") format("woff2"), url("fa-duotone-900.442e9bfa.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-solid-900.a6efde67.woff2") format("woff2"), url("fa-solid-900.cd862fcc.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-brands-400.0859ffe7.woff2") format("woff2"), url("fa-brands-400.465c5219.ttf") format("truetype");
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-regular-400.402a2c8b.woff2") format("woff2"), url("fa-regular-400.ecf41ec7.ttf") format("truetype");
  unicode-range: U+F003, U+F006, U+F014, U+F016-F017, U+F01A-F01B, U+F01D, U+F022, U+F03E, U+F044, U+F046, U+F05C-F05D, U+F06E, U+F070, U+F087-F088, U+F08A, U+F094, U+F096-F097, U+F09D, U+F0A0, U+F0A2, U+F0A4-F0A7, U+F0C5, U+F0C7, U+F0E5-F0E6, U+F0EB, U+F0F6-F0F8, U+F10C, U+F114-F115, U+F118-F11A, U+F11C-F11D, U+F133, U+F147, U+F14E, U+F150-F152, U+F185-F186, U+F18E, U+F190-F192, U+F196, U+F1C1-F1C9, U+F1D9, U+F1DB, U+F1E3, U+F1EA, U+F1F7, U+F1F9, U+F20A, U+F247-F248, U+F24A, U+F24D, U+F255-F25B, U+F25D, U+F271-F274, U+F278, U+F27B, U+F28C, U+F28E, U+F29C, U+F2B5, U+F2B7, U+F2BA, U+F2BC, U+F2BE, U+F2C0-F2C1, U+F2C3, U+F2D0, U+F2D2, U+F2D4, U+F2DC;
}

@font-face {
  font-family: FontAwesome;
  font-display: block;
  src: url("fa-v4compatibility.25a9886b.woff2") format("woff2"), url("fa-v4compatibility.8781db08.ttf") format("truetype");
  unicode-range: U+F041, U+F047, U+F065-F066, U+F07D-F07E, U+F080, U+F08B, U+F08E, U+F090, U+F09A, U+F0AC, U+F0AE, U+F0B2, U+F0D0, U+F0D6, U+F0E4, U+F0EC, U+F10A-F10B, U+F123, U+F13E, U+F148-F149, U+F14C, U+F156, U+F15E, U+F160-F161, U+F163, U+F175-F178, U+F195, U+F1F8, U+F219, U+F27A;
}

.wrapper:after {
  clear: both;
  content: "";
  display: block;
}

.wrapper {
  box-sizing: content-box;
  width: auto;
  margin: 0 auto;
  padding: 0 .8rem;
  position: relative;
}

@media (width >= 769px) {
  .wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 1261px) {
  .wrapper {
    padding-left: 5rem;
    padding-right: 5rem;
  }
}

.wrapper > .wrapper {
  padding-left: 0;
  padding-right: 0;
}

.wrapper--m {
  max-width: 1240px;
}

.wrapper--sm {
  max-width: 820px;
}

.wrapper--l {
  max-width: 1440px;
}

.wrapper--text {
  max-width: 49.9rem;
}

@media (width >= 769px) {
  .wrapper--no-padding {
    padding: 0;
  }
}

.field:after {
  clear: both;
  content: "";
  display: block;
}

.field {
  padding-bottom: 1rem;
}

.field:last-child {
  padding-bottom: 0;
}

.field > ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.field > ul li {
  list-style: none;
}

.field > ul label {
  cursor: pointer;
  font-weight: 400;
}

.field.field--checkboxinput label {
  cursor: pointer;
  align-items: center;
  gap: .5rem;
  font-weight: 400;
  display: flex;
}

.field.field--checkboxinput input {
  min-width: 1rem;
}

.file-input {
  padding: 0;
  position: relative;
}

.file-input input {
  visibility: hidden;
  max-width: none;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.file-input label {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: .5rem 1rem;
  font-weight: 400;
  display: block;
}

.errorlist {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.errorlist li {
  list-style: none;
}

.errorlist {
  --tw-text-opacity: 1;
  color: rgb(255 0 0 / var(--tw-text-opacity));
  padding: .5rem 0;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  text-align: center;
  vertical-align: middle;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-width: 0;
  border-radius: .5rem;
  min-width: 10.6rem;
  padding: 1.12rem 1rem;
  font-size: .8rem;
  font-weight: 700;
  line-height: 1.2;
  text-decoration-line: none;
  transition: all .5s;
  display: inline-block;
  position: relative;
}

.btn img {
  opacity: 0;
  width: 0;
  max-width: 0;
  margin: -2px 0 0;
  transition: all .5s;
  display: inline-block;
}

.btn:hover, .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  min-width: 12.5rem;
}

.btn:hover img, .btn:focus img {
  opacity: 1;
  width: 100%;
  max-width: 1.3rem;
  margin-left: .5rem;
}

.btn--img-left:hover img, .btn--img-left:focus img {
  margin-left: 0;
  margin-right: .5rem;
}

.btn--down-arrow:hover img, .btn--down-arrow:focus img {
  max-width: .85rem;
}

.btn--arrow:after {
  vertical-align: middle;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("arrow-right.d1584e45.svg");
  background-size: contain;
  width: 1.3rem;
  height: .8rem;
  margin-left: .6rem;
  display: inline-block;
}

.btn--white {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #fff0;
  border-radius: .5rem;
}

.btn--white:hover, .btn--white:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn--partial-white {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  background-color: #ffffff70;
  border-radius: .5rem;
}

.btn--orange {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 1rem 2.5rem;
}

.btn--orange:hover, .btn--orange:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 3rem;
  padding-right: 3rem;
  text-decoration-line: underline;
}

.btn--orange img {
  opacity: 1;
  width: 100%;
  max-width: 1.3rem;
  margin-left: .5rem;
}

.btn--orange--rounded {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  border-radius: 9999px;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.btn--orange--rounded:hover, .btn--orange--rounded:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
}

.btn--grey {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.btn--grey:hover, .btn--grey:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn--white-text, .btn--white-text:hover, .btn--white-text:focus {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn--dark-red {
  --tw-bg-opacity: 1;
  background-color: rgb(209 60 13 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.btn--dark-red:hover, .btn--dark-red:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.btn--bordered {
  --tw-border-opacity: 1;
  border-width: 1.5px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.btn--bordered:hover, .btn--bordered:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.btn--transparent {
  background-color: #fff0 !important;
  background-image: none !important;
}

.skip-to-content .btn {
  z-index: 60;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: bottom, top;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: -8rem;
  left: 50%;
}

.skip-to-content .btn:focus {
  top: 1rem;
}

.book-call-btn {
  z-index: 110;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  display: none;
  position: fixed;
  bottom: .75rem;
  right: .75rem;
}

.book-call-btn.show {
  flex-direction: column;
  display: flex;
}

.book-call-btn .book-call-btn__button {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  margin-bottom: 1.5rem;
  padding-left: .7rem;
  padding-right: .7rem;
  min-width: 0 !important;
}

.book-call-btn .book-call-btn__button:hover, .book-call-btn .book-call-btn__button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
}

@media (width >= 961px) {
  .book-call-btn .book-call-btn__button {
    min-width: 12rem !important;
  }
}

.tag {
  --tw-bg-opacity: 1;
  background-color: rgb(254 236 230 / var(--tw-bg-opacity));
  background-image: url("tag.0892ea35.svg");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  border-radius: 1.35rem;
  padding: .25rem 1.25rem .25rem .55rem;
  font-size: .7rem;
  line-height: 1.05rem;
  text-decoration-line: none;
  transition: background-color .2s;
}

.tag:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.next-page {
  padding-bottom: 3.75rem;
  display: block;
}

@media (width >= 961px) {
  .next-page {
    padding-right: 7.3rem;
  }
}

.next-page__container {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media (width >= 561px) {
  .next-page__container {
    flex-direction: row;
  }
}

.next-page__next {
  justify-content: flex-end;
  text-decoration-line: none;
  display: flex;
}

.next-page__next p {
  text-align: right;
  padding-bottom: 0;
  padding-right: 1.5rem;
}

.next-page__arrow {
  justify-content: center;
  width: 4rem;
  display: flex;
  position: relative;
}

.next-page__arrow img {
  width: 1.3rem;
  height: auto;
}

.next-page__progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.orange-gradient {
  object-fit: cover;
  background-image: url("orange-grad.e9a259f3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-template {
  min-height: 100dvh;
  position: relative;
}

.form-template form fieldset + fieldset {
  margin-top: 5rem;
}

.form-template form .help-text {
  margin-top: .5rem;
  padding-bottom: 0;
  font-size: .8rem;
  font-style: italic;
}

.form-template form .field .radio-select label {
  cursor: pointer;
  padding-bottom: .25rem;
  font-weight: 400;
}

.form-template form .cf-turnstile {
  padding-top: .5rem;
}

.form-template__default .text-block__col {
  flex-direction: column;
  align-items: flex-start;
  max-width: 31.85rem;
  display: flex;
}

.form-template__default .text-block__col p:last-child {
  padding-bottom: 2rem;
}

.form-template__default .text-block__col .btn {
  margin-top: auto;
}

.form-template__default .text-block__cols {
  justify-content: space-between;
}

.form-template__default h1 {
  padding-bottom: 2rem;
  font-size: 2.4rem;
}

@media (width >= 769px) {
  .form-template__default h1 {
    padding-bottom: 3.2rem;
  }
}

.form-template__default.hide {
  display: none;
}

.form-template__reveal__container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-top-left-radius: 2.2rem;
  border-top-right-radius: 2.2rem;
  margin-top: 0;
  padding: 1rem .25rem;
}

@media (width >= 961px) {
  .form-template__reveal__container {
    padding: 3.7rem;
  }
}

.form-template__meta {
  flex-flow: column wrap;
  justify-content: space-between;
  gap: 1rem;
  padding-bottom: 3rem;
  display: flex;
}

@media (width >= 769px) {
  .form-template__meta {
    flex-direction: row;
    align-items: center;
  }
}

.form-template__meta p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding: 0;
}

.form-template__meta p:last-of-type {
  align-items: center;
  gap: .25rem;
  display: flex;
}

@media (width >= 561px) {
  .form-template__meta p:last-of-type {
    gap: 0;
  }
}

.form-template__meta p strong {
  display: block;
}

@media (width >= 561px) {
  .form-template__meta p strong {
    display: inline-block;
  }
}

.form-template__meta p span {
  display: none;
}

@media (width >= 561px) {
  .form-template__meta p span {
    display: inline-block;
  }
}

.form-template__meta a {
  text-decoration-line: none;
  display: block;
}

.form-template__meta a:hover {
  text-decoration-line: underline;
}

@media (width >= 561px) {
  .form-template__meta a {
    margin-left: .25rem;
    margin-right: .25rem;
    display: inline-block;
  }
}

.form-template__meta__social a {
  display: flex;
}

.form-template__meta__social a:hover {
  text-decoration-line: none;
}

.form-block {
  background-image: linear-gradient(#262c38 18.09%, #9ec9d9 100%);
  padding-top: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .form-block {
    padding-top: 8.35rem;
  }
}

.scale-select {
  display: flex;
}

.scale-select label {
  cursor: pointer;
  --tw-border-opacity: 1;
  border-width: 1px 1px 1px 0;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  overflow: hidden;
}

.scale-select label input {
  z-index: -1;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.scale-select label input:checked + span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #e86c4dcc;
}

.scale-select label span {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.scale-select label span:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  background-color: #e86c4dbf;
}

.scale-select > div:first-child label {
  border-left-width: 1px;
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.scale-select > div:last-child label {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.arrow-link {
  align-items: center;
  gap: .25rem;
  text-decoration-line: none;
  display: flex;
}

.arrow-link:hover, .arrow-link:focus {
  text-decoration-line: underline;
}

.social-link {
  border-width: 1px;
  border-color: #ffffffbf;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.85rem;
  height: 1.85rem;
  line-height: 1.6;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.social-link:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.site-header {
  z-index: 50;
  box-sizing: border-box;
  width: 100%;
  padding-top: .9rem;
  padding-bottom: 2.55rem;
  transition-property: padding-top;
  transition-duration: .75s;
  transition-timing-function: cubic-bezier(.23, 1, .32, 1);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 961px) {
  .site-header {
    padding-top: 2.55rem;
    padding-bottom: 4.9rem;
  }
}

.site-header .site-header__container {
  border-radius: 1.7rem;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: .75rem;
  padding-bottom: .75rem;
  transition-property: width, max-width, padding, backgroud, box-shadow;
  transition-duration: .75s;
  transition-timing-function: cubic-bezier(.23, 1, .32, 1);
}

@media not all and (width >= 769px) {
  .site-header--campaign.wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .site-header--campaign .site-header__container {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .site-header--campaign .site-header__nav {
    display: block;
  }
}

.site-header--campaign .site-header__nav ul li {
  padding-bottom: 0;
}

.site-header--campaign .site-header__nav a {
  padding-top: 1.12rem;
  padding-bottom: 1rem;
}

.site-header--campaign .site-header__nav a:after {
  background-color: #ffffffb2;
  height: 0;
}

@media not all and (width >= 769px) {
  .site-header--campaign .site-header__nav .btn--orange {
    padding: .75rem .5rem;
  }

  .site-header--campaign .site-header__nav .btn--orange:hover {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .site-header--campaign .site-header__nav .btn--orange img {
    display: none;
  }

  .site-header--campaign .site-header__logo img {
    max-width: 7rem;
  }

  .site-header--campaign .site-header__logo img.icon {
    display: block;
  }

  .site-header--campaign .site-header__logo img.full {
    display: none;
  }
}

.site-header--campaign .site-header__nav-opener {
  display: none;
}

@media (width >= 961px) {
  .site-header--white .site-header__nav {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

.site-header--fixed {
  padding-top: 1.65rem;
  padding-bottom: 0;
}

.site-header--fixed .site-header__nav-opener .closed--white {
  display: none;
}

.site-header--fixed .site-header__nav-opener .closed--dark {
  display: block;
}

.site-header--fixed .site-header__container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 .5rem var(--tw-shadow-color);
  --tw-shadow-colored: 0 0 .5rem var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow-color: #00000059;
  --tw-shadow: var(--tw-shadow-colored);
  gap: 0;
  width: calc(100% - 2rem);
  max-width: 41.6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.site-header--fixed.menu-open .site-header__container {
  border-radius: 1.7rem;
}

.site-header--fixed.menu-open .site-header__nav-opener .open {
  display: block;
}

.site-header--fixed.menu-open .site-header__nav-opener .closed {
  display: none;
}

.site-header--fixed .site-header__logo .icon {
  display: block;
}

.site-header--fixed .site-header__logo .full {
  display: none;
}

@media (width >= 961px) {
  .site-header--fixed .site-header__nav {
    --tw-text-opacity: 1;
    color: rgb(38 44 56 / var(--tw-text-opacity));
    padding-right: 1rem;
  }
}

.site-header--fixed .site-header__nav ul {
  gap: 1.2rem;
}

.site-header--fixed .site-header__nav.open {
  flex: 100%;
  width: 100%;
  padding-top: .75rem;
  padding-bottom: .25rem;
  display: block;
}

.site-header--fixed .site-header__nav.open ul {
  text-align: center;
}

.site-header--fixed .site-header__nav.open li {
  padding-bottom: .8rem;
}

.site-header--fixed .site-header__nav.open li:last-child {
  padding-bottom: 0;
}

.site-header__container {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (width >= 961px) {
  .site-header__container {
    flex-wrap: nowrap;
  }
}

.site-header__logo a {
  display: block;
}

.site-header__logo img {
  width: 100%;
  max-width: 11.85rem;
  height: auto;
}

.site-header__logo img.icon {
  max-width: 1.8rem;
  display: none;
}

@media (width >= 961px) {
  .site-header__logo img.icon {
    max-width: 2.7rem;
  }
}

.site-header__nav {
  display: none;
}

@media (width >= 961px) {
  .site-header__nav {
    display: block;
  }
}

.site-header__nav ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.site-header__nav ul li {
  list-style: none;
}

.site-header__nav ul {
  gap: 2.15rem;
  margin: 0;
  padding: 0;
  display: block;
}

@media (width >= 961px) {
  .site-header__nav ul {
    display: flex;
  }
}

.site-header__nav a {
  padding-bottom: .125rem;
  font-size: .9rem;
  font-weight: 600;
  line-height: 1.3;
  text-decoration-line: none;
  position: relative;
}

.site-header__nav a:after {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
  --tw-content: "";
  content: var(--tw-content);
  background-repeat: repeat;
  background-attachment: scroll;
  width: 0;
  height: 2px;
  transition-property: width, left;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
}

.site-header__nav a:hover:after, .site-header__nav a:focus:after, .site-header__nav a.active:after {
  width: 100%;
  left: 0;
}

.site-header__nav-opener {
  cursor: pointer;
  display: block;
}

@media (width >= 961px) {
  .site-header__nav-opener {
    display: none;
  }
}

.site-header__nav-opener .open {
  display: none;
}

.site-header__nav-opener .closed--dark {
  display: block;
}

.site-header__nav-opener .closed--white {
  display: none;
}

.site-header__nav-opener--white .closed--white {
  display: block;
}

.site-header__nav-opener--white .closed--dark {
  display: none;
}

.site-footer {
  z-index: 50;
  --tw-bg-opacity: 1;
  background-color: rgb(14 18 25 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  display: block;
  position: relative;
}

.site-footer--campaign {
  --tw-bg-opacity: 1;
  background-color: rgb(19 25 35 / var(--tw-bg-opacity));
}

@media not all and (width >= 769px) {
  .site-footer--campaign .site-footer__lower {
    flex-wrap: wrap;
  }

  .site-footer--campaign .site-footer__lower .site-footer__logo, .site-footer--campaign .site-footer__lower .site-footer__links {
    flex: 0 0 100%;
  }
}

.site-footer__container {
  padding-top: 4.25rem;
  display: block;
}

@media (width >= 769px) {
  .site-footer__container {
    padding-top: 6.75rem;
  }
}

.site-footer__upper {
  border-bottom-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .5;
  text-align: center;
  padding-bottom: 3.4rem;
  display: block;
}

.site-footer__upper .h1 {
  text-align: center;
  padding-bottom: .5rem;
  font-weight: 700;
}

.site-footer__upper .h1 span {
  white-space: nowrap;
}

.site-footer__upper p {
  text-align: center;
  padding-bottom: .5rem;
  font-size: 1.4rem;
}

.site-footer__upper .btn {
  margin: 2.3rem auto 4.7rem;
}

.site-footer__links {
  gap: 1.5rem;
  display: block;
}

@media (width >= 769px) {
  .site-footer__links {
    gap: 5rem;
    display: flex;
  }
}

.site-footer__links .col {
  padding-bottom: 2.65rem;
}

.site-footer__links .col:last-of-type {
  padding-bottom: 0;
}

@media (width >= 561px) {
  .site-footer__links .col:first-of-type {
    min-width: 9.45rem;
  }
}

@media (width >= 769px) {
  .site-footer__links .col {
    padding-bottom: 0;
  }
}

.site-footer__links .links a {
  justify-content: space-between;
  align-items: center;
  gap: .25rem;
  display: flex;
}

.site-footer__links .links a img {
  width: 1rem;
  height: auto;
}

.site-footer__logo {
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 4.7rem;
  display: flex;
}

@media (width >= 769px) {
  .site-footer__logo {
    max-width: none;
    display: block;
  }
}

.site-footer__logo img {
  min-width: 2rem;
  height: auto;
}

@media (width >= 769px) {
  .site-footer__logo img {
    width: 7.7rem;
  }
}

.site-footer__lower {
  justify-content: space-between;
  align-items: stretch;
  gap: 5rem;
  padding-top: 3.2rem;
  padding-bottom: 2.25rem;
  display: flex;
}

.site-footer__lower p {
  padding-bottom: .65rem;
}

.site-footer__lower a {
  text-decoration-line: none;
  display: block;
}

.site-footer__lower a:hover {
  text-decoration-line: underline;
}

.site-footer__lower ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.site-footer__lower ul li {
  list-style: none;
}

.site-footer__lower ul {
  padding-bottom: .85rem;
}

.site-footer__lower ul:last-of-type {
  padding-bottom: 0;
}

.site-footer__lower ul li {
  padding-bottom: .85rem;
}

.site-footer__lower ul li:last-of-type {
  padding-bottom: 0;
}

.site-footer__lower .social {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.site-footer__lower .social li {
  list-style: none;
}

.site-footer__lower .social {
  gap: .35rem;
  padding: 0;
  display: flex;
}

.site-footer__lower .social li {
  padding-bottom: 0;
}

.site-footer__lower .social a {
  display: flex;
}

.site-footer__lower .social a:hover {
  text-decoration-line: none;
}

.site-footer__newsletter {
  border-top-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .5;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@media (width >= 769px) {
  .site-footer__newsletter {
    border-top-width: 0;
    padding-top: 0;
    padding-bottom: 2.6rem;
  }
}

.site-footer__newsletter form {
  width: 100%;
  max-width: 27.3rem;
}

.site-footer__newsletter fieldset {
  margin-bottom: 0;
}

.site-footer__newsletter fieldset input[type="text"], .site-footer__newsletter fieldset input[type="email"] {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(14 18 25 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .8rem 1.25rem;
  font-size: .7rem;
}

.site-footer__newsletter fieldset input[type="text"]::placeholder, .site-footer__newsletter fieldset input[type="email"]::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.site-footer__newsletter p {
  padding-bottom: 1rem;
  font-size: .9rem;
  font-weight: 700;
}

.site-footer__newsletter label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .6rem;
  font-weight: 400;
}

.site-footer__newsletter .submit-row {
  justify-content: space-between;
  align-items: center;
}

@media (width >= 561px) {
  .site-footer__newsletter .submit-row {
    display: flex;
  }
}

.site-footer__newsletter .submit-row > div {
  flex: auto;
}

.site-footer__newsletter .submit-row > div .field {
  padding-bottom: 0;
}

.site-footer__newsletter .submit-row .btn {
  border-radius: 9999px;
  min-width: 8rem;
  margin-top: 1rem;
}

.site-footer__newsletter .submit-row .btn:hover, .site-footer__newsletter .submit-row .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(14 18 25 / var(--tw-text-opacity));
}

@media (width >= 561px) {
  .site-footer__newsletter .submit-row .btn {
    margin-top: 0;
  }
}

.site-footer__newsletter .field--has-submit {
  padding-bottom: .8rem;
}

.site-footer__newsletter .field--has-submit input {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(14 18 25 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .5rem;
  padding: .8rem 5rem .8rem 1.25rem;
  font-size: .7rem;
}

.site-footer__newsletter .field--has-submit input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.site-footer__newsletter .field--has-submit .field__field {
  position: relative;
}

.site-footer__newsletter .field--has-submit .field__field button {
  text-align: left;
  width: 4.5rem;
  height: calc(100% + 2px);
  font-size: .9rem;
  font-weight: 700;
  position: absolute;
  top: -2px;
  right: 0;
}

.site-footer__newsletter .field--has-submit .field__field button:hover {
  text-decoration-line: underline;
}

.site-footer__newsletter img {
  width: 3rem;
}

.site-footer__legal {
  border-top-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: .5;
  color: #ffffffbf;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
}

@media (width >= 769px) {
  .site-footer__legal {
    padding-top: 1.7rem;
    padding-bottom: 1.7rem;
  }
}

@media (width >= 1440px) {
  .site-footer__legal {
    flex-wrap: nowrap;
  }
}

.site-footer__legal nav ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.site-footer__legal nav ul li {
  list-style: none;
}

.site-footer__legal nav ul {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

@media (width >= 769px) {
  .site-footer__legal nav ul {
    flex-wrap: nowrap;
  }
}

@media (width >= 1261px) {
  .site-footer__legal nav ul {
    gap: 2rem;
  }
}

.site-footer__legal nav li {
  padding: 0;
  font-size: .9rem;
}

@media screen and (width <= 560px) {
  .site-footer__legal nav li {
    flex: calc(50% - .5rem);
  }
}

.site-footer__legal nav li a:hover {
  text-decoration-line: underline;
}

@media (width >= 769px) {
  .site-footer__legal nav li a {
    text-decoration-line: none;
  }
}

.site-footer__legal p {
  font-size: .9rem;
}

@media (width >= 1440px) {
  .site-footer__legal p {
    text-align: right;
    margin-left: auto;
  }
}

.hero {
  width: 100%;
  margin-bottom: 4.25rem;
  display: block;
  position: relative;
}

.hero + .breadcrumb {
  padding-top: 4.25rem;
}

.hero--featured {
  flex-direction: column;
  min-height: 40rem;
  margin-bottom: 0;
  display: flex;
  overflow: hidden;
}

.hero--featured--full {
  min-height: 100vh;
}

@media (height <= 560px) {
  .hero--featured--full {
    min-height: 28rem;
  }
}

.hero--featured--full .hero__container {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hero--featured .wrapper, .hero--featured .hero__container {
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  display: flex;
}

.hero--featured .wrapper {
  z-index: 3;
  box-sizing: border-box;
}

.hero--featured h1 {
  padding-bottom: 1rem;
}

.hero--featured:not(.no-spacing) + section {
  padding-top: 2.75rem;
}

@media (width >= 769px) {
  .hero--featured:not(.no-spacing) + section {
    padding-top: 5rem;
  }
}

.hero--featured .breadcrumb {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
  text-shadow: 0 0 .375rem var(--tw-shadow-color);
}

.hero--featured .hero__heading {
  --tw-shadow-color: #0009;
  --tw-shadow: var(--tw-shadow-colored);
  text-shadow: 0 0 .375rem var(--tw-shadow-color);
  padding-bottom: 0;
}

.hero__overlay, .hero__image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__overlay {
  z-index: 2;
  background-color: #0000004d;
}

.hero__gradient {
  z-index: 3;
  aspect-ratio: 1440 / 180;
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #262c38cc var(--tw-gradient-from-position);
  --tw-gradient-to: #262c3800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #262c3800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #262c3800 var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-via-position: 90%;
  width: 100%;
  max-height: 9rem;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__carousel {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero__carousel .slick-list, .hero__carousel .slick-track, .hero__carousel .slick-slide, .hero__carousel .slick-slide > div {
  height: 100%;
}

.hero__image {
  z-index: 1;
  background-position: center;
  background-size: cover;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.hero__image.fade-out {
  opacity: 0;
}

.hero__image picture, .hero__image img {
  object-fit: cover;
  object-position: 75% center;
  width: 100%;
  height: 100%;
}

@media (width >= 769px) {
  .hero__image picture, .hero__image img {
    object-position: center;
  }
}

.hero__image .hero__image__watermark {
  width: 10%;
  min-width: 7rem;
  height: auto;
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
}

picture.hero__image, picture.hero__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero__container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  max-width: 27.9rem;
  display: flex;
  position: relative;
}

.hero__container--centered {
  justify-content: center;
}

.hero__container--logo {
  align-items: center;
  margin: 0 auto;
}

.hero--casestudy {
  margin-bottom: 0;
}

@media (width >= 1440px) {
  .hero--casestudy {
    aspect-ratio: 1440 / 650;
  }
}

.hero--casestudy .wrapper {
  z-index: 2;
}

.hero--casestudy .hero__container {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 7rem;
  display: flex;
}

@media (width >= 769px) {
  .hero--casestudy .hero__container {
    padding-bottom: 10rem;
  }
}

.hero--casestudy .hero__container > * {
  flex: none;
}

.hero--casestudy .hero__container .breadcrumb {
  padding-bottom: 0;
}

.hero--casestudy .hero__container .hero__logo {
  width: auto;
  margin-top: 3.2rem;
  margin-bottom: 5.4rem;
  padding-bottom: 0;
}

.hero--casestudy .hero__container .hero__logo img {
  max-height: 7rem;
}

.hero--casestudy .hero__container .text-block--featured {
  padding-bottom: 0;
}

.hero--casestudy .hero__container .text-block--featured .text-block__text {
  border-width: 0;
  max-width: 28rem;
  padding-bottom: 0;
}

.hero--casestudy .hero__container .text-block--featured .text-block__text .text-block__tags {
  color: #fffc;
  padding-top: .25rem;
}

.hero--campaign {
  width: auto;
  margin-bottom: 0;
}

@media (width >= 1440px) {
  .hero--campaign {
    aspect-ratio: 1440 / 650;
  }
}

.hero--campaign:before {
  z-index: 2;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0b2245 var(--tw-gradient-from-position);
  --tw-gradient-to: #0b224500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  mix-blend-mode: multiply;
  --tw-content: "";
  content: var(--tw-content);
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
  width: 60%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.hero--campaign .wrapper {
  z-index: 2;
}

.hero--campaign .hero__image {
  border-radius: .5rem;
}

.hero--campaign .hero__container {
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
  max-width: 46.85rem;
  padding-bottom: 7rem;
  display: flex;
}

@media (width >= 769px) {
  .hero--campaign .hero__container {
    padding-bottom: 10rem;
  }
}

.hero--campaign .hero__container > * {
  flex: none;
}

.hero--campaign .hero__container .breadcrumb {
  padding-bottom: 0;
}

.hero--campaign .hero__container .hero__logo {
  width: auto;
  margin-top: 3.2rem;
  margin-bottom: 5.4rem;
  padding-bottom: 0;
}

.hero--campaign .hero__container .hero__logo img {
  max-height: 7rem;
}

.hero--campaign .hero__container .text-block--featured {
  padding-bottom: 0;
}

.hero--campaign .hero__container .text-block--featured .text-block__text {
  border-width: 0;
  padding-bottom: 0;
}

@media (width >= 961px) {
  .hero--campaign .hero__container .text-block--featured .text-block__text h1 {
    padding-bottom: 2rem;
  }

  .hero--campaign .hero__container .text-block--featured .text-block__text p {
    padding-bottom: 2.5rem;
  }
}

.hero--campaign .hero__container .text-block--featured .text-block__text .text-block__tags {
  color: #fffc;
  padding-top: .25rem;
}

.hero--campaign .hero__container .text-block--featured .text-block__text .btn--white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.hero--campaign .hero__container .text-block--featured .text-block__text .btn--white:hover {
  background-color: #ffffffb2;
  border-color: #ffffffb2;
}

@media (width >= 961px) {
  .hero--campaign .hero__container .text-block--featured .text-block__text .btn--white {
    max-width: 13.9rem;
  }
}

.hero--campaign.hero--campaign--python {
  margin: 1rem 1rem 2.5rem;
}

.hero--campaign.hero--campaign--python .hero__container {
  max-width: 33rem;
  padding-bottom: 4rem;
}

.hero--campaign.hero--campaign--python h1 {
  font-size: 1.5rem;
  font-weight: 800;
}

@media (width >= 401px) {
  .hero--campaign.hero--campaign--python h1 {
    font-size: 2rem;
  }
}

@media (width >= 1025px) {
  .hero--campaign.hero--campaign--python h1 {
    font-size: 5.55rem;
  }
}

.hero--spacious {
  margin-bottom: 4.25rem;
}

@media (width >= 769px) {
  .hero--spacious {
    margin-bottom: 7.3rem;
  }
}

.hero--spacious h1 {
  padding-bottom: 1.6rem;
}

.hero--spacious .hero__container .breadcrumb {
  padding-bottom: 4.25rem;
}

@media (width >= 769px) {
  .hero--spacious .hero__container .breadcrumb {
    padding-bottom: 7.5rem;
  }
}

.hero--news .hero__container {
  padding-bottom: 6rem !important;
}

.hero--dark-text .hero__container, .hero--dark-text .text-block__tags {
  --tw-text-opacity: 1 !important;
  color: rgb(38 44 56 / var(--tw-text-opacity)) !important;
}

.hero--black-text .hero__container, .hero--black-text .text-block__tags {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

.our-work {
  width: 100%;
  padding-bottom: 4.5rem;
  display: block;
  position: relative;
}

.our-work .gradient {
  z-index: 10;
  position: absolute;
  bottom: 0;
  transform: scaleX(-1);
}

.our-work .wrapper {
  z-index: 20;
}

.our-work--dark {
  background-image: url("1-grad.93d471b4.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.our-work--dark .our-work__intro, .our-work--dark .our-work__card {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.our-work__intro {
  max-width: 38.1rem;
  margin: 0 auto 0 0;
  padding-bottom: 3.4rem;
}

.our-work__intro.wide {
  max-width: 32.25rem;
}

.our-work__intro.widest {
  max-width: 40.8rem;
}

.our-work__container {
  flex-wrap: wrap;
  gap: 2rem;
  display: flex;
}

@media (width >= 961px) {
  .our-work__container {
    gap: 3.5rem;
  }
}

.our-work__card {
  flex: calc(50% - 1.75rem);
  text-decoration-line: none;
}

@media screen and (width <= 560px) {
  .our-work__card {
    flex: 100%;
  }
}

.our-work__card img {
  width: 100%;
}

.our-work__card:hover .our-work__card__image img, .our-work__card:focus .our-work__card__image img {
  transform: scale(1.05);
}

.our-work__card--full {
  flex: 100%;
  width: 100%;
}

.our-work__card--has-mobile-img img.desktop {
  display: none;
}

@media (width >= 561px) {
  .our-work__card--has-mobile-img img.desktop {
    display: block;
  }
}

.our-work__card--has-mobile-img img.mobile {
  display: block;
}

@media (width >= 561px) {
  .our-work__card--has-mobile-img img.mobile {
    display: none;
  }
}

.our-work__card__image {
  overflow: hidden;
}

.our-work__card__image video {
  aspect-ratio: 16 / 9;
  width: 100%;
}

.our-work__card__image .desktop-video {
  display: none;
}

@media (width >= 769px) {
  .our-work__card__image .desktop-video {
    display: block;
  }
}

.our-work__card__image .mobile-video {
  aspect-ratio: auto;
  display: block;
}

@media (width >= 769px) {
  .our-work__card__image .mobile-video {
    display: none;
  }
}

.our-work__card__image img {
  transition-property: transform;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.our-work__card__text {
  justify-content: space-between;
  gap: 1rem;
  padding-top: .6rem;
  display: flex;
}

@media (width >= 769px) {
  .our-work__card__text {
    padding-top: 1rem;
  }
}

.our-work__card__text p {
  padding: 0;
  font-size: .7rem;
}

@media (width >= 769px) {
  .our-work__card__text p {
    font-size: 20px;
  }
}

@media (width >= 961px) {
  .our-work__card__text p {
    font-size: 1.2rem;
  }
}

.our-work__card__text .small {
  flex-shrink: 0;
  align-items: center;
  font-size: .55rem;
  display: flex;
}

@media (width >= 769px) {
  .our-work__card__text .small {
    font-size: .75rem;
  }
}

@media (width >= 961px) {
  .our-work__card__text .small {
    font-size: .9rem;
  }
}

.our-work__card__text span {
  padding-right: .1rem;
  display: block;
}

.our-work__card__text img {
  width: .7rem;
  height: auto;
  margin-top: .1rem;
}

@media (width >= 769px) {
  .our-work__card__text img {
    width: .9rem;
  }
}

@media (width >= 961px) {
  .our-work__card__text img {
    width: 1.2rem;
  }
}

.our-work__cta {
  text-align: center;
  padding-top: 4rem;
}

@media (width >= 961px) {
  .our-work__cta {
    padding-top: 4.5rem;
  }
}

.logo-wall {
  --tw-bg-opacity: 1;
  background-color: rgb(255 142 106 / var(--tw-bg-opacity));
  width: 100%;
  display: block;
  overflow: hidden;
}

.logo-wall h3 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 3rem;
  padding-bottom: 0;
  font-size: 1.6rem;
}

.logo-wall--secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.logo-wall.dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.logo-wall__container {
  padding-bottom: 1rem;
}

.logo-wall__container:first-child {
  padding-top: 1rem;
}

.logo-wall__container .logo-wall__logos {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-rows: 0;
  align-content: center;
  place-items: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
  display: grid;
}

@media (width >= 769px) {
  .logo-wall__container .logo-wall__logos {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (width >= 961px) {
  .logo-wall__container .logo-wall__logos {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.logo-wall__container .logo-wall__logo {
  justify-content: center;
  align-items: center;
  height: 4rem;
  padding-left: .75rem;
  padding-right: .75rem;
  display: flex;
}

@media (width >= 769px) {
  .logo-wall__container .logo-wall__logo {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.logo-wall__container .logo-wall__logo img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.logo-wall__text {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 4.25rem;
}

@media (width >= 769px) {
  .logo-wall__text {
    padding-top: 6rem;
  }
}

.logo-wall--static {
  padding: 0;
}

.logo-wall--static .logo-wall__container {
  padding-top: 3.3rem;
  padding-bottom: 3.3rem;
}

@media (width >= 769px) {
  .logo-wall--static .logo-wall__container {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

.logo-wall--static .logo-wall__container .logo-wall__logos {
  flex-wrap: wrap;
  grid-auto-rows: auto;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 0;
  display: flex;
}

@media (width >= 769px) {
  .logo-wall--static .logo-wall__container .logo-wall__logos {
    flex-wrap: nowrap;
  }
}

@media (width >= 961px) {
  .logo-wall--static .logo-wall__container .logo-wall__logos {
    grid-auto-rows: 1px;
  }
}

@media not all and (width >= 769px) {
  .logo-wall--static .logo-wall__container .logo-wall__logos .logo-wall__logo {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.logo-wall--static .logo-wall__container .logo-wall__logos img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.logo-wall--campaign .logo-wall__container {
  padding-top: 0 !important;
}

.logo-wall--python h3 {
  padding-top: 0;
}

.logo-wall--dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.logo-wall--dark h3 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}

.logo-wall--dark .logo-wall__container {
  padding-top: 0;
}

@media (width >= 961px) {
  .logo-wall--dark .logo-wall__container {
    padding-top: 2.5rem;
  }
}

.logo-wall--dark .logo-wall__container .logo-wall__logos {
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: block;
}

@media (width >= 561px) {
  .logo-wall--dark .logo-wall__container .logo-wall__logos {
    display: flex;
  }
}

@media (width >= 961px) {
  .logo-wall--dark .logo-wall__container .logo-wall__logos {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    padding-top: 5rem;
    padding-bottom: 5rem;
    display: grid;
  }
}

.logo-wall--dark .logo-wall__container .logo-wall__logos .logo-wall__logo {
  margin-bottom: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 561px) {
  .logo-wall--dark .logo-wall__container .logo-wall__logos .logo-wall__logo {
    margin-bottom: 0;
  }
}

@media (width >= 961px) {
  .logo-wall--dark .logo-wall__container .logo-wall__logos .logo-wall__logo {
    height: 10.85rem;
  }
}

.logo-wall--accreditations {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.logo-wall--accreditations h3 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 3rem;
  padding-bottom: 1rem;
  font-size: 2rem;
}

.logo-wall--accreditations .logo-wall__container {
  padding: 0;
}

.logo-wall--accreditations .logo-wall__container .logo-wall__logos {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.logo-wall--accreditations .logo-wall__container .logo-wall__logos .logo-wall__logo {
  width: calc(50% - .75rem);
  height: 6rem;
  margin-bottom: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media (width >= 401px) {
  .logo-wall--accreditations .logo-wall__container .logo-wall__logos .logo-wall__logo {
    width: auto;
  }
}

@media (width >= 561px) {
  .logo-wall--accreditations .logo-wall__container .logo-wall__logos .logo-wall__logo {
    margin-bottom: 0;
  }
}

.logo-wall--accreditations--colour {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media (width >= 769px) {
  .logo-wall--accreditations--colour .wrapper > div {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
}

.logo-wall--accreditations--colour h3 {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  padding-top: 0;
  font-size: 20px;
  font-weight: 800;
}

@media not all and (width >= 769px) {
  .logo-wall--accreditations--colour h3 {
    padding-bottom: 1rem;
  }
}

@media (width >= 769px) {
  .logo-wall--accreditations--colour h3 {
    text-align: center;
    font-size: 1.2rem;
  }
}

@media (width >= 961px) {
  .logo-wall--accreditations--colour h3 {
    font-size: 1.4rem;
  }
}

.logo-wall--accreditations--colour .logo-wall__container {
  padding: 0;
}

.logo-wall--accreditations--colour .logo-wall__container .logo-wall__logos .logo-wall__logo {
  width: calc(50% - .75rem);
  height: 6rem;
  margin-bottom: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
}

@media not all and (width >= 769px) {
  .logo-wall--accreditations--colour .logo-wall__container .logo-wall__logos .logo-wall__logo {
    flex: 0 0 calc(25% - 1.5rem);
  }
}

@media (width >= 401px) {
  .logo-wall--accreditations--colour .logo-wall__container .logo-wall__logos .logo-wall__logo {
    width: auto;
  }
}

@media (width >= 561px) {
  .logo-wall--accreditations--colour .logo-wall__container .logo-wall__logos .logo-wall__logo {
    margin-bottom: 0;
  }
}

@media not all and (width >= 769px) {
  .logo-wall--accreditations--colour .logo-wall__container .logo-wall__logos .logo-wall__logo img {
    height: 3.5rem;
  }
}

.solutions {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  margin-bottom: 6rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

@media (width >= 769px) {
  .solutions {
    padding-top: 6rem;
    padding-bottom: 9.75rem;
  }
}

.solutions__intro {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-bottom: 4rem;
}

@media (width >= 769px) {
  .solutions__intro {
    padding-bottom: 5rem;
  }
}

.solutions__intro p {
  color: #ffffffb2;
  max-width: 26.85rem;
}

.solutions__container {
  justify-content: center;
  gap: 4rem;
}

@media (width >= 769px) {
  .solutions__container {
    display: flex;
  }
}

.solutions__card {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex: 33.333%;
  padding-bottom: 4rem;
  text-decoration-line: none;
  display: block;
}

@media (width >= 769px) {
  .solutions__card {
    text-align: left;
    padding-bottom: 0;
  }
}

.solutions__card:hover h3, .solutions__card:focus h3 {
  --tw-text-opacity: 1;
  color: rgb(255 142 106 / var(--tw-text-opacity));
}

.solutions__card:hover h3 svg g, .solutions__card:focus h3 svg g {
  stroke: #ff8e6a;
}

.solutions__card__image {
  margin-bottom: 1.5rem;
}

.solutions__card__image img {
  height: 2.75rem;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 769px) {
  .solutions__card__image img {
    margin-left: 0;
    margin-right: 0;
  }
}

.solutions__card__text h3 {
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  display: flex;
}

@media (width >= 769px) {
  .solutions__card__text h3 {
    justify-content: flex-start;
  }
}

.solutions__card__text h3, .solutions__card__text h3 svg g {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.solutions__card__text svg {
  flex: none;
  width: 1.6rem;
  margin-top: 3px;
  margin-left: 1.3rem;
}

.solutions__card__text p {
  padding-bottom: .6rem;
  font-size: .9rem;
}

.solutions__card__text p:last-child {
  padding-bottom: 0;
}

.solutions__cta {
  text-align: center;
  padding-top: 1.5rem;
}

@media (width >= 769px) {
  .solutions__cta {
    padding-top: 6.667rem;
  }
}

.solution-cards {
  padding-bottom: 2rem;
  display: block;
}

@media (width >= 769px) {
  .solution-cards {
    padding-bottom: 3rem;
  }
}

.solution-cards__intro {
  max-width: 38.1rem;
  margin: 0 auto;
  padding-bottom: 3.4rem;
}

.solution-cards__card {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.solution-cards__card .solution-cards__grid {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
  display: grid;
}

@media (width >= 961px) {
  .solution-cards__card .solution-cards__grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.solution-cards__card .left {
  place-self: start flex-start;
}

.solution-cards__card .left h2 {
  margin-top: 1rem;
}

.solution-cards__card .left img {
  aspect-ratio: 1;
  height: 165px;
  margin: 0 auto;
}

.solution-cards__card .right {
  grid-column: span 2 / span 2;
}

.solution-cards__card .our-work {
  width: 100%;
  padding-bottom: 0;
}

.solution-cards__card .reveal-list {
  margin-bottom: 4rem;
}

.solution-cards__card .reveal-list .item {
  cursor: pointer;
  border-color: #262c3854;
  border-bottom-width: 1px;
  padding-left: 3rem;
  position: relative;
}

.solution-cards__card .reveal-list .item .arrow-link {
  justify-content: flex-end;
}

.solution-cards__card .reveal-list .item:before {
  cursor: pointer;
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("plus.e2fa6a3c.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1.3rem;
  left: 0;
}

.solution-cards__card .reveal-list .item h3 {
  padding-top: 1rem;
  display: inline-block;
}

.solution-cards__card .reveal-list .item .soon, .solution-cards__card .reveal-list .item .new {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  background-color: #ffffff70;
  border-radius: 9999px;
  margin-left: .5rem;
  padding: .25rem .75rem;
  font-size: .9rem;
  display: inline-block;
  position: absolute;
  top: 1.2rem;
}

.solution-cards__card .reveal-list .item .new {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.solution-cards__card .reveal-list .item p, .solution-cards__card .reveal-list .item a {
  height: 0;
  display: none;
}

.solution-cards__card .reveal-list .item.open {
  padding-bottom: 2rem;
}

.solution-cards__card .reveal-list .item.open:before {
  background-image: url("minus.21045ea4.svg");
}

.solution-cards__card .reveal-list .item.open p {
  height: auto;
  display: block;
}

.solution-cards__card .reveal-list .item.open a {
  height: auto;
}

.solution-cards__card .reveal-list .item.open a:not(.arrow-link) {
  display: inline-block;
}

.solution-cards__card .reveal-list .item.open a.arrow-link {
  display: flex;
}

.solution-cards__card .reveal-list .btn {
  margin-top: 1.5rem;
  font-size: 1.2rem;
}

.solution-cards__card .reveal-list--white .item {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.solution-cards__card .reveal-list--white .item:before {
  background-image: url("plus--white.2a605691.svg");
}

.solution-cards__card .reveal-list--white .item.open:before {
  background-image: url("minus--white.bb7ddf2a.svg");
}

.solution-cards__card--primary {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.solution-cards__card--teal {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
}

.solution-cards__card--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(164 220 232 / var(--tw-bg-opacity));
}

.solution-cards__card__more {
  cursor: pointer;
  margin-top: auto;
}

.solution-cards__card__more .reveal {
  display: none;
}

.solution-cards__card__more p {
  padding-bottom: .15rem;
  font-size: .7rem;
  font-weight: 700;
  line-height: 1.2;
}

@media (width >= 769px) {
  .solution-cards__card__more p {
    font-size: .9rem;
  }
}

.solution-cards__card__more img {
  width: 1.55rem;
  height: auto;
}

.solution-cards__card__reveal {
  margin-top: auto;
  display: none;
}

.news {
  width: 100%;
  padding-bottom: 3.15rem;
  display: block;
}

.news--homepage {
  background-image: url("2-grad.a11dfec0.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: -1px;
}

.news--homepage .news__intro, .news--homepage .news__intro p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.news--blue {
  --tw-bg-opacity: 1;
  background-color: rgb(164 220 232 / var(--tw-bg-opacity));
  padding-top: 5rem;
  padding-bottom: 4rem;
}

.news--blue .news__intro, .news--blue .news__intro p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.news__intro {
  max-width: 38.1rem;
  margin: 0 auto 0 0;
  padding-bottom: 3.4rem;
}

.news__articles {
  flex-wrap: wrap;
  gap: 2rem;
}

@media (width >= 561px) {
  .news__articles {
    display: flex;
  }
}

@media (width >= 769px) {
  .news__articles {
    gap: 2.6rem;
  }
}

.news__article {
  padding-bottom: 2.5rem;
  display: flex;
}

@media (width >= 561px) {
  .news__article {
    max-width: 50%;
    padding-bottom: .65rem;
  }
}

.news__article {
  flex: calc(33.3333% - 1.8rem);
}

@media screen and (width <= 768px) {
  .news__article {
    flex: calc(50% - 1.8rem);
  }
}

@media screen and (width <= 560px) {
  .news__article {
    flex: 100%;
  }
}

.news__article:nth-of-type(2n) .news__article__image img {
  aspect-ratio: 355 / 382;
}

.news__article__content {
  text-decoration-line: none;
  display: block;
}

.news__article__content:hover .news__article__image img, .news__article__content:focus .news__article__image img {
  transform: scale(1.05);
}

.news__article__date {
  flex-direction: column;
  margin-right: .3rem;
  display: flex;
}

.news__article__date .news__date {
  text-align: center;
  padding-bottom: .35rem;
  font-size: .8rem;
  line-height: .75;
}

.news__article__date .news__date span {
  font-size: .5rem;
  line-height: .8rem;
  display: block;
}

.news__article__date .news__tag {
  pointer-events: none;
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-transform: uppercase;
  writing-mode: vertical-rl;
  padding: 0;
  font-size: .625rem;
  line-height: .8rem;
  display: block;
}

.news__article__date .news__tag:after {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  content: "";
  width: 1px;
  height: 1.9rem;
  margin-top: .55rem;
  display: inline-block;
}

.news__article__image {
  width: 100%;
  margin-bottom: .8rem;
  overflow: hidden;
}

.news__article__image img {
  object-fit: cover;
  width: 100%;
  transition: transform .2s ease-out;
}

.news__article__text h3 {
  padding-bottom: .4rem;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
}

@media (width >= 769px) {
  .news__article__text h3 {
    font-size: 1.2rem;
    line-height: 1.25;
  }
}

.news__article__text p {
  font-size: .8rem;
  line-height: 1.25;
}

.news__cta {
  text-align: center;
  padding-top: 4rem;
}

@media (width >= 769px) {
  .news__cta {
    padding-top: 3.65rem;
  }
}

.news__cta .btn + .btn {
  margin-left: 1rem;
}

.accordions {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  padding-top: 4.35rem;
  padding-bottom: 5.7rem;
  display: block;
}

@media (width >= 769px) {
  .accordions {
    padding-top: 7.95rem;
    padding-bottom: 11.4rem;
  }
}

.accordions h2 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-bottom: 2.5rem;
}

@media (width >= 769px) {
  .accordions h2 {
    padding-bottom: 4.5rem;
  }
}

.accordions__accordion {
  color: #ffffffb2;
  border-style: solid;
  border-color: #ffffff73;
  border-bottom-width: 1px;
}

.accordions__accordion.open .accordions__accordion__opener path:first-child {
  opacity: 0;
}

.accordions__accordion__opener {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-top: 1.85rem;
  padding-bottom: 1.85rem;
  display: flex;
}

@media (width >= 769px) {
  .accordions__accordion__opener {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.accordions__accordion__opener svg {
  max-width: 1.6rem;
}

@media (width >= 769px) {
  .accordions__accordion__opener svg {
    max-width: none;
  }
}

.accordions__accordion__opener p {
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.333;
}

@media (width >= 769px) {
  .accordions__accordion__opener p {
    font-size: 1.6rem;
    line-height: 1.25;
  }
}

.accordions__accordion__opener p {
  transition: color .2s;
}

.accordions__accordion__opener path {
  transition: opacity .2s;
}

.accordions__accordion__opener:hover p, .accordions__accordion__opener:focus p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accordions__accordion__opener:hover path, .accordions__accordion__opener:focus path {
  opacity: 1;
}

.accordions__accordion__content {
  padding-bottom: 2.5rem;
  display: none;
}

.accordions__accordion__content ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.accordions__accordion__content ul li {
  list-style: none;
}

.accordions__accordion__content ul {
  flex-wrap: wrap;
  gap: .5rem;
  display: flex;
}

@media (width >= 401px) {
  .accordions__accordion__content ul {
    gap: 1rem;
  }
}

.accordions__accordion__content ul li {
  flex: 0 0 calc(33% - .75rem);
  padding-bottom: 1rem;
}

@media screen and (width <= 768px) {
  .accordions__accordion__content ul li {
    flex: calc(50% - .75rem);
  }
}

@media screen and (width <= 400px) {
  .accordions__accordion__content ul li {
    flex: 0 0 100%;
  }
}

.accordions__accordion__content ul a {
  text-decoration-line: none;
  position: relative;
}

.accordions__accordion__content ul a:hover, .accordions__accordion__content ul a:focus {
  text-decoration-line: underline;
}

.accordions--campaign {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

@media (width >= 769px) {
  .accordions--campaign {
    padding-bottom: 8rem;
  }
}

.accordions--campaign .accordions__container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-wrap: wrap;
  display: flex;
}

@media (width >= 961px) {
  .accordions--campaign .accordions__container {
    gap: 3.2rem;
  }

  .accordions--campaign .accordions__container .accordions__container__heading {
    flex: calc(33.3% - 1.6rem);
  }
}

.accordions--campaign .accordions__container .accordions__container__heading h2 {
  padding-bottom: 1.5rem;
}

.accordions--campaign .accordions__container .accordions__container__heading p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 961px) {
  .accordions--campaign .accordions__container .accordions__container__content {
    flex: calc(66.6% - 1.6rem);
  }
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion.open .accordions__accordion__opener path:first-child {
  opacity: 0;
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener {
  justify-content: normal;
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener svg g path {
  opacity: 1;
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener:hover p, .accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener:focus p {
  color: #ffffffb2;
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener:hover path, .accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__opener:focus path {
  opacity: .7;
}

@media (width >= 961px) {
  .accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__content {
    padding-left: 3.9rem;
  }
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__content .btn--white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  justify-content: center;
  align-items: center;
  gap: .5rem;
  display: flex;
}

.accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__content .btn--white:hover {
  background-color: #ffffffb2;
  border-color: #ffffffb2;
}

@media (width >= 961px) {
  .accordions--campaign .accordions__container .accordions__container__content .accordions__accordion .accordions__accordion__content .btn--white {
    max-width: 13.9rem;
  }
}

.breadcrumb {
  padding-bottom: 2.57rem;
  display: block;
}

.breadcrumb ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.breadcrumb ul li {
  list-style: none;
}

.breadcrumb ul {
  flex-wrap: wrap;
  align-items: center;
  gap: .8rem;
  display: flex;
}

.breadcrumb li {
  font-size: .9rem;
  line-height: 1.2;
}

.breadcrumb a {
  text-decoration-line: none;
}

.breadcrumb a:hover, .breadcrumb a:focus {
  text-decoration-line: underline;
}

.breadcrumb img {
  margin-top: .1rem;
}

.tags {
  flex-wrap: wrap;
  gap: .6rem;
  display: flex;
}

.partners {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  margin-bottom: 3rem;
  padding-top: 3.9rem;
  padding-bottom: 1.25rem;
}

@media (width >= 769px) {
  .partners {
    margin-bottom: 4.5rem;
    padding-top: 6.3rem;
    padding-bottom: 5.5rem;
  }
}

.partners.light {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.partners.light h2, .partners.light p {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.partners--white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  margin-bottom: 5rem;
  padding: 0;
}

@media (width >= 769px) {
  .partners--white {
    margin-bottom: 4.5rem;
  }
}

.partners--white .partners__container, .partners--white .partners__partner {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.partners__container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  gap: 2rem;
  padding-top: 4rem;
}

@media (width >= 769px) {
  .partners__container {
    justify-content: space-between;
    padding-top: 4.3rem;
    display: flex;
  }
}

.partners__partner {
  color: #ffffffb2;
  padding-bottom: 2.7rem;
  font-size: .9rem;
}

@media (width >= 769px) {
  .partners__partner {
    padding: 0;
  }
}

.partners__partner p {
  padding: 0;
}

.partners__partner .image {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

@media (width >= 769px) {
  .partners__partner .image {
    height: 3.2rem;
    margin-bottom: 1.65rem;
  }
}

.partners__partner .image img {
  width: auto;
}

.screen-images {
  padding-bottom: 4rem;
  display: block;
}

.screen-images__images {
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
  display: flex;
}

@media (width >= 961px) {
  .screen-images__images {
    gap: 3rem;
  }
}

@media (width >= 1025px) {
  .screen-images__images {
    gap: 4rem;
  }
}

.screen-images__images--three {
  gap: 1rem;
}

@media (width >= 769px) {
  .screen-images__images--three {
    gap: 2rem;
  }
}

.screen-images__desktop img {
  width: 100%;
  max-width: 50rem;
}

.screen-images__mobile {
  max-width: 25vw;
}

@media (width >= 1025px) {
  .screen-images__mobile {
    max-width: 14.6rem;
  }
}

.screen-images__mobile {
  flex: 0 0 14.6rem;
}

.simple-screen-images {
  background-size: cover;
  margin-bottom: 4.5rem;
  padding-top: 1.5rem;
  position: relative;
}

.simple-screen-images:before {
  z-index: 10;
  content: var(--tw-content);
  background-color: #0006;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 769px) {
  .simple-screen-images {
    min-height: 15rem;
    margin-bottom: 5rem;
  }
}

.simple-screen-images .wrapper {
  z-index: 20;
}

.simple-screen-images--no-shadow:before {
  content: var(--tw-content);
  display: none;
}

.simple-screen-images__gradient {
  z-index: 10;
  aspect-ratio: 1440 / 250;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #262c38 var(--tw-gradient-from-position);
  --tw-gradient-to: #262c3800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  width: 100%;
  max-height: 12.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

.text-block {
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .text-block {
    padding-bottom: 5rem;
  }
}

.text-block.contact-form {
  margin-top: -1.25rem;
}

.text-block + .services-block {
  margin-top: -.85rem;
}

@media (width >= 769px) {
  .text-block + .services-block {
    margin-top: -1.35rem;
  }
}

.logo-wall + .text-block {
  padding-top: 4.5rem;
}

@media (width >= 769px) {
  .logo-wall + .text-block {
    padding-top: 5rem;
  }
}

.text-block--featured {
  padding-bottom: 4.5rem;
}

@media (width >= 769px) {
  .text-block--featured {
    padding-bottom: 6.25rem;
  }
}

.text-block--featured .text-block__text {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  max-width: none;
  padding-bottom: 3.6rem;
}

.text-block--featured--grey-border {
  padding-bottom: 2.2rem;
}

.text-block--featured--grey-border .text-block__text {
  border-color: #262c3826;
  padding-bottom: 2.75rem;
}

.text-block__text {
  max-width: 49.9rem;
}

.text-block__text + .text-block__cols, .text-block__text + .text-block__carousel {
  padding-top: 2rem;
}

@media (width >= 769px) {
  .text-block__text + .text-block__cols, .text-block__text + .text-block__carousel {
    padding-top: 4rem;
  }
}

.text-block--about-us .text-block__text {
  max-width: 32.8rem;
}

.text-block--about-us .text-block__image {
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.text-block--about-us .text-block__image > img, .text-block--about-us .text-block__image div {
  flex: 0 0 calc(50% - .5rem);
}

.text-block--about-us .text-block__image > img {
  max-width: 8.25rem;
}

.text-block--about-us .text-block__image > div {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.text-block--about-us .text-block__image > div img {
  flex: 0 0 100%;
}

.text-block--about-us .text-block__image > div .text-block__image-b-corp {
  max-width: 4.6rem;
}

.text-block--about-us .text-block__image > div .text-block__image-living-wage {
  max-width: 6.25rem;
}

.text-block--about-us.text-block--about-us--python {
  background-color: #262c381a;
}

@media (width >= 1025px) {
  .text-block--about-us.text-block--about-us--python {
    padding-top: 12rem;
    padding-bottom: 10rem;
  }
}

.text-block--about-us.text-block--about-us--python .text-block__container {
  align-items: center;
}

.text-block--about-us.text-block--about-us--python .text-block__container .text-block__text h2 {
  font-size: 2.4rem;
  font-weight: 800;
}

.text-block--about-us.text-block--about-us--python .text-block__container .text-block__text h2, .text-block--about-us.text-block--about-us--python .text-block__container .text-block__text p {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.text-block--about-us.text-block--about-us--python .text-block__container .text-block__image > img {
  max-width: 100%;
}

.text-block--about-us.text-block--about-us--python + .logo-integrations {
  margin-top: -5rem;
}

@media (width >= 1025px) {
  .wrapper + .text-block--about-us--python {
    margin-top: -8rem;
  }
}

.text-block-text__bold {
  font-weight: 700;
}

@media (width >= 769px) {
  .text-block-text__bold {
    margin-top: 5rem;
  }
}

.text-block__carousel {
  position: relative;
}

@media (width >= 769px) {
  .text-block__carousel {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (width >= 1261px) {
  .text-block__carousel {
    padding-left: 0;
    padding-right: 0;
  }
}

.text-block__carousel .slick-slide {
  margin: 0 .3rem;
}

.text-block__carousel .slick-list {
  margin: 0 -.3rem;
}

.text-block__prev, .text-block__next {
  z-index: 10;
  cursor: pointer;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 50%;
}

@media (width >= 769px) {
  .text-block__prev, .text-block__next {
    background-color: #ffffffb2;
    width: auto;
    height: 1.6rem;
  }
}

.text-block__prev img, .text-block__next img {
  vertical-align: middle;
  width: auto;
  height: 1.1rem;
  display: inline;
  position: relative;
  top: 3px;
}

@media (width >= 769px) {
  .text-block__prev img, .text-block__next img {
    top: auto;
  }
}

.text-block__prev {
  left: -.75rem;
}

@media (width >= 1261px) {
  .text-block__prev {
    left: -2rem;
  }
}

.text-block__next {
  right: -.75rem;
}

@media (width >= 1261px) {
  .text-block__next {
    right: -2rem;
  }
}

.text-block__slide {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .75rem;
  padding: 1.75rem;
}

@media (width >= 769px) {
  .text-block__slide {
    padding-top: 3rem;
  }
}

.text-block__slide h3 {
  font-size: 1.2rem;
}

.text-block__slide p {
  font-size: .9rem;
}

.text-block__slide__icon {
  text-align: right;
  height: 3.5rem;
  margin-bottom: 1.75rem;
  padding-right: .5rem;
}

@media (width >= 769px) {
  .text-block__slide__icon {
    margin-bottom: 2.85rem;
  }
}

.text-block__slide__icon img {
  width: auto;
  height: auto;
  max-height: 3.5rem;
  margin-left: auto;
}

.text-block__dots {
  padding-top: 1.75rem;
}

@media (width >= 769px) {
  .text-block__dots {
    padding-top: 2.5rem;
  }
}

.text-block__dots .slick-dots li button {
  background-color: #262c3820;
}

.text-block__dots .slick-dots li button:hover, .text-block__dots .slick-dots li button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.text-block__dots .slick-dots li.slick-active button {
  background-color: #262c3854;
}

@media (width >= 769px) {
  .text-block--wide {
    padding-bottom: 6.6rem;
  }
}

.text-block--wide .text-block__text {
  max-width: 46.15rem;
}

.text-block__container {
  align-items: flex-start;
  gap: 1rem;
}

@media (width >= 961px) {
  .text-block__container {
    display: flex;
  }
}

@media (width >= 1025px) {
  .text-block__container {
    gap: 4rem;
  }
}

.text-block__image {
  text-align: center;
  padding-top: 1rem;
}

@media (width >= 769px) {
  .text-block__image {
    min-width: 14rem;
  }
}

@media (width >= 961px) {
  .text-block__image {
    padding: 0;
  }
}

.text-block__image a {
  display: inline-block;
}

.text-block__cols {
  gap: 2rem;
}

@media (width >= 769px) {
  .text-block__cols {
    display: flex;
  }
}

.text-block__cols + .text-block__text {
  padding-top: 2rem;
}

@media (width >= 769px) {
  .text-block__cols + .text-block__text {
    padding-top: 4rem;
  }
}

.text-block__col {
  padding-bottom: 1rem;
}

@media (width >= 769px) {
  .text-block__col {
    padding: 0;
  }
}

.text-block__col:last-child {
  padding: 0;
}

.text-block__tags {
  --tw-text-opacity: 1;
  color: rgb(124 124 124 / var(--tw-text-opacity));
  padding-top: 1rem;
  font-size: .9rem;
  font-weight: 500;
}

.text-block__tags span {
  padding-left: .5rem;
  padding-right: .5rem;
}

.text-block__author {
  flex-wrap: wrap;
  align-items: center;
  gap: .8rem;
  padding-top: 2.75rem;
  display: flex;
}

@media (width >= 561px) {
  .text-block__author {
    flex-wrap: nowrap;
  }
}

.text-block__author p {
  padding-bottom: 0;
  font-size: .8rem;
  line-height: 1.4;
}

.text-block__author p:last-child {
  padding-left: .5rem;
}

.text-block__author img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
}

.text-block__author__social {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.text-block__author__social:hover {
  border-color: #262c3854;
}

.text-block__author__social img {
  border-radius: 0;
  width: 1rem;
  height: auto;
}

.quote-carousel {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  margin-bottom: 3rem;
  padding-top: 3.9rem;
  padding-bottom: 1.25rem;
}

@media (width >= 769px) {
  .quote-carousel {
    margin-bottom: 4.5rem;
    padding-top: 6.15rem;
    padding-bottom: 3.1rem;
  }
}

.quote-carousel .slick-slide {
  justify-content: center;
  align-items: center;
  display: flex;
}

.quote-carousel .quote-carousel__dots {
  padding-top: 2.5rem;
}

.quote-carousel--campaign {
  background-image: url("quote-bg.65ee589a.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.quote-carousel--campaign h3 {
  max-width: 30.7rem;
  font-size: 1.2rem;
}

@media (width >= 769px) {
  .quote-carousel--campaign h3 {
    font-size: 2rem;
  }
}

@media (width >= 961px) {
  .quote-carousel--campaign h3 {
    font-size: 2.4rem;
  }
}

.quote-carousel--campaign h3 span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 800;
}

.quote-carousel--campaign .slick-slide > div {
  width: 100%;
}

.quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container {
  align-items: center;
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (width >= 961px) {
  .quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container > img, .quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container blockquote {
    flex: 0 0 calc(50% - 2.7rem);
  }
}

.quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container blockquote p {
  font-size: .9rem;
}

@media (width >= 769px) {
  .quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container blockquote p {
    font-size: 1.2rem;
  }
}

@media (width >= 961px) {
  .quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container blockquote p {
    font-size: 1.4rem;
  }
}

.quote-carousel--campaign .slick-slide > div .quote-carousel__quote__container blockquote p span {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  font-weight: 700;
}

.quote-carousel--campaign .quote-carousel__dots {
  float: right;
  padding-top: 0;
}

.quote-carousel--campaign .quote-carousel__dots .slick-dots li button {
  background-color: #262c3854;
}

.quote-carousel--campaign .quote-carousel__dots .slick-dots li button:hover, .quote-carousel--campaign .quote-carousel__dots .slick-dots li button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.quote-carousel--campaign .quote-carousel__dots .slick-dots li.slick-active button {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  width: .5rem;
}

.quote-carousel--python {
  background-color: #ffffffb2;
  background-image: url("quote-bg.2dd61914.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
}

@media (width >= 1025px) {
  .quote-carousel--python {
    margin-bottom: 0;
  }
}

.quote-carousel--python {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 95% 100%, 0 100%);
}

.quote-carousel--python h3 {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-size: 2.4rem;
  font-weight: 800;
}

@media (width >= 769px) {
  .quote-carousel--python h3 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 1025px) {
  .quote-carousel--python h3 {
    padding-left: 0;
    padding-right: 0;
  }
}

.quote-carousel--python blockquote p, .quote-carousel--python blockquote footer {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (width >= 769px) {
  .quote-carousel--python blockquote p, .quote-carousel--python blockquote footer {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (width >= 1025px) {
  .quote-carousel--python blockquote p, .quote-carousel--python blockquote footer {
    padding-left: 0;
    padding-right: 0;
  }
}

.quote-carousel--python blockquote footer {
  font-weight: 700;
}

.quote-carousel--featured .slick-slide {
  justify-content: flex-start;
}

.quote-carousel--image {
  background-position: center;
  background-size: cover;
  position: relative;
}

.quote-carousel--image:before {
  z-index: 10;
  content: var(--tw-content);
  background-color: #0009;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.quote-carousel--image .wrapper {
  z-index: 20;
}

.quote-carousel--image .quote-carousel__quote__container {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.quote-carousel--primary {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

@media (width >= 1025px) {
  .quote-carousel--primary {
    padding-top: 8.2rem;
    padding-bottom: 8.2rem;
    padding-left: 2.5rem;
  }
}

.quote-carousel--primary .quote-carousel__quotes {
  padding: 0;
}

.quote-carousel--primary .quote-carousel__quote__container {
  max-width: 48.45rem;
  margin: 0;
}

.quote-carousel--teal {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
}

.quote-carousel--teal .quote-carousel__quote__container {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.quote-carousel--teal .slick-dots li button {
  background-color: #262c3854;
}

.quote-carousel--teal .slick-dots li button:hover, .quote-carousel--teal .slick-dots li button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.quote-carousel--teal .slick-dots li.slick-active button {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  width: 1.5rem;
}

.quote-carousel__quote__container {
  color: #ffffffb2;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1.35rem;
  max-width: 41rem;
  margin: 0 auto;
  display: flex;
}

.quote-carousel__quote__container blockquote.small p {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.quote-carousel__quote__container p {
  padding-bottom: 1.6rem;
}

@media (width >= 769px) {
  .quote-carousel__quote__container p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

.quote-carousel__quote__container footer {
  font-size: .8rem;
  font-style: italic;
  font-weight: 700;
}

@media (width >= 769px) {
  .quote-carousel__quote__container footer {
    font-size: 20px;
  }
}

.text-with-image {
  padding-bottom: 4.5rem;
  display: block;
  overflow: hidden;
}

@media (width >= 1025px) {
  .text-with-image--spacious .text-with-image__card {
    padding-bottom: 7.9rem;
  }
}

.text-with-image__card {
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2.3rem;
  display: flex;
}

@media (width >= 769px) {
  .text-with-image__card {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}

@media (width >= 961px) {
  .text-with-image__card {
    gap: 4rem;
  }
}

.text-with-image__card:last-child {
  padding-bottom: 0;
}

.text-with-image__card .image {
  order: 1;
  max-width: 20rem;
  position: relative;
}

@media (width >= 769px) {
  .text-with-image__card .image {
    order: 2;
    max-width: none;
    margin-right: -2.5rem;
  }
}

.text-with-image__card .image__arrow {
  width: 100%;
  display: none;
}

@media (width >= 769px) {
  .text-with-image__card .image__arrow {
    display: block;
  }
}

.text-with-image__card .image__arrow img {
  max-height: 4rem;
  position: absolute;
  top: 95%;
  right: 70%;
}

@media (width >= 961px) {
  .text-with-image__card .image__arrow img {
    max-height: 4.75rem;
    top: 90%;
    right: 99%;
  }
}

.text-with-image__card .text {
  order: 2;
}

@media (width >= 769px) {
  .text-with-image__card .text {
    order: 1;
    max-width: 27.95rem;
  }
}

.text-with-image__card p {
  font-size: .9rem;
}

@media (width >= 769px) {
  .text-with-image__card--image-left .image {
    order: 1;
    margin-left: -2.5rem;
    margin-right: 0;
  }
}

.text-with-image__card--image-left .image__arrow img {
  left: 70%;
  right: auto;
}

@media (width >= 961px) {
  .text-with-image__card--image-left .image__arrow img {
    left: 100%;
  }
}

@media (width >= 769px) {
  .text-with-image__card--image-left .text {
    order: 2;
  }
}

.video-block {
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .video-block {
    padding-bottom: 5.85rem;
  }
}

.video-block .video-play {
  display: block;
  position: relative;
}

.video-block .video-play svg {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
}

@media (width >= 769px) {
  .video-block .video-play svg {
    width: 6rem;
    height: 6rem;
  }
}

.video-block img {
  object-fit: cover;
  width: 100%;
  height: 100vh;
}

.team {
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .team {
    padding-bottom: 6.5rem;
  }
}

.team h2 {
  padding-bottom: 2.15rem;
}

@media (width >= 961px) {
  .team h2 {
    padding-bottom: 3.6rem;
  }
}

.team__container {
  display: flex;
}

@media (width >= 961px) {
  .team__team {
    flex: none;
    gap: 3rem;
    display: flex;
  }
}

.team__team dl {
  font-size: .9rem;
  display: none;
}

@media (width >= 961px) {
  .team__team dl {
    display: block;
  }
}

.team__team dl.tab-show {
  display: block;
}

.team__team dl + p {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.team__team p {
  --tw-text-opacity: 1;
  color: rgb(0 185 174 / var(--tw-text-opacity));
  margin-bottom: 1.6rem;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.333;
  display: none;
}

@media (width >= 961px) {
  .team__team p {
    display: block;
  }
}

.team__team dd {
  margin-bottom: 1.6rem;
  padding-left: 0;
}

.team__team dd:last-child {
  margin-bottom: 0;
}

@media (width >= 961px) {
  .team__team .show {
    font-weight: 700;
  }
}

.team__team .mobile-show {
  font-weight: 700;
}

@media (width >= 961px) {
  .team__team .mobile-show {
    font-weight: 400;
  }
}

.team__team .mobile-show + dd {
  font-weight: 700;
}

@media (width >= 961px) {
  .team__team .mobile-show + dd {
    font-weight: 400;
  }
}

.team__team dd, .team__team dt {
  cursor: default;
}

.team__team__left p, .team__team__right p {
  display: none;
}

@media (width >= 961px) {
  .team__team__left p, .team__team__right p {
    display: block;
  }
}

.team__tabs {
  text-align: center;
  align-items: center;
  gap: .6rem;
  margin-bottom: 1rem;
}

@media (width >= 561px) {
  .team__tabs {
    display: flex;
  }
}

@media (width >= 961px) {
  .team__tabs {
    display: none;
  }
}

.team__tabs p {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(0 185 174 / var(--tw-text-opacity));
  border-radius: 2.4rem;
  margin-bottom: .5rem;
  padding: .5rem 1.5rem;
  display: inline-block;
}

.team__tabs p.active {
  --tw-bg-opacity: 1;
  background-color: rgb(0 185 174 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
}

.team__images {
  flex: auto;
  margin-left: 2rem;
  position: relative;
}

.team__images img {
  opacity: 0;
  width: auto;
  height: auto;
  max-height: 10rem;
  transition-property: opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
}

@media (width >= 561px) {
  .team__images img {
    max-height: none;
  }
}

@media (width >= 961px) {
  .team__images img.show {
    opacity: 1;
  }
}

.team__images img.mobile-show {
  opacity: 1;
}

@media (width >= 961px) {
  .team__images img.mobile-show {
    opacity: 0;
  }
}

.stats-block {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 4rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .stats-block {
    padding-top: 6.25rem;
    padding-bottom: 6.95rem;
  }
}

.stats-block h3 {
  padding-bottom: 1.7rem;
}

.stats-block p {
  max-width: 40.8rem;
  font-size: .9rem;
}

@media (width >= 769px) {
  .stats-block p {
    font-size: 20px;
  }
}

@media (width >= 561px) {
  .stats-block--quarters .stats-block__stats__stat {
    max-width: calc(25% - 1.5rem);
  }
}

@media (width >= 769px) {
  .stats-block--quarters .stats-block__stats__stat {
    max-width: calc(25% - 1.5rem);
  }
}

@media (width >= 1025px) {
  .stats-block--quarters .stats-block__stats__stat {
    max-width: calc(25% - 2.25rem);
  }
}

.stats-block__stats {
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

@media (width >= 561px) {
  .stats-block__stats {
    display: flex;
  }
}

@media (width >= 769px) {
  .stats-block__stats {
    gap: 2rem;
  }
}

@media (width >= 1025px) {
  .stats-block__stats {
    gap: 2.5rem;
  }
}

.stats-block__stats__stat {
  flex: none;
  width: 100%;
  padding-bottom: 1.5rem;
}

@media (width >= 561px) {
  .stats-block__stats__stat {
    max-width: calc(33.3333% - .666667rem);
    padding: 0;
  }
}

@media (width >= 769px) {
  .stats-block__stats__stat {
    max-width: calc(33.3333% - 1.33333rem);
  }
}

@media (width >= 1025px) {
  .stats-block__stats__stat {
    max-width: calc(33.3333% - 2rem);
  }
}

.stats-block__stats__stat:last-child {
  padding-bottom: 0;
}

.stats-block__stats__stat span {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.333;
  display: block;
}

@media (width >= 769px) {
  .stats-block__stats__stat span {
    font-size: 3.2rem;
  }
}

@media (width >= 961px) {
  .stats-block__stats__stat span {
    font-size: 3.6rem;
    line-height: 5.75rem;
  }
}

.social-share {
  padding-bottom: 4.25rem;
  display: block;
}

.social-share__container {
  --tw-border-opacity: 1;
  border-top-width: 2px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  align-items: center;
  gap: .5rem;
  width: 100%;
  padding-top: 1.25rem;
  display: flex;
}

.social-share__container p {
  padding: 0 .3rem 0 0;
  font-size: .9rem;
}

.author-bio {
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .author-bio {
    padding-bottom: 5.65rem;
  }
}

.author-bio__container {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  border-radius: .65rem;
  gap: 1rem;
  padding: 2.1rem 1rem 2.6rem;
}

@media (width >= 561px) {
  .author-bio__container {
    display: flex;
  }
}

@media (width >= 769px) {
  .author-bio__container {
    gap: 1.65rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
}

.author-bio__image {
  flex: 0 0 8.35rem;
}

.author-bio__image img {
  border-radius: 50%;
  width: 100%;
  max-width: 8.25rem;
  height: auto;
}

@media (width >= 561px) {
  .author-bio__image img {
    width: 8.25rem;
    height: 8.35rem;
  }
}

.author-bio__text {
  padding-top: 1rem;
}

@media (width >= 769px) {
  .author-bio__text {
    padding-top: 0;
  }
}

.author-bio__text img {
  width: 6.35rem;
  height: auto;
  margin-bottom: .25rem;
}

.author-bio__text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-bottom: .5rem;
}

.author-bio__text p:last-child {
  max-width: 31.85rem;
  padding: 0;
}

.author-bio__text p.intro {
  align-items: center;
  gap: 1rem;
  display: flex;
}

@media (width >= 769px) {
  .author-bio__text p.intro {
    gap: 1.5rem;
  }
}

.author-bio__text p.intro a {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

.author-bio__text p.intro a:hover {
  --tw-border-opacity: 1;
  border-color: rgb(124 124 124 / var(--tw-border-opacity));
}

.author-bio__text p.intro img {
  width: 1rem;
  height: auto;
  margin: 0;
}

.contact-form {
  padding-bottom: 4rem;
  display: block;
}

.contact-form .halves {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

@media (width >= 769px) {
  .contact-form .halves {
    display: flex;
  }
}

.contact-form .halves .field {
  flex: none;
  width: 100%;
}

@media (width >= 769px) {
  .contact-form .halves .field input, .contact-form .halves .field select {
    width: calc(50% - .5rem);
  }
}

.contact-form .halves .field--half {
  flex: auto;
}

@media (width >= 769px) {
  .contact-form .halves .field--half {
    width: calc(50% - .5rem);
  }
}

.contact-form .halves .field--half input, .contact-form .halves .field--half select, .contact-form .halves .field--wide input, .contact-form .halves .field--wide select {
  width: 100%;
}

.contact-form__submit {
  justify-content: flex-start;
  padding-top: 2rem;
  display: flex;
}

.budget-slider__container {
  height: 5rem;
}

.archive {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.archive h4 {
  font-size: 20px;
}

.archive h4:first-child {
  margin-bottom: 2rem;
}

.archive-list .item {
  border-color: #ffffff70;
  border-bottom-width: 1px;
}

.archive-list .item .items {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  display: none;
}

@media (width >= 561px) {
  .archive-list .item .items {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 961px) {
  .archive-list .item .items {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.archive-list .item .items a {
  text-decoration-line: none;
}

.archive-list .item .items a:hover, .archive-list .item .items a:focus {
  text-decoration-line: underline;
}

.archive-list .item.open .items {
  padding-bottom: 2rem;
  display: grid;
}

.archive-list .item.open .item-title:after {
  background-image: url("minus--white.bb7ddf2a.svg");
}

.archive-list .item .item-title {
  cursor: pointer;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.archive-list .item .item-title h4 {
  margin: 0;
}

.archive-list .item .item-title:after {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("plus--white.2a605691.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 50%;
  right: 0;
}

.integrations {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.integrations img {
  aspect-ratio: 580 / 412;
  object-fit: cover;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.integrations__container {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
  border-radius: .5rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  padding: 1rem;
  display: grid;
}

@media (width >= 961px) {
  .integrations__container {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.integrations__text {
  flex-direction: column;
  padding: 3rem;
  display: flex;
}

.integrations__text a {
  width: -moz-fit-content;
  width: fit-content;
  margin-top: auto;
  text-decoration-line: none;
  display: inline-block;
  position: relative;
}

.integrations__text a img {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0;
  right: -1.75rem;
}

.integrations__text h3 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}

.integrations__text h3:before {
  --tw-content: "";
  content: var(--tw-content);
  background-image: url("integrations-sentence.73541c62.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
}

.coloured-cta {
  margin-bottom: 4rem;
}

.coloured-cta .coloured-cta__container {
  --tw-bg-opacity: 1;
  background-color: rgb(102 224 203 / var(--tw-bg-opacity));
  border-radius: .5rem;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  padding: 1.25rem;
  overflow: hidden;
}

@media (width >= 769px) {
  .coloured-cta .coloured-cta__container {
    display: flex;
  }
}

@media (width >= 1025px) {
  .coloured-cta .coloured-cta__container {
    gap: 5rem;
  }
}

@media (width >= 1261px) {
  .coloured-cta .coloured-cta__container {
    gap: 7.5rem;
  }
}

.coloured-cta .coloured-cta__text {
  box-sizing: content-box;
  flex: auto;
  padding-bottom: 2.5rem;
}

@media (width >= 769px) {
  .coloured-cta .coloured-cta__text {
    max-width: 24rem;
    padding-bottom: 0;
  }
}

@media (width >= 1261px) {
  .coloured-cta .coloured-cta__text {
    padding-left: 3.5rem;
  }
}

.coloured-cta .coloured-cta__text .btn {
  min-width: 12rem;
  margin-top: 1.5rem;
}

.coloured-cta .coloured-cta__image {
  flex: auto;
}

@media (width >= 769px) {
  .coloured-cta .coloured-cta__image {
    max-width: 40rem;
  }
}

.coloured-cta .coloured-cta__image img {
  border-radius: .5rem;
  width: 100%;
}

.picture-grid {
  padding-bottom: 6rem;
}

.picture-grid .picture-grid__row {
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.picture-grid .picture-grid__row picture img {
  width: 100%;
}

.pagination {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.pagination .wrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.pagination .wrapper a {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pagination .wrapper ol {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}

.pagination .wrapper ol li {
  list-style: none;
}

.pagination .wrapper ol {
  flex: none;
  order: 3;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  width: 100%;
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
}

@media (width >= 561px) {
  .pagination .wrapper ol {
    order: 2;
    width: auto;
  }
}

.pagination .wrapper ol li {
  padding-bottom: 0;
}

.pagination .wrapper ol li a, .pagination .wrapper ol li .current {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  min-width: 2.5rem;
  height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration-line: none;
  display: flex;
}

.pagination .wrapper ol li a:hover, .pagination .wrapper ol li .current:hover, .pagination .wrapper ol li a:focus, .pagination .wrapper ol li .current:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.pagination .wrapper ol li .current {
  --tw-border-opacity: 1;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 700;
}

.pagination .wrapper ol li .current:hover, .pagination .wrapper ol li .current:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.pagination .wrapper ol li span:not(.current) {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pagination .pagination__prev, .pagination .pagination__next {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
  border-radius: .25rem;
  order: 1;
  margin-bottom: .5rem;
  padding: .5rem;
}

.pagination .pagination__prev:hover, .pagination .pagination__next:hover, .pagination .pagination__prev:focus, .pagination .pagination__next:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

@media (width >= 561px) {
  .pagination .pagination__prev, .pagination .pagination__next {
    margin-bottom: 0;
  }
}

.pagination .pagination__prev svg, .pagination .pagination__next svg {
  width: 1.2rem;
  height: 1.5rem;
  margin-left: .15rem;
  margin-right: .15rem;
}

.pagination .pagination__prev svg path, .pagination .pagination__next svg path {
  stroke: #262c38;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pagination .pagination__prev:hover svg path, .pagination .pagination__prev:focus svg path, .pagination .pagination__next:hover svg path, .pagination .pagination__next:focus svg path {
  stroke: #fff;
}

.pagination .pagination__prev--combined {
  border-radius: .25rem 0 0 .25rem;
}

@media (width >= 561px) {
  .pagination .pagination__prev--combined {
    border-radius: .25rem;
  }
}

.pagination .pagination__next {
  order: 2;
}

@media (width >= 561px) {
  .pagination .pagination__next {
    order: 9999;
  }
}

.pagination .pagination__next svg {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pagination .pagination__next--combined {
  border-radius: 0 .25rem .25rem 0;
}

@media (width >= 561px) {
  .pagination .pagination__next--combined {
    border-radius: .25rem;
  }
}

.content-image {
  --tw-bg-opacity: 1;
  background-color: rgb(158 201 217 / var(--tw-bg-opacity));
  margin-bottom: 4.5rem;
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
  display: block;
}

@media (width >= 769px) {
  .content-image {
    margin-bottom: 6.6rem;
    padding-top: 6.15rem;
    padding-bottom: 6.15rem;
  }
}

.services-block {
  margin-bottom: 4.5rem;
  display: block;
}

.services-block__services {
  --tw-border-opacity: 1;
  border-bottom-width: 2px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  flex-wrap: wrap;
  gap: 1rem;
  padding-bottom: 4.5rem;
  display: flex;
}

@media (width >= 769px) {
  .services-block__services {
    padding-bottom: 8.2rem;
  }
}

@media (width >= 1261px) {
  .services-block__services--four .services-block__service {
    flex: 0 0 calc(25% - .85rem);
  }
}

.services-block__service {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(38 44 56 / var(--tw-border-opacity));
  text-align: center;
  border-radius: .75rem;
  flex: calc(50% - .85rem);
  justify-content: center;
  align-items: center;
  padding: 1.5rem .9rem;
  display: flex;
}

@media (width >= 769px) {
  .services-block__service {
    flex: calc(33% - .85rem);
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (width >= 1261px) {
  .services-block__service {
    flex: 0 0 calc(20% - .85rem);
    padding-top: 2.65rem;
    padding-bottom: 2.65rem;
  }
}

.services-block__service p {
  font-size: 1.2rem;
  font-weight: 700;
}

@media (width >= 769px) {
  .services-block__service p {
    font-size: 1.25rem;
  }
}

.event-banner {
  margin-bottom: 5rem;
  padding: 1rem;
}

.event-banner .wrapper {
  flex-direction: column;
  display: flex;
}

@media (width >= 1175px) {
  .event-banner .wrapper {
    flex-direction: row;
  }
}

.event-banner .middle-image {
  object-fit: cover;
  width: 13rem;
  display: none;
  position: relative;
  top: 0;
}

@media (width >= 1175px) {
  .event-banner .middle-image {
    display: block;
  }
}

.event-banner__left {
  background-image: url("teal-gradient.71dbc633.png");
  background-repeat: no-repeat;
  background-size: cover;
  flex: 70%;
  margin-right: 0;
  padding: 1rem;
}

@media (width >= 1175px) {
  .event-banner__left {
    margin-right: -4rem;
    padding-right: 5rem;
  }
}

.event-banner__left .inner {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media (width >= 769px) {
  .event-banner__left .inner {
    flex-direction: row;
    justify-content: normal;
    gap: 4rem;
  }
}

.event-banner__left .inner h4 {
  text-align: center;
  text-transform: uppercase;
}

@media (width >= 769px) {
  .event-banner__left .inner h4 {
    text-align: left;
  }
}

.event-banner__left .inner .btn {
  display: block;
}

@media (width >= 769px) {
  .event-banner__left .inner .btn {
    display: inline-block;
  }
}

.event-banner__right {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  flex-direction: column;
  flex: 30%;
  justify-content: center;
  align-items: flex-start;
  margin-left: 0;
  padding: 1rem;
  display: flex;
}

@media (width >= 1175px) {
  .event-banner__right {
    margin-left: -7rem;
    padding-left: 5rem;
  }
}

.dotdigital-form {
  background-image: url("dotdigital-gradient-bg.5fa9ba02.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.dotdigital-form .wrapper {
  padding-top: 2.5rem;
}

@media (width >= 961px) {
  .dotdigital-form .wrapper {
    padding-top: 5rem;
  }
}

.dotdigital-form form {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  padding: 2.5rem .5rem;
}

@media (width >= 961px) {
  .dotdigital-form form {
    padding: 5rem 3rem;
  }
}

.dotdigital-form form input {
  border-radius: .125rem;
}

.dotdigital-form form .btn {
  border-radius: .5rem;
}

@media (width >= 961px) {
  .dotdigital-form form .btn {
    max-width: 7.5rem;
  }
}

.cta-banner {
  flex-wrap: wrap;
  display: flex;
}

.cta-banner .cta-banner__col {
  background-repeat: no-repeat;
  background-size: cover;
  flex: 0 0 100%;
  min-height: 18.65rem;
}

@media (width >= 1025px) {
  .cta-banner .cta-banner__col {
    flex: 0 0 50%;
  }
}

.cta-banner .cta-banner__col picture {
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
}

.cta-banner .cta-banner__col picture img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
}

.cta-banner .cta-banner__col h3 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 1.6rem;
  font-weight: 400;
}

@media (width >= 769px) {
  .cta-banner .cta-banner__col h3 {
    font-size: 2rem;
  }
}

.cta-banner .cta-banner__col .btn {
  border-radius: 9999px !important;
}

.cta-banner .cta-banner__col--text-col {
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
}

@media (width >= 769px) {
  .cta-banner .cta-banner__col--text-col {
    justify-content: center;
  }
}

.cta-banner .cta-banner__col--text-col .cta-banner__col--text-col__content {
  position: absolute;
}

@media not all and (width >= 769px) {
  .cta-banner .cta-banner__col--text-col .cta-banner__col--text-col__content {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    display: flex;
  }
}

@media (width >= 769px) {
  .cta-banner .cta-banner__col--text-col .cta-banner__col--text-col__content {
    padding-left: 2rem;
  }
}

.cta-banner .cta-banner__col--text-col .btn {
  max-width: 11.95rem;
}

.cta-banner .cta-banner__col--image-col {
  align-items: baseline;
  display: flex;
}

@media not all and (width >= 769px) {
  .cta-banner .cta-banner__col--image-col {
    justify-content: flex-end;
  }
}

@media (width >= 769px) {
  .cta-banner .cta-banner__col--image-col {
    align-items: flex-end;
  }
}

.cta-banner .cta-banner__col--image-col span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  align-items: center;
  padding: 3rem 1rem 1.5rem 0;
  font-size: .8rem;
  text-decoration-line: underline;
  display: flex;
  position: absolute;
}

@media (width >= 769px) {
  .cta-banner .cta-banner__col--image-col span {
    padding-top: 0;
    padding-left: 2rem;
    padding-right: 0;
  }
}

.cta-banner .cta-banner__col--image-col span svg {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: .5rem;
}

.cta-banner .cta-banner__col--image-col span.cta-banner__col__button--dark {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.cta-banner .cta-banner__col--image-col span.cta-banner__col__button--dark svg path {
  stroke: #262c38;
}

@media not all and (width >= 961px) {
  .calendly-embed {
    --tw-bg-opacity: 1;
    background-color: rgb(229 89 40 / var(--tw-bg-opacity));
  }
}

.calendly-embed .calendly-embed__content {
  --tw-gradient-from: #e55928 var(--tw-gradient-from-position);
  --tw-gradient-to: #e5592800 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: #e86c4d var(--tw-gradient-to-position);
  --tw-shadow-color: #0006;
  --tw-shadow: var(--tw-shadow-colored);
  padding-bottom: 1rem;
}

@media (width >= 682px) {
  .calendly-embed .calendly-embed__content {
    padding-bottom: 0;
  }
}

@media (width >= 961px) {
  .calendly-embed .calendly-embed__content {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
    --tw-shadow: 0 0 1rem var(--tw-shadow-color);
    --tw-shadow-colored: 0 0 1rem var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-top-left-radius: 2.2rem;
    border-top-right-radius: 2.2rem;
  }
}

.calendly-embed .calendly-embed__content .calendly-embed__heading {
  padding-top: 1rem;
}

@media (width >= 961px) {
  .calendly-embed .calendly-embed__content .calendly-embed__heading {
    padding-top: 6rem;
  }
}

.calendly-embed .calendly-embed__content .calendly-embed__heading h3, .calendly-embed .calendly-embed__content .calendly-embed__heading p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  padding-left: .25rem;
  padding-right: .25rem;
}

.calendly-embed .calendly-embed__content .calendly-inline-widget {
  padding-top: 2rem;
}

@media (width >= 682px) {
  .calendly-embed .calendly-embed__content .calendly-inline-widget {
    padding-top: 0;
    height: 55rem !important;
  }
}

@media (width >= 1080px) {
  .calendly-embed .calendly-embed__content .calendly-inline-widget {
    height: 35rem !important;
  }
}

.dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.dark .text-block h1, .dark .text-block h2, .dark .text-block h3, .dark .text-block h4, .dark .text-block h5, .dark .text-block h6, .dark .text-block ol, .dark .text-block ul, .dark .text-block li, .dark .text-block p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.book-a-call-modal {
  width: 100%;
  height: 100%;
}

.book-a-call-modal .mfp-container {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  max-width: 23rem;
  max-height: 100vh;
  padding: 2rem;
  position: relative;
  overflow-y: scroll;
}

@media (width >= 769px) {
  .book-a-call-modal .mfp-container {
    float: right;
    padding: 3rem 2.3rem;
  }
}

.book-a-call-modal.mfp-bg {
  display: none;
}

.book-a-call-modal #book-a-call-modal {
  padding-top: 5rem;
}

.book-a-call-modal #book-a-call-modal .book-a-call-modal__content {
  margin-bottom: 2rem;
}

.book-a-call-modal #book-a-call-modal .book-a-call-modal__content h3 {
  font-size: 2rem;
  font-weight: 800;
}

.book-a-call-modal #book-a-call-modal .book-a-call-modal__content p {
  font-size: .8rem;
}

.book-a-call-modal .book-a-call-modal__close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.book-a-call-modal .book-a-call-modal__close .btn {
  justify-content: center;
  align-items: center;
  gap: .75rem;
  display: flex;
}

.book-a-call-modal .book-a-call-modal__close .btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  text-decoration-line: underline;
}

@media (width >= 961px) {
  .book-a-call {
    min-width: 14rem;
  }
}

.book-call-banner {
  --tw-bg-opacity: 1;
  background-color: rgb(60 138 150 / var(--tw-bg-opacity));
  border-radius: 1rem;
  align-items: center;
  margin-bottom: 2.5rem;
  display: flex;
}

@media not all and (width >= 769px) {
  .book-call-banner {
    flex-wrap: wrap;
  }
}

.book-call-banner .book-call-banner__image {
  flex: 0 0 100%;
  width: 100%;
}

@media (width >= 769px) {
  .book-call-banner .book-call-banner__image {
    flex: 0 0 40%;
  }
}

@media (width >= 961px) {
  .book-call-banner .book-call-banner__image {
    flex: 0 0 45.52%;
    min-height: 22rem;
  }
}

@media (width >= 1025px) {
  .book-call-banner .book-call-banner__image {
    margin-bottom: -.1rem;
    margin-left: -.1rem;
  }
}

.book-call-banner .book-call-banner__image img {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.book-call-banner .book-call-banner__content {
  flex: 0 0 100%;
  padding: 1rem;
}

@media (width >= 769px) {
  .book-call-banner .book-call-banner__content {
    flex: 0 0 60%;
    padding: 2rem 1rem;
  }
}

@media (width >= 961px) {
  .book-call-banner .book-call-banner__content {
    flex: 0 0 54.48%;
    padding: 1rem 2rem 1rem 4.9rem;
  }
}

@media (width >= 1175px) {
  .book-call-banner .book-call-banner__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.book-call-banner .book-call-banner__content h3 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 1.6rem;
  font-weight: 800;
}

@media (width >= 961px) {
  .book-call-banner .book-call-banner__content h3 {
    font-size: 2rem;
  }
}

.book-call-banner .book-call-banner__content h3 span {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.book-call-banner .book-call-banner__content p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .9rem;
}

@media (width >= 961px) {
  .book-call-banner .book-call-banner__content p {
    font-size: 20px;
  }
}

.book-call-banner .book-call-banner__content .btn:hover, .book-call-banner .book-call-banner__content .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.book-call-banner .book-call-banner__content .btn img {
  opacity: 1;
  width: 100%;
  max-width: 1.3rem;
  margin-left: .5rem;
}

.book-call-banner.book-call-banner--orange {
  --tw-bg-opacity: 1;
  background-color: rgb(241 89 41 / var(--tw-bg-opacity));
}

@media (width >= 1025px) {
  .book-call-banner.book-call-banner--orange .book-call-banner__image {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.book-call-banner.book-call-banner--orange .book-call-banner__content h3, .book-call-banner.book-call-banner--orange .book-call-banner__content p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.book-call-banner.book-call-banner--orange .book-call-banner__content h3 {
  font-size: 1.2rem;
}

.book-call-banner.book-call-banner--orange .book-call-banner__content p {
  font-size: .8rem;
}

.stats-grid {
  flex-wrap: wrap;
  gap: .75rem;
  display: flex;
}

.stats-grid .stats-grid__col {
  flex-direction: column;
  flex: 0 0 100%;
  gap: .75rem;
  display: flex;
}

@media (width >= 769px) {
  .stats-grid .stats-grid__col {
    flex: 0 0 calc(50% - .75rem);
  }
}

.stats-grid .stats-grid__col .stats-grid__col__image, .stats-grid .stats-grid__col .stats-grid__col__video, .stats-grid .stats-grid__col .stats-grid__col__stat {
  border-radius: .5rem;
}

@media (width >= 961px) {
  .stats-grid .stats-grid__col .stats-grid__col__image, .stats-grid .stats-grid__col .stats-grid__col__video {
    min-height: 28.7rem;
  }
}

.stats-grid .stats-grid__col .stats-grid__col__image img {
  object-fit: cover;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.stats-grid .stats-grid__col .stats-grid__col__video {
  -webkit-mask-image: url("video-mask.a62ec6e3.svg");
  mask-image: url("video-mask.a62ec6e3.svg");
  -webkit-mask-position: 100%;
  mask-position: 100%;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.stats-grid .stats-grid__col .stats-grid__col__video video {
  object-fit: cover;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content {
  justify-content: space-between;
  align-items: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  position: relative;
  bottom: 6rem;
}

@media (width >= 561px) {
  .stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content {
    bottom: 4.5rem;
  }
}

@media (width >= 769px) {
  .stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content {
    bottom: 6.5rem;
  }
}

@media (width >= 961px) {
  .stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content {
    bottom: 8.5rem;
  }
}

.stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content span {
  --tw-bg-opacity: 1;
  background-color: rgb(244 188 66 / var(--tw-bg-opacity));
  border-radius: 1rem;
  padding: .5rem 1rem;
  font-size: .5rem;
  font-weight: 700;
}

.stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: .5rem;
  font-size: 1.3rem;
  font-weight: 800;
}

@media (width >= 961px) {
  .stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content p {
    font-size: 2rem;
  }
}

.stats-grid .stats-grid__col .stats-grid__col__video .stats-grid__col__video__content a {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 9999px;
  margin-bottom: .25rem;
  padding: .5rem;
}

.stats-grid .stats-grid__col .stats-grid__col__stat {
  flex-direction: column;
  flex: auto;
  justify-content: space-between;
  padding: 1rem;
  display: flex;
}

.stats-grid .stats-grid__col .stats-grid__col__stat p, .stats-grid .stats-grid__col .stats-grid__col__stat span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.stats-grid .stats-grid__col .stats-grid__col__stat span {
  max-width: 70%;
  font-size: 1.9rem;
  font-weight: 600;
  line-height: 1;
}

.stats-grid .stats-grid__col .stats-grid__col__stat span span {
  font-size: 3.2rem;
}

.stats-grid .stats-grid__col .stats-grid__col__stat p {
  font-size: .9rem;
}

.stats-grid .stats-grid__col:first-child .stats-grid__col__stat {
  --tw-bg-opacity: 1;
  background-color: rgb(159 163 208 / var(--tw-bg-opacity));
}

.stats-grid .stats-grid__col:first-child .stats-grid__col__stat p {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  padding-top: 2rem;
}

.stats-grid .stats-grid__col:nth-child(2) .stats-grid__col__stat {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

@media not all and (width >= 769px) {
  .stats-grid .stats-grid__col:nth-child(2) .stats-grid__col__stat {
    order: 2;
  }
}

.stats-grid .stats-grid__col:nth-child(2) .stats-grid__col__stat p {
  padding-bottom: 2rem;
}

.stats-grid .stats-grid__col:nth-child(2) .stats-grid__col__stat span:nth-child(2) {
  font-size: 1.894rem;
}

.cta-grid {
  flex-wrap: wrap;
  gap: .75rem;
  margin-bottom: 5rem;
  display: flex;
}

.cta-grid .cta-grid__col {
  flex-direction: column;
  flex: 0 0 100%;
  gap: .75rem;
  display: flex;
}

@media (width >= 769px) {
  .cta-grid .cta-grid__col {
    flex: 0 0 calc(50% - .75rem);
  }
}

.cta-grid .cta-grid__col .cta-grid__col__image {
  border-radius: .5rem;
  position: relative;
}

.cta-grid .cta-grid__col .cta-grid__col__image img {
  object-fit: cover;
  border-radius: .5rem;
  width: 100%;
  height: 100%;
}

.cta-grid .cta-grid__col .cta-grid__col__image > svg {
  position: absolute;
  top: 1rem;
  left: 1rem;
}

@media (width >= 961px) {
  .cta-grid .cta-grid__col .cta-grid__col__image > svg {
    top: 2rem;
    left: 2rem;
  }
}

.cta-grid .cta-grid__col .cta-grid__col__image a {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 9999px;
  margin-bottom: .25rem;
  padding: .5rem;
  position: absolute;
  bottom: .5rem;
  right: .5rem;
}

@media (width >= 961px) {
  .cta-grid .cta-grid__col .cta-grid__col__1, .cta-grid .cta-grid__col .cta-grid__col__4 {
    min-height: 28.7rem;
  }
}

.cta-grid .cta-grid__col .cta-grid__col__2, .cta-grid .cta-grid__col .cta-grid__col__3 {
  flex: auto;
}

.download-form {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  border-radius: .75rem;
  align-items: center;
  margin-bottom: 2.5rem;
  display: flex;
}

@media not all and (width >= 769px) {
  .download-form {
    flex-wrap: wrap;
  }
}

@media (width >= 961px) {
  .download-form {
    max-height: 100%;
  }
}

.download-form .download-form__image {
  flex: 0 0 100%;
  width: 100%;
}

@media (width >= 769px) {
  .download-form .download-form__image {
    flex: 0 0 40%;
  }
}

@media (width >= 961px) {
  .download-form .download-form__image {
    flex: 0 0 50%;
  }
}

.download-form .download-form__image img {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media (width >= 961px) {
  .download-form .download-form__image img {
    max-height: 33.05rem;
  }
}

.download-form .download-form__content {
  flex: 0 0 100%;
  padding: 1rem;
}

@media not all and (width >= 769px) {
  .download-form .download-form__content {
    order: 2;
  }
}

@media (width >= 769px) {
  .download-form .download-form__content {
    flex: 0 0 60%;
    padding: 2rem 1rem;
  }
}

@media (width >= 961px) {
  .download-form .download-form__content {
    flex: 0 0 50%;
    padding: 0 3rem;
  }
}

.download-form .download-form__content .download-form__content__text {
  display: block;
}

.download-form .download-form__content .download-form__content__text.close {
  display: none;
}

.download-form .download-form__content .download-form__content__text > span {
  --tw-bg-opacity: 1;
  background-color: rgb(50 50 50 / var(--tw-bg-opacity));
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 1rem;
  padding: .5rem 1rem;
  font-size: .9rem;
  font-weight: 700;
}

.download-form .download-form__content .download-form__content__text h3 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: 800;
  line-height: 1.25;
}

@media (width >= 961px) {
  .download-form .download-form__content .download-form__content__text h3 {
    margin-bottom: 5rem;
    font-size: 2rem;
  }
}

.download-form .download-form__content .download-form__content__text h3 span {
  --tw-text-opacity: 1;
  color: rgb(159 163 208 / var(--tw-text-opacity));
}

.download-form .download-form__content .download-form__content__text p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .9rem;
}

@media (width >= 961px) {
  .download-form .download-form__content .download-form__content__text p {
    font-size: 20px;
  }
}

.download-form .download-form__content .download-form__content__form {
  display: none;
}

.download-form .download-form__content .download-form__content__form.open {
  display: block;
}

.download-form .download-form__content .download-form__content__form h4 {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 2rem;
  font-weight: 800;
}

.download-form .download-form__content .download-form__content__form label {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: .9rem;
  font-weight: 400;
}

.download-form .download-form__content .download-form__content__form input {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 1rem;
}

.download-form .download-form__content .download-form__content__form .download-form__form__col {
  flex-wrap: wrap;
  gap: 1rem;
  display: flex;
}

.download-form .download-form__content .download-form__content__form .download-form__form__col > div {
  flex: 0 0 calc(50% - .5rem);
}

.download-form .download-form__content .download-form__content__form .download-form__form__consent {
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
}

.download-form .download-form__content .btn {
  --tw-bg-opacity: 1;
  background-color: rgb(159 163 208 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  border-radius: .75rem;
}

.download-form .download-form__content .btn:hover, .download-form .download-form__content .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(159 163 208 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.download-form .download-form__content .btn img {
  opacity: 1;
  width: 100%;
  max-width: 1.3rem;
  margin-left: .5rem;
}

.campaign-carousel {
  margin-bottom: 2.5rem;
  position: relative;
}

@media (width >= 961px) {
  .campaign-carousel {
    margin-bottom: 5rem;
  }
}

.campaign-carousel h3 {
  max-width: 30.7rem;
  font-size: 1.2rem;
  font-weight: 800;
}

@media (width >= 769px) {
  .campaign-carousel h3 {
    font-size: 2rem;
  }
}

@media (width >= 961px) {
  .campaign-carousel h3 {
    font-size: 2.4rem;
  }
}

.campaign-carousel h3 span {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-weight: 800;
}

.campaign-carousel .campaign-carousel__slides {
  margin-top: -4rem;
}

.campaign-carousel .slick-slide {
  justify-content: center;
  align-items: center;
  display: flex;
}

.campaign-carousel .slick-slide > div {
  width: 100%;
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide {
  flex-wrap: wrap;
  align-items: center;
  display: flex !important;
}

@media (width >= 961px) {
  .campaign-carousel .slick-slide > div .campaign-carousel__slide {
    min-height: 28.55rem;
  }
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__container, .campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__image {
  flex: 0 0 100%;
}

@media (width >= 769px) {
  .campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__container, .campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__image {
    flex: 0 0 50%;
  }
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__image {
  justify-content: center;
  max-height: 28.55rem;
  display: flex;
}

@media not all and (width >= 769px) {
  .campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__image {
    order: 1;
    margin-bottom: 1rem;
  }
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide .campaign-carousel__slide__image img {
  max-height: 28.55rem;
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide__container {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media not all and (width >= 769px) {
  .campaign-carousel .slick-slide > div .campaign-carousel__slide__container {
    order: 2;
  }
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide__container h4 {
  font-size: 1.6rem;
  font-weight: 800;
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide__container h4 span {
  --tw-text-opacity: 1;
  color: rgb(60 138 150 / var(--tw-text-opacity));
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide__container p {
  font-size: .9rem;
}

.campaign-carousel .slick-slide > div .campaign-carousel__slide__container p span {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
  font-weight: 700;
}

.campaign-carousel .campaign-carousel__dots {
  text-align: left;
  padding-top: 0;
  bottom: 10%;
}

@media (width >= 961px) {
  .campaign-carousel .campaign-carousel__dots {
    position: absolute;
  }
}

@media (width >= 1025px) {
  .campaign-carousel .campaign-carousel__dots {
    bottom: 15%;
  }
}

.campaign-carousel .campaign-carousel__dots .slick-dots {
  justify-content: normal;
}

@media not all and (width >= 769px) {
  .campaign-carousel .campaign-carousel__dots .slick-dots {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.campaign-carousel .campaign-carousel__dots .slick-dots li button {
  background-color: #262c3854;
}

.campaign-carousel .campaign-carousel__dots .slick-dots li button:hover, .campaign-carousel .campaign-carousel__dots .slick-dots li button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(0 70 80 / var(--tw-bg-opacity));
}

.campaign-carousel .campaign-carousel__dots .slick-dots li.slick-active button {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  width: .5rem;
}

.campaign-carousel .btn {
  bottom: 0;
}

.campaign-carousel .btn:hover, .campaign-carousel .btn:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (width >= 961px) {
  .campaign-carousel .btn {
    position: absolute;
  }
}

.campaign-carousel .btn img {
  opacity: 1;
  width: 100%;
  max-width: 1.3rem;
  margin-left: .5rem;
}

.section-header h2 {
  font-size: 1.6rem;
}

.section-header h2, .section-header p {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

@media (width >= 1025px) {
  .section-header h2, .section-header p {
    max-width: 65%;
  }
}

@media (width >= 961px) {
  .section-header img {
    margin-top: 2.5rem;
  }
}

.section-header.section-header--dark {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

@media (width >= 961px) {
  .section-header.section-header--dark {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media (width >= 1025px) {
  .section-header.section-header--dark {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
}

.section-header.section-header--dark {
  --tw-bg-opacity: 1;
  background-color: rgb(38 44 56 / var(--tw-bg-opacity));
}

.section-header.section-header--dark h2 {
  font-size: 2.4rem;
}

.section-header.section-header--dark h2, .section-header.section-header--dark p {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.section-header.section-header--dark + .wrapper .page-cards {
  margin-top: 2.5rem;
}

@media (width >= 1025px) {
  .section-header.section-header--dark + .wrapper .page-cards {
    margin-top: -8rem;
  }
}

.section-header + .section-header--dark {
  margin-top: 2.5rem;
}

@media (width >= 961px) {
  .section-header + .section-header--dark {
    margin-top: -5rem;
  }
}

.page-cards {
  flex-wrap: wrap;
  gap: 1.5rem;
  display: flex;
}

@media (width >= 1025px) {
  .page-cards {
    margin-bottom: 4rem;
  }
}

.page-cards .page-card {
  flex: 0 0 100%;
  margin-bottom: 1rem;
}

@media (width >= 961px) {
  .page-cards .page-card {
    flex: 0 0 calc(50% - .75rem);
  }
}

.page-cards .page-card img {
  width: 100%;
  margin-bottom: 2rem;
}

.page-cards .page-card h3 {
  padding-bottom: 1.5rem;
  font-weight: 800;
}

.page-cards .page-card h3, .page-cards .page-card p {
  --tw-text-opacity: 1;
  color: rgb(38 44 56 / var(--tw-text-opacity));
}

.logo-integrations {
  margin-bottom: 2.5rem;
}

.logo-integrations .logo-integrations__container {
  flex-wrap: wrap;
  gap: 1.4rem;
  display: flex;
}

.logo-integrations .logo-integrations__container > div {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .75rem;
  padding: 3rem 2rem;
}

.logo-integrations .logo-integrations__container > div h3 {
  text-align: center;
  margin-bottom: 2.5rem;
  font-size: 1.4rem;
  font-weight: 800;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__tech {
  flex: 0 0 100%;
}

@media (width >= 961px) {
  .logo-integrations .logo-integrations__container .logo-integrations__container__tech {
    flex: 0 0 calc(60% - 1.4rem);
  }
}

.logo-integrations .logo-integrations__container .logo-integrations__container__tech h3 {
  margin-bottom: 2rem;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__tech .logo-integrations__container__tech__logos {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1.5rem 2rem;
  display: flex;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__tech .logo-integrations__container__tech__logos img {
  object-fit: contain;
  flex: auto;
  max-height: 4rem !important;
}

@media (width >= 961px) {
  .logo-integrations .logo-integrations__container .logo-integrations__container__tech .logo-integrations__container__tech__logos img {
    flex: 0 0 calc(33.33% - 2rem);
    max-width: calc(33.33% - 2rem);
  }
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud {
  flex: 0 0 100%;
}

@media (width >= 961px) {
  .logo-integrations .logo-integrations__container .logo-integrations__container__cloud {
    flex: 0 0 calc(40% - 1.4rem);
  }
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud h3 {
  margin-bottom: 2rem;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud .logo-integrations__container__cloud__logos {
  flex-flow: column wrap;
  justify-content: center;
  gap: 3rem;
  display: flex;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud .logo-integrations__container__cloud__logos img {
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
}

@media (width >= 961px) {
  .logo-integrations .logo-integrations__container .logo-integrations__container__cloud .logo-integrations__container__cloud__logos img {
    max-width: 11.2rem;
  }
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud .logo-integrations__container__cloud__logos img:first-child {
  max-width: 8.3rem;
}

.logo-integrations .logo-integrations__container .logo-integrations__container__cloud .logo-integrations__container__cloud__logos img:nth-child(2) {
  max-width: 5.05rem;
}

.hero-gradient {
  background-image: url("hero-gradient--mobile.ed6318b1.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (width >= 769px) {
  .hero-gradient {
    background-image: url("hero-gradient.95d06449.jpg");
  }
}

.hero-gradient--tall {
  background-image: url("hero-gradient--tall-mobile.fdabf3e7.jpg");
  margin-bottom: 4.25rem;
}

@media (width >= 769px) {
  .hero-gradient--tall {
    background-image: url("hero-gradient--tall.0c6603bc.jpg");
    margin-bottom: 6.111rem;
  }
}

.hero-gradient--tall * {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:\!hidden:before {
  content: var(--tw-content);
  display: none !important;
}

@media (width >= 561px) {
  .small-mobile\:\!mr-16 {
    margin-right: 4rem !important;
  }
}

@media (width >= 769px) {
  .mobile\:mb-20 {
    margin-bottom: 5rem;
  }

  .mobile\:block {
    display: block;
  }

  .mobile\:flex {
    display: flex;
  }

  .mobile\:hidden {
    display: none;
  }

  .mobile\:\!h-12 {
    height: 3rem !important;
  }

  .mobile\:\!h-20 {
    height: 5rem !important;
  }

  .mobile\:\!h-\[3\.6rem\] {
    height: 3.6rem !important;
  }

  .mobile\:max-h-\[2\.35rem\] {
    max-height: 2.35rem;
  }

  .mobile\:max-h-\[3\.2rem\] {
    max-height: 3.2rem;
  }

  .mobile\:max-w-\[17\.55rem\] {
    max-width: 17.55rem;
  }

  .mobile\:max-w-\[18\.55rem\] {
    max-width: 18.55rem;
  }

  .mobile\:pb-\[2\.85rem\] {
    padding-bottom: 2.85rem;
  }

  .mobile\:pt-\[2\.85rem\] {
    padding-top: 2.85rem;
  }
}

@media (width >= 1025px) {
  .desktop\:max-w-\[25\.45rem\] {
    max-width: 25.45rem;
  }
}

@media (width >= 1261px) {
  .large-desktop\:\!px-16 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
}

@media (width >= 1440px) {
  .widescreen\:hidden {
    display: none;
  }

  .widescreen\:bg-\[url\(\'\.\.\/\.\.\/static\/img\/client-imagery\/whizz-kidz\/hero-wide\.jpg\'\)\] {
    background-image: url("hero-wide.fd702b66.jpg");
  }
}

/*# sourceMappingURL=main.css.map */
